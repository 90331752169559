import * as React from "react"
import {
  ChakraProvider,
  extendTheme,
  ThemeConfig, 
  createStandaloneToast,
} from "@chakra-ui/react"
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Signup } from "./pages/signup/Signup"
import { AuthProvider } from "./contexts/Auth"
import { Signin } from "./pages/signin/Signin"
import ForgotPassword from "./pages/forgotPassword/ForgotPassword"
import MagicLink from "./pages/magicLink/MagicLink"
import AuthActionHandler from "./pages/authActionHandler/AuthActionHandler"
import PrivateRoute from "./routes/PrivateRoute"
import Dashboard from "./pages/dashboard/Dashboard"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from 'react-query/devtools'
import queryString from 'query-string'
import "focus-visible/dist/focus-visible"
import { useEffect } from "react"

export const App = () => {

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        // staleTime: 180000
      },
    },
  })

  React.useEffect(() => {
    const el = document.querySelector(".container");
    if (el) {
      el.remove();
    }
  }, []);

  const config : ThemeConfig = {
    initialColorMode: "dark",
    useSystemColorMode: false,
  }
  // 3. extend the theme
  const theme = extendTheme({ config })

  const urlHashParams = queryString.parse(window.location.hash)

  useEffect(() => {
    if (urlHashParams.message) {
      const toast = createStandaloneToast()
      toast({
        title: 'Notice',
        description: urlHashParams.message,
        status: 'info',
        duration: 9000,
        isClosable: true,
        position: 'top-right'
      })
    }
  }, [urlHashParams])

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <AuthProvider>
          <Router>
            <Switch>
              <Route path="/signup" component={ Signup } />
              <Route path="/signin" component={ Signin } />
              <Route path="/forgot-password" component={ ForgotPassword } />
              <Route path="/get-magic-link" component={ MagicLink } />
              <Route path="/auth-action-handler" component={ AuthActionHandler } />
              <PrivateRoute path="/dashboard" component={ Dashboard } />
              <PrivateRoute path="/" component={ Dashboard } />
            </Switch>
          </Router>
        </AuthProvider>
      </ChakraProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

import Table from '@tiptap/extension-table'

export const CustomTable = Table.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            width: {
                default: '100%',
                parseHTML: element => element.getAttribute('width'),
                renderHTML: attributes => {
                    return {
                        width: attributes.width,
                    }
                },
            },
            tableLayout: {
                default: 'fixed',
                parseHTML: element => element.getAttribute('table-layout'),
                renderHTML: attributes => {
                    return {
                        'table-layout': attributes.tableLayout,
                        style: `table-layout: ${attributes.tableLayout};`
                    }
                },
            }
        }
    }
})
import React from "react";
import { useState, State } from "@hookstate/core";
import RectangleShape, { RectProps } from "../elements/shapes/RectangleShape";
import EllipseShape, { EllipseProps } from "../elements/shapes/EllipseShape";
import TextElement, { TextProps } from "../elements/TextElement";
import QrElement, { QrProps } from "../elements/QrElement";
import ImageElement, { ImageProps } from "../elements/ImageElement";

export interface ElementType {
	type: string;
	data: RectProps | EllipseProps | TextProps | QrProps | ImageProps;
	header: string;
	required?: boolean;
	auto?: boolean;
}
export interface ElementsType {
	[id: string]: { type: string; data: RectProps | EllipseProps | ImageProps | TextProps | QrProps; header: string; required?: boolean; auto?: boolean };
}

interface Props {
	elementsData: State<ElementsType>;
	selectedId: State<string | null>;
}

const DrawingSheet: React.FC<Props> = ({ elementsData, selectedId }) => {
	const elements = useState<ElementsType>(elementsData);

	return (
		<>
			{Object.keys(elements).map((key) => {
				let element = elements[key];
				switch (element.type.get()) {
					default:
						return <></>;
					case "rect":
						return (
							<RectangleShape
								key={key}
								attrs={{ ...element.data.get(), id: key } as RectProps}
								isSelected={key === selectedId.get()}
								onSelect={() => {
									selectedId.set(key);
								}}
								onChange={(newAttrs) => {
									element.data.set({ ...newAttrs });
								}}
							/>
						);
					case "ellipse":
						return (
							<EllipseShape
								key={key}
								attrs={{ ...element.data.get(), id: key } as EllipseProps}
								isSelected={key === selectedId.get()}
								onSelect={() => {
									selectedId.set(key);
								}}
								onChange={(newAttrs) => {
									element.data.set({ ...newAttrs });
								}}
							/>
						);
					case "text":
						return (
							<TextElement
								key={key}
								attrs={{ ...element.data.get(), id: key } as TextProps}
								isSelected={key === selectedId.get()}
								isEditable={!element.auto.get()}
								onSelect={() => {
									selectedId.set(key);
								}}
								onChange={(newAttrs) => {
									element.data.set({ ...newAttrs });
								}}
							/>
						);
					case "qr":
						return (
							<QrElement
								key={key}
								elementProps={element.get()}
								attrs={{ ...element.data.get(), id: key } as QrProps}
								isSelected={key === selectedId.get()}
								onSelect={() => {
									selectedId.set(key);
								}}
								onChange={(newAttrs) => {
									element.data.set({ ...newAttrs });
								}}
							/>
						);
					case "image":
						return (
							<ImageElement
								key={key}
								elementProps={element.get()}
								attrs={{ ...element.data.get(), id: key } as ImageProps}
								isSelected={key === selectedId.get()}
								onSelect={() => {
									selectedId.set(key);
								}}
								onChange={(newAttrs) => {
									element.data.set({ ...newAttrs });
								}}
							/>
						);
				}
			})}
		</>
	);
};

export default DrawingSheet;

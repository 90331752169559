import React, { useRef, useEffect, KeyboardEvent } from "react";
import { Text, Transformer } from "react-konva";
import { Html } from "react-konva-utils";

import { Text as KonvaText } from "konva/lib/shapes/Text";
import { Transformer as KonvaTransformer } from "konva/lib/shapes/Transformer";
import { useState } from "@hookstate/core";

export interface TextProps {
	id?: string;
	x: number;
	y: number;
	fill: string;
	text: string;
	fontSize: number;
	width?: number;
	height?: number;
	stroke?: string;
	strokeWidth?: number;
	align?: string;
	fontStyle?: string;
	textDecoration?: string;
	fontFamily?: string;
}

export interface Props {
	attrs: TextProps;
	isSelected: boolean;
	isEditable?: boolean;
	onSelect: () => void;
	onChange: (newAttrs: TextProps) => void;
}

const TextElement: React.FC<Props> = ({ attrs, isSelected, isEditable, onSelect, onChange }) => {
	const isEditing = useState<boolean>(false);
	const textRef = useRef<KonvaText>(null);
	const trRef = useRef<KonvaTransformer>(null);
	const inputRef = useRef<HTMLDivElement>(null);

	const handleKeyup = (event:any) => {
		if (isSelected) {
			if (isEditing.get()) {
				return
			}
			if (event.key in ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown']) {
				handleDragEnd()
			}
		}
	}

	const handleKeydown = (event:any) => {
		if (isSelected) {
			if (isEditing.get()) {
				return
			}
			switch (event.key) {
				case 'ArrowLeft':
					textRef.current?.x(textRef.current?.x() - 1)	
					event.preventDefault()	
					break;

				case 'ArrowRight':
					textRef.current?.x(textRef.current?.x() + 1)	
					event.preventDefault()
					break;

				case 'ArrowUp':
					textRef.current?.y(textRef.current?.y() - 1)	
					event.preventDefault()	
					break;

				case 'ArrowDown':
					textRef.current?.y(textRef.current?.y() + 1)	
					event.preventDefault()	
					break;
			
				default:
					break;
			}
		}
	}

	useEffect(() => {
		window.addEventListener('keydown', handleKeydown)
		window.addEventListener('keyup', handleKeyup)
		return () => {
			window.removeEventListener('keydown', handleKeydown)
			window.removeEventListener('keyup', handleKeyup)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSelected])

	useEffect(() => {
		if (isSelected) {
			// attach transformer
			if (trRef.current && textRef.current) {
				trRef.current.nodes([textRef.current]);
				trRef.current.moveToTop();
				trRef.current.getLayer()?.batchDraw();
			}
		}
	}, [isSelected]);

	const handleMouseEnter = () => {
		const node = textRef.current;
		if (node && !isSelected) onChange({ ...attrs, stroke: "#42adf5", strokeWidth: 0.5 });
	};

	const handleMouseLeave = () => {
		const node = textRef.current;
		if (node) onChange({ ...attrs, strokeWidth: 0 });
	};

	const handleCanvasBounds = (pos: any) => {
		let newPos = { x: 0, y: 0 };
		let node = textRef.current;
		let tr = trRef.current;
		// let nodeWidth = node?.getTextWidth() ? node?.getTextWidth() : 0;
		// let nodeHeight = node?.getHeight() ? node?.getHeight() : 0;
		if (tr) {
			let nodeWidth = tr.width();
			let nodeHeight = tr?.height();
			let maxX = node?.getStage()?.attrs.width - nodeWidth;
			let maxY = node?.getStage()?.attrs.height - nodeHeight;
			if (pos.x > 0) newPos.x = pos.x;
			if (pos.x > maxX) newPos.x = maxX;
			if (pos.y > 0) newPos.y = pos.y;
			if (pos.y > maxY) newPos.y = maxY;
		}
		return newPos;
	};

	const handleTransform = () => {
		const node = textRef.current;
		if (node) {
			const scaleX = node.scaleX();
			const xPos = node.attrs.x;
			node.scaleX(1);
			onChange({
				...attrs,
				width: node.width() * scaleX,
				x: xPos
			});
		}
	};

	const handleDragEnd = () => {
		let tr = trRef.current;
		if (tr) {
			let x = tr?.getX();
			let y = tr?.getY();
			let width = textRef.current?.width()
			onChange({ ...attrs, x, y, width });
		}
		onSelect();
	};

	const enableEditor = () => {
		const node = textRef.current;
		const tr = trRef.current;
		if (node) {
			node.draggable(false);
			node.visible(false);
			tr?.resizeEnabled(false);
			// tr?.rotateEnabled(false);
		}
		isEditing.set(true);
	};

	const disableEditor = () => {
		const node = textRef.current;
		const tr = trRef.current;
		if (node) {
			node.draggable(true);
			node.visible(true);
			tr?.resizeEnabled(true);
			// tr?.rotateEnabled(true);
		}
		isEditing.set(false);
	};

	const handleEditor = () => {
		if (!isEditable) return;
		isEditing.set(!isEditing.get());
		const node = textRef.current;
		const input = inputRef.current;
		
		if (node) {
			if (isEditing.get()) {
				enableEditor();
			} else {
				disableEditor();
			}
			input?.setAttribute(
				"style",
				`position:absolute;
				top:${node.attrs.y - 2}px;
				left:${node.attrs.x}px;
				width:${node.attrs.width}px;
				height:${node.getHeight() + 15}px;
				color:${node.fill()};
				font-size:${node.fontSize()}px;
				font-family:${node.attrs.fontFamily || "Arial"};
				background-color:transparent;
				border:none;
				outline:none;
				text-align:${node.attrs.align};
				line-height: 1;
				overflow:hidden;`
			);
			if (input) {
				input.textContent = node.text();
				let rotation = node.rotation();
				let transform = "";
				if (rotation) {
					transform += "rotateZ(" + rotation + "deg)";
					input.style.transform = transform;
				}
				input.focus();
			}
		}
	};

	const handleTextEdit = (e: KeyboardEvent) => {
		const node = textRef.current;
		const input = inputRef.current;
		// Return
		if (node && input) {
			node.text(input.textContent || '');
			onChange({ ...attrs, text: input.textContent || '' });
		}
		if (e.keyCode === 13 && !e.shiftKey) {
			if (node && input) {
				node.text(input.textContent || '');
				onChange({ ...attrs, text: input.textContent || '' });
			}
			disableEditor();
		}
		// Esc
		if (e.keyCode === 27) {
			disableEditor();
		}
	};

	const handleBlur = () => {
		disableEditor();
	};

	// console.log(attrs);
	

	return (
		<>
			<Text
				draggable
				{...attrs}
				onMouseDown={onSelect}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={onSelect}
				onTap={onSelect}
				ref={textRef}
				onDragEnd={handleDragEnd}
				dragBoundFunc={handleCanvasBounds}
				onTransformEnd={onSelect}
				onTransform={handleTransform}
				onDblClick={handleEditor}
				onDblTap={handleEditor}
				name='konva_object'
			/>
			{isSelected && (
				<Transformer
					ref={trRef}
					rotateEnabled={false}
					enabledAnchors={["middle-left", "middle-right"]}
					boundBoxFunc={(oldBox, newBox) => {
						let min_width = 0;
						if (textRef.current) {
							min_width = textRef.current.fontSize();
						}
						if (newBox.width < min_width) {
							return oldBox;
						}
						return newBox;
					}}
				/>
			)}
			{isEditing.get() && (
				<Html >
					<div 
						contentEditable="true" 
						ref={inputRef} 
						onKeyUp={handleTextEdit} 
						onBlur={handleBlur}
						
					>
					</div>
				</Html>
			)}
		</>
	);
};

export default TextElement;

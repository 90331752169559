import { Box, ButtonProps, Icon, IconButton, Textarea, TextareaProps, useClipboard } from '@chakra-ui/react'
import React, { useState } from 'react'
import { FaCheckCircle, FaCopy } from 'react-icons/fa'

interface TextareaWithCopyProps extends TextareaProps {
    toCopy: string,
    copyButtonProps?: ButtonProps,
}

export const TextareaWithCopy = ( props:TextareaWithCopyProps ) => {
    const { toCopy, copyButtonProps, ...restProps } = props
    const [ valueToCopy ] = useState(toCopy)
    const { hasCopied, onCopy } = useClipboard(valueToCopy)
    return (
        <Box position='relative'>
            <Textarea 
                defaultValue={toCopy}
                {...restProps}
            />
            <IconButton
                position="absolute"
                top={1}
                size='sm'
                right={2}
                aria-label="Copy Value"
                icon={ hasCopied ? <Icon as={FaCheckCircle} color="teal" /> : <FaCopy /> }
                onClick={onCopy}
                {...copyButtonProps}
            />
        </Box>
    )
}

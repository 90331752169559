import React, { useRef, useEffect } from "react";
import { useState } from "@hookstate/core";
import { Stack, Skeleton } from "@chakra-ui/react";

import { Layer as KonvaLayer } from "konva/lib/Layer";
import { ElementsType } from "../../components/builder/DrawingSheet";

import ToolBox from "../../components/builder/ToolBox";
import ArtBoard from "../../components/builder/ArtBoard";
import PropsPane from "../../components/builder/PropsPane/PropsPane";

import { Config } from "../../components/builder/ArtBoard";

import styles from "../../styles/components/builder.module.css";
import { RouteComponentProps } from "react-router-dom";
import { getTemplateData } from "../../dataFetchers/templatesDataFetcher";

import "../../styles/fonts.css";
import NameEditor from "../../components/builder/PropsPane/NameEditor";
import { useConfig } from "../../hooks/useConfig";
import { DEFAULT_TEMPLATE_INITIAL_DATA } from "../../configs/GlobalConstants";

export const Builder: React.FC<RouteComponentProps> = ({ match }) => {
	const layerRef = useRef<KonvaLayer>(null);
	const selectedId = useState<string | null>(null);
	const artBoardConfig = useState<Config | null>(null);
	const isLoading = useState<boolean>(true);
	const isInitialDataUpdated = useRef(false)

	const { templateInitialElements, isLoading:isConfigLoading } = useConfig()
	let elementsSampleData = {}
	const elements = useState(elementsSampleData as ElementsType);

	useEffect(() => {
		if (isInitialDataUpdated.current === false) {
			if (isConfigLoading === false) {
				let elementsSampleDataLocal = {}
				Object.keys(templateInitialElements).map( key => {
					//@ts-ignore
					if (templateInitialElements[key].initialDisplay === true) {
						//@ts-ignore
						elementsSampleDataLocal[key] = {
							//@ts-ignore
							...DEFAULT_TEMPLATE_INITIAL_DATA[key],
							//@ts-ignore
							...templateInitialElements[key]
						}
						return true
					}
					return false
				})
				elements.set(elementsSampleDataLocal)
				isInitialDataUpdated.current = true
			}
		}
	}, [isConfigLoading, templateInitialElements, isInitialDataUpdated, elements])
	

	const fetchInitialData = async () => {
		// TODO: error handling
		try {
			if ("id" in match.params) {
				//@ts-ignore
				let { id } = match.params;
				const { isQueryError, queryError, queryData } = await getTemplateData(id);
				if (!isQueryError) {
					if (queryData) {
						if (queryData[0].config) artBoardConfig.set({ ...queryData[0].config, name: queryData[0].name || "" });
						if (queryData[0].name) artBoardConfig.merge({ name: queryData[0].name || "" });
						if (queryData[0].elements) elements.set(queryData[0].elements);
						isLoading.set(false);
					}
				} else console.log(queryError);
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (!isConfigLoading) {
			fetchInitialData();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isConfigLoading]);

	return (!isLoading.get() && !isConfigLoading) ? (
		<>
			<ToolBox elements={elements} selectedId={selectedId} config={artBoardConfig} />
			<div className={styles.board_container}>
				<div className={styles.artboard_container}>
					<div className={styles.artboard} id='artboard'>
						<ArtBoard config={artBoardConfig} elementsData={elements} selectedId={selectedId} layerRef={layerRef} />
					</div>
				</div>
				<div className={styles.propspane}>
					<NameEditor config={artBoardConfig} />
					<PropsPane elements={elements} selectedId={selectedId} layerRef={layerRef} />
				</div>
			</div>
		</>
	) : (
		<Stack>
			<Skeleton height='75vh' />
		</Stack>
	);
};

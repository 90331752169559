import { FormControl, FormErrorMessage, FormLabel, Input, Select, Stack, StackDivider, StackProps, Textarea, VStack } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react'
import { useAuth } from '../../contexts/Auth';
import * as yup from 'yup';
import { certificateTypeArray } from '../../dataObjects/globalDataObject';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FieldGroup } from '../fieldGroup/FieldGroup';
import { removeKeyFromObject } from '../../helpers/CommonFunctions';

export type CertificateFormInputTypes = {
    name: string;
    email: string;
    type: string;
}

interface certificateInputPropsType extends StackProps {
    actionOnSubmitSuccess?: (...args: any[]) => any
    loadingStatus?: (...args: any[]) => any
    formId?: string
    onSubmitFunction: ( arg:any ) => any
    initialData?: {[key:string]: any}
}

export const CreateCertificateForm = ( props:certificateInputPropsType ) => {
    const { actionOnSubmitSuccess, loadingStatus, formId, onSubmitFunction, initialData, ...restProps } = props
    const { user } = useAuth()
    const testAreaRef = useRef<HTMLTextAreaElement>(null)

    const schema = yup.object().shape({
        name: yup.string().required('name is required').min(4, "It looks very short"),
        email: yup.string().email().required(),
        type:yup.string().oneOf(certificateTypeArray),
    })

    const methods = useForm<CertificateFormInputTypes>({
        mode: 'onBlur',
        resolver: yupResolver(schema),
        defaultValues: initialData,
    })

    const { register, handleSubmit, formState: { errors, isSubmitting } } = methods

    useEffect( () => {
        if (loadingStatus) {
            loadingStatus( isSubmitting )
        }
    }, [loadingStatus, isSubmitting])    
    
    const handleSubmitButtonPress = async (values:CertificateFormInputTypes) => {

        const dataToUpdate = {
            name: values.name,
            email: values.email,
            type: values.type,
            extra_metadata: {
                ...( testAreaRef.current?.value && JSON.parse(testAreaRef.current?.value)),
                name: values.name,
                email: values.email,
                certificateType: values.type,
            },
            user_id: user?.id,
        }

        await onSubmitFunction(dataToUpdate)
        if (actionOnSubmitSuccess) {
            actionOnSubmitSuccess()
        }
    }

    return (
        <form
            id={formId}
            onSubmit={handleSubmit(handleSubmitButtonPress)}
        >
            <Stack spacing="4" divider={<StackDivider />} {...restProps}>
                <FieldGroup title="Certificate">
                    <VStack width="full" spacing="6">
                        <FormControl 
                            id="name"
                            isInvalid={!!errors?.name}
                            errortext={errors?.name?.message}
                            isRequired
                        >
                            <FormLabel>Name</FormLabel>
                            <Input type="text" maxLength={255} {...register('name')} />
                            <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="email"
                            isInvalid={!!errors?.email}
                            errortext={errors?.email?.message}
                            isRequired
                        >
                            <FormLabel>Email</FormLabel>
                            <Input type="text" maxLength={255} {...register('email')} />
                            <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="type"
                            isInvalid={!!errors?.type}
                            errortext={errors?.type?.message}
                            isRequired
                        >
                            <FormLabel>Type</FormLabel>
                            <Select placeholder="Event Type" {...register('type')} >
                                {
                                    certificateTypeArray.map((item) => {
                                        return (
                                            <option key={item} value={item}>{item}</option>
                                        )
                                    })
                                }
                            </Select>
                            <FormErrorMessage>{errors?.type?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="extra_metadata"
                            isRequired
                        >
                            <FormLabel>Extra Metadata</FormLabel>
                            <Textarea 
                                ref={testAreaRef}
                                defaultValue={JSON.stringify(removeKeyFromObject(initialData?.extra_metadata, ['name', 'email', 'certificateType']), undefined, 2)}
                            />
                        </FormControl>

                    </VStack>
                </FieldGroup>
            </Stack>
        </form>
    )
}

import { useColorModeValue } from '@chakra-ui/react'
import React, { forwardRef } from 'react'
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './PhoneInputWithDarkMode.css'

const PhoneInputWithDarkMode = forwardRef(( props:PhoneInputProps, ref) => {
    const phoneInputWrapperClassNameForHack = useColorModeValue("light", "dark")

    return (
        <div className={phoneInputWrapperClassNameForHack}>
            <PhoneInput
                inputProps={{
                    "style": {
                        "background": "inherit",
                        "width": "100%",
                        "height": "2.5rem",
                        "borderRadius": "0.375rem",
                        "borderColor": "inherit"
                    }
                }}
                // ref={ref}
                { ...props }
            >
            </PhoneInput>
        </div>
    )
})

export default PhoneInputWithDarkMode

import {
    Icon,
    InputLeftAddon,
    IconButton,
    Input,
    InputGroup,
    InputProps,
    InputRightElement,
    useDisclosure,
  } from '@chakra-ui/react'
  import * as React from 'react'
  import { HiEye, HiEyeOff } from 'react-icons/hi'
	import { useFormContext } from "react-hook-form"
import { FaLock } from 'react-icons/fa'
  
  export const PasswordField = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const { isOpen, onToggle } = useDisclosure()
    const inputRef = React.useRef<HTMLInputElement>(null)
		const methods = useFormContext()
  
    const onClickReveal = () => {
      onToggle()
      const input = inputRef.current
      if (input) {
        input.focus({ preventScroll: true })
        const length = input.value.length * 2
        requestAnimationFrame(() => {
          input.setSelectionRange(length, length)
        })
      }
    }
  
    return (
			<InputGroup>
        <InputLeftAddon 
          children={<Icon as={FaLock} />}
        />
        <Input
          type={isOpen ? 'text' : 'password'}
          autoComplete="current-password"
          required
          {...methods.register(props.name || 'password')}
          {...props}
          placeholder="**********"
        />
        <InputRightElement>
          <IconButton
              bg="transparent !important"
              variant="ghost"
              aria-label={isOpen ? 'Mask password' : 'Reveal password'}
              icon={isOpen ? <HiEyeOff /> : <HiEye />}
              onClick={onClickReveal}
          />
        </InputRightElement>
			</InputGroup>
    )
  })
  
  PasswordField.displayName = 'PasswordField'
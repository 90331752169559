import { Box, Flex, FlexProps, Text, useColorModeValue } from '@chakra-ui/react'
import React, { ReactElement, isValidElement } from 'react'

interface Props extends FlexProps {
  label: string
  value: string | ReactElement
}

export const CardProperty = (props: Props) => {
  const { label, value, ...flexProps } = props
  const bluredTextColor = useColorModeValue('gray.200', 'gray.600')
  return (
    <Flex
      as="dl"
      direction={{ base: 'column', sm: 'row' }}
      px="6"
      py="4"
    //   _even={{ bg: useColorModeValue('gray.50', 'gray.800') }}
      {...flexProps}
    >
      <Box as="dt" minWidth={{ sm: '130px', md: '170px' }}>
        {label}
      </Box>
      <Box 
        as="dd" 
        minW={{ sm: 'calc(100% - 130px)', md: 'calc(100% - 170px)' }} 
        flex="1" 
        fontWeight="semibold" 
        borderLeftWidth={{ base: undefined, sm: 2 }} 
        paddingLeft={{ base: undefined, sm: 8 }} 
      >
        {
          isValidElement(value)?
            value
          :
          <Text color={ value ? undefined : bluredTextColor }>
            {value ? value : '(Blank)'}
          </Text>
        }
      </Box>
    </Flex>
  )
}
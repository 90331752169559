// import moment from "moment";
import supabase from "../configs/Supabase";
import { displayError } from "../helpers/CommonFunctions";
import {
	templateDataObjectReturnType,
	templatesDataObjectType,
	updateTemplateDataObjectType,
	deleteTemplateDataObjectType,
} from "../types/dataFetcherTypes/TemplatesDataFetcherTypes";

export const setTemplatesData = async (updatedData: templatesDataObjectType): Promise<templateDataObjectReturnType> => {
	const updatedDataObject = { ...updatedData };

	let isError = false;
	const { data, error } = await supabase.from("templates").upsert(updatedDataObject, { onConflict: "id" });

	if (error) {
		isError = true;
		displayError("Action not allowed", error.message || error.details || error.hint);
	}

	return {
		isQueryError: isError,
		queryData: data as templatesDataObjectType[] | null,
		queryError: error,
	};
};

export const getTemplatesData = async (
	page: number = 1,
	size: number = 10,
	sortOnColumn: string = "created_at",
	sortOrder: string = "ascending", 
	serchText?:string|null, 
	searchOnColumn?:string|null 
): Promise<templateDataObjectReturnType> => {
	try {
		let isError = false;
		let queryCondition = supabase
								.from("templates")
								.select(
									`id,
									name,
									user_id,
									config,
									elements,
									created_at,
									updated_at`,
									{
										count: "exact",
									}
								)
								.eq("is_deleted", false)

		if (serchText && searchOnColumn) {
			queryCondition = queryCondition.ilike(searchOnColumn, `%${serchText}%`)
		}

		const { data, error, count } = await queryCondition
												.order(sortOnColumn, {
													ascending: sortOrder === "ascending",
												})
												.range((page - 1) * size, page * size - 1);
		if (error) {
			isError = true;
		}

		return {
			isQueryError: isError,
			queryData: data,
			queryError: error,
			querySize: count,
		};
	} catch (error) {
		throw error;
	}
};
export const getTemplateData = async (id: string): Promise<templateDataObjectReturnType> => {
	try {
		let isError = false;
		const { data, error, count } = await supabase
			.from("templates")
			.select(
				`id,
				name,
				config,
				elements,
				created_at,
				updated_at`,
				{
					count: "exact",
				}
			)
			.eq("is_deleted", false)
			.eq("id", id);

		if (error) {
			isError = true;
		}

		return {
			isQueryError: isError,
			queryData: data,
			queryError: error,
			querySize: count,
		};
	} catch (error) {
		throw error;
	}
};

export const deleteTemplate = async (values: deleteTemplateDataObjectType) => {
	const dataToUpdate = {
		is_deleted: true,
	};

	let isError = false;
	const { data, error } = await supabase.from("templates").update(dataToUpdate).eq("id", values.id).eq("user_id", values.user_id);

	if (error) {
		isError = true;
		displayError("Action not allowed", error.message || error.details || error.hint);
	}

	return {
		isQueryError: isError,
		queryData: data as templatesDataObjectType[] | null,
		queryError: error,
	};
};

export const updateTemplate = async (values: updateTemplateDataObjectType) => {
	const dataToUpdate = {
		...values,
		id: undefined,
	};

	let isError = false;
	const { data, error } = await supabase.from("templates").update(dataToUpdate).eq("id", values.id).eq("user_id", values.user_id);

	if (error) {
		isError = true;
		displayError("Action not allowed", error.message || error.details || error.hint);
	}

	return {
		isQueryError: isError,
		queryData: data as templatesDataObjectType[] | null,
		queryError: error,
	};
};

export const getSignedImageURL = async (img_url: string) => {
	// TODO : error handling
	const { error, signedURL } = await supabase.storage.from("template-images").createSignedUrl(img_url.replace("template-images/", ""), 3600);
	if (!error) return signedURL;
};

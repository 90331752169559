import supabase from "../configs/Supabase"
import { displayError, displaySuccess } from "../helpers/CommonFunctions"
import { smtpSettingsFetcherObjectReturnType, smtpSettingsType } from "../types/dataFetcherTypes/SmtpSettingsDataFetcherTypes"

export const getSmtpSettingsData = async (): Promise<smtpSettingsFetcherObjectReturnType> => {
    try {
        let isError = false
        const { data, error } = await supabase
                                .from( 'smtp_configs' )
                                .select( ` 
                                    id,
                                    name,
                                    email:smtp_admin_email, 
                                    host:smtp_host, 
                                    port:smtp_port, 
                                    user:smtp_user, 
                                    password:smtp_password, 
                                    senderName:smtp_sender_name,
                                    isDefault:is_default
                                `)
                                .order('id')
        if (error) {
            isError = true
        }

        return {
            isQueryError: isError,
            queryData: data as smtpSettingsType[] | null,
            queryError: error
        }
    } catch (error) {
        throw error;  
    }
}

export const insertSmtpSettingsData = async (updatedData:smtpSettingsType): Promise<smtpSettingsFetcherObjectReturnType> => {

    const updatedDataObject = {
        name: updatedData.name,
        is_default: updatedData.isDefault,
        smtp_admin_email: updatedData.email, 
        smtp_host: updatedData.host, 
        smtp_port: updatedData.port, 
        smtp_user: updatedData.user, 
        smtp_password: updatedData.password, 
        smtp_sender_name: updatedData.senderName, 
        user_id: updatedData.userId,
    }

    let isError = false
    const { data, error } = await supabase.from( 'smtp_configs' ).insert(updatedDataObject)

    if (error) {
        isError = true
        if (error.message === "duplicate key value violates unique constraint \"smtp_configs_user_id_name_key\"") {
            displayError( 'Action not allowed', "Settings name can not be duplicate")
        } else {
            displayError( 'Data Error', error.message || error.details || error.hint)
        }
    } else {
        displaySuccess('Saved', 'Data Saved successfully')
    }

    return {
        isQueryError: isError,
        queryData: data as smtpSettingsType[] | null,
        queryError: error
    }
}

export const updateSmtpSettingsData = async (updatedData:smtpSettingsType): Promise<smtpSettingsFetcherObjectReturnType> => {

    const updatedDataObject = {
        name: updatedData.name,
        is_default: updatedData.isDefault,
        smtp_admin_email: updatedData.email, 
        smtp_host: updatedData.host, 
        smtp_port: updatedData.port, 
        smtp_user: updatedData.user, 
        smtp_password: updatedData.password, 
        smtp_sender_name: updatedData.senderName, 
        user_id: updatedData.userId,
    }

    let isError = false
    const { data, error } = await supabase.from( 'smtp_configs' )
                                            .update(updatedDataObject)
                                            .match({
                                                id: updatedData.id
                                            })

    if (error) {
        isError = true
        if (error.message === "duplicate key value violates unique constraint \"smtp_configs_user_id_name_key\"") {
            displayError( 'Action not allowed', "Settings name can not be duplicate")
        } else {
            displayError( 'Data Error', error.message || error.details || error.hint)
        }
    } else {
        displaySuccess('Saved', 'Data Saved successfully')
    }

    return {
        isQueryError: isError,
        queryData: data as smtpSettingsType[] | null,
        queryError: error
    }
}
import { Box, Center, Divider, Heading, SimpleGrid } from "@chakra-ui/react";
import { useHookstate } from "@hookstate/core";
import React from "react";
import { useQuery } from "react-query";
import { StatCard, StatCardData } from "../../components/statCard/StatCard";
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE, DEFAULT_SORT_ORDER } from "../../configs/GlobalConstants";
import { getEventsData } from "../../dataFetchers/eventsDataFetcher";
import { getOrganisationData } from "../../dataFetchers/organisationDataFetcher";
import { getUserProfileData } from "../../dataFetchers/userProfileDataFetcher";
import { defaultAvatarsType } from "../../dataObjects/DefaultAvatarsObject";
import { useConfig } from "../../hooks/useConfig";
import { randomUserAvatar } from "../../stores/UserDataStore";

export const Home = () => {
	const { data: profileData } = useQuery("userProfile", getUserProfileData);
	const pageNo = DEFAULT_PAGE_NO;
	const pageSize = DEFAULT_PAGE_SIZE;
	const sortOnColumn = "updated_at";
	const sortOrder = DEFAULT_SORT_ORDER;
	const { data: organizationData, isFetching: isOrganizationFetching } = useQuery(
		["organizationData", pageNo, pageSize, sortOnColumn, sortOrder],
		() => getOrganisationData(pageNo, pageSize, sortOnColumn, sortOrder),
		{ keepPreviousData: true }
	);
	const { data: eventData, isFetching: iseventFetching } = useQuery(
		["eventsData", pageNo, pageSize, sortOnColumn, sortOrder],
		() => getEventsData(pageNo, pageSize, sortOnColumn, sortOrder),
		{ keepPreviousData: true }
	);
	const { maxOrganizationsAllowed, planType, maxEventsAllowed } = useConfig();
	const userAvatarObj = useHookstate<defaultAvatarsType>(randomUserAvatar);

    const data: StatCardData[] = [
        {
            id: 'organization',
            title: 'Organizations Created',
            value: organizationData?.querySize || 0,
            limit: maxOrganizationsAllowed || 1,
            isLoading: isOrganizationFetching,
        },
        {
            id: 'events',
            title: 'Events Created',
            value: eventData?.querySize || 0,
            limit: maxEventsAllowed || 10,
            isLoading: iseventFetching,
        },
        {
            id: 'plan_type',
            title: 'Plan Type',
            value: 0,
            limit: 100,
            formattedData: {
                value: planType,
                limit: ''
            }
        },
    ]

    return (
        <>
            <Heading my={8} ml={9} >
                {`Welcome ${profileData?.queryData?.full_name || userAvatarObj.displayName.get()}`}
            </Heading>
            <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ base: '6', md: '8' }}>
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: '2', md: '4', lg: '8' }}>
                    {data.map((stat) => (
                        <StatCard data={stat} key={stat.id} />
                    ))}
                </SimpleGrid>
            </Box>
            <Divider py="8" />
            <Heading size="md" my={8} ml={9} >
                Watch our demo video
            </Heading>
            <Center>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/iimhCXhK3is" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Center>
        </>
    )
}

import { Checkbox, CheckboxProps } from '@chakra-ui/react'
import React, { ChangeEvent, forwardRef, useImperativeHandle, useState } from 'react'

export interface CustomCheckboxPropsType extends CheckboxProps {
    renderAsChecked?: boolean,
    renderAsIndeterminate?: boolean
}

export interface CustomCheckboxType extends CheckboxProps {
    setChecked: (arg:boolean) => React.Dispatch<React.SetStateAction<boolean>>,
    setIndeterminate: (arg:boolean) => React.Dispatch<React.SetStateAction<boolean>>
}

export const CustomCheckbox = forwardRef((props:CustomCheckboxPropsType, ref) => {
    const { renderAsChecked = false, renderAsIndeterminate = false, onChange, ...restProps} = props
    const [isChecked, setIsChecked] = useState(renderAsChecked)
    const [isIndeterminate, setIsIndeterminate] = useState(renderAsIndeterminate)

    useImperativeHandle( ref, () => ({
        setChecked: setIsChecked,
        setIndeterminate: setIsIndeterminate
    }))

    const handleOnChangeEvent = (ev:ChangeEvent<HTMLInputElement>) => {
        setIsChecked(ev.target.checked)
        if (onChange) {
            onChange(ev)
        }
    }

    return (
        <Checkbox 
            isChecked={isChecked}
            isIndeterminate={isIndeterminate}
            onChange={handleOnChangeEvent}
            {...restProps} 
        />
    )
})

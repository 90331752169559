import { Flex, FlexProps, FormControl, FormErrorMessage, FormHelperText, FormLabel, Icon, Input, InputGroup, InputLeftAddon, Select, Stack, StackDivider, Textarea, Tooltip, VStack } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { FieldGroup } from "../fieldGroup/FieldGroup"
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { organizationSize, organizationType } from "../../dataObjects/globalDataObject"
import moment from "moment"
import { EMAIL_REG_EXP, ORGANIZATION_MAX_ESTABLISHMENT_DATE_MESSAGE, ORGANIZATION_MIN_ESTABLISHMENT_DATE_MESSAGE, PHONE_REG_EXP } from "../../configs/GlobalConstants"
import { FaGlobeAsia, FaGlobeEurope } from "react-icons/fa"
import { countryStateDataObject } from "../../dataObjects/countryStatesDataObject"
import PhoneInputWithDarkMode from "../phoneInputWithDarkMode/PhoneInputWithDarkMode"
import { useAuth } from "../../contexts/Auth"
import { PictureUploader } from "../pictureUploader/PictureUploader"
import { getUniqueId } from "../../helpers/CommonFunctions"
import { VscQuestion } from "react-icons/vsc"

export interface OrganizationFormInputType {
    name: string, 
    type: string,
    about: string,
    establishment_date: Date
    size: string, 
    country: string,
    state: string,
    address_line_one: string,
    address_line_two: string,
    city: string,
    pin_code: number,
    mobile: string,
    email: string,
    website: string,
    linkedin: string, 
    facebook: string, 
    twitter: string, 
    instagram: string, 
    reply_to_email: string,
}

interface organizationInputPropsType extends FlexProps {
    actionOnSubmitSuccess?: (...args: any[]) => any
    loadingStatus?: (...args: any[]) => any
    formId?: string
    onSubmitFunction: ( arg:any ) => any
    initialData?: {[key:string]: any}
}

export const OrganizationForm = ( props:organizationInputPropsType ) => {

    const { actionOnSubmitSuccess, formId, loadingStatus, onSubmitFunction, initialData } = props

    const [selectedCountry, setselectedCountry] = useState(initialData?.country || '')
    const [logoUrl, setLogoUrl] = useState(initialData?.logo_url || '')
    
    const { user } = useAuth()

    const schema = yup.object().shape({
        name: yup.string().min(3).required(), 
        type: yup.string().oneOf(organizationType),
        about: yup.string().transform((v,q) => ( (q === '') ? null:v )).min(50).nullable(),
        establishment_date: yup.date()
                                .nullable()
                                .default( new Date() )
                                .max( moment().format('YYYY-MM-DD').toString(), ORGANIZATION_MAX_ESTABLISHMENT_DATE_MESSAGE )
                                .min( moment().subtract( 100, 'years' ).format('YYYY-MM-DD').toString(), ORGANIZATION_MIN_ESTABLISHMENT_DATE_MESSAGE ),
        size: yup.string().oneOf(organizationSize), 
        country: yup.string().nullable(),
        state: yup.string().nullable(),
        address_line_one: yup.string().optional(),
        address_line_two: yup.string().nullable(),
        city: yup.string().transform((v,q) => ( (q === '') ? null: v )).min(3).optional().notRequired().nullable(),
        pin_code: yup.number().transform((v, q) => ( (q === '') ? null: v )).typeError('Pin Code must be a number').max(9999999, 'Pin Code should be of max 7 digits').min(1000, 'Pin Code should be of min 4 digit').optional().notRequired().nullable(),
        mobile: yup.string().matches(PHONE_REG_EXP, 'Phone no must be valid'),
        email: yup.string().matches(EMAIL_REG_EXP, 'Email must be valid'),
        website: yup.string().url(),
        linkedin: yup.string().url(), 
        facebook: yup.string().url(), 
        twitter: yup.string().url(), 
        instagram: yup.string().url(), 
        reply_to_email: yup.string().email().optional().nullable(),
    })

    const methods = useForm<OrganizationFormInputType>({
		mode: 'onBlur',
		resolver: yupResolver(schema),
        defaultValues: initialData
	})

	const { register, handleSubmit, control, formState: { errors, isSubmitting } } = methods

    useEffect( () => {
        if (loadingStatus) {
            loadingStatus( isSubmitting )
        }
    }, [loadingStatus, isSubmitting])

    const handleSubmitButtonPress = async (values:OrganizationFormInputType) => {
        const countrySelect = document.getElementById('country') as HTMLSelectElement
        const address = {
            address_line_one: values.address_line_one,
            address_line_two: values.address_line_two,
            city: values.city,
            state: values.state,
            country: values.country || countrySelect?.options[countrySelect?.selectedIndex].value,
            pin_code: values.pin_code
        }
        
        const dataToUpdate = {
            user_id: user?.id,
            name: values.name,
            type: values.type,
            about: values.about,
            establishment_date: values.establishment_date,
            logo_url: logoUrl,
            size: values.size,
            address: address,
            mobile: values.mobile,
            email: values.email,
            website: values.website,
            linkedin: values.linkedin,
            facebook: values.facebook,
            twitter: values.twitter,
            instagram: values.instagram,
            reply_to_email: values.reply_to_email,
        }
        
        await onSubmitFunction(dataToUpdate)
        if (actionOnSubmitSuccess) {
            actionOnSubmitSuccess()
        }
    }

    return (
        <form
            id={formId}
            onSubmit={handleSubmit(handleSubmitButtonPress)}
        >
            <Stack spacing="4" divider={<StackDivider />}>
                <FieldGroup title="Basic Info">
                    <VStack width="full" spacing="6">
                        <FormControl 
                            id="name"
                            isInvalid={!!errors?.name}
                            errortext={errors?.name?.message}
                            isRequired
                        >
                            <FormLabel>Name</FormLabel>
                            <Input type="text" maxLength={255} {...register('name')} />
                            <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="type"
                            isInvalid={!!errors?.type}
                            errortext={errors?.type?.message}
                            isRequired
                        >
                            <FormLabel>Type</FormLabel>
                            <Select placeholder="Organization Type" {...register('type')} >
                                {
                                    organizationType.map((organizationTypeObj) => {
                                        return (
                                            <option key={organizationTypeObj} value={organizationTypeObj}>{organizationTypeObj}</option>
                                        )
                                    })
                                }
                            </Select>
                            <FormErrorMessage>{errors?.type?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="about"
                            isInvalid={!!errors?.about}
                            errortext={errors?.about?.message}
                            isRequired
                        >
                            <FormLabel>About</FormLabel>
                            <Textarea 
                                type="text" 
                                autoComplete="about" 
                                maxLength={255}
                                rows={5}
                                {...register('about')} 
                            />
                            <FormErrorMessage>{errors?.about?.message}</FormErrorMessage>
                            <FormHelperText>
                                Some words about your organization
                            </FormHelperText>
                        </FormControl>

                        <FormControl 
                            id="establishment_date"
                            isInvalid={!!errors?.establishment_date}
                            errortext={errors?.establishment_date?.message}
                            isRequired
                        >
                            <FormLabel>Establishment Date</FormLabel>
                            <Input 
                                type="date" 
                                defaultValue={moment().format('YYYY-MM-DD')}
                                autoComplete="establishment_date" 
                                {...register('establishment_date')} 
                            />
                            <FormErrorMessage>{errors?.establishment_date?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="size"
                            isInvalid={!!errors?.size}
                            errortext={errors?.size?.message}
                            isRequired
                        >
                            <FormLabel>Size</FormLabel>
                            <Select placeholder="Organization Size" {...register('size')} >
                                {
                                    organizationSize.map((organizationSizeObj) => {
                                        return (
                                            <option key={organizationSizeObj} value={organizationSizeObj}>{organizationSizeObj}</option>
                                        )
                                    })
                                }
                            </Select>
                            <FormErrorMessage>{errors?.size?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl>
                            <FormLabel>Logo</FormLabel>
                            <PictureUploader 
                                bucketName='public' 
                                path={`logos/${user?.id}/organizations`}
                                pictureId={ initialData?.organization_id || getUniqueId( 'high' ) } 
                                fieldId="organizationLogoUploader" 
                                initialPicURL={logoUrl}
                                pictureName={initialData?.name}
                                updateUrlFunction={setLogoUrl}
                            />
                        </FormControl>

                    </VStack>
                </FieldGroup>

                <FieldGroup title="Address">
                    <VStack width="full" spacing="6">
                    <FormControl 
                            id="country"
                            isInvalid={!!errors?.country}
                            errortext={errors?.country?.message}
                        >
                            <FormLabel>Country</FormLabel>
                            <InputGroup >
                                <InputLeftAddon children={<FaGlobeEurope />} />
                                <Select placeholder="Select country" {...register('country')} borderLeftRadius="0" onChange={(val)=> setselectedCountry(val.target.value)}>
                                    {
                                        countryStateDataObject.map((countryObj) => {
                                            return (
                                                <option key={countryObj.name} value={countryObj.name}>{countryObj.name}</option>
                                            )
                                        })
                                    }
                                </Select>
                            </InputGroup>
                            <FormErrorMessage>{errors?.country?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="state"
                            isInvalid={!!errors?.state}
                            errortext={errors?.state?.message}
                        >
                            <FormLabel>State</FormLabel>
                            <InputGroup >
                                <InputLeftAddon children={<FaGlobeAsia />} />
                                <Select placeholder="Select state" {...register('state')} borderLeftRadius="0" >
                                    {
                                        countryStateDataObject.find( name => name?.name === selectedCountry )?.states.map((countryObj) => {
                                            return (
                                                <option key={countryObj.name} value={countryObj.name}>{countryObj.name}</option>
                                            )
                                        })
                                    }
                                </Select>
                            </InputGroup>
                            <FormErrorMessage>{errors?.state?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="address_line_one"
                            isInvalid={!!errors?.address_line_one}
                            errortext={errors?.address_line_one?.message}
                            isRequired
                        >
                            <FormLabel>Shop no./Building name</FormLabel>
                            <Input type="text" maxLength={255} {...register('address_line_one')} />
                            <FormErrorMessage>{errors?.address_line_one?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="address_line_two"
                            isInvalid={!!errors?.address_line_two}
                            errortext={errors?.address_line_two?.message}
                            isRequired
                        >
                            <FormLabel>Street Name</FormLabel>
                            <Input type="text" maxLength={255} {...register('address_line_two')} />
                            <FormErrorMessage>{errors?.address_line_two?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="city"
                            isInvalid={!!errors?.city}
                            errortext={errors?.city?.message}
                            isRequired
                        >
                            <FormLabel>City</FormLabel>
                            <Input type="text" maxLength={255} {...register('city')} />
                            <FormErrorMessage>{errors?.city?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="pin_code"
                            isInvalid={!!errors?.pin_code}
                            errortext={errors?.pin_code?.message}
                            isRequired
                        >
                            <FormLabel>Pin Code</FormLabel>
                            <Input type="text" maxLength={255} {...register('pin_code')} />
                            <FormErrorMessage>{errors?.pin_code?.message}</FormErrorMessage>
                        </FormControl>
                    </VStack>
                </FieldGroup>

                <FieldGroup title="Contact Info">
                    <VStack width="full" spacing="6">
                        <FormControl 
                            id="mobile"
                            isInvalid={!!errors?.mobile}
                            errortext={errors?.mobile?.message}
                            isRequired
                        >
                            <FormLabel>Mobile</FormLabel>
                            <Controller
                                control={control}
                                name="mobile"
                                render={ ({ field }) => (
                                    <PhoneInputWithDarkMode
                                        { ...field }
                                        country={'in'}
                                        placeholder="Where should we contect you?" 
                                        onChange={(v) => field.onChange(v)} 
                                    />
                                )}
                            />
                            <FormErrorMessage>{errors?.mobile?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="email"
                            isInvalid={!!errors?.email}
                            errortext={errors?.email?.message}
                            isRequired
                        >
                            <FormLabel>Email</FormLabel>
                            <Input type="email" maxLength={255} {...register('email')} />
                            <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="website"
                            isInvalid={!!errors?.website}
                            errortext={errors?.website?.message}
                        >
                            <FormLabel>Website</FormLabel>
                            <Input type="url" maxLength={255} {...register('website')} />
                            <FormErrorMessage>{errors?.website?.message}</FormErrorMessage>
                        </FormControl>

                    </VStack>
                </FieldGroup>

                <FieldGroup title="Social">
                    <VStack width="full" spacing="6">
                        
                        <FormControl 
                            id="linkedin"
                            isInvalid={!!errors?.linkedin}
                            errortext={errors?.linkedin?.message}
                        >
                            <FormLabel>Linkedin Id</FormLabel>
                            <Input type="url" maxLength={255} {...register('linkedin')} />
                            <FormErrorMessage>{errors?.linkedin?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="facebook"
                            isInvalid={!!errors?.facebook}
                            errortext={errors?.facebook?.message}
                        >
                            <FormLabel>facebook</FormLabel>
                            <Input type="url" maxLength={255} {...register('facebook')} />
                            <FormErrorMessage>{errors?.facebook?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="twitter"
                            isInvalid={!!errors?.twitter}
                            errortext={errors?.twitter?.message}
                        >
                            <FormLabel>twitter</FormLabel>
                            <Input type="url" maxLength={255} {...register('twitter')} />
                            <FormErrorMessage>{errors?.twitter?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="instagram"
                            isInvalid={!!errors?.instagram}
                            errortext={errors?.instagram?.message}
                        >
                            <FormLabel>instagram</FormLabel>
                            <Input type="url" maxLength={255} {...register('instagram')} />
                            <FormErrorMessage>{errors?.instagram?.message}</FormErrorMessage>
                        </FormControl>

                    </VStack>
                </FieldGroup>

                <FieldGroup title="Communication">
                    <VStack width="full" spacing="6"> 
                        <FormControl 
                            id="reply_to_email"
                            isInvalid={!!errors?.reply_to_email}
                            errortext={errors?.reply_to_email?.message}
                        >
                            <Flex alignItems='baseline'>
                                <FormLabel>Reply To Email</FormLabel>
                                <Tooltip label='This email will be used if your certificate recipients choose to reply on their email' placement="auto">
                                    <span>
                                        <Icon as={ VscQuestion } />
                                    </span>
                                </Tooltip>
                            </Flex>
                            <Input 
                                type="email" 
                                maxLength={255} 
                                defaultValue={user?.email}
                                {...register('reply_to_email')}
                            />
                            <FormErrorMessage>{errors?.reply_to_email?.message}</FormErrorMessage>
                        </FormControl>
                    </VStack>
                </FieldGroup>
            </Stack>
        </form>
    )
}
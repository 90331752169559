import { Img } from '@chakra-ui/image'
import { Center, Text, VStack } from '@chakra-ui/layout'
import { Heading } from '@chakra-ui/react'
import React from 'react'

export const ComingSoon = () => {
    return (
        <Center
            w="100%"
            h="100%"
        >
            <VStack>
                <Heading>
                    Coming Soon
                </Heading>
                <Img
                    src="https://www.hubdo.com/hs-fs/hubfs/training/images/gifs/www.cutecatgifs.com-soon.gif?width=500&name=www.cutecatgifs.com-soon.gif"
                />
                <Text>
                    Thank you for your patience, We are building something awesome
                </Text>
            </VStack>
        </Center>
    )
}

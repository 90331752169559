import React from 'react'
import { Header } from '../../components/header/Header'
import { Main } from '../../components/main/Main'
import { Sidebar } from '../../components/sidebar/Sidebar'

export default function Dashboard() {
    return (
        <>
            <Header />
            <Sidebar />
            <Main />
        </>
    )
}

import { Box, Text, Button, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Skeleton, Stack, useDisclosure, HStack, Flex, Spacer, Select, CheckboxGroup, Checkbox, VStack, Heading, Divider, RadioGroup, Radio, Collapse, Input } from '@chakra-ui/react'
import moment from 'moment'
import React, { ChangeEvent } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleDown, FaAngleLeft, FaAngleRight, FaAngleUp, FaCopy, FaEdit, FaEye, FaPlus, FaRedo, FaStar, FaTimes } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { Card } from '../../components/card/Card'
import { drawerComponentRefFunctionType, DrawerComponentWithRef } from '../../components/drawerComponent/DrawerComponent'
import { ErrorComponent } from '../../components/errorComponent/ErrorComponent'
import ResponsiveTable from '../../components/responsiveTable/ResponsiveTable'
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE } from '../../configs/GlobalConstants'
import { paginationPageSizeArray } from '../../dataObjects/globalDataObject'
import { displayError, displaySuccess, recreateListWithDesiredOrder, removeKeyFromObject } from '../../helpers/CommonFunctions'
import { useConfig } from '../../hooks/useConfig'
import { StringOrNumber } from "@chakra-ui/utils"
import { HiPencilAlt } from 'react-icons/hi'
import { CardWithColorAccent } from '../../components/cardWithColorAccent/CardWithColorAccent'
import { Persistence } from '@hookstate/persistence'
import { Untracked } from '@hookstate/untracked';
import { useHookState } from '../../hooks/useHookState'
import { ModalComponentWithRef } from '../../components/modalComponent/ModalComponent'
import { useAuth } from '../../contexts/Auth'
import { CreateEventForm } from '../../components/createEventForm/CreateEventForm'
import { deleteEvent, getEventsData, setEventsData, updateEvent } from '../../dataFetchers/eventsDataFetcher'
import { eventsDataObjectType } from '../../types/dataFetcherTypes/EventsDataFetcherTypes'
import ShowEvent from '../../components/showEvent/ShowEvent'

function useLocationQuery() {
    return new URLSearchParams(useLocation().search);
}

export const Events = () => {
    const locationQuery = useLocationQuery()
    const history = useHistory()

    const [pageNo, setPageNo] = useState( parseInt(locationQuery.get('page') || DEFAULT_PAGE_NO.toString() ) )
    const [pageSize, setPageSize, rawPageSize] = useHookState( parseInt(locationQuery.get('size') || localStorage.getItem('global-page-size') || DEFAULT_PAGE_SIZE.toString() ) )  
    rawPageSize.attach(Persistence('global-page-size'))
    const [isSaveEventLoading, setIsSaveEventLoading] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [serchText, setSerchText] = useState<string|null>(null)
    const [searchOnColumn, setSearchOnColumn] = useState('name')
    const { isOpen:isAdvanceMenuOpen, onToggle:onAdvanceMenuToggle } = useDisclosure()
    const timeoutHolderForSearch = useRef<any>(null)
    const newTemplateNameRef = useRef<HTMLInputElement>(null)

    const createEventDwawerRef = useRef<drawerComponentRefFunctionType>()
    const editEventDwawerRef = useRef<drawerComponentRefFunctionType>()

    const { maxEventsAllowed, planType } = useConfig()
    const { user } = useAuth()    

    const availableColumns = [
        "id",
        "name",
        "type",
        "hashtags",
        "about",
        "start_date",
        "end_date",
        "address_type",
        "address",
        "inserted_at",
        "updated_at",
        "controls",
    ]

    const defaultDisplayColumns = [
        "name",
        "type",
        "start_date",
        "controls",
    ]

    const availableColumnsForSearch = [
        "name",
        "type",
        "about",
    ]

    const columnToDisplayValMapping:{ [key: string]: string } = {
        id: "Event Id",
        name: "Name",
        type: "Type",
        hashtags: "Event Hashtags",
        about: "About",
        start_date: "Start Date",
        end_date: "End Date",
        address_type: "Location Type",
        address: "Address",
        controls: "Controls",
        inserted_at: "Created On",
        updated_at: "Updated On"
    }

    const [columnsToDisplay, setColumnsToDisplay, rawColumnsToDisplay]  = useHookState<StringOrNumber[]>(defaultDisplayColumns)
    const [sortOnColumn, setSortOnColumn, rawSortOnColumn] = useHookState('updated_at')
    const [sortOrder, setSortOrder, rawSortOrder] = useHookState('descending')
    const [itemToUpdate, setItemToUpdate, rawItemToUpdate] = useHookState<eventsDataObjectType>({})

    rawColumnsToDisplay.attach(Persistence('event-columns-to-display'))
    rawSortOnColumn.attach(Persistence('event-sort-on-column'))
    rawSortOrder.attach(Persistence('event-sort-order'))
    rawItemToUpdate.attach(Untracked)
    
    const queryClient = useQueryClient()
    const { isError, isLoading, data, error, isFetching } = useQuery(['eventsData', pageNo, pageSize, sortOnColumn, sortOrder, serchText, searchOnColumn ], () => getEventsData( pageNo, pageSize, sortOnColumn, sortOrder, serchText, searchOnColumn ), { keepPreviousData : true })
    
    const createMutation = useMutation(setEventsData, {
        onSuccess: data => {
            if ( !data.isQueryError ) {
                queryClient.invalidateQueries('eventsData')
                displaySuccess('Event Created', 'We have created your event')
                createEventDwawerRef?.current?.onClose()
            }
        }
    })

    const deleteMutation = useMutation(deleteEvent, {
        onSuccess: data => {
            if ( !data.isQueryError ) {
                queryClient.invalidateQueries('eventsData')
                displaySuccess('Event Deleted', 'Event is deleted')
            }
        }
    })

    const updateMutation = useMutation(updateEvent, {
        onSuccess: data => {
            if ( !data.isQueryError ) {
                queryClient.invalidateQueries('eventsData')
                displaySuccess('Event Updated', 'Event is updated successfully')
            }
        }
    })

    useEffect( () => {
        const params = new URLSearchParams()
        if (pageNo) {
            params.append("page", pageNo.toString() )
        } else {
            params.delete("page")
        }
        if (pageSize) {
            params.append("size", pageSize.toString() )
        } else {
            params.delete("size")
        }
        if ( locationQuery.get('page') || (pageNo !== DEFAULT_PAGE_NO) || (pageSize !== DEFAULT_PAGE_SIZE) ) {
            history.replace({search: params.toString()})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ pageNo, pageSize, history ])

    const onSubmitFunction = async ( value:any ) => {
        return await createMutation.mutateAsync(value)
    }

    const copyEvent = async ( item:any ) => {
        await createMutation.mutateAsync({
			...removeKeyFromObject(item, ['id', 'inserted_at', 'updated_at']),
			name: newTemplateNameRef.current?.value || item.name + ' - Copy'
		})
    }

    const createDataForTable = (data:eventsDataObjectType[]) => {
        let returnObject = {
            keys: columnsToDisplay,
            header: columnToDisplayValMapping,
            body: [] as object[]
        }
        
        data.map( (item:eventsDataObjectType) => {
            returnObject.body.push({
                keyId: item.id,
                id: item.id,
                name: item.name,
                type: item.type,
                hashtags: item.hashtags?.map( (item:string) => ( '#'+item )).join(' '),
                about: item.about,
                start_date: <Text minW="max-content">
                                { moment(item.start_date).local().format('MMM DD, YYYY') }
                            </Text>,
                end_date: <Text minW="max-content">
                                { moment(item.end_date).local().format('MMM DD, YYYY') }
                            </Text>,
                address_type: item.address_type,
                address: <Stack>
                        <Text>
                            {`${item.address?.address_line_one}, ${item.address?.address_line_two}`}
                        </Text>
                        <Text>
                            {`${item.address?.city}, ${item.address?.state}, ${item.address?.country} ${item.address?.pin_code}`}
                        </Text>
                    </Stack>,
                inserted_at: <Stack>
                        <Text minW="max-content">
                            { moment(item.created_at).local().format('MMM DD, YYYY HH:mm') }
                        </Text>
                    </Stack>,
                updated_at: <Stack>
                        <Text minW="max-content">
                            { moment(item.updated_at).local().format('MMM DD, YYYY HH:mm') }
                        </Text>
                    </Stack>,
                controls: <HStack>
                                <ModalComponentWithRef
                                    triggerComponent={<IconButton size='xs' variant='outline' colorScheme='blue' icon={<FaCopy />} aria-label='Copy Event' />}
                                    heading='Copy Template'
                                    showFooter={true}
                                    footerButtons={
                                        <Button
                                            colorScheme='blue'
                                            onClick={async () => {
                                                await copyEvent(item)
                                            }}>
                                            Copy
                                        </Button>
                                    }
                                >
                                    <Input ref={newTemplateNameRef} type='text' placeholder="Template Name" defaultValue={item.name + ' - Copy'} />
                                </ModalComponentWithRef>

                                <DrawerComponentWithRef
                                    size="lg"
                                    triggerComponent={
                                        <IconButton
                                            size="xs"
                                            variant="outline"
                                            colorScheme="teal"
                                            icon={<FaEye />}
                                            aria-label="Show Preview"
                                        />
                                    }
                                    heading="Details"
                                >
                                    <ShowEvent 
                                        displayOrder={availableColumns}
                                        keysAndDisplayNameMapping={columnToDisplayValMapping}
                                        displayObject={item}
                                    />
                                </DrawerComponentWithRef>

                                <IconButton
                                    size="xs"
                                    variant="outline"
                                    colorScheme="teal"
                                    icon={<FaEdit />}
                                    aria-label="Edit Event"
                                    onClick={ () => {
                                        setItemToUpdate(item)
                                        editEventDwawerRef.current?.onOpen()
                                    }}
                                />
                                
                                <ModalComponentWithRef 
                                    triggerComponent={
                                        <IconButton
                                            size="xs"
                                            variant="outline"
                                            colorScheme="red"
                                            icon={<FaTimes />}
                                            aria-label="Delete Event"
                                        />
                                    }
                                    heading="Delete"
                                    showFooter={true}
                                    footerButtons={
                                        (planType === 'free')?
                                        <Button colorScheme="blue">View Plans</Button>
                                        :
                                        <Button 
                                            colorScheme="blue"
                                            onClick={ async () => {
                                                return await deleteMutation.mutateAsync({
                                                    id: item.id!,
                                                    user_id: user?.id!
                                                })
                                            }}
                                        >
                                            Confirm
                                        </Button>
                                    }
                                >
                                    {
                                        (planType === 'free')?
                                        <Text>
                                            Sorry this feature is only available in premium plan, Please upgrade
                                        </Text>
                                        :
                                        <Text>
                                            Please be informed that this action is irreversible, You won't be able to recover it once deleted. However, Don't worry as all the certificates issued in the past using this event will retain it's value. 
                                        </Text>
                                    }
                                </ModalComponentWithRef>
                    </HStack>
            })
            return true
        })

        return returnObject
    }

    const setDebouncedSerchText = (eve:ChangeEvent<HTMLInputElement>) => {
        const searchFieldValue = eve.currentTarget.value
        if (timeoutHolderForSearch.current !== null ) {
            clearTimeout(timeoutHolderForSearch.current)
        }
        timeoutHolderForSearch.current = setTimeout(() => {
            setSerchText(searchFieldValue)
        }, 300)
    }

    if ( data ) {
        const { queryData, queryError, querySize } = data

        if ( queryError ) {
            displayError( 'Action not allowed', queryError.message || queryError.details || queryError.hint)
        }

        if ( queryData ) {
            const lastPage = (Math.ceil(querySize!/pageSize) > 0) ? Math.ceil(querySize!/pageSize) : 1
            return (
                <>
                    {
                        (querySize! < maxEventsAllowed ) ?
                            <DrawerComponentWithRef
                                triggerComponent={
                                    <IconButton
                                        size="lg"
                                        fontSize="lg"
                                        colorScheme="blue"
                                        ml="auto"
                                        borderRadius="50%"
                                        m={2}
                                        icon={<FaPlus />}
                                        aria-label="Create new Event"
                                        position="fixed"
                                        right={{
                                            base: 2,
                                            md: 10
                                        }}
                                    />
                                }
                                heading="Create new Event"
                                size="lg"
                                showFooter={ true }
                                footerButtons={
                                    <Button 
                                        colorScheme="blue"
                                        type="submit"
                                        form="createNewEventForm"
                                        isLoading={ isSaveEventLoading }
                                        loadingText="Processing"
                                    >
                                        Save
                                    </Button>
                                }
                                ref={createEventDwawerRef}
                            >
                                <CreateEventForm 
                                    formId="createNewEventForm"
                                    loadingStatus={setIsSaveEventLoading}
                                    actionOnSubmitSuccess={ () => {
                                        //Write your code that you want to execute after submit
                                    }}
                                    onSubmitFunction={onSubmitFunction}
                                />
                            </DrawerComponentWithRef>
                        :
                            <>
                                <IconButton
                                    size="lg"
                                    fontSize="lg"
                                    colorScheme="yellow"
                                    ml="auto"
                                    onClick={onOpen}
                                    borderRadius="50%"
                                    m={2}
                                    icon={<FaStar />}
                                    aria-label="Create new Organisation"
                                    position="fixed"
                                    right={{
                                        base: 2,
                                        md: 10
                                    }}
                                />
                                <Modal isOpen={isOpen} onClose={onClose}>
                                    <ModalOverlay />
                                    <ModalContent>
                                        <ModalHeader>Quota Exceed</ModalHeader>
                                        <ModalCloseButton />
                                        <ModalBody>
                                            Quota for your current plan exceed. Please upgrade your plan
                                        </ModalBody>

                                        <ModalFooter>
                                            <Button  variant="ghost" mr={3} onClick={onClose}>
                                                Close
                                            </Button>
                                            <Button colorScheme="blue">View Plans</Button>
                                        </ModalFooter>
                                    </ModalContent>
                                </Modal>
                            </>
                    }
                    <Box h={8} />
                    <Card>
                        <Flex
                            borderBottomWidth={1}
                            direction='column'
                            alignItems='flex-start'
                        >
                            <Flex alignItems="center" w='100%' mb={4}>
                                <Heading as="h2" fontSize="lg">
                                    Events
                                </Heading>
                                <Spacer />
                                <Button
                                    leftIcon={<FaRedo />}
                                    mr={3}
                                    variant="outline"
                                    onClick={ () => {
                                        queryClient.invalidateQueries(['eventsData', pageNo, pageSize, sortOnColumn, sortOrder])
                                    }}
                                    display={["none", "inherit", null, null]}
                                    isLoading={isFetching}
                                >
                                    Refresh
                                </Button>
                                <IconButton 
                                    aria-label="Refresh Events Data"
                                    onClick={ () => {
                                        queryClient.invalidateQueries(['eventsData', pageNo, pageSize, sortOnColumn, sortOrder])
                                    }}
                                    icon={<FaRedo />}
                                    mr={3}
                                    variant="outline"
                                    display={["inherit", "none", null, null]}
                                    isLoading={isFetching}
                                />
                                <Button
                                    aria-label='Open Advance Options'
                                    rightIcon={isAdvanceMenuOpen ? <FaAngleUp /> : <FaAngleDown /> }
                                    leftIcon={<FaEdit />}
                                    onClick={onAdvanceMenuToggle}
                                    variant='outline'
                                >
                                    Advance
                                </Button>
                            </Flex>
                            <Collapse 
                                style={{
                                    width: '100%',
                                }} 
                                in={isAdvanceMenuOpen} 
                                animateOpacity
                            >
                                <Flex w='100%' mb={4} direction={['column', null, 'row', null]} >
                                    <Flex alignItems='center'>
                                        <Text pr='2'>
                                            Filter:
                                        </Text>
                                        <Input
                                            type='search'
                                            mr={3}
                                            placeholder={columnToDisplayValMapping[searchOnColumn]}
                                            onChange={setDebouncedSerchText}
                                        />
                                        <Select
                                            defaultValue={searchOnColumn}
                                            onChange={(val) => {
                                                setSearchOnColumn(val.target.value)
                                            }}
                                        >
                                            {
                                                availableColumnsForSearch.map((columnKey) => (
                                                    <option key={columnKey} value={columnKey}> { columnToDisplayValMapping[columnKey] } </option>
                                                ))
                                            }
                                        </Select>
                                    </Flex>
                                    <Spacer />
                                    <Flex pt={['2', null, '0', null]}>
                                        <Spacer />
                                        <DrawerComponentWithRef
                                            heading="Table Properties"
                                            triggerComponent={
                                                <Button variant="outline" minW="20" leftIcon={<HiPencilAlt />} >
                                                    Edit
                                                </Button>
                                            }
                                            showFooter={true}
                                        >
                                            <CardWithColorAccent>
                                                <Heading size="sm" mb="4">
                                                    Columns To display
                                                </Heading>
                                                <Divider mb="6" />
                                                <CheckboxGroup 
                                                    colorScheme="blue"
                                                    defaultValue={columnsToDisplay}
                                                    onChange={ newVal => setColumnsToDisplay(recreateListWithDesiredOrder(availableColumns, newVal)) }
                                                >
                                                    <VStack alignItems="flex-start">
                                                        {
                                                            availableColumns.map((columnKey) => (
                                                                <Checkbox key={columnKey} value={columnKey}>{ columnToDisplayValMapping[columnKey] }</Checkbox>
                                                            ))
                                                        }
                                                    </VStack>
                                                </CheckboxGroup>
                                            </CardWithColorAccent> 

                                            <CardWithColorAccent mt={8}>
                                                <Heading size="sm" mb="4">
                                                    Sort by
                                                </Heading>
                                                <Divider mb="6" />
                                                <RadioGroup onChange={setSortOnColumn} value={sortOnColumn}>
                                                    <VStack alignItems="flex-start">
                                                        {
                                                            availableColumns.filter( i => !['controls'].includes(i) ).map((columnKey) => (
                                                                <Radio key={columnKey} value={columnKey}> { columnToDisplayValMapping[columnKey] } </Radio>
                                                            ))
                                                        }
                                                    </VStack>
                                                </RadioGroup>
                                            </CardWithColorAccent> 

                                            <CardWithColorAccent mt={8}>
                                                <Heading size="sm" mb="4">
                                                    Sort Order
                                                </Heading>
                                                <Divider mb="6" />
                                                <RadioGroup onChange={setSortOrder} value={sortOrder}>
                                                    <VStack alignItems="flex-start">
                                                        <Radio value='ascending'> Ascending </Radio>
                                                        <Radio value='descending'> Descending </Radio>
                                                    </VStack>
                                                </RadioGroup>
                                            </CardWithColorAccent>                              
                                        </DrawerComponentWithRef>
                                    </Flex>
                                </Flex>
                            </Collapse>
                        </Flex>
                        <ResponsiveTable data={createDataForTable(queryData)} />
                        <Flex alignItems="center" mt={3} borderTopWidth={1} pt={4} >
                            <Text>
                                {`Showing ${((pageNo-1)*pageSize)+1} - ${ ( querySize! < pageNo*pageSize ) ? querySize : pageNo*pageSize } of ${querySize}`}
                            </Text>
                            <Spacer />
                            <IconButton
                                aria-label="First Page"
                                icon={ <FaAngleDoubleLeft /> }
                                size="xs"
                                variant='ghost'
                                isDisabled={ ( pageNo === 1 ) }
                                onClick={() => {
                                    setPageNo(1)
                                }}
                            />
                            <IconButton
                                aria-label="Previous Page"
                                icon={ <FaAngleLeft /> }
                                size="xs"
                                variant='ghost'
                                isDisabled={ ( pageNo === 1 ) }
                                onClick={() => {
                                    if ( pageNo > 1 ) {
                                        setPageNo( p => p - 1 )
                                    }
                                }}
                            />
                            <IconButton
                                aria-label="Next Page"
                                icon={ <FaAngleRight /> }
                                size="xs"
                                variant='ghost'
                                isDisabled={ ( pageNo === lastPage ) }
                                onClick={() => {
                                    if ( pageNo < lastPage ) {
                                        setPageNo( p => p + 1 )
                                    }
                                }}
                            />
                            <IconButton
                                aria-label="Last Page"
                                icon={ <FaAngleDoubleRight /> }
                                size="xs"
                                variant='ghost'
                                isDisabled={ ( pageNo === lastPage ) }
                                onClick={() => {
                                    setPageNo( lastPage )
                                }}
                            />
                            <Select 
                                w="auto" 
                                defaultValue={pageSize} 
                                onChange={(val) => {
                                    const changedPageSize = parseInt( val.target.value )
                                    setPageSize(changedPageSize)
                                    if ( changedPageSize*pageNo > querySize! ) {
                                        const lastPage = (Math.ceil(querySize!/changedPageSize) > 0) ? Math.ceil(querySize!/changedPageSize) : 1
                                        setPageNo( lastPage )
                                    }
                                }}
                            >
                                {
                                    paginationPageSizeArray.map( size => (
                                        <option key={size} value={size}> { size } </option>
                                    ))
                                }
                            </Select>
                        </Flex>
                    </Card>

                    {/*******************************************************************
                     * ******************************************************************
                     *       Below Code can be pushed inside a seperate function        *
                     *       leaving it for now as the drawer is closing due to         *
                     *       re-rendering of conponent                                  *
                     * ******************************************************************
                     ********************************************************************/}
                    <DrawerComponentWithRef
                        size="lg"
                        heading="Edit Event"
                        showFooter={ true }
                        triggerComponent={
                            <Button display="none" >
                                Edit
                            </Button>
                        }
                        footerButtons={
                            <Button 
                                colorScheme="blue"
                                type="submit"
                                form="createNewEventForm"
                                isLoading={ isSaveEventLoading }
                                loadingText="Processing"
                            >
                                Update
                            </Button>
                        }
                        ref={editEventDwawerRef}
                    >
                        <CreateEventForm 
                            formId="createNewEventForm"
                            loadingStatus={setIsSaveEventLoading}
                            actionOnSubmitSuccess={ () => {
                                editEventDwawerRef?.current?.onClose()
                            }}
                            onSubmitFunction={ async (value) => {
                                return await updateMutation.mutateAsync({
                                    ...value,
                                    id: itemToUpdate.id!,
                                    user_id: user?.id!
                                })
                            }}
                            initialData={{
                                ...itemToUpdate,
                                ...itemToUpdate.address,
                                start_date: moment(itemToUpdate.start_date).format('YYYY-MM-DD'),
                                end_date: moment(itemToUpdate.end_date).format('YYYY-MM-DD'),
                            }}
                        />
                    </DrawerComponentWithRef>
                </>
            )
        }
    }

    if ( isError ) {
        return (
            <ErrorComponent
                //@ts-ignore
                errorMessage={error.message}
            />
        )
    }

    if ( isLoading ) {
        return (
            <Stack mt={10} spacing={5} >
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
            </Stack>
        )
    }

    if ( !data ) {
        return (
            <ErrorComponent
                //@ts-ignore
                errorMessage={error.message}
            />
        )
    }

    return (
        <ErrorComponent />
    )
}

  
import { Input, Tag, TagCloseButton, TagLabel, Wrap, WrapItem } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'

export default function Chips( props:any ) {
    const { defaultValue, placeholder, onChipModify, isDisabled, ...propsToPass } = props
    const [chipArray, setChipArray] = useState( defaultValue || [] )

    useEffect(() => {
        onChipModify(chipArray)
    }, [chipArray, onChipModify])

    const MyTag = ( props:any ) => {
        const { tagName, index } = props
        return (
            <Tag
                size="lg"
                borderRadius="full"
                variant="solid"
                colorScheme="teal"
            >
                <TagLabel>{tagName}</TagLabel>
                <TagCloseButton
                    isDisabled={isDisabled}
                    onClick={ () => {
                        setChipArray(chipArray.filter((_:any, i:number) => i !== index))
                    }}
                />
            </Tag>
        )
    }

    const handleKeyDown = (e:any) => {
        if (e.key === ',') {
            e.target.value = e.target.value.replace(',','')
        }
        if (e.key === 'Enter') {
            e.preventDefault()
            let valToPush = e.target.value.replace(',','')
            valToPush = valToPush.trim()
            if (valToPush==='') {
                return 
            }
            valToPush = valToPush.split(' ')
            setChipArray([
                ...chipArray,
                ...valToPush
            ])
            e.target.value = ''
        }
    }

    const handleKeyUp = (e:any) => {
        if (e.key === ',') {
            e.target.value = e.target.value.replace(',','')
        }
    }

    const handleBlur = (e:any) => {
        let valToPush = e.target.value.replace(',','')
        valToPush = valToPush.trim()
        if (valToPush==='') {
            return 
        }
        valToPush = valToPush.split(' ')
        setChipArray([
            ...chipArray,
            ...valToPush
        ])
        e.target.value = ''
    }

    return (
        <>
            <Input
                placeholder={placeholder}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
                onBlur={handleBlur}
                {...propsToPass}
                isDisabled={isDisabled}
            />
            <Wrap mt={2}>
                {
                    chipArray.map( (item:string, index:number) => {
                        return (
                            <WrapItem key={`chipItemKey${index}`}>
                                <MyTag tagName={item} index={index} />
                            </WrapItem>
                        )
                    })
                }
            </Wrap>
        </>
    )
}

import { Center, Stack, VStack } from '@chakra-ui/layout'
import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Switch, Textarea, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { CardWithColorAccent } from '../../components/cardWithColorAccent/CardWithColorAccent'
import { PasswordField } from '../../components/passwordField/PasswordField'
import * as yup from 'yup'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAuth } from '../../contexts/Auth'
import { displayError, displaySuccess } from '../../helpers/CommonFunctions'

export type CustomSmtpFormInputs = {
    id?: string;
    name: string;
	email: string;
    host: string;
    port: number;
    user: string;
    password: string;
    senderName: string;
    isDefault: boolean;
}

export interface customSmtpFormPropsType {
    formValues?: CustomSmtpFormInputs
    onSubmit: (props:any) => any
}

export const CustomSmtpForm = (props:customSmtpFormPropsType) => {
    const { formValues, onSubmit } = props
    const { user } = useAuth()

    let schema = yup.object().shape({
        name: yup.string().required("Name is required"),
        email: yup.string().email().required("Admin Email is required"),
        host: yup.string().required("Host is required"),
        port: yup.number().typeError("Port should be a number").min(1).max(65000).required(),
        user: yup.string().required("SMTP Username is required to send emails"),
        password: yup.string().required("SMTP Password is required to send emails"),
        senderName: yup.string().required("SMTP Sender Name is required"),
    });

    const methods = useForm<CustomSmtpFormInputs>({
		mode: 'onBlur',
		resolver: yupResolver(schema),
        defaultValues: formValues
	})
    const { handleSubmit, formState: { errors, isSubmitting }, setValue, getValues } = methods
    const [isDefault, setIsDefault] = useState(getValues('isDefault'))
    const [isVerifySettingButtonLoading, setIsVerifySettingButtonLoading] = useState(false)
    const [isSendTestEmailButtonLoading, setIsSendTestEmailButtonLoading] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()

    const toEmailRef = useRef<HTMLInputElement>(null)
    const emailSubjectRef = useRef<HTMLInputElement>(null)
    const emailBodyRef = useRef<HTMLTextAreaElement>(null)

    const handleSaveSmtpSettingsButtonClick = async (values:CustomSmtpFormInputs) => {
        await onSubmit({ 
            ...values, 
            userId: user?.id,
        })
    }

    useEffect(() => {
        setIsDefault(formValues?.isDefault!)
    }, [formValues, setIsDefault])

    const testEmail = async (intent:string='test_connection') => {
        const host = getValues('host')
        const port = getValues('port')
        const user = getValues('user')
        const pass = getValues('password')
        const senderName = getValues('senderName')
        const adminEmail = getValues('email')
        
        let myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")

        if (intent === 'test_connection') {
            setIsVerifySettingButtonLoading(true)
            let data = JSON.stringify({
                "host": host,
                "port": port,
                "username": user,
                "password": pass
            })
    
            const response = await fetch("https://us-central1-gmc-testing.cloudfunctions.net/checkSmtpSettings/verify", {
                method: 'POST',
                headers: myHeaders,
                body: data,
                redirect: "follow"
            })
    
            try {
                const responseBody = await response.json()
                if (responseBody.is_success) {
                    displaySuccess('Connection Successful', 'SMTP configuration looks good')
                } else {
                    displayError('Connection Failed', 'Error connecting to your SMTP server')
                }
            } catch (error) {
                displayError( 'Something went wrong', 'Please wait while our cats are fire fighting' )
            } finally {
                setIsVerifySettingButtonLoading(false)
            }
        } else if ( intent === 'send_mail' ) {
            setIsSendTestEmailButtonLoading(true)
            let data = JSON.stringify({
                "host": host,
                "port": port,
                "username": user,
                "password": pass,
                "sender_name": senderName,
                "from_email": adminEmail,
                "to": toEmailRef.current?.value,
                "subject": emailSubjectRef.current?.value,
                "body": emailBodyRef.current?.value
            })
    
            const response = await fetch("https://us-central1-gmc-testing.cloudfunctions.net/checkSmtpSettings/test", {
                method: 'POST',
                headers: myHeaders,
                body: data,
                redirect: "follow"
            })
    
            try {
                const responseBody = await response.json()
                if (responseBody.is_success) {
                    displaySuccess('Mail Sent successfully', 'E-Mail sent, Please check your inbox')
                } else {
                    displayError('Connection Failed', 'Error connecting to your SMTP server')
                }
            } catch (error) {
                displayError( 'Something went wrong', 'Please wait while our cats are fire fighting' )
            } finally {
                setIsSendTestEmailButtonLoading(false)
            }
        }
    }

    return (
        <CardWithColorAccent mt={4} w="100%">
            <FormProvider {...methods} >
                <form
                    onSubmit={handleSubmit(handleSaveSmtpSettingsButtonClick)}
                    id={formValues?.id || "customSmtpForm"}
                >
                    <FormControl 
                        id={`${formValues?.id}smtp_name`} 
                        mb="3"
                        isInvalid={!!errors?.name}
                        errortext={errors?.name?.message}
                    >
                        <FormLabel>Setting Name</FormLabel>
                        <Input type="name" {...methods.register('name')} />
                        <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl 
                        id={`${formValues?.id}smtp_email`} 
                        mb="3"
                        isInvalid={!!errors?.email}
                        errortext={errors?.email?.message}
                    >
                        <FormLabel>SMTP Admin Email</FormLabel>
                        <Input type="email" {...methods.register('email')} />
                        <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl 
                        id={`${formValues?.id}smtp_host`}
                        mb="3"
                        isInvalid={!!errors?.host}
                        errortext={errors?.host?.message}
                    >
                        <FormLabel>SMTP Host</FormLabel>
                        <Input type="text" {...methods.register('host')} />
                        <FormErrorMessage>{errors?.host?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl 
                        id={`${formValues?.id}smtp_port`}
                        mb="3"
                        isInvalid={!!errors?.port}
                        errortext={errors?.port?.message}
                    >
                        <FormLabel>SMTP Port</FormLabel>
                            <NumberInput step={1} defaultValue={587} min={1} max={65000} >
                                <NumberInputField {...methods.register('port')} />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                        <FormErrorMessage>{errors?.port?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl 
                        id={`${formValues?.id}smtp_user`}
                        mb="3"
                        isInvalid={!!errors?.user}
                        errortext={errors?.user?.message}
                    >
                        <FormLabel>SMTP User</FormLabel>
                        <Input type="text" {...methods.register('user')} />
                        <FormErrorMessage>{errors?.user?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl 
                        id={`${formValues?.id}smtp_password`} 
                        mb="3"
                    >
                        <FormLabel>SMTP Password</FormLabel>
                        <PasswordField name="password" />
                    </FormControl>
                    <FormControl 
                        id={`${formValues?.id}smtp_sender_name`}
                        mb="3"
                        isInvalid={!!errors?.senderName}
                        errortext={errors?.senderName?.message}
                    >
                        <FormLabel>SMTP Sender Name</FormLabel>
                        <Input type="text" {...methods.register('senderName')} />
                        <FormErrorMessage>{errors?.senderName?.message}</FormErrorMessage>
                    </FormControl>
                    <Flex mb="6">
                        <FormControl 
                            id={`${formValues?.id}smtp_is_default`}
                            display="flex" 
                            alignItems="center"
                        >
                            <FormLabel htmlFor="email-alerts" mb="6">
                                Mark as default?
                            </FormLabel>
                            <Switch
                                size="lg"
                                isChecked={isDefault}
                                onChange={() => {
                                    setValue('isDefault', !getValues('isDefault'))
                                    setIsDefault(getValues('isDefault'))
                                }}
                            />
                        </FormControl>
                    </Flex>

                    <Center>
                        <Stack direction={['column', 'row']}>
                            <Button
                                type="button"
                                isLoading={isVerifySettingButtonLoading}
                                onClick={() => {
                                    testEmail('test_connection')
                                }}
                                loadingText="Verifying.."
                            >
                                Test Connection
                            </Button>
                            <Button 
                                onClick={onOpen}
                            >
                                Send Test Email
                            </Button>
                        </Stack>
                    </Center>
                </form>
            </FormProvider>
            <Flex
                alignItems="center"
                justifyContent="center"
            >
                <Button
                    type="submit" 
                    colorScheme="blue"
                    isLoading={isSubmitting}
                    loadingText="Saving.."
                    form={formValues?.id || "customSmtpForm"}
                    px="10"
                    mt="5"
                >
                    Save
                </Button>
            </Flex>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Send Test Email</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack>
                            <FormControl 
                                mb="2"
                            >
                                <FormLabel>To (Comma seperated list)</FormLabel>
                                <Input type="text" placeholder='youremail@email.com' ref={toEmailRef} />
                            </FormControl>
                            <FormControl 
                                mb="2"
                            >
                                <FormLabel>Subject</FormLabel>
                                <Input type="text" placeholder='Your Subject' ref={emailSubjectRef} />
                            </FormControl>
                            <FormControl 
                                mb="2"
                            >
                                <FormLabel>Body</FormLabel>
                                <Textarea placeholder='Email body' ref={emailBodyRef} />
                            </FormControl>
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button
                            type="button"
                            colorScheme="blue"
                            isLoading={isSendTestEmailButtonLoading}
                            onClick={() => {
                                testEmail('send_mail')
                            }}
                            loadingText="Sending.."
                        >
                            Send Test Email
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </CardWithColorAccent>
    )
}

import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Select,
  Textarea,
  FormHelperText,
  Stack,
  StackDivider,
  VStack,
  InputGroup,
  InputLeftAddon,
  StackProps,
} from "@chakra-ui/react";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Chips from "../chips/Chips";
import { addressTypeArray, eventTypeArray } from "../../dataObjects/globalDataObject";
import { FieldGroup } from "../fieldGroup/FieldGroup";
import { FaAddressBook, FaGlobeAsia, FaGlobeEurope } from "react-icons/fa";
import { countryStateDataObject } from "../../dataObjects/countryStatesDataObject";
import moment from "moment";
import { useAuth } from "../../contexts/Auth";

export type EventFormInputTypes = {
    name: string;
    type: string;
    hashtags: string[];
    about: string
    start_date: string;
    end_date: string;
    address_type: string;
    country: string,
    state: string,
    address_line_one: string,
    address_line_two: string,
    city: string,
    pin_code: number,
}

interface eventInputPropsType extends StackProps {
    actionOnSubmitSuccess?: (...args: any[]) => any
    loadingStatus?: (...args: any[]) => any
    formId?: string
    onSubmitFunction: ( arg:any ) => any
    initialData?: {[key:string]: any}
}

export const CreateEventForm = ( props:eventInputPropsType ) => {

    const { actionOnSubmitSuccess, loadingStatus, formId, onSubmitFunction, initialData, ...restProps } = props
    const { user } = useAuth()

    const schema = yup.object().shape({
        name: yup.string().required('name is required').min(4, "It looks very short"),
        type:yup.string().oneOf(eventTypeArray),
        hashtags: yup.array(),
        about: yup.string().optional(),
        start_date: yup.date().default( () => new Date() ).max(yup.ref('end_date'), 'Start date can not be after end date' ).typeError('Establishment date is either invalid or null').optional(),
        end_date: yup.date().default( () => new Date() ).min(yup.ref('start_date'), 'End date can not be before start date' ).typeError('Establishment date is either invalid or null').optional(),
        address_type: yup.string().oneOf(addressTypeArray),
        country: yup.string().nullable(),
        state: yup.string().nullable(),
        address_line_one: yup.string().optional(),
        address_line_two: yup.string().nullable(),
        city: yup.string().transform((v,q) => ( (q === '') ? null: v )).min(3).optional().notRequired().nullable(),
        pin_code: yup.number().transform((v, q) => ( (q === '') ? null: v )).typeError('Pin Code must be a number').max(9999999, 'Pin Code should be of max 7 digits').min(1000, 'Pin Code should be of min 4 digit').optional().notRequired().nullable(),
    })

    const methods = useForm<EventFormInputTypes>({
        mode: 'onBlur',
        resolver: yupResolver(schema),
        defaultValues: initialData,
    })

    const [selectedCountry, setselectedCountry] = useState(initialData?.country || '')

    const { register, handleSubmit, getValues, setValue, watch, formState: { errors, isSubmitting } } = methods
    const watchAddressType = watch('address_type', initialData?.address_type || '')

    useEffect( () => {
        if (loadingStatus) {
            loadingStatus( isSubmitting )
        }
    }, [loadingStatus, isSubmitting])

    const handleSubmitButtonPress = async (values:EventFormInputTypes) => {
        const countrySelect = document.getElementById('country') as HTMLSelectElement
        const address = {
            address_line_one: values.address_line_one,
            address_line_two: values.address_line_two,
            city: values.city,
            state: values.state,
            country: values.country || countrySelect?.options[countrySelect?.selectedIndex].value,
            pin_code: values.pin_code
        }
        const dataToUpdate = {
            name: values.name,
            type: values.type,
            hashtags: values.hashtags,
            about: values.about,
            start_date: values.start_date,
            end_date: values.end_date,
            address_type: values.address_type,
            address: address,
            user_id: user?.id,
        }

        await onSubmitFunction(dataToUpdate)
        if (actionOnSubmitSuccess) {
            actionOnSubmitSuccess()
        }
    }
  
    return (
        <form
            id={formId}
            onSubmit={handleSubmit(handleSubmitButtonPress)}
        >
            <Stack spacing="4" divider={<StackDivider />} {...restProps}>
                <FieldGroup title="Basic Info">
                    <VStack width="full" spacing="6">
                        <FormControl 
                            id="name"
                            isInvalid={!!errors?.name}
                            errortext={errors?.name?.message}
                            isRequired
                        >
                            <FormLabel>Name</FormLabel>
                            <Input type="text" maxLength={255} {...register('name')} />
                            <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="type"
                            isInvalid={!!errors?.type}
                            errortext={errors?.type?.message}
                            isRequired
                        >
                            <FormLabel>Type</FormLabel>
                            <Select placeholder="Event Type" {...register('type')} >
                                {
                                    eventTypeArray.map((organizationTypeObj) => {
                                        return (
                                            <option key={organizationTypeObj} value={organizationTypeObj}>{organizationTypeObj}</option>
                                        )
                                    })
                                }
                            </Select>
                            <FormErrorMessage>{errors?.type?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="about"
                            isInvalid={!!errors?.about}
                            errortext={errors?.about?.message}
                        >
                            <FormLabel>About</FormLabel>
                            <Textarea 
                                type="text" 
                                autoComplete="about" 
                                maxLength={255}
                                rows={5}
                                {...register('about')} 
                            />
                            <FormErrorMessage>{errors?.about?.message}</FormErrorMessage>
                            <FormHelperText>
                                Some words about your event
                            </FormHelperText>
                        </FormControl>
                    </VStack>
                </FieldGroup>

                <FieldGroup title="Hosting Details">
                    <VStack width="full" spacing="6">
                        <FormControl 
                            id="start_date"
                            isInvalid={!!errors?.start_date}
                            errortext={errors?.start_date?.message}
                        >
                            <FormLabel>Start Date</FormLabel>
                            <Input 
                                type="date" 
                                defaultValue={moment().format('YYYY-MM-DD')}
                                autoComplete="start_date" 
                                {...register('start_date')} 
                            />
                            <FormErrorMessage>{errors?.start_date?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="end_date"
                            isInvalid={!!errors?.end_date}
                            errortext={errors?.end_date?.message}
                        >
                            <FormLabel>End Date</FormLabel>
                            <Input 
                                type="date" 
                                defaultValue={moment().format('YYYY-MM-DD')}
                                autoComplete="end_date" 
                                {...register('end_date')} 
                                min={getValues().start_date}
                            />
                            <FormErrorMessage>{errors?.end_date?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="hashtags"
                        >
                            <FormLabel>Hashtags</FormLabel>
                            <Chips
                                placeholder="Few keywords to pramote it"
                                onChipModify={ (arr:any) => {
                                    setValue('hashtags', arr)
                                }}
                                defaultValue={(initialData?.hashtags)?initialData?.hashtags:[]}
                            />
                        </FormControl>
                    </VStack>
                </FieldGroup>

                <FieldGroup title="Address">
                    <VStack width="full" spacing="6">
                        <FormControl 
                            id="address_type"
                            isInvalid={!!errors?.address_type}
                            errortext={errors?.address_type?.message}
                        >
                            <FormLabel>Location Type</FormLabel>
                            <InputGroup >
                                <InputLeftAddon children={<FaAddressBook />} />
                                <Select placeholder="Select Location Type" {...register('address_type')} borderLeftRadius="0" >
                                    {
                                        addressTypeArray.map((addressVal) => {
                                            return (
                                                <option key={addressVal} value={addressVal}>{addressVal}</option>
                                            )
                                        })
                                    }
                                </Select>
                            </InputGroup>
                            <FormErrorMessage>{errors?.address_type?.message}</FormErrorMessage>
                        </FormControl>

                        {
                        (watchAddressType === 'offline') &&
                        <>
                            <FormControl 
                                id="country"
                                isInvalid={!!errors?.country}
                                errortext={errors?.country?.message}
                            >
                                <FormLabel>Country</FormLabel>
                                <InputGroup >
                                    <InputLeftAddon children={<FaGlobeEurope />} />
                                    <Select placeholder="Select country" {...register('country')} borderLeftRadius="0" onChange={(val)=> setselectedCountry(val.target.value)}>
                                        {
                                            countryStateDataObject.map((countryObj) => {
                                                return (
                                                    <option key={countryObj.name} value={countryObj.name}>{countryObj.name}</option>
                                                )
                                            })
                                        }
                                    </Select>
                                </InputGroup>
                                <FormErrorMessage>{errors?.country?.message}</FormErrorMessage>
                            </FormControl>

                            <FormControl 
                                id="state"
                                isInvalid={!!errors?.state}
                                errortext={errors?.state?.message}
                            >
                                <FormLabel>State</FormLabel>
                                <InputGroup >
                                    <InputLeftAddon children={<FaGlobeAsia />} />
                                    <Select placeholder="Select state" {...register('state')} borderLeftRadius="0" >
                                        {
                                            countryStateDataObject.find( name => name?.name === selectedCountry )?.states.map((countryObj) => {
                                                return (
                                                    <option key={countryObj.name} value={countryObj.name}>{countryObj.name}</option>
                                                )
                                            })
                                        }
                                    </Select>
                                </InputGroup>
                                <FormErrorMessage>{errors?.state?.message}</FormErrorMessage>
                            </FormControl>

                            <FormControl 
                                id="address_line_one"
                                isInvalid={!!errors?.address_line_one}
                                errortext={errors?.address_line_one?.message}
                                isRequired
                            >
                                <FormLabel>Shop no./Building name</FormLabel>
                                <Input type="text" maxLength={255} {...register('address_line_one')} />
                                <FormErrorMessage>{errors?.address_line_one?.message}</FormErrorMessage>
                            </FormControl>

                            <FormControl 
                                id="address_line_two"
                                isInvalid={!!errors?.address_line_two}
                                errortext={errors?.address_line_two?.message}
                                isRequired
                            >
                                <FormLabel>Street Name</FormLabel>
                                <Input type="text" maxLength={255} {...register('address_line_two')} />
                                <FormErrorMessage>{errors?.address_line_two?.message}</FormErrorMessage>
                            </FormControl>

                            <FormControl 
                                id="city"
                                isInvalid={!!errors?.city}
                                errortext={errors?.city?.message}
                                isRequired
                            >
                                <FormLabel>City</FormLabel>
                                <Input type="text" maxLength={255} {...register('city')} />
                                <FormErrorMessage>{errors?.city?.message}</FormErrorMessage>
                            </FormControl>

                            <FormControl 
                                id="pin_code"
                                isInvalid={!!errors?.pin_code}
                                errortext={errors?.pin_code?.message}
                                isRequired
                            >
                                <FormLabel>Pin Code</FormLabel>
                                <Input type="text" maxLength={255} {...register('pin_code')} />
                                <FormErrorMessage>{errors?.pin_code?.message}</FormErrorMessage>
                            </FormControl>
                        </>                        
                        }
                    </VStack>
                </FieldGroup>
            </Stack>
        </form>
    )
}


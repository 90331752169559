import React, { useRef, useEffect } from "react";
import { Image, Transformer } from "react-konva";
import useImage from "use-image";
import { Transformer as KonvaTransformer } from "konva/lib/shapes/Transformer";
import { Image as KonvaImage } from "konva/lib/shapes/Image";

export interface QrProps {
	id?: string;
	x: number;
	y: number;
	size: number;
	stroke?: string;
	strokeWidth?: number;
}

export interface Props {
	attrs: QrProps;
	isSelected: boolean;
	onSelect: () => void;
	onChange: (newAttrs: QrProps) => void;
	elementProps?: {[key:string]: string | object | boolean }
}

const QrElement: React.FC<Props> = ({ attrs, isSelected, onSelect, onChange, elementProps }) => {
	const trRef = useRef<KonvaTransformer>(null);
	const qrRef = useRef<KonvaImage>(null);
	const logoRef = useRef<KonvaImage>(null);
	const gmcLogoUrl = 'https://gpnmjenofbfeawopmhkj.supabase.co/storage/v1/object/public/public/gmc_files/gmc_logo_sq_small_compress.png';
	const blankImageUrl = 'https://gpnmjenofbfeawopmhkj.supabase.co/storage/v1/object/public/public/gmc_files/blank.png?'
	const qrImg = '/images/gmc_qr_without_logo.png'
	const logoRatio = 4;
	let displayLogoOnQr = false

	const [gmc_qr] = useImage(qrImg);

	let logoImgUrl = blankImageUrl

	if ( elementProps && elementProps['gmcLogo']) {
		logoImgUrl = gmcLogoUrl
		displayLogoOnQr = true
	}
	
	if ( elementProps && elementProps['customLogo'] && (typeof elementProps['customLogoUrl'] === 'string') && elementProps['customLogoUrl'].toString().length > 0) {
		logoImgUrl = elementProps['customLogoUrl']
		displayLogoOnQr = true
	}

	const [logoImg] = useImage(logoImgUrl)

	const handleKeyup = () => {
		if (isSelected) {
			handleDragEnd()
		}
	}

	const handleKeydown = (event:any) => {
		if (isSelected) {
			switch (event.key) {
				case 'ArrowLeft':
					qrRef.current?.x(qrRef.current?.x() - 1)
					handleDragMove()
					event.preventDefault()	
					break;

				case 'ArrowRight':
					qrRef.current?.x(qrRef.current?.x() + 1)
					handleDragMove()	
					event.preventDefault()	
					break;

				case 'ArrowUp':
					qrRef.current?.y(qrRef.current?.y() - 1)
					handleDragMove()
					event.preventDefault()		
					break;

				case 'ArrowDown':
					qrRef.current?.y(qrRef.current?.y() + 1)
					handleDragMove()
					event.preventDefault()		
					break;
			
				default:
					break;
			}
		}
	}

	useEffect(() => {
		window.addEventListener('keydown', handleKeydown)
		window.addEventListener('keyup', handleKeyup)
		return () => {
			window.removeEventListener('keydown', handleKeydown)
			window.removeEventListener('keyup', handleKeyup)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSelected])
		
	useEffect(() => {
		if (isSelected) {
			// attach transformer
			if (trRef.current && qrRef.current) {
				trRef.current.nodes([qrRef.current]);
				trRef.current.moveToTop();
				trRef.current.getLayer()?.batchDraw();
			}
		}
	}, [isSelected]);

	const handleMouseEnter = () => {
		const node = qrRef.current;
		if (node && !isSelected) onChange({ ...attrs, stroke: "#42adf5", strokeWidth: 1 });
	};

	const handleMouseLeave = () => {
		const node = qrRef.current;
		if (node) onChange({ ...attrs, strokeWidth: 0 });
	};

	const handleCanvasBounds = (pos: any) => {
		let newPos = { x: 0, y: 0 };
		let node = qrRef.current;
		let tr = trRef.current;
		// let nodeWidth = node?.getTextWidth() ? node?.getTextWidth() : 0;
		// let nodeHeight = node?.getHeight() ? node?.getHeight() : 0;
		if (tr) {
			let nodeWidth = tr.width();
			let nodeHeight = tr?.height();
			let maxX = node?.getStage()?.attrs.width - nodeWidth;
			let maxY = node?.getStage()?.attrs.height - nodeHeight;
			if (pos.x > 0) newPos.x = pos.x;
			if (pos.x > maxX) newPos.x = maxX;
			if (pos.y > 0) newPos.y = pos.y;
			if (pos.y > maxY) newPos.y = maxY;
		}
		return newPos;
	};

	const handleTransform = () => {
		const node = qrRef.current;
		if (node) {
			const scaleY = node.scaleY();
			node.scaleX(1);
			node.scaleY(1);
			onChange({
				...attrs,
				size: node.width() * scaleY,
			});
		}
	};

	const handleDragMove = () => {
		let tr = trRef.current;
		if (tr) {
			let x = (tr.getX() || attrs.x) + ((tr.width() || attrs.size) * (1 - 1 / logoRatio)) / 2;
			let y = (tr.getY() || attrs.y) + ((tr.width() || attrs.size) * (1 - 1 / logoRatio)) / 2;
			logoRef?.current?.setPosition({
				x: x,
				y: y
			})
		}
	}

	const handleDragEnd = () => {
		let tr = qrRef.current;
		if (tr) {
			let x = tr?.x();
			let y = tr?.y();
			let size = tr.width()
			onChange({ ...attrs, x, y, size });
		}
		onSelect();
	};

	return (
		<>
			<Image
				{...attrs}
				draggable
				height={attrs.size}
				width={attrs.size}
				image={gmc_qr}
				onMouseDown={onSelect}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={onSelect}
				onTap={onSelect}
				ref={qrRef}
				onDragMove={handleDragMove}
				onDragEnd={handleDragEnd}
				dragBoundFunc={handleCanvasBounds}
				onTransformEnd={onSelect}
				onTransform={handleTransform}
				name='konva_object'
			/>
			{
				displayLogoOnQr && 
				<Image 
					ref={logoRef}
					height={attrs.size / logoRatio}
					width={attrs.size / logoRatio}
					image={logoImg}
					x={(trRef.current?.getX() || attrs.x) + (attrs.size * (1 - 1 / logoRatio)) / 2}
					y={(trRef.current?.getY() || attrs.y) + (attrs.size * (1 - 1 / logoRatio)) / 2}
				/>
			}

			{isSelected && (
				<Transformer
					ref={trRef}
					rotateEnabled={false}
					flipEnabled={false}
					enabledAnchors={["top-right", "top-left", "bottom-right", "bottom-left"]}
					boundBoxFunc={(oldBox, newBox) => {
						let min_width = 0;
						if (qrRef.current) {
							min_width = 65;
						}
						if (newBox.width < min_width) {
							return oldBox;
						}
						return newBox;
					}}
				/>
			)}
		</>
	);
};

export default QrElement;

import { USER_MAGIC_LINK_MESSAGE, USER_MAGIC_LINK_TITLE } from "../configs/GlobalConstants"
import { successObjectInputType } from "./CommonFunctions"

export const globalSuccessObjectMaker = ( input:successObjectInputType ) => {
    switch (input.type) {
        case 'USER':
            switch (input.subType) {
                case 'LINK_SEND':
                    return {
                        code: 200,
                        title: USER_MAGIC_LINK_TITLE,
                        message: USER_MAGIC_LINK_MESSAGE,
                        successObj: input.successObj
                    }

                // case 'LINK_SEND':
                //     return {
                //         code: 200,
                //         title: USER_MAGIC_LINK_TITLE,
                //         message: USER_MAGIC_LINK_MESSAGE,
                //         successObj: input.successObj
                //     }
            
                default:
                    return {
                        code: 404,
                        title: 'User does not exist',
                        message: 'A user with the provided e-mail does not exist',
                        successObj: input.successObj
                    }
            }
    
        default:
            return {
                code: 404,
                title: 'User does not exist',
                message: 'A user with the provided e-mail does not exist',
                errorObject: input.successObj
            }
    }
}
import React, { useState } from "react";
import { State, none } from "@hookstate/core";

import { ElementType } from "../DrawingSheet";

import { HStack, Text, Stack, Button, Input, Box } from "@chakra-ui/react";
import { NumberInput, NumberInputField, NumberIncrementStepper, NumberDecrementStepper } from "@chakra-ui/react";
import { Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverBody } from "@chakra-ui/react";
import { ButtonGroup, Flex, Select } from "@chakra-ui/react";
import { Modal, ModalContent, ModalOverlay, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/react";

import { SketchPicker } from "react-color";
import { FiTrash2, FiAlignCenter, FiAlignLeft, FiAlignRight, FiBold, FiUnderline, FiItalic } from "react-icons/fi";
import { IoMdBrush } from "react-icons/io";

import EmptyPane from "./EmptyPane";

const fontList = [
	"Avenir LT Pro",
	"Bebas Neue",
	"Comfortaa",
	"Dancing Script",
	"Great Vibes",
	"Sacramento",
	"Staatliches",
	"Pacifico",
	"Lobster",
	"Lora",
	"Lato",
	"Montserrat",
	"Noto Sans",
	"Open Sans",
	"Oswald",
	"Poppins",
	"Roboto",
	"Rubik",
	"Ubuntu"
];

interface TextEditorProps {
	element: State<ElementType>;
}

const TextEditor: React.FC<TextEditorProps> = ({ element }) => {
	const [isPhModalOpen, setIsPhModalOpen] = useState(false);

	// const handlePhModalOpen = () => {
	// 	setIsPhModalOpen(true);
	// };

	const onPhModalClose = () => {
		setIsPhModalOpen(false);
	};

	const handleDelete = () => {
		if (element.required.get()) return;
		element.set(none);
	};

	const handleFontChange = (e: any) => {
		if (element.type.get() === "text") element.data.merge({ fontFamily: e.target.value.toString() });
	};

	const handleTextChange = (e: any) => {
		if ("text" in element.data) element.data.text.set(e.target.value);
	};

	const handleCenterAlign = () => {
		if (element.type.get() === "text") element.data.merge({ align: "center" });
	};
	const handleLeftAlign = () => {
		if (element.type.get() === "text") element.data.merge({ align: "left" });
	};
	const handleRightAlign = () => {
		if (element.type.get() === "text") element.data.merge({ align: "right" });
	};

	const handleToggleUnderline = () => {
		if (element.type.get() === "text") {
			if ("fontStyle" in element.data)
				if (element.data.textDecoration.get() === "underline") element.data.textDecoration.set("none");
				else element.data.textDecoration.set("underline");
			else element.merge({ data: { ...element.data.get(), textDecoration: "underline" } });
		}
	};
	const handleToggleBold = () => {
		if (element.type.get() !== "text") return;
		if (!("fontStyle" in element.data)) {
			element.merge({ data: { ...element.data.get(), fontStyle: "bold" } });
			return;
		}
		let style = element.data.fontStyle.get();
		if (!style) {
			element.merge({ data: { ...element.data.get(), fontStyle: "bold" } });
			return;
		}
		if (style?.indexOf("bold") > -1) {
			element.data.fontStyle.set(element.data.fontStyle.get()?.replaceAll("bold", ""));
		} else {
			element.data.fontStyle.set(element.data.fontStyle.get()?.concat(" bold"));
		}
	};

	const handleToggleItalics = () => {
		if (element.type.get() !== "text") return;
		if (!("fontStyle" in element.data)) {
			element.merge({ data: { ...element.data.get(), fontStyle: "italic" } });
			return;
		}
		let style = element.data.fontStyle.get();
		if (!style) {
			element.merge({ data: { ...element.data.get(), fontStyle: "italic" } });
			return;
		}
		if (style?.indexOf("italic") > -1) {
			element.data.fontStyle.set(element.data.fontStyle.get()?.replaceAll("italic", ""));
		} else {
			element.data.fontStyle.set(element.data.fontStyle.get()?.concat(" italic"));
		}
	};

	const isStyled = (style: string) => {
		let attrs = element.data.get();
		if ("fontStyle" in attrs) {
			// @ts-ignore
			if (attrs.fontStyle.indexOf(style) > -1) return true;
		}
		if ("textDecoration" in attrs) {
			// @ts-ignore
			if (attrs.textDecoration.indexOf(style) > -1) return true;
		}
		if ("align" in attrs) {
			// @ts-ignore
			if (attrs.align.indexOf(style) > -1) return true;
		}
		return false;
	};

	const handleFillChange = (color: any) => {
		let changeColor;
		clearTimeout(changeColor);
		changeColor = setTimeout(() => {
			element.merge((p) => ({ data: { ...p.data, fill: color.hex } }));
		}, 50);
	};

	const handleCHEdit = () => {};

	if (!element.get()) return <EmptyPane />;

	return (
		<>
			<Stack spacing={5}>
				{!element.auto.get() && (
					<>
						<Stack spacing={1}>
							<HStack>
								<Text fontSize='sm' color='gray'>
									Column Header
								</Text>
								{/* <Popover>
									<PopoverTrigger>
										<FiInfo cursor='pointer' />
									</PopoverTrigger>
									<PopoverContent rootProps={{ style: { right: 0 } }}>
										<PopoverArrow />
										<PopoverBody>
											<HStack>
												<Box>{`{TODO: add Header editing}`}</Box>
												<Button variant='solid' size='sm' width='min-content' onClick={handlePhModalOpen}> */}
													{/* TODO: EDIT onClick */}
													{/* Edit
												</Button>
											</HStack>
										</PopoverBody>
									</PopoverContent>
								</Popover> */}
							</HStack>
							<Input disabled value={element.header.get()}></Input>
						</Stack>

						<Stack spacing={1}>
							<Text fontSize='sm' color='gray'>
								Sample Text
							</Text>
							<Input value={"text" in element.data ? element.data.text.get() : ""} onChange={handleTextChange}></Input>
						</Stack>
					</>
				)}

				<Stack spacing={1}>
					<Text fontSize='sm' color='gray'>
						Font
					</Text>
					{/* @ts-ignore */}
					<Select onChange={handleFontChange} value={element.data.fontFamily.get()}>
						{fontList.map((font) => (
							<option key={font} style={{ fontFamily: font }}>{font}</option>
						))}
					</Select>
				</Stack>

				<Stack direction={['column', null, null, 'row']} spacing={5}>
					{"fill" in element.data && (
						<Popover>
							<PopoverTrigger>
								<Stack spacing={1}>
									<Text fontSize='sm' color='gray'>
										Color
									</Text>
									<Button leftIcon={<IoMdBrush />} variant='outline' size='sm' width='min-content'>
										<Flex h='100%' w='4em' p='5px'>
											<div
												style={{
													height: "100%",
													border: "1px solid",
													borderColor: "gray",
													width: 60,
													backgroundColor: element.data.fill.get(),
												}}></div>
										</Flex>
									</Button>
								</Stack>
							</PopoverTrigger>
							<PopoverContent bg='transparent' w='min-content' color='black' rootProps={{ style: { right: 0 } }}>
								<SketchPicker disableAlpha color={element.data.fill.get()} onChangeComplete={handleFillChange} onChange={handleFillChange} />
							</PopoverContent>
						</Popover>
					)}

					<Stack spacing={1}>
						<Text fontSize='sm' color='gray'>
							Size
						</Text>
						<NumberInput
							size='sm'
							min={7}
							max={125}
							allowMouseWheel
							onChange={(value) => {
								if ("fontSize" in element.data) element.data.fontSize.set(Number(value));
							}}
							value={"fontSize" in element.data ? Math.round(element.data.fontSize.get()) : 0}>
							<HStack>
								<NumberDecrementStepper
									style={{ borderInlineStart: "0px", margin: 0 }}
									children={
										<Button size='sm' style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
											-
										</Button>
									}
								/>
								<NumberInputField style={{ margin: 0, paddingRight: 12 }} />
								<NumberIncrementStepper
									style={{ borderInlineStart: "0px", borderTopWidth: "0px", margin: 0 }}
									children={
										<Button size='sm' style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
											+
										</Button>
									}
								/>
							</HStack>
						</NumberInput>
					</Stack>
				</Stack>

				{/* <HStack spacing={10}>
				<Stack spacing={1}>
					<Text fontSize='sm' color='gray'>
						Width
					</Text>
					<NumberInput
						size='sm'
						min={5}
						allowMouseWheel
						onChange={(value) => {
							setProps({ ...props, width: Number(value) });
						}}
						value={props.width ? Math.round(props.width) : "-"}>
						<HStack>
							<NumberInputField />
						</HStack>
					</NumberInput>
				</Stack>
			</HStack> */}

				{/* <HStack spacing={10}>
				<HStack spacing={5}>
					<Text fontSize='sm' color='gray'>
						X
					</Text>
					<NumberInput
						size='sm'
						allowMouseWheel
						onChange={(value) => {
							setProps({ ...props, x: Number(value) });
						}}
						value={Math.round(props.x)}>
						<NumberInputField />
					</NumberInput>
				</HStack>

				<HStack spacing={5}>
					<Text fontSize='sm' color='gray'>
						Y
					</Text>
					<NumberInput
						size='sm'
						allowMouseWheel
						onChange={(value) => {
							setProps({ ...props, y: Number(value) });
						}}
						value={Math.round(props.y)}>
						<NumberInputField />
					</NumberInput>
				</HStack>
			</HStack> */}

				{/* <Stack spacing={1}><Text fontSize='sm' color='gray'>Rotation</Text>
				<Slider size='sm' defaultValue={0} min={-180} max={180} step={1}
					onChange={(value) => {setProps({ ...props, rotation: Number(value) })}}>
					<SliderTrack bg='gray'><Box position='relative' right={10} />
						<SliderFilledTrack bg='white' />
					</SliderTrack>
					<SliderThumb boxSize={3} />
				</Slider>
			</Stack> */}

				<Stack spacing={1}>
					<Text fontSize='sm' color='gray'>
						Align
					</Text>
					<ButtonGroup isAttached width='100%'>
						<Button variant={isStyled("left") ? "solid" : "outline"} size='md' width='min-content' onClick={handleLeftAlign}>
							<FiAlignLeft />
						</Button>
						<Button variant={isStyled("center") ? "solid" : "outline"} size='md' width='min-content' onClick={handleCenterAlign}>
							<FiAlignCenter />
						</Button>
						<Button variant={isStyled("right") ? "solid" : "outline"} size='md' width='min-content' onClick={handleRightAlign}>
							<FiAlignRight />
						</Button>
					</ButtonGroup>
				</Stack>

				<Stack spacing={1}>
					<Text fontSize='sm' color='gray'>
						Decorate
					</Text>
					<ButtonGroup isAttached>
						<Button variant={isStyled("bold") ? "solid" : "outline"} size='md' width='min-content' onClick={handleToggleBold}>
							<FiBold />
						</Button>
						<Button variant={isStyled("underline") ? "solid" : "outline"} size='md' width='min-content' onClick={handleToggleUnderline}>
							<FiUnderline />
						</Button>
						<Button variant={isStyled("italic") ? "solid" : "outline"} size='md' width='min-content' onClick={handleToggleItalics}>
							<FiItalic />
						</Button>
						{/* TODO: Italics Button */}
					</ButtonGroup>
				</Stack>

				{!element.required.get() && (
					<Popover>
						<PopoverTrigger>
							<Button leftIcon={<FiTrash2 />} colorScheme='red' variant='outline' size='sm' width='min-content'>
								Delete
							</Button>
						</PopoverTrigger>
						<PopoverContent rootProps={{ style: { right: 0 } }}>
							<PopoverArrow />
							<PopoverBody>
								<HStack>
									<Box>Are you sure you want to delete this element?</Box>
									<Button onClick={handleDelete} variant='solid' size='sm' width='min-content'>
										Yes
									</Button>
								</HStack>
							</PopoverBody>
						</PopoverContent>
					</Popover>
				)}
			</Stack>
			<Modal isOpen={isPhModalOpen} onClose={onPhModalClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Edit column header</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack spacing={3}>
							<Text fontSize='md'>{`Header Name`}</Text>
							<Input placeholder='Enter column header' />
						</Stack>
					</ModalBody>

					<ModalFooter>
						<Button variant='solid' onClick={handleCHEdit}>
							Apply
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

export default TextEditor;

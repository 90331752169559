import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useEditor, EditorContent, Editor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import { Link } from '../../helpers/textEditors/tiptap/CustomLink'
import { CustomTable } from '../../helpers/textEditors/tiptap/CustomTable'
import { CustomImage } from '../../helpers/textEditors/tiptap/CustomImage'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import { Box, Button, Divider, Flex, IconButton, Stack, Tooltip, Wrap, WrapItem, Text, Menu, MenuButton, MenuList, MenuItem, useColorModeValue, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, useDisclosure, InputGroup, InputLeftAddon, Input, Spacer, Switch, Collapse, useBreakpointValue } from '@chakra-ui/react'
import { FaAlignCenter, FaAlignJustify, FaAlignLeft, FaAlignRight, FaBold, FaCaretDown, FaImage, FaItalic, FaLink, FaListOl, FaListUl, FaParagraph, FaRedo, FaStrikethrough, FaTable, FaUndo, FaUnlink } from 'react-icons/fa'
import  FocusLock from "react-focus-lock"
import { CardWithColorAccent } from '../cardWithColorAccent/CardWithColorAccent'
import './TipTapEditor.css'
import { DEFAULT_EMAIL_HEADER, DEFAULT_EMAIL_TEMPLATE } from '../../configs/GlobalConstants'
import { displayError, objectToCssString, setWait } from '../../helpers/CommonFunctions'
import { HEADER_NAVBAR_HEIGHT } from '../../configs/LayoutConfigs'
import { BsArrowBarDown, BsArrowBarLeft, BsArrowBarRight, BsArrowBarUp } from 'react-icons/bs'
import { AiOutlineDelete, AiOutlineDeleteColumn, AiOutlineDeleteRow, AiOutlineMergeCells, AiOutlineSplitCells } from 'react-icons/ai'
import { VscHorizontalRule } from 'react-icons/vsc'
import { useConfig } from '../../hooks/useConfig'

export interface tipTapEditorRefFunctionType {
    editor: Editor,
    subjectEditor: Editor,
    onSubmit: () => {
        subject: string,
        content: string
    },
}

const InsertVeriableBar = ({
    editor,
    customVariableObject = {},
    customVariableOrderList = []
}:{
    editor:Editor,
    customVariableObject:{[key:string]:any},
    customVariableOrderList:string[]
}) => {
    if (!editor) {
        return null
    }
    return (
        <Menu>
            <MenuButton as={Button} rightIcon={<FaCaretDown />}>
                Variables
            </MenuButton>
            <MenuList maxH="400px" overflowY='auto'>
            {
                customVariableOrderList.map(key => (
                    <Tooltip 
                        key={key}
                        label={`Click here to insert placeholder variable for ${customVariableObject[key]['displayName']} in editor`} 
                        placement="auto"
                    >
                        <MenuItem
                            onClick={() => editor.chain().focus().insertContent(customVariableObject[key]['placeHolder']).run()}
                        >
                            {customVariableObject[key]['displayName']}
                        </MenuItem>
                    </Tooltip>
                ))
            }
            </MenuList>
        </Menu>
    )
}

const MenuBar = ({ editor, customVariableBarObject }:{ editor:Editor, customVariableBarObject:{
    showCustomVariableBar:boolean,
    customVariableObject:object,
    customVariableOrderList:string[]
} }) => {

    const veriableBarBackgroundColor = useColorModeValue('white', 'gray.800')
    const {showCustomVariableBar, customVariableObject, customVariableOrderList} = customVariableBarObject
    const { onOpen, onClose, isOpen } = useDisclosure()
    const { onOpen:onOpenImage, onClose:onCloseImage, isOpen:isOpenImage } = useDisclosure()
    const linkUrlRef = useRef<HTMLInputElement>(null)
    const imageUrlRef = useRef<HTMLInputElement>(null)
    const imageWidthRef = useRef<HTMLInputElement>(null)
    const imageHeightRef = useRef<HTMLInputElement>(null)
    const linkButtonColorRef = useRef<HTMLInputElement>(null)
    const [useLinkAsButton, setUseLinkAsButton] = useState<number>(0)
    const [imageAlignValueHolder, setImageAlignValueHolder] = useState<string>('left')
    const variant = useBreakpointValue({ base: 'mobile', md: 'notMobile' })
    
    // I don't know what it does but removing it breaks the UI, Leving it here till we find a better front end dev
    const jugaad = useCallback(
        async () => {
            if (variant === 'mobile') {
                onOpen()
                await setWait(20)
                onClose()
            }
        }, [onOpen, onClose, variant]
    )
    useEffect(() => {
        jugaad()
    }, [jugaad])
    
    const addLinkOrButton = () => {
        const linkUrl = linkUrlRef.current?.value
        const linkButtonColor = linkButtonColorRef.current?.value        

        if (linkUrl === undefined || linkUrl === null || linkUrl.length < 1) {
            displayError('Link URL not provided', 'Sorry link URL can not be null, Please provide link URL')
            return false
        }

        if (useLinkAsButton === 0) {
            editor.chain().focus().extendMarkRange('link').setLink({ 
                href: linkUrl,
                class: 'editor_link',
            }).run()
            onClose()
        } else {
            editor.chain().focus().extendMarkRange('link').setLink({ 
                href: linkUrl,
                class: 'editor_link_button',
                style: objectToCssString({
                    display: 'inline-block',
                    padding: '16px 22px',
                    'font-family': '"Source Sans Pro", Helvetica, Arial, sans-serif',
                    'font-size': '16.0px',
                    color: '#ffffff',
                    'text-decoration': 'none',
                    'border-radius': '6px',
                    'background-color': linkButtonColor || '#1a82e2',
                }),
            }).run()
            onClose()
        }
    }

    if (!editor) {
      return null
    }

    return (
        <Wrap top={HEADER_NAVBAR_HEIGHT*4} position='sticky' zIndex='1000' bg={veriableBarBackgroundColor} py={3} >
            <WrapItem>
                <Menu>
                    <MenuButton as={Button} rightIcon={<FaCaretDown />}>
                        {
                            editor.isActive('paragraph') ? 'Paragraph' : 
                            (editor.isActive('heading', { level: 1 }) ? 'Heading 1' : 
                            (editor.isActive('heading', { level: 2 }) ? 'Heading 2' : 
                            (editor.isActive('heading', { level: 3 }) ? 'Heading 3' : 
                            (editor.isActive('heading', { level: 4 }) ? 'Heading 4' : 
                            (editor.isActive('heading', { level: 5 }) ? 'Heading 5' : 
                            (editor.isActive('heading', { level: 6 }) ? 'Heading 6' : 'Heading' ))))))
                        }
                    </MenuButton>
                    <MenuList maxH="400px" overflowY='auto'>
                        <MenuItem
                            onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                        >
                            Heading 1
                        </MenuItem>
                        <MenuItem
                            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                        >
                            Heading 2
                        </MenuItem>
                        <MenuItem
                            onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                        >
                            Heading 3
                        </MenuItem>
                        <MenuItem
                            onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
                        >
                            Heading 4
                        </MenuItem>
                        <MenuItem
                            onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
                        >
                            Heading 5
                        </MenuItem>
                        <MenuItem
                            onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
                        >
                            Heading 6
                        </MenuItem>
                        <MenuItem
                            onClick={() => editor.chain().focus().setParagraph().run()}
                        >
                            Paragraph
                        </MenuItem>
                    </MenuList>
                </Menu>
            </WrapItem>
            <WrapItem>
                <Tooltip label="Bold" placement="auto">
                    <IconButton
                        aria-label="Bold"
                        icon={<FaBold />}
                        onClick={() => editor.chain().focus().toggleBold().run()}
                        variant={editor.isActive('bold') ? 'solid' : 'outline'}
                        borderBottomRightRadius='0'
                        borderTopRightRadius='0'
                    />
                </Tooltip>
                <Tooltip label="Italic" placement="auto">
                    <IconButton
                        aria-label="Italic"
                        icon={<FaItalic />}
                        onClick={() => editor.chain().focus().toggleItalic().run()}
                        variant={editor.isActive('italic') ? 'solid' : 'outline'}
                        borderRadius='0'
                    />
                </Tooltip>
                <Tooltip label="Strike Through" placement="auto">
                    <IconButton
                        aria-label="Strike Through"
                        icon={<FaStrikethrough />}
                        onClick={() => editor.chain().focus().toggleStrike().run()}
                        variant={editor.isActive('strike') ? 'solid' : 'outline'}
                        borderBottomLeftRadius='0'
                        borderTopLeftRadius='0'
                    />
                </Tooltip>
            </WrapItem>
            <WrapItem>
                <Tooltip label="Paragraph" placement="auto">
                    <IconButton
                        aria-label="Paragraph"
                        icon={<FaParagraph />}
                        onClick={() => editor.chain().focus().setParagraph().run()}
                        variant={editor.isActive('paragraph') ? 'solid' : 'outline'}
                    />
                </Tooltip>
            </WrapItem>
            <WrapItem>
                <Tooltip label="Horizontal Rule" placement="auto">
                    <IconButton
                        aria-label="Horizontal Rule"
                        icon={<VscHorizontalRule />}
                        onClick={() => editor.chain().focus().setHorizontalRule().run()}
                    />
                </Tooltip>
            </WrapItem>
            <WrapItem>
                <Tooltip label="Unordered List" placement="auto">
                    <IconButton
                        aria-label="BulletList"
                        icon={<FaListUl />}
                        onClick={() => editor.chain().focus().toggleBulletList().run()}
                        variant={editor.isActive('bulletList') ? 'solid' : 'outline'}
                        borderBottomRightRadius='0'
                        borderTopRightRadius='0'
                    />
                </Tooltip>
                <Tooltip label="Ordered List" placement="auto">
                    <IconButton
                        aria-label="OrderedList"
                        icon={<FaListOl />}
                        onClick={() => editor.chain().focus().toggleOrderedList().run()}
                        variant={editor.isActive('orderedList') ? 'solid' : 'outline'}
                        borderBottomLeftRadius='0'
                        borderTopLeftRadius='0'
                    />
                </Tooltip>
            </WrapItem>
            <WrapItem>
                <Tooltip label="Undo" placement="auto">
                    <IconButton
                        aria-label="Undo"
                        icon={<FaUndo />}
                        onClick={() => editor.chain().focus().undo().run()}
                        isDisabled={!editor.can().undo()}
                        borderBottomRightRadius='0'
                        borderTopRightRadius='0'
                    />
                </Tooltip>
                <Tooltip label="Redo" placement="auto">
                    <IconButton
                        aria-label="Redo"
                        icon={<FaRedo />}
                        onClick={() => editor.chain().focus().redo().run()}
                        isDisabled={!editor.can().redo()}
                        borderBottomLeftRadius='0'
                        borderTopLeftRadius='0'
                    />
                </Tooltip>
            </WrapItem>

            <WrapItem>
                <Popover
                    isOpen={isOpen}
                    initialFocusRef={linkUrlRef}
                    onOpen={onOpen}
                    onClose={onClose}
                    placement='auto'
                    closeOnBlur={false}
                >
                    <PopoverTrigger>
                        {/* <Tooltip label="Add Link" placement="auto"> */}
                            <IconButton 
                                aria-label='Add Link' 
                                icon={<FaLink />} 
                                borderBottomRightRadius='0'
                                borderTopRightRadius='0'
                                onClick={() => {
                                    if (linkUrlRef.current) {
                                        if (editor.getAttributes('link').href !== undefined) {
                                            linkUrlRef.current.value = editor.getAttributes('link').href
                                        }
                                    }
                                }}
                            />
                        {/* </Tooltip> */}
                    </PopoverTrigger>
                    <PopoverContent p={5}>
                        <FocusLock returnFocus persistentFocus={false}>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <Stack spacing={6} my='4'>
                                <InputGroup>
                                    <InputLeftAddon children='URL' />
                                    <Input 
                                        type='url' 
                                        placeholder='URL to link' 
                                        ref={linkUrlRef} 
                                    />
                                </InputGroup>
                                <Flex alignItems='flex-end'>
                                    <Text>
                                        Make Button?
                                    </Text>
                                    <Spacer />
                                    <Switch size="md" value={useLinkAsButton} 
                                    onChange={(event) => {    
                                            setUseLinkAsButton(Math.abs(useLinkAsButton-1))
                                        }} 
                                    />
                                </Flex>
                                <Collapse in={(useLinkAsButton === 1)} style={{ width: '100%' }} animateOpacity>
                                    <Flex alignItems='center'>
                                        <Text>
                                            Button Color:
                                        </Text>
                                        <Spacer />
                                        <Input type='color' width='100px' defaultValue='#1a82e2' ref={linkButtonColorRef} />
                                    </Flex>
                                </Collapse>
                                <Flex justifyContent='center'>
                                    <Button
                                        variant="solid" 
                                        colorScheme="blue"
                                        onClick={addLinkOrButton}
                                    >
                                        Create
                                    </Button>
                                </Flex>
                            </Stack>
                        </FocusLock>
                    </PopoverContent>
                </Popover>
                <Tooltip label="Unlink" placement="auto">
                    <IconButton
                        aria-label="Unlink"
                        isDisabled={!editor.isActive('link')}
                        icon={<FaUnlink />}
                        onClick={() => editor.chain().focus().unsetLink().run()}
                        borderBottomLeftRadius='0'
                        borderTopLeftRadius='0'
                    />
                </Tooltip>
            </WrapItem>


            <WrapItem>
                <Popover
                    isOpen={isOpenImage}
                    initialFocusRef={imageUrlRef}
                    onOpen={onOpenImage}
                    onClose={onCloseImage}
                    placement='auto'
                    closeOnBlur={false}
                >
                    <PopoverTrigger>
                        {/* <Tooltip label="Add Link" placement="auto"> */}
                            <IconButton 
                                aria-label='Add Image' 
                                icon={<FaImage />} 
                            />
                        {/* </Tooltip> */}
                    </PopoverTrigger>
                    <PopoverContent p={5}>
                        <FocusLock returnFocus persistentFocus={false}>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <Stack spacing={6} my='4'>
                                <InputGroup>
                                    <InputLeftAddon children='URL' />
                                    <Input 
                                        type='url' 
                                        placeholder='Image URL to link' 
                                        ref={imageUrlRef} 
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <InputLeftAddon children='Width' />
                                    <Input 
                                        type='url' 
                                        placeholder='Image Width' 
                                        defaultValue='100%'
                                        ref={imageWidthRef} 
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <InputLeftAddon children='Height' />
                                    <Input 
                                        type='url' 
                                        placeholder='Image Height' 
                                        defaultValue='auto'
                                        ref={imageHeightRef} 
                                    />
                                </InputGroup>
                                <Flex justifyContent='center' alignItems='center'>
                                    <Text>
                                        Image Align:
                                    </Text>
                                    <Spacer />
                                    <Tooltip label="Left Align" placement="auto">
                                        <IconButton
                                            aria-label="Left Align"
                                            icon={<FaAlignLeft />}
                                            onClick={() => setImageAlignValueHolder('left')}
                                            isActive={imageAlignValueHolder === 'left'}
                                            borderBottomRightRadius='0'
                                            borderTopRightRadius='0'
                                        />
                                    </Tooltip>
                                    <Tooltip label="Center Align" placement="auto">
                                        <IconButton
                                            aria-label="Center Align"
                                            icon={<FaAlignCenter />}
                                            onClick={() => setImageAlignValueHolder('center')}
                                            isActive={imageAlignValueHolder === 'center'}
                                            borderRadius='0'
                                        />
                                    </Tooltip>
                                    <Tooltip label="Right Align" placement="auto">
                                        <IconButton
                                            aria-label="Right Align"
                                            icon={<FaAlignRight />}
                                            onClick={() => setImageAlignValueHolder('right')}
                                            isActive={imageAlignValueHolder === 'right'}
                                            borderBottomLeftRadius='0'
                                            borderTopLeftRadius='0'
                                        />
                                    </Tooltip>
                                </Flex>
                                <Flex justifyContent='center'>
                                    <Button
                                        variant="solid" 
                                        colorScheme="blue"
                                        onClick={() => {
                                            const imgUrl = imageUrlRef.current?.value
                                            const imgWidth = imageWidthRef.current?.value
                                            const imageHeight = imageHeightRef.current?.value
                                            let imageStyleObject = {} 

                                            if (imgUrl === undefined || imgUrl === null || imgUrl === '') {
                                                displayError('Image URL can not be null', 'Please provide a imge url to set')
                                                return false
                                            }

                                            if (imgWidth === undefined || imgWidth === null || imgWidth === '') {
                                                displayError('Image width can not be null', 'Please provide a imge width to set')
                                                return false
                                            }

                                            if (imageHeight === undefined || imageHeight === null || imageHeight === '') {
                                                displayError('Image height can not be null', 'Please provide a imge height to set')
                                                return false
                                            }

                                            switch (imageAlignValueHolder) {
                                                case 'left':
                                                    imageStyleObject = {
                                                        display: 'block',
                                                        'margin-right': 'auto',
                                                    }
                                                    break;

                                                case 'center':
                                                    imageStyleObject = {
                                                        display: 'block',
                                                        'margin-left': 'auto',
                                                        'margin-right': 'auto',
                                                    }
                                                    break;

                                                case 'right':
                                                    imageStyleObject = {
                                                        display: 'block',
                                                        'margin-left': 'auto',
                                                    }
                                                    break;
                                            
                                                default:
                                                    imageStyleObject = {
                                                        display: 'block',
                                                        'margin-right': 'auto',
                                                    }
                                                    break;
                                            }

                                            editor.chain().focus().setImage({ 
                                                src: imgUrl, 
                                                //@ts-ignore
                                                width: imgWidth, 
                                                height: imageHeight,
                                                style: objectToCssString(imageStyleObject),
                                            }).run()
                                            onCloseImage()
                                        }}
                                    >
                                        Insert
                                    </Button>
                                </Flex>
                            </Stack>
                        </FocusLock>
                    </PopoverContent>
                </Popover>
            </WrapItem>
            <WrapItem>
                <Tooltip label="Left Align" placement="auto">
                    <IconButton
                        aria-label="Left Align"
                        icon={<FaAlignLeft />}
                        onClick={() => editor.chain().focus().setTextAlign('left').run()}
                        isActive={editor.isActive({ textAlign: 'left' })}
                        borderBottomRightRadius='0'
                        borderTopRightRadius='0'
                    />
                </Tooltip>
                <Tooltip label="Center Align" placement="auto">
                    <IconButton
                        aria-label="Center Align"
                        icon={<FaAlignCenter />}
                        onClick={() => editor.chain().focus().setTextAlign('center').run()}
                        isActive={editor.isActive({ textAlign: 'center' })}
                        borderRadius='0'
                    />
                </Tooltip>
                <Tooltip label="Right Align" placement="auto">
                    <IconButton
                        aria-label="Right Align"
                        icon={<FaAlignRight />}
                        onClick={() => editor.chain().focus().setTextAlign('right').run()}
                        isActive={editor.isActive({ textAlign: 'right' })}
                        borderRadius='0'
                    />
                </Tooltip>
                <Tooltip label="Justify Align" placement="auto">
                    <IconButton
                        aria-label="Justify Align"
                        icon={<FaAlignJustify />}
                        onClick={() => editor.chain().focus().setTextAlign('justify').run()}
                        isActive={editor.isActive({ textAlign: 'justify' })}
                        borderBottomLeftRadius='0'
                        borderTopLeftRadius='0'
                    />
                </Tooltip>
            </WrapItem>
            <WrapItem>
                <Tooltip label="Insert Table" placement="auto">
                    <IconButton
                        aria-label="Insert Table"
                        icon={<FaTable />}
                        onClick={() => editor.chain().focus().insertTable({ 
                                rows: 2, 
                                cols: 2, 
                                withHeaderRow: false 
                            })
                            .run()
                        }
                        borderBottomRightRadius='0'
                        borderTopRightRadius='0'
                    />
                </Tooltip>
                <Tooltip label="Delete Table" placement="auto">
                    <IconButton
                        aria-label="Justify Align"
                        icon={<AiOutlineDelete />}
                        onClick={() => editor.chain().focus().deleteTable().run()}
                        isDisabled={!editor.can().deleteTable()}
                        borderBottomLeftRadius='0'
                        borderTopLeftRadius='0'
                    />
                </Tooltip>
            </WrapItem>
            <WrapItem>
            <Tooltip label="Insert Column After" placement="auto">
                    <IconButton
                        aria-label="Insert Column After"
                        icon={<BsArrowBarRight />}
                        onClick={() => editor.chain().focus().addColumnAfter().run()}
                        isDisabled={!editor.can().addColumnAfter()}
                        borderBottomRightRadius='0'
                        borderTopRightRadius='0'
                    />
                </Tooltip>
                <Tooltip label="Insert Column Before" placement="auto">
                    <IconButton
                        aria-label="Insert Column Before"
                        icon={<BsArrowBarLeft />}
                        onClick={() => editor.chain().focus().addColumnBefore().run()}
                        isDisabled={!editor.can().addColumnBefore()}
                        borderRadius='0'
                    />
                </Tooltip>
                <Tooltip label="Delete Column" placement="auto">
                    <IconButton
                        aria-label="Delete Column"
                        icon={<AiOutlineDeleteColumn />}
                        onClick={() => editor.chain().focus().deleteColumn().run()}
                        isDisabled={!editor.can().deleteColumn()}
                        borderBottomLeftRadius='0'
                        borderTopLeftRadius='0'
                    />
                </Tooltip>
            </WrapItem>
            <WrapItem>
                <Tooltip label="Insert Row After" placement="auto">
                    <IconButton
                        aria-label="Insert Row After"
                        icon={<BsArrowBarDown />}
                        onClick={() => editor.chain().focus().addRowAfter().run()}
                        isDisabled={!editor.can().addRowAfter()}
                        borderBottomRightRadius='0'
                        borderTopRightRadius='0'
                    />
                </Tooltip>
                <Tooltip label="Insert Row Before" placement="auto">
                    <IconButton
                        aria-label="Insert Row Before"
                        icon={<BsArrowBarUp />}
                        onClick={() => editor.chain().focus().addRowBefore().run()}
                        isDisabled={!editor.can().addRowBefore()}
                        borderRadius='0'
                    />
                </Tooltip>
                <Tooltip label="Delete Row" placement="auto">
                    <IconButton
                        aria-label="Delete Row"
                        icon={<AiOutlineDeleteRow />}
                        onClick={() => editor.chain().focus().deleteRow().run()}
                        isDisabled={!editor.can().deleteRow()}
                        borderBottomLeftRadius='0'
                        borderTopLeftRadius='0'
                    />
                </Tooltip>
            </WrapItem>
            <WrapItem>
            <Tooltip label="Merge Cells" placement="auto">
                    <IconButton
                        aria-label="Merge Cells"
                        icon={<AiOutlineMergeCells />}
                        onClick={() => editor.chain().focus().mergeCells().run()}
                        isDisabled={!editor.can().mergeCells()}
                        borderBottomRightRadius='0'
                        borderTopRightRadius='0'
                    />
                </Tooltip>
                <Tooltip label="Split Cell" placement="auto">
                    <IconButton
                        aria-label="Split Cell"
                        icon={<AiOutlineSplitCells />}
                        onClick={() => editor.chain().focus().splitCell().run()}
                        isDisabled={!editor.can().splitCell()}
                        borderBottomLeftRadius='0'
                        borderTopLeftRadius='0'
                    />
                </Tooltip>
            </WrapItem>
            <WrapItem>
                {
                    showCustomVariableBar &&
                    <InsertVeriableBar 
                        editor={editor} 
                        customVariableObject={customVariableObject} 
                        customVariableOrderList={customVariableOrderList} 
                    />
                }
            </WrapItem>
        </Wrap>
    )
}

export const TipTapEditor = forwardRef(({
    showCustomVariableBar = true,
    customVariableObject = {},
    customVariableOrderList = [],
    dataHolder,
    subjectHolder,
    initialEmailContent = DEFAULT_EMAIL_TEMPLATE,
    initialEmailSubject = DEFAULT_EMAIL_HEADER,
    onSubmit,
    isShowSaveButton = true
}:{
    showCustomVariableBar:boolean,
    customVariableObject:object,
    customVariableOrderList:string[],
    dataHolder?: (params:any) => any,
    subjectHolder?: (params:any) => any,
    initialEmailContent?: string,
    initialEmailSubject?: string,
    onSubmit?: (params:any) => any,
    isShowSaveButton?: boolean
}, ref) => {
    const [isSaving, setIsSaving] = useState(false)
    const { isWhiteLabeled, companyWebsite, companyLogo } = useConfig()
    const editor = useEditor({
        extensions: [
          StarterKit,
          Link,
          TextAlign.configure({
            types: ['heading', 'paragraph'],
          }),
          CustomTable.configure({
            resizable: false,
          }),
          TableRow,
          TableHeader,
          TableCell,
          CustomImage.configure({
              inline: false
          }),
        ],
        content: initialEmailContent,
        injectCSS: true,
        editorProps: {
            attributes: {
                class: 'tip_tap_editor_styles'
            }
        }
    })

    const header = useEditor({
        extensions: [
            StarterKit,
            Link,
            TextAlign.configure({
                types: ['heading', 'paragraph'],
            }),
            CustomTable.configure({
                resizable: false,
            }),
            TableRow,
            TableHeader,
            TableCell,
            CustomImage.configure({
                inline: false
            }),
        ],
        content: initialEmailSubject,
        injectCSS: true,
        editorProps: {
            attributes: {
                class: 'tip_tap_header_styles'
            }
        }
    })

    useEffect(() => {
      if (initialEmailContent !== DEFAULT_EMAIL_TEMPLATE ) {
          editor?.commands.setContent(initialEmailContent)
      }
    }, [initialEmailContent, editor])

    useEffect(() => {
        if (initialEmailSubject !== DEFAULT_EMAIL_TEMPLATE ) {
            header?.commands.setContent(initialEmailSubject)
        }
    }, [initialEmailSubject, header])
    

    const [activeEditorHolder, setActiveEditorHolder] = useState(editor)

    const handleSaveButtonClick = async () => {
        setIsSaving(true)

        const htmlHolder = editor?.getHTML()
        dataHolder && dataHolder(htmlHolder)
        
        const subjectTextHolder = header?.getText()
        subjectHolder && subjectHolder( subjectTextHolder )

        await setWait(1000)
        setIsSaving(false) 
        
        onSubmit && onSubmit({
            subject: subjectHolder,
            content: htmlHolder
        })
    }

    useImperativeHandle( ref, () => ({
        editor: editor,
        subjectEditor: header,
        onSubmit: () => {
            return {
                subject: header?.getText(),
                content: editor?.getHTML()
            }
        }
    }))

    return (
        <CardWithColorAccent w="100%" maxW="100%">
            <Box flexGrow={1}>
                <MenuBar editor={activeEditorHolder || editor! } customVariableBarObject={{
                    showCustomVariableBar: showCustomVariableBar,
                    customVariableObject: customVariableObject,
                    customVariableOrderList: customVariableOrderList
                }} />
                <Divider py={3} />
                <Text mt="5" mb="2">
                    Email Subject :-
                </Text>
                <EditorContent id="email_subject_holder" editor={header} onClickCapture={ () => setActiveEditorHolder(header) } />
                <Text mt="5" mb="2">
                    Email Body :-
                </Text>
                <div style={{width: '100%', color: 'black'}}>
                    <div style={{fontFamily: 'Verdana, Arial, Helvetica, sans-serif', fontSize: '10.0pt'}}>
                    <table style={{borderCollapse: 'collapse', borderWidth: "0"}} width="100%" cellSpacing={0} cellPadding={0}>
                        <tbody>
                        <tr>
                            <td style={{backgroundColor: "#e9ecef"}} align="center">
                            <table style={{maxWidth: '600.0px', borderCollapse: 'collapse', borderWidth: "0"}} width="100%" cellSpacing={0} cellPadding={0} >
                                <tbody>
                                <tr>
                                    <td style={{padding: '36.0px 24.0px'}} valign="top" align="center">
                                        {isWhiteLabeled?<a href={ (companyWebsite.length > 0)? companyWebsite : "https://www.upgrad.com/"} style={{color: 'rgb(26,130,226)', display: 'inline-block'}}>
                                            <img src={ (companyLogo.length > 0 ) ? companyLogo : "https://gpnmjenofbfeawopmhkj.supabase.co/storage/v1/object/public/public/gmc_files/upgrad_logo_red.png"} alt="Warm" style={{height: '48.0px', maxHeight: '48.0px', minHeight: '48.0px', lineHeight: '100.0%', textDecoration: 'none', border: 0, outline: 'none', display: 'block', width: 'auto', borderWidth: "0"}} width={48} height="auto" />
                                        </a>:<a href="https://givemycertificate.com/" style={{color: 'rgb(26,130,226)', display: 'inline-block'}}>
                                            <img src="https://givemycertificate.com/style/images/GMC.png" alt="Warm" style={{height: 'auto', lineHeight: '100.0%', textDecoration: 'none', border: 0, outline: 'none', display: 'block', width: '48.0px', maxWidth: '48.0px', minWidth: '48.0px', borderWidth: "0"}} width={48} height="auto" />
                                        </a>}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: '#e9ecef'}} align="center">
                            <table style={{maxWidth: '600.0px', borderCollapse: 'collapse', borderWidth: "0"}} width="100%" cellSpacing={0} cellPadding={0}>
                                <tbody>
                                <tr>
                                    <td style={{padding: '24.0px', fontFamily: '"Source Sans Pro", Helvetica, Arial, sans-serif', fontSize: '16.0px', lineHeight: '24.0px', backgroundColor: '#ffffff'}} align="left">
                                        <EditorContent editor={editor} onClickCapture={ () => setActiveEditorHolder(editor) } />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{padding: '24.0px', backgroundColor: '#e9ecef'}} align="center">
                            <table style={{maxWidth: '600.0px', borderCollapse: 'collapse', borderWidth: "0"}} width="100%" cellSpacing={0} cellPadding={0} >
                                <tbody>
                                <tr>
                                    <td style={{padding: '12.0px 24.0px', fontFamily: '"Source Sans Pro", Helvetica, Arial, sans-serif', fontSize: '14.0px', lineHeight: '20.0px', color: 'rgb(102,102,102)', backgroundColor: '#e9ecef'}} align="center">
                                        <p style={{margin: 0}}>
                                            You received this email because you have earned a certificate from <a href='{"{{organization.website}}"}'>{"{{organization.name}}"}</a>.
                                            <br />
                                        </p>
                                        {isWhiteLabeled?<></>:<a href='https://givemycertificate.com' title='Powered By:- GiveMyCertificate'>
                                            <img alt='Powered By:- GiveMyCertificate' src="https://gpnmjenofbfeawopmhkj.supabase.in/storage/v1/object/public/public/gmc_files/GMC_Powered_By.png" width="45%" height="auto" style={{display: 'block', marginLeft: 'auto'}}></img>
                                        </a>}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div>
                        <br />
                    </div>
                    </div>
                </div>
                { isShowSaveButton && <Flex w="100%" mt={4} justifyContent="center">
                    <Button
                        variant="solid" 
                        colorScheme="blue" 
                        px="10" 
                        isLoading={isSaving}
                        loadingText="Saving..."
                        onClick={handleSaveButtonClick}
                    >
                        Save
                    </Button>
                </Flex>}
            </Box>
        </CardWithColorAccent>
    )
})

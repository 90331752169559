import { Avatar } from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'
import { stringOrNumber } from '../../helpers/CommonFunctions'
import DisplayObjectInCard from '../displayObjectInCard/DisplayObjectInCard'

export interface showOrganizationInputType {
    displayOrder: stringOrNumber[],
    keysAndDisplayNameMapping: {[key:string]:string},
    displayObject: {[key:string]:any}
}

const ShowOrganization = (props:showOrganizationInputType) => {
    const { displayOrder, keysAndDisplayNameMapping, displayObject } = props
    const internalDisplayObject = {
        ...displayObject,
        ...( displayObject.establishment_date && { establishment_date: moment( displayObject.establishment_date ).local().format('MMM DD, YYYY') } ),
        address: [displayObject.address['address_line_one'], displayObject.address['address_line_two'] ,displayObject.address['city'], displayObject.address['state'], displayObject.address['country'], displayObject.address['pin_code']].filter(Boolean).join(", "),
        logo_url: <Avatar size="md" name={displayObject.name || 'Give My Certificate'} src={displayObject.logo_url} />
    }
    const internalDisplayOrder = displayOrder.filter(item => !['controls'].includes(item as string))
    return (
        <DisplayObjectInCard 
            displayOrder={internalDisplayOrder}
            keysAndDisplayNameMapping={keysAndDisplayNameMapping}
            displayObject={internalDisplayObject}
        />
    )
}

export default ShowOrganization

import { 
    Box, 
    Button, 
    Heading, 
    Text, 
    useColorModeValue, 
    Stack, 
    Input, 
    ButtonGroup,
    IconButton,
    MenuButton,
    MenuList,
    MenuItem,
    Menu,
    Center,
    SkeletonCircle,
    Skeleton
} from '@chakra-ui/react'
import { FaCaretDown, FaCheck, FaEdit } from 'react-icons/fa'
import { AiOutlineClose } from 'react-icons/ai'
import React, { useState as useReactState, useRef } from 'react'
import { CardWithAvatar } from '../../components/cardWithAvatar/CardWithAvatar'
import { ProfileForm } from '../../components/profileForm/ProfileForm'
import { CardContent } from '../../components/cardContent/CardContent'
import { UserInfo } from '../../components/userInfo/UserInfo'
import { HiPencilAlt } from 'react-icons/hi'
import supabase from '../../configs/Supabase'
import { defaultAvatarsType } from '../../dataObjects/DefaultAvatarsObject'
import { displayError, getUniqueId, isFileImage } from '../../helpers/CommonFunctions'
import { CustomError, useAuth } from '../../contexts/Auth'
import { IMAGE_TOO_LARGE_MESSAGE, IMAGE_TOO_LARGE_TITLE, NOT_IMAGE_MESSAGE, NOT_IMAGE_TITLE, USER_ERROR_NOT_ALLOWED } from '../../configs/GlobalConstants'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getUserProfileData, setUserProfileData } from '../../dataFetchers/userProfileDataFetcher'
import { randomUserAvatar } from '../../stores/UserDataStore'
import { useState } from '@hookstate/core'
import { Untracked } from '@hookstate/untracked';
import { ErrorComponent } from '../../components/errorComponent/ErrorComponent'
import moment from 'moment'
import { globalCacheBuster } from '../../stores/CacheBuster'

export interface storageFunctionsReturnValueType {
    data: {
        Key: string
    } | null
    error: CustomError | null
}

export const UserProfile = () => {

    const userAvatarObj = useState<defaultAvatarsType>(randomUserAvatar)
    const { user } = useAuth()
    const profilePicRef = useRef<HTMLInputElement>(null)
    const [photoURL, setPhotoURL] = useReactState<string>(userAvatarObj.get().URL)
    const [showUpdateProfileButton, setShowUpdateProfileButton] = useReactState(false)
    const [isProfilePicSaving, setIsProfilePicSaving] = useReactState(false)
    const cacheBuster = useState(globalCacheBuster)
    const isProfilePicAvailable = useState(false)
    isProfilePicAvailable.attach(Untracked)
    const subHeadingColour = useColorModeValue('gray.600', 'gray.400')

    const queryClient = useQueryClient()
    const { isError, isLoading, data, error } = useQuery('userProfile', getUserProfileData)
    const mutation = useMutation(setUserProfileData, {
        onSuccess: data => {
          if ( !data.isQueryError ) {
            queryClient.setQueryData('userProfile', data)
          }
        }
    })

    const handleChangeProfilePicButtonClick = () => {
        profilePicRef.current?.click()
    }

    const handleProfilePicChange = () => {

        const fileHandler = profilePicRef?.current?.files![0]

        if (fileHandler) {
            if (fileHandler.size > 2098000) {
                displayError( IMAGE_TOO_LARGE_TITLE, IMAGE_TOO_LARGE_MESSAGE )
                return false
            }
            if (isFileImage(fileHandler)) {
                setPhotoURL( URL.createObjectURL( fileHandler ) )
                userAvatarObj.URL.set( URL.createObjectURL( fileHandler ) )
                setShowUpdateProfileButton( true )
            } else {
                displayError( NOT_IMAGE_TITLE, NOT_IMAGE_MESSAGE )
            }
        }
    }

    const saveProfilePicChange = async () => {
        setIsProfilePicSaving(true)
        const fileName = profilePicRef.current?.files![0]
        let returnVal: storageFunctionsReturnValueType

        if ( isProfilePicAvailable.get() ) {
            returnVal = await supabase.storage
                                    .from('public')
                                    .update('avatars/'+user?.id+'/avatar.png', fileName!)
        } else {
            returnVal = await supabase.storage
                                    .from('public')
                                    .upload('avatars/'+user?.id+'/avatar.png', fileName!)
        }
        const { data, error } = returnVal

        if ( data ) {
            const avatarUrl = supabase.storage.from('public').getPublicUrl( 'avatars/'+user?.id+'/avatar.png' ).publicURL
            const updateProfileDataObject = {
                id: user?.id,
                avatarURL: avatarUrl
            }
            await onSubmitFunction(updateProfileDataObject)
            cacheBuster.profilePic.set( getUniqueId() )
            setShowUpdateProfileButton( false )
        } else if ( error ) {
            displayError( USER_ERROR_NOT_ALLOWED, error.message )
        }
        setIsProfilePicSaving(false)
    }

    const onSubmitFunction = async ( value:any ) => {
        return await mutation.mutateAsync(value)
    }

    if ( data ) {
        const { queryData, queryError } = data

        if ( queryError ) {
            displayError( 'Action not allowed', queryError.message || queryError.details || queryError.hint)
        }

        if ( queryData ) {

            const initialData = {
                fullName: queryData.full_name || userAvatarObj.displayName.get(), 
                gender: ( queryData.gender ) ? ( (['male', 'female'].includes(queryData.gender)) ? queryData.gender : 'other' ) : null,
                genderString: (['male', 'female'].includes(queryData.gender!)) ? 'other' : queryData.gender,
                dob: moment( queryData.date_of_birth ).format('YYYY-MM-DD').toString() || moment(new Date()).subtract( 30, 'days' ).format('YYYY-MM-DD').toString(), 
                linkedinId: queryData.linkedin_id, 
                facebookHandle: queryData.facebook_handle, 
                twitterHandle: queryData.twitter_handle, 
                instaHandle: queryData.insta_handle, 
                about: queryData.about || userAvatarObj.superPowers.get(),
                avatarURL: ( queryData.avatar_url ) ? queryData.avatar_url+'?cacheBuster='+cacheBuster.profilePic.get() : userAvatarObj.URL.get(),
                website: queryData.website,
                country: queryData.country,
                state: queryData.state
            }

            Untracked(isProfilePicAvailable).set( (queryData.avatar_url) ? true : false )

            return (
                <Box as="section" pt="20" pb="12" position="relative">
                    <Box position="absolute" inset="0" height="32" bg="blue.600" />
                    <CardWithAvatar
                        maxW="2xl"
                        avatarProps={{
                            src: ( showUpdateProfileButton ) ? photoURL : initialData.avatarURL,
                            name: initialData.fullName,
                        }}
                        action={
                            <>
                            <Input 
                                type="file" 
                                id="profilePicHiddenInput" 
                                name="file" 
                                ref={profilePicRef} 
                                accept="image/png, image/jpeg, image/jpg" 
                                display="none" 
                                onChange={handleProfilePicChange}
                            />
                            {
                                ( showUpdateProfileButton )?
                                <>
                                    <ButtonGroup size="sm" isAttached >
                                        <Button 
                                            mr="-px"
                                            leftIcon={< FaCheck />}
                                            onClick={ saveProfilePicChange }
                                            isLoading={isProfilePicSaving}
                                            loadingText="Saving..."
                                        >
                                            Save
                                        </Button>
                                        <Menu>
                                            <MenuButton as={IconButton} aria-label="More Options" icon={< FaCaretDown />} />
                                            <MenuList>
                                                <MenuItem 
                                                    icon={< FaEdit />} 
                                                    onClick={ handleChangeProfilePicButtonClick }
                                                >Change</MenuItem>
                                                <MenuItem 
                                                    icon={< AiOutlineClose />} 
                                                    onClick={ () => { 
                                                        setShowUpdateProfileButton( false ) 
                                                        setPhotoURL( userAvatarObj.URL.get() )
                                                        userAvatarObj.URL.set( initialData.avatarURL )
                                                        //@ts-ignore
                                                        document.getElementById('profilePicHiddenInput').value = ''
                                                    }} 
                                                >
                                                    Cancel
                                                </MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </ButtonGroup>
                                </>
                                :
                                <Button 
                                    size="sm" 
                                    leftIcon={<HiPencilAlt />}
                                    onClick={ handleChangeProfilePicButtonClick }
                                >
                                    Edit
                                </Button>
                            }
                            </>
                        }
                    >
                        <CardContent>
                            <Heading size="lg" fontWeight="extrabold" letterSpacing="tight" textTransform="capitalize" >
                                { initialData.fullName }
                            </Heading>
                            <Text color={subHeadingColour} textTransform="capitalize" mt={2} >
                                { initialData.about }
                            </Text>
                            <UserInfo
                                location= { initialData.country ? `${initialData.state}, ${initialData.country}` : undefined} 
                                website= { initialData.website ? initialData.website : undefined}
                                memberSince= { initialData.dob ? `Born ${moment(initialData.dob).format('DD MMM YYYY')}` : undefined} />
                        </CardContent>
                        <ProfileForm initialData={initialData} onSubmitFunction={onSubmitFunction} />
                    </CardWithAvatar>
                </Box>
            )
        }
    }

    if ( isError ) {
        return (
            <ErrorComponent
                //@ts-ignore
                errorMessage={error.message}
            />
        )
    }

    if ( isLoading ) {
        return (
            <>
                <Center>
                    <SkeletonCircle size="20" mt={5} />
                </Center>
                <Stack mt={10} spacing={5} >
                    <Skeleton height="20px" />
                    <Skeleton height="20px" />
                    <Skeleton height="20px" />
                </Stack>
            </>
        )
    }

    if ( !data ) {
        return (
            <ErrorComponent
                //@ts-ignore
                errorMessage={error.message}
            />
        )
    }

    return (
        <ErrorComponent />
    )
}

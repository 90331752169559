import { Box, Table, TableCaption, Thead, Tr, Th, Tbody, Td, Flex, Text, Divider, Stack, useBreakpointValue, Spacer } from '@chakra-ui/react'
import React from 'react'
import { useState } from 'react'
import { FaServer, FaTable, FaThList } from 'react-icons/fa'
import { ToggleButton } from '../toggleButton/ToggleButton'
import { ToggleButtonGroup } from '../toggleButtonGroup/ToggleButtonGroup'

export type portraitTableDisplayModeType = 'HCard' | 'VCard' | 'Table'

export default function ResponsiveTable( params:any ) {
    const { data, tableCaption, tablePropsToPass, containerPropsToPass, ...propsToPass } = params
    const deviceType = useBreakpointValue({ base: "portrait", md: "landscape" })
    const [portraitTableDisplayMode, setPortraitTableDisplayMode] = useState<portraitTableDisplayModeType>('HCard')

    const LandscapeDisplay = () => {
        return(
            <Box
                {...containerPropsToPass}
                overflowX="auto"
                p={3}
            >
                <Table colorScheme="teal" {...tablePropsToPass} {...propsToPass} >
                    { tableCaption && <TableCaption>{tableCaption}</TableCaption> }
                    <Thead>
                        <Tr>
                            {
                                data.keys.map((key:string, index:number) => {
                                    return (<Th key={`${index}header`}>{data.header[key]}</Th>)
                                })
                            }
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            data.body.map((item:any, bodyItemIndex:number) => (
                                    <Tr key={item.keyId || bodyItemIndex}>
                                        {
                                            data.keys.map((key:string, index:number) => {
                                                return (<Td minW="150px" maxW="300px" key={`${item.keyId || bodyItemIndex}-${index}row`}>{item[key]}</Td>)
                                            })
                                        }
                                    </Tr>
                                )
                            )
                        }
                    </Tbody>
                </Table>
            </Box>
        )
    }

    const PortraitDisplay = () => {
        return(
            <Stack
                {...containerPropsToPass}
                p={3}
                spacing={5}
            >
                {
                    data.body.map((item:any, bodyItemIndex:number) => {
                        return (
                            <Box boxShadow="md" p={3} rounded="3xl" borderWidth={1} key={item.keyId || bodyItemIndex} >
                                {
                                    data.keys.map((key:string, index:number) => (
                                            (portraitTableDisplayMode === 'VCard') ?
                                            (<Stack spacing={0} key={`${item.keyId || bodyItemIndex}-${index}row`}>
                                                <Flex direction="column" p="3" minH="60px" >
                                                    <Text fontWeight="bold">
                                                        {
                                                            data.header[key]
                                                        } :
                                                    </Text>
                                                    {
                                                        item[key]
                                                    }
                                                </Flex>
                                                {data.keys[index+1] && <Divider/>}
                                            </Stack>)
                                            :
                                            <Stack spacing={0} key={`${item.keyId || bodyItemIndex}-${index}row`}>
                                                <Flex as="dl" direction="row" p="3" minH="60px" alignItems="center">
                                                    <Flex as="dt" minW="100px" pr="3">
                                                        <Text fontWeight="bold">
                                                            {
                                                                data.header[key]
                                                            }
                                                        </Text>
                                                    </Flex>
                                                    <Divider orientation="vertical" h="40px" />
                                                    <Flex as="dd" flex="1" pl={3} wordBreak="break-all">
                                                        {
                                                            item[key]
                                                        }
                                                    </Flex>
                                                </Flex>
                                                {data.keys[index+1] && <Divider/>}
                                            </Stack>
                                    ))
                                }
                            </Box>
                        )
                    })
                }
            </Stack>
        )
    }

    const PortraitDisplayWithOptions = () => {
        return (
            <>
                <Flex>
                    <Spacer />
                    <ToggleButtonGroup
                        value={portraitTableDisplayMode}
                        onChange={setPortraitTableDisplayMode}
                        defaultValue="HCard"
                        isAttached
                        variant="outline"
                        aria-label="Align text"
                        mt={4}
                    >
                        <ToggleButton value="HCard" aria-label="Display data in horizontal card" icon={<FaThList />} />
                        <ToggleButton value="VCard" aria-label="Display data in vertical card" icon={<FaServer />} />
                        <ToggleButton value="Table" aria-label="Display data in table" icon={<FaTable />} />
                    </ToggleButtonGroup>
                </Flex>
                { (portraitTableDisplayMode === 'Table') ? <LandscapeDisplay /> : <PortraitDisplay /> }
            </>
        )
    }

    return (
        <>
            { (deviceType==="landscape") && <LandscapeDisplay /> }
            { (deviceType==="portrait") && <PortraitDisplayWithOptions /> }
        </>
    )
}

import { Box, Button, Collapse, Divider, Flex, FormControl, FormLabel, Heading, Icon, Input, Select, Spacer, Stack, Switch, Text, Textarea, Tooltip, VStack, RangeSlider, RangeSliderTrack, RangeSliderFilledTrack, RangeSliderThumb } from '@chakra-ui/react'
import { Untracked } from '@hookstate/untracked'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { VscQuestion } from 'react-icons/vsc'
import { AutoCompleteFromTableData } from '../../components/autoCompleteFromTableData/AutoCompleteFromTableData'
import { Card } from '../../components/card/Card'
import { CardWithColorAccent } from '../../components/cardWithColorAccent/CardWithColorAccent'
import { certificateTypeArray } from '../../dataObjects/globalDataObject'
import { displayError, displaySuccess, exportCSV, genericObjectType } from '../../helpers/CommonFunctions'
import { useHookState } from '../../hooks/useHookState'
import { organisationDataObjectType } from '../../types/dataFetcherTypes/DataFetcherTypes'
import { eventsDataObjectType } from '../../types/dataFetcherTypes/EventsDataFetcherTypes'
import { templatesDataObjectType } from '../../types/dataFetcherTypes/TemplatesDataFetcherTypes'
import Papa from 'papaparse'
import { JsonToTable, JsonToTableReturnType } from '../../components/jsonToTable/JsonToTable'
import { FaDownload, FaFilter } from 'react-icons/fa'
import { useMutation, useQueryClient } from 'react-query'
import { createJob } from '../../dataFetchers/jobsDataFetcher'
import { useAuth } from '../../contexts/Auth'
import { useHistory } from 'react-router-dom'
import { EmailTemplates, emailTemplatesRefFunctionType } from '../settings/EmailTemplates'
import { FIELDS_TO_EXCLUDE_FROM_CSV } from '../../configs/GlobalConstants'

export const NewCertificate = () => {
    const [organizationStateHolder, setOrganizationStateHolder, rawOrganizationStateHolder] = useHookState<organisationDataObjectType>({})
    rawOrganizationStateHolder.attach(Untracked)

    const [eventStateHolder, setEventStateHolder, rawEventStateHolder] = useHookState<eventsDataObjectType>({})
    rawEventStateHolder.attach(Untracked)

    const [templateStateHolder, setTemplateStateHolder, rawTemplateStateHolder] = useHookState<templatesDataObjectType>({})
    rawTemplateStateHolder.attach(Untracked)

    const [fileHolder, setFileHolder] = useState()
    const [urlHolder, setUrlHolder] = useState('')
    
    const [isValidCSVURL, setIsValidCSVURL] = useState(true)
    const [csvDataHolder, setCsvDataHolder] = useState<object[] | null>(null)
    const csvDataHolderCopy = useRef<object[] | null>(null)
    const certTypeRef = useRef<HTMLSelectElement>(null)
    const textAreaForCSVStringRef = useRef<HTMLTextAreaElement>(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isSendLaterSubmitting, setIsSendLaterSubmitting] = useState(false)
    const [isZoomDataLoading, setIsZoomDataLoading] = useState(false)
    const [showCustomEmailTemplate, setShowCustomEmailTemplate] = useState<number>(0)
    const emailTemplateRef = useRef<emailTemplatesRefFunctionType>(null)
    const jobNameRef = useRef<HTMLInputElement>(null)
    const webinarIdInputFieldRef = useRef<HTMLInputElement>(null)
    const sliderRangeTextHolderRef = useRef<HTMLParagraphElement>(null)
    const jsonToTableRef = useRef<JsonToTableReturnType>(null)

    const { user } = useAuth()
    const history = useHistory()

    const queryClient = useQueryClient()

    const createMutation = useMutation(createJob, {
        onSuccess: data => {
            if ( !data.isQueryError ) {
                queryClient.invalidateQueries('eventsData')
                displaySuccess('Job Created', 'We have created your job')
            }
        }
    })

    const handleDownloadSampleCSV = () => {
        if (!templateStateHolder.id) {
            displayError('Please Select Template', 'CSV fields are dependent on template selected. So please select one to proceed further')
        } else if (!templateStateHolder.elements) {
            displayError('Invalid Template Selected', 'Looks like the template that you have selected is not yet build completely. Please complete that or chose different one')
        } else {
            let sampleCSVJSONHolder = []
            let sampleCSVRow:any = {}

            let CSVKeys = [
                ...Object.keys(templateStateHolder.elements)
                    .filter( i => !FIELDS_TO_EXCLUDE_FROM_CSV.includes(i) )
                    .map(key => templateStateHolder?.elements![key]['header']), 
                'email'
            ]
            
            CSVKeys.map( key => {
                switch (key) {
                    case 'candidate_name':
                        sampleCSVRow['candidate_name'] = 'Satyapal Sharma'
                        break;

                    case 'name':
                        sampleCSVRow['name'] = 'Satyapal Sharma'
                        break;

                    case 'email':
                        sampleCSVRow['email'] = 'support@givemycertificate.com'
                        break;

                    case 'date':
                        sampleCSVRow['date'] = moment().format('YYYY-MM-DD')
                        break;
                
                    default:
                        sampleCSVRow[key] = `Value for key ${key}`
                        break;
                }
                return true
            })
            sampleCSVJSONHolder.push(sampleCSVRow)
            
            exportCSV(Papa.unparse(sampleCSVJSONHolder), `Sample_CSV_for_${templateStateHolder.name || 'data_upload'}.csv`)
        }
    }

    const handleFileChange = (e:any) => {
        const file = e.target.files[0]
        if (file) {
            if (file.name) {
                setFileHolder(file)
            } else {
                displayError('Not a csv file', 'We are supporting only CSV files for now')
            }
        }
    }

    const handleFileURLChange = (e:any) => {
        const url = e.target.value
        if (url.length === 0 || url.split('.').pop() === 'csv') {
            setUrlHolder(url)
            setIsValidCSVURL(true)
        } else {
            setIsValidCSVURL(false)
        }
    }

    const checkCSVColumns = (csvRow:object) => {
        if (!templateStateHolder.elements) {
            displayError('Please Select Template', 'Jobs are dependent on template selected. So please select one to proceed further')
            return false
        }
        let CSVKeys = [
            ...Object.keys(templateStateHolder.elements)
                .filter( i => !FIELDS_TO_EXCLUDE_FROM_CSV.includes(i) )
                .map(key => templateStateHolder?.elements![key]['header']), 
            'email'
        ]

        const providedFields = Object.keys(csvRow)
        const missingFields = CSVKeys.filter( key => !providedFields.includes(key))

        if (missingFields.length > 0) {
            displayError('Not all fields are provided', `Following fields are missing from CSV. "${missingFields.join(', ')}". Please add these fields to CSV before proceeding further`)
            return false
        }
        return true
    }

    // const trimSpacesFromEmail = () => {
    //     removeTrailingSpaces()
    // }

    const parseCSV = () => {
        if( fileHolder ) {
            Papa.parse(fileHolder as unknown as File, {
                worker: true,
                header: true,
                // transformHeader doesn't work with worker:true so commenting it for now 
                // transformHeader: (header) => {
                //     return header.toLowerCase()
                // },
                skipEmptyLines: 'greedy',
                complete: (result) => {
                    const data = result.data as object[]
                    const isValidData = checkCSVColumns(data[0])
                    if (!isValidData) {
                        return false
                    }
                    
                    //Redundent code as skipEmptyLines: 'greedy' does the same thing
                    // const nonEmptyResult = filterEmptyValues(data, 'email')
                    // if (data.length > nonEmptyResult.length) {
                    //     displayInfo('Empty Rows detected in CSV Data', `We have detected ${data.length - nonEmptyResult.length} empty rows in data and removed them, Please check if not intentional`)
                    // }
                    setCsvDataHolder(data)
                }
            });
        } else if (urlHolder){
            Papa.parse(urlHolder, {
                worker: true,
                header: true,
                download:true,
                skipEmptyLines: 'greedy',
                complete: (result) => {
                    const data = result.data as object[]
                    const isValidData = checkCSVColumns(data[0])
                    if (!isValidData) {
                        return false
                    }
                    setCsvDataHolder(data)
                }
            });
        }
    }

    const parseString = () => {
        let CSVString = textAreaForCSVStringRef.current?.value!
        Papa.parse(CSVString, {
            worker: true,
            header: true,
            skipEmptyLines: 'greedy',
            complete: (result) => {
                const data = result.data as object[]
                const isValidData = checkCSVColumns(data[0])
                if (!isValidData) {
                    return false
                }
                setCsvDataHolder(data)
            }
        })
    }

    const fetchDataFromZoom = () => {
        setIsZoomDataLoading(true)
        const webinarId = webinarIdInputFieldRef.current?.value.replace(/\s/g, '')

        if (!webinarId || webinarId.length < 1) {
            displayError('Invalid webinar ID', 'Sorry, webinar ID can not be null')
            webinarIdInputFieldRef.current?.focus()
            setIsZoomDataLoading(false)
            return false
        }
        
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
        "user_id": user?.id,
        "webinar_id": webinarId
        });

        const requestOptions:RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch("https://asia-south1-gmc-testing.cloudfunctions.net/zoomDataFetch/zoom/data", requestOptions)
        .then(async response => {
            const responseBody = await response.json()
            const responseStatus = await response.status
            const data = responseBody.data
            if (responseStatus !== 200) {
                displayError(responseBody.message, responseBody.error)
                setIsZoomDataLoading(false)
                return false
            }
            const isValidData = checkCSVColumns(data[0])
            if (!isValidData) {
                setIsZoomDataLoading(false)
                return false
            }
            csvDataHolderCopy.current = data
            setCsvDataHolder(data)
            setIsZoomDataLoading(false)
        })
        .catch(error => console.log('error', error));
    }

    const submitJob = async (sendLater?:boolean) => {

        const emailTemplateData = emailTemplateRef.current?.getFullTemplate()
        const finalJsonHolder = jsonToTableRef.current?.getSelectedData()
           
        let certType = certTypeRef.current?.value
        if (!organizationStateHolder.organization_id) {
            displayError('Please Select Organization', 'You need to select an organization to submit job')
            return false
        }
        if (!eventStateHolder.id) {
            displayError('Please Select Event', 'You need to select an event to submit job')
            return false
        }
        if (!templateStateHolder.id) {
            displayError('Please Select Template', 'Jobs are dependent on template selected. So please select one to proceed further')
            return false
        }
        if (!certType) {
            displayError('Please Select Certificate Type', 'You need to select certificate type to submit job')
            return false
        }
        if (!templateStateHolder.elements) {
            displayError('Please Select Template', 'Jobs are dependent on template selected. So please select one to proceed further')
            return false
        }
        if (!finalJsonHolder || finalJsonHolder.length === 0) {
            displayError('User data can not be null', 'Looks like you haven\'t imported data yet.')
            return false
        }

        let CSVKeys = [
            ...Object.keys(templateStateHolder.elements)
                .filter( i => !FIELDS_TO_EXCLUDE_FROM_CSV.includes(i) )
                .map(key => templateStateHolder?.elements![key]['header']), 
            'email'
        ]

        const providedFields = Object.keys(finalJsonHolder[0])
        const missingFields = CSVKeys.filter( key => !providedFields.includes(key))

        if (missingFields.length > 0) {
            displayError('Not all fields are provided', `Following fields are missing from CSV. "${missingFields.join(', ')}"`)
            return false
        }

        let finalCsvData = finalJsonHolder?.map( (item:any) => (
            {
                ...item,
                certificateType: certType
            }
        ))

        if (sendLater) {
            setIsSendLaterSubmitting(true)
        } else {
            setIsSubmitting(true)
        }

        await createMutation.mutateAsync({
            name: jobNameRef.current?.value,
            organization_id: organizationStateHolder.organization_id,
            event_id: eventStateHolder.id,
            template_id: templateStateHolder.id,
            certificate_type: certType,
            user_id: user?.id,
            data: finalCsvData,
            is_custom_email_template_set: (showCustomEmailTemplate === 1),
            custom_email_template_subject: emailTemplateData?.subject,
            custom_email_template: emailTemplateData?.template,
            send_later: sendLater
        })
        // try {
        //     const processJob = await fetch('https://us-central1-gmc-testing.cloudfunctions.net/processNewJobs')
        //     console.log(processJob)
        // } catch (error) {
        //     console.log(error)
        // }
        if (sendLater) {
            setIsSendLaterSubmitting(false)
        } else {
            setIsSubmitting(false)
        }
        history.push('/dashboard/certificates')
    }

    const filterDataOnSliderChange = (val:number[]) => {
        const dataToUpdate = csvDataHolderCopy.current?.filter( dataVal => (
            //@ts-ignore
            (val[0] <= parseInt(dataVal.duration)) && (parseInt(dataVal.duration) <= val[1])
        ))
        if (dataToUpdate) {
            setCsvDataHolder(dataToUpdate)
        }
    }

    const getSliderRange = (data=null) => {
        const dataToParse = data || csvDataHolderCopy.current
        const durationArray = dataToParse?.map((obj:genericObjectType) => (obj.duration))
        if (durationArray) {
            return {
                min: Math.min(...durationArray),
                max: Math.max(...durationArray)
            }
        }
        return {
            min: 0,
            max: 100
        }
    }

    const updateSliderRangeText = (min:number, max:number) => {
        if (sliderRangeTextHolderRef.current) {
            sliderRangeTextHolderRef.current.innerText = ` ${min} - ${max} `
        }
    }

    const updateSliderRange = (val:number[]) => {
        updateSliderRangeText(val[0], val[1])
    }

    return (
        <Card>
            <Flex direction="column" alignItems="flex-start">
                <Heading size="md" mb={3}>
                    Name your job
                </Heading>
                <Input ref={jobNameRef} placeholder='Give your job a super memorable name' />
                <Divider my={6} />

                <Heading size="md" mb={3}>
                    Choose Event details
                </Heading>
                <Stack direction={['column', null, "row", null]} spacing={4} justifyContent="space-between" w="100%">

                {/**********************************************************************************
                **                                                                                ** 
                **   Can't deside between wrap and stack layout on the basis of which one         **
                **   looks better so leaving both of these implementations in code for now.       **
                **                                                                                **
                **   Hope to hire a UX designer in near future and ask him the dificult question  **
                **                                                                                **
                ***********************************************************************************/}

                {/* <Wrap justifyContent="space-between" spacing={4}>
                    <WrapItem> */}
                        <CardWithColorAccent>
                            <AutoCompleteFromTableData 
                                fromTable='organizations' 
                                fetchKey='name' 
                                keyName="organization_id" 
                                placeholder="Organizations Select..." 
                                label="Organization"
                                tooltipText="Select Organization for which you want to send certificates"
                                onChangeFunction={setOrganizationStateHolder}
                            />
                        </CardWithColorAccent>
                    {/* </WrapItem>
                    <WrapItem> */}
                        <CardWithColorAccent>
                            <AutoCompleteFromTableData 
                                fromTable='events' 
                                fetchKey='name' 
                                keyName="id" 
                                placeholder="Events Select..." 
                                label="Event"
                                tooltipText="Select Event for which you want to send certificates"
                                onChangeFunction={setEventStateHolder}
                            />
                        </CardWithColorAccent>
                    {/* </WrapItem>
                    <WrapItem> */}
                        <CardWithColorAccent>
                            <AutoCompleteFromTableData 
                                fromTable='templates' 
                                fetchKey='name' 
                                keyName="id" 
                                placeholder="Templates Select..." 
                                label="Template"
                                tooltipText="Select Templates for which you want to send certificates"
                                errorMessage={ ( templateStateHolder.id && ( templateStateHolder.elements === null )) ? 'Looks like this template is not yet Completed' : undefined }
                                onChangeFunction={setTemplateStateHolder}
                            />
                        </CardWithColorAccent>
                    {/* </WrapItem>
                    <WrapItem> */}
                        <CardWithColorAccent>
                            <FormControl>
                                <Flex alignItems="center" justifyContent="space-between">
                                    <FormLabel>Certificate Type</FormLabel>
                                    <Tooltip label="Select certificate type" placement="auto">
                                        <span>
                                            <Icon as={ VscQuestion } />
                                        </span>
                                    </Tooltip>
                                </Flex>
                                <Select placeholder="Certificate Type Select..." title="Certificate Type" ref={certTypeRef}>
                                    {
                                        certificateTypeArray.map( (item) => {
                                            return (
                                                <option key={item} value={item}>{item}</option>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </CardWithColorAccent>
                    {/* </WrapItem>
                </Wrap> */}
                </Stack>
                
                <Divider my={6} />

                <Flex w="100%" justifyContent="space-between" alignItems="center">
                    <VStack maxW="40%" alignItems="flex-start">
                        <Heading size="md">
                            Provide Data
                        </Heading>
                        <Text mb={3}>
                            (You only need to provide one)
                        </Text>
                    </VStack>
                    <Button 
                        variant="outline"
                        onClick={handleDownloadSampleCSV}
                        maxW="40%"
                        whiteSpace="normal"
                        size="md"
                        rightIcon={<FaDownload />}
                    >
                        <Text fontSize={["xx-small", 'sm', 'md', 'md']}>
                            Download Sample CSV
                        </Text>
                    </Button>
                </Flex>
                
                <Stack direction={['column', null, "row", null]} spacing={4} justifyContent="space-between" w="100%" mt={3}>
                    <CardWithColorAccent w={['100%', null, '45%', null]}>
                        <FormControl>
                            <FormLabel>Upload CSV</FormLabel>
                            <Input 
                                type="file" 
                                p="4px" 
                                accept=".csv"
                                onChange={(e) => {
                                    handleFileChange(e)
                                }}
                                onClick={(e) => {
                                    e.currentTarget.value = ''
                                }}
                            />
                        </FormControl>

                        <Divider my={8} />
                        <Box border="1px solid" borderRadius="50%" w="fit-content" p={2} mx="auto" mt="-52px">
                            Or
                        </Box>

                        <FormControl mt={3}>
                            <FormLabel>Fetch from an URL</FormLabel>
                            <Input 
                                type="url" 
                                isInvalid={!isValidCSVURL}
                                errorBorderColor="red.300"
                                onChange={(e) => {
                                    handleFileURLChange(e)
                                }}
                            />
                        </FormControl>
                        <Flex mt="3" justifyContent="center">
                            <Button variant="solid" colorScheme="blue" onClick={parseCSV} >
                                Fetch Data
                            </Button>
                        </Flex>
                    </CardWithColorAccent>

                    <CardWithColorAccent w={['100%', null, '45%', null]}>
                        <Flex direction="column" h="100%">
                            <FormControl mt={3}>
                                <FormLabel>Enter CSV String</FormLabel>
                                <Textarea
                                    ref={textAreaForCSVStringRef}
                                    placeholder="Here is a sample placeholder"
                                    size="md"
                                />
                            </FormControl>
                            <Flex mt="auto" justifyContent="center">
                                <Button variant="solid" colorScheme="blue" onClick={parseString} >
                                    Decode Data
                                </Button>
                            </Flex>
                        </Flex>
                    </CardWithColorAccent>
                </Stack>

                <CardWithColorAccent mt={4} w="100%" maxW="100%" >
                    <Flex direction="column" h="100%">
                        <FormControl mt={3}>
                            <FormLabel>Fetch From Zoom</FormLabel>
                            <Input ref={webinarIdInputFieldRef} placeholder='webinar Id' />
                        </FormControl>
                        <Flex mt="auto" justifyContent="center">
                            <Button 
                                variant="solid" 
                                colorScheme="blue" 
                                mt={4} 
                                onClick={fetchDataFromZoom} 
                                isLoading={isZoomDataLoading}
                                loadingText="Fetching..."
                            >
                                Fetch Data
                            </Button>
                        </Flex>
                        {
                            csvDataHolder && (csvDataHolder.length > 0) && csvDataHolderCopy.current && (csvDataHolderCopy.current.length > 0) &&
                            <>
                                <Divider my={8} />
                                <Flex mb={3} alignItems="center">
                                    <FaFilter />
                                    <Text pl={2} fontSize="xl">
                                        Filters
                                    </Text>
                                </Flex>
                                <Flex>
                                    <Text>
                                        Duration (Seconds): 
                                    </Text>
                                    <Text ref={sliderRangeTextHolderRef}>
                                        {getSliderRange().min} - {getSliderRange().max}
                                    </Text>
                                </Flex>
                                <RangeSlider
                                    min={getSliderRange().min || 0}
                                    max={getSliderRange().max || 100}
                                    defaultValue={[getSliderRange().min, getSliderRange().max]}
                                    onChange={updateSliderRange}
                                    onChangeEnd={filterDataOnSliderChange}
                                    minStepsBetweenThumbs={Math.ceil((getSliderRange().max-getSliderRange().min)/100)}
                                >
                                    <RangeSliderTrack>
                                        <RangeSliderFilledTrack />
                                    </RangeSliderTrack>
                                    <RangeSliderThumb index={0} boxSize={6} />
                                    <RangeSliderThumb index={1} boxSize={6} />
                                </RangeSlider>
                            </>
                        }
                    </Flex>
                </CardWithColorAccent>

                {
                    csvDataHolder && (csvDataHolder.length > 0) && 
                    <>
                        <CardWithColorAccent mt={4} w="100%" maxW="100%" maxH="300px" overflowY="auto">
                            <Flex position="sticky" top="0">
                                <Spacer />
                                <Text>Total line parsed :- {csvDataHolder.length}</Text>
                            </Flex>
                            <JsonToTable ref={jsonToTableRef} jsonData={csvDataHolder} isSelectable />
                        </CardWithColorAccent>
                    </>
                }
                
                <Divider my={6} />
                <Flex w="100%" justifyContent="space-between" mb={3}>
                    <Heading size="md" mb={3}>
                        Email Template
                    </Heading>
                    <Switch size="lg" value={showCustomEmailTemplate} onChange={(event) => {    
                        setShowCustomEmailTemplate(Math.abs(showCustomEmailTemplate-1))
                    }} />
                </Flex>
                {/* <EmailEditor /> */}
                
                <Collapse in={(showCustomEmailTemplate === 1)} style={{ width: '100%' }} animateOpacity>
                    <EmailTemplates ref={emailTemplateRef} />
                </Collapse>
                <Divider my={6} />
                <Flex w="100%" mt={4} justifyContent="center" >
                    <Button 
                        variant="solid" 
                        colorScheme="blue" 
                        isDisabled={(csvDataHolder && (csvDataHolder.length > 0))?false:true}
                        size="lg" 
                        px="10" 
                        mx='2'
                        isLoading={isSubmitting}
                        loadingText="Submitting..."
                        onClick={ () => submitJob(false) }
                    >
                        Submit Job
                    </Button>

                    <Button 
                        variant="solid" 
                        colorScheme="blue" 
                        isDisabled={(csvDataHolder && (csvDataHolder.length > 0))?false:true}
                        size="lg" 
                        px="8" 
                        mx='2'
                        isLoading={isSendLaterSubmitting}
                        loadingText="Submitting..."
                        onClick={ () => submitJob(true) }
                    >
                        Save For Later
                    </Button>
                </Flex>

            </Flex>
        </Card>
    )
}


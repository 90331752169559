import { Flex, Spacer, Table, Tbody, Td, Th, Thead, Tr, Text } from '@chakra-ui/react'
import React, { ChangeEvent, createRef, forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import { genericObjectType, stringOrNumber } from '../../helpers/CommonFunctions'
import { CustomCheckbox, CustomCheckboxType } from '../customCheckbox/CustomCheckbox';

export interface JsonToTableInputType {
    jsonData: {
        [key:string]:stringOrNumber | stringOrNumber[] | object
    }[] | object[];
    isSelectable?: boolean;
}

export interface JsonToTableReturnType {
    getSelectedData: () => genericObjectType[]
}

export const JsonToTable = forwardRef(( props:JsonToTableInputType, ref ) => {
    const { jsonData, isSelectable=false } = props
    const selectedCheckboxLenghtTextRef = useRef<HTMLParagraphElement>(null)
    const masterCheckboxRef = useRef<CustomCheckboxType>(null)
    const checkboxsRef = useRef(jsonData.map(() => createRef<CustomCheckboxType>()))
    const selectedCheckboxHolder = useRef<genericObjectType>({})

    useEffect(() => {
        selectedCheckboxHolder.current = {}
        jsonData.map((row:any, index) => (
            selectedCheckboxHolder.current[index] = true
        ))
        updateSelectedCheckboxText()
        updateMasterCheckboxState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jsonData])
    
    const updateSelectedCheckboxText = () => {
        if (selectedCheckboxLenghtTextRef.current) {
            selectedCheckboxLenghtTextRef.current.innerText = String(getSelectedCheckboxLength())
        }
    }

    const updateMasterCheckboxState = () => {
        const isAllSelected = Object.values(selectedCheckboxHolder.current).every(Boolean)
        const isIndeterminate = Object.values(selectedCheckboxHolder.current).some(Boolean) && !isAllSelected
        
        masterCheckboxRef.current?.setIndeterminate(isIndeterminate)
        masterCheckboxRef.current?.setChecked(isAllSelected)
        
    }

    const handleCheckboxClick = (el:ChangeEvent<HTMLInputElement>, index:number) => {
        selectedCheckboxHolder.current[index] = el.target.checked
        updateSelectedCheckboxText()
        updateMasterCheckboxState()
    }

    const handleMasterCheckboxClick = (el:ChangeEvent<HTMLInputElement>) => {
        const isChecked = el.target.checked

        if (isChecked) {
            jsonData.map((row:any, index) => {
                checkboxsRef.current[index].current?.setChecked(true)
                selectedCheckboxHolder.current[index] = true
                return true
            })
        } else {
            jsonData.map((row:any, index) => {
                checkboxsRef.current[index].current?.setChecked(false)
                selectedCheckboxHolder.current[index] = false
                return true
            })
        }
        updateSelectedCheckboxText()
        updateMasterCheckboxState()
    }

    const getSelectedCheckboxLength = () => {
        return Object.keys(selectedCheckboxHolder.current).filter(val => (
            selectedCheckboxHolder.current[val] === true
        )).length
    }

    useImperativeHandle( ref, () => ({
        getSelectedData: () => {
            const selectedCheckboxKeys = Object.keys(selectedCheckboxHolder.current).filter(val => (
                selectedCheckboxHolder.current[val] === true
            ))
            return selectedCheckboxKeys.map(key => (
                jsonData[parseInt(key)]
            ))
        }
    }))

    return (
        <>
            <Flex position="sticky" top="6">
                <Spacer />
                <Text>
                    Total line selected :-&nbsp;
                </Text>
                <Text ref={selectedCheckboxLenghtTextRef}>
                    {getSelectedCheckboxLength()}
                </Text>
            </Flex>
            <Table variant="simple" colorScheme="blue">
                <Thead>
                    <Tr>
                        {
                            isSelectable &&
                            <Td key={`table_head_row_checkbox`}>
                                <CustomCheckbox 
                                    ref={masterCheckboxRef} 
                                    defaultChecked
                                    onChange={handleMasterCheckboxClick}
                                />
                            </Td>
                        }
                        {
                            Object.keys(jsonData[0]).map(key => (
                                <Th key={`table_header_${key}`}>
                                    {key}
                                </Th>
                            ))
                        }
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        jsonData.map((row:any, index) => (
                            <Tr key={`table_body_row_number_${index}`}>
                                {
                                    isSelectable &&
                                    <Td key={row.id || `table_body_row_checkbox_${index}`}>
                                        <CustomCheckbox 
                                            defaultChecked 
                                            ref={checkboxsRef.current[index]}
                                            renderAsChecked={true}
                                            onChange={(el:ChangeEvent<HTMLInputElement>) => {
                                                handleCheckboxClick(el, index)
                                            }
                                        } />
                                    </Td>
                                }
                                {
                                    Object.keys(jsonData[0]).map(key => (
                                        <Td key={`table_body_row_number_${index}_${key}`}>
                                            {row[key]}
                                        </Td>
                                    ))
                                }
                            </Tr>
                        ))
                    }
                </Tbody>
            </Table>
        </>
    )
})


import { useForm } from "react-hook-form";
import React, { useEffect } from "react";
import { FormErrorMessage, FormLabel, FormControl, Input, Stack, StackDivider, VStack, StackProps } from "@chakra-ui/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldGroup } from "../fieldGroup/FieldGroup";
import { useAuth } from "../../contexts/Auth";

export type TemplateFormInputTypes = {
	name: string;
};

interface templateInputPropsType extends StackProps {
	actionOnSubmitSuccess?: (...args: any[]) => any;
	loadingStatus?: (...args: any[]) => any;
	formId?: string;
	onSubmitFunction: (arg: any) => any;
	initialData?: { [key: string]: any };
}

const CreateTemplateForm = (props: templateInputPropsType) => {
	const { actionOnSubmitSuccess, loadingStatus, formId, onSubmitFunction, initialData, ...restProps } = props;
	const { user } = useAuth();

	const schema = yup.object().shape({
		name: yup.string().required("name is required").min(4, "It looks very short"),
	});

	const methods = useForm<TemplateFormInputTypes>({
		mode: "onBlur",
		resolver: yupResolver(schema),
		defaultValues: initialData,
	});

	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = methods;

	useEffect(() => {
		if (loadingStatus) {
			loadingStatus(isSubmitting);
		}
	}, [loadingStatus, isSubmitting]);

	const handleSubmitButtonPress = async (values: TemplateFormInputTypes) => {
		const dataToUpdate = {
			name: values.name,
			user_id: user?.id,
		};

		await onSubmitFunction(dataToUpdate);
		if (actionOnSubmitSuccess) {
			actionOnSubmitSuccess();
		}
	};

	return (
		<form id={formId} onSubmit={handleSubmit(handleSubmitButtonPress)}>
			<Stack spacing='4' divider={<StackDivider />} {...restProps}>
				<FieldGroup title='Basic Info'>
					<VStack width='full' spacing='6'>
						<FormControl id='name' isInvalid={!!errors?.name} errortext={errors?.name?.message} isRequired>
							<FormLabel>Name</FormLabel>
							<Input type='text' maxLength={255} {...register("name")} />
							<FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
						</FormControl>
					</VStack>
				</FieldGroup>
			</Stack>
		</form>
	);
};

export default CreateTemplateForm;

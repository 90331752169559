import React, { useEffect, RefObject, useRef } from "react";
import { useState, State } from "@hookstate/core";
import Konva from "konva"
import { Stage, Image, Layer, Text as KonvaText } from "react-konva";
import useImage from "use-image";
import { Layer as KonvaLayer } from "konva/lib/Layer";
import { ElementsType } from "./DrawingSheet";

import DrawingSheet from "./DrawingSheet";
import DropperBox from "./DropperBox";
import { getSignedImageURL } from "../../dataFetchers/templatesDataFetcher";
import { Skeleton, Stack } from "@chakra-ui/react";
import { genericObjectType } from "../../helpers/CommonFunctions";

interface Bounds {
	height: number;
	width: number;
	top?: number;
	left?: number;
}

export interface Config {
	name: string;
	image_url: string;
	actual_dimensions: { width: number; height: number };
	rendered_dimensions: { width: number; height: number };
}

interface Props {
	elementsData: State<ElementsType>;
	selectedId: State<string | null>;
	layerRef: RefObject<KonvaLayer>;
	config: State<Config | null>;
}

const ArtBoard: React.FC<Props> = ({ elementsData, selectedId, layerRef, config }) => {
	// const imageURL = useState<string>("https://i.imgur.com/Aav84hq.jpeg");
	const imageURL = useState<string>("");
	const isLoading = useState<boolean>(true);
	const [image, imageStatus] = useImage(imageURL.get());

	const canvasBounds = useState<DOMRect>({ height: 1, width: 1 } as DOMRect);
	const konvaBounds = useState<Bounds>({ height: 1, width: 1 });
	const bgImgBounds = useState<Bounds>({ height: 1, width: 1 });
	const prevBounds = useState<Bounds>({ height: 0, width: 0 });
	const scale = useState<number>(1);
	const loader = useState<boolean>(false);
	const stageRef: React.RefObject<Konva.Stage> = useRef(null)

	const GUIDELINE_OFFSET = 5

	// let resizeTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {}, 100);

	const reDrawChildren = () => {
		if (layerRef?.current) {
			let { height, width } = prevBounds.get();
			elementsData.keys.forEach((element) => {
				if (height > 0 && width > 0) {
					// let { x, y } = elementsData[element].data;
					let factorX = konvaBounds.width.get() / width;
					let factorY = konvaBounds.height.get() / height;
					let attrs = elementsData[element].data;
					attrs.merge({ x: attrs.x.value * factorX, y: attrs.y.value * factorY });
					if ("fontSize" in attrs) attrs.fontSize.set(attrs.fontSize.value * factorX);

					if ("size" in attrs) attrs.merge({ size: attrs.size.value * factorX });

					// @ts-ignore
					if ("width" in attrs) attrs.merge({ width: attrs.width.value * factorX });
					// @ts-ignore
					if ("height" in attrs) attrs.merge({ height: attrs.height.value * factorY });
				}
			});
			// update the previous bounds of the board
			prevBounds.set({ ...konvaBounds.get() });
		}
	};

	const initRenderSizing = () => {
		if (loader.value) return;
		// @ts-ignore
		if (!config.value.rendered_dimensions) return;
		// @ts-ignore
		scale.set(konvaBounds.value.height / config.value?.rendered_dimensions.height);

		if (layerRef?.current) {
			elementsData.keys.forEach((element) => {
				let attrs = elementsData[element].data;
				attrs.merge({ x: attrs.x.value * scale.value, y: attrs.y.value * scale.value });
				if ("fontSize" in attrs) attrs.fontSize.set(attrs.fontSize.value * scale.value);

				if ("size" in attrs) attrs.merge({ size: attrs.size.value * scale.value });

				// @ts-ignore
				if ("width" in attrs) attrs.merge({ width: attrs.width.value * scale.value });
				// @ts-ignore
				if ("height" in attrs) attrs.merge({ height: attrs.height.value * scale.value });
			});
			loader.set(true);
		}
	};

	const initKonvaSize = () => {
		let bounds = document.getElementById("artboard")?.getBoundingClientRect();
		if (bounds) canvasBounds.set(bounds);
		if (canvasBounds && bgImgBounds) {
			// resize the canvas according to the image dimensions
			if (canvasBounds?.width.get() - bgImgBounds?.width.get() < canvasBounds.height.get() - bgImgBounds.height.get()) {
				konvaBounds.merge({
					height: (bgImgBounds.height.get() * canvasBounds.width.get()) / bgImgBounds.width.get(),
					width: canvasBounds.width.get(),
				});
			} else {
				konvaBounds.merge({
					height: canvasBounds.height.get(),
					width: (bgImgBounds.width.get() * canvasBounds.height.get()) / bgImgBounds.height.get(),
				});
			}
			// center align the canvas
			konvaBounds.merge({
				top: Math.abs(canvasBounds.height.get() - konvaBounds.height.get()) / 2,
				left: Math.abs(canvasBounds.width.get() - konvaBounds.width.get()) / 2,
			});
		}

		initRenderSizing();

		let { width, height } = konvaBounds.get();
		config.merge({ rendered_dimensions: { height, width }, actual_dimensions: bgImgBounds.get() });

		// redraw elements
		reDrawChildren();
	};

	useEffect(() => {
		if (imageStatus === "loaded") {
			bgImgBounds.set({ height: image?.height || 0, width: image?.width || 0 });
			initKonvaSize();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [imageStatus]);

	useEffect(() => {
		window.onresize = () => {
			// clearTimeout(resizeTimeout);
			// resizeTimeout = setTimeout(initKonvaSize, 10);
			initKonvaSize();
		};
		return () => {
			window.onresize = null;
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getLineGuideStops = (skipShape:string|null) => {
		if (!stageRef.current) {
			return {
				vertical: [],
				horizontal: [],
			};
		}
        // we can snap to stage borders and the center of the stage
        var vertical = [[0, stageRef.current?.width() / 2, stageRef.current?.width()]];
        var horizontal = [[0, stageRef.current?.height() / 2, stageRef.current?.height()]];

        // and we snap over edges and center of each object on the canvas
        stageRef.current?.find('.konva_object').forEach((guideItem) => {
          if (guideItem.attrs.id === skipShape) {	
            return;
          }
          var box = guideItem.getClientRect();
          // and we can snap to all edges of shapes
          vertical.push([box.x, box.x + box.width, box.x + box.width / 2]);
          horizontal.push([box.y, box.y + box.height, box.y + box.height / 2]);
        });
        return {
          vertical: vertical.flat(),
          horizontal: horizontal.flat(),
        };
      }

      // what points of the object will trigger to snapping?
      // it can be just center of the object
      // but we will enable all edges and center
      function getObjectSnappingEdges(node:any) {
        var box = node.getClientRect();
        var absPos = node.absolutePosition();

        return {
          vertical: [
            {
              guide: Math.round(box.x),
              offset: Math.round(absPos.x - box.x),
              snap: 'start',
            },
            {
              guide: Math.round(box.x + box.width / 2),
              offset: Math.round(absPos.x - box.x - box.width / 2),
              snap: 'center',
            },
            {
              guide: Math.round(box.x + box.width),
              offset: Math.round(absPos.x - box.x - box.width),
              snap: 'end',
            },
          ],
          horizontal: [
            {
              guide: Math.round(box.y),
              offset: Math.round(absPos.y - box.y),
              snap: 'start',
            },
            {
              guide: Math.round(box.y + box.height / 2),
              offset: Math.round(absPos.y - box.y - box.height / 2),
              snap: 'center',
            },
            {
              guide: Math.round(box.y + box.height),
              offset: Math.round(absPos.y - box.y - box.height),
              snap: 'end',
            },
          ],
        };
      }

      // find all snapping possibilities
      function getGuides(lineGuideStops:genericObjectType, itemBounds:genericObjectType) {
        var resultV:any[] = [];
        var resultH:any[] = [];

        lineGuideStops.vertical.forEach((lineGuide:number) => {
          itemBounds.vertical.forEach((itemBound:genericObjectType) => {
            var diff = Math.abs(lineGuide - itemBound.guide);
            // if the distance between guild line and object snap point is close we can consider this for snapping
            if (diff < GUIDELINE_OFFSET) {
              resultV.push({
                lineGuide: lineGuide,
                diff: diff,
                snap: itemBound.snap,
                offset: itemBound.offset,
              });
            }
          });
        });

        lineGuideStops.horizontal.forEach((lineGuide:number) => {
          itemBounds.horizontal.forEach((itemBound:genericObjectType) => {
            var diff = Math.abs(lineGuide - itemBound.guide);
            if (diff < GUIDELINE_OFFSET) {
              resultH.push({
                lineGuide: lineGuide,
                diff: diff,
                snap: itemBound.snap,
                offset: itemBound.offset,
              });
            }
          });
        });

        var guides = [];

        // find closest snap
        var minV = resultV.sort((a, b) => a.diff - b.diff)[0];
        var minH = resultH.sort((a, b) => a.diff - b.diff)[0];
        if (minV) {
          guides.push({
            lineGuide: minV.lineGuide,
            offset: minV.offset,
            orientation: 'V',
            snap: minV.snap,
          });
        }
        if (minH) {
          guides.push({
            lineGuide: minH.lineGuide,
            offset: minH.offset,
            orientation: 'H',
            snap: minH.snap,
          });
        }
        return guides;
      }

      function drawGuides(guides:genericObjectType[]) {
        guides.forEach((lg) => {
		let line = null
          if (lg.orientation === 'H') {
            line = new Konva.Line({
              points: [-6000, 0, 6000, 0],
              stroke: 'rgb(0, 161, 255)',
              strokeWidth: 1,
              name: 'guid-line',
              dash: [4, 6],
            });
            layerRef.current?.add(line);
            line.absolutePosition({
              x: 0,
              y: lg.lineGuide,
            });
          } else if (lg.orientation === 'V') {
            line = new Konva.Line({
              points: [0, -6000, 0, 6000],
              stroke: 'rgb(0, 161, 255)',
              strokeWidth: 1,
              name: 'guid-line',
              dash: [4, 6],
            });
            layerRef.current?.add(line);
            line.absolutePosition({
              x: lg.lineGuide,
              y: 0,
            });
          }
        });
      }

	useEffect(() => {
		layerRef.current?.on('dragmove touchmove', function (e) {

			// clear all previous lines on the screen
			layerRef.current?.find('.guid-line').forEach((l) => l.destroy());
	
			// find possible snapping lines
			var lineGuideStops = getLineGuideStops(selectedId.get());			
			
			// find snapping points of current object
			var itemBounds = getObjectSnappingEdges(e.target);
	
			// now find where can we snap current object
			var guides = getGuides(lineGuideStops, itemBounds);
	
			// do nothing of no snapping
			if (!guides.length) {
			  return;
			}
	
			drawGuides(guides);
	
			var absPos = e.target.absolutePosition();
			// now force object position
			guides.forEach((lg) => {
			  switch (lg.snap) {
				case 'start': {
				  switch (lg.orientation) {
					case 'V': {
					  absPos.x = lg.lineGuide + lg.offset;
					  break;
					}
					case 'H': {
					  absPos.y = lg.lineGuide + lg.offset;
					  break;
					}
				  }
				  break;
				}
				case 'center': {
				  switch (lg.orientation) {
					case 'V': {
					  absPos.x = lg.lineGuide + lg.offset;
					  break;
					}
					case 'H': {
					  absPos.y = lg.lineGuide + lg.offset;
					  break;
					}
				  }
				  break;
				}
				case 'end': {
				  switch (lg.orientation) {
					case 'V': {
					  absPos.x = lg.lineGuide + lg.offset;
					  break;
					}
					case 'H': {
					  absPos.y = lg.lineGuide + lg.offset;
					  break;
					}
				  }
				  break;
				}
			  }
			});
			e.target.absolutePosition(absPos);
		  });
	
		  layerRef.current?.on('dragend touchend', function (e) {
			// clear all previous lines on the screen
			layerRef.current?.find('.guid-line').forEach((l) => l.destroy());
		  });
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [imageStatus])
	

	const initConfig = async () => {
		let image_url = config.get()?.image_url;
		if (image_url) {
			const sURL = await getSignedImageURL(image_url);
			if (sURL) imageURL.set(sURL);
		}
		isLoading.set(false);
	};

	useEffect(() => {
		initConfig();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return !isLoading.get() ? (
		<>
			<Stage
				ref={stageRef}
				height={konvaBounds.height.get()}
				width={konvaBounds.width.get()}
				style={{ position: "absolute", marginLeft: konvaBounds.left.get(), marginTop: konvaBounds.top.get() }}>
				{imageURL.get().length > 0 && (
					<Layer
						ref={layerRef}
						onClick={(e) => {
							if (e.target.index === 0) selectedId.set(null);
						}}>
						{/* Background Image should be the first element in this layer */}
						{imageStatus === "loading" && <KonvaText fontSize={25} text='Loading...' />}
						<Image height={konvaBounds.height.get()} width={konvaBounds.width.get()} image={image} />
						<DrawingSheet elementsData={elementsData} selectedId={selectedId} />
					</Layer>
				)}
			</Stage>
			{imageURL.get().length < 1 && <DropperBox config={config} imageURL={imageURL} />}
		</>
	) : (
		<Stack>
			<Skeleton height='75vh' />
		</Stack>
	);
};

export default ArtBoard;

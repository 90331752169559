import supabase from "../configs/Supabase"
import { configDataObjectReturnType } from "../types/dataFetcherTypes/DataFetcherTypes"

export const getConfigData = async (): Promise<configDataObjectReturnType> => {
    try {
        let isError = false
        const { data, error } = await supabase
                                .from( 'organizations_premium_plan_config' )
                                .select( `
                                    max_allowed_organizations,
                                    plan_type,
                                    max_allowed_events,
                                    template_initial_elements,
                                    is_white_labeled,
                                    company_website,
                                    company_logo
                                `)
                                .single()
        if (error) {
            isError = true
        }

        return {
            isQueryError: isError,
            queryData: data,
            queryError: error
        }
    } catch (error) {
        throw error;  
    }
}

import { ChevronDownIcon } from '@chakra-ui/icons'
import { Skeleton, Stack, Textarea, Text, Flex, Heading, Spacer, Button, IconButton, Divider, CheckboxGroup, VStack, Checkbox, RadioGroup, Radio, Select, Tooltip, HStack, useDisclosure, Collapse, Input, Icon, useColorModeValue, Box, Menu, MenuButton, MenuDivider, MenuItem, MenuList, useBreakpointValue } from '@chakra-ui/react'
import { Persistence } from '@hookstate/persistence'
import { Untracked } from '@hookstate/untracked'
import moment from 'moment'
import Papa from 'papaparse'
import React, { ChangeEvent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AiOutlineDeliveredProcedure, AiOutlineDoubleLeft, AiOutlineDoubleRight } from 'react-icons/ai'
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleDown, FaAngleLeft, FaAngleRight, FaAngleUp, FaBinoculars, FaEdit, FaEnvelope, FaEnvelopeOpenText, FaEye, FaFileDownload, FaPaperPlane, FaPlus, FaRedo, FaTimes } from 'react-icons/fa'
import { HiPencilAlt } from 'react-icons/hi'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'
import { AddSubJobForm, AddSubJobFormType } from '../../components/addSubJobForm/AddSubJobForm'
import { Card } from '../../components/card/Card'
import { CardWithColorAccent } from '../../components/cardWithColorAccent/CardWithColorAccent'
import { CreateCertificateForm } from '../../components/createCertificateForm/CreateCertificateForm'
import { drawerComponentRefFunctionType, DrawerComponentWithRef } from '../../components/drawerComponent/DrawerComponent'
import { ErrorComponent } from '../../components/errorComponent/ErrorComponent'
import { InputWithCopy } from '../../components/inputWithCopy/InputWithCopy'
import { ModalComponentWithRef } from '../../components/modalComponent/ModalComponent'
import ResponsiveTable from '../../components/responsiveTable/ResponsiveTable'
import { ResponsiveTableVirtualMemo } from '../../components/responsiveTable/ResponsiveTableVirtual'
import { ShowJob } from '../../components/showJob/ShowJob'
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE } from '../../configs/GlobalConstants'
import supabase from '../../configs/Supabase'
import { useAuth } from '../../contexts/Auth'
import { deleteAllCertificatesInJob, deleteCertificate, deleteCertificates, updateCertificate } from '../../dataFetchers/certificateDataFetcher'
import { addSubJob, fetchJobDetails } from '../../dataFetchers/jobsDataFetcher'
import { paginationPageSizeArray } from '../../dataObjects/globalDataObject'
import { createCookie, displayError, displaySuccess, exportCSV, genericObjectType, recreateListWithDesiredOrder, removeKeyFromObject, stringOrNumber } from '../../helpers/CommonFunctions'
import { useConfig } from '../../hooks/useConfig'
import { useHookState } from '../../hooks/useHookState'
import { ActionBarObjectType, certificateDataType } from '../../types/dataFetcherTypes/JobsDataFetcherTypes'
const ResponsiveTableMemo = memo(ResponsiveTable)

function useLocationQuery() {
    return new URLSearchParams(useLocation().search);
}

export const JobStatus:React.FC<RouteComponentProps> = ( {match} ) => {
    const locationQuery = useLocationQuery()
    const history = useHistory()
    const { isWhiteLabeled } = useConfig()

    const [pageNo, setPageNo] = useState( parseInt(locationQuery.get('page') || DEFAULT_PAGE_NO.toString() ) )
    const [pageSize, setPageSize, rawPageSize] = useHookState( parseInt(locationQuery.get('size') || localStorage.getItem('global-page-size') || DEFAULT_PAGE_SIZE.toString() ) )  
    rawPageSize.attach(Persistence('global-page-size'))
    
    const [isCsvGenerating, setIsCsvGenerating] = useState(false)
    const [serchText, setSerchText] = useState<string|null>(null)
    const [searchOnColumn, setSearchOnColumn] = useState('recipient_name')
    const [isSaveCertificateLoading, setIsSaveCertificateLoading] = useState(false)
    const [loadingButtons, setLoadingButtons] = useState<{[key:string|number]:any}>({})
    const [checkboxStatusHolder, setCheckboxStatusHolder] = useState<{[key:string|number]:any}>({})
    const [isSubJobAdding, setIsSubJobAdding] = useState(false)
    const editCertificateDwawerRef = useRef<drawerComponentRefFunctionType>()
    const { isOpen, onToggle } = useDisclosure()
    const timeoutHolderForSearch = useRef<any>(null)
    const backgroundColor = useColorModeValue('gray.200', 'inherit')
    const showActionBarBackgroundColor = useColorModeValue('white', 'gray.700')
    const checkBoxStatus = useRef<genericObjectType>({})
    const AddSubJobFormRef = useRef<AddSubJobFormType>()

    const { id } = match.params as unknown as { id:string }
    const { user } = useAuth()

    const toolbarVerticalMarginValue = useBreakpointValue({
        base: 1,
        md: undefined,
        lg: undefined,
        xl: undefined,
        '2xl': undefined
    })

    const availableColumns = [
        "id",
        "certificate_short_code",
        "recipient_name",
        "recipient_email",
        "extra_metadata",
        "type",
        "download_link",
        "verification_link",
        "created_at",
        "updated_at",
        "status",
        "controls",
    ]

    const availableColumnsForSorting = [
        "id",
        "certificate_short_code",
        "recipient_name",
        "recipient_email",
        "created_at",
        "updated_at",
    ]

    const availableColumnsForSearch = [
        "recipient_name",
        "recipient_email"
    ]

    const defaultDisplayColumns = [
        "id",
        "recipient_name",
        "download_link",
        "verification_link",
        "status",
        "controls",
    ]

    const [columnsToDisplay, setColumnsToDisplay, rawColumnsToDisplay]  = useHookState<stringOrNumber[]>(defaultDisplayColumns)
    const [sortOnColumn, setSortOnColumn, rawSortOnColumn] = useHookState('updated_at')
    const [sortOrder, setSortOrder, rawSortOrder] = useHookState('descending')
    const [itemToUpdate, setItemToUpdate, rawItemToUpdate] = useHookState<certificateDataType>({})
    const [isActionbarDisplay, setIsActionbarDisplay, rawIsActionbarDisplay] = useHookState(true)

    rawColumnsToDisplay.attach(Persistence('job-columns-to-display'))
    rawSortOnColumn.attach(Persistence('job-sort-on-column'))
    rawSortOrder.attach(Persistence('job-sort-order'))
    rawIsActionbarDisplay.attach(Persistence('certificate-status-action-bar-display-status'))
    rawItemToUpdate.attach(Untracked)

    const queryClient = useQueryClient()
    const { isError, isLoading, data, error, isFetching } = useQuery( ['jobStatus', id, pageNo, pageSize, sortOnColumn, sortOrder, serchText, searchOnColumn ], () => fetchJobDetails( id, pageNo, pageSize, sortOnColumn, sortOrder, serchText, searchOnColumn ), { keepPreviousData : true })

    const columnToDisplayValMapping:{ [key: string]: any } = {
        id: <Text>
                Certificate Id
            </Text>,
        certificate_short_code: "Certificate Display Id",
        recipient_name: "Recipient Name",
        recipient_email: "Recipient Email",
        extra_metadata: "Extra Metadata",
        type: "Certificate Type",
        download_link: "Download Link",
        verification_link: "Verification Link",
        created_at: "Created On",
        updated_at: "Updated On",
        status: "Status",
        controls: "Controls"
    }

    const updateMutation = useMutation(updateCertificate, {
        onSuccess: data => {
            if ( !data.isQueryError ) {
                queryClient.invalidateQueries('jobStatus')
                displaySuccess('Certificate Updated', 'Certificate is updated successfully')
            }
        }
    })

    const deleteMutation = useMutation(deleteCertificate, {
        onSuccess: data => {
            if ( !data.isQueryError ) {
                queryClient.invalidateQueries('jobStatus')
                displaySuccess('Certificate Deleted', 'Certificate is deleted')
            }
        }
    })

    const multipleDeleteMutation = useMutation(deleteCertificates, {
        onSuccess: data => {
            if ( !data.isQueryError ) {
                queryClient.invalidateQueries('jobStatus')
                displaySuccess('Certificate Deleted', 'Certificate is deleted')
            }
        }
    })

    const allDeleteMutation = useMutation(deleteAllCertificatesInJob, {
        onSuccess: data => {
            if ( !data.isQueryError ) {
                queryClient.invalidateQueries('jobStatus')
                displaySuccess('Certificate Deleted', 'Certificate is deleted')
            }
        }
    })

    useEffect( () => {
        const params = new URLSearchParams()
        if (pageNo) {
            params.append("page", pageNo.toString() )
        } else {
            params.delete("page")
        }
        if (pageSize) {
            params.append("size", pageSize.toString() )
        } else {
            params.delete("size")
        }
        if ( locationQuery.get('page') || (pageNo !== DEFAULT_PAGE_NO) || (pageSize !== DEFAULT_PAGE_SIZE) ) {
            history.replace({search: params.toString()})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ pageNo, pageSize, history ])

    const downloadCsvData = async () => {
        setIsCsvGenerating(true)
        const {data, error} = await supabase.from('certificates')
                                            .select(`
                                                id,
                                                recipient_name,
                                                recipient_email,
                                                extra_metadata,
                                                type,
                                                created_at,
                                                certificate_short_code,
                                                organizations(
                                                    subdomain
                                                )
                                            `)
                                            .eq('job_id', id)
                                            .order('created_at', { ascending: false })

        if (error) {
            displayError( 'Something went wrong', error.message || error.details || error.hint || 'Please retry after sometime' )
        }

        let csvData:any[] = []
        data?.map( (item:certificateDataType) => {
            let pushObj = {
                id: item.id,
                certificate_id: (item.certificate_short_code === 'AAAA-BBBB-CCCC-DDDD') ? item.id : item.certificate_short_code,
                recipient_name: item.recipient_name,
                recipient_email: item.recipient_email,
                type: item.type,
                created_at: item.created_at,
                download_link: `https://${( isWhiteLabeled ? ( item.organizations && item.organizations?.subdomain !== null ) ? item.organizations?.subdomain+'.' : '' : '' )}certificate.givemycertificate.com/c/${(item.certificate_short_code === 'AAAA-BBBB-CCCC-DDDD') ? item.id : item.certificate_short_code}`,
                verification_link: `https://${( isWhiteLabeled ? ( item.organizations && item.organizations?.subdomain !== null ) ? item.organizations?.subdomain+'.' : '' : '' )}verification.givemycertificate.com/v/${(item.certificate_short_code === 'AAAA-BBBB-CCCC-DDDD') ? item.id : item.certificate_short_code}`,
                ...item.extra_metadata,
            }

            csvData.push(pushObj)
            return true
        })
        let unparseConfig = {}
        
        if (user?.id === '2624b314-08d1-4076-94aa-98cc907169b1' ) {
            //@ts-ignore
            unparseConfig['columns'] = ['recipient_name', 'recipient_email', 'id', 'date', 'download_link', 'course', '', '', ...Object.keys(csvData[0])]
        }
        console.log(Papa.unparse(csvData, unparseConfig));
        
        exportCSV(Papa.unparse(csvData, unparseConfig), `Certificate_data_for_job_id_${id}.csv`)
        setIsCsvGenerating(false)
    }

    const deleteMultipleCertificates = async () => {
        setLoadingButtons((ids) => ({
            ...ids,
            delete_all_checked: true
        }))

        let certificateIdsToDelete = Object.keys(checkboxStatusHolder).map(item => {
            //@ts-ignore
            return checkboxStatusHolder[item].id
        })
        await multipleDeleteMutation.mutateAsync(certificateIdsToDelete)

        setLoadingButtons((ids) => ({
            ...ids,
            delete_all_checked: false
        }))
        setCheckboxStatusHolder({})
    }

    const deleteAllCertificates = async () => {
        setLoadingButtons((ids) => ({
            ...ids,
            delete_every_checked: true
        }))

        let jobIdToDeleteCertificates = id
        await allDeleteMutation.mutateAsync(jobIdToDeleteCertificates)

        setLoadingButtons((ids) => ({
            ...ids,
            delete_every_checked: false
        }))
    }

    const publishMultipleCertificates = async () => {
        setLoadingButtons((ids) => ({
            ...ids,
            publish_all_checked: true
        }))

        let myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        const data = JSON.stringify({
            job_id: id,
            certificate_ids: 
                Object.keys(checkboxStatusHolder).map(item => {
                    //@ts-ignore
                    return checkboxStatusHolder[item].id
                })
            ,
            publish: true
        })
        
        const requestOptions:RequestInit = {
            method: 'POST',
            headers: myHeaders,
            body: data,
            redirect: 'follow'
        }
        await fetch(process.env.REACT_APP_PUBLISH_CERTIFICATE_URL || 'https://us-central1-gmc-testing.cloudfunctions.net/sendGridEventProcessor/mail/resend', requestOptions)

        displaySuccess('Certificate Published Successfully', `All selected certificates published successfully`)
        setLoadingButtons((ids) => ({
            ...ids,
            publish_all_checked: false
        }))
        setCheckboxStatusHolder({})
        queryClient.invalidateQueries(['jobStatus', id, pageNo, pageSize, sortOnColumn, sortOrder, serchText, searchOnColumn ])
    } 

    const publishAllCertificates = async () => {
        setLoadingButtons((ids) => ({
            ...ids,
            publish_all_certificates: true
        }))

        let myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        const data = JSON.stringify({
            job_id: id,
            publish: true
        })
        
        const requestOptions:RequestInit = {
            method: 'POST',
            headers: myHeaders,
            body: data,
            redirect: 'follow'
        }
        await fetch(process.env.REACT_APP_PUBLISH_CERTIFICATE_URL || 'https://us-central1-gmc-testing.cloudfunctions.net/sendGridEventProcessor/mail/resend', requestOptions)

        displaySuccess('All Certificate Published Successfully', `All certificates published successfully`)
        setLoadingButtons((ids) => ({
            ...ids,
            publish_all_certificates: false
        }))
        setCheckboxStatusHolder({})
        queryClient.invalidateQueries(['jobStatus', id, pageNo, pageSize, sortOnColumn, sortOrder, serchText, searchOnColumn ])
    }
    
    const resendMultipleCertificates = async () => {
        setLoadingButtons((ids) => ({
            ...ids,
            resend_all_checked: true
        }))

        let myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        const data = JSON.stringify({
            job_id: id,
            certificate_ids: 
                Object.keys(checkboxStatusHolder).map(item => {
                    //@ts-ignore
                    return checkboxStatusHolder[item].id
                })
            ,
            publish: false
        })
        
        const requestOptions:RequestInit = {
            method: 'POST',
            headers: myHeaders,
            body: data,
            redirect: 'follow'
        }
        await fetch(process.env.REACT_APP_PUBLISH_CERTIFICATE_URL || 'https://us-central1-gmc-testing.cloudfunctions.net/sendGridEventProcessor/mail/resend', requestOptions)

        displaySuccess('Mail Resend Successful', `All selected certificates resend successfully`)
        setLoadingButtons((ids) => ({
            ...ids,
            resend_all_checked: false
        }))
        setCheckboxStatusHolder({})
        queryClient.invalidateQueries(['jobStatus', id, pageNo, pageSize, sortOnColumn, sortOrder, serchText, searchOnColumn ])
    }

    const actionBarObject:ActionBarObjectType[] = [
        {
            name: "Publish All",
            icon: FaPaperPlane,
            onClickFunction: publishAllCertificates,
            isLoading: (loadingButtons['publish_all_certificates']),
            type: "single",
        },
        {
            name: "Publish",
            icon: FaPaperPlane,
            onClickFunction: publishMultipleCertificates,
            isDisabled: (Object.keys(checkboxStatusHolder).length === 0),
            isLoading: (loadingButtons['publish_all_checked']),
            type: "single",
        },
        {
            name: "Resend",
            icon: FaEnvelope,
            onClickFunction: resendMultipleCertificates,
            isDisabled: (Object.keys(checkboxStatusHolder).length === 0),
            isLoading: (loadingButtons['resend_all_checked']),
            type: "single",
        },
        {
            name: "Delete",
            icon: FaTimes,
            onClickFunction: deleteMultipleCertificates,
            isDisabled: (Object.keys(checkboxStatusHolder).length === 0),
            isLoading: (loadingButtons['delete_all_checked']),
            type: "single",
        },
        {
            name: "Delete All",
            icon: FaTimes,
            onClickFunction: deleteAllCertificates,
            // isDisabled: (Object.keys(checkboxStatusHolder).length === 0),
            isLoading: (loadingButtons['delete_every_checked']),
            type: "single",
        }
    ]

    const publishCertificate = async (item:certificateDataType) => {
        setLoadingButtons((ids) => ({
            ...ids,
            [item.id!+'publish']: true
        }))
        console.log(loadingButtons);
        
        let myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        const data = JSON.stringify({
            job_id: item.job_id,
            certificate_ids: [
                item.id
            ],
            publish: true
        })
        const requestOptions:RequestInit = {
            method: 'POST',
            headers: myHeaders,
            body: data,
            redirect: 'follow'
        }
        await fetch(process.env.REACT_APP_PUBLISH_CERTIFICATE_URL || 'https://us-central1-gmc-testing.cloudfunctions.net/sendGridEventProcessor/mail/resend', requestOptions)

        displaySuccess('Certificate Published Successfully', `certificate related to ${item.recipient_name} published successfully`)
        setLoadingButtons((ids) => ({
            ...ids,
            [item.id!+'publish']: false
        }))
        queryClient.invalidateQueries(['jobStatus', id, pageNo, pageSize, sortOnColumn, sortOrder, serchText, searchOnColumn ])
    }
    
    const resendCertificateEmail = async (item:certificateDataType) => {
        setLoadingButtons((ids) => ({
            ...ids,
            [item.id!+'resend']: true
        }))

        let myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        const data = JSON.stringify({
            job_id: item.job_id,
            certificate_ids: [
                item.id
            ],
            publish: false
        })
        const requestOptions:RequestInit = {
            method: 'POST',
            headers: myHeaders,
            body: data,
            redirect: 'follow'
        }
        await fetch(process.env.REACT_APP_RESEND_MAIL_URL || "https://us-central1-gmc-testing.cloudfunctions.net/sendGridEventProcessor/mail/resend", requestOptions)

        displaySuccess('Mail Resend Successful', `Email to ${item.recipient_email} send successfully`)
        setLoadingButtons((ids) => ({
            ...ids,
            [item.id!+'resend']: false
        }))
    }

    const handleCheckboxChange = (e:ChangeEvent<HTMLInputElement>, item:certificateDataType) => {
        if (e.target.checked) {
            checkBoxStatus.current = {
                ...checkBoxStatus.current,
                [item.id!]: item
            }
        } else {
            checkBoxStatus.current = removeKeyFromObject(checkBoxStatus.current, [item.id!])
        }
        setCheckboxStatusHolder(checkBoxStatus.current)
    }

    const addSubJobData = async () => {
        setIsSubJobAdding(true)
        const csvData = AddSubJobFormRef.current?.getCsvData()
        const jobId = id
        await addSubJob({
            job_id: jobId,
            data: csvData,
            user_id: user?.id
        })
        setIsSubJobAdding(false)
        displaySuccess('Job update completed', `We have added ${csvData?.length} new certificates to the job`)
    }

    const getTableData = useCallback(() => {
        let returnObject = {
            keys: columnsToDisplay,
            header: columnToDisplayValMapping,
            body: [] as object[]
        }
        
        data?.queryData?.map( (item:certificateDataType) => {
            returnObject.body.push({
                id: <HStack>
                        <Checkbox  
                            onChange={(e) => handleCheckboxChange(e, item)} 
                        />
                        <Text>
                            {item.id}
                        </Text>
                    </HStack>,
                keyId: item.id,
                certificate_short_code: (item.certificate_short_code === 'AAAA-BBBB-CCCC-DDDD') ? '(null)' : item.certificate_short_code,
                recipient_name: item.recipient_name,
                recipient_email: item.recipient_email,
                extra_metadata: <Textarea isReadOnly={true} resize="both" >
                                    {JSON.stringify(item.extra_metadata, null, 4)}
                                </Textarea>,
                type: item.type,
                download_link: <InputWithCopy
                                    isReadOnly={true}
                                    linkIcon={true}
                                    toCopy={`https://${( isWhiteLabeled ? ( item.organizations && item.organizations?.subdomain !== null ) ? item.organizations?.subdomain+'.' : '' : '' )}certificate.givemycertificate.com/c/${(item.certificate_short_code === 'AAAA-BBBB-CCCC-DDDD') ? item.id : item.certificate_short_code}`}
                                />,
                verification_link: <InputWithCopy
                                        isReadOnly={true}
                                        linkIcon={true}
                                        toCopy={`https://${( isWhiteLabeled ? ( item.organizations && item.organizations?.subdomain !== null ) ? item.organizations?.subdomain+'.' : '' : '' )}verification.givemycertificate.com/v/${(item.certificate_short_code === 'AAAA-BBBB-CCCC-DDDD') ? item.id : item.certificate_short_code}`}
                                    />,
                created_at: <Stack>
                                <Text minW="max-content">
                                    { moment(item.created_at).local().format('MMM DD, YYYY HH:mm') }
                                </Text>
                            </Stack>,
                updated_at: <Stack>
                                <Text minW="max-content">
                                    { moment(item.updated_at).local().format('MMM DD, YYYY HH:mm') }
                                </Text>
                            </Stack>,
                controls:   <VStack>
                                <HStack>
                                    <DrawerComponentWithRef
                                        size="lg"
                                        triggerComponent={
                                            <IconButton
                                                size="xs"
                                                variant="outline"
                                                colorScheme="teal"
                                                icon={<FaEye />}
                                                aria-label="Show Preview"
                                            />
                                        }
                                        heading="Details"
                                    >
                                        <ShowJob
                                            displayOrder={availableColumns}
                                            keysAndDisplayNameMapping={columnToDisplayValMapping}
                                            displayObject={item}
                                        />
                                    </DrawerComponentWithRef>
    
                                    <IconButton
                                        size="xs"
                                        variant="outline"
                                        colorScheme="teal"
                                        icon={<FaEdit />}
                                        aria-label="Edit Certificate"
                                        onClick={ () => {
                                            setItemToUpdate(item)
                                            editCertificateDwawerRef.current?.onOpen()
                                        }}
                                    />
                                    
                                    <ModalComponentWithRef 
                                        triggerComponent={
                                            <IconButton
                                                size="xs"
                                                variant="outline"
                                                colorScheme="red"
                                                icon={<FaTimes />}
                                                aria-label="Delete Event"
                                            />
                                        }
                                        heading="Delete"
                                        showFooter={true}
                                        footerButtons={
                                            <Button 
                                                colorScheme="blue"
                                                onClick={ async () => {
                                                    return await deleteMutation.mutateAsync({
                                                        id: item.id!,
                                                        user_id: user?.id!
                                                    })
                                                }}
                                            >
                                                Confirm
                                            </Button>
                                        }
                                    >
                                        {
                                            <Text>
                                                Please be informed that this action is irreversible, You won't be able to recover it once deleted. However, Don't worry as all the certificates issued in the past using this event will retain it's value. 
                                            </Text>
                                        }
                                    </ModalComponentWithRef>
                                </HStack>
                                <HStack>
                                    <Tooltip label='Preview certificate before sending' >
                                        <IconButton
                                            size="xs"
                                            variant="outline"
                                            colorScheme="teal"
                                            icon={<FaBinoculars />}
                                            aria-label="Preview Certificate"
                                            onClick={ () => {
                                                createCookie('isPreviewEnabled', true, 5)
                                                window.open(`https://${( isWhiteLabeled ? ( item.organizations && item.organizations?.subdomain !== null ) ? item.organizations?.subdomain+'.' : '' : '' )}certificate.givemycertificate.com/c/${(item.certificate_short_code === 'AAAA-BBBB-CCCC-DDDD') ? item.id : item.certificate_short_code}`, '_blank')
                                            }}
                                        />
                                    </Tooltip>
                                    <Tooltip label='Resend certificate' >
                                        <IconButton
                                            size="xs"
                                            variant="outline"
                                            colorScheme="teal"
                                            isLoading={loadingButtons[item.id!+'resend']}
                                            isDisabled={!item.is_published}
                                            icon={<FaEnvelope />}
                                            aria-label="Resend Certificate"
                                            onClick={ () => {
                                                resendCertificateEmail(item)
                                            }}
                                        />
                                    </Tooltip>
                                    <Tooltip label='Publish certificate' >
                                        <IconButton
                                            size="xs"
                                            variant="outline"
                                            colorScheme="teal"
                                            isLoading={loadingButtons[item.id!+'publish']}
                                            isDisabled={item.is_published}
                                            icon={<FaPaperPlane />}
                                            aria-label="Publish Certificate"
                                            onClick={ () => {
                                                publishCertificate(item)
                                            }}
                                        />
                                    </Tooltip>
                                </HStack>
                            </VStack>,
                status: <VStack>
                            <HStack>
                                <Tooltip label={item.certificate_status && item.certificate_status[0] && item.certificate_status[0].is_delivered ? "Certificate delivered to recipient": "Delivery status pending"}>
                                    <span>
                                        <Icon as={AiOutlineDeliveredProcedure} color={item.certificate_status && item.certificate_status[0] && item.certificate_status[0].is_delivered ? 'green': 'gray'} />
                                    </span>
                                </Tooltip>
                                <Tooltip label={item.certificate_status && item.certificate_status[0] && item.certificate_status[0].is_opened ? "Mail opened by recipient": "Email not opend"}>
                                    <span>
                                        <Icon as={item.certificate_status && item.certificate_status[0] && item.certificate_status[0].is_opened ? FaEnvelopeOpenText : FaEnvelope } color={item.certificate_status && item.certificate_status[0] && item.certificate_status[0].is_opened ? 'green': 'gray'} />
                                    </span>
                                </Tooltip>
                            </HStack>
                </VStack>
            })
            return true
        })
        return returnObject
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.queryData, loadingButtons])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const tableDataMemoized = useMemo(getTableData, [data?.queryData, loadingButtons])

    const setDebouncedSerchText = (eve:ChangeEvent<HTMLInputElement>) => {
        const searchFieldValue = eve.currentTarget.value
        if (timeoutHolderForSearch.current !== null ) {
            clearTimeout(timeoutHolderForSearch.current)
        }
        timeoutHolderForSearch.current = setTimeout(() => {
            setSerchText(searchFieldValue)
        }, 300)
    }

    if (data) {
        const { queryData, querySize } = data

        if ( queryData ) {
            const lastPage = (Math.ceil(querySize!/pageSize) > 0) ? Math.ceil(querySize!/pageSize) : 1
            return (
                <>
                    <Box 
                        bg={showActionBarBackgroundColor} 
                        w='fit-content'
                        borderLeftRadius='lg'
                        position='fixed'
                        right='0'
                        top='68px'
                        zIndex='42'
                        display={isActionbarDisplay ? 'none': 'block'}
                    >
                        <Tooltip label='Show Action Bar'>
                            <IconButton
                                aria-label='Show action bar'
                                icon={<AiOutlineDoubleLeft />}
                                py='8'
                                onClick={() => {
                                    setIsActionbarDisplay(true)
                                }}
                            />
                        </Tooltip>
                    </Box>
                    <Collapse in={isActionbarDisplay} style={{
                        position: 'sticky',
                        top: '54px',
                        zIndex: 40
                    }} >
                        <Card 
                            mb='3' 
                            p='2' 
                            top='54px' 
                            borderTop='4px' 
                            borderBottom='4px' 
                            borderColor={backgroundColor}
                            transition='.4s'
                        >
                            <Flex direction={['column', null, 'row', null]}>
                                {actionBarObject.map((item, i) => {
                                    switch (item.type) {
                                        case 'single':
                                            return (
                                                <Tooltip key={i} label={item.name} fontSize='sm'>
                                                    <Button
                                                        onClick={item.onClickFunction ? item.onClickFunction : () => {}}
                                                        rightIcon={<Icon as={item.icon} />}
                                                        isDisabled={item.isDisabled}
                                                        isLoading={item.isLoading}
                                                        mx={[null, null, 1, null]}
                                                        my={toolbarVerticalMarginValue}
                                                    >
                                                        {item.name}
                                                    </Button>
                                                </Tooltip>
                                            )

                                        case 'divider':
                                            return (
                                                <Divider key={i} orientation='vertical' px={2} />
                                            )

                                        case 'nested':
                                            return (
                                                <Menu key={i}>
                                                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                                        {
                                                            item.icon &&
                                                            <Icon as={item.icon} mr={3} />
                                                        }
                                                        {item.name}
                                                    </MenuButton>
                                                    <MenuList>
                                                        {
                                                            item.children?.map( (item, i) => {
                                                                switch (item.type) {
                                                                    case 'single':
                                                                        return (
                                                                            <MenuItem
                                                                                key={i}
                                                                                isDisabled={item.isDisabled}
                                                                                isLoading={item.isLoading}
                                                                                onClick={item.onClickFunction}
                                                                                icon={<Icon as={item.icon} mr={3} />}
                                                                            >
                                                                                {item.name}
                                                                            </MenuItem>
                                                                        )

                                                                    case 'divider':
                                                                        return (
                                                                            <MenuDivider key={i} />
                                                                        )
                                                                
                                                                    default:
                                                                        return (
                                                                            <MenuItem
                                                                                key={i}
                                                                                isDisabled={item.isDisabled}
                                                                                isLoading={item.isLoading}
                                                                                onClick={item.onClickFunction}
                                                                                icon={<Icon as={item.icon} mr={3} />}
                                                                            >
                                                                                {item.name}
                                                                            </MenuItem>
                                                                        )
                                                                }
                                                            })
                                                        }
                                                    </MenuList>
                                                </Menu>
                                            )
                                    
                                        default:
                                            return (
                                                <Tooltip key={i} label={item.name} fontSize='sm'>
                                                    <Button
                                                        isDisabled={item.isDisabled}
                                                        isLoading={item.isLoading}
                                                        onClick={item.onClickFunction ? item.onClickFunction : () => {}}
                                                        rightIcon={<Icon as={item.icon} />}
                                                    >
                                                        {item.name}
                                                    </Button>
                                                </Tooltip>
                                            )
                                    }
                                })}
                                <Spacer />
                                <Flex>
                                    <Spacer />
                                    <Tooltip label='Hide Action Bar'>
                                        <IconButton
                                            aria-label='Hide action bar'
                                            icon={<AiOutlineDoubleRight />}
                                            onClick={() => {
                                                setIsActionbarDisplay(false)
                                            }}
                                        />
                                    </Tooltip>
                                </Flex>
                            </Flex>
                        </Card>
                    </Collapse>
                    <DrawerComponentWithRef
                        triggerComponent={
                            <IconButton
                                size="lg"
                                fontSize="lg"
                                colorScheme="blue"
                                ml="auto"
                                borderRadius="50%"
                                m={2}
                                icon={<FaPlus />}
                                aria-label="Create new Organisation"
                                position="fixed"
                                right={{
                                    base: 2,
                                    md: 10
                                }}
                            />
                        }
                        heading="Add data to job"
                        size="xl"
                        showFooter={ true }
                        footerButtons={
                            <Button 
                                colorScheme="blue"
                                isLoading={ isSubJobAdding }
                                loadingText="Processing"
                                onClick={addSubJobData}
                            >
                                Add Data
                            </Button>
                        }
                    >
                        <AddSubJobForm 
                            ref={AddSubJobFormRef}
                            jobId={id}
                            certificateData={queryData[0]}
                        />
                    </DrawerComponentWithRef>
                    <Box h={8} />
                    <Card>
                        <Flex
                            borderBottomWidth={1}
                            direction='column'
                            alignItems='flex-start'
                        >
                            <Flex alignItems="center" w='100%' mb={4}>
                                <Heading as="h2" fontSize="lg">
                                    Certificates
                                </Heading>
                                <Spacer />
                                <Button
                                    leftIcon={<FaRedo />}
                                    mr={3}
                                    variant="outline"
                                    onClick={ () => {
                                        queryClient.invalidateQueries(['jobStatus', id, pageNo, pageSize, sortOnColumn, sortOrder, serchText, searchOnColumn ])
                                    }}
                                    display={["none", "inherit", null, null]}
                                    isLoading={isFetching}
                                >
                                    Refresh
                                </Button>
                                <IconButton 
                                    aria-label="Refresh Certificate Data"
                                    onClick={ () => {
                                        queryClient.invalidateQueries(['jobStatus', id, pageNo, pageSize, sortOnColumn, sortOrder, serchText, searchOnColumn ])
                                    }}
                                    icon={<FaRedo />}
                                    mr={3}
                                    variant="outline"
                                    display={["inherit", "none", null, null]}
                                    isLoading={isFetching}
                                />
                                <Button
                                    aria-label='Open Advance Options'
                                    rightIcon={isOpen ? <FaAngleUp /> : <FaAngleDown /> }
                                    leftIcon={<FaEdit />}
                                    onClick={onToggle}
                                    variant='outline'
                                >
                                    Advance
                                </Button>
                            </Flex>
                            <Collapse 
                                style={{
                                    width: '100%',
                                }} 
                                in={isOpen} 
                                animateOpacity
                            >
                                <Flex w='100%' mb={4} direction={['column', null, 'row', null]} >
                                    <Flex alignItems='center'>
                                        <Text pr='2'>
                                            Filter:
                                        </Text>
                                        <Input
                                            type='search'
                                            mr={3}
                                            placeholder={columnToDisplayValMapping[searchOnColumn]}
                                            onChange={setDebouncedSerchText}
                                        />
                                        <Select
                                            defaultValue={searchOnColumn}
                                            onChange={(val) => {
                                                setSearchOnColumn(val.target.value)
                                            }}
                                        >
                                            {
                                                availableColumnsForSearch.map((columnKey) => (
                                                    <option key={columnKey} value={columnKey}> { columnToDisplayValMapping[columnKey] } </option>
                                                ))
                                            }
                                        </Select>
                                    </Flex>
                                    <Spacer />
                                    <Flex pt={['2', null, '0', null]}>
                                        <Spacer />
                                        <Tooltip label="A maximum of 5000 rows are allowed to download at a time">
                                            <Button
                                                leftIcon={<FaFileDownload />}
                                                mx={3}
                                                variant="outline"
                                                onClick={ () => {
                                                    downloadCsvData()
                                                }}
                                                display={["none", "inherit", null, null]}
                                                isLoading={isCsvGenerating}
                                            >
                                                Download
                                            </Button>
                                        </Tooltip>
                                        <Tooltip label="A maximum of 5000 rows are allowed to download at a time">
                                            <IconButton 
                                                aria-label="Download Certificate Data"
                                                onClick={ () => {
                                                    downloadCsvData()
                                                }}
                                                icon={<FaFileDownload />}
                                                mx={3}
                                                variant="outline"
                                                display={["inherit", "none", null, null]}
                                                isLoading={isCsvGenerating}
                                            />
                                        </Tooltip>

                                        <DrawerComponentWithRef
                                            heading="Table Properties"
                                            triggerComponent={
                                                <Button variant="outline" minW="20" leftIcon={<HiPencilAlt />} >
                                                    Edit
                                                </Button>
                                            }
                                            showFooter={true}
                                        >
                                            <CardWithColorAccent>
                                                <Heading size="sm" mb="4">
                                                    Columns To display
                                                </Heading>
                                                <Divider mb="6" />
                                                <CheckboxGroup 
                                                    colorScheme="blue"
                                                    defaultValue={columnsToDisplay}
                                                    onChange={(newVal) => {
                                                        setLoadingButtons((ids) => ({
                                                            ...ids,
                                                            //Jugad to re-render table on column changes
                                                            jugaad: true
                                                        }))
                                                        setColumnsToDisplay(recreateListWithDesiredOrder(availableColumns, newVal)) 
                                                    }}
                                                >
                                                    <VStack alignItems="flex-start">
                                                        {
                                                            availableColumns.map((columnKey) => (
                                                                <Checkbox key={columnKey} isDisabled={columnKey === 'id'} value={columnKey}>{ columnKey === 'id' ? 'Certificate Id' : columnToDisplayValMapping[columnKey] }</Checkbox>
                                                            ))
                                                        }
                                                    </VStack>
                                                </CheckboxGroup>
                                            </CardWithColorAccent> 
                    
                                            <CardWithColorAccent mt={8}>
                                                <Heading size="sm" mb="4">
                                                    Sort by
                                                </Heading>
                                                <Divider mb="6" />
                                                <RadioGroup onChange={setSortOnColumn} value={sortOnColumn}>
                                                    <VStack alignItems="flex-start">
                                                        {
                                                            availableColumnsForSorting.map((columnKey) => (
                                                                <Radio key={columnKey} value={columnKey}> { columnToDisplayValMapping[columnKey] } </Radio>
                                                            ))
                                                        }
                                                    </VStack>
                                                </RadioGroup>
                                            </CardWithColorAccent> 
                    
                                            <CardWithColorAccent mt={8}>
                                                <Heading size="sm" mb="4">
                                                    Sort Order
                                                </Heading>
                                                <Divider mb="6" />
                                                <RadioGroup onChange={setSortOrder} value={sortOrder}>
                                                    <VStack alignItems="flex-start">
                                                        <Radio value='ascending'> Ascending </Radio>
                                                        <Radio value='descending'> Descending </Radio>
                                                    </VStack>
                                                </RadioGroup>
                                            </CardWithColorAccent>                              
                                        </DrawerComponentWithRef>
                                    </Flex>
                                </Flex>
                            </Collapse>
                        </Flex>
                        {
                            ( queryData.length > 20 ) ? 
                            <ResponsiveTableVirtualMemo data={tableDataMemoized} />
                            :
                            <ResponsiveTableMemo data={tableDataMemoized}  />
                        }
                        <Flex alignItems="center" mt={3} borderTopWidth={1} pt={4} >
                            <Text>
                                {`Showing ${((pageNo-1)*pageSize)+1} - ${ ( querySize! < pageNo*pageSize ) ? querySize : pageNo*pageSize } of ${querySize}`}
                            </Text>
                            <Spacer />
                            <IconButton
                                aria-label="First Page"
                                icon={ <FaAngleDoubleLeft /> }
                                size="xs"
                                variant='ghost'
                                isDisabled={ ( pageNo === 1 ) }
                                onClick={() => {
                                    setPageNo(1)
                                }}
                            />
                            <IconButton
                                aria-label="Previous Page"
                                icon={ <FaAngleLeft /> }
                                size="xs"
                                variant='ghost'
                                isDisabled={ ( pageNo === 1 ) }
                                onClick={() => {
                                    if ( pageNo > 1 ) {
                                        setPageNo( p => p - 1 )
                                    }
                                }}
                            />
                            <IconButton
                                aria-label="Next Page"
                                icon={ <FaAngleRight /> }
                                size="xs"
                                variant='ghost'
                                isDisabled={ ( pageNo === lastPage ) }
                                onClick={() => {
                                    if ( pageNo < lastPage ) {
                                        setPageNo( p => p + 1 )
                                    }
                                }}
                            />
                            <IconButton
                                aria-label="Last Page"
                                icon={ <FaAngleDoubleRight /> }
                                size="xs"
                                variant='ghost'
                                isDisabled={ ( pageNo === lastPage ) }
                                onClick={() => {
                                    setPageNo( lastPage )
                                }}
                            />
                            <Select 
                                w="auto" 
                                defaultValue={pageSize} 
                                onChange={(val) => {
                                    const changedPageSize = parseInt( val.target.value )
                                    setPageSize(changedPageSize)
                                    if ( changedPageSize*pageNo > querySize! ) {
                                        const lastPage = (Math.ceil(querySize!/changedPageSize) > 0) ? Math.ceil(querySize!/changedPageSize) : 1
                                        setPageNo( lastPage )
                                    }
                                }}
                            >
                                {
                                    paginationPageSizeArray.map( size => (
                                        <option key={size} value={size}> { size } </option>
                                    ))
                                }
                            </Select>
                        </Flex>
                    </Card>

                    <DrawerComponentWithRef
                        size="lg"
                        heading="Edit Certificate"
                        showFooter={ true }
                        triggerComponent={
                            <Button display="none" >
                                Edit
                            </Button>
                        }
                        footerButtons={
                            <Button 
                                colorScheme="blue"
                                type="submit"
                                form="createNewCertificateForm"
                                isLoading={ isSaveCertificateLoading }
                                loadingText="Processing"
                            >
                                Update
                            </Button>
                        }
                        ref={editCertificateDwawerRef}
                    >
                        <CreateCertificateForm 
                            formId="createNewCertificateForm"
                            loadingStatus={setIsSaveCertificateLoading}
                            actionOnSubmitSuccess={ () => {
                                editCertificateDwawerRef?.current?.onClose()
                            }}
                            onSubmitFunction={ async (value) => {
                                return await updateMutation.mutateAsync({
                                    ...value,
                                    id: itemToUpdate.id!,
                                    recipient_name: value.name,
                                    recipient_email: value.email,
                                })
                            }}
                            initialData={{
                                ...itemToUpdate,
                                name: itemToUpdate.recipient_name,
                                email: itemToUpdate.recipient_email,
                            }}
                        />
                    </DrawerComponentWithRef>
                </>
            )
        }
    }

    if ( isError ) {
        return (
            <ErrorComponent
                //@ts-ignore
                errorMessage={error.message}
            />
        )
    }

    if ( isLoading ) {
        return (
            <Stack mt={10} spacing={5} >
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
            </Stack>
        )
    }

    if ( !data ) {
        return (
            <ErrorComponent
                //@ts-ignore
                errorMessage={error.message}
            />
        )
    }

    return (
        <ErrorComponent />
    )
}

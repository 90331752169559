import React from 'react'
import {
    FormControl,
    Stack,
    Button,
    Flex,
    FormLabel,
    FormErrorMessage,
    FlexProps,
    Spacer,
} from '@chakra-ui/react'
import { displayError, displaySuccess } from '../../helpers/CommonFunctions'
import * as yup from 'yup'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAuth } from '../../contexts/Auth'
import { PasswordField} from '../passwordField/PasswordField'
import { useEffect } from 'react'

const schema = yup.object().shape({
	password: yup.string().min(8).required(),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
})

export type PasswordResetFormInputs = {
	password: string;
    confirmPassword: string;
}

interface ChangePasswordInputPropsType extends FlexProps {
    actionOnSubmitSuccess?: (...args: any[]) => any
    loadingStatus?: (...args: any[]) => any
    showActionOnBottom?: boolean
    hideSubmitButton?: boolean
    formId?: string
}

export const ChangePassword = ( props:ChangePasswordInputPropsType ) => {
    const { saveNewPassword } = useAuth()
    const { actionOnSubmitSuccess, showActionOnBottom, formId, loadingStatus, hideSubmitButton } = props

    const methods = useForm<PasswordResetFormInputs>({
		mode: 'onBlur',
		resolver: yupResolver(schema),
	})
    const { handleSubmit, formState: { errors, isSubmitting } } = methods

    useEffect( () => {
        if (loadingStatus) {
            loadingStatus( isSubmitting )
        }
    }, [loadingStatus, isSubmitting])

    const handleSavePasswordButtonClick = async (values:PasswordResetFormInputs) => {
        const { user, error } = await saveNewPassword(values)
        if (user) {
            displaySuccess('Password changed', 'We have changed your password')
            if (actionOnSubmitSuccess) {
                actionOnSubmitSuccess()
            }
        } else {
            displayError( '', '', true, {
                type: 'USER',
                subType: 'NOT_LOGGED_IN_CHANGE_PASSWORD',
                code: error?.status,
				error: error
            })
        }
    }

    return (
        <FormProvider {...methods} >
            <form
                onSubmit={handleSubmit(handleSavePasswordButtonClick)}
                style={{
                    height: '100%'
                }}
                id={formId}
            >
                <Stack spacing="4" h="100%">
                    <FormControl
                        id="password"
                        isInvalid={!!errors?.password}
                        errortext={errors?.password?.message}
                        isRequired
                    >
                        <Flex align="baseline" justify="space-between">
                            <FormLabel mb={1}>Password</FormLabel>
                        </Flex>
                        <PasswordField name="password" />
                        <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                        id="confirmPassword"
                        isInvalid={!!errors?.confirmPassword}
                        errortext={errors?.confirmPassword?.message}
                        isRequired
                    >
                        <Flex align="baseline" justify="space-between">
                            <FormLabel mb={1}>Confirm Password</FormLabel>
                        </Flex>
                        <PasswordField name="confirmPassword" />
                        <FormErrorMessage>{errors?.confirmPassword?.message}</FormErrorMessage>
                    </FormControl>
                    { showActionOnBottom && <Spacer /> }
                    {
                        !hideSubmitButton &&
                        <Button 
                            type="submit" 
                            colorScheme="blue" 
                            size="lg" 
                            fontSize="md" 
                            isLoading={isSubmitting} 
                            loadingText="Submitting..."
                            isDisabled={Object.keys(errors).length !== 0}
                        >
                            Save Password
                        </Button>
                    }
                </Stack>
            </form>
        </FormProvider>
    )
}

import React, { useRef, useEffect } from "react";
import { Rect, Transformer } from "react-konva";
import { Rect as KonvaRect } from "konva/lib/shapes/Rect";
import { Transformer as KonvaTransformer } from "konva/lib/shapes/Transformer";

export interface RectProps {
	id?: string;
	x: number;
	y: number;
	width: number;
	height: number;
	fill: string;
	stroke?: string;
	strokeWidth?: number;
}

export interface Props {
	attrs: RectProps;
	isSelected: boolean;
	onSelect: () => void;
	onChange: (newAttrs: RectProps) => void;
}

const RectangleShape: React.FC<Props> = ({ attrs, isSelected, onSelect, onChange }) => {
	const shapeRef = useRef<KonvaRect>(null);
	const trRef = useRef<KonvaTransformer>(null);
	useEffect(() => {
		if (isSelected) {
			// attach transformer
			if (trRef.current && shapeRef.current) {
				trRef.current.nodes([shapeRef.current]);
				trRef.current.moveToTop();
				trRef.current.getLayer()?.batchDraw();
			}
		}
	}, [isSelected]);

	const handleMouseEnter = () => {
		const node = shapeRef.current;
		if (node && !isSelected) onChange({ ...attrs, stroke: "#42adf5", strokeWidth: 1.5 });
	};

	const handleMouseLeave = () => {
		const node = shapeRef.current;
		if (node) onChange({ ...attrs, strokeWidth: 0 });
	};

	const handleDragEnd = () => {
		let tr = trRef.current;
		if (tr) {
			let x = tr?.getX();
			let y = tr?.getY();
			onChange({ ...attrs, x, y });
		}
		onSelect();
	};

	return (
		<>
			<Rect
				draggable
				{...attrs}
				onMouseDown={onSelect}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={onSelect}
				onTap={onSelect}
				ref={shapeRef}
				onDragEnd={handleDragEnd}
				onTransformEnd={(e) => {
					onSelect();
					const node = shapeRef.current;
					if (node) {
						const scaleX = node.scaleX();
						const scaleY = node.scaleY();

						node.scaleX(1);
						node.scaleY(1);
						onChange({
							...attrs,
							width: Math.max(5, node.width() * scaleX),
							height: Math.max(node.height() * scaleY),
						});
					}
				}}
			/>
			{isSelected && (
				<Transformer
					ref={trRef}
					boundBoxFunc={(oldBox, newBox) => {
						if (newBox.width < 5 || newBox.height < 5) {
							return oldBox;
						}
						return newBox;
					}}
				/>
			)}
		</>
	);
};

export default RectangleShape;

import { Box, Button, Checkbox, Flex, Input, InputGroup, InputLeftAddon, Select, Skeleton, Spacer, Stack } from '@chakra-ui/react'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ErrorComponent } from '../../components/errorComponent/ErrorComponent'
import { TipTapEditor, tipTapEditorRefFunctionType } from '../../components/textEditors/TipTapEditor'
import { DEFAULT_EMAIL_HEADER, DEFAULT_EMAIL_TEMPLATE_VERSION_1, DEFAULT_EMAIL_TEMPLATE_VERSION_1_WHITELABELED } from '../../configs/GlobalConstants'
import { useAuth } from '../../contexts/Auth'
import { getEmailTemplatesData, insertEmailTemplatesData, updateEmailTemplatesData } from '../../dataFetchers/emailTemplatesDataFetcher'
import { displayError, replacePlaceholderValueInString } from '../../helpers/CommonFunctions'
import { useConfig } from '../../hooks/useConfig'
import { emailTemplateType } from '../../types/dataFetcherTypes/EmailTemplatesDataFetcherTypes'

export interface emailTemplatesRefFunctionType {
    getFullTemplate: () => {
        template: string,
        subject: string,
    }
}

//A dummy input is placed as ref could only be the second argument, Add/Change when needed
export const EmailTemplates = forwardRef(( {params}:{params?:any}, ref ) => {
    const queryClient = useQueryClient()
    const { isError, isLoading, data, error } = useQuery('emailTemplates', () => getEmailTemplatesData( 1, 30), { keepPreviousData : true })

    const insertMutation = useMutation(insertEmailTemplatesData)
    const updateMutation = useMutation(updateEmailTemplatesData)

    const [selectedTemplate, setSelectedTemplate] = useState<emailTemplateType | null>(null)
    const [isSaveAsNewChecked, setIsSaveAsNewChecked] = useState(false)
    const [isSaveAsNewDisabled, setIsSaveAsNewDisabled] = useState(false)
    const [templateNameHolder, setTemplateNameHolder] = useState('')
    const [isSaving, setIsSaving] = useState(false)
    const editorRef = useRef<tipTapEditorRefFunctionType>(null)
    const { isWhiteLabeled, csvVariables, isLoading:isConfigLoading, companyWebsite, companyLogo } = useConfig()
    
    const { user } = useAuth()

    let customVariableOrderList= [
        'participantName', 
        'eventName', 
        'organizationName', 
        'certificateId', 
        'eventStartDate', 
        'eventEndDate', 
        'eventHashTags',
        'verificationLink',
        'downloadLink'
    ]
    let customVariableObject:{[key:string]:{
        placeHolder: string,
        displayName: string,
    }} = {
        participantName: {
            placeHolder: '{{name}}',
            displayName: 'Participant Name'
        },
        eventName: {
            placeHolder: '{{event.name}}',
            displayName: 'Event Name'
        },
        organizationName: {
            placeHolder: '{{organization.name}}',
            displayName: 'Organization Name'
        },
        certificateId: {
            placeHolder: '{{certificate_id}}',
            displayName: 'Certificate Id'
        },
        eventStartDate: {
            placeHolder: '{{event.start_date}}',
            displayName: 'Start Date'
        },
        eventEndDate: {
            placeHolder: '{{event.end_date}}',
            displayName: 'End Date'
        },
        eventHashTags: {
            placeHolder: '{{event.hashtags}}',
            displayName: 'Hashtags'
        },
        verificationLink: {
            placeHolder: '{{verification_link}}',
            displayName: 'Verification Link'
        },
        downloadLink: {
            placeHolder: '{{download_link}}',
            displayName: 'Download Link'
        }
    }

    const replacements = {
        '{{companyWebsite}}': companyWebsite || 'https://www.upgrad.com/',
        '{{companyLogo}}': companyLogo || 'https://gpnmjenofbfeawopmhkj.supabase.co/storage/v1/object/public/public/gmc_files/upgrad_logo_red.png'
    }

    if (csvVariables && csvVariables.length > 0 && (isConfigLoading === false)) {
        csvVariables.map(item => {
            customVariableOrderList.push(item.field_name)
            customVariableObject[item.field_name] = {
                placeHolder: `{{csvData.${item.field_name}}}`,
                displayName: item.field_name
            }
            return true
        })
    }
    
    useImperativeHandle( ref, () => ({
        getFullTemplate: () => {
            const editorValues = editorRef?.current?.onSubmit()
            let mailHeader = DEFAULT_EMAIL_TEMPLATE_VERSION_1.header
            if (isWhiteLabeled) {
                mailHeader = replacePlaceholderValueInString(replacements, DEFAULT_EMAIL_TEMPLATE_VERSION_1_WHITELABELED.header)
            }

            const header = mailHeader
            const content = editorValues?.content || DEFAULT_EMAIL_TEMPLATE_VERSION_1.content
            const footer = isWhiteLabeled ? DEFAULT_EMAIL_TEMPLATE_VERSION_1_WHITELABELED.footer : DEFAULT_EMAIL_TEMPLATE_VERSION_1.footer

            return {
                template: header + content + footer,
                subject: editorValues?.subject || DEFAULT_EMAIL_HEADER
            }
        }
    }))

    useEffect(() => {
        setIsSaveAsNewChecked(selectedTemplate?.id ? (selectedTemplate.templateType === 'PUBLIC' ? true : false ) : true)
        setIsSaveAsNewDisabled((selectedTemplate?.templateType === 'PUBLIC' || selectedTemplate?.id === undefined) ? true : false)
    }, [selectedTemplate])

    const handleSaveButtonClick = async () => {
        setIsSaving(true)
        const editorValues = editorRef?.current?.onSubmit()

        let mailHeader = DEFAULT_EMAIL_TEMPLATE_VERSION_1.header
        if (isWhiteLabeled) {
            mailHeader = replacePlaceholderValueInString(replacements, DEFAULT_EMAIL_TEMPLATE_VERSION_1_WHITELABELED.header)
        }

        if (isSaveAsNewChecked) {
            if (templateNameHolder.length < 1) {
                displayError('Data Error', 'Template Name can not be null')
                setIsSaving(false)
                return false
            }
            const dataToInsert = {
                name: templateNameHolder,
                templateType: 'PRIVATE',
                version: 'V1',
                subject: editorValues?.subject || DEFAULT_EMAIL_HEADER,
                template: {
                    name: templateNameHolder,
                    version: 'V1',
                    header: mailHeader,
                    content: editorValues?.content || DEFAULT_EMAIL_TEMPLATE_VERSION_1.content,
                    footer: isWhiteLabeled ? DEFAULT_EMAIL_TEMPLATE_VERSION_1_WHITELABELED.footer : DEFAULT_EMAIL_TEMPLATE_VERSION_1.footer,
                },
                userId: user?.id,
            }
            await insertMutation.mutateAsync(dataToInsert)
        } else {
            if (selectedTemplate?.templateType === 'PUBLIC') {
                displayError('Can not update a public template', 'Please check on the "Save As New" checkbox and give a name to save your template')
                setIsSaving(false)
                return false
            }
            const dataToUpdate = {
                id: selectedTemplate?.id,
                name: selectedTemplate?.name || DEFAULT_EMAIL_TEMPLATE_VERSION_1.name,
                templateType: 'PRIVATE',
                version: 'V1',
                subject: editorValues?.subject || DEFAULT_EMAIL_HEADER,
                template: {
                    name: templateNameHolder,
                    version: 'V1',
                    header: mailHeader,
                    content: editorValues?.content || DEFAULT_EMAIL_TEMPLATE_VERSION_1.content,
                    footer: isWhiteLabeled ? DEFAULT_EMAIL_TEMPLATE_VERSION_1_WHITELABELED.footer : DEFAULT_EMAIL_TEMPLATE_VERSION_1.footer,
                },
                userId: user?.id,
            }
            await updateMutation.mutateAsync(dataToUpdate)
        }
        queryClient.invalidateQueries('emailTemplates')
        setIsSaving(false)
    }

    if ( isError ) {
        return (
            <ErrorComponent
                //@ts-ignore
                errorMessage={error.message}
            />
        )
    }

    if ( isLoading ) {
        return (
            <Stack mt={10} spacing={5} >
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
            </Stack>
        )
    }

    

    if (data && data.isQueryError === false) {
        const { queryData, queryError } = data
        
        const onTemplateSelectionUpdate = (event:any) => {
            const selectedId = event.target.value
            let selectedTemplate = {}
            queryData?.map( (item) => {
                if (item.id === selectedId) {
                    selectedTemplate = item
                    return true
                }
                return false
            })
            //@ts-ignore
            setSelectedTemplate(selectedTemplate)
        }

        if ( queryError ) {
            return (
                <ErrorComponent
                    errorMessage={queryError.message}
                />
            )
        }
    
        return (
            <Box>
                <Flex mb={4}>
                    <Spacer />
                    <Select placeholder='Select Template' onChange={onTemplateSelectionUpdate}>
                        {
                            queryData?.map( (item:any) => {
                                return (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                )
                            })
                        }
                    </Select>
                </Flex>
                <TipTapEditor 
                    showCustomVariableBar={true}
                    initialEmailContent={selectedTemplate?.id ? selectedTemplate.template.content : undefined}
                    initialEmailSubject={selectedTemplate?.id ? selectedTemplate.subject : undefined}
                    isShowSaveButton={false}
                    ref={editorRef}
                    customVariableOrderList={customVariableOrderList}
                    customVariableObject={customVariableObject}
                />
                <Flex my='5' p='3'>
                    <Checkbox 
                        isChecked={isSaveAsNewChecked}
                        onChange={() => {
                            setIsSaveAsNewChecked(!isSaveAsNewChecked)
                        }}
                        isDisabled={isSaveAsNewDisabled}
                    >
                        Save As New
                    </Checkbox>
                    <Spacer />
                    <InputGroup w='50%'>
                        <InputLeftAddon children='Name' />
                        <Input 
                            type='text' 
                            defaultValue={templateNameHolder} 
                            placeholder='Template Name' 
                            isInvalid={(isSaveAsNewChecked && (templateNameHolder.length < 1)) ? true : false } 
                            onBlur={ (e) => {
                                setTemplateNameHolder(e.target.value)
                            }}
                        />
                    </InputGroup>
                </Flex>
                <Flex w="100%" mt={4} justifyContent="center">
                    <Button
                        variant="solid" 
                        colorScheme="blue" 
                        px="10" 
                        isLoading={isSaving}
                        loadingText="Saving..."
                        onClick={handleSaveButtonClick}
                    >
                        Save
                    </Button>
                </Flex>
            </Box>
        )
    }

    return (
        <ErrorComponent
        />
    )
})

import supabase from "../configs/Supabase"
import { displayError, displaySuccess } from "../helpers/CommonFunctions"
import { emailTemplateFetcherObjectReturnType, emailTemplateType } from "../types/dataFetcherTypes/EmailTemplatesDataFetcherTypes"



export const getEmailTemplatesData = async (page:number=1, size:number=10, sortOnColumn:string='updated_at', sortOrder:string='ascending' ): Promise<emailTemplateFetcherObjectReturnType> => {
    try {
        let isError = false
        const { data, error } = await supabase
                                .from( 'email_templates' )
                                .select( ` 
                                    id,
                                    name,
                                    templateType:template_type, 
                                    version, 
                                    subject,
                                    template:template, 
                                    userId:user_id, 
                                    createdAt:created_at, 
                                    updatedAt:updated_at
                                `)
                                .eq('is_deleted', false)
                                .order( sortOnColumn, {
                                    ascending: (sortOrder === 'ascending')
                                })
                                .range( (page-1)*size, (page*size)-1 )
        if (error) {
            isError = true
        }

        return {
            isQueryError: isError,
            queryData: data as emailTemplateType[] | null,
            queryError: error
        }
    } catch (error) {
        throw error;  
    }
}

export const insertEmailTemplatesData = async ( updatedData:emailTemplateType ): Promise<emailTemplateFetcherObjectReturnType> => {
    const updatedDataObject = {
        name: updatedData.name,
        template_type: updatedData.templateType,
        version: updatedData.version,
        subject: updatedData.subject,
        template: updatedData.template,
        user_id: updatedData.userId,
    }

    let isError = false
    const { data, error } = await supabase.from( 'email_templates' ).insert(updatedDataObject)

    if (error) {
        isError = true
        displayError( 'Data Error', error.message || error.details || error.hint)
    } else {
        displaySuccess('Saved', 'Data Saved successfully')
    }

    return {
        isQueryError: isError,
        queryData: data as emailTemplateType[] | null,
        queryError: error
    }
}

export const updateEmailTemplatesData = async ( updatedData:emailTemplateType ): Promise<emailTemplateFetcherObjectReturnType> => {
    
    const updatedDataObject = {
        id: updatedData.id,
        name: updatedData.name,
        template_type: updatedData.templateType,
        version: updatedData.version,
        subject: updatedData.subject,
        template: updatedData.template,
        user_id: updatedData.userId,
    }

    let isError = false
    const { data, error } = await supabase.from( 'email_templates' )
                                            .update(updatedDataObject)
                                            .match({
                                                id: updatedData.id
                                            })

    if (error) {
        isError = true
        displayError( 'Data Error', error.message || error.details || error.hint)
    } else {
        displaySuccess('Saved', 'Data Saved successfully')
    }

    return {
        isQueryError: isError,
        queryData: data as emailTemplateType[] | null,
        queryError: error
    }
}
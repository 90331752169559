import { LinkBox, LinkOverlay } from '@chakra-ui/react'
import { Flex, HTMLChakraProps, Image, Text, useToken } from '@chakra-ui/react'
import * as React from 'react'
import GMCLogSq from '../../assets/images/GMCLogSq.png'

export const Logo = (props: HTMLChakraProps<'div'> & { iconColor?: string }) => {
  const { iconColor = 'currentColor', ...rest } = props
  const color = useToken('colors', iconColor)
  return (
    <LinkBox>
      <Flex
        alignItems="center"
        {...rest}
      >
        <Image
          src={GMCLogSq} 
          h="inherit"
          mr={2}
          color={color}
        />
        <LinkOverlay href="/" >
          <Text
            fontSize="inherit"
            color="#32d4e1"
            fontWeight="700"
          >
            Give My Certificate
          </Text>
        </LinkOverlay>
      </Flex>
    </LinkBox>
  )
}

import { Icon, IconButton, InputLeftElement, InputProps } from '@chakra-ui/react'
import { Input, InputGroup, InputRightElement, useClipboard } from '@chakra-ui/react'
import React, { useState } from 'react'
import { FaCheckCircle, FaCopy, FaExternalLinkAlt } from 'react-icons/fa'

interface InputWithCopyProps extends InputProps {
    toCopy: string
    linkIcon?: boolean
    makeLink?: boolean
}

export const InputWithCopy = ( props:InputWithCopyProps ) => {
    const { toCopy, linkIcon=false, makeLink=false, ...restProps } = props
    const [ valueToCopy ] = useState(toCopy)
    const { hasCopied, onCopy } = useClipboard(valueToCopy)
    return (
        <InputGroup size="md" minW="150px">
            {linkIcon && <InputLeftElement>
                <IconButton
                    aria-label="Copy Value"
                    icon={ <FaExternalLinkAlt /> }
                    onClick={() => window.open(valueToCopy, "_blank")}
                    variant='ghost'
                />
            </InputLeftElement>}
            {
                makeLink ? 
                <Input
                    cursor='pointer'
                    onClick={() => window.open(valueToCopy, "_blank")}
                    defaultValue={toCopy}
                    {...restProps}
                />
                :
                <Input
                    defaultValue={toCopy}
                    {...restProps}
                />
            }
            <InputRightElement>
                <IconButton
                    aria-label="Copy Value"
                    icon={ hasCopied ? <Icon as={FaCheckCircle} color="teal" /> : <FaCopy /> }
                    onClick={onCopy}
                />
            </InputRightElement>
        </InputGroup>
    )
}

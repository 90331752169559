import { Button, Flex, FlexProps, FormControl, FormErrorMessage, FormLabel, Input, Spacer, Stack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useAuth } from '../../contexts/Auth'
import { displayError, displaySuccess } from '../../helpers/CommonFunctions'

const schema = yup.object().shape({
	email: yup.string().email('Invalid Email').required(),
})

export type ChangeEmailFormInputs = {
	email: string;
}

interface ChangeEmailInputPropsType extends FlexProps {
    actionOnSubmitSuccess?: (...args: any[]) => any
    loadingStatus?: (...args: any[]) => any
    showActionOnBottom?: boolean
    hideSubmitButton?: boolean
    formId?: string
}

export const ChangeEmail = (props:ChangeEmailInputPropsType) => {

    const { changeUserEmail } = useAuth()
    const { actionOnSubmitSuccess, showActionOnBottom, formId, loadingStatus, hideSubmitButton } = props

    const methods = useForm<ChangeEmailFormInputs>({
		mode: 'onBlur',
		resolver: yupResolver(schema),
	})
    const { handleSubmit, formState: { errors, isSubmitting }, register } = methods

    useEffect( () => {
        if (loadingStatus) {
            loadingStatus( isSubmitting )
        }
    }, [loadingStatus, isSubmitting])

    const handleChangeEmailButtonClick = async (values:ChangeEmailFormInputs) => {
        console.log('lllll', values);
        
        const { user, error } = await changeUserEmail(values)

        console.log('uuuuuu', user);
        console.log('eeeeee', error);
        
        if (user) {
            displaySuccess('Link Sent', 'We have sent a verification link to both new and old email')
            if (actionOnSubmitSuccess) {
                actionOnSubmitSuccess()
            }
        } else {
            displayError( 'Something is not right', error?.message || 'Seems to be an error, Please contect support' )
        }
    }
    
    return (
        <FormProvider {...methods} >
            <form
                onSubmit={handleSubmit(handleChangeEmailButtonClick)}
                style={{
                    height: '100%'
                }}
                id={formId}
            >
                <Stack spacing="4" h="100%">
                    <FormControl
                        id="email"
                        isInvalid={!!errors?.email}
                        errortext={errors?.email?.message}
                        isRequired
                    >
                        <Flex align="baseline" justify="space-between">
                            <FormLabel mb={1}>New Email</FormLabel>
                        </Flex>
                        <Input type='email' {...register('email')}  />
                        <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
                    </FormControl>
                    { showActionOnBottom && <Spacer /> }
                    {
                        !hideSubmitButton &&
                        <Button 
                            type="submit" 
                            colorScheme="blue" 
                            size="lg" 
                            fontSize="md" 
                            isLoading={isSubmitting} 
                            loadingText="Submitting..."
                            isDisabled={Object.keys(errors).length !== 0}
                        >
                            Change Email
                        </Button>
                    }
                </Stack>
            </form>
        </FormProvider>
    )
}

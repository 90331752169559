import React, { HTMLProps } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    Stack,
    StackDivider,
    Heading,
    FormLabel,
    FormControl,
    VStack,
    Input,
    FormErrorMessage,
    Button,
    Textarea,
    FormHelperText,
    InputLeftAddon,
    InputGroup,
    HStack,
    Text,
    useColorModeValue as mode,
    Select,
} from '@chakra-ui/react'
import { FieldGroup } from '../../components/fieldGroup/FieldGroup'
import { ButtonRadioGroup } from '../../components/buttonRadioGroup/ButtonRadioGroup'
import moment from 'moment'
import { createDateWithDays } from '../../helpers/CommonFunctions'
import { FaTransgenderAlt, FaMale, FaFemale, FaLinkedinIn, FaFacebookF, FaInstagram, FaTwitter, FaGlobe, FaGlobeAsia, FaGlobeEurope } from 'react-icons/fa'
import { useState } from 'react'
import { useAuth } from '../../contexts/Auth'
import { drawerComponentRefFunctionType, DrawerComponentWithRef } from '../drawerComponent/DrawerComponent'
import { ChangePassword } from '../changePassword/ChangePassword'
import { countryStateDataObject } from '../../dataObjects/countryStatesDataObject'
import { defaultAvatarsType } from '../../dataObjects/DefaultAvatarsObject'
import { randomUserAvatar } from '../../stores/UserDataStore'
import { useState as useHookState } from '@hookstate/core'
import { useRef } from 'react'
import { ChangeEmail } from '../changeEmail/ChangeEmail'

export interface ProfileFormInputType {
    fullName?: string 
    gender?: 'male' | 'female' | 'other' | null
    genderString?: string | null
    dob?: Date | string
    linkedinId?: string 
    facebookHandle?: string 
    twitterHandle?: string 
    instaHandle?: string 
    about?: string
    avatarURL?: string
    website?: string
    country?: string
    state?: string
}

interface ProfileFormPropsType extends HTMLProps<HTMLFormElement> {
    initialData: ProfileFormInputType,
    onSubmitFunction: ( arg:any ) => any
}

export const ProfileForm = ( props:ProfileFormPropsType ) => {

    const { initialData, onSubmitFunction, ...restProps } = props

    const [genderValue, setgenderValue] = useState<'male'|'female'|'other'|null>(initialData.gender!)
    const [isSavePasswordLoading, setIsSavePasswordLoading] = useState(false)
    const [selectedCountry, setselectedCountry] = useState( initialData.country || '' )
    const userAvatarObj = useHookState<defaultAvatarsType>(randomUserAvatar)
    const { user } = useAuth()
    const changePasswordDwawerRef = useRef<drawerComponentRefFunctionType>()   
    const changeEmailDwawerRef = useRef<drawerComponentRefFunctionType>()  
    
    const schema = yup.object().shape({
        fullName: yup.string().min(3), 
        genderString: yup.string().nullable(),
        dob: yup.date()
                .nullable()
                .default( createDateWithDays( -30 ) )
                .max( moment(new Date()).subtract( 30, 'days' ).format('YYYY-MM-DD').toString(), 'Sorry, But we had trouble when we used to let toddlers operate' )
                .min( moment(new Date()).subtract( 100, 'years' ).format('YYYY-MM-DD').toString(), 'Belive us we tried but we can\'t find your name in record books' ), 
        linkedinId: yup.string().url(), 
        facebookHandle: yup.string().url(), 
        twitterHandle: yup.string().url(), 
        instaHandle: yup.string().url(), 
        about: yup.string(),
        website: yup.string().url(),
        country: yup.string(),
        state: yup.string(),
    })

    const methods = useForm<ProfileFormInputType>({
		mode: 'onBlur',
		resolver: yupResolver(schema),
        defaultValues: initialData
	})

	const { register, handleSubmit, formState: { errors, isSubmitting } } = methods

    const handleProfileSaveButtonClick = async (values:any) => {
        values.gender = (genderValue) ? ( (['male', 'female'].includes(genderValue)) ? genderValue : values.genderString ) : initialData.gender 
        values.id = user?.id
        values.fullName = ( values.fullName === userAvatarObj.displayName.get() ) ? null : values.fullName
        values.about = ( values.about === userAvatarObj.superPowers.get() ) ? null : values.about
        // values.avatarURL = ( values.avatarURL === userAvatarObj.URL.get() ) ? null : values.avatarURL
        values.avatarURL = undefined
        await onSubmitFunction(values)
	}

    return (
        <form
            onSubmit={handleSubmit(handleProfileSaveButtonClick)}
            {...restProps}
        >
            <Stack 
                spacing="4" 
                divider={<StackDivider />} 
                mt={12} 
                w={{
                    base: undefined,
                    sm: "md",
                    md: "xl"
                }}
            >
                <Heading size="lg" as="h1" paddingBottom="4">
                    My Profile
                </Heading>
                <FieldGroup title="Personal Info">
                    <VStack width="full" spacing="6">
                        <FormControl 
                            id="fullname"
                            isInvalid={!!errors?.fullName}
                            errortext={errors?.fullName?.message}
                        >
                            <FormLabel>Name</FormLabel>
                            <Input 
                                type="text" 
                                autoComplete="name" 
                                maxLength={255}
                                {...register('fullName')} 
                            />
                            <FormErrorMessage>{errors?.fullName?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="about"
                            isInvalid={!!errors?.about}
                            errortext={errors?.about?.message}
                        >
                            <FormLabel>About</FormLabel>
                            <Textarea 
                                type="text" 
                                autoComplete="name" 
                                maxLength={255}
                                rows={5}
                                {...register('about')} 
                            />
                            <FormErrorMessage>{errors?.about?.message}</FormErrorMessage>
                            <FormHelperText>
                                Some words about you.
                            </FormHelperText>
                        </FormControl>

                        <FormControl 
                            id="dob"
                            isInvalid={!!errors?.dob}
                            errortext={errors?.dob?.message}
                        >
                            <FormLabel>Date Of Birth</FormLabel>
                            <Input 
                                type="date" 
                                autoComplete="dob" 
                                maxLength={255}
                                {...register('dob')} 
                            />
                            <FormErrorMessage>{errors?.dob?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="gender"
                            isInvalid={!!errors?.dob}
                            errortext={errors?.dob?.message}
                        >
                            <FormLabel>Gender</FormLabel>
                            <ButtonRadioGroup
                                defaultValue={ initialData.gender! }
                                options={[
                                {
                                    label: 'Male',
                                    icon: <FaMale />,
                                    value: 'male',
                                },
                                {
                                    label: 'Female',
                                    icon: <FaFemale />,
                                    value: 'female',
                                },
                                {
                                    label: 'Other',
                                    icon: <FaTransgenderAlt />,
                                    value: 'other',
                                },
                                ]}
                                //@ts-ignore
                                onChange={setgenderValue}
                            />
                            <FormErrorMessage>{errors?.gender?.message}</FormErrorMessage>
                        </FormControl>

                        {
                            (genderValue === 'other') &&
                            <FormControl 
                                id="genderString"
                                isInvalid={!!errors?.genderString}
                                errortext={errors?.genderString?.message}
                            >
                                <FormLabel>How do you identify yourself</FormLabel>
                                <Input 
                                    type="text" 
                                    autoComplete="gender" 
                                    maxLength={255}
                                    {...register('genderString')} 
                                />
                                <FormErrorMessage>{errors?.genderString?.message}</FormErrorMessage>
                            </FormControl>
                        }
                    </VStack>
                </FieldGroup>

                <FieldGroup title="Security">
                    <VStack width="full" spacing="6">
                        <FormControl >
                            <FormLabel>Email</FormLabel>
                            <HStack>
                                <Input 
                                    type="text" 
                                    isDisabled={true}
                                    defaultValue={user?.email}
                                />
                                <DrawerComponentWithRef 
                                    triggerComponent={
                                        <Button
                                            ml="4"
                                            p={2}
                                            variant="link"
                                            color={mode('blue.600', 'blue.300')}
                                        >
                                            Change
                                        </Button>
                                    }
                                    heading="Change Email"
                                    showFooter={ true }
                                    footerButtons={
                                        <Button 
                                            colorScheme="blue"
                                            type="submit"
                                            form="changeEmailFromProfile"
                                            isLoading={isSavePasswordLoading}
                                            loadingText="Processing"
                                        >
                                            Change Email
                                        </Button>
                                    }
                                    ref={changeEmailDwawerRef}
                                >
                                    <ChangeEmail 
                                        showActionOnBottom={ true }
                                        formId="changeEmailFromProfile"
                                        loadingStatus={setIsSavePasswordLoading}
                                        hideSubmitButton={ true }
                                        actionOnSubmitSuccess={ () => {
                                            changeEmailDwawerRef?.current?.onClose()
                                        }}
                                    />
                                </DrawerComponentWithRef>
                            </HStack>
                        </FormControl>

                        <FormControl >
                            <FormLabel>Password</FormLabel>
                            <HStack>
                                <Text>**********</Text>
                                
                                <DrawerComponentWithRef 
                                    triggerComponent={
                                        <Button
                                            ml="4"
                                            p={2}
                                            variant="link"
                                            color={mode('blue.600', 'blue.300')}
                                        >
                                            Change
                                        </Button>
                                    }
                                    heading="Change Password"
                                    showFooter={ true }
                                    footerButtons={
                                        <Button 
                                            colorScheme="blue"
                                            type="submit"
                                            form="changePasswordFromProfile"
                                            isLoading={isSavePasswordLoading}
                                            loadingText="Processing"
                                        >
                                            Save
                                        </Button>
                                    }
                                    ref={changePasswordDwawerRef}
                                >
                                    <ChangePassword 
                                        showActionOnBottom={ true }
                                        formId="changePasswordFromProfile"
                                        loadingStatus={setIsSavePasswordLoading}
                                        hideSubmitButton={ true }
                                        actionOnSubmitSuccess={ () => {
                                            changePasswordDwawerRef?.current?.onClose()
                                        }}
                                    />
                                </DrawerComponentWithRef>
                            </HStack>
                        </FormControl>
                    </VStack>
                </FieldGroup>

                <FieldGroup title="Social">
                    <VStack width="full" spacing="6">
                        <FormControl 
                            id="linkedinId"
                            isInvalid={!!errors?.linkedinId}
                            errortext={errors?.linkedinId?.message}
                        >
                            <FormLabel>Linkedin Id</FormLabel>
                            <InputGroup >
                                <InputLeftAddon children={<FaLinkedinIn />} />
                                <Input 
                                    type="text" 
                                    autoComplete="linkedinId" 
                                    maxLength={255}
                                    {...register('linkedinId')} 
                                />
                            </InputGroup>
                            <FormErrorMessage>{errors?.linkedinId?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="facebookHandle"
                            isInvalid={!!errors?.facebookHandle}
                            errortext={errors?.facebookHandle?.message}
                        >
                            <FormLabel>Facebook Handle</FormLabel>
                            <InputGroup >
                                <InputLeftAddon children={<FaFacebookF />} />
                                <Input 
                                    type="text" 
                                    autoComplete="facebookHandle" 
                                    maxLength={255}
                                    {...register('facebookHandle')} 
                                />
                            </InputGroup>
                            <FormErrorMessage>{errors?.facebookHandle?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="twitterHandle"
                            isInvalid={!!errors?.twitterHandle}
                            errortext={errors?.twitterHandle?.message}
                        >
                            <FormLabel>Twitter Handle</FormLabel>
                            <InputGroup >
                                <InputLeftAddon children={<FaTwitter />} />
                                <Input 
                                    type="text" 
                                    autoComplete="twitterHandle" 
                                    maxLength={255}
                                    {...register('twitterHandle')} 
                                />
                            </InputGroup>
                            <FormErrorMessage>{errors?.twitterHandle?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="instaHandle"
                            isInvalid={!!errors?.instaHandle}
                            errortext={errors?.instaHandle?.message}
                        >
                            <FormLabel>Instagram Handle</FormLabel>
                            <InputGroup >
                                <InputLeftAddon children={<FaInstagram />} />
                                <Input 
                                    type="text" 
                                    autoComplete="instaHandle" 
                                    maxLength={255}
                                    {...register('instaHandle')} 
                                />
                            </InputGroup>
                            <FormErrorMessage>{errors?.instaHandle?.message}</FormErrorMessage>
                        </FormControl>
                    </VStack>
                </FieldGroup>

                <FieldGroup title="Extras">
                    <VStack width="full" spacing="6">
                        <FormControl 
                            id="website"
                            isInvalid={!!errors?.website}
                            errortext={errors?.website?.message}
                        >
                            <FormLabel>Website</FormLabel>
                            <InputGroup >
                                <InputLeftAddon children={<FaGlobe />} />
                                <Input 
                                    type="text" 
                                    autoComplete="website" 
                                    maxLength={255}
                                    {...register('website')} 
                                />
                            </InputGroup>
                            <FormErrorMessage>{errors?.website?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="country"
                            isInvalid={!!errors?.country}
                            errortext={errors?.country?.message}
                        >
                            <FormLabel>Country</FormLabel>
                            <InputGroup >
                                <InputLeftAddon children={<FaGlobeEurope />} />
                                <Select placeholder={initialData.country || "Select country"} {...register('country')} borderLeftRadius="0" onChange={(val)=> setselectedCountry(val.target.value)}>
                                    {
                                        countryStateDataObject.map((countryObj) => {
                                            return (
                                                <option key={countryObj.name} value={countryObj.name}>{countryObj.name}</option>
                                            )
                                        })
                                    }
                                </Select>
                            </InputGroup>
                            <FormErrorMessage>{errors?.country?.message}</FormErrorMessage>
                        </FormControl>

                        <FormControl 
                            id="state"
                            isInvalid={!!errors?.state}
                            errortext={errors?.state?.message}
                        >
                            <FormLabel>State</FormLabel>
                            <InputGroup >
                                <InputLeftAddon children={<FaGlobeAsia />} />
                                <Select placeholder={initialData.state || "Select state" } {...register('state')} defaultValue={initialData.state} borderLeftRadius="0" >
                                    { initialData.state && <option value={initialData.state}>{initialData.state}</option>}
                                    {
                                        countryStateDataObject.find( name => name?.name === selectedCountry )?.states.map((countryObj) => {
                                            return (
                                                <option key={countryObj.name} value={countryObj.name}>{countryObj.name}</option>
                                            )
                                        })
                                    }
                                </Select>
                            </InputGroup>
                            <FormErrorMessage>{errors?.state?.message}</FormErrorMessage>
                        </FormControl>

                    </VStack>
                </FieldGroup>
                <FieldGroup mt="8">
                    <HStack width="full">
                        <Button 
                            type="submit" 
                            colorScheme="blue"
                            isLoading={isSubmitting}
                            loadingText="Submitting.."
                            // isDisabled={!isDirty}
                        >
                            Save Changes
                        </Button>
                        <Button variant="outline">Cancel</Button>
                    </HStack>
                </FieldGroup>
            </Stack>
        </form>
    )
}

import { useEffect } from "react"
import { useQuery } from "react-query"
import { DEFAULT_ORGANISATION_CONFIG } from "../configs/GlobalConstants"
import { getAccountConfig } from "../dataFetchers/accountConfigFetcher"
import { getConfigData } from "../dataFetchers/userConfigFetcher"
import { genericObjectType } from "../helpers/CommonFunctions"
import { useHookState } from "./useHookState"

export const useConfig = () => {
    const [configState, setConfigState] = useHookState({
        max_allowed_organizations: 1,
        plan_type: 'FREE',
        max_allowed_events: 10,
        template_initial_elements: {
            "gmc_qr": {
              "required": true
            },
            "gmc_link": {
              "required": true
            },
            "name": {
              "required": true
            }
        },
        is_white_labeled: false,
        company_website: '',
        company_logo: '',
        isLoading: true,
        csv_variables: [] as genericObjectType[]
    })
    const { data, isLoading } = useQuery('userConfig', getConfigData)
    const { data:accountLevelConfigData, isLoading:accountLevelConfigLoading } = useQuery('accountLevelConfig', getAccountConfig)

    useEffect( () => {
        if (data && accountLevelConfigData) {
            setConfigState({
                max_allowed_organizations: data.queryData?.max_allowed_organizations || 1,
                plan_type: data.queryData?.plan_type || 'FREE',
                max_allowed_events: data.queryData?.max_allowed_events || 10,
                template_initial_elements: data.queryData?.template_initial_elements || DEFAULT_ORGANISATION_CONFIG.template_initial_elements,
                is_white_labeled: data.queryData?.is_white_labeled || DEFAULT_ORGANISATION_CONFIG.is_white_labeled,
                company_website: data.queryData?.company_website || '',
                company_logo: data.queryData?.company_logo || '',
                isLoading: isLoading || accountLevelConfigLoading,
                csv_variables: accountLevelConfigData?.queryData.csv_variables || []
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, accountLevelConfigData, isLoading, accountLevelConfigLoading])

    return {
        maxOrganizationsAllowed: configState.max_allowed_organizations,
        planType: configState.plan_type,
        maxEventsAllowed: configState.max_allowed_events,
        templateInitialElements: configState.template_initial_elements,
        isWhiteLabeled: configState.is_white_labeled,
        companyWebsite: configState.company_website,
        companyLogo: configState.company_logo,
        isLoading: configState.isLoading,
        csvVariables: configState.csv_variables
    }
}

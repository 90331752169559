import { Box, Button, Divider, Flex, FormControl, FormLabel, Input, Skeleton, Spacer, Stack, Text, Textarea } from '@chakra-ui/react'
import moment from 'moment'
import Papa from 'papaparse'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { FaDownload } from 'react-icons/fa'
import { FIELDS_TO_EXCLUDE_FROM_CSV } from '../../configs/GlobalConstants'
import supabase from '../../configs/Supabase'
import { displayError, exportCSV, genericObjectType } from '../../helpers/CommonFunctions'
import { certificateDataType, jobDataType } from '../../types/dataFetcherTypes/JobsDataFetcherTypes'
import { CardWithColorAccent } from '../cardWithColorAccent/CardWithColorAccent'
import { JsonToTable } from '../jsonToTable/JsonToTable'

export interface csvDataType extends genericObjectType {
    name: string;
    email: string;
}

export interface AddSubJobFormType {
    getCsvData: () => csvDataType | csvDataType[],
    csvDataHolder: csvDataType | csvDataType[]
}

export const AddSubJobForm = forwardRef(({
    jobId,
    jobData,
    certificateData
}:{
    jobId: string,
    jobData?: jobDataType
    certificateData?: certificateDataType
}, ref ) => {
    const [csvKeys, setCsvKeys] = useState<Array<string>>([])

    const [fileHolder, setFileHolder] = useState()
    const [urlHolder, setUrlHolder] = useState('')
    
    const [isValidCSVURL, setIsValidCSVURL] = useState(true)
    const [csvDataHolder, setCsvDataHolder] = useState<object[] | null>(null)
    const textAreaForCSVStringRef = useRef<HTMLTextAreaElement>(null)
    
    useImperativeHandle( ref, () => ({
        getCsvData: () => {
            return csvDataHolder
        },
        csvDataHolder
    }))

    const handleFileChange = (e:any) => {
        const file = e.target.files[0]
        if (file) {
            if (file.name) {
                setFileHolder(file)
            } else {
                displayError('Not a csv file', 'We are supporting only CSV files for now')
            }
        }
    }

    const handleFileURLChange = (e:any) => {
        const url = e.target.value
        if (url.length === 0 || url.split('.').pop() === 'csv') {
            setUrlHolder(url)
            setIsValidCSVURL(true)
        } else {
            setIsValidCSVURL(false)
        }
    }

    const checkCSVColumns = (csvRow:object) => {
        const providedFields = Object.keys(csvRow)
        const missingFields = csvKeys.filter( key => !providedFields.includes(key))

        if (missingFields.length > 0) {
            displayError('Not all fields are provided', `Following fields are missing from CSV. "${missingFields.join(', ')}". Please add these fields to CSV before proceeding further`)
            return false
        }
        return true
    }

    const parseCSV = () => {
        if( fileHolder ) {
            Papa.parse(fileHolder as unknown as File, {
                worker: true,
                header: true,
                // transformHeader doesn't work with worker:true so commenting it for now 
                // transformHeader: (header) => {
                //     return header.toLowerCase()
                // },
                skipEmptyLines: 'greedy',
                complete: (result) => {
                    const data = result.data as object[]
                    const isValidData = checkCSVColumns(data[0])
                    if (!isValidData) {
                        return false
                    }
                    
                    //Redundent code as skipEmptyLines: 'greedy' does the same thing
                    // const nonEmptyResult = filterEmptyValues(data, 'email')
                    // if (data.length > nonEmptyResult.length) {
                    //     displayInfo('Empty Rows detected in CSV Data', `We have detected ${data.length - nonEmptyResult.length} empty rows in data and removed them, Please check if not intentional`)
                    // }
                    setCsvDataHolder(data)
                }
            });
        } else if (urlHolder){
            Papa.parse(urlHolder, {
                worker: true,
                header: true,
                download:true,
                skipEmptyLines: 'greedy',
                complete: (result) => {
                    const data = result.data as object[]
                    const isValidData = checkCSVColumns(data[0])
                    if (!isValidData) {
                        return false
                    }
                    setCsvDataHolder(data)
                }
            });
        }
    }

    const parseString = () => {
        let CSVString = textAreaForCSVStringRef.current?.value!
        Papa.parse(CSVString, {
            worker: true,
            header: true,
            skipEmptyLines: 'greedy',
            complete: (result) => {
                const data = result.data as object[]
                const isValidData = checkCSVColumns(data[0])
                if (!isValidData) {
                    return false
                }
                setCsvDataHolder(data)
            }
        })
    }
    
    const fetchCsvKeys = async () => {
        let CSVKeys:Array<string> = []
        if (certificateData && certificateData.extra_metadata && Object.keys(certificateData.extra_metadata).length > 0 ) {
            CSVKeys = Object.keys(certificateData.extra_metadata).filter( i => !['certificateType'].includes(i) )
        } else if (jobData && jobData.template_id && jobData.template_id.length > 0) {
            const { data, error } = await supabase.from('templates')
                                                    .select('elements')
                                                    .eq('id' ,jobData.template_id)
                                                    .limit(1)
                                                    .single()
            if (error) {
                displayError('Something Broke', 'An error occurred. please try again later')
                return false
            }
            CSVKeys = [
                ...Object.keys(data.elements)
                    .filter( i => !FIELDS_TO_EXCLUDE_FROM_CSV.includes(i) )
                    .map(key => data?.elements![key]['header']), 
                'email'
            ]
        } else if (jobId && jobId.length > 0) {
            const { data:jobData, error:jobError } = await supabase.from('jobs')
                                                    .select('template_id')
                                                    .eq('id' ,jobId)
                                                    .limit(1)
                                                    .single()
            if (jobError) {
                displayError('Something Broke', 'An error occurred. please try again later')
                return false
            }
            const { data, error } = await supabase.from('templates')
                                                    .select('elements')
                                                    .eq('id' ,jobData.template_id)
                                                    .limit(1)
                                                    .single()
            if (error) {
                displayError('Something Broke', 'An error occurred. please try again later')
                return false
            }
            CSVKeys = [
                ...Object.keys(data.elements)
                    .filter( i => !['gmc_qr', 'gmc_link'].includes(i) )
                    .map(key => data?.elements![key]['header']), 
                'email'
            ]
        } else {
            displayError('Something Broke', 'An error occurred. please try again later')
            return false
        }
        setCsvKeys(CSVKeys)
    }

    const handleDownloadSampleCSV = () => {
        let sampleCSVJSONHolder = []
        let sampleCSVRow:any = {}
        csvKeys.map( key => {
            switch (key) {
                case 'candidate_name':
                    sampleCSVRow['candidate_name'] = 'Satyapal Sharma'
                    break;

                case 'name':
                    sampleCSVRow['name'] = 'Satyapal Sharma'
                    break;

                case 'email':
                    sampleCSVRow['email'] = 'support@givemycertificate.com'
                    break;

                case 'date':
                    sampleCSVRow['date'] = moment().format('YYYY-MM-DD')
                    break;
            
                default:
                    sampleCSVRow[key] = `Value for key ${key}`
                    break;
            }
            return true
        })
        sampleCSVJSONHolder.push(sampleCSVRow)
        
        exportCSV(Papa.unparse(sampleCSVJSONHolder), `Sample_CSV_for_data_upload.csv`)
    }

    useEffect(() => {
        fetchCsvKeys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    if (csvKeys.length === 0) {
        return (
            <Stack mt={10} spacing={5} >
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
            </Stack>
        )
    }
    return (
        <Box>
            <Flex>
                <Spacer/>
                <Button 
                    variant="outline"
                    onClick={handleDownloadSampleCSV}
                    maxW="40%"
                    whiteSpace="normal"
                    size="md"
                    rightIcon={<FaDownload />}
                >
                    <Text fontSize={["xx-small", 'sm', 'md', 'md']}>
                        Download Sample CSV
                    </Text>
                </Button>
            </Flex>
            <Stack direction={['column', null, "row", null]} spacing={4} justifyContent="space-between" w="100%" mt={3}>
                <CardWithColorAccent w={['100%', null, '45%', null]}>
                    <FormControl>
                        <FormLabel>Upload CSV</FormLabel>
                        <Input 
                            type="file" 
                            p="4px" 
                            accept=".csv"
                            onChange={(e) => {
                                handleFileChange(e)
                            }}
                            onClick={(e) => {
                                e.currentTarget.value = ''
                            }}
                        />
                    </FormControl>

                    <Divider my={8} />
                    <Box border="1px solid" borderRadius="50%" w="fit-content" p={2} mx="auto" mt="-52px">
                        Or
                    </Box>

                    <FormControl mt={3}>
                        <FormLabel>Fetch from an URL</FormLabel>
                        <Input 
                            type="url" 
                            isInvalid={!isValidCSVURL}
                            errorBorderColor="red.300"
                            onChange={(e) => {
                                handleFileURLChange(e)
                            }}
                        />
                    </FormControl>
                    <Flex mt="3" justifyContent="center">
                        <Button variant="solid" colorScheme="blue" onClick={parseCSV} >
                            Fetch Data
                        </Button>
                    </Flex>
                </CardWithColorAccent>

                <CardWithColorAccent w={['100%', null, '45%', null]}>
                    <Flex direction="column" h="100%">
                        <FormControl mt={3}>
                            <FormLabel>Enter CSV String</FormLabel>
                            <Textarea
                                ref={textAreaForCSVStringRef}
                                placeholder="Here is a sample placeholder"
                                size="md"
                            />
                        </FormControl>
                        <Flex mt="auto" justifyContent="center">
                            <Button variant="solid" colorScheme="blue" onClick={parseString} >
                                Decode Data
                            </Button>
                        </Flex>
                    </Flex>
                </CardWithColorAccent>
            </Stack>

            {
                csvDataHolder && (csvDataHolder.length > 0) && 
                <>
                    <CardWithColorAccent mt={4} w="100%" maxW="100%" maxH="300px" overflowY="auto">
                        <Flex>
                            <Spacer />
                            <Text>Total line parsed :- {csvDataHolder.length}</Text>
                        </Flex>
                        <JsonToTable jsonData={csvDataHolder} />
                    </CardWithColorAccent>
                </>
            }
        </Box>
    )
})
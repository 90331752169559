import { Avatar, Button, ButtonGroup, IconButton, Input, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import React, { useRef } from 'react'
import { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { FaCaretDown, FaCheck, FaEdit } from 'react-icons/fa'
import { HiPencilAlt } from 'react-icons/hi'
import { IMAGE_TOO_LARGE_MESSAGE, IMAGE_TOO_LARGE_TITLE, NOT_IMAGE_MESSAGE, NOT_IMAGE_TITLE, USER_ERROR_NOT_ALLOWED } from '../../configs/GlobalConstants'
import supabase from '../../configs/Supabase'
import { displayError, getFileNameWithExt, isFileImage } from '../../helpers/CommonFunctions'

export interface PictureUploaderInputType {
    bucketName:string
    path:string
    pictureId:string
    fieldId:string
    initialPicURL:string
    pictureName?:string
    updateUrlFunction: ( params:any ) => any
}

export const PictureUploader = ( props:PictureUploaderInputType ) => {

    const { bucketName, initialPicURL, path, pictureId, fieldId, pictureName, updateUrlFunction } = props

    const picRef = useRef<HTMLInputElement>(null)
    const [photoURL, setPhotoURL] = useState<string>(initialPicURL)
    const [showUpdateProfileButton, setShowUpdateProfileButton] = useState(false)
    const [initialPhotoURL, setinitialPhotoURL] = useState(initialPicURL)
    
    const [isPicSaving, setIsPicSaving] = useState(false)

    const handlePicChange = () => {

        const fileHandler = picRef?.current?.files![0]
            
        if (fileHandler) {
            if (fileHandler.size > 2098000) {
                displayError( IMAGE_TOO_LARGE_TITLE, IMAGE_TOO_LARGE_MESSAGE )
                return false
            }
            if (isFileImage(fileHandler)) {
                setPhotoURL( URL.createObjectURL( fileHandler ) )
                setShowUpdateProfileButton( true )
            } else {
                displayError( NOT_IMAGE_TITLE, NOT_IMAGE_MESSAGE )
            }
        }
    }

    const handleChangePicButtonClick = () => {
        picRef.current?.click()
    }

    const savePicChange = async () => {
        setIsPicSaving(true)
        const fileObject = getFileNameWithExt( picRef )
        
        if (fileObject) {
            const { file, fileExtension } = fileObject
            const { data, error } = await supabase
                                        .storage
                                        .from(bucketName)
                                        .upload(`${path}/${pictureId}.${fileExtension}`, file, {
                                            cacheControl: '0',
                                            upsert: true
                                        })

            if ( data ) {
                const logoUrl = supabase.storage.from(bucketName).getPublicUrl( `${path}/${pictureId}.${fileExtension}` ).publicURL
                const updateProfileDataObject = {
                    logoURL: logoUrl
                }
                updateUrlFunction(updateProfileDataObject.logoURL)
                setinitialPhotoURL(updateProfileDataObject.logoURL!)
                setShowUpdateProfileButton( false )
            } else if ( error ) {
                displayError( USER_ERROR_NOT_ALLOWED, error.message )
            }
        }
        setIsPicSaving(false)
    }

    return (
        <>
            <Input 
                type="file" 
                id={fieldId}
                name="file" 
                ref={picRef} 
                accept="image/png, image/jpeg, image/jpg" 
                display="none" 
                onChange={handlePicChange}
            />
            <Avatar size="xl" name={pictureName || 'Give My Certificate'} src={photoURL} />
            {
                ( showUpdateProfileButton )?
                <>
                    <ButtonGroup size="sm" isAttached >
                        <Button 
                            mr="-px"
                            leftIcon={< FaCheck />}
                            onClick={ savePicChange }
                            isLoading={isPicSaving}
                            loadingText="Saving..."
                        >
                            Save
                        </Button>
                        <Menu>
                            <MenuButton as={IconButton} aria-label="More Options" icon={< FaCaretDown />} />
                            <MenuList>
                                <MenuItem 
                                    icon={< FaEdit />} 
                                    onClick={ handleChangePicButtonClick }
                                >Change</MenuItem>
                                <MenuItem 
                                    icon={< AiOutlineClose />} 
                                    onClick={ () => { 
                                        setShowUpdateProfileButton( false ) 
                                        setPhotoURL( initialPhotoURL )
                                        //@ts-ignore
                                        document.getElementById(fieldId).value = ''
                                    }} 
                                >
                                    Cancel
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </ButtonGroup>
                </>
                :
                <Button 
                    size="sm" 
                    leftIcon={<HiPencilAlt />}
                    onClick={ handleChangePicButtonClick }
                >
                    Edit
                </Button>
            }
        </>
    )
}

export const HEADER_NAVBAR_HEIGHT = 3
export const HEADER_LOGO_HEIGHT = HEADER_NAVBAR_HEIGHT - 1

export const HEADER_LOGO_SMALL_SCREEN_RATIO = 0.6
export const HEADER_LOGO_SMALL_SCREEN_TEXT_SIZE = 'small'

export const SIDEBAR_CONFIG = {
    MOBILE_VIEW: {
        BOTTOM_NAVBAR_HEIGHT: 3
    },
    WIDE_SCREEN_VIEW:{
        SIDE_NAVBAR_WIDTH: 4
    }
}

export const SIDEBAR_PORTRAIT_TO_LANDSCAPE_RATIO = SIDEBAR_CONFIG.MOBILE_VIEW.BOTTOM_NAVBAR_HEIGHT/SIDEBAR_CONFIG.WIDE_SCREEN_VIEW.SIDE_NAVBAR_WIDTH
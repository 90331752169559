import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  ToastId,
  useBreakpointValue,
  useToast,
  InputGroup,
  InputLeftAddon,
  Icon
} from '@chakra-ui/react'
import * as React from 'react'
import { PasswordField } from '../passwordField/PasswordField'
import * as yup from 'yup'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import NotificationWrapper from '../notificationWrapper/NotificationWrapper'
import { useRef } from 'react'
import { useAuth } from '../../contexts/Auth'
import { FaUser } from 'react-icons/fa'
import { displayError } from '../../helpers/CommonFunctions'
import { redirectURLHolderOnAuth } from '../../stores/AuthStore'
import { useState } from '@hookstate/core'
import { useHistory } from 'react-router-dom'

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(8).required(),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
});

export type SignupFormInputs = {
  email: string;
  password: string;
  confirmPassword: string;
};

export const SigupForm = () => {

  const { signupByEmail } = useAuth()

  const methods = useForm<SignupFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const toast = useToast()
  const toastIdRef = useRef<ToastId>()
  const notificationPosition = useBreakpointValue({ base: "bottom", md: "top-right" }) as "bottom" || "top-right"

  const { register, handleSubmit, formState: { errors, isSubmitting } } = methods
  const redirectUrl = useState(redirectURLHolderOnAuth)
  const history = useHistory()

  const emailLoginSubmit = async (values:SignupFormInputs) => {
    const {user, error } = await signupByEmail(values)
    
    if(user){
      toastIdRef.current = toast({
        position: notificationPosition,
        duration: 5000,
        render: () => (
          <NotificationWrapper 
            heading="Confirmation mail sent" 
            text="We have sent a confirmation email on the email ID you have provided. Please Click on redirect button if already confirmed" 
            primaryActionText="Redirect"
            secondaryActionText="Close"
            notificationType="SUCCESS"
            primaryActionOnClick={() => {
              history.push( (redirectUrl.get())?redirectUrl.get():window.location.origin )
              return true;
            }}
            secondaryActionOnClick={() => {
              toast.close(toastIdRef.current as ToastId)
              return true;
            }}
          />
        ),
      })
    } else {
      displayError( '', '', true, {
        type: 'USER',
        subType: 'INVALID_AUTH',
        code: error?.status,
        error: error
      })
    }
  }

  return (
    <FormProvider {...methods} >
      <form
        onSubmit={handleSubmit(emailLoginSubmit)}
      >
        <Stack spacing="4">
          <FormControl 
            id="email"
            isInvalid={!!errors?.email}
            errortext={errors?.email?.message}
            isRequired
          >
            <FormLabel mb={1}>Email</FormLabel>
            <InputGroup>
              <InputLeftAddon 
                children={<Icon as={FaUser} />}
              />
              <Input 
                type="email" 
                autoComplete="email" 
                {...register('email')} 
                placeholder="someone@email.com"
              />
            </InputGroup>
            <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
          </FormControl>
          <FormControl
            id="password"
            isInvalid={!!errors?.password}
            errortext={errors?.password?.message}
            isRequired
          >
            <Flex align="baseline" justify="space-between">
              <FormLabel mb={1}>Password</FormLabel>
            </Flex>
            <PasswordField name="password" />
            <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
          </FormControl>
          <FormControl
            id="confirmPassword"
            isInvalid={!!errors?.confirmPassword}
            errortext={errors?.confirmPassword?.message}
            isRequired
          >
            <Flex align="baseline" justify="space-between">
              <FormLabel mb={1}>Confirm Password</FormLabel>
            </Flex>
            <PasswordField name="confirmPassword" />
            <FormErrorMessage>{errors?.confirmPassword?.message}</FormErrorMessage>
          </FormControl>
          <Button 
            type="submit" 
            colorScheme="blue" 
            size="lg" 
            fontSize="md" 
            isLoading={isSubmitting} 
            loadingText="Submitting..."
            isDisabled={Object.keys(errors).length !== 0}
          >
            Create my account
          </Button>
        </Stack>
      </form>
    </FormProvider>
  )
}

// @ts-nocheck
import React from "react";

import { useEditableControls, Box } from "@chakra-ui/react";
import { ButtonGroup, IconButton, Editable, EditablePreview, EditableInput } from "@chakra-ui/react";

import { FiCheck, FiX, FiEdit } from "react-icons/fi";
import { State } from "@hookstate/core";

const NameEditor: React.FC<State> = ({ config }) => {
	const EditableControls = () => {
		const { isEditing, getSubmitButtonProps, getCancelButtonProps, getEditButtonProps } = useEditableControls();

		return isEditing ? (
			<ButtonGroup justifyContent='center' size='sm'>
				<IconButton icon={<FiCheck />} {...getSubmitButtonProps()} />
				<IconButton icon={<FiX />} {...getCancelButtonProps()} />
			</ButtonGroup>
		) : (
			<ButtonGroup justifyContent='center' size='sm'>
				<IconButton variant='ghost' size='sm' icon={<FiEdit />} {...getEditButtonProps()} />
			</ButtonGroup>
		);
	};

	const handleUpdateName = (name) => {
		config.merge({ name });
	};

	return (
		<Editable textAlign='center' defaultValue={config.get()?.name} fontSize='2xl' isPreviewFocusable={false} onSubmit={handleUpdateName}>
			<Box display='flex' width='90%' justifyContent='space-between' m={2}>
				<EditablePreview />
				<EditableInput />
				<EditableControls />
			</Box>
		</Editable>
	);
};
export default NameEditor;

import React, { useRef, useState, useEffect, ChangeEvent } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { Persistence } from "@hookstate/persistence";
// import { Untracked } from "@hookstate/untracked";
import moment from "moment";

import { Box, Text, Button, Stack, HStack, Flex, Select, Heading, Radio, RadioGroup, useDisclosure, Collapse, Input } from "@chakra-ui/react";
import { IconButton, Skeleton, Spacer, CheckboxGroup, Checkbox, VStack, Divider } from "@chakra-ui/react";
import { StringOrNumber } from "@chakra-ui/utils";
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleDown, FaAngleLeft, FaAngleRight, FaAngleUp, FaCopy, FaEdit, FaPlus, FaRedo, FaTimes } from "react-icons/fa";
import { HiPencilAlt } from "react-icons/hi";

import { displayError, displaySuccess, recreateListWithDesiredOrder, removeKeyFromObject } from "../../helpers/CommonFunctions";

import { templatesDataObjectType } from "../../types/dataFetcherTypes/TemplatesDataFetcherTypes";
import { deleteTemplate, getTemplatesData, setTemplatesData } from "../../dataFetchers/templatesDataFetcher";
import { paginationPageSizeArray } from "../../dataObjects/globalDataObject";
import { useHookState } from "../../hooks/useHookState";
import { useAuth } from "../../contexts/Auth";

import { Card } from "../../components/card/Card";
import { drawerComponentRefFunctionType, DrawerComponentWithRef } from "../../components/drawerComponent/DrawerComponent";
import { ErrorComponent } from "../../components/errorComponent/ErrorComponent";
import ResponsiveTable from "../../components/responsiveTable/ResponsiveTable";
import { CardWithColorAccent } from "../../components/cardWithColorAccent/CardWithColorAccent";
import { ModalComponentWithRef } from "../../components/modalComponent/ModalComponent";
import CreateTemplateForm from "../../components/createTemplateForm/CreateTemplateForm";

import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE } from "../../configs/GlobalConstants";
import { Builder } from "./Builder";

function useLocationQuery() {
	return new URLSearchParams(useLocation().search);
}

export const Templates = () => {
	const locationQuery = useLocationQuery();
	const history = useHistory();

	const [pageNo, setPageNo] = useState(parseInt(locationQuery.get("page") || DEFAULT_PAGE_NO.toString()));
	const [pageSize, setPageSize, rawPageSize] = useHookState( parseInt(locationQuery.get('size') || localStorage.getItem('global-page-size') || DEFAULT_PAGE_SIZE.toString() ) )  
	
    rawPageSize.attach(Persistence('global-page-size'))
	const [isSaveTemplateLoading, setIsSaveTemplateLoading] = useState(false);
	const [serchText, setSerchText] = useState<string|null>(null)
    const [searchOnColumn, setSearchOnColumn] = useState('name')
    const { isOpen, onToggle } = useDisclosure()
    const timeoutHolderForSearch = useRef<any>(null)
	const holdNewTemplateId = useRef('')
	const newTemplateNameRef = useRef<HTMLInputElement>(null)

	const createEventDrawerRef = useRef<drawerComponentRefFunctionType>();
	const editTemplateDrawerRef = useRef<drawerComponentRefFunctionType>();

	const { user } = useAuth();

	const availableColumns = ["id", "name", "created_at", "updated_at", "controls"];

	const defaultDisplayColumns = ["name", "created_at", "controls"];

	const availableColumnsForSearch = [
        "name"
    ]

	const columnToDisplayValMapping: { [key: string]: string } = {
		id: "Template Id",
		name: "Name",
		created_at: "Created On",
		updated_at: "Updated On",
		controls: "Controls",
	};

	const [columnsToDisplay, setColumnsToDisplay, rawColumnsToDisplay] = useHookState<StringOrNumber[]>(defaultDisplayColumns);
	const [sortOnColumn, setSortOnColumn, rawSortOnColumn] = useHookState("updated_at");
	const [sortOrder, setSortOrder, rawSortOrder] = useHookState("descending");

	rawColumnsToDisplay.attach(Persistence("templates-columns-to-display"));
	rawSortOnColumn.attach(Persistence("templates-sort-on-column"));
	rawSortOrder.attach(Persistence("templates-sort-order"));

	const queryClient = useQueryClient();
	const { isError, isLoading, data, error, isFetching } = useQuery(
		["templatesData", pageNo, pageSize, sortOnColumn, sortOrder, serchText, searchOnColumn ],
		() => getTemplatesData(pageNo, pageSize, sortOnColumn, sortOrder, serchText, searchOnColumn ),
		{ keepPreviousData: true }
	);

	const createMutation = useMutation(setTemplatesData, {
		onSuccess: (data) => {
			if (!data.isQueryError) {
				queryClient.invalidateQueries("templatesData");
				displaySuccess("Template Created", "We have created your template");
				createEventDrawerRef?.current?.onClose();
			}
		},
	});

	const deleteMutation = useMutation(deleteTemplate, {
		onSuccess: (data) => {
			if (!data.isQueryError) {
				queryClient.invalidateQueries("templatesData");
				displaySuccess("Template Deleted", "Template is deleted");
			}
		},
	});

	useEffect(() => {
		const params = new URLSearchParams();
		if (pageNo) {
			params.append("page", pageNo.toString());
		} else {
			params.delete("page");
		}
		if (pageSize) {
			params.append("size", pageSize.toString());
		} else {
			params.delete("size");
		}
		if (locationQuery.get("page") || pageNo !== DEFAULT_PAGE_NO || pageSize !== DEFAULT_PAGE_SIZE) {
			history.replace({ search: params.toString() });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNo, pageSize, history]);

	const onSubmitFunction = async (value: any) => {
		const newTemplateData = await createMutation.mutateAsync(value);
		holdNewTemplateId.current = newTemplateData.queryData![0].id || ''
		return true
	}

	const copyTemplate = async (item:any) => {
		await createMutation.mutateAsync({
			...removeKeyFromObject(item, ['id', 'created_at', 'updated_at']),
			image_url: item.config.image_url,
			name: newTemplateNameRef.current?.value || item.name + ' - Copy'
		})
	}

	const createDataForTable = (data: templatesDataObjectType[]) => {
		let returnObject = {
			keys: columnsToDisplay,
			header: columnToDisplayValMapping,
			body: [] as object[],
		};

		data.map((item: templatesDataObjectType, i: number) => {
			returnObject.body.push({
				keyId: item.id,
				id: item.id,
				name: item.name,
				created_at: <Text minW='max-content'>{moment(item.created_at).local().format("MMM DD, YYYY")}</Text>,
				updated_at: <Text minW='max-content'>{moment(item.updated_at).local().format("MMM DD, YYYY")}</Text>,
				controls: (
					<HStack>
						<ModalComponentWithRef
							triggerComponent={<IconButton size='xs' variant='outline' colorScheme='blue' icon={<FaCopy />} aria-label='Copy Template' />}
							heading='Copy Template'
							showFooter={true}
							footerButtons={
								<Button
									colorScheme='blue'
									onClick={async () => {
										await copyTemplate(item)
									}}>
									Copy
								</Button>
							}
						>
							<Input ref={newTemplateNameRef} type='text' placeholder="Template Name" defaultValue={item.name + ' - Copy'} />
						</ModalComponentWithRef>

						<IconButton
							size='xs'
							variant='outline'
							colorScheme='teal'
							icon={<FaEdit />}
							aria-label='Edit Template'
							onClick={() => {
								history.push(`/dashboard/templates/edit/${data[i].id}`);
							}}
						/>

						<ModalComponentWithRef
							triggerComponent={<IconButton size='xs' variant='outline' colorScheme='red' icon={<FaTimes />} aria-label='Delete Template' />}
							heading='Delete'
							showFooter={true}
							footerButtons={
								// planType === "free" ? (
								// 	<Button colorScheme='blue'>View Plans</Button>
								// ) : (
								<Button
									colorScheme='blue'
									onClick={async () => {
										return await deleteMutation.mutateAsync({
											id: item.id!,
											user_id: user?.id!,
										});
									}}>
									Confirm
								</Button>
								// )
							}>
							{/* {planType === "free" ? (
								<Text>Sorry this feature is only available in premium plan, Please upgrade</Text>
							) : ( */}
							<Text>
								Please be informed that this action is irreversible, You won't be able to recover it once deleted. However, Don't worry as all the
								certificates issued in the past using this event will retain it's value.
							</Text>
							{/* )} */}
						</ModalComponentWithRef>
					</HStack>
				),
			});
			return true;
		});

		return returnObject;
	};

	const setDebouncedSerchText = (eve:ChangeEvent<HTMLInputElement>) => {
        const searchFieldValue = eve.currentTarget.value
        if (timeoutHolderForSearch.current !== null ) {
            clearTimeout(timeoutHolderForSearch.current)
        }
        timeoutHolderForSearch.current = setTimeout(() => {
            setSerchText(searchFieldValue)
        }, 300)
    }

	if (data) {
		const { queryData, queryError, querySize } = data;

		if (queryError) {
			displayError("Action not allowed", queryError.message || queryError.details || queryError.hint);
		}

		if (queryData) {
			const lastPage = (Math.ceil(querySize!/pageSize) > 0) ? Math.ceil(querySize!/pageSize) : 1
			return (
				<>
					{/* {querySize! < maxOrganizationsAllowed ? ( */}
					<DrawerComponentWithRef
						triggerComponent={
							<IconButton
								size='lg'
								fontSize='lg'
								colorScheme='blue'
								ml='auto'
								borderRadius='50%'
								m={2}
								icon={<FaPlus />}
								aria-label='Create new Template'
								position='fixed'
								right={{
									base: 2,
									md: 10,
								}}
							/>
						}
						heading='Create new Template'
						size='lg'
						showFooter={true}
						footerButtons={
							<Button colorScheme='blue' type='submit' form='createNewTemplateForm' isLoading={isSaveTemplateLoading} loadingText='Processing'>
								Save
							</Button>
						}
						ref={createEventDrawerRef}>
						<CreateTemplateForm
							formId='createNewTemplateForm'
							loadingStatus={setIsSaveTemplateLoading}
							actionOnSubmitSuccess={() => {
								if ( holdNewTemplateId.current && holdNewTemplateId.current !== '' ) {
									history.push(`/dashboard/templates/edit/${holdNewTemplateId.current}`);
								}
							}}
							onSubmitFunction={onSubmitFunction}
						/>
					</DrawerComponentWithRef>
					{/* ) : (
						<>
							<IconButton
								size='lg'
								fontSize='lg'
								colorScheme='yellow'
								ml='auto'
								onClick={onOpen}
								borderRadius='50%'
								m={2}
								icon={<FaStar />}
								aria-label='Create new Template'
								position='fixed'
								right={{
									base: 2,
									md: 10,
								}}
							/>
							<Modal isOpen={isOpen} onClose={onClose}>
								<ModalOverlay />
								<ModalContent>
									<ModalHeader>Quota Exceed</ModalHeader>
									<ModalCloseButton />
									<ModalBody>Quota for your current plan exceed. Please upgrade your plan</ModalBody>

									<ModalFooter>
										<Button variant='ghost' mr={3} onClick={onClose}>
											Close
										</Button>
										<Button colorScheme='blue'>View Plans</Button>
									</ModalFooter>
								</ModalContent>
							</Modal>
						</>
					)} */}
					<Box h={8} />
					<Card>
						<Flex
                            borderBottomWidth={1}
                            direction='column'
                            alignItems='flex-start'
                        >
                            <Flex alignItems="center" w='100%' mb={4}>
								<Heading as='h2' fontSize='lg'>
									Templates
								</Heading>
								<Spacer />
								<Button
									leftIcon={<FaRedo />}
									mr={3}
									variant='outline'
									onClick={() => {
										queryClient.invalidateQueries(["templatesData", pageNo, pageSize, sortOnColumn, sortOrder]);
									}}
									display={["none", "inherit", null, null]}
									isLoading={isFetching}>
									Refresh
								</Button>
								<IconButton
									aria-label='Refresh Templates Data'
									onClick={() => {
										queryClient.invalidateQueries(["templatesData", pageNo, pageSize, sortOnColumn, sortOrder]);
									}}
									icon={<FaRedo />}
									mr={3}
									variant='outline'
									display={["inherit", "none", null, null]}
									isLoading={isFetching}
								/>
								<Button
                                    aria-label='Open Advance Options'
                                    rightIcon={isOpen ? <FaAngleUp /> : <FaAngleDown /> }
                                    leftIcon={<FaEdit />}
                                    onClick={onToggle}
                                    variant='outline'
                                >
                                    Advance
                                </Button>
							</Flex>
                            <Collapse 
                                style={{
                                    width: '100%',
                                }} 
                                in={isOpen} 
                                animateOpacity
                            >
								<Flex w='100%' mb={4} direction={['column', null, 'row', null]} >
                                    <Flex alignItems='center'>
                                        <Text pr='2'>
                                            Filter:
                                        </Text>
                                        <Input
                                            type='search'
                                            mr={3}
                                            placeholder={columnToDisplayValMapping[searchOnColumn]}
                                            onChange={setDebouncedSerchText}
                                        />
                                        <Select
                                            defaultValue={searchOnColumn}
                                            onChange={(val) => {
                                                setSearchOnColumn(val.target.value)
                                            }}
                                        >
                                            {
                                                availableColumnsForSearch.map((columnKey) => (
                                                    <option key={columnKey} value={columnKey}> { columnToDisplayValMapping[columnKey] } </option>
                                                ))
                                            }
                                        </Select>
                                    </Flex>
                                    <Spacer />
                                    <Flex pt={['2', null, '0', null]}>
                                        <Spacer />   
										<DrawerComponentWithRef
											heading='Table Properties'
											triggerComponent={
												<Button variant='outline' minW='20' leftIcon={<HiPencilAlt />} >
													Edit
												</Button>
											}
											showFooter={true}>
											<CardWithColorAccent>
												<Heading size='sm' mb='4'>
													Columns To display
												</Heading>
												<Divider mb='6' />
												<CheckboxGroup
													colorScheme='blue'
													defaultValue={columnsToDisplay}
													onChange={(newVal) => setColumnsToDisplay(recreateListWithDesiredOrder(availableColumns, newVal))}>
													<VStack alignItems='flex-start'>
														{availableColumns.map((columnKey) => (
															<Checkbox key={columnKey} value={columnKey}>
																{columnToDisplayValMapping[columnKey]}
															</Checkbox>
														))}
													</VStack>
												</CheckboxGroup>
											</CardWithColorAccent>

											<CardWithColorAccent mt={8}>
												<Heading size='sm' mb='4'>
													Sort by
												</Heading>
												<Divider mb='6' />
												<RadioGroup onChange={setSortOnColumn} value={sortOnColumn}>
													<VStack alignItems='flex-start'>
														{availableColumns
															.filter((i) => !["controls"].includes(i))
															.map((columnKey) => (
																<Radio key={columnKey} value={columnKey}>
																	{" "}
																	{columnToDisplayValMapping[columnKey]}{" "}
																</Radio>
															))}
													</VStack>
												</RadioGroup>
											</CardWithColorAccent>

											<CardWithColorAccent mt={8}>
												<Heading size='sm' mb='4'>
													Sort Order
												</Heading>
												<Divider mb='6' />
												<RadioGroup onChange={setSortOrder} value={sortOrder}>
													<VStack alignItems='flex-start'>
														<Radio value='ascending'> Ascending </Radio>
														<Radio value='descending'> Descending </Radio>
													</VStack>
												</RadioGroup>
											</CardWithColorAccent>
										</DrawerComponentWithRef>
									</Flex>
								</Flex>
							</Collapse>
						</Flex>
						<ResponsiveTable data={createDataForTable(queryData)} />
						<Flex alignItems='center' mt={3} borderTopWidth={1} pt={4}>
							<Text>
								{`Showing ${(pageNo - 1) * pageSize + 1} - ${querySize! < pageNo * pageSize ? querySize : pageNo * pageSize} of ${querySize}`}
							</Text>
							<Spacer />
							<IconButton
								aria-label='First Page'
								icon={<FaAngleDoubleLeft />}
								size='xs'
								variant='ghost'
								isDisabled={pageNo === 1}
								onClick={() => {
									setPageNo(1);
								}}
							/>
							<IconButton
								aria-label='Previous Page'
								icon={<FaAngleLeft />}
								size='xs'
								variant='ghost'
								isDisabled={pageNo === 1}
								onClick={() => {
									if (pageNo > 1) {
										setPageNo((p) => p - 1);
									}
								}}
							/>
							<IconButton
								aria-label='Next Page'
								icon={<FaAngleRight />}
								size='xs'
								variant='ghost'
								isDisabled={pageNo === lastPage}
								onClick={() => {
									if (pageNo < lastPage) {
										setPageNo((p) => p + 1);
									}
								}}
							/>
							<IconButton
								aria-label='Last Page'
								icon={<FaAngleDoubleRight />}
								size='xs'
								variant='ghost'
								isDisabled={pageNo === lastPage}
								onClick={() => {
									setPageNo(lastPage);
								}}
							/>
							<Select
								w='auto'
								defaultValue={pageSize}
								onChange={(val) => {
									const changedPageSize = parseInt(val.target.value);
									setPageSize(changedPageSize);
									if (changedPageSize * pageNo > querySize!) {
										const lastPage = (Math.ceil(querySize!/changedPageSize) > 0) ? Math.ceil(querySize!/changedPageSize) : 1
										setPageNo(lastPage);
									}
								}}>
								{paginationPageSizeArray.map((size) => (
									<option key={size} value={size}>
										{" "}
										{size}{" "}
									</option>
								))}
							</Select>
						</Flex>
					</Card>

					{/*******************************************************************
					 * ******************************************************************
					 *       Below Code can be pushed inside a separate function        *
					 *       leaving it for now as the drawer is closing due to         *
					 *       re-rendering of component                                  *
					 * ******************************************************************
					 ********************************************************************/}
					<DrawerComponentWithRef
						size='full'
						heading='Edit Template'
						showFooter={true}
						triggerComponent={<Button display='none'>Edit</Button>}
						footerButtons={
							<Button colorScheme='blue' type='submit' form='createNewTemplateForm' isLoading={isSaveTemplateLoading} loadingText='Processing'>
								Update
							</Button>
						}
						ref={editTemplateDrawerRef}>
						{/* @ts-ignore */}
						<Builder />
					</DrawerComponentWithRef>
				</>
			);
		}
	}

	if (isError) {
		return (
			<ErrorComponent
				//@ts-ignore
				errorMessage={error.message}
			/>
		);
	}

	if (isLoading) {
		return (
			<Stack mt={10} spacing={5}>
				<Skeleton height='20px' />
				<Skeleton height='20px' />
				<Skeleton height='20px' />
			</Stack>
		);
	}

	if (!data) {
		return (
			<ErrorComponent
				//@ts-ignore
				errorMessage={error.message}
			/>
		);
	}

	return <ErrorComponent />;
};

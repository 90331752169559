import { Box, Text, Button, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Skeleton, Stack, useDisclosure, HStack, Flex, Spacer, Select, CheckboxGroup, Checkbox, VStack, Heading, Divider, RadioGroup, Radio, Avatar } from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight, FaEdit, FaEye, FaPlus, FaRedo, FaStar, FaTimes } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { Card } from '../../components/card/Card'
import { drawerComponentRefFunctionType, DrawerComponentWithRef } from '../../components/drawerComponent/DrawerComponent'
import { ErrorComponent } from '../../components/errorComponent/ErrorComponent'
import { OrganizationForm } from '../../components/organizationForm/OrganizationForm'
import ResponsiveTable from '../../components/responsiveTable/ResponsiveTable'
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE, DEFAULT_SORT_ORDER } from '../../configs/GlobalConstants'
import { deleteOrganization, getOrganisationData, setOrganizationData, updateOrganization } from '../../dataFetchers/organisationDataFetcher'
import { paginationPageSizeArray } from '../../dataObjects/globalDataObject'
import { displayError, displaySuccess, recreateListWithDesiredOrder } from '../../helpers/CommonFunctions'
import { useConfig } from '../../hooks/useConfig'
import { organisationDataObjectType } from '../../types/dataFetcherTypes/DataFetcherTypes'
import { StringOrNumber } from "@chakra-ui/utils"
import { HiPencilAlt } from 'react-icons/hi'
import { CardWithColorAccent } from '../../components/cardWithColorAccent/CardWithColorAccent'
import { Persistence } from '@hookstate/persistence'
import { Untracked } from '@hookstate/untracked';
import { useHookState } from '../../hooks/useHookState'
import ShowOrganization from '../../components/showOrganization/ShowOrganization'
import { ModalComponentWithRef } from '../../components/modalComponent/ModalComponent'
import { useAuth } from '../../contexts/Auth'

function useLocationQuery() {
    return new URLSearchParams(useLocation().search);
}

export const Organisations = () => {

    const locationQuery = useLocationQuery()
    const history = useHistory()

    const [pageNo, setPageNo] = useState( parseInt(locationQuery.get('page') || DEFAULT_PAGE_NO.toString() ) )
    const [pageSize, setPageSize, rawPageSize] = useHookState( parseInt(locationQuery.get('size') || localStorage.getItem('global-page-size') || DEFAULT_PAGE_SIZE.toString() ) )  
    rawPageSize.attach(Persistence('global-page-size'))
    const [isSaveOrganizationLoading, setIsSaveOrganizationLoading] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()

    const createOrganizationDwawerRef = useRef<drawerComponentRefFunctionType>()
    const editOrganizationDwawerRef = useRef<drawerComponentRefFunctionType>()

    const { maxOrganizationsAllowed, planType } = useConfig()
    const { user } = useAuth()    

    const availableColumns = [
        "organization_id",
        "name",
        "type",
        "about",
        "establishment_date",
        "size",
        "logo_url",
        "address",
        "mobile",
        "email",
        "website",
        "linkedin",
        "facebook",
        "twitter",
        "instagram",
        "reply_to_email",
        "created_at",
        "updated_at",
        "controls",
    ]

    const defaultDisplayColumns = [
        "name",
        "type",
        "mobile",
        "email",
        "website",
        "controls",
    ]

    const columnToDisplayValMapping:{ [key: string]: string } = {
        organization_id: "Id",
        name: "Name",
        type: "Type",
        about: "About",
        establishment_date: "Establishment Date",
        size: "Size",
        logo_url: "Logo",
        address: "Address",
        mobile: "Mobile",
        email: "Email",
        website: "Website",
        linkedin: "Linkedin Id",
        facebook: "Facebook Id",
        twitter: "Twitter Id",
        instagram: "Instagram Id",
        reply_to_email: "Reply To Email",
        created_at: "Created On",
        updated_at: "Updated On",
        controls: "Controls",
    }

    const [columnsToDisplay, setColumnsToDisplay, rawColumnsToDisplay]  = useHookState<StringOrNumber[]>(defaultDisplayColumns)
    const [sortOnColumn, setSortOnColumn, rawSortOnColumn] = useHookState('updated_at')
    const [sortOrder, setSortOrder, rawSortOrder] = useHookState(DEFAULT_SORT_ORDER)
    const [itemToUpdate, setItemToUpdate, rawItemToUpdate] = useHookState<organisationDataObjectType>({})

    rawColumnsToDisplay.attach(Persistence('organization-columns-to-display'))
    rawSortOnColumn.attach(Persistence('organization-sort-on-column'))
    rawSortOrder.attach(Persistence('organization-sort-order'))
    rawItemToUpdate.attach(Untracked)
    
    const queryClient = useQueryClient()
    const { isError, isLoading, data, error, isFetching } = useQuery(['organizationData', pageNo, pageSize, sortOnColumn, sortOrder], () => getOrganisationData( pageNo, pageSize, sortOnColumn, sortOrder), { keepPreviousData : true })
    const mutation = useMutation(setOrganizationData, {
        onSuccess: data => {
            if ( !data.isQueryError ) {
                queryClient.invalidateQueries('organizationData')
                displaySuccess('Organization Created', 'We have created your organization')
            }
        }
    })

    const deleteMutation = useMutation(deleteOrganization, {
        onSuccess: data => {
            if ( !data.isQueryError ) {
                queryClient.invalidateQueries('organizationData')
                displaySuccess('Organization Deleted', 'Organization is deleted')
            }
        }
    })

    const updateMutation = useMutation(updateOrganization, {
        onSuccess: data => {
            if ( !data.isQueryError ) {
                queryClient.invalidateQueries('organizationData')
                displaySuccess('Organization Updated', 'Organization is updated successfully')
            }
        }
    })

    useEffect( () => {
        const params = new URLSearchParams()
        if (pageNo) {
            params.append("page", pageNo.toString() )
        } else {
            params.delete("page")
        }
        if (pageSize) {
            params.append("size", pageSize.toString() )
        } else {
            params.delete("size")
        }
        if ( locationQuery.get('page') || (pageNo !== DEFAULT_PAGE_NO) || (pageSize !== DEFAULT_PAGE_SIZE) ) {
            history.replace({search: params.toString()})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ pageNo, pageSize, history ])

    const onSubmitFunction = async ( value:any ) => {
        return await mutation.mutateAsync(value)
    }

    const createDataForTable = (data:organisationDataObjectType[]) => {
        let returnObject = {
            keys: columnsToDisplay,
            header: columnToDisplayValMapping,
            body: [] as object[]
        }
        
        data.map( (item:organisationDataObjectType) => {
            returnObject.body.push({
                keyId: item.organization_id,
                organization_id: item.organization_id,
                name: item.name,
                type: item.type,
                about: item.about,
                establishment_date: <Text minW="max-content">
                                        { moment(item.establishment_date).format('MMM DD, YYYY') }
                                    </Text>,
                size: item.size,
                logo_url: <Avatar size="md" name={item.name || 'Give My Certificate'} src={item.logo_url} />,
                address: <Stack>
                        <Text>
                            {`${item.address?.address_line_one}, ${item.address?.address_line_two}`}
                        </Text>
                        <Text>
                            {`${item.address?.city}, ${item.address?.state}, ${item.address?.country} ${item.address?.pin_code}`}
                        </Text>
                    </Stack>,
                mobile: item.mobile,
                email: item.email,
                website: item.website,
                linkedin: item.linkedin,
                facebook: item.facebook,
                twitter: item.twitter,
                instagram: item.instagram,
                reply_to_email: item.reply_to_email,
                created_at: <Stack>
                        <Text minW="max-content">
                            { moment(item.created_at).format('MMM DD, YYYY HH:mm:ss') }
                        </Text>
                    </Stack>,
                updated_at: <Stack>
                        <Text minW="max-content">
                            { moment(item.updated_at).format('MMM DD, YYYY HH:mm:ss') }
                        </Text>
                    </Stack>,
                controls: <HStack>
                                <DrawerComponentWithRef
                                    size="lg"
                                    triggerComponent={
                                        <IconButton
                                            size="xs"
                                            variant="outline"
                                            colorScheme="teal"
                                            icon={<FaEye />}
                                            aria-label="Show Preview"
                                        />
                                    }
                                    heading="Details"
                                >
                                    <ShowOrganization 
                                        displayOrder={availableColumns}
                                        keysAndDisplayNameMapping={columnToDisplayValMapping}
                                        displayObject={item}
                                    />
                                </DrawerComponentWithRef>

                                <IconButton
                                    size="xs"
                                    variant="outline"
                                    colorScheme="teal"
                                    icon={<FaEdit />}
                                    aria-label="Edit Organization"
                                    onClick={ () => {
                                        setItemToUpdate(item)
                                        editOrganizationDwawerRef.current?.onOpen()
                                    }}
                                />
                                
                                <ModalComponentWithRef 
                                    triggerComponent={
                                        <IconButton
                                            size="xs"
                                            variant="outline"
                                            colorScheme="red"
                                            icon={<FaTimes />}
                                            aria-label="Delete Organization"
                                        />
                                    }
                                    heading="Delete"
                                    showFooter={true}
                                    footerButtons={
                                        (planType === 'free')?
                                        <Button colorScheme="blue">View Plans</Button>
                                        :
                                        <Button 
                                            colorScheme="blue"
                                            onClick={ async () => {
                                                return await deleteMutation.mutateAsync({
                                                    id: item.organization_id!,
                                                    user_id: user?.id!
                                                })
                                            }}
                                        >
                                            Confirm
                                        </Button>
                                    }
                                >
                                    {
                                        (planType === 'free')?
                                        <Text>
                                            Sorry this feature is only available in premium plan, Please upgrade
                                        </Text>
                                        :
                                        <Text>
                                            Please be informed that this action is irreversible, You won't be able to recover it once deleted. However, Don't worry as all the certificates issued in the past using this organization will retain it's value. 
                                        </Text>
                                    }
                                </ModalComponentWithRef>
                    </HStack>
            })
            return true
        })

        return returnObject
    }

    if ( data ) {
        const { queryData, queryError, querySize } = data

        if ( queryError ) {
            displayError( 'Action not allowed', queryError.message || queryError.details || queryError.hint)
        }

        if ( queryData ) {
            const lastPage = (Math.ceil(querySize!/pageSize) > 0) ? Math.ceil(querySize!/pageSize) : 1
            return (
                <>
                    {
                        (querySize! < maxOrganizationsAllowed ) ?
                            <DrawerComponentWithRef
                                triggerComponent={
                                    <IconButton
                                        size="lg"
                                        fontSize="lg"
                                        colorScheme="blue"
                                        ml="auto"
                                        borderRadius="50%"
                                        m={2}
                                        icon={<FaPlus />}
                                        aria-label="Create new Organisation"
                                        position="fixed"
                                        right={{
                                            base: 2,
                                            md: 10
                                        }}
                                    />
                                }
                                heading="Create new Organisation"
                                size="lg"
                                showFooter={ true }
                                footerButtons={
                                    <Button 
                                        colorScheme="blue"
                                        type="submit"
                                        form="createNewOrganizationForm"
                                        isLoading={ isSaveOrganizationLoading }
                                        loadingText="Processing"
                                    >
                                        Save
                                    </Button>
                                }
                                ref={createOrganizationDwawerRef}
                            >
                                <OrganizationForm 
                                    formId="createNewOrganizationForm"
                                    loadingStatus={setIsSaveOrganizationLoading}
                                    actionOnSubmitSuccess={ () => {
                                        createOrganizationDwawerRef?.current?.onClose()
                                    }}
                                    onSubmitFunction={onSubmitFunction}
                                />
                            </DrawerComponentWithRef>
                        :
                            <>
                                <IconButton
                                    size="lg"
                                    fontSize="lg"
                                    colorScheme="yellow"
                                    ml="auto"
                                    onClick={onOpen}
                                    borderRadius="50%"
                                    m={2}
                                    icon={<FaStar />}
                                    aria-label="Create new Organisation"
                                    position="fixed"
                                    right={{
                                        base: 2,
                                        md: 10
                                    }}
                                />
                                <Modal isOpen={isOpen} onClose={onClose}>
                                    <ModalOverlay />
                                    <ModalContent>
                                        <ModalHeader>Quota Exceed</ModalHeader>
                                        <ModalCloseButton />
                                        <ModalBody>
                                            Quota for your current plan exceed. Please upgrade your plan
                                        </ModalBody>

                                        <ModalFooter>
                                            <Button  variant="ghost" mr={3} onClick={onClose}>
                                                Close
                                            </Button>
                                            <Button colorScheme="blue">View Plans</Button>
                                        </ModalFooter>
                                    </ModalContent>
                                </Modal>
                            </>
                    }
                    <Box h={8} />
                    <Card>
                        <Flex borderBottomWidth="1px" alignItems="center">
                            <Heading as="h2" fontSize="lg">
                                Organizations
                            </Heading>
                            <Spacer />
                            <Button
                                leftIcon={<FaRedo />}
                                mr={3}
                                variant="outline"
                                onClick={ () => {
                                    queryClient.invalidateQueries(['organizationData', pageNo, pageSize, sortOnColumn, sortOrder])
                                }}
                                display={["none", "inherit", null, null]}
                                isLoading={isFetching}
                            >
                                Refresh
                            </Button>
                            <IconButton 
                                aria-label="Refresh Organization Data"
                                onClick={ () => {
                                    queryClient.invalidateQueries(['organizationData', pageNo, pageSize, sortOnColumn, sortOrder])
                                }}
                                icon={<FaRedo />}
                                mr={3}
                                variant="outline"
                                display={["inherit", "none", null, null]}
                                isLoading={isFetching}
                            />
                            <DrawerComponentWithRef
                                heading="Table Properties"
                                triggerComponent={
                                    <Button variant="outline" minW="20" leftIcon={<HiPencilAlt />} my={4} >
                                        Edit
                                    </Button>
                                }
                                showFooter={true}
                            >
                                <CardWithColorAccent>
                                    <Heading size="sm" mb="4">
                                        Columns To display
                                    </Heading>
                                    <Divider mb="6" />
                                    <CheckboxGroup 
                                        colorScheme="blue"
                                        defaultValue={columnsToDisplay}
                                        onChange={ newVal => setColumnsToDisplay(recreateListWithDesiredOrder(availableColumns, newVal)) }
                                    >
                                        <VStack alignItems="flex-start">
                                            {
                                                availableColumns.map((columnKey) => (
                                                    <Checkbox key={columnKey} value={columnKey}>{ columnToDisplayValMapping[columnKey] }</Checkbox>
                                                ))
                                            }
                                        </VStack>
                                    </CheckboxGroup>
                                </CardWithColorAccent> 

                                <CardWithColorAccent mt={8}>
                                    <Heading size="sm" mb="4">
                                        Sort by
                                    </Heading>
                                    <Divider mb="6" />
                                    <RadioGroup onChange={setSortOnColumn} value={sortOnColumn}>
                                        <VStack alignItems="flex-start">
                                            {
                                                availableColumns.filter( i => !['controls'].includes(i) ).map((columnKey) => (
                                                    <Radio key={columnKey} value={columnKey}> { columnToDisplayValMapping[columnKey] } </Radio>
                                                ))
                                            }
                                        </VStack>
                                    </RadioGroup>
                                </CardWithColorAccent> 

                                <CardWithColorAccent mt={8}>
                                    <Heading size="sm" mb="4">
                                        Sort Order
                                    </Heading>
                                    <Divider mb="6" />
                                    <RadioGroup onChange={setSortOrder} value={sortOrder}>
                                        <VStack alignItems="flex-start">
                                            <Radio value='ascending'> Ascending </Radio>
                                            <Radio value='descending'> Descending </Radio>
                                        </VStack>
                                    </RadioGroup>
                                </CardWithColorAccent>                              
                            </DrawerComponentWithRef>
                        </Flex>
                        <ResponsiveTable data={createDataForTable(queryData)} />
                        <Flex alignItems="center" mt={3} borderTopWidth={1} pt={4} >
                            <Text>
                                {`Showing ${((pageNo-1)*pageSize)+1} - ${ ( querySize! < pageNo*pageSize ) ? querySize : pageNo*pageSize } of ${querySize}`}
                            </Text>
                            <Spacer />
                            <IconButton
                                aria-label="First Page"
                                icon={ <FaAngleDoubleLeft /> }
                                size="xs"
                                variant='ghost'
                                isDisabled={ ( pageNo === 1 ) }
                                onClick={() => {
                                    setPageNo(1)
                                }}
                            />
                            <IconButton
                                aria-label="Previous Page"
                                icon={ <FaAngleLeft /> }
                                size="xs"
                                variant='ghost'
                                isDisabled={ ( pageNo === 1 ) }
                                onClick={() => {
                                    if ( pageNo > 1 ) {
                                        setPageNo( p => p - 1 )
                                    }
                                }}
                            />
                            <IconButton
                                aria-label="Next Page"
                                icon={ <FaAngleRight /> }
                                size="xs"
                                variant='ghost'
                                isDisabled={ ( pageNo === lastPage ) }
                                onClick={() => {
                                    if ( pageNo < lastPage ) {
                                        setPageNo( p => p + 1 )
                                    }
                                }}
                            />
                            <IconButton
                                aria-label="Last Page"
                                icon={ <FaAngleDoubleRight /> }
                                size="xs"
                                variant='ghost'
                                isDisabled={ ( pageNo === lastPage ) }
                                onClick={() => {
                                    setPageNo( lastPage )
                                }}
                            />
                            <Select 
                                w="auto" 
                                defaultValue={pageSize} 
                                onChange={(val) => {
                                    const changedPageSize = parseInt( val.target.value )
                                    setPageSize(changedPageSize)
                                    if ( changedPageSize*pageNo > querySize! ) {
                                        const lastPage = (Math.ceil(querySize!/changedPageSize) > 0) ? Math.ceil(querySize!/changedPageSize) : 1
                                        setPageNo( lastPage )
                                    }
                                }}
                            >
                                {
                                    paginationPageSizeArray.map( size => (
                                        <option key={size} value={size}> { size } </option>
                                    ))
                                }
                            </Select>
                        </Flex>
                    </Card>

                    {/*******************************************************************
                     * ******************************************************************
                     *       Below Code can be pushed inside a seperate function        *
                     *       leaving it for now as the drawer is closing due to         *
                     *       re-rendering of conponent                                  *
                     * ******************************************************************
                     ********************************************************************/}
                    <DrawerComponentWithRef
                        size="lg"
                        heading="Edit Organization"
                        showFooter={ true }
                        triggerComponent={
                            <Button display="none" >
                                Edit
                            </Button>
                        }
                        footerButtons={
                            <Button 
                                colorScheme="blue"
                                type="submit"
                                form="createNewOrganizationForm"
                                isLoading={ isSaveOrganizationLoading }
                                loadingText="Processing"
                            >
                                Update
                            </Button>
                        }
                        ref={editOrganizationDwawerRef}
                    >
                        <OrganizationForm 
                            formId="createNewOrganizationForm"
                            loadingStatus={setIsSaveOrganizationLoading}
                            actionOnSubmitSuccess={ () => {
                                editOrganizationDwawerRef?.current?.onClose()
                            }}
                            onSubmitFunction={ async (value) => {
                                return await updateMutation.mutateAsync({
                                    ...value,
                                    organization_id: itemToUpdate.organization_id!,
                                    user_id: user?.id!
                                })
                            }}
                            initialData={{
                                ...itemToUpdate,
                                ...itemToUpdate.address,
                                establishment_date: moment(itemToUpdate.establishment_date).format('YYYY-MM-DD')
                            }}
                        />
                    </DrawerComponentWithRef>
                </>
            )
        }
    }

    if ( isError ) {
        return (
            <ErrorComponent
                //@ts-ignore
                errorMessage={error.message}
            />
        )
    }

    if ( isLoading ) {
        return (
            <Stack mt={10} spacing={5} >
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
            </Stack>
        )
    }

    if ( !data ) {
        return (
            <ErrorComponent
                //@ts-ignore
                errorMessage={error.message}
            />
        )
    }

    return (
        <ErrorComponent />
    )
}

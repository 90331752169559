import { ButtonGroup, ButtonGroupProps, useRadioGroup } from '@chakra-ui/react'
import * as React from 'react'
import { ToggleButtonProps } from '../toggleButton/ToggleButton'

interface ToggleButtonGroupProps<T> extends Omit<ButtonGroupProps, 'onChange'> {
  name?: string
  value: T
  defaultValue?: string
  onChange?: (value: T) => void
}

export const ToggleButtonGroup = <T extends string>(props: ToggleButtonGroupProps<T>) => {
  const { children, name, defaultValue, value, onChange, ...rest } = props
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    value,
    onChange,
  })

  const buttons = React.useMemo(
    () =>
      React.Children.toArray(children)
        .filter<React.ReactElement<ToggleButtonProps>>(React.isValidElement)
        .map((button, index, array) => {
          const isFirstItem = index === 0
          const isLastItem = array.length === index + 1

          const styleProps = Object.assign({
            ...(isFirstItem && !isLastItem ? { borderRightRadius: 0 } : {}),
            ...(!isFirstItem && isLastItem ? { borderLeftRadius: 0 } : {}),
            ...(!isFirstItem && !isLastItem ? { borderRadius: 0 } : {}),
            ...(!isLastItem ? { mr: '-px' } : {}),
          })

          return React.cloneElement(button, {
            ...styleProps,
            radioProps: getRadioProps({
              value: button.props.value,
              disabled: props.isDisabled || button.props.isDisabled,
              enterKeyHint: button.props['aria-label']
            }),
          })
        }),
    [children, getRadioProps, props.isDisabled],
  )
  return <ButtonGroup {...getRootProps(rest)}>{buttons}</ButtonGroup>
}

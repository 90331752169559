import React, { useRef, useState } from "react";
import { State } from "@hookstate/core";
import { useColorModeValue, Box, Icon, Tooltip, Divider, Button, Text, Input, Stack, HStack, Menu, MenuButton, MenuList, MenuItem, MenuDivider, Skeleton } from "@chakra-ui/react";
import { Modal, ModalContent, ModalOverlay, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/react";
import { FiPlus, FiSave, FiMaximize2, FiX, FiArrowLeft, FiLoader, FiFileText } from "react-icons/fi";

import { ElementsType } from "./DrawingSheet";
import { Config } from "./ArtBoard";

import styles from "../../styles/components/builder.module.css";
import { IconType } from "react-icons/lib";
import { TextProps } from "../elements/TextElement";
import { createElementId, displaySuccess, displayError } from "../../helpers/CommonFunctions";
import { useAuth } from "../../contexts/Auth";
import supabase from "../../configs/Supabase";
import { useRouteMatch, useHistory } from "react-router-dom";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { VscVerified } from "react-icons/vsc";
import { DEFAULT_TEMPLATE_INITIAL_DATA } from "../../configs/GlobalConstants";
import { useConfig } from "../../hooks/useConfig";
import { FaIdBadge, FaImage, FaQrcode } from "react-icons/fa";
import { ImageProps } from "../elements/ImageElement";

export interface ToolBoxItemObjectType {
	name?: string;
	divider?: boolean;
	icon?: IconType;
	type?: string;
	children?: ToolBoxItemObjectType[];
	onClickFunction?: () => void;
}

export interface ToolBoxDataType {
	data: Array<ToolBoxItemObjectType>;
}

interface Props {
	elements: State<ElementsType>;
	selectedId: State<string | null>;
	config: State<Config | null>;
}

const ToolBox: React.FC<Props> = ({ elements, selectedId, config }) => {
	const colorMode = useColorModeValue("white", "gray.700");
	const [isPhModalOpen, setIsPhModalOpen] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [headerText, setHeaderText] = useState("");

	const whichElementToAdd = useRef('text')

	const { user } = useAuth();
	const { params } = useRouteMatch();
	const history = useHistory();

	const { templateInitialElements, isLoading:isConfigLoading } = useConfig()

	const handleAddText = () => {
		let id = createElementId();
		elements.merge({
			[id]: { type: "text", header: `${headerText}`, data: { fill: "black", fontSize: 20, text: "Double click to edit" } as TextProps },
		});
		selectedId.set(id);
	}

	const handleAddCertificateId = () => {
		elements.merge({
			'certificateId': { type: "text", header: "Certificate Id", data: { fill: "black", fontSize: 20, text: "17186a2e-aa3a-4231-ae6f-ef2553977554" } as TextProps },
		});
		selectedId.set('certificateId');
	}

	const handleFullScreen = () => {
		if (!document.fullscreen) document.body.requestFullscreen();
		else document.exitFullscreen();
	};

	const handleSaveTemplate = async () => {
		if ("id" in params) {
			//@ts-ignore
			let { id } = params;
			let templateDataObject = {
				user_id: user?.id,
				id,
				name: config.get()?.name,
				image_url: config.get()?.image_url,
				config: config.get(),
				elements: elements.get(),
			};

			try {
				setIsSaving(true);
				const { error } = await supabase.from("templates").upsert(templateDataObject, { onConflict: "id" });
				if (!error) {
					displaySuccess("Template Updated", "Template is updated successfully");
				} else {
					displayError("Uh oh!", "There was some error updating the template.");
				}
			} finally {
				setIsSaving(false);
			}
		}
	};

	const handleClose = () => {
		history.replace("/dashboard/templates");
	};

	const handleSaveNClose = async () => {
		try {
			await handleSaveTemplate();
			handleClose();
		} catch (error) {
			console.log(error);
		}
	};

	const handlePhModalOpen = () => {
		setIsPhModalOpen(true);
	};

	const onPhModalClose = () => {
		setIsPhModalOpen(false);
	};

	const handleAddQr = () => {
		elements.merge({
			'gmc_qr': {
				...DEFAULT_TEMPLATE_INITIAL_DATA['gmc_qr'],
				...templateInitialElements['gmc_qr']
			},
		});
		selectedId.set('gmc_qr')
	}

	const handleAddVerificationLink = () => {
		elements.merge({
			'gmc_link': {
				...DEFAULT_TEMPLATE_INITIAL_DATA['gmc_link'],
				...templateInitialElements['gmc_link']
			},
		});
		selectedId.set('gmc_link')
	}
	
	const handleAddImage = () => {
		let id = createElementId();
		elements.merge({
			[id]: { type: "image", header: `${headerText}`, data: { url: "https://gpnmjenofbfeawopmhkj.supabase.co/storage/v1/object/public/public/gmc_files/avatar-placeholder.png?t=2022-09-05T07%3A32%3A12.280Z", x: 0, y: 0, height: 100, width:100 } as ImageProps },
		});
		selectedId.set(id);
	}

	// const debugElements = () => {
	// 	console.log(elements.get());
	// };

	const toolBoxData: ToolBoxDataType = {
		data: [
			{
				name: "Back",
				icon: FiArrowLeft,
				onClickFunction: handleClose,
				type: "single",
			},
			{
				name: "Fullscreen",
				icon: FiMaximize2,
				onClickFunction: handleFullScreen,
				type: "single",
			},
			{
				name: "Add Field",
				icon: FiPlus,
				onClickFunction: handlePhModalOpen,
				type: "nested",
				children: [
					{
						name: "Add Field",
						icon: FiFileText,
						onClickFunction: ()=>handleAddElement('text'),
						type: "single",
					},
					{
						name: "Add QR",
						icon: FaQrcode,
						onClickFunction: handleAddQr,
						type: "single",
					},
					{
						name: "Verification Link",
						icon: VscVerified,
						onClickFunction: handleAddVerificationLink,
						type: "single",
					},
					{
						name: "Certificate Id",
						icon: FaIdBadge,
						onClickFunction: handleAddCertificateId,
						type: "single",
					},
					{
						name: "Image",
						icon: FaImage,
						onClickFunction: ()=>handleAddElement('image'),
						type: "single",
					},
				]
			},
			{
				name: "Save Template",
				icon: FiSave,
				onClickFunction: handleSaveTemplate,
				type: "single",
			},
			{
				name: "Save & Close",
				icon: FiX,
				onClickFunction: handleSaveNClose,
				type: "single",
			},
		],
	};

	const handleAddElement = (whichElement:string = 'text') =>{
		switch (whichElement) {
			case 'text':
				whichElementToAdd.current = 'text'
				handlePhModalOpen()
				break;

			case 'image':
				whichElementToAdd.current = 'image'
				handlePhModalOpen()
				break;
		
			default:
				whichElementToAdd.current = 'text'
				handlePhModalOpen()
				break;
		}
	}

	const handleElementAddFromModal= () => {
		setIsPhModalOpen(false);
		switch (whichElementToAdd.current) {
			case 'text':
				handleAddText()
				break;

			case 'image':
				handleAddImage()
				break;
		
			default:
				handleAddText()
				break;
		}
	}

	if (isConfigLoading) {
		<Skeleton />
	}

	return (
		<>
			<div className={styles.toolbar_container}>
				{toolBoxData.data.map((item, i) => {
					switch (item.type) {
						case 'single':
							return (
								<Tooltip key={i} label={item.name} fontSize='sm'>
									<Box bg={colorMode} className={styles.toolbar_item} onClick={item.onClickFunction ? item.onClickFunction : () => {}}>
										<Icon as={item.icon} mr={3} />
										{item.name}
									</Box>
								</Tooltip>
							)

						case 'divider':
							return (
								<Divider key={i} orientation='vertical' px={2} />
							)

						case 'nested':
							return (
								<Menu key={i}>
									<MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
										{
											item.icon &&
											<Icon as={item.icon} mr={3} />
										}
										{item.name}
									</MenuButton>
									<MenuList>
										{
											item.children?.map( (item, i) => {
												switch (item.type) {
													case 'single':
														return (
															<MenuItem
																key={i}
																onClick={item.onClickFunction}
																icon={<Icon as={item.icon} mr={3} />}
															>
																{item.name}
															</MenuItem>
														)

													case 'divider':
														return (
															<MenuDivider key={i} />
														)
												
													default:
														return (
															<MenuItem
																key={i}
																onClick={item.onClickFunction}
																icon={<Icon as={item.icon} mr={3} />}
															>
																{item.name}
															</MenuItem>
														)
												}
											})
										}
									</MenuList>
								</Menu>
							)
					
						default:
							return (
								<Tooltip key={i} label={item.name} fontSize='sm'>
									<Box bg={colorMode} className={styles.toolbar_item} onClick={item.onClickFunction ? item.onClickFunction : () => {}}>
										<Icon as={item.icon} mr={3} />
										{item.name}
									</Box>
								</Tooltip>
							)
					}
				})}
			</div>

			<Modal isOpen={isPhModalOpen} onClose={onPhModalClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Add new field</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack spacing={3}>
							<Text fontSize='md'>{`Field Name`}</Text>
							{/* TODO: column header not null */}
							<Input
								placeholder='Enter column header'
								value={headerText}
								onChange={(e) => {
									setHeaderText(e.target.value);
								}}
							/>
						</Stack>
					</ModalBody>

					<ModalFooter>
						<Button variant='solid' onClick={handleElementAddFromModal}>
							Add
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<Modal isOpen={isSaving} onClose={() => {}} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalBody>
						<HStack margin={30}>
							<FiLoader />
							<Text fontSize='md'>Saving the template design</Text>
						</HStack>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ToolBox;

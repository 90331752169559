import supabase from "../configs/Supabase"
import { displayError } from "../helpers/CommonFunctions"
import { certificateDataType, deleteCertificateDataObjectType, updateCertificateDataObjectType } from "../types/dataFetcherTypes/JobsDataFetcherTypes"

export const updateCertificate = async ( values:updateCertificateDataObjectType ) => {
    
    const dataToUpdate = {
        ...values,
        id: undefined,
        name: undefined,
        email: undefined,
        user_id: undefined,
    }

    let isError = false
    const { data, error } = await supabase.from( 'certificates' ).update(dataToUpdate)
    .eq('id', values.id)
    
    if (error) {
        isError = true
        displayError( 'Action not allowed', error.message || error.details || error.hint)
    }

    return {
        isQueryError: isError,
        queryData: data as certificateDataType[] | null,
        queryError: error
    }
}

export const deleteCertificate = async ( values:deleteCertificateDataObjectType ) => {

    const dataToUpdate = {
        is_deleted: true,
    }

    let isError = false
    const { data, error } = await supabase.from( 'certificates' ).update(dataToUpdate)
    .eq('id', values.id)
    
    if (error) {
        isError = true
        displayError( 'Action not allowed', error.message || error.details || error.hint)
    }

    return {
        isQueryError: isError,
        queryData: data as certificateDataType[] | null,
        queryError: error
    }
}

export const deleteCertificates = async ( ids:string[] ) => {

    const dataToUpdate = {
        is_deleted: true,
    }

    let isError = false
    const { data, error } = await supabase.from( 'certificates' ).update(dataToUpdate)
                                        .in('id', ids)
    
    if (error) {
        isError = true
        displayError( 'Action not allowed', error.message || error.details || error.hint)
    }

    return {
        isQueryError: isError,
        queryData: data as certificateDataType[] | null,
        queryError: error
    }
}

export const deleteAllCertificatesInJob = async ( jobId:string ) => {

    const dataToUpdate = {
        is_deleted: true,
    }

    let isError = false
    const { data, error } = await supabase.from( 'certificates' ).update(dataToUpdate)
                                        .eq('job_id', jobId)
    
    if (error) {
        isError = true
        displayError( 'Action not allowed', error.message || error.details || error.hint)
    }

    return {
        isQueryError: isError,
        queryData: data as certificateDataType[] | null,
        queryError: error
    }
}
import React from "react";
import { State, none } from "@hookstate/core";

import { ElementType } from "../DrawingSheet";

import {
	NumberInput,
	NumberInputField,
	NumberIncrementStepper,
	NumberDecrementStepper,
	HStack,
	Text,
	Stack,
	Button,
	Box,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverArrow,
	PopoverBody,
	Center,
} from "@chakra-ui/react";
import { FiTrash2 } from "react-icons/fi";

import EmptyPane from "./EmptyPane";

interface ImageEditorProps {
	element: State<ElementType>;
	isQr?: boolean;
}

const ImageEditor: React.FC<ImageEditorProps> = ({ element, isQr }) => {
	const handleDelete = () => {
		if (element.required.get()) return;
		element.set(none);
	};

	if (!element.get()) return <EmptyPane />;

	return (
		<>
			<Stack spacing={5}>
				{isQr && (
					<>
						<Box padding='2' pt='6'>
							<Center>
								<Stack>
									<Text fontSize='xl'>Verifiable QR Code</Text>
									<Text fontSize='md' color='gray'>
										More about QR Code and the verifiable Link
									</Text>
								</Stack>
							</Center>
						</Box>
						<HStack>
							<Stack spacing={1}>
								<Text fontSize='sm' color='gray'>
									Size
								</Text>
								<NumberInput
									size='sm'
									allowMouseWheel
									min={65}
									onChange={(value) => {
										if ("size" in element.data) element.data.size.set(Number(value));
									}}
									value={"size" in element.data ? Math.round(element.data.size.get()) : 0}>
									<HStack>
										<NumberDecrementStepper
											style={{ borderInlineStart: "0px", margin: 0 }}
											children={
												<Button size='sm' style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
													-
												</Button>
											}
										/>
										<NumberInputField style={{ margin: 0, paddingRight: 12 }} />
										<NumberIncrementStepper
											style={{ borderInlineStart: "0px", borderTopWidth: "0px", margin: 0 }}
											children={
												<Button size='sm' style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
													+
												</Button>
											}
										/>
									</HStack>
								</NumberInput>
							</Stack>
						</HStack>
					</>
				)}

				{!isQr && (
					<>
						<HStack>
							<Stack spacing={1}>
								<Text fontSize='sm' color='gray'>
									Widht
								</Text>
								<NumberInput
									size='sm'
									allowMouseWheel
									min={65}
									onChange={(value) => {
										if ("width" in element.data) element.data.width.set(Number(value));
									}}
									value={"width" in element.data ? Math.round(element.data.width.get() || 0) : 0}>
									<HStack>
										<NumberDecrementStepper
											style={{ borderInlineStart: "0px", margin: 0 }}
											children={
												<Button size='sm' style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
													-
												</Button>
											}
										/>
										<NumberInputField style={{ margin: 0, paddingRight: 12 }} />
										<NumberIncrementStepper
											style={{ borderInlineStart: "0px", borderTopWidth: "0px", margin: 0 }}
											children={
												<Button size='sm' style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
													+
												</Button>
											}
										/>
									</HStack>
								</NumberInput>


								<Text fontSize='sm' color='gray'>
									Height
								</Text>
								<NumberInput
									size='sm'
									allowMouseWheel
									min={65}
									onChange={(value) => {
										if ("height" in element.data) element.data.height.set(Number(value));
									}}
									value={"height" in element.data ? Math.round(element.data.height.get() || 0) : 0}>
									<HStack>
										<NumberDecrementStepper
											style={{ borderInlineStart: "0px", margin: 0 }}
											children={
												<Button size='sm' style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
													-
												</Button>
											}
										/>
										<NumberInputField style={{ margin: 0, paddingRight: 12 }} />
										<NumberIncrementStepper
											style={{ borderInlineStart: "0px", borderTopWidth: "0px", margin: 0 }}
											children={
												<Button size='sm' style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
													+
												</Button>
											}
										/>
									</HStack>
								</NumberInput>
							</Stack>
						</HStack>
					</>
				)}

				{!element.required.get() && (
					<Popover>
						<PopoverTrigger>
							<Button leftIcon={<FiTrash2 />} colorScheme='red' variant='outline' size='sm' width='min-content'>
								Delete
							</Button>
						</PopoverTrigger>
						<PopoverContent rootProps={{ style: { right: 0 } }}>
							<PopoverArrow />
							<PopoverBody>
								<HStack>
									<Box>Are you sure you want to delete this element?</Box>
									<Button onClick={handleDelete} variant='solid' size='sm' width='min-content'>
										Yes
									</Button>
								</HStack>
							</PopoverBody>
						</PopoverContent>
					</Popover>
				)}
			</Stack>
		</>
	);
};

export default ImageEditor;

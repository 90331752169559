import { Button, Divider, Flex, Heading, Skeleton, Spacer, Stack } from '@chakra-ui/react'
import React, { useRef } from 'react'
import { FaRedo } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ErrorComponent } from '../../components/errorComponent/ErrorComponent'
import { JsonObjectFormResponsive, jsonObjectFormResponsiveRefFunctionType } from '../../components/jsonObjectForm/JsonObjectFormResponsive'
import { useAuth } from '../../contexts/Auth'
import { getAccountConfig, setOrUpdateAccountConfig } from '../../dataFetchers/accountConfigFetcher'
import { displaySuccess } from '../../helpers/CommonFunctions'

export function GlobalVariables() {
  const { user } = useAuth()  
  const formRef = useRef<jsonObjectFormResponsiveRefFunctionType>()

  const queryClient = useQueryClient()
  const { isError, isLoading, data, error, isFetching } = useQuery(['accountLevelConfig'], () => getAccountConfig( ))

  const updateMutation = useMutation(setOrUpdateAccountConfig, {
    onSuccess: data => {
        if ( !data.isQueryError ) {
            queryClient.invalidateQueries('accountLevelConfig')
            displaySuccess('Data Updated', 'Data is updated successfully')
        }
    }
  })

  const onFormSubmit = async (data:any) => {
    // formRef.current?.reset(data['form'])
    return await updateMutation.mutateAsync({
      dataToUpdate: data['form'],
      user: user!
    })
  }  

  if ( isError ) {
    return (
        <ErrorComponent
            //@ts-ignore
            errorMessage={error.message}
        />
    )
  }

  if (data && data.isQueryError === false) {
    const { queryData, queryError } = data

    if ( queryError ) {
        return (
            <ErrorComponent
                errorMessage={queryError.message}
            />
        )
    }
    return (
      <>
          <Flex>
            <Heading size='lg'> 
                CSV Variables
            </Heading>
            <Spacer />
            <Button
                leftIcon={<FaRedo />}
                mr={3}
                variant="outline"
                onClick={ () => {
                    queryClient.invalidateQueries(['accountLevelConfig'])
                }}
                display={["none", "inherit", null, null]}
                isLoading={isFetching}
            >
                Refresh
            </Button>
          </Flex>
          <Divider my={3} />
          <JsonObjectFormResponsive
            formName='form'
            ref={formRef}
            formSchema={[
              {
                fieldName: 'Column Name',
                fieldKey: 'field_name',
                fieldType: 'text',
                fieldPlaceholder: 'Column Name',
                fieldIsRequired: true,
                fieldLabel: 'Column Name',
                fieldValidationRules: {
                  required: true,
                  minLength: {
                    value: 3,
                    message: 'Value is too short, Enter atleast 3 characters'
                  }
                }
              },
              {
                fieldName: 'Default Value',
                fieldKey: 'default_value',
                fieldType: 'text',
                fieldPlaceholder: 'Default Value',
                fieldIsRequired: true,
                fieldLabel: 'Default Value',
                fieldValidationRules: {
                  required: true,
                  minLength: {
                    value: 3,
                    message: 'Value is too short, Enter atleast 3 characters'
                  }
                }
              }
            ]}
            formValues={queryData?.csv_variables || []}
            formTableProps={{
              variant: 'simple',
              colorScheme: 'blue'
            }}
            formRowProps={{
              verticalAlign: 'top'
            }}
            functionToCallOnSubmitForm={onFormSubmit}
          />
      </>
    )
  }
  if ( isLoading ) {
    return (
        <Stack mt={10} spacing={5} >
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
        </Stack>
    )
  }

  return (
      <ErrorComponent
      />
  )
}

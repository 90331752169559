import { PostgrestError, User } from "@supabase/supabase-js"
import supabase from "../configs/Supabase"
import { displayError, genericObjectType } from "../helpers/CommonFunctions"

export interface accountLevelConfigTypes {
    csv_variables: genericObjectType[],
    zoom_credentials: genericObjectType
}

export interface accountLevelConfigReturnTypes {
    isQueryError: boolean,
    queryData: accountLevelConfigTypes,
    queryError: PostgrestError | null
}

export const getAccountConfig = async (): Promise<accountLevelConfigReturnTypes> => {
    try {
        let isError = false
        const { data, error } = await supabase
                                            .from('account_level_config')
                                            .select('csv_variables, zoom_credentials')
                                            // .eq('user_id', user?.id)
                                            .limit(1)
        if (error) {
            isError = true
        }

        return {
            isQueryError: isError,
            queryData: (data && data[0]) ? data[0] : {csv_variables: []},
            queryError: error,
        }
    } catch (error) {
        throw error;  
    }
}

export const setOrUpdateAccountConfig = async ({dataToUpdate, user}:{ dataToUpdate:any, user:User }) => {
    let isError = false

    const { data, error } = await supabase.from('account_level_config').upsert({
        user_id: user?.id,
        csv_variables: dataToUpdate
    }, {
        onConflict: 'user_id',
        returning: 'minimal'
    })

    if (error) {
        isError = true
        displayError( 'Action not allowed', error.message || error.details || error.hint)
    }

    return {
        isQueryError: isError,
        queryData: data as unknown as accountLevelConfigTypes,
        queryError: error
    }
}

export const setOrUpdateMultipleAccountConfig = async ({dataToUpdate}:{ dataToUpdate:any }) => {
    let isError = false

    const { data, error } = await supabase.from('account_level_config').upsert(dataToUpdate, {
        onConflict: 'user_id',
        returning: 'minimal'
    })

    if (error) {
        isError = true
        displayError( 'Action not allowed', error.message || error.details || error.hint)
    }

    return {
        isQueryError: isError,
        queryData: data as unknown as accountLevelConfigTypes,
        queryError: error
    }
}
import Image from '@tiptap/extension-image'

export const CustomImage = Image.extend({
    defaultOptions: {
        ...Image.options,
        sizes: ['small', 'medium', 'large']
    },
    addAttributes() {
        return {
            ...this.parent?.(),
            width: {
                default: '100%',
                parseHTML: element => element.getAttribute('width'),
                renderHTML: attributes => {
                    return {
                        width: attributes.width,
                    }
                },
            },
            height: {
                default: 'auto',
                parseHTML: element => element.getAttribute('height'),
                renderHTML: attributes => {
                    return {
                        height: attributes.height,
                    }
                },
            },
            style: {
                default: {

                },
                parseHTML: element => element.getAttribute('style'),
                renderHTML: attributes => {
                    return {
                        style: `${attributes.style};`
                    }
                },
            },
            size: {
                default: 'small',
                rendered: false
            }
        }
    },
    // addCommands() {
    //     return {
    //         ...this.parent?.(),
    //         setSize: (attributes:any) => ({ chain:any }) => {
    //             if (!this.options.sizes.includes(attributes.size)) {
    //                 return false
    //             }
    //         }
    //     }
    // }

})
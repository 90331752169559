import moment from "moment"
import supabase from "../configs/Supabase"
import { displayError } from "../helpers/CommonFunctions"
import { profileFetcherObjectReturnType } from "../types/dataFetcherTypes/DataFetcherTypes"

export const getUserProfileData = async (): Promise<profileFetcherObjectReturnType> => {
    try {
        let isError = false
        let returnData: null | object = {}
        const { data, error } = await supabase
                                .from( 'profiles' )
                                .select( `
                                    full_name, 
                                    gender, 
                                    date_of_birth, 
                                    linkedin_id, 
                                    facebook_handle, 
                                    twitter_handle, 
                                    insta_handle, 
                                    about,
                                    avatar_url,
                                    website,
                                    state,
                                    country
                                `)
        if (error) {
            isError = true
        }

        if (data) {
            returnData = {
                ...data![0],
                ...( ( data![0] && data![0].date_of_birth ) && { 'date_of_birth': moment(data![0].date_of_birth).local() } )
            }
        } else {
            returnData = data
        }

        return {
            isQueryError: isError,
            queryData: returnData,
            queryError: error
        }
    } catch (error) {
        throw error;  
    }
}

export const setUserProfileData = async (updatedData:any): Promise<profileFetcherObjectReturnType> => {

    const updatedDataObject = {
        user_id: updatedData.id,
        full_name: updatedData.fullName, 
        gender: updatedData.gender, 
        ...( updatedData.dob && { date_of_birth: moment( updatedData.dob ).utc() } ), 
        linkedin_id: updatedData.linkedinId, 
        facebook_handle: updatedData.facebookHandle, 
        twitter_handle: updatedData.twitterHandle, 
        insta_handle: updatedData.instaHandle, 
        about: updatedData.about,
        avatar_url: updatedData.avatarURL,
        website: updatedData.website,
        country: updatedData.country,
        state: updatedData.state
    }

    let isError = false
    const { data, error } = await supabase.from( 'profiles' ).upsert(updatedDataObject)
    let returnData: null | object = {}

    if (error) {
        isError = true
        displayError( 'Action not allowed', error.message || error.details || error.hint)
    }

    if (data) {
        returnData = {
            ...data![0],
            date_of_birth: moment(data![0].date_of_birth).local()
        }
    } else {
        returnData = data
    }

    return {
        isQueryError: isError,
        queryData: returnData,
        queryError: error
    }
}


// .select( `
//     full_name as name, 
//     gender, 
//     date_of_birth as dob, 
//     linkedin_id as linkedinId, 
//     facebook_handle as facebookHandle, 
//     twitter_handle as twitterHandle, 
//     insta_handle as instaHandle, 
//     avatar_url as avatarURL
// `)
import { createStandaloneToast } from "@chakra-ui/react"
import moment, { isDate } from "moment"
import { DEFAULT_NOTIFICATION_DISPLAY_TIME } from "../configs/GlobalConstants"
import { CustomError } from "../contexts/Auth"
import { globalErrorObjectMaker } from "./ErrorMessageHelper"
import { globalSuccessObjectMaker } from "./SuccessMessageHelper"

const { customAlphabet } = require('nanoid')
const alphabetSimple = '123456789ABCDEFGHIJKLMNPQRSTUVWXYZ'
const alphabetComplex = '123456789ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz'

const nanoIdSimple = customAlphabet(alphabetSimple, 10)
const nanoIdAverage = customAlphabet(alphabetSimple, 16)
const nanoIdComplex = customAlphabet(alphabetComplex, 20)

export type stringOrNumber = string | number
export type genericObjectType = {[key:string]: stringOrNumber | boolean | any}
export interface errorObjectInputType {
	code?: number;
	subType?: string;
	type?: string;
	error?: CustomError | null;
}

export interface successObjectInputType {
	code?: number;
	subType?: string;
	type?: string;
	successObj?: any;
}

export const toast = createStandaloneToast();

export const displayInfo = ( title?: string, message?: string ) => {
	const idObject = {
		title,
		message,
	};
	const id = JSON.stringify(idObject);
	const isThisToastExist = toast.isActive(id);

	if (!isThisToastExist) {
		toast({
			id,
			title: title,
			description: message,
			status: 'info',
			duration: DEFAULT_NOTIFICATION_DISPLAY_TIME,
			isClosable: true,
			position: "top-right",
		});
	}
}

export const displayError = (title?: string, message?: string, createError: boolean = false, errorObject: errorObjectInputType = {}) => {
	if (createError && Object.keys(errorObject).length !== 0) {
		const homeMadeErrorObject = globalErrorObjectMaker(errorObject);
		title = homeMadeErrorObject.title;
		message = homeMadeErrorObject.message;
	}
	const idObject = {
		title,
		message,
	};
	const id = JSON.stringify(idObject);
	const isThisToastExist = toast.isActive(id);

	if (!isThisToastExist) {
		toast({
			id,
			title: title,
			description: message,
			status: "error",
			duration: DEFAULT_NOTIFICATION_DISPLAY_TIME,
			isClosable: true,
			position: "top-right",
		});
	}
};

export const displaySuccess = (title?: string, message?: string, createMessage: boolean = false, successObject: successObjectInputType = {}) => {
	if (createMessage && Object.keys(successObject).length !== 0) {
		const homeMadeSuccessObject = globalSuccessObjectMaker(successObject);
		title = homeMadeSuccessObject.title;
		message = homeMadeSuccessObject.message;
	}
	toast({
		title: title,
		description: message,
		status: "success",
		duration: DEFAULT_NOTIFICATION_DISPLAY_TIME,
		isClosable: true,
		position: "top-right",
	});
};

export interface StringMap {
	[key: string]: any;
}

export const getRandomProperty = (obj: StringMap): any => {
	let keys = Object.keys(obj);
	return obj[keys[(keys.length * Math.random()) << 0]];
};

export const getRandomKey = (obj: StringMap): any => {
	let keys = Object.keys(obj);
	return keys[(keys.length * Math.random()) << 0];
};

export const getRandomKeyValuePair = (obj: StringMap): any => {
	let keys = Object.keys(obj);
	let key = keys[(keys.length * Math.random()) << 0];
	return {
		key,
		value: obj[key],
	};
};

export const isFileImage = (file: File) => {
	return file && file["type"].split("/")[0] === "image";
};

export const parseDateString = (value: any, originalValue: any) => {
	const parsedDate = isDate(originalValue) ? originalValue : moment(originalValue).format("YYYY-MM-DD");

	return parsedDate;
};

export const createElementId = () => Math.ceil(1 + Math.random() * 10000000).toString(16);
export const createDateWithDays = (days: number) => {
	let newDate = new Date();
	newDate.setDate(newDate.getDate() + days);
	return newDate;
};

export function addSeperater(str: string, seperator: string, positionArray: number[]) {
	positionArray.map((pos, index) => {
		str = str.slice(0, pos + index) + (seperator || "") + str.slice(pos + index);
		return true;
	});
	return str;
}

export function getUniqueId(
	latency: "low" | "medium" | "high" = "low",
	isSeperater: boolean = true,
	seperator: string = "-",
	positionArray: number[] = [],
	alphabet: string | undefined = undefined,
	size: number = 10
) {
	let certId = "";

	if (alphabet) {
		const nenoIdCustom = customAlphabet(alphabet, size);
		if (isSeperater) {
			certId = addSeperater(nenoIdCustom(), seperator, positionArray);
		} else {
			certId = nenoIdCustom();
		}
	} else {
		switch (latency) {
			case "low":
				if (isSeperater) {
					certId = addSeperater(nanoIdSimple(), seperator, [3, 7]);
				} else {
					certId = nanoIdSimple();
				}
				break;

			case "medium":
				if (isSeperater) {
					certId = addSeperater(nanoIdAverage(), seperator, [4, 8, 12]);
				} else {
					certId = nanoIdAverage();
				}
				break;

			case "high":
				if (isSeperater) {
					certId = addSeperater(nanoIdComplex(), seperator, [5, 10, 15]);
				} else {
					certId = nanoIdComplex();
				}
				break;
		}
	}
	return certId;
}

export const recreateListWithDesiredOrder = (desiredOrder: stringOrNumber[], listToOrder: stringOrNumber[]) => {
	let newOrderedList: stringOrNumber[] = [];
	desiredOrder.map((listKey) => {
		if (listToOrder.includes(listKey)) {
			newOrderedList.push(listKey);
		}
		return true;
	});
	return newOrderedList;
};

export const exportCSV = ( csvString:string, fileName:string = 'data.csv' ) => {
	let csvContent = "data:text/csv;charset=utf-8,"
	
	let encodedUri = encodeURIComponent(csvString)
	csvContent = csvContent+encodedUri
	
	let link = document.createElement("a")
	link.setAttribute("href", csvContent)
	link.setAttribute("download", fileName)
	document.body.appendChild(link)
	link.click()
}

export const getFileNameWithExt = (event:any) => {

	if (!event || !event.current || !event.current.files || event.current.files.length === 0) {
	  return false;
	}
  
	const file = event.current.files[0]
	const name = event.current.files[0].name;
	const lastDot = name.lastIndexOf('.');
  
	const fileName = name.substring(0, lastDot);
	const fileExtension = name.substring(lastDot + 1);
  
	return {
		file,
		fileName,
		fileExtension
	}
	
}

export const setWait = (ms: number) => {
    return new Promise( resolve => setTimeout(resolve, ms) );
}

export const objectToCssString = (obj:object) => {
	return Object.entries(obj).map(([k, v]) => `${k}:${v}`).join(';')
}

export const removeKeyFromObject = (obj:Object, keysToRemove:string[]) => {
	const filteredObject = Object.keys(obj).filter( key => !keysToRemove.includes(key))
	if (filteredObject.length > 0) {
		//@ts-ignore
		return Object.assign(...filteredObject.map( key => ({[key]: obj[key]})))
	} else {
		return {}
	}
}

export const createCookie = (name:string, value:any, minutes:number ) => {
	var expires = "";
    if (minutes) {
        var date = new Date();
        date.setTime(date.getTime()+(minutes*60*1000));
        expires = "; expires="+date.toUTCString();
    } else {
        expires = "";
    }
    document.cookie = name+"="+value+expires+"; path=/";
}

export const filterEmptyValues = (data:Array<any>, column:string) => {
    return data.filter(row => row[column]);
}

// export const removeTrailingSpaces = ( list:genericObjectType[], keys?:string[]) => {
// 	if (!keys) {
// 		keys = Object.keys(list[0])
// 	} else if(typeof keys === 'string') {
// 		keys = [keys]
// 	}
// 	if (!keys || typeof keys === 'undefined' || (keys.length < 1)) {
// 		return list
// 	}
// 	list.map( obj => {
// 		let trimedObject:genericObjectType = {}
// 		keys!.map((key:string) => {
// 			if (typeof obj[key] === 'string') {
// 				trimedObject[key] = obj[key].trim()
// 			} else if (Array.isArray(obj[key])) {
// 				trimedObject[key] = obj[key].map( (val:any) => {
// 					if (typeof val === 'string') {
// 						return val.trim()
// 					}
// 					if (typeof val === 'object') {
// 						return Object.keys(val).map( arrKey => (
// 							val[arrKey].trim()
// 						))
// 					}
// 					if (Array.isArray(val) && typeof val[0] === 'object') {
// 						return removeTrailingSpaces(obj[key])
// 					}
// 					if (Array.isArray(val) && typeof val[0] === 'string') {
// 						return val.map( arrVal => (
// 							arrVal.trim()
// 						))
// 					}
// 					return val
// 				})
// 			}
// 		})
// 		return trimedObject
// 	})
// }

export const replacePlaceholderValueInString = (replacements:genericObjectType, str:string) => {
	return str.replace(/{{\w+}}/g, function(all) {
		return replacements[all] || all;
	});
}
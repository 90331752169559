import React, { useRef, useEffect } from "react";
import { Image, Transformer } from "react-konva";
import useImage from "use-image";
import { Transformer as KonvaTransformer } from "konva/lib/shapes/Transformer";
import { Image as KonvaImage } from "konva/lib/shapes/Image";

export interface ImageProps {
	id?: string;
	x: number;
	y: number;
	width: number;
    height: number;
	stroke?: string;
	strokeWidth?: number;
    url: string
}

export interface Props {
	attrs: ImageProps;
	isSelected: boolean;
	onSelect: () => void;
	onChange: (newAttrs: ImageProps) => void;
	elementProps?: {[key:string]: string | object | boolean }
}

const ImageElement: React.FC<Props> = ({ attrs, isSelected, onSelect, onChange, elementProps }) => {
	const trRef = useRef<KonvaTransformer>(null);
	const qrRef = useRef<KonvaImage>(null);

	const [logoImg] = useImage(attrs.url)

	const handleKeyup = () => {
		if (isSelected) {
			handleDragEnd()
		}
	}

	const handleKeydown = (event:any) => {
		if (isSelected) {
			switch (event.key) {
				case 'ArrowLeft':
					qrRef.current?.x(qrRef.current?.x() - 1)
					event.preventDefault()	
					break;

				case 'ArrowRight':
					qrRef.current?.x(qrRef.current?.x() + 1)	
					event.preventDefault()	
					break;

				case 'ArrowUp':
					qrRef.current?.y(qrRef.current?.y() - 1)
					event.preventDefault()		
					break;

				case 'ArrowDown':
					qrRef.current?.y(qrRef.current?.y() + 1)
					event.preventDefault()		
					break;
			
				default:
					break;
			}
		}
	}

	useEffect(() => {
		window.addEventListener('keydown', handleKeydown)
		window.addEventListener('keyup', handleKeyup)
		return () => {
			window.removeEventListener('keydown', handleKeydown)
			window.removeEventListener('keyup', handleKeyup)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSelected])
		
	useEffect(() => {
		if (isSelected) {
			// attach transformer
			if (trRef.current && qrRef.current) {
				trRef.current.nodes([qrRef.current]);
				trRef.current.moveToTop();
				trRef.current.getLayer()?.batchDraw();
			}
		}
	}, [isSelected]);

	const handleMouseEnter = () => {
		const node = qrRef.current;
		if (node && !isSelected) onChange({ ...attrs, stroke: "#42adf5", strokeWidth: 1 });
	};

	const handleMouseLeave = () => {
		const node = qrRef.current;
		if (node) onChange({ ...attrs, strokeWidth: 0 });
	};

	const handleCanvasBounds = (pos: any) => {
		let newPos = { x: 0, y: 0 };
		let node = qrRef.current;
		let tr = trRef.current;
		if (tr) {
			let nodeWidth = tr.width();
			let nodeHeight = tr?.height();
			let maxX = node?.getStage()?.attrs.width - nodeWidth;
			let maxY = node?.getStage()?.attrs.height - nodeHeight;
			if (pos.x > 0) newPos.x = pos.x;
			if (pos.x > maxX) newPos.x = maxX;
			if (pos.y > 0) newPos.y = pos.y;
			if (pos.y > maxY) newPos.y = maxY;
		}
		return newPos;
	};

	const handleTransform = () => {
		const node = qrRef.current;
		if (node) {
			const scaleY = node.scaleY();
            const scaleX = node.scaleX()
			node.scaleX(1);
			node.scaleY(1);
			onChange({
				...attrs,
				height: node.height() * scaleY,
                width: node.width() * scaleX,
			});
		}
	};

	const handleDragEnd = () => {
		let tr = qrRef.current;
		if (tr) {
			let x = tr?.x();
			let y = tr?.y();
			let width = tr.width()
            let height = tr.height()
			onChange({ ...attrs, x, y, width, height });
		}
		onSelect();
	};

	return (
		<>
			<Image
				{...attrs}
				draggable
				height={attrs.height}
				width={attrs.width}
				image={logoImg}
				onMouseDown={onSelect}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={onSelect}
				onTap={onSelect}
				ref={qrRef}
				onDragEnd={handleDragEnd}
				dragBoundFunc={handleCanvasBounds}
				onTransformEnd={onSelect}
				onTransform={handleTransform}
				name='konva_object'
			/>

			{isSelected && (
				<Transformer
					ref={trRef}
					rotateEnabled={false}
					flipEnabled={false}
					enabledAnchors={["top-right", "top-left", "bottom-right", "bottom-left", "top-center", "bottom-center", "middle-right", "middle-left"]}
					boundBoxFunc={(oldBox, newBox) => {
						let min_width = 0;
						if (qrRef.current) {
							min_width = 65;
						}
						if (newBox.width < min_width) {
							return oldBox;
						}
						return newBox;
					}}
				/>
			)}
		</>
	);
};

export default ImageElement;

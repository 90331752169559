import React from 'react'
import './Loading.css'

export default function Loading() {
    return (
        <div className="container">
            <section className="section-class">
                <div className="loader loader-1">
                <div className="loader-outter"></div>
                <div className="loader-inner"></div>
                </div>
            </section>
        </div>
    )
}

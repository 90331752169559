import { Box, BoxProps, Table, TableCaption, TableProps, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { useWindowVirtualizer } from '@tanstack/react-virtual'
import React, { memo } from 'react'
import { genericObjectType, stringOrNumber } from '../../helpers/CommonFunctions'

export interface ResponsiveTableVirtualType {
    data:{
        keys: stringOrNumber[],
        header: genericObjectType,
        body: genericObjectType[]
    }, 
    tableCaption?: string, 
    tablePropsToPass?: TableProps, 
    containerPropsToPass?: BoxProps
}

export const ResponsiveTableVirtual = ( params:ResponsiveTableVirtualType ) => {
    const { data, tableCaption, tablePropsToPass, containerPropsToPass, ...propsToPass } = params
    const rowVirtualizer = useWindowVirtualizer({
        count: data.body.length,
        overscan: 2,
        debug: true,
        estimateSize(index) {
            return 89
        },
    })
    const items = rowVirtualizer.getVirtualItems();
    const paddingTop = items.length > 0 ? items[0].start : 0;
    const paddingBottom = items.length > 0 ? rowVirtualizer.getTotalSize() - items[items.length - 1].end : 0;
    
    return (
        <Box
            {...containerPropsToPass}
            overflow="auto"
            p={3}
            pt={`${paddingTop}px`}
            pb={`${paddingBottom}px`}
        >
            <Table 
                colorScheme="teal" 
                {...tablePropsToPass} 
                {...propsToPass} 
            >
                { tableCaption && <TableCaption>{tableCaption}</TableCaption> }
                <Thead>
                    <Tr>
                        {
                            data.keys.map((key:stringOrNumber, index:number) => {
                                return (<Th key={`${index}header`}>{data.header[key]}</Th>)
                            })
                        }
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        rowVirtualizer.getVirtualItems().map((virtualRow) => {
                            const row = data.body[virtualRow.index]
                            return (
                                <Tr key={row.keyId}>
                                    {
                                        data.keys.map((key:stringOrNumber, index:number) => {
                                            return (<Td minW="150px" maxW="300px" key={`${row.keyId}-${index}row`}>{row[key]}</Td>)
                                        })
                                    }
                                </Tr>
                            )
                        })
                    }
                </Tbody>
            </Table>
        </Box>
    )
}

export const ResponsiveTableVirtualMemo = memo(ResponsiveTableVirtual)
import moment from 'moment'
import React from 'react'
import { stringOrNumber } from '../../helpers/CommonFunctions'
import DisplayObjectInCard from '../displayObjectInCard/DisplayObjectInCard'
import { InputWithCopy } from '../inputWithCopy/InputWithCopy'
import { TextareaWithCopy } from '../textareaWithCopy/TextareaWithCopy'

export interface ShowJobInputType {
    displayOrder: stringOrNumber[],
    keysAndDisplayNameMapping: {[key:string]:string},
    displayObject: {[key:string]:any}
}

export const ShowJob = (props:ShowJobInputType) => {
    const { displayOrder, keysAndDisplayNameMapping, displayObject } = props
    const internalDisplayObject = {
        ...displayObject,
        ...( displayObject.created_at && { inserted_at: moment( displayObject.inserted_at ).local().format('MMM DD, YYYY HH:mm:ss') } ),
        ...( displayObject.updated_at && { updated_at: moment( displayObject.updated_at ).local().format('MMM DD, YYYY HH:mm:ss') } ),
        certificate_short_code: (displayObject.certificate_short_code === 'AAAA-BBBB-CCCC-DDDD') ? displayObject.id : displayObject.certificate_short_code,
        extra_metadata: <TextareaWithCopy 
                            isReadOnly={true} 
                            toCopy={JSON.stringify(displayObject.extra_metadata, undefined, 2)} 
                        />,
        download_link: <InputWithCopy
                        isReadOnly={true}
                        toCopy={`https://certificate.givemycertificate.com/c/${(displayObject.certificate_short_code === 'AAAA-BBBB-CCCC-DDDD') ? displayObject.id : displayObject.certificate_short_code}`}
                    />,
        verification_link: <InputWithCopy
                            isReadOnly={true}
                            toCopy={`https://verification.givemycertificate.com/v/${(displayObject.certificate_short_code === 'AAAA-BBBB-CCCC-DDDD') ? displayObject.id : displayObject.certificate_short_code}`}
                        />,
    }
    const internalDisplayOrder = displayOrder.filter(item => !['controls'].includes(item as string))
    
    return (
        <DisplayObjectInCard 
            displayOrder={internalDisplayOrder}
            keysAndDisplayNameMapping={keysAndDisplayNameMapping}
            displayObject={internalDisplayObject}
        />
    )
}

import React, { useEffect, useRef, useState } from 'react'
import { Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Icon, Input, InputGroup, InputRightElement, Link, Spinner, Text, Tooltip, useColorModeValue, useOutsideClick, } from '@chakra-ui/react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { useQuery } from 'react-query'
import { getSearchData } from '../../dataFetchers/searchDataFetcher'
import { VscQuestion } from 'react-icons/vsc'

export interface AutoCompleteFromTableDataInputType {
    inputName?: string
    fromTable: string
    fetchKey: string
    placeholder?: string
    keyName?: string
    label?: string
    helperText?: string
    tooltipText?: string
    errorMessage?: string
    defaultText?: string
    onChangeFunction: (...args: any[]) => any
}

export const AutoCompleteFromTableData = ( props:AutoCompleteFromTableDataInputType ) => {
    const { inputName, fromTable, fetchKey, placeholder, keyName, label, helperText, tooltipText, errorMessage, defaultText = '', onChangeFunction } = props
    const [isOpen, setIsOpen] = useState(false)
    const [searchText, setSearchText] = useState<string>(defaultText)
    const [timeOutIdHolder, setTimeOutIdHolder] = useState<any>(null)
    const [searchTextForAPI, setSearchTextForAPI] = useState<string>(defaultText)
    
    const lastSelectedValue = useRef('')
    const ref = useRef<HTMLDivElement>(null)
    const { data, isFetching } = useQuery([ 'searchInput', fromTable, fetchKey, searchTextForAPI ], 
            () => getSearchData( fromTable, fetchKey, searchTextForAPI ), 
            { keepPreviousData : true }
        )

    useEffect(() => {
        if (timeOutIdHolder !== null ) {
            clearTimeout(timeOutIdHolder)
        }
        setTimeOutIdHolder(setTimeout(() => {
            setSearchTextForAPI(searchText)
        }, 500))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText])
    
    useOutsideClick({
        ref: ref,
        handler: () => setIsOpen(false)
    })

    useEffect(() => {
        if (!isOpen) {
            setSearchText(lastSelectedValue.current)
        }
    }, [isOpen])
    useEffect(() => {
        setSearchText(defaultText)
    }, [defaultText])

    return (
        <div ref={ref}>
            <FormControl isInvalid={ (errorMessage)? true : false }>
                <Flex alignItems="center" justifyContent="space-between">
                    { label && <FormLabel>{label}</FormLabel> }
                    {
                        tooltipText &&
                        <Tooltip label={tooltipText} placement="auto">
                            <span>
                                <Icon as={ VscQuestion } />
                            </span>
                        </Tooltip>
                    }
                </Flex>
                <InputGroup>
                    <Input 
                        placeholder={placeholder || 'Search...'}
                        type="search" 
                        name={ inputName || undefined}
                        value={searchText}
                        onFocus={() => {
                            setIsOpen(true)
                        }}
                        onChange={(event) => (
                            setSearchText(event.target.value)
                        )}
                    />
                    <InputRightElement
                        onClick={() => {
                            setIsOpen(!isOpen)
                        }}
                    >
                        {
                            isFetching ? 
                            <Spinner
                                thickness="3px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="blue.500"
                                size="sm"
                            />
                            :
                            <Icon as={ isOpen ? FiChevronUp : FiChevronDown } />
                        }
                    </InputRightElement>
                </InputGroup>
                { helperText && <FormHelperText>{helperText}</FormHelperText> }
                { errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage> }
            </FormControl>
            <Flex 
                direction="column" 
                maxH="200px" 
                overflowY="auto" 
                position="absolute" 
                bg={useColorModeValue('white', 'gray.700')}
                mt={3}
                py={4}
                borderRadius={6}
                display={ isOpen ? 'flex' : 'none'}
                shadow="lg"
                zIndex={5}
            >
                {
                    ( data?.queryData && ( data?.queryData.length > 0 )) ?
                    data?.queryData!.map( (item) => {
                        return (
                            <Link 
                                px={6}
                                py={4}
                                //@ts-ignore
                                key={item[keyName!] || item.id}
                                onClick={() => {
                                    lastSelectedValue.current = item.name!
                                    setSearchText(item.name!)
                                    onChangeFunction(item)
                                    setIsOpen(false)
                                }}
                                _hover={{
                                    bg: "rgba(0,0,0,0.2)"
                                }}
                            >
                                {item.name}
                            </Link>
                        )
                    })
                    :
                    <Text
                        px={6}
                        py={4}
                    >
                        No matching value found
                    </Text>
                }
            </Flex>
        </div>
    )
}


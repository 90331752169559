import moment from "moment"
import supabase from "../configs/Supabase"
import { displayError } from "../helpers/CommonFunctions"
import { deleteOrganisationDataObjectType, organisationDataObjectReturnType, organisationDataObjectType, updateOrganisationDataObjectType } from "../types/dataFetcherTypes/DataFetcherTypes"

export const getOrganisationData = async ( page:number=1, size:number=10, sortOnColumn:string='updated_at', sortOrder:string='ascending' ): Promise<organisationDataObjectReturnType> => {
    try {
        let isError = false
        const { data, error, count } = await supabase
                                .from( 'organizations' )
                                .select( `
                                    organization_id,
                                    name,
                                    type,
                                    about,
                                    establishment_date,
                                    size,
                                    logo_url,
                                    address,
                                    mobile,
                                    email,
                                    website,
                                    linkedin,
                                    facebook,
                                    twitter,
                                    instagram,
                                    created_at,
                                    updated_at,
                                    reply_to_email
                                `, {
                                    count: "exact"
                                })
                                .eq('is_deleted', false)
                                .order( sortOnColumn, {
                                    ascending: (sortOrder === 'ascending')
                                })
                                .range( (page-1)*size, (page*size)-1 )
        if (error) {
            isError = true
        }

        return {
            isQueryError: isError,
            queryData: data,
            queryError: error,
            querySize: count,
        }
    } catch (error) {
        throw error;  
    }
}

export const setOrganizationData = async (updatedData:organisationDataObjectType): Promise<organisationDataObjectReturnType> => {
    
    const updatedDataObject = {
        ...updatedData,
        ...( updatedData.establishment_date && { establishment_date: moment( updatedData.establishment_date ).utc() } ),
    }

    let isError = false
    const { data, error } = await supabase.from( 'organizations' ).insert(updatedDataObject, {
        returning: "minimal"
    })

    if (error) {
        isError = true
        displayError( 'Action not allowed', error.message || error.details || error.hint)
    }

    return {
        isQueryError: isError,
        queryData: data as organisationDataObjectType[] | null,
        queryError: error
    }
}

export const deleteOrganization = async ( values:deleteOrganisationDataObjectType ) => {
    const dataToUpdate = {
        is_deleted: true
    }

    let isError = false
    const { data, error } = await supabase.from( 'organizations' ).update(dataToUpdate)
    .eq('organization_id', values.id)
    .eq('user_id', values.user_id)
    
    if (error) {
        isError = true
        displayError( 'Action not allowed', error.message || error.details || error.hint)
    }

    return {
        isQueryError: isError,
        queryData: data as organisationDataObjectType[] | null,
        queryError: error
    }
}

export const updateOrganization = async ( values:updateOrganisationDataObjectType ) => {
    const dataToUpdate = {
        ...values,
        organization_id: undefined
    }

    let isError = false
    const { data, error } = await supabase.from( 'organizations' ).update(dataToUpdate)
    .eq('organization_id', values.organization_id)
    .eq('user_id', values.user_id)
    
    if (error) {
        isError = true
        displayError( 'Action not allowed', error.message || error.details || error.hint)
    }

    return {
        isQueryError: isError,
        queryData: data as organisationDataObjectType[] | null,
        queryError: error
    }
}
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Button,
    BoxProps,
    useBreakpointValue,
    SlideDirection
} from "@chakra-ui/react"
import React, { cloneElement, ReactElement } from 'react'
import { useImperativeHandle } from "react"
import { forwardRef } from "react"

export type forceDrawerStateType = "open" | "close" | null | undefined

export interface drawerComponentInputPropsType extends BoxProps {
    triggerComponent: ReactElement
    heading: string
    showFooter?: boolean
    footerButtons?: ReactElement
    size?: "xs" | "sm" | "md" | "lg" | "xl" | "full"
    forceDrawerState?: forceDrawerStateType
}

export interface drawerComponentRefFunctionType {
    onOpen: () => void,
    onClose: () => void,
}

export const DrawerComponent = ( props:drawerComponentInputPropsType ) => {
    const { triggerComponent, children, heading, showFooter, footerButtons, size, forceDrawerState, ...restProps } = props
    const { isOpen, onOpen, onClose } = useDisclosure()
    const slideDirection = useBreakpointValue({ base: "bottom", md: "right" }) as SlideDirection

    const triggerComponentWithInjectedProps = cloneElement( triggerComponent, { onClick: () => {
        if ( triggerComponent.props.onClick ) {
            triggerComponent.props.onClick()
        }
        onOpen()
    }})

    const getDrawerState = () => {
        if ( forceDrawerState ) {
            if ( forceDrawerState === 'close' ) {
                return false
            }
            if ( forceDrawerState === 'open' ) {
                return true
            }
        }
        return isOpen
    }

    return (
        <>
            { triggerComponentWithInjectedProps }
            <Drawer
                isOpen={ getDrawerState() }
                placement={slideDirection}
                onClose={onClose}
                size={size}
                { ...restProps }
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader> { heading } </DrawerHeader>

                    <DrawerBody>
                        { children }
                    </DrawerBody>

                    {
                        showFooter &&
                        <DrawerFooter>
                            <Button variant="outline" mr={3} onClick={onClose}>
                                Cancel
                            </Button>
                            { footerButtons }
                        </DrawerFooter>
                    }
                </DrawerContent>
            </Drawer>
        </>
    )
}

export const DrawerComponentWithRef = forwardRef(( props:drawerComponentInputPropsType, ref) => {
    const { triggerComponent, children, heading, showFooter, footerButtons, size, forceDrawerState, ...restProps } = props
    const { isOpen, onOpen, onClose } = useDisclosure()
    const slideDirection = useBreakpointValue({ base: "bottom", md: "right" }) as SlideDirection

    const triggerComponentWithInjectedProps = cloneElement( triggerComponent, { onClick: () => {
        if ( triggerComponent.props.onClick ) {
            triggerComponent.props.onClick()
        }
        onOpen()
    }})

    useImperativeHandle( ref, () => ({
        onClose: onClose,
        onOpen: onOpen
    }))

    const getDrawerState = () => {
        if ( forceDrawerState ) {
            if ( forceDrawerState === 'close' ) {
                return false
            }
            if ( forceDrawerState === 'open' ) {
                return true
            }
        }
        return isOpen
    }

    return (
        <>
            { triggerComponentWithInjectedProps }
            <Drawer
                isOpen={ getDrawerState() }
                placement={slideDirection}
                onClose={onClose}
                size={size}
                { ...restProps }
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader> { heading } </DrawerHeader>

                    <DrawerBody>
                        { children }
                    </DrawerBody>

                    {
                        showFooter &&
                        <DrawerFooter>
                            <Button variant="outline" mr={3} onClick={onClose}>
                                Cancel
                            </Button>
                            { footerButtons }
                        </DrawerFooter>
                    }
                </DrawerContent>
            </Drawer>
        </>
    )
})

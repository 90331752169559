import { Box, BoxProps, Button, ButtonProps, Divider, Checkbox, CheckboxProps, Flex, FormControl, FormErrorMessage, IconButton, Input, InputProps, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputProps, NumberInputStepper, Select, SelectProps, Spacer, Stack, Switch, SwitchProps, Table, TableCaption, TableCellProps, TableProps, TableRowProps, Tbody, Td, Th, Thead, Tr, useBreakpointValue, Text } from '@chakra-ui/react'
import React, { forwardRef, memo, useImperativeHandle, useState } from 'react'
import { useForm, useFieldArray, RegisterOptions, FormState, UseFormGetValues } from "react-hook-form"
import { FaChevronCircleUp, FaHistory, FaPlusSquare, FaServer, FaTable, FaThList, FaTrash } from 'react-icons/fa'
import { stringOrNumber, removeKeyFromObject } from '../../helpers/CommonFunctions'
import { ToggleButton } from '../toggleButton/ToggleButton'
import { ToggleButtonGroup } from '../toggleButtonGroup/ToggleButtonGroup'

export interface formSchemaType extends InputProps {
  fieldName: string,
  fieldKey: string,
  fieldType: 'text' | 'number' | 'boolean' | 'select' | 'switch',
  fieldSelectOptions?: stringOrNumber[],
  fieldPlaceholder?: string,
  fieldIsRequired: boolean,
  fieldLabel?: string,
  fieldValidationRules?: RegisterOptions,
  fieldProps?: TableCellProps
}

export interface formValueType {
  [key:string]: string | number | readonly string[] | boolean | undefined
}

export interface JsonObjectFormResponsiveType extends BoxProps {
    formSchema: formSchemaType[],
    isAddNewValueAllowed?: boolean,
    formValues?: formValueType[],
    formRowProps?: TableRowProps,
    showDeleteButton?: boolean,
    isSingleRow?: boolean,
    deleteButtonProps?: ButtonProps,
    formCaption?: stringOrNumber | null,
    formTableProps?: TableProps,
    formNumberInputProps?: NumberInputProps,
    formCheckboxProps?: CheckboxProps,
    formSwitchProps?: SwitchProps,
    formSelectProps?: SelectProps,
    deleteButtonCellProps?: TableCellProps,
    functionToCallOnSubmitForm: (arg0: any) => any,
    formName: string,
}

export interface jsonObjectFormResponsiveRefFunctionType {
  getFormValues: (fieldName?:string) => UseFormGetValues<formSchemaType>,
  formState: FormState<formSchemaType>,
  reset: (resetValues?:formValueType[]) => void
}

export const JsonObjectFormResponsive = forwardRef(( props:JsonObjectFormResponsiveType, ref ) => {
  const { 
    formSchema, 
    isAddNewValueAllowed=true, 
    formValues, 
    formRowProps, 
    showDeleteButton=true,
    isSingleRow=false,
    deleteButtonProps,
    formCaption,
    formTableProps,
    formNumberInputProps,
    formCheckboxProps,
    formSwitchProps,
    formSelectProps,
    deleteButtonCellProps,
    functionToCallOnSubmitForm,
    formName,
    ...restProps 
  } = props

  let shouldShowAddNewValueSnippet = isAddNewValueAllowed
  let shouldShowDeleteButton = showDeleteButton

  if (isSingleRow) {
    shouldShowAddNewValueSnippet = false
    shouldShowDeleteButton = false
  }
  
  const getFieldObject = () => {
    let newFieldObject:formValueType = {}
    formSchema.map( (item) => {
      newFieldObject[item.fieldKey] = ''
      return true
    })
    return newFieldObject
  }

  const { register, control, handleSubmit, reset, formState, getValues } = useForm({
    defaultValues: {
      [formName]: (isSingleRow) ? ((formValues && formValues.length > 0) ? formValues : [getFieldObject()]) : (formValues || [])
    }
  })
  const { errors, isSubmitting } = formState
  
  const {
    fields,
    append,
    remove
  } = useFieldArray({
    control,
    name: formName
  });

  useImperativeHandle( ref, () => ({
    getFormValues: (fieldName?:string) => {
      if (fieldName) {
        return getValues(fieldName)
      } else {
        return getValues()
      }
    },
    formState,
    reset: (resetValues?:formValueType[]) => {
      if (resetValues) {
        return reset({
          [formName]: resetValues
        })
      } else {
        return reset()
      }
    }
  }))

  const deviceType = useBreakpointValue({ base: "portrait", md: "landscape" })
  type portraitTableDisplayModeType = 'HCard' | 'VCard' | 'Table'
  const [portraitTableDisplayMode, setPortraitTableDisplayMode] = useState<portraitTableDisplayModeType>('HCard')

  const handleAddNewButtonClick = () => {
    append(getFieldObject())
  }
  
  const resetFormToDefault = () => {
    reset()
  }  

  const onSubmit = async (data:any) => {
    await functionToCallOnSubmitForm(data)
  }

  if (deviceType==="landscape" || portraitTableDisplayMode === 'Table') {
    return (
      <Box {...restProps}>
        {
          (deviceType==="portrait" && portraitTableDisplayMode === 'Table') && 
          <Flex>
            <Spacer />
            <ToggleButtonGroup
                value={portraitTableDisplayMode}
                onChange={setPortraitTableDisplayMode}
                defaultValue="HCard"
                isAttached
                variant="outline"
                aria-label="Align text"
                mt={4}
            >
                <ToggleButton value="HCard" aria-label="Display data in horizontal card" icon={<FaThList />} />
                <ToggleButton value="VCard" aria-label="Display data in vertical card" icon={<FaServer />} />
                <ToggleButton value="Table" aria-label="Display data in table" icon={<FaTable />} />
            </ToggleButtonGroup>
          </Flex>
        }
        <form onSubmit={handleSubmit(onSubmit)}>
          <Table {...formTableProps}>
            {formCaption && <TableCaption>{formCaption}</TableCaption>}
            <Thead>
              <Tr>
                {
                  formSchema.map((
                    {
                      fieldType, 
                      fieldPlaceholder, 
                      fieldIsRequired, 
                      fieldKey, 
                      fieldSelectOptions, 
                      fieldName, 
                      fieldLabel,
                      fieldValidationRules,
                      fieldProps,
                      ...restProps
                    }, fieldIndex ) => {
                      return (
                        <Th key={fieldIndex}>
                          { fieldLabel }{ fieldIsRequired ? ' *' : '' }
                        </Th>
                      )
                    }
                  )
                }
                {
                  shouldShowDeleteButton &&
                  <Th>
                    Remove
                  </Th>
                }
              </Tr>
            </Thead>
            <Tbody>
              {fields.map((item, index) => {
                  return (
                    <Tr key={item.id} {...formRowProps}>
                      {
                        formSchema.map((
                          {
                            fieldType, 
                            fieldPlaceholder, 
                            fieldIsRequired, 
                            fieldKey, 
                            fieldSelectOptions, 
                            fieldName, 
                            fieldLabel,
                            fieldValidationRules,
                            fieldProps,
                            ...restProps
                          }, fieldIndex ) => { 
                          switch (fieldType) {
                            case 'text':
                              return (
                                <Td key={fieldIndex} {...fieldProps}>
                                  <FormControl
                                    isRequired={fieldIsRequired}
                                    isInvalid={(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? true : false}
                                  >
                                    <Input 
                                      key={item.id+fieldIndex}
                                      placeholder={fieldPlaceholder} 
                                      type='text' 
                                      isRequired={fieldIsRequired} 
                                      defaultValue={item[fieldKey] as stringOrNumber}
                                      {...register(`${formName}.${index}.${fieldKey}` as const, fieldValidationRules)} 
                                      {...restProps} 
                                    />
                                    <FormErrorMessage wordBreak='break-word'>{(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? errors[formName]![index]![fieldKey]?.message : null }</FormErrorMessage>
                                  </FormControl>
                                </Td>
                              )
  
                            case 'number':
                              const { size, ...numberRestProps } = restProps
                              return(
                                <Td key={fieldIndex} {...fieldProps}>
                                  <FormControl
                                    isRequired={fieldIsRequired}
                                    isInvalid={(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? true : false}
                                  >
                                    <NumberInput 
                                      key={item.id+fieldIndex}
                                      placeholder={fieldPlaceholder} 
                                      isRequired={fieldIsRequired} 
                                      size={size}
                                      defaultValue={ (item[fieldKey] === false || typeof item[fieldKey] === 'undefined' || item[fieldKey] === null || item[fieldKey] === 0 || item[fieldKey] === '' ) ? 0 : ( typeof item[fieldKey] === 'string') ? parseInt(item[fieldKey] as string) : (( typeof item[fieldKey] === 'number') ? item[fieldKey] as number : 0) }
                                      {...formNumberInputProps}
                                    >
                                      <NumberInputField {...register(`${formName}.${index}.${fieldKey}`, fieldValidationRules)} {...numberRestProps} />
                                      <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                      </NumberInputStepper>
                                    </NumberInput>
                                    <FormErrorMessage wordBreak='break-word'>{(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? errors[formName]![index]![fieldKey]?.message : null }</FormErrorMessage>
                                  </FormControl>
                                </Td>
                              )
  
                            case 'boolean':
                              return(
                                <Td key={fieldIndex} {...fieldProps}>
                                  <FormControl
                                    isRequired={fieldIsRequired}
                                    isInvalid={(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? true : false}
                                  >
                                    <Checkbox 
                                      key={item.id+fieldIndex}
                                      placeholder={fieldPlaceholder} 
                                      isRequired={fieldIsRequired} 
                                      defaultChecked={!(item[fieldKey] === false || typeof item[fieldKey] === 'undefined' || item[fieldKey] === null || item[fieldKey] === 0 || item[fieldKey] === '')}
                                      {...register(`${formName}.${index}.${fieldKey}`, fieldValidationRules)}
                                      {...formCheckboxProps}
                                    />
                                    <FormErrorMessage wordBreak='break-word'>{(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? errors[formName]![index]![fieldKey]?.message : null }</FormErrorMessage>
                                  </FormControl>
                                </Td>
                              )
  
                            case 'switch':
                              return(
                                <Td key={fieldIndex} {...fieldProps}>
                                  <FormControl
                                    isRequired={fieldIsRequired}
                                    isInvalid={(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? true : false}
                                  >
                                    <Switch 
                                      key={item.id+fieldIndex}
                                      placeholder={fieldPlaceholder} 
                                      isRequired={fieldIsRequired} 
                                      defaultChecked={!(item[fieldKey] === false || typeof item[fieldKey] === 'undefined' || item[fieldKey] === null || item[fieldKey] === 0)}
                                      {...register(`${formName}.${index}.${fieldKey}`, fieldValidationRules)}
                                      {...formSwitchProps}
                                    />
                                    <FormErrorMessage wordBreak='break-word'>{(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? errors[formName]![index]![fieldKey]?.message : null }</FormErrorMessage>
                                  </FormControl>
                                </Td>
                              )
  
                            case 'select':
                              return(
                                <Td key={fieldIndex} {...fieldProps}>
                                  <FormControl
                                    isRequired={fieldIsRequired}
                                    isInvalid={(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? true : false}
                                  >
                                    <Select 
                                      key={item.id+fieldIndex}
                                      placeholder={fieldPlaceholder} 
                                      isRequired={fieldIsRequired} 
                                      defaultValue={item[fieldKey] as string}
                                      {...register(`${formName}.${index}.${fieldKey}`, fieldValidationRules)}
                                      {...formSelectProps}
                                    >
                                      {
                                        fieldSelectOptions?.map((val, index) => {
                                          return <option key={index} value={val} >{val}</option>
                                        })
                                      }
                                    </Select>
                                    <FormErrorMessage wordBreak='break-word'>{(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? errors[formName]![index]![fieldKey]?.message : null }</FormErrorMessage>
                                  </FormControl>
                                </Td>
                              )
                          
                            default:
                              return (
                                <Td key={fieldIndex} {...fieldProps}>
                                  <FormControl
                                    isRequired={fieldIsRequired}
                                    isInvalid={(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? true : false}
                                  >
                                    <Input 
                                      key={item.id+fieldIndex}
                                      placeholder={fieldPlaceholder} 
                                      type='text' 
                                      isRequired={fieldIsRequired} 
                                      defaultValue={item[fieldKey] as stringOrNumber}
                                      {...register(`${formName}.${index}.${fieldKey}`, fieldValidationRules)} 
                                      {...restProps} 
                                    />
                                    <FormErrorMessage wordBreak='break-word'>{(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? errors[formName]![index]![fieldKey]?.message : null }</FormErrorMessage>
                                  </FormControl>
                                </Td>
                              )
                          }
                        })
                      }
                      {
                        shouldShowDeleteButton &&
                        <Td {...deleteButtonCellProps}>
                          <IconButton
                            aria-label='Remove Field'
                            icon={<FaTrash />}
                            colorScheme='red'
                            variant='outline'
                            onClick={() => remove(index)}
                            {...deleteButtonProps}
                          />
                        </Td>
                      }
                    </Tr>
                  )
                })
              }
            </Tbody>
          </Table>
          
          <Flex justifyContent='center'>
            <Button
              mt="5"
              type='submit'
              colorScheme="blue"
              minW="fit-content"
              maxW="xl"
              leftIcon={<FaChevronCircleUp />}
              isLoading={isSubmitting}
              loadingText='Saving...'
            >
              Save
            </Button>
            <Box mx='3' />
            <Button
              mt="5"
              colorScheme="red"
              minW="fit-content"
              maxW="xl"
              leftIcon={<FaHistory />}
              onClick={resetFormToDefault}
            >
              Reset
            </Button>
          </Flex>
        </form>
        {
          shouldShowAddNewValueSnippet && 
          <Flex
              alignItems="center"
              justifyContent="center"
          >
              <Button
                  mt="5"
                  colorScheme="blue"
                  minW="fit-content"
                  w="90%"
                  maxW="2xl"
                  leftIcon={<FaPlusSquare/>}
                  onClick={handleAddNewButtonClick}
              >
                  Add New Variable
              </Button>
          </Flex>
        }
      </Box>
    )
  } else {
    return (
      <>
        <Flex>
          <Spacer />
          <ToggleButtonGroup
              value={portraitTableDisplayMode}
              onChange={setPortraitTableDisplayMode}
              defaultValue="HCard"
              isAttached
              variant="outline"
              aria-label="Align text"
              mt={4}
          >
              <ToggleButton value="HCard" aria-label="Display data in horizontal card" icon={<FaThList />} />
              <ToggleButton value="VCard" aria-label="Display data in vertical card" icon={<FaServer />} />
              <ToggleButton value="Table" aria-label="Display data in table" icon={<FaTable />} />
          </ToggleButtonGroup>
        </Flex>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
              p={3}
              spacing={5}
          >
            {fields.map((item, index) => {
              return (
                <Box boxShadow="md" p={3} rounded="3xl" borderWidth={1} key={item.id} >
                  {
                    formSchema.map((
                      {
                        fieldType, 
                        fieldPlaceholder, 
                        fieldIsRequired, 
                        fieldKey, 
                        fieldSelectOptions, 
                        fieldName, 
                        fieldLabel,
                        fieldValidationRules,
                        fieldProps,
                        ...restProps
                      }, fieldIndex ) => (
                        (portraitTableDisplayMode === 'VCard') ?
                        (
                          <Stack spacing={0} key={`${item.id || index}-${fieldIndex}row`}>
                            <Flex direction="column" p="3" minH="60px" >
                                <Text fontWeight="bold">
                                  { fieldLabel }{ fieldIsRequired ? ' * : ' : ' : ' }
                                </Text>
                                {
                                  (fieldType === 'text') && 
                                  <FormControl
                                    isRequired={fieldIsRequired}
                                    isInvalid={(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? true : false}
                                  >
                                    <Input 
                                      key={item.id+fieldIndex}
                                      placeholder={fieldPlaceholder} 
                                      type='text' 
                                      isRequired={fieldIsRequired} 
                                      defaultValue={item[fieldKey] as stringOrNumber}
                                      {...register(`${formName}.${index}.${fieldKey}` as const, fieldValidationRules)} 
                                      {...restProps} 
                                    />
                                    <FormErrorMessage wordBreak='break-word'>{(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? errors[formName]![index]![fieldKey]?.message : null }</FormErrorMessage>
                                  </FormControl>
                                }
                                {
                                  (fieldType === 'number') && 
                                  <FormControl
                                    isRequired={fieldIsRequired}
                                    isInvalid={(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? true : false}
                                  >
                                    <NumberInput 
                                      key={item.id+fieldIndex}
                                      placeholder={fieldPlaceholder} 
                                      isRequired={fieldIsRequired} 
                                      size={restProps.size}
                                      defaultValue={ (item[fieldKey] === false || typeof item[fieldKey] === 'undefined' || item[fieldKey] === null || item[fieldKey] === 0 || item[fieldKey] === '' ) ? 0 : ( typeof item[fieldKey] === 'string') ? parseInt(item[fieldKey] as string) : (( typeof item[fieldKey] === 'number') ? item[fieldKey] as number : 0) }
                                      {...formNumberInputProps}
                                    >
                                      <NumberInputField {...register(`${formName}.${index}.${fieldKey}`, fieldValidationRules)} {...removeKeyFromObject(restProps, ['size'])} />
                                      <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                      </NumberInputStepper>
                                    </NumberInput>
                                    <FormErrorMessage wordBreak='break-word'>{(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? errors[formName]![index]![fieldKey]?.message : null }</FormErrorMessage>
                                  </FormControl>
                                }
                                {
                                  (fieldType === 'boolean') &&
                                  <FormControl
                                    isRequired={fieldIsRequired}
                                    isInvalid={(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? true : false}
                                  >
                                    <Checkbox 
                                      key={item.id+fieldIndex}
                                      placeholder={fieldPlaceholder} 
                                      isRequired={fieldIsRequired} 
                                      defaultChecked={!(item[fieldKey] === false || typeof item[fieldKey] === 'undefined' || item[fieldKey] === null || item[fieldKey] === 0 || item[fieldKey] === '')}
                                      {...register(`${formName}.${index}.${fieldKey}`, fieldValidationRules)}
                                      {...formCheckboxProps}
                                    />
                                    <FormErrorMessage wordBreak='break-word'>{(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? errors[formName]![index]![fieldKey]?.message : null }</FormErrorMessage>
                                  </FormControl>
                                }
                                {
                                  (fieldType === 'switch') &&
                                  <FormControl
                                    isRequired={fieldIsRequired}
                                    isInvalid={(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? true : false}
                                  >
                                    <Switch 
                                      key={item.id+fieldIndex}
                                      placeholder={fieldPlaceholder} 
                                      isRequired={fieldIsRequired} 
                                      defaultChecked={!(item[fieldKey] === false || typeof item[fieldKey] === 'undefined' || item[fieldKey] === null || item[fieldKey] === 0)}
                                      {...register(`${formName}.${index}.${fieldKey}`, fieldValidationRules)}
                                      {...formSwitchProps}
                                    />
                                    <FormErrorMessage wordBreak='break-word'>{(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? errors[formName]![index]![fieldKey]?.message : null }</FormErrorMessage>
                                  </FormControl>
                                }
                                {
                                  (fieldType === 'select') &&
                                  <FormControl
                                    isRequired={fieldIsRequired}
                                    isInvalid={(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? true : false}
                                  >
                                    <Select 
                                      key={item.id+fieldIndex}
                                      placeholder={fieldPlaceholder} 
                                      isRequired={fieldIsRequired} 
                                      defaultValue={item[fieldKey] as string}
                                      {...register(`${formName}.${index}.${fieldKey}`, fieldValidationRules)}
                                      {...formSelectProps}
                                    >
                                      {
                                        fieldSelectOptions?.map((val, index) => {
                                          return <option key={index} value={val} >{val}</option>
                                        })
                                      }
                                    </Select>
                                    <FormErrorMessage wordBreak='break-word'>{(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? errors[formName]![index]![fieldKey]?.message : null }</FormErrorMessage>
                                  </FormControl>
                                }
                            </Flex>
                            <Divider/>
                          </Stack>
                        )
                        :
                        <Stack spacing={0} key={`${item.id || index}-${fieldIndex}row`}>
                            <Flex as="dl" direction="row" p="3" minH="60px" alignItems="center">
                                <Flex as="dt" minW="100px" pr="3">
                                    <Text fontWeight="bold">
                                      { fieldLabel }{ fieldIsRequired ? ' * : ' : ' : ' }
                                    </Text>
                                </Flex>
                                <Divider orientation="vertical" h="40px" />
                                <Flex as="dd" flex="1" pl={3} wordBreak="break-all">
                                  {
                                    (fieldType === 'text') && 
                                    <FormControl
                                      isRequired={fieldIsRequired}
                                      isInvalid={(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? true : false}
                                    >
                                      <Input 
                                        key={item.id+fieldIndex}
                                        placeholder={fieldPlaceholder} 
                                        type='text' 
                                        isRequired={fieldIsRequired} 
                                        defaultValue={item[fieldKey] as stringOrNumber}
                                        {...register(`${formName}.${index}.${fieldKey}` as const, fieldValidationRules)} 
                                        {...restProps} 
                                      />
                                      <FormErrorMessage wordBreak='break-word'>{(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? errors[formName]![index]![fieldKey]?.message : null }</FormErrorMessage>
                                    </FormControl>
                                  }
                                  {
                                    (fieldType === 'number') && 
                                    <FormControl
                                      isRequired={fieldIsRequired}
                                      isInvalid={(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? true : false}
                                    >
                                      <NumberInput 
                                        key={item.id+fieldIndex}
                                        placeholder={fieldPlaceholder} 
                                        isRequired={fieldIsRequired} 
                                        size={restProps.size}
                                        defaultValue={ (item[fieldKey] === false || typeof item[fieldKey] === 'undefined' || item[fieldKey] === null || item[fieldKey] === 0 || item[fieldKey] === '' ) ? 0 : ( typeof item[fieldKey] === 'string') ? parseInt(item[fieldKey] as string) : (( typeof item[fieldKey] === 'number') ? item[fieldKey] as number : 0) }
                                        {...formNumberInputProps}
                                      >
                                        <NumberInputField {...register(`${formName}.${index}.${fieldKey}`, fieldValidationRules)} {...removeKeyFromObject(restProps, ['size'])} />
                                        <NumberInputStepper>
                                          <NumberIncrementStepper />
                                          <NumberDecrementStepper />
                                        </NumberInputStepper>
                                      </NumberInput>
                                      <FormErrorMessage wordBreak='break-word'>{(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? errors[formName]![index]![fieldKey]?.message : null }</FormErrorMessage>
                                    </FormControl>
                                  }
                                  {
                                    (fieldType === 'boolean') &&
                                    <FormControl
                                      isRequired={fieldIsRequired}
                                      isInvalid={(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? true : false}
                                    >
                                      <Checkbox 
                                        key={item.id+fieldIndex}
                                        placeholder={fieldPlaceholder} 
                                        isRequired={fieldIsRequired} 
                                        defaultChecked={!(item[fieldKey] === false || typeof item[fieldKey] === 'undefined' || item[fieldKey] === null || item[fieldKey] === 0 || item[fieldKey] === '')}
                                        {...register(`${formName}.${index}.${fieldKey}`, fieldValidationRules)}
                                        {...formCheckboxProps}
                                      />
                                      <FormErrorMessage wordBreak='break-word'>{(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? errors[formName]![index]![fieldKey]?.message : null }</FormErrorMessage>
                                    </FormControl>
                                  }
                                  {
                                    (fieldType === 'switch') &&
                                    <FormControl
                                      isRequired={fieldIsRequired}
                                      isInvalid={(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? true : false}
                                    >
                                      <Switch 
                                        key={item.id+fieldIndex}
                                        placeholder={fieldPlaceholder} 
                                        isRequired={fieldIsRequired} 
                                        defaultChecked={!(item[fieldKey] === false || typeof item[fieldKey] === 'undefined' || item[fieldKey] === null || item[fieldKey] === 0)}
                                        {...register(`${formName}.${index}.${fieldKey}`, fieldValidationRules)}
                                        {...formSwitchProps}
                                      />
                                      <FormErrorMessage wordBreak='break-word'>{(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? errors[formName]![index]![fieldKey]?.message : null }</FormErrorMessage>
                                    </FormControl>
                                  }
                                  {
                                    (fieldType === 'select') &&
                                    <FormControl
                                      isRequired={fieldIsRequired}
                                      isInvalid={(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? true : false}
                                    >
                                      <Select 
                                        key={item.id+fieldIndex}
                                        placeholder={fieldPlaceholder} 
                                        isRequired={fieldIsRequired} 
                                        defaultValue={item[fieldKey] as string}
                                        {...register(`${formName}.${index}.${fieldKey}`, fieldValidationRules)}
                                        {...formSelectProps}
                                      >
                                        {
                                          fieldSelectOptions?.map((val, index) => {
                                            return <option key={index} value={val} >{val}</option>
                                          })
                                        }
                                      </Select>
                                      <FormErrorMessage wordBreak='break-word'>{(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? errors[formName]![index]![fieldKey]?.message : null }</FormErrorMessage>
                                    </FormControl>
                                  }
                                </Flex>
                            </Flex>
                            <Divider/>
                        </Stack>
                      ))
                  }
                  {
                    shouldShowDeleteButton &&
                    (portraitTableDisplayMode === 'VCard') ?
                    <Stack spacing={0} key={`${item.id || index}-removeRow`}>
                        <Flex direction="column" p="3" minH="60px" >
                            <Text fontWeight="bold">
                                Remove : 
                            </Text>
                            <IconButton
                              aria-label='Remove Field'
                              icon={<FaTrash />}
                              colorScheme='red'
                              variant='outline'
                              onClick={() => remove(index)}
                              {...deleteButtonProps}
                            />
                        </Flex>
                    </Stack>
                    :
                    <Stack spacing={0} key={`${item.id || index}-removeRow`}>
                        <Flex as="dl" direction="row" p="3" minH="60px" alignItems="center">
                            <Flex as="dt" minW="100px" pr="3">
                                <Text fontWeight="bold">
                                  Remove : 
                                </Text>
                            </Flex>
                            <Divider orientation="vertical" h="40px" />
                            <Flex as="dd" flex="1" pl={3} wordBreak="break-all">
                              <IconButton
                                aria-label='Remove Field'
                                icon={<FaTrash />}
                                colorScheme='red'
                                variant='outline'
                                onClick={() => remove(index)}
                                {...deleteButtonProps}
                              />
                            </Flex>
                        </Flex>
                    </Stack>
                  }
                </Box>
              )
            })
            }
          </Stack>
          <Flex justifyContent='center'>
            <Button
              mt="5"
              type='submit'
              colorScheme="blue"
              minW="fit-content"
              maxW="xl"
              leftIcon={<FaChevronCircleUp />}
              isLoading={isSubmitting}
              loadingText='Saving...'
            >
              Save
            </Button>
            <Box mx='3' />
            <Button
              mt="5"
              colorScheme="red"
              minW="fit-content"
              maxW="xl"
              leftIcon={<FaHistory />}
              onClick={resetFormToDefault}
            >
              Reset
            </Button>
          </Flex>
        </form>
        {
          shouldShowAddNewValueSnippet && 
          <Flex
              alignItems="center"
              justifyContent="center"
          >
              <Button
                  mt="5"
                  colorScheme="blue"
                  minW="fit-content"
                  w="90%"
                  maxW="2xl"
                  leftIcon={<FaPlusSquare/>}
                  onClick={handleAddNewButtonClick}
              >
                  Add New Variable
              </Button>
          </Flex>
        }
      </>
    )
  }
})

export const JsonObjectFormResponsiveMemoized = memo(JsonObjectFormResponsive)
import { SetStateAction, State, useState } from "@hookstate/core"
import { Dispatch } from "react"

type SetInitialStateAction<S> = S | Promise<S> | (() => S | Promise<S>)

export function useHookState<S> (initialVal: SetInitialStateAction<S>):[S, Dispatch<SetStateAction<S>>, State<S>] {
    const state = useState(initialVal)

    return [
        state.get(),
        (val: any) => state.set(val),
        state
    ]
}
import { Button, Flex, Skeleton, Stack, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import { FaPlusSquare } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ErrorComponent } from '../../components/errorComponent/ErrorComponent'
import { getSmtpSettingsData, insertSmtpSettingsData, updateSmtpSettingsData } from '../../dataFetchers/smtpSettingsDataFetcher'
import { smtpSettingsType } from '../../types/dataFetcherTypes/SmtpSettingsDataFetcherTypes'
import { CustomSmtpForm } from './CustomSmtpForm'

export const CustomSmtp = () => {
    const queryClient = useQueryClient()
    const { isError, isLoading, data, error } = useQuery('smtpSettings', getSmtpSettingsData)
    const [smtpFormDataWraper, setSmtpFormDataWraper] = useState<Array<smtpSettingsType> | null>([])
    
    const insertMutation = useMutation(insertSmtpSettingsData)
    const updateMutation = useMutation(updateSmtpSettingsData)

    const saveSmtpData = async (values:smtpSettingsType) => {
        if (values.id) {
            await updateMutation.mutateAsync(values)
        } else {
            await insertMutation.mutateAsync(values)
        }
        setSmtpFormDataWraper([])
        queryClient.invalidateQueries('smtpSettings')
    }

    const handleAddNewButtonClick = () => {
        setSmtpFormDataWraper([
            {
                name: '',
                email: '',
                host: '',
                port: 587,
                user: '',
                password: '',
                senderName: '',
                isDefault: false
            }
        ])
    }

    if ( isError ) {
        return (
            <ErrorComponent
                //@ts-ignore
                errorMessage={error.message}
            />
        )
    }

    if (data && data.isQueryError === false) {
        const { queryData, queryError } = data

        if ( queryError ) {
            return (
                <ErrorComponent
                    errorMessage={queryError.message}
                />
            )
        }

        return (
            <Skeleton isLoaded={!isLoading} w="100%">
                <Flex direction="column">
                    <VStack spacing="6">
                        {
                            [...queryData!, ...smtpFormDataWraper!].map( item => {
                                return (
                                    <CustomSmtpForm
                                        key={item.id}
                                        formValues={item}
                                        onSubmit={saveSmtpData} 
                                    />
                                )
                            })
                        }
                    </VStack>
                    <Flex
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Button
                            mt="5"
                            colorScheme="blue"
                            minW="fit-content"
                            w="90%"
                            maxW="2xl"
                            leftIcon={<FaPlusSquare/>}
                            onClick={handleAddNewButtonClick}
                        >
                            Add New SMTP
                        </Button>
                    </Flex>
                </Flex>
            </Skeleton>
        )
    }
    if ( isLoading ) {
        return (
            <Stack mt={10} spacing={5} >
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
            </Stack>
        )
    }

    return (
        <ErrorComponent
        />
    )
}

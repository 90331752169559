import { Box, Flex, Stack, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'
import {  BsFillInboxFill } from 'react-icons/bs'
import { HiTemplate } from 'react-icons/hi'
import { VscSymbolVariable } from 'react-icons/vsc'
import { Redirect, useHistory } from 'react-router'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { MobileMenuButton } from '../../components/MobileMenuButton/MobileMenuButton'
import { NavSectionTitle } from '../../components/NavSectionTitle/NavSectionTitle'
import { ScrollArea } from '../../components/ScrollArea/ScrollArea'
import { SidebarLink } from '../../components/SidebarLink/SidebarLink'
import { SIDEBAR_CONFIG } from '../../configs/LayoutConfigs'
import { useMobileMenuState } from '../../hooks/useMobileMenuState'
import { CustomSmtp } from './CustomSmtp'
import { EmailTemplates } from './EmailTemplates'
import { GlobalVariables } from './GlobalVariables'
import { ZoomSettings } from './ZoomSettings'

export const Settings = () => {
  const { isOpen, toggle } = useMobileMenuState()
  const currentPath = window.location.pathname
  
  const history = useHistory()
  const match = useRouteMatch();
  
  return (
    <Flex
      bg={mode('gray.50', 'gray.800')}
      sx={{ '--sidebar-width': '12rem' }}
    >
      <Box
        as="nav"
        display={[(isOpen? 'block' : 'none'), 'block']}
        flex="1"
        width="var(--sidebar-width)"
        left="0"
        py="5"
        px="3"
        color={mode('gray.700', 'gray.200')}
        position="fixed"
        ml={{
          base: undefined,
          md: `${SIDEBAR_CONFIG.WIDE_SCREEN_VIEW.SIDE_NAVBAR_WIDTH}rem`
        }}
      >
        <Box fontSize="sm" lineHeight="tall">
          <ScrollArea pt="5" pb="6">
            <Stack pb="6">
              <NavSectionTitle>E-Mails</NavSectionTitle>
              <SidebarLink 
                active={(currentPath.replace(match.path, '').replace('/', '') === 'customSmtp')}
                icon={<BsFillInboxFill />} 
                onClick={ () => {
                  history.push('/dashboard/settings/customSmtp')
                }}>
                  SMTP
              </SidebarLink>
              <SidebarLink 
                active={(currentPath.replace(match.path, '').replace('/', '') === 'templates')}
                icon={<HiTemplate />} 
                onClick={ () => {
                  history.push('/dashboard/settings/templates')
                }}>
                  Templates
              </SidebarLink>
              <NavSectionTitle>Configs</NavSectionTitle>
              <SidebarLink 
                active={(currentPath.replace(match.path, '').replace('/', '') === 'globalVariables')}
                icon={<VscSymbolVariable />} 
                onClick={ () => {
                  history.push('/dashboard/settings/globalVariables')
                }}>
                  Global Variables
              </SidebarLink>
              <SidebarLink 
                active={(currentPath.replace(match.path, '').replace('/', '') === 'zoomSettings')}
                icon={<VscSymbolVariable />} 
                onClick={ () => {
                  history.push('/dashboard/settings/zoomSettings')
                }}>
                  Zoom Settings
              </SidebarLink>
            </Stack>
          </ScrollArea>
        </Box>
      </Box>
      <Box
        flex="1"
        marginStart={{ md: 'var(--sidebar-width)' }}
        position="relative"
        left={isOpen ? 'var(--sidebar-width)' : '0'}
        transition="left 0.2s"
      >
        <Box
          maxW="2560px"
          bg={mode('white', 'gray.700')}
          height="100%"
          pb="6"
          rounded={{ md: 'lg' }}
        >
          <Flex w="full" py="4" justify="space-between" align="center" px="10">
            <Flex align="center" minH="8">
              <MobileMenuButton onClick={toggle} isOpen={isOpen} />
            </Flex>
          </Flex>
          <Flex direction="column" flex="1" p={[3,3,9,9]} mt={[0, -12]}>
            <Switch>
              <Route path={`${match.path}/customSmtp`} component={CustomSmtp} />
              <Route path={`${match.path}/templates`} component={EmailTemplates} />
              <Route path={`${match.path}/globalVariables`} component={GlobalVariables} />
              <Route path={`${match.path}/zoomSettings`} component={ZoomSettings} />
              <Redirect from={`${match.path}/`} to={`${match.path}/customSmtp`} />
            </Switch>
          </Flex>
        </Box>
      </Box>
    </Flex>
  )
}

import supabase from "../configs/Supabase";
import { searchDataObjectReturnType } from "../types/dataFetcherTypes/EventsDataFetcherTypes";

export const getSearchData = async ( fromTable:string, fetchKey:string, searchText:string ): Promise<searchDataObjectReturnType> => {
    try {
        let isError = false
        const { data, error, count } = await supabase
                                .from( fromTable )
                                .select( `
                                    *
                                `, {
                                    count: "exact"
                                })
                                .eq('is_deleted', false)
                                .ilike(fetchKey, `%${searchText}%`)
                                .limit(10)
        if (error) {
            isError = true
        }

        return {
            isQueryError: isError,
            queryData: data,
            queryError: error,
            querySize: count,
        }
    } catch (error) {
        throw error;  
    }
}
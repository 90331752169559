import React from "react";
import { Text, Stack, Box, Center } from "@chakra-ui/react";

const EmptyPane: React.FC = () => (
	<Box padding='6' boxShadow='lg'>
		<Center>
			<Stack>
				<Text fontSize='xl'>No elements selected</Text>
				<Text fontSize='md' color='gray'>
					Click on any element to edit its properties.
				</Text>
			</Stack>
		</Center>
	</Box>
);

export default EmptyPane;

import { BoxProps, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import React, { cloneElement, forwardRef, ReactElement, useImperativeHandle } from 'react'

export type forceDrawerStateType = "open" | "close" | null | undefined
export interface modalComponentInputPropsType extends BoxProps {
    triggerComponent: ReactElement
    heading: string
    showFooter?: boolean
    footerButtons?: ReactElement
    forceDrawerState?: forceDrawerStateType
}
export interface modalComponentRefFunctionType {
    onOpen: () => void,
    onClose: () => void,
}

export const ModalComponent = () => {
    const { isOpen, onClose } = useDisclosure()
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Quota Exceed</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    Quota for your current plan exceed. Please upgrade your plan
                </ModalBody>

                <ModalFooter>
                    <Button  variant="ghost" mr={3} onClick={onClose}>
                        Close
                    </Button>
                    <Button colorScheme="blue">View Plans</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export const ModalComponentWithRef = forwardRef(( props:modalComponentInputPropsType, ref) => {
    const { triggerComponent, children, heading, showFooter, footerButtons, forceDrawerState, ...restProps } = props
    const { isOpen, onOpen, onClose } = useDisclosure()

    const triggerComponentWithInjectedProps = cloneElement( triggerComponent, { onClick: () => {
        if ( triggerComponent.props.onClick ) {
            triggerComponent.props.onClick()
        }
        onOpen()
    }})

    useImperativeHandle( ref, () => ({
        onClose: onClose,
        onOpen: onOpen
    }))

    return (
        <>
            { triggerComponentWithInjectedProps }
            <Modal isOpen={isOpen} onClose={onClose} { ...restProps } >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader> { heading } </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        { children }
                    </ModalBody>
                    {
                        showFooter &&
                        <ModalFooter>
                            <Button variant="ghost" mr={3} onClick={onClose}>
                                Cancel
                            </Button>
                            { footerButtons }
                        </ModalFooter>
                    }
                </ModalContent>
            </Modal>
        </>
    )
})


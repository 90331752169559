import { Flex, useColorModeValue, BoxProps } from '@chakra-ui/react'
import React from 'react'
import { HEADER_NAVBAR_HEIGHT, SIDEBAR_CONFIG } from '../../configs/LayoutConfigs'

export const SidebarSection = ( props: BoxProps ) => {
    return (
        <Flex
            direction={{
                base: "row",
                md: "column"
            }}
            h={{
                base: `${SIDEBAR_CONFIG.MOBILE_VIEW.BOTTOM_NAVBAR_HEIGHT}rem`,
                md: `calc(100vh - ${HEADER_NAVBAR_HEIGHT}rem)`
            }}
            w={{
                base: '100vw',
                md: `${SIDEBAR_CONFIG.WIDE_SCREEN_VIEW.SIDE_NAVBAR_WIDTH}rem`
            }}
            position="fixed"
            top={{
                base: undefined,
                md: `${HEADER_NAVBAR_HEIGHT}rem`
            }}
            bottom={{
                base: 0,
                md: undefined
            }}
            borderRightWidth={{
                base: undefined,
                md: 1
            }}
            borderTopWidth={{
                base: 1,
                md: 0
            }}
            shadow="md"
            bg={useColorModeValue('white', 'gray.700')}
            justifyContent={{
                base:"space-around",
                md: "normal"
            }}
            zIndex={99}
            {...props}
        />
    )
}

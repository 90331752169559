export const countryStateDataObject = [
    {
        "name": "Afghanistan",
        "iso3": "AFG",
        "iso2": "AF",
        "phone_code": "93",
        "capital": "Kabul",
        "currency": "AFN",
        "currency_symbol": "؋",
        "tld": ".af",
        "native": "افغانستان",
        "region": "Asia",
        "subregion": "Southern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Kabul",
                "gmtOffset": 16200,
                "gmtOffsetName": "UTC+04:30",
                "abbreviation": "AFT",
                "tzName": "Afghanistan Time"
            }
        ],
        "translations": {
            "br": "Afeganistão",
            "pt": "Afeganistão",
            "nl": "Afghanistan",
            "hr": "Afganistan",
            "fa": "افغانستان",
            "de": "Afghanistan",
            "es": "Afganistán",
            "fr": "Afghanistan",
            "ja": "アフガニスタン",
            "it": "Afghanistan"
        },
        "latitude": "33.00000000",
        "longitude": "65.00000000",
        "emoji": "🇦🇫",
        "emojiU": "U+1F1E6 U+1F1EB",
        "states": [
            {
                "id": 3901,
                "name": "Badakhshan",
                "state_code": "BDS"
            },
            {
                "id": 3871,
                "name": "Badghis",
                "state_code": "BDG"
            },
            {
                "id": 3875,
                "name": "Baghlan",
                "state_code": "BGL"
            },
            {
                "id": 3884,
                "name": "Balkh",
                "state_code": "BAL"
            },
            {
                "id": 3872,
                "name": "Bamyan",
                "state_code": "BAM"
            },
            {
                "id": 3892,
                "name": "Daykundi",
                "state_code": "DAY"
            },
            {
                "id": 3899,
                "name": "Farah",
                "state_code": "FRA"
            },
            {
                "id": 3889,
                "name": "Faryab",
                "state_code": "FYB"
            },
            {
                "id": 3870,
                "name": "Ghazni",
                "state_code": "GHA"
            },
            {
                "id": 3888,
                "name": "Ghōr",
                "state_code": "GHO"
            },
            {
                "id": 3873,
                "name": "Helmand",
                "state_code": "HEL"
            },
            {
                "id": 3887,
                "name": "Herat",
                "state_code": "HER"
            },
            {
                "id": 3886,
                "name": "Jowzjan",
                "state_code": "JOW"
            },
            {
                "id": 3902,
                "name": "Kabul",
                "state_code": "KAB"
            },
            {
                "id": 3890,
                "name": "Kandahar",
                "state_code": "KAN"
            },
            {
                "id": 3879,
                "name": "Kapisa",
                "state_code": "KAP"
            },
            {
                "id": 3878,
                "name": "Khost",
                "state_code": "KHO"
            },
            {
                "id": 3876,
                "name": "Kunar",
                "state_code": "KNR"
            },
            {
                "id": 3900,
                "name": "Kunduz Province",
                "state_code": "KDZ"
            },
            {
                "id": 3891,
                "name": "Laghman",
                "state_code": "LAG"
            },
            {
                "id": 3897,
                "name": "Logar",
                "state_code": "LOG"
            },
            {
                "id": 3882,
                "name": "Nangarhar",
                "state_code": "NAN"
            },
            {
                "id": 3896,
                "name": "Nimruz",
                "state_code": "NIM"
            },
            {
                "id": 3880,
                "name": "Nuristan",
                "state_code": "NUR"
            },
            {
                "id": 3894,
                "name": "Paktia",
                "state_code": "PIA"
            },
            {
                "id": 3877,
                "name": "Paktika",
                "state_code": "PKA"
            },
            {
                "id": 3881,
                "name": "Panjshir",
                "state_code": "PAN"
            },
            {
                "id": 3895,
                "name": "Parwan",
                "state_code": "PAR"
            },
            {
                "id": 3883,
                "name": "Samangan",
                "state_code": "SAM"
            },
            {
                "id": 3885,
                "name": "Sar-e Pol",
                "state_code": "SAR"
            },
            {
                "id": 3893,
                "name": "Takhar",
                "state_code": "TAK"
            },
            {
                "id": 3898,
                "name": "Urozgan",
                "state_code": "URU"
            },
            {
                "id": 3874,
                "name": "Zabul",
                "state_code": "ZAB"
            }
        ]
    },
    {
        "name": "Aland Islands",
        "iso3": "ALA",
        "iso2": "AX",
        "phone_code": "+358-18",
        "capital": "Mariehamn",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".ax",
        "native": "Åland",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Mariehamn",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "translations": {
            "br": "Ilhas de Aland",
            "pt": "Ilhas de Aland",
            "nl": "Ålandeilanden",
            "hr": "Ålandski otoci",
            "fa": "جزایر الند",
            "de": "Åland",
            "es": "Alandia",
            "fr": "Åland",
            "ja": "オーランド諸島",
            "it": "Isole Aland"
        },
        "latitude": "60.11666700",
        "longitude": "19.90000000",
        "emoji": "🇦🇽",
        "emojiU": "U+1F1E6 U+1F1FD",
        "states": []
    },
    {
        "name": "Albania",
        "iso3": "ALB",
        "iso2": "AL",
        "phone_code": "355",
        "capital": "Tirana",
        "currency": "ALL",
        "currency_symbol": "Lek",
        "tld": ".al",
        "native": "Shqipëria",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Tirane",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Albânia",
            "pt": "Albânia",
            "nl": "Albanië",
            "hr": "Albanija",
            "fa": "آلبانی",
            "de": "Albanien",
            "es": "Albania",
            "fr": "Albanie",
            "ja": "アルバニア",
            "it": "Albania"
        },
        "latitude": "41.00000000",
        "longitude": "20.00000000",
        "emoji": "🇦🇱",
        "emojiU": "U+1F1E6 U+1F1F1",
        "states": [
            {
                "id": 603,
                "name": "Berat County",
                "state_code": "01"
            },
            {
                "id": 629,
                "name": "Berat District",
                "state_code": "BR"
            },
            {
                "id": 607,
                "name": "Bulqizë District",
                "state_code": "BU"
            },
            {
                "id": 618,
                "name": "Delvinë District",
                "state_code": "DL"
            },
            {
                "id": 608,
                "name": "Devoll District",
                "state_code": "DV"
            },
            {
                "id": 610,
                "name": "Dibër County",
                "state_code": "09"
            },
            {
                "id": 605,
                "name": "Dibër District",
                "state_code": "DI"
            },
            {
                "id": 632,
                "name": "Durrës County",
                "state_code": "02"
            },
            {
                "id": 639,
                "name": "Durrës District",
                "state_code": "DR"
            },
            {
                "id": 598,
                "name": "Elbasan County",
                "state_code": "03"
            },
            {
                "id": 631,
                "name": "Fier County",
                "state_code": "04"
            },
            {
                "id": 627,
                "name": "Fier District",
                "state_code": "FR"
            },
            {
                "id": 604,
                "name": "Gjirokastër County",
                "state_code": "05"
            },
            {
                "id": 621,
                "name": "Gjirokastër District",
                "state_code": "GJ"
            },
            {
                "id": 617,
                "name": "Gramsh District",
                "state_code": "GR"
            },
            {
                "id": 600,
                "name": "Has District",
                "state_code": "HA"
            },
            {
                "id": 594,
                "name": "Kavajë District",
                "state_code": "KA"
            },
            {
                "id": 628,
                "name": "Kolonjë District",
                "state_code": "ER"
            },
            {
                "id": 630,
                "name": "Korçë County",
                "state_code": "06"
            },
            {
                "id": 597,
                "name": "Korçë District",
                "state_code": "KO"
            },
            {
                "id": 614,
                "name": "Krujë District",
                "state_code": "KR"
            },
            {
                "id": 612,
                "name": "Kuçovë District",
                "state_code": "KC"
            },
            {
                "id": 601,
                "name": "Kukës County",
                "state_code": "07"
            },
            {
                "id": 623,
                "name": "Kukës District",
                "state_code": "KU"
            },
            {
                "id": 622,
                "name": "Kurbin District",
                "state_code": "KB"
            },
            {
                "id": 609,
                "name": "Lezhë County",
                "state_code": "08"
            },
            {
                "id": 595,
                "name": "Lezhë District",
                "state_code": "LE"
            },
            {
                "id": 596,
                "name": "Librazhd District",
                "state_code": "LB"
            },
            {
                "id": 599,
                "name": "Lushnjë District",
                "state_code": "LU"
            },
            {
                "id": 602,
                "name": "Malësi e Madhe District",
                "state_code": "MM"
            },
            {
                "id": 637,
                "name": "Mallakastër District",
                "state_code": "MK"
            },
            {
                "id": 635,
                "name": "Mat District",
                "state_code": "MT"
            },
            {
                "id": 638,
                "name": "Mirditë District",
                "state_code": "MR"
            },
            {
                "id": 619,
                "name": "Peqin District",
                "state_code": "PQ"
            },
            {
                "id": 625,
                "name": "Përmet District",
                "state_code": "PR"
            },
            {
                "id": 606,
                "name": "Pogradec District",
                "state_code": "PG"
            },
            {
                "id": 620,
                "name": "Pukë District",
                "state_code": "PU"
            },
            {
                "id": 624,
                "name": "Sarandë District",
                "state_code": "SR"
            },
            {
                "id": 611,
                "name": "Shkodër County",
                "state_code": "10"
            },
            {
                "id": 626,
                "name": "Shkodër District",
                "state_code": "SH"
            },
            {
                "id": 593,
                "name": "Skrapar District",
                "state_code": "SK"
            },
            {
                "id": 616,
                "name": "Tepelenë District",
                "state_code": "TE"
            },
            {
                "id": 615,
                "name": "Tirana County",
                "state_code": "11"
            },
            {
                "id": 633,
                "name": "Tirana District",
                "state_code": "TR"
            },
            {
                "id": 636,
                "name": "Tropojë District",
                "state_code": "TP"
            },
            {
                "id": 634,
                "name": "Vlorë County",
                "state_code": "12"
            },
            {
                "id": 613,
                "name": "Vlorë District",
                "state_code": "VL"
            }
        ]
    },
    {
        "name": "Algeria",
        "iso3": "DZA",
        "iso2": "DZ",
        "phone_code": "213",
        "capital": "Algiers",
        "currency": "DZD",
        "currency_symbol": "دج",
        "tld": ".dz",
        "native": "الجزائر",
        "region": "Africa",
        "subregion": "Northern Africa",
        "timezones": [
            {
                "zoneName": "Africa/Algiers",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Argélia",
            "pt": "Argélia",
            "nl": "Algerije",
            "hr": "Alžir",
            "fa": "الجزایر",
            "de": "Algerien",
            "es": "Argelia",
            "fr": "Algérie",
            "ja": "アルジェリア",
            "it": "Algeria"
        },
        "latitude": "28.00000000",
        "longitude": "3.00000000",
        "emoji": "🇩🇿",
        "emojiU": "U+1F1E9 U+1F1FF",
        "states": [
            {
                "id": 1118,
                "name": "Adrar",
                "state_code": "01"
            },
            {
                "id": 1119,
                "name": "Aïn Defla",
                "state_code": "44"
            },
            {
                "id": 1122,
                "name": "Aïn Témouchent",
                "state_code": "46"
            },
            {
                "id": 1144,
                "name": "Algiers",
                "state_code": "16"
            },
            {
                "id": 1103,
                "name": "Annaba",
                "state_code": "23"
            },
            {
                "id": 1142,
                "name": "Batna",
                "state_code": "05"
            },
            {
                "id": 1108,
                "name": "Béchar",
                "state_code": "08"
            },
            {
                "id": 1128,
                "name": "Béjaïa",
                "state_code": "06"
            },
            {
                "id": 4909,
                "name": "Béni Abbès",
                "state_code": "53"
            },
            {
                "id": 1114,
                "name": "Biskra",
                "state_code": "07"
            },
            {
                "id": 1111,
                "name": "Blida",
                "state_code": "09"
            },
            {
                "id": 4908,
                "name": "Bordj Baji Mokhtar",
                "state_code": "52"
            },
            {
                "id": 1116,
                "name": "Bordj Bou Arréridj",
                "state_code": "34"
            },
            {
                "id": 1104,
                "name": "Bouïra",
                "state_code": "10"
            },
            {
                "id": 1125,
                "name": "Boumerdès",
                "state_code": "35"
            },
            {
                "id": 1105,
                "name": "Chlef",
                "state_code": "02"
            },
            {
                "id": 1121,
                "name": "Constantine",
                "state_code": "25"
            },
            {
                "id": 4912,
                "name": "Djanet",
                "state_code": "56"
            },
            {
                "id": 1098,
                "name": "Djelfa",
                "state_code": "17"
            },
            {
                "id": 1129,
                "name": "El Bayadh",
                "state_code": "32"
            },
            {
                "id": 4905,
                "name": "El M'ghair",
                "state_code": "49"
            },
            {
                "id": 4906,
                "name": "El Menia",
                "state_code": "50"
            },
            {
                "id": 1099,
                "name": "El Oued",
                "state_code": "39"
            },
            {
                "id": 1100,
                "name": "El Tarf",
                "state_code": "36"
            },
            {
                "id": 1127,
                "name": "Ghardaïa",
                "state_code": "47"
            },
            {
                "id": 1137,
                "name": "Guelma",
                "state_code": "24"
            },
            {
                "id": 1112,
                "name": "Illizi",
                "state_code": "33"
            },
            {
                "id": 4914,
                "name": "In Guezzam",
                "state_code": "58"
            },
            {
                "id": 4913,
                "name": "In Salah",
                "state_code": "57"
            },
            {
                "id": 1113,
                "name": "Jijel",
                "state_code": "18"
            },
            {
                "id": 1126,
                "name": "Khenchela",
                "state_code": "40"
            },
            {
                "id": 1138,
                "name": "Laghouat",
                "state_code": "03"
            },
            {
                "id": 1134,
                "name": "M'Sila",
                "state_code": "28"
            },
            {
                "id": 1124,
                "name": "Mascara",
                "state_code": "29"
            },
            {
                "id": 1109,
                "name": "Médéa",
                "state_code": "26"
            },
            {
                "id": 1132,
                "name": "Mila",
                "state_code": "43"
            },
            {
                "id": 1140,
                "name": "Mostaganem",
                "state_code": "27"
            },
            {
                "id": 1102,
                "name": "Naama",
                "state_code": "45"
            },
            {
                "id": 1101,
                "name": "Oran",
                "state_code": "31"
            },
            {
                "id": 1139,
                "name": "Ouargla",
                "state_code": "30"
            },
            {
                "id": 4907,
                "name": "Ouled Djellal",
                "state_code": "51"
            },
            {
                "id": 1136,
                "name": "Oum El Bouaghi",
                "state_code": "04"
            },
            {
                "id": 1130,
                "name": "Relizane",
                "state_code": "48"
            },
            {
                "id": 1123,
                "name": "Saïda",
                "state_code": "20"
            },
            {
                "id": 1141,
                "name": "Sétif",
                "state_code": "19"
            },
            {
                "id": 4902,
                "name": "Sidi Bel Abbès",
                "state_code": "22"
            },
            {
                "id": 1110,
                "name": "Skikda",
                "state_code": "21"
            },
            {
                "id": 1143,
                "name": "Souk Ahras",
                "state_code": "41"
            },
            {
                "id": 1135,
                "name": "Tamanghasset",
                "state_code": "11"
            },
            {
                "id": 1117,
                "name": "Tébessa",
                "state_code": "12"
            },
            {
                "id": 1106,
                "name": "Tiaret",
                "state_code": "14"
            },
            {
                "id": 4910,
                "name": "Timimoun",
                "state_code": "54"
            },
            {
                "id": 1120,
                "name": "Tindouf",
                "state_code": "37"
            },
            {
                "id": 1115,
                "name": "Tipasa",
                "state_code": "42"
            },
            {
                "id": 1133,
                "name": "Tissemsilt",
                "state_code": "38"
            },
            {
                "id": 1131,
                "name": "Tizi Ouzou",
                "state_code": "15"
            },
            {
                "id": 1107,
                "name": "Tlemcen",
                "state_code": "13"
            },
            {
                "id": 4911,
                "name": "Touggourt",
                "state_code": "55"
            }
        ]
    },
    {
        "name": "American Samoa",
        "iso3": "ASM",
        "iso2": "AS",
        "phone_code": "+1-684",
        "capital": "Pago Pago",
        "currency": "USD",
        "currency_symbol": "$",
        "tld": ".as",
        "native": "American Samoa",
        "region": "Oceania",
        "subregion": "Polynesia",
        "timezones": [
            {
                "zoneName": "Pacific/Pago_Pago",
                "gmtOffset": -39600,
                "gmtOffsetName": "UTC-11:00",
                "abbreviation": "SST",
                "tzName": "Samoa Standard Time"
            }
        ],
        "translations": {
            "br": "Samoa Americana",
            "pt": "Samoa Americana",
            "nl": "Amerikaans Samoa",
            "hr": "Američka Samoa",
            "fa": "ساموآی آمریکا",
            "de": "Amerikanisch-Samoa",
            "es": "Samoa Americana",
            "fr": "Samoa américaines",
            "ja": "アメリカ領サモア",
            "it": "Samoa Americane"
        },
        "latitude": "-14.33333333",
        "longitude": "-170.00000000",
        "emoji": "🇦🇸",
        "emojiU": "U+1F1E6 U+1F1F8",
        "states": []
    },
    {
        "name": "Andorra",
        "iso3": "AND",
        "iso2": "AD",
        "phone_code": "376",
        "capital": "Andorra la Vella",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".ad",
        "native": "Andorra",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Andorra",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Andorra",
            "pt": "Andorra",
            "nl": "Andorra",
            "hr": "Andora",
            "fa": "آندورا",
            "de": "Andorra",
            "es": "Andorra",
            "fr": "Andorre",
            "ja": "アンドラ",
            "it": "Andorra"
        },
        "latitude": "42.50000000",
        "longitude": "1.50000000",
        "emoji": "🇦🇩",
        "emojiU": "U+1F1E6 U+1F1E9",
        "states": [
            {
                "id": 488,
                "name": "Andorra la Vella",
                "state_code": "07"
            },
            {
                "id": 489,
                "name": "Canillo",
                "state_code": "02"
            },
            {
                "id": 487,
                "name": "Encamp",
                "state_code": "03"
            },
            {
                "id": 492,
                "name": "Escaldes-Engordany",
                "state_code": "08"
            },
            {
                "id": 493,
                "name": "La Massana",
                "state_code": "04"
            },
            {
                "id": 491,
                "name": "Ordino",
                "state_code": "05"
            },
            {
                "id": 490,
                "name": "Sant Julià de Lòria",
                "state_code": "06"
            }
        ]
    },
    {
        "name": "Angola",
        "iso3": "AGO",
        "iso2": "AO",
        "phone_code": "244",
        "capital": "Luanda",
        "currency": "AOA",
        "currency_symbol": "Kz",
        "tld": ".ao",
        "native": "Angola",
        "region": "Africa",
        "subregion": "Middle Africa",
        "timezones": [
            {
                "zoneName": "Africa/Luanda",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WAT",
                "tzName": "West Africa Time"
            }
        ],
        "translations": {
            "br": "Angola",
            "pt": "Angola",
            "nl": "Angola",
            "hr": "Angola",
            "fa": "آنگولا",
            "de": "Angola",
            "es": "Angola",
            "fr": "Angola",
            "ja": "アンゴラ",
            "it": "Angola"
        },
        "latitude": "-12.50000000",
        "longitude": "18.50000000",
        "emoji": "🇦🇴",
        "emojiU": "U+1F1E6 U+1F1F4",
        "states": [
            {
                "id": 221,
                "name": "Bengo Province",
                "state_code": "BGO"
            },
            {
                "id": 218,
                "name": "Benguela Province",
                "state_code": "BGU"
            },
            {
                "id": 212,
                "name": "Bié Province",
                "state_code": "BIE"
            },
            {
                "id": 228,
                "name": "Cabinda Province",
                "state_code": "CAB"
            },
            {
                "id": 226,
                "name": "Cuando Cubango Province",
                "state_code": "CCU"
            },
            {
                "id": 217,
                "name": "Cuanza Norte Province",
                "state_code": "CNO"
            },
            {
                "id": 216,
                "name": "Cuanza Sul",
                "state_code": "CUS"
            },
            {
                "id": 215,
                "name": "Cunene Province",
                "state_code": "CNN"
            },
            {
                "id": 213,
                "name": "Huambo Province",
                "state_code": "HUA"
            },
            {
                "id": 225,
                "name": "Huíla Province",
                "state_code": "HUI"
            },
            {
                "id": 222,
                "name": "Luanda Province",
                "state_code": "LUA"
            },
            {
                "id": 223,
                "name": "Lunda Norte Province",
                "state_code": "LNO"
            },
            {
                "id": 220,
                "name": "Lunda Sul Province",
                "state_code": "LSU"
            },
            {
                "id": 227,
                "name": "Malanje Province",
                "state_code": "MAL"
            },
            {
                "id": 219,
                "name": "Moxico Province",
                "state_code": "MOX"
            },
            {
                "id": 224,
                "name": "Uíge Province",
                "state_code": "UIG"
            },
            {
                "id": 214,
                "name": "Zaire Province",
                "state_code": "ZAI"
            }
        ]
    },
    {
        "name": "Anguilla",
        "iso3": "AIA",
        "iso2": "AI",
        "phone_code": "+1-264",
        "capital": "The Valley",
        "currency": "XCD",
        "currency_symbol": "$",
        "tld": ".ai",
        "native": "Anguilla",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/Anguilla",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "translations": {
            "br": "Anguila",
            "pt": "Anguila",
            "nl": "Anguilla",
            "hr": "Angvila",
            "fa": "آنگویلا",
            "de": "Anguilla",
            "es": "Anguilla",
            "fr": "Anguilla",
            "ja": "アンギラ",
            "it": "Anguilla"
        },
        "latitude": "18.25000000",
        "longitude": "-63.16666666",
        "emoji": "🇦🇮",
        "emojiU": "U+1F1E6 U+1F1EE",
        "states": []
    },
    {
        "name": "Antarctica",
        "iso3": "ATA",
        "iso2": "AQ",
        "phone_code": "",
        "capital": "",
        "currency": "",
        "currency_symbol": "$",
        "tld": ".aq",
        "native": "Antarctica",
        "region": "Polar",
        "subregion": "",
        "timezones": [
            {
                "zoneName": "Antarctica/Casey",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "AWST",
                "tzName": "Australian Western Standard Time"
            },
            {
                "zoneName": "Antarctica/Davis",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "DAVT",
                "tzName": "Davis Time"
            },
            {
                "zoneName": "Antarctica/DumontDUrville",
                "gmtOffset": 36000,
                "gmtOffsetName": "UTC+10:00",
                "abbreviation": "DDUT",
                "tzName": "Dumont d'Urville Time"
            },
            {
                "zoneName": "Antarctica/Mawson",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "MAWT",
                "tzName": "Mawson Station Time"
            },
            {
                "zoneName": "Antarctica/McMurdo",
                "gmtOffset": 46800,
                "gmtOffsetName": "UTC+13:00",
                "abbreviation": "NZDT",
                "tzName": "New Zealand Daylight Time"
            },
            {
                "zoneName": "Antarctica/Palmer",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "CLST",
                "tzName": "Chile Summer Time"
            },
            {
                "zoneName": "Antarctica/Rothera",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ROTT",
                "tzName": "Rothera Research Station Time"
            },
            {
                "zoneName": "Antarctica/Syowa",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "SYOT",
                "tzName": "Showa Station Time"
            },
            {
                "zoneName": "Antarctica/Troll",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            },
            {
                "zoneName": "Antarctica/Vostok",
                "gmtOffset": 21600,
                "gmtOffsetName": "UTC+06:00",
                "abbreviation": "VOST",
                "tzName": "Vostok Station Time"
            }
        ],
        "translations": {
            "br": "Antártida",
            "pt": "Antárctida",
            "nl": "Antarctica",
            "hr": "Antarktika",
            "fa": "جنوبگان",
            "de": "Antarktika",
            "es": "Antártida",
            "fr": "Antarctique",
            "ja": "南極大陸",
            "it": "Antartide"
        },
        "latitude": "-74.65000000",
        "longitude": "4.48000000",
        "emoji": "🇦🇶",
        "emojiU": "U+1F1E6 U+1F1F6",
        "states": []
    },
    {
        "name": "Antigua And Barbuda",
        "iso3": "ATG",
        "iso2": "AG",
        "phone_code": "+1-268",
        "capital": "St. John's",
        "currency": "XCD",
        "currency_symbol": "$",
        "tld": ".ag",
        "native": "Antigua and Barbuda",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/Antigua",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "translations": {
            "br": "Antígua e Barbuda",
            "pt": "Antígua e Barbuda",
            "nl": "Antigua en Barbuda",
            "hr": "Antigva i Barbuda",
            "fa": "آنتیگوا و باربودا",
            "de": "Antigua und Barbuda",
            "es": "Antigua y Barbuda",
            "fr": "Antigua-et-Barbuda",
            "ja": "アンティグア・バーブーダ",
            "it": "Antigua e Barbuda"
        },
        "latitude": "17.05000000",
        "longitude": "-61.80000000",
        "emoji": "🇦🇬",
        "emojiU": "U+1F1E6 U+1F1EC",
        "states": [
            {
                "id": 3708,
                "name": "Barbuda",
                "state_code": "10"
            },
            {
                "id": 3703,
                "name": "Redonda",
                "state_code": "11"
            },
            {
                "id": 3709,
                "name": "Saint George Parish",
                "state_code": "03"
            },
            {
                "id": 3706,
                "name": "Saint John Parish",
                "state_code": "04"
            },
            {
                "id": 3707,
                "name": "Saint Mary Parish",
                "state_code": "05"
            },
            {
                "id": 3705,
                "name": "Saint Paul Parish",
                "state_code": "06"
            },
            {
                "id": 3704,
                "name": "Saint Peter Parish",
                "state_code": "07"
            },
            {
                "id": 3710,
                "name": "Saint Philip Parish",
                "state_code": "08"
            }
        ]
    },
    {
        "name": "Argentina",
        "iso3": "ARG",
        "iso2": "AR",
        "phone_code": "54",
        "capital": "Buenos Aires",
        "currency": "ARS",
        "currency_symbol": "$",
        "tld": ".ar",
        "native": "Argentina",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America/Argentina/Buenos_Aires",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ART",
                "tzName": "Argentina Time"
            },
            {
                "zoneName": "America/Argentina/Catamarca",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ART",
                "tzName": "Argentina Time"
            },
            {
                "zoneName": "America/Argentina/Cordoba",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ART",
                "tzName": "Argentina Time"
            },
            {
                "zoneName": "America/Argentina/Jujuy",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ART",
                "tzName": "Argentina Time"
            },
            {
                "zoneName": "America/Argentina/La_Rioja",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ART",
                "tzName": "Argentina Time"
            },
            {
                "zoneName": "America/Argentina/Mendoza",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ART",
                "tzName": "Argentina Time"
            },
            {
                "zoneName": "America/Argentina/Rio_Gallegos",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ART",
                "tzName": "Argentina Time"
            },
            {
                "zoneName": "America/Argentina/Salta",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ART",
                "tzName": "Argentina Time"
            },
            {
                "zoneName": "America/Argentina/San_Juan",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ART",
                "tzName": "Argentina Time"
            },
            {
                "zoneName": "America/Argentina/San_Luis",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ART",
                "tzName": "Argentina Time"
            },
            {
                "zoneName": "America/Argentina/Tucuman",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ART",
                "tzName": "Argentina Time"
            },
            {
                "zoneName": "America/Argentina/Ushuaia",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "ART",
                "tzName": "Argentina Time"
            }
        ],
        "translations": {
            "br": "Argentina",
            "pt": "Argentina",
            "nl": "Argentinië",
            "hr": "Argentina",
            "fa": "آرژانتین",
            "de": "Argentinien",
            "es": "Argentina",
            "fr": "Argentine",
            "ja": "アルゼンチン",
            "it": "Argentina"
        },
        "latitude": "-34.00000000",
        "longitude": "-64.00000000",
        "emoji": "🇦🇷",
        "emojiU": "U+1F1E6 U+1F1F7",
        "states": [
            {
                "id": 4880,
                "name": "Autonomous City Of Buenos Aires",
                "state_code": "C"
            },
            {
                "id": 3656,
                "name": "Buenos Aires Province",
                "state_code": "B"
            },
            {
                "id": 3647,
                "name": "Catamarca Province",
                "state_code": "K"
            },
            {
                "id": 3640,
                "name": "Chaco Province",
                "state_code": "H"
            },
            {
                "id": 3651,
                "name": "Chubut Province",
                "state_code": "U"
            },
            {
                "id": 3642,
                "name": "Córdoba Province",
                "state_code": "X"
            },
            {
                "id": 3638,
                "name": "Corrientes",
                "state_code": "W"
            },
            {
                "id": 3654,
                "name": "Entre Ríos Province",
                "state_code": "E"
            },
            {
                "id": 3652,
                "name": "Formosa Province",
                "state_code": "P"
            },
            {
                "id": 3645,
                "name": "Jujuy Province",
                "state_code": "Y"
            },
            {
                "id": 3655,
                "name": "La Pampa",
                "state_code": "L"
            },
            {
                "id": 3653,
                "name": "La Rioja Province",
                "state_code": "F"
            },
            {
                "id": 3646,
                "name": "Mendoza",
                "state_code": "M"
            },
            {
                "id": 3644,
                "name": "Misiones Province",
                "state_code": "N"
            },
            {
                "id": 3648,
                "name": "Neuquén Province",
                "state_code": "Q"
            },
            {
                "id": 3639,
                "name": "Río Negro Province",
                "state_code": "R"
            },
            {
                "id": 3643,
                "name": "Salta Province",
                "state_code": "A"
            },
            {
                "id": 3634,
                "name": "San Juan Province",
                "state_code": "J"
            },
            {
                "id": 3636,
                "name": "San Luis Province",
                "state_code": "D"
            },
            {
                "id": 3649,
                "name": "Santa Cruz Province",
                "state_code": "Z"
            },
            {
                "id": 3641,
                "name": "Santa Fe Province",
                "state_code": "S"
            },
            {
                "id": 3635,
                "name": "Santiago del Estero Province",
                "state_code": "G"
            },
            {
                "id": 3650,
                "name": "Tierra del Fuego Province",
                "state_code": "V"
            },
            {
                "id": 3637,
                "name": "Tucumán Province",
                "state_code": "T"
            }
        ]
    },
    {
        "name": "Armenia",
        "iso3": "ARM",
        "iso2": "AM",
        "phone_code": "374",
        "capital": "Yerevan",
        "currency": "AMD",
        "currency_symbol": "֏",
        "tld": ".am",
        "native": "Հայաստան",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia/Yerevan",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "AMT",
                "tzName": "Armenia Time"
            }
        ],
        "translations": {
            "br": "Armênia",
            "pt": "Arménia",
            "nl": "Armenië",
            "hr": "Armenija",
            "fa": "ارمنستان",
            "de": "Armenien",
            "es": "Armenia",
            "fr": "Arménie",
            "ja": "アルメニア",
            "it": "Armenia"
        },
        "latitude": "40.00000000",
        "longitude": "45.00000000",
        "emoji": "🇦🇲",
        "emojiU": "U+1F1E6 U+1F1F2",
        "states": [
            {
                "id": 2023,
                "name": "Aragatsotn Region",
                "state_code": "AG"
            },
            {
                "id": 2024,
                "name": "Ararat Province",
                "state_code": "AR"
            },
            {
                "id": 2026,
                "name": "Armavir Region",
                "state_code": "AV"
            },
            {
                "id": 2028,
                "name": "Gegharkunik Province",
                "state_code": "GR"
            },
            {
                "id": 2033,
                "name": "Kotayk Region",
                "state_code": "KT"
            },
            {
                "id": 2029,
                "name": "Lori Region",
                "state_code": "LO"
            },
            {
                "id": 2031,
                "name": "Shirak Region",
                "state_code": "SH"
            },
            {
                "id": 2027,
                "name": "Syunik Province",
                "state_code": "SU"
            },
            {
                "id": 2032,
                "name": "Tavush Region",
                "state_code": "TV"
            },
            {
                "id": 2025,
                "name": "Vayots Dzor Region",
                "state_code": "VD"
            },
            {
                "id": 2030,
                "name": "Yerevan",
                "state_code": "ER"
            }
        ]
    },
    {
        "name": "Aruba",
        "iso3": "ABW",
        "iso2": "AW",
        "phone_code": "297",
        "capital": "Oranjestad",
        "currency": "AWG",
        "currency_symbol": "ƒ",
        "tld": ".aw",
        "native": "Aruba",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/Aruba",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "translations": {
            "br": "Aruba",
            "pt": "Aruba",
            "nl": "Aruba",
            "hr": "Aruba",
            "fa": "آروبا",
            "de": "Aruba",
            "es": "Aruba",
            "fr": "Aruba",
            "ja": "アルバ",
            "it": "Aruba"
        },
        "latitude": "12.50000000",
        "longitude": "-69.96666666",
        "emoji": "🇦🇼",
        "emojiU": "U+1F1E6 U+1F1FC",
        "states": []
    },
    {
        "name": "Australia",
        "iso3": "AUS",
        "iso2": "AU",
        "phone_code": "61",
        "capital": "Canberra",
        "currency": "AUD",
        "currency_symbol": "$",
        "tld": ".au",
        "native": "Australia",
        "region": "Oceania",
        "subregion": "Australia and New Zealand",
        "timezones": [
            {
                "zoneName": "Antarctica/Macquarie",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "MIST",
                "tzName": "Macquarie Island Station Time"
            },
            {
                "zoneName": "Australia/Adelaide",
                "gmtOffset": 37800,
                "gmtOffsetName": "UTC+10:30",
                "abbreviation": "ACDT",
                "tzName": "Australian Central Daylight Saving Time"
            },
            {
                "zoneName": "Australia/Brisbane",
                "gmtOffset": 36000,
                "gmtOffsetName": "UTC+10:00",
                "abbreviation": "AEST",
                "tzName": "Australian Eastern Standard Time"
            },
            {
                "zoneName": "Australia/Broken_Hill",
                "gmtOffset": 37800,
                "gmtOffsetName": "UTC+10:30",
                "abbreviation": "ACDT",
                "tzName": "Australian Central Daylight Saving Time"
            },
            {
                "zoneName": "Australia/Currie",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "AEDT",
                "tzName": "Australian Eastern Daylight Saving Time"
            },
            {
                "zoneName": "Australia/Darwin",
                "gmtOffset": 34200,
                "gmtOffsetName": "UTC+09:30",
                "abbreviation": "ACST",
                "tzName": "Australian Central Standard Time"
            },
            {
                "zoneName": "Australia/Eucla",
                "gmtOffset": 31500,
                "gmtOffsetName": "UTC+08:45",
                "abbreviation": "ACWST",
                "tzName": "Australian Central Western Standard Time (Unofficial)"
            },
            {
                "zoneName": "Australia/Hobart",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "AEDT",
                "tzName": "Australian Eastern Daylight Saving Time"
            },
            {
                "zoneName": "Australia/Lindeman",
                "gmtOffset": 36000,
                "gmtOffsetName": "UTC+10:00",
                "abbreviation": "AEST",
                "tzName": "Australian Eastern Standard Time"
            },
            {
                "zoneName": "Australia/Lord_Howe",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "LHST",
                "tzName": "Lord Howe Summer Time"
            },
            {
                "zoneName": "Australia/Melbourne",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "AEDT",
                "tzName": "Australian Eastern Daylight Saving Time"
            },
            {
                "zoneName": "Australia/Perth",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "AWST",
                "tzName": "Australian Western Standard Time"
            },
            {
                "zoneName": "Australia/Sydney",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "AEDT",
                "tzName": "Australian Eastern Daylight Saving Time"
            }
        ],
        "translations": {
            "br": "Austrália",
            "pt": "Austrália",
            "nl": "Australië",
            "hr": "Australija",
            "fa": "استرالیا",
            "de": "Australien",
            "es": "Australia",
            "fr": "Australie",
            "ja": "オーストラリア",
            "it": "Australia"
        },
        "latitude": "-27.00000000",
        "longitude": "133.00000000",
        "emoji": "🇦🇺",
        "emojiU": "U+1F1E6 U+1F1FA",
        "states": [
            {
                "id": 3907,
                "name": "Australian Capital Territory",
                "state_code": "ACT"
            },
            {
                "id": 3909,
                "name": "New South Wales",
                "state_code": "NSW"
            },
            {
                "id": 3910,
                "name": "Northern Territory",
                "state_code": "NT"
            },
            {
                "id": 3905,
                "name": "Queensland",
                "state_code": "QLD"
            },
            {
                "id": 3904,
                "name": "South Australia",
                "state_code": "SA"
            },
            {
                "id": 3908,
                "name": "Tasmania",
                "state_code": "TAS"
            },
            {
                "id": 3903,
                "name": "Victoria",
                "state_code": "VIC"
            },
            {
                "id": 3906,
                "name": "Western Australia",
                "state_code": "WA"
            }
        ]
    },
    {
        "name": "Austria",
        "iso3": "AUT",
        "iso2": "AT",
        "phone_code": "43",
        "capital": "Vienna",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".at",
        "native": "Österreich",
        "region": "Europe",
        "subregion": "Western Europe",
        "timezones": [
            {
                "zoneName": "Europe/Vienna",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "áustria",
            "pt": "áustria",
            "nl": "Oostenrijk",
            "hr": "Austrija",
            "fa": "اتریش",
            "de": "Österreich",
            "es": "Austria",
            "fr": "Autriche",
            "ja": "オーストリア",
            "it": "Austria"
        },
        "latitude": "47.33333333",
        "longitude": "13.33333333",
        "emoji": "🇦🇹",
        "emojiU": "U+1F1E6 U+1F1F9",
        "states": [
            {
                "id": 2062,
                "name": "Burgenland",
                "state_code": "1"
            },
            {
                "id": 2057,
                "name": "Carinthia",
                "state_code": "2"
            },
            {
                "id": 2065,
                "name": "Lower Austria",
                "state_code": "3"
            },
            {
                "id": 2061,
                "name": "Salzburg",
                "state_code": "5"
            },
            {
                "id": 2059,
                "name": "Styria",
                "state_code": "6"
            },
            {
                "id": 2064,
                "name": "Tyrol",
                "state_code": "7"
            },
            {
                "id": 2058,
                "name": "Upper Austria",
                "state_code": "4"
            },
            {
                "id": 2060,
                "name": "Vienna",
                "state_code": "9"
            },
            {
                "id": 2063,
                "name": "Vorarlberg",
                "state_code": "8"
            }
        ]
    },
    {
        "name": "Azerbaijan",
        "iso3": "AZE",
        "iso2": "AZ",
        "phone_code": "994",
        "capital": "Baku",
        "currency": "AZN",
        "currency_symbol": "m",
        "tld": ".az",
        "native": "Azərbaycan",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia/Baku",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "AZT",
                "tzName": "Azerbaijan Time"
            }
        ],
        "translations": {
            "br": "Azerbaijão",
            "pt": "Azerbaijão",
            "nl": "Azerbeidzjan",
            "hr": "Azerbajdžan",
            "fa": "آذربایجان",
            "de": "Aserbaidschan",
            "es": "Azerbaiyán",
            "fr": "Azerbaïdjan",
            "ja": "アゼルバイジャン",
            "it": "Azerbaijan"
        },
        "latitude": "40.50000000",
        "longitude": "47.50000000",
        "emoji": "🇦🇿",
        "emojiU": "U+1F1E6 U+1F1FF",
        "states": [
            {
                "id": 540,
                "name": "Absheron District",
                "state_code": "ABS"
            },
            {
                "id": 559,
                "name": "Agdam District",
                "state_code": "AGM"
            },
            {
                "id": 553,
                "name": "Agdash District",
                "state_code": "AGS"
            },
            {
                "id": 577,
                "name": "Aghjabadi District",
                "state_code": "AGC"
            },
            {
                "id": 543,
                "name": "Agstafa District",
                "state_code": "AGA"
            },
            {
                "id": 547,
                "name": "Agsu District",
                "state_code": "AGU"
            },
            {
                "id": 528,
                "name": "Astara District",
                "state_code": "AST"
            },
            {
                "id": 575,
                "name": "Babek District",
                "state_code": "BAB"
            },
            {
                "id": 552,
                "name": "Baku",
                "state_code": "BA"
            },
            {
                "id": 560,
                "name": "Balakan District",
                "state_code": "BAL"
            },
            {
                "id": 569,
                "name": "Barda District",
                "state_code": "BAR"
            },
            {
                "id": 554,
                "name": "Beylagan District",
                "state_code": "BEY"
            },
            {
                "id": 532,
                "name": "Bilasuvar District",
                "state_code": "BIL"
            },
            {
                "id": 561,
                "name": "Dashkasan District",
                "state_code": "DAS"
            },
            {
                "id": 527,
                "name": "Fizuli District",
                "state_code": "FUZ"
            },
            {
                "id": 585,
                "name": "Ganja",
                "state_code": "GA"
            },
            {
                "id": 589,
                "name": "Gədəbəy",
                "state_code": "GAD"
            },
            {
                "id": 573,
                "name": "Gobustan District",
                "state_code": "QOB"
            },
            {
                "id": 551,
                "name": "Goranboy District",
                "state_code": "GOR"
            },
            {
                "id": 531,
                "name": "Goychay",
                "state_code": "GOY"
            },
            {
                "id": 574,
                "name": "Goygol District",
                "state_code": "GYG"
            },
            {
                "id": 571,
                "name": "Hajigabul District",
                "state_code": "HAC"
            },
            {
                "id": 544,
                "name": "Imishli District",
                "state_code": "IMI"
            },
            {
                "id": 564,
                "name": "Ismailli District",
                "state_code": "ISM"
            },
            {
                "id": 570,
                "name": "Jabrayil District",
                "state_code": "CAB"
            },
            {
                "id": 578,
                "name": "Jalilabad District",
                "state_code": "CAL"
            },
            {
                "id": 572,
                "name": "Julfa District",
                "state_code": "CUL"
            },
            {
                "id": 525,
                "name": "Kalbajar District",
                "state_code": "KAL"
            },
            {
                "id": 567,
                "name": "Kangarli District",
                "state_code": "KAN"
            },
            {
                "id": 590,
                "name": "Khachmaz District",
                "state_code": "XAC"
            },
            {
                "id": 537,
                "name": "Khizi District",
                "state_code": "XIZ"
            },
            {
                "id": 524,
                "name": "Khojali District",
                "state_code": "XCI"
            },
            {
                "id": 549,
                "name": "Kurdamir District",
                "state_code": "KUR"
            },
            {
                "id": 541,
                "name": "Lachin District",
                "state_code": "LAC"
            },
            {
                "id": 587,
                "name": "Lankaran",
                "state_code": "LAN"
            },
            {
                "id": 558,
                "name": "Lankaran District",
                "state_code": "LA"
            },
            {
                "id": 546,
                "name": "Lerik District",
                "state_code": "LER"
            },
            {
                "id": 568,
                "name": "Martuni",
                "state_code": "XVD"
            },
            {
                "id": 555,
                "name": "Masally District",
                "state_code": "MAS"
            },
            {
                "id": 580,
                "name": "Mingachevir",
                "state_code": "MI"
            },
            {
                "id": 562,
                "name": "Nakhchivan Autonomous Republic",
                "state_code": "NX"
            },
            {
                "id": 530,
                "name": "Neftchala District",
                "state_code": "NEF"
            },
            {
                "id": 556,
                "name": "Oghuz District",
                "state_code": "OGU"
            },
            {
                "id": 534,
                "name": "Ordubad District",
                "state_code": "ORD"
            },
            {
                "id": 542,
                "name": "Qabala District",
                "state_code": "QAB"
            },
            {
                "id": 526,
                "name": "Qakh District",
                "state_code": "QAX"
            },
            {
                "id": 521,
                "name": "Qazakh District",
                "state_code": "QAZ"
            },
            {
                "id": 563,
                "name": "Quba District",
                "state_code": "QBA"
            },
            {
                "id": 548,
                "name": "Qubadli District",
                "state_code": "QBI"
            },
            {
                "id": 588,
                "name": "Qusar District",
                "state_code": "QUS"
            },
            {
                "id": 557,
                "name": "Saatly District",
                "state_code": "SAT"
            },
            {
                "id": 565,
                "name": "Sabirabad District",
                "state_code": "SAB"
            },
            {
                "id": 522,
                "name": "Sadarak District",
                "state_code": "SAD"
            },
            {
                "id": 545,
                "name": "Salyan District",
                "state_code": "SAL"
            },
            {
                "id": 536,
                "name": "Samukh District",
                "state_code": "SMX"
            },
            {
                "id": 591,
                "name": "Shabran District",
                "state_code": "SBN"
            },
            {
                "id": 579,
                "name": "Shahbuz District",
                "state_code": "SAH"
            },
            {
                "id": 518,
                "name": "Shaki",
                "state_code": "SA"
            },
            {
                "id": 586,
                "name": "Shaki District",
                "state_code": "SAK"
            },
            {
                "id": 529,
                "name": "Shamakhi District",
                "state_code": "SMI"
            },
            {
                "id": 583,
                "name": "Shamkir District",
                "state_code": "SKR"
            },
            {
                "id": 535,
                "name": "Sharur District",
                "state_code": "SAR"
            },
            {
                "id": 520,
                "name": "Shirvan",
                "state_code": "SR"
            },
            {
                "id": 592,
                "name": "Shusha District",
                "state_code": "SUS"
            },
            {
                "id": 584,
                "name": "Siazan District",
                "state_code": "SIY"
            },
            {
                "id": 582,
                "name": "Sumqayit",
                "state_code": "SM"
            },
            {
                "id": 519,
                "name": "Tartar District",
                "state_code": "TAR"
            },
            {
                "id": 533,
                "name": "Tovuz District",
                "state_code": "TOV"
            },
            {
                "id": 539,
                "name": "Ujar District",
                "state_code": "UCA"
            },
            {
                "id": 550,
                "name": "Yardymli District",
                "state_code": "YAR"
            },
            {
                "id": 538,
                "name": "Yevlakh",
                "state_code": "YE"
            },
            {
                "id": 523,
                "name": "Yevlakh District",
                "state_code": "YEV"
            },
            {
                "id": 581,
                "name": "Zangilan District",
                "state_code": "ZAN"
            },
            {
                "id": 566,
                "name": "Zaqatala District",
                "state_code": "ZAQ"
            },
            {
                "id": 576,
                "name": "Zardab District",
                "state_code": "ZAR"
            }
        ]
    },
    {
        "name": "Bahamas The",
        "iso3": "BHS",
        "iso2": "BS",
        "phone_code": "+1-242",
        "capital": "Nassau",
        "currency": "BSD",
        "currency_symbol": "B$",
        "tld": ".bs",
        "native": "Bahamas",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/Nassau",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America)"
            }
        ],
        "translations": {
            "br": "Bahamas",
            "pt": "Baamas",
            "nl": "Bahama’s",
            "hr": "Bahami",
            "fa": "باهاما",
            "de": "Bahamas",
            "es": "Bahamas",
            "fr": "Bahamas",
            "ja": "バハマ",
            "it": "Bahamas"
        },
        "latitude": "24.25000000",
        "longitude": "-76.00000000",
        "emoji": "🇧🇸",
        "emojiU": "U+1F1E7 U+1F1F8",
        "states": [
            {
                "id": 3601,
                "name": "Acklins",
                "state_code": "AK"
            },
            {
                "id": 3628,
                "name": "Acklins and Crooked Islands",
                "state_code": "AC"
            },
            {
                "id": 3593,
                "name": "Berry Islands",
                "state_code": "BY"
            },
            {
                "id": 3629,
                "name": "Bimini",
                "state_code": "BI"
            },
            {
                "id": 3605,
                "name": "Black Point",
                "state_code": "BP"
            },
            {
                "id": 3611,
                "name": "Cat Island",
                "state_code": "CI"
            },
            {
                "id": 3603,
                "name": "Central Abaco",
                "state_code": "CO"
            },
            {
                "id": 3631,
                "name": "Central Andros",
                "state_code": "CS"
            },
            {
                "id": 3596,
                "name": "Central Eleuthera",
                "state_code": "CE"
            },
            {
                "id": 3621,
                "name": "Crooked Island",
                "state_code": "CK"
            },
            {
                "id": 3614,
                "name": "East Grand Bahama",
                "state_code": "EG"
            },
            {
                "id": 3612,
                "name": "Exuma",
                "state_code": "EX"
            },
            {
                "id": 3626,
                "name": "Freeport",
                "state_code": "FP"
            },
            {
                "id": 3619,
                "name": "Fresh Creek",
                "state_code": "FC"
            },
            {
                "id": 3597,
                "name": "Governor's Harbour",
                "state_code": "GH"
            },
            {
                "id": 3632,
                "name": "Grand Cay",
                "state_code": "GC"
            },
            {
                "id": 3595,
                "name": "Green Turtle Cay",
                "state_code": "GT"
            },
            {
                "id": 3613,
                "name": "Harbour Island",
                "state_code": "HI"
            },
            {
                "id": 3598,
                "name": "High Rock",
                "state_code": "HR"
            },
            {
                "id": 3624,
                "name": "Hope Town",
                "state_code": "HT"
            },
            {
                "id": 3609,
                "name": "Inagua",
                "state_code": "IN"
            },
            {
                "id": 3618,
                "name": "Kemps Bay",
                "state_code": "KB"
            },
            {
                "id": 3610,
                "name": "Long Island",
                "state_code": "LI"
            },
            {
                "id": 3625,
                "name": "Mangrove Cay",
                "state_code": "MC"
            },
            {
                "id": 3604,
                "name": "Marsh Harbour",
                "state_code": "MH"
            },
            {
                "id": 3633,
                "name": "Mayaguana District",
                "state_code": "MG"
            },
            {
                "id": 4881,
                "name": "New Providence",
                "state_code": "NP"
            },
            {
                "id": 3594,
                "name": "Nichollstown and Berry Islands",
                "state_code": "NB"
            },
            {
                "id": 3616,
                "name": "North Abaco",
                "state_code": "NO"
            },
            {
                "id": 3617,
                "name": "North Andros",
                "state_code": "NS"
            },
            {
                "id": 3602,
                "name": "North Eleuthera",
                "state_code": "NE"
            },
            {
                "id": 3615,
                "name": "Ragged Island",
                "state_code": "RI"
            },
            {
                "id": 3623,
                "name": "Rock Sound",
                "state_code": "RS"
            },
            {
                "id": 3600,
                "name": "Rum Cay District",
                "state_code": "RC"
            },
            {
                "id": 3620,
                "name": "San Salvador and Rum Cay",
                "state_code": "SR"
            },
            {
                "id": 3627,
                "name": "San Salvador Island",
                "state_code": "SS"
            },
            {
                "id": 3606,
                "name": "Sandy Point",
                "state_code": "SP"
            },
            {
                "id": 3608,
                "name": "South Abaco",
                "state_code": "SO"
            },
            {
                "id": 3622,
                "name": "South Andros",
                "state_code": "SA"
            },
            {
                "id": 3607,
                "name": "South Eleuthera",
                "state_code": "SE"
            },
            {
                "id": 3630,
                "name": "Spanish Wells",
                "state_code": "SW"
            },
            {
                "id": 3599,
                "name": "West Grand Bahama",
                "state_code": "WG"
            }
        ]
    },
    {
        "name": "Bahrain",
        "iso3": "BHR",
        "iso2": "BH",
        "phone_code": "973",
        "capital": "Manama",
        "currency": "BHD",
        "currency_symbol": ".د.ب",
        "tld": ".bh",
        "native": "‏البحرين",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia/Bahrain",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "AST",
                "tzName": "Arabia Standard Time"
            }
        ],
        "translations": {
            "br": "Bahrein",
            "pt": "Barém",
            "nl": "Bahrein",
            "hr": "Bahrein",
            "fa": "بحرین",
            "de": "Bahrain",
            "es": "Bahrein",
            "fr": "Bahreïn",
            "ja": "バーレーン",
            "it": "Bahrein"
        },
        "latitude": "26.00000000",
        "longitude": "50.55000000",
        "emoji": "🇧🇭",
        "emojiU": "U+1F1E7 U+1F1ED",
        "states": [
            {
                "id": 1992,
                "name": "Capital Governorate",
                "state_code": "13"
            },
            {
                "id": 1996,
                "name": "Central Governorate",
                "state_code": "16"
            },
            {
                "id": 1995,
                "name": "Muharraq Governorate",
                "state_code": "15"
            },
            {
                "id": 1994,
                "name": "Northern Governorate",
                "state_code": "17"
            },
            {
                "id": 1993,
                "name": "Southern Governorate",
                "state_code": "14"
            }
        ]
    },
    {
        "name": "Bangladesh",
        "iso3": "BGD",
        "iso2": "BD",
        "phone_code": "880",
        "capital": "Dhaka",
        "currency": "BDT",
        "currency_symbol": "৳",
        "tld": ".bd",
        "native": "Bangladesh",
        "region": "Asia",
        "subregion": "Southern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Dhaka",
                "gmtOffset": 21600,
                "gmtOffsetName": "UTC+06:00",
                "abbreviation": "BDT",
                "tzName": "Bangladesh Standard Time"
            }
        ],
        "translations": {
            "br": "Bangladesh",
            "pt": "Bangladeche",
            "nl": "Bangladesh",
            "hr": "Bangladeš",
            "fa": "بنگلادش",
            "de": "Bangladesch",
            "es": "Bangladesh",
            "fr": "Bangladesh",
            "ja": "バングラデシュ",
            "it": "Bangladesh"
        },
        "latitude": "24.00000000",
        "longitude": "90.00000000",
        "emoji": "🇧🇩",
        "emojiU": "U+1F1E7 U+1F1E9",
        "states": [
            {
                "id": 796,
                "name": "Bagerhat District",
                "state_code": "05"
            },
            {
                "id": 802,
                "name": "Bahadia",
                "state_code": "33"
            },
            {
                "id": 752,
                "name": "Bandarban District",
                "state_code": "01"
            },
            {
                "id": 784,
                "name": "Barguna District",
                "state_code": "02"
            },
            {
                "id": 818,
                "name": "Barisal District",
                "state_code": "06"
            },
            {
                "id": 807,
                "name": "Barisal Division",
                "state_code": "A"
            },
            {
                "id": 756,
                "name": "Bhola District",
                "state_code": "07"
            },
            {
                "id": 797,
                "name": "Bogra District",
                "state_code": "03"
            },
            {
                "id": 810,
                "name": "Brahmanbaria District",
                "state_code": "04"
            },
            {
                "id": 768,
                "name": "Chandpur District",
                "state_code": "09"
            },
            {
                "id": 761,
                "name": "Chapai Nawabganj District",
                "state_code": "45"
            },
            {
                "id": 785,
                "name": "Chittagong District",
                "state_code": "10"
            },
            {
                "id": 803,
                "name": "Chittagong Division",
                "state_code": "B"
            },
            {
                "id": 788,
                "name": "Chuadanga District",
                "state_code": "12"
            },
            {
                "id": 763,
                "name": "Comilla District",
                "state_code": "08"
            },
            {
                "id": 751,
                "name": "Cox's Bazar District",
                "state_code": "11"
            },
            {
                "id": 771,
                "name": "Dhaka District",
                "state_code": "13"
            },
            {
                "id": 760,
                "name": "Dhaka Division",
                "state_code": "C"
            },
            {
                "id": 783,
                "name": "Dinajpur District",
                "state_code": "14"
            },
            {
                "id": 762,
                "name": "Faridpur District",
                "state_code": "15"
            },
            {
                "id": 816,
                "name": "Feni District",
                "state_code": "16"
            },
            {
                "id": 795,
                "name": "Gaibandha District",
                "state_code": "19"
            },
            {
                "id": 798,
                "name": "Gazipur District",
                "state_code": "18"
            },
            {
                "id": 792,
                "name": "Gopalganj District",
                "state_code": "17"
            },
            {
                "id": 805,
                "name": "Habiganj District",
                "state_code": "20"
            },
            {
                "id": 808,
                "name": "Jamalpur District",
                "state_code": "21"
            },
            {
                "id": 757,
                "name": "Jessore District",
                "state_code": "22"
            },
            {
                "id": 778,
                "name": "Jhalokati District",
                "state_code": "25"
            },
            {
                "id": 789,
                "name": "Jhenaidah District",
                "state_code": "23"
            },
            {
                "id": 806,
                "name": "Joypurhat District",
                "state_code": "24"
            },
            {
                "id": 786,
                "name": "Khagrachari District",
                "state_code": "29"
            },
            {
                "id": 811,
                "name": "Khulna District",
                "state_code": "27"
            },
            {
                "id": 775,
                "name": "Khulna Division",
                "state_code": "D"
            },
            {
                "id": 779,
                "name": "Kishoreganj District",
                "state_code": "26"
            },
            {
                "id": 793,
                "name": "Kurigram District",
                "state_code": "28"
            },
            {
                "id": 774,
                "name": "Kushtia District",
                "state_code": "30"
            },
            {
                "id": 819,
                "name": "Lakshmipur District",
                "state_code": "31"
            },
            {
                "id": 780,
                "name": "Lalmonirhat District",
                "state_code": "32"
            },
            {
                "id": 817,
                "name": "Madaripur District",
                "state_code": "36"
            },
            {
                "id": 776,
                "name": "Meherpur District",
                "state_code": "39"
            },
            {
                "id": 794,
                "name": "Moulvibazar District",
                "state_code": "38"
            },
            {
                "id": 790,
                "name": "Munshiganj District",
                "state_code": "35"
            },
            {
                "id": 766,
                "name": "Mymensingh District",
                "state_code": "34"
            },
            {
                "id": 758,
                "name": "Mymensingh Division",
                "state_code": "H"
            },
            {
                "id": 814,
                "name": "Naogaon District",
                "state_code": "48"
            },
            {
                "id": 769,
                "name": "Narail District",
                "state_code": "43"
            },
            {
                "id": 770,
                "name": "Narayanganj District",
                "state_code": "40"
            },
            {
                "id": 787,
                "name": "Natore District",
                "state_code": "44"
            },
            {
                "id": 764,
                "name": "Netrokona District",
                "state_code": "41"
            },
            {
                "id": 772,
                "name": "Nilphamari District",
                "state_code": "46"
            },
            {
                "id": 815,
                "name": "Noakhali District",
                "state_code": "47"
            },
            {
                "id": 754,
                "name": "Pabna District",
                "state_code": "49"
            },
            {
                "id": 800,
                "name": "Panchagarh District",
                "state_code": "52"
            },
            {
                "id": 777,
                "name": "Patuakhali District",
                "state_code": "51"
            },
            {
                "id": 791,
                "name": "Pirojpur District",
                "state_code": "50"
            },
            {
                "id": 773,
                "name": "Rajbari District",
                "state_code": "53"
            },
            {
                "id": 813,
                "name": "Rajshahi District",
                "state_code": "54"
            },
            {
                "id": 753,
                "name": "Rajshahi Division",
                "state_code": "E"
            },
            {
                "id": 809,
                "name": "Rangamati Hill District",
                "state_code": "56"
            },
            {
                "id": 759,
                "name": "Rangpur District",
                "state_code": "55"
            },
            {
                "id": 750,
                "name": "Rangpur Division",
                "state_code": "F"
            },
            {
                "id": 799,
                "name": "Satkhira District",
                "state_code": "58"
            },
            {
                "id": 801,
                "name": "Shariatpur District",
                "state_code": "62"
            },
            {
                "id": 755,
                "name": "Sherpur District",
                "state_code": "57"
            },
            {
                "id": 781,
                "name": "Sirajganj District",
                "state_code": "59"
            },
            {
                "id": 812,
                "name": "Sunamganj District",
                "state_code": "61"
            },
            {
                "id": 767,
                "name": "Sylhet District",
                "state_code": "60"
            },
            {
                "id": 765,
                "name": "Sylhet Division",
                "state_code": "G"
            },
            {
                "id": 782,
                "name": "Tangail District",
                "state_code": "63"
            },
            {
                "id": 804,
                "name": "Thakurgaon District",
                "state_code": "64"
            }
        ]
    },
    {
        "name": "Barbados",
        "iso3": "BRB",
        "iso2": "BB",
        "phone_code": "+1-246",
        "capital": "Bridgetown",
        "currency": "BBD",
        "currency_symbol": "Bds$",
        "tld": ".bb",
        "native": "Barbados",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/Barbados",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "translations": {
            "br": "Barbados",
            "pt": "Barbados",
            "nl": "Barbados",
            "hr": "Barbados",
            "fa": "باربادوس",
            "de": "Barbados",
            "es": "Barbados",
            "fr": "Barbade",
            "ja": "バルバドス",
            "it": "Barbados"
        },
        "latitude": "13.16666666",
        "longitude": "-59.53333333",
        "emoji": "🇧🇧",
        "emojiU": "U+1F1E7 U+1F1E7",
        "states": [
            {
                "id": 1228,
                "name": "Christ Church",
                "state_code": "01"
            },
            {
                "id": 1229,
                "name": "Saint Andrew",
                "state_code": "02"
            },
            {
                "id": 1226,
                "name": "Saint George",
                "state_code": "03"
            },
            {
                "id": 1224,
                "name": "Saint James",
                "state_code": "04"
            },
            {
                "id": 1227,
                "name": "Saint John",
                "state_code": "05"
            },
            {
                "id": 1223,
                "name": "Saint Joseph",
                "state_code": "06"
            },
            {
                "id": 1221,
                "name": "Saint Lucy",
                "state_code": "07"
            },
            {
                "id": 1230,
                "name": "Saint Michael",
                "state_code": "08"
            },
            {
                "id": 1222,
                "name": "Saint Peter",
                "state_code": "09"
            },
            {
                "id": 1220,
                "name": "Saint Philip",
                "state_code": "10"
            },
            {
                "id": 1225,
                "name": "Saint Thomas",
                "state_code": "11"
            }
        ]
    },
    {
        "name": "Belarus",
        "iso3": "BLR",
        "iso2": "BY",
        "phone_code": "375",
        "capital": "Minsk",
        "currency": "BYN",
        "currency_symbol": "Br",
        "tld": ".by",
        "native": "Белару́сь",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Minsk",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "MSK",
                "tzName": "Moscow Time"
            }
        ],
        "translations": {
            "br": "Bielorrússia",
            "pt": "Bielorrússia",
            "nl": "Wit-Rusland",
            "hr": "Bjelorusija",
            "fa": "بلاروس",
            "de": "Weißrussland",
            "es": "Bielorrusia",
            "fr": "Biélorussie",
            "ja": "ベラルーシ",
            "it": "Bielorussia"
        },
        "latitude": "53.00000000",
        "longitude": "28.00000000",
        "emoji": "🇧🇾",
        "emojiU": "U+1F1E7 U+1F1FE",
        "states": [
            {
                "id": 2959,
                "name": "Brest Region",
                "state_code": "BR"
            },
            {
                "id": 2955,
                "name": "Gomel Region",
                "state_code": "HO"
            },
            {
                "id": 2956,
                "name": "Grodno Region",
                "state_code": "HR"
            },
            {
                "id": 2958,
                "name": "Minsk",
                "state_code": "HM"
            },
            {
                "id": 2957,
                "name": "Minsk Region",
                "state_code": "MI"
            },
            {
                "id": 2954,
                "name": "Mogilev Region",
                "state_code": "MA"
            },
            {
                "id": 2960,
                "name": "Vitebsk Region",
                "state_code": "VI"
            }
        ]
    },
    {
        "name": "Belgium",
        "iso3": "BEL",
        "iso2": "BE",
        "phone_code": "32",
        "capital": "Brussels",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".be",
        "native": "België",
        "region": "Europe",
        "subregion": "Western Europe",
        "timezones": [
            {
                "zoneName": "Europe/Brussels",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Bélgica",
            "pt": "Bélgica",
            "nl": "België",
            "hr": "Belgija",
            "fa": "بلژیک",
            "de": "Belgien",
            "es": "Bélgica",
            "fr": "Belgique",
            "ja": "ベルギー",
            "it": "Belgio"
        },
        "latitude": "50.83333333",
        "longitude": "4.00000000",
        "emoji": "🇧🇪",
        "emojiU": "U+1F1E7 U+1F1EA",
        "states": [
            {
                "id": 1381,
                "name": "Antwerp",
                "state_code": "VAN"
            },
            {
                "id": 1376,
                "name": "Brussels-Capital Region",
                "state_code": "BRU"
            },
            {
                "id": 1377,
                "name": "East Flanders",
                "state_code": "VOV"
            },
            {
                "id": 1373,
                "name": "Flanders",
                "state_code": "VLG"
            },
            {
                "id": 1374,
                "name": "Flemish Brabant",
                "state_code": "VBR"
            },
            {
                "id": 1375,
                "name": "Hainaut",
                "state_code": "WHT"
            },
            {
                "id": 1384,
                "name": "Liège",
                "state_code": "WLG"
            },
            {
                "id": 1372,
                "name": "Limburg",
                "state_code": "VLI"
            },
            {
                "id": 1379,
                "name": "Luxembourg",
                "state_code": "WLX"
            },
            {
                "id": 1378,
                "name": "Namur",
                "state_code": "WNA"
            },
            {
                "id": 1380,
                "name": "Wallonia",
                "state_code": "WAL"
            },
            {
                "id": 1382,
                "name": "Walloon Brabant",
                "state_code": "WBR"
            },
            {
                "id": 1383,
                "name": "West Flanders",
                "state_code": "VWV"
            }
        ]
    },
    {
        "name": "Belize",
        "iso3": "BLZ",
        "iso2": "BZ",
        "phone_code": "501",
        "capital": "Belmopan",
        "currency": "BZD",
        "currency_symbol": "$",
        "tld": ".bz",
        "native": "Belize",
        "region": "Americas",
        "subregion": "Central America",
        "timezones": [
            {
                "zoneName": "America/Belize",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America)"
            }
        ],
        "translations": {
            "br": "Belize",
            "pt": "Belize",
            "nl": "Belize",
            "hr": "Belize",
            "fa": "بلیز",
            "de": "Belize",
            "es": "Belice",
            "fr": "Belize",
            "ja": "ベリーズ",
            "it": "Belize"
        },
        "latitude": "17.25000000",
        "longitude": "-88.75000000",
        "emoji": "🇧🇿",
        "emojiU": "U+1F1E7 U+1F1FF",
        "states": [
            {
                "id": 264,
                "name": "Belize District",
                "state_code": "BZ"
            },
            {
                "id": 269,
                "name": "Cayo District",
                "state_code": "CY"
            },
            {
                "id": 266,
                "name": "Corozal District",
                "state_code": "CZL"
            },
            {
                "id": 268,
                "name": "Orange Walk District",
                "state_code": "OW"
            },
            {
                "id": 265,
                "name": "Stann Creek District",
                "state_code": "SC"
            },
            {
                "id": 267,
                "name": "Toledo District",
                "state_code": "TOL"
            }
        ]
    },
    {
        "name": "Benin",
        "iso3": "BEN",
        "iso2": "BJ",
        "phone_code": "229",
        "capital": "Porto-Novo",
        "currency": "XOF",
        "currency_symbol": "CFA",
        "tld": ".bj",
        "native": "Bénin",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa/Porto-Novo",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WAT",
                "tzName": "West Africa Time"
            }
        ],
        "translations": {
            "br": "Benin",
            "pt": "Benim",
            "nl": "Benin",
            "hr": "Benin",
            "fa": "بنین",
            "de": "Benin",
            "es": "Benín",
            "fr": "Bénin",
            "ja": "ベナン",
            "it": "Benin"
        },
        "latitude": "9.50000000",
        "longitude": "2.25000000",
        "emoji": "🇧🇯",
        "emojiU": "U+1F1E7 U+1F1EF",
        "states": [
            {
                "id": 3077,
                "name": "Alibori Department",
                "state_code": "AL"
            },
            {
                "id": 3076,
                "name": "Atakora Department",
                "state_code": "AK"
            },
            {
                "id": 3079,
                "name": "Atlantique Department",
                "state_code": "AQ"
            },
            {
                "id": 3078,
                "name": "Borgou Department",
                "state_code": "BO"
            },
            {
                "id": 3070,
                "name": "Collines Department",
                "state_code": "CO"
            },
            {
                "id": 3072,
                "name": "Donga Department",
                "state_code": "DO"
            },
            {
                "id": 3071,
                "name": "Kouffo Department",
                "state_code": "KO"
            },
            {
                "id": 3081,
                "name": "Littoral Department",
                "state_code": "LI"
            },
            {
                "id": 3075,
                "name": "Mono Department",
                "state_code": "MO"
            },
            {
                "id": 3080,
                "name": "Ouémé Department",
                "state_code": "OU"
            },
            {
                "id": 3074,
                "name": "Plateau Department",
                "state_code": "PL"
            },
            {
                "id": 3073,
                "name": "Zou Department",
                "state_code": "ZO"
            }
        ]
    },
    {
        "name": "Bermuda",
        "iso3": "BMU",
        "iso2": "BM",
        "phone_code": "+1-441",
        "capital": "Hamilton",
        "currency": "BMD",
        "currency_symbol": "$",
        "tld": ".bm",
        "native": "Bermuda",
        "region": "Americas",
        "subregion": "Northern America",
        "timezones": [
            {
                "zoneName": "Atlantic/Bermuda",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "translations": {
            "br": "Bermudas",
            "pt": "Bermudas",
            "nl": "Bermuda",
            "hr": "Bermudi",
            "fa": "برمودا",
            "de": "Bermuda",
            "es": "Bermudas",
            "fr": "Bermudes",
            "ja": "バミューダ",
            "it": "Bermuda"
        },
        "latitude": "32.33333333",
        "longitude": "-64.75000000",
        "emoji": "🇧🇲",
        "emojiU": "U+1F1E7 U+1F1F2",
        "states": [
            {
                "id": 4860,
                "name": "Devonshire Parish",
                "state_code": "DEV"
            },
            {
                "id": 4862,
                "name": "Hamilton Municipality",
                "state_code": "HAM"
            },
            {
                "id": 4861,
                "name": "Hamilton Parish",
                "state_code": "HA"
            },
            {
                "id": 4863,
                "name": "Paget Parish",
                "state_code": "PAG"
            },
            {
                "id": 4864,
                "name": "Pembroke Parish",
                "state_code": "PEM"
            },
            {
                "id": 4865,
                "name": "Saint George's Municipality",
                "state_code": "SG"
            },
            {
                "id": 4866,
                "name": "Saint George's Parish",
                "state_code": "SGE"
            },
            {
                "id": 4867,
                "name": "Sandys Parish",
                "state_code": "SAN"
            },
            {
                "id": 4868,
                "name": "Smith's Parish,",
                "state_code": "SMI"
            },
            {
                "id": 4869,
                "name": "Southampton Parish",
                "state_code": "SOU"
            },
            {
                "id": 4870,
                "name": "Warwick Parish",
                "state_code": "WAR"
            }
        ]
    },
    {
        "name": "Bhutan",
        "iso3": "BTN",
        "iso2": "BT",
        "phone_code": "975",
        "capital": "Thimphu",
        "currency": "BTN",
        "currency_symbol": "Nu.",
        "tld": ".bt",
        "native": "ʼbrug-yul",
        "region": "Asia",
        "subregion": "Southern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Thimphu",
                "gmtOffset": 21600,
                "gmtOffsetName": "UTC+06:00",
                "abbreviation": "BTT",
                "tzName": "Bhutan Time"
            }
        ],
        "translations": {
            "br": "Butão",
            "pt": "Butão",
            "nl": "Bhutan",
            "hr": "Butan",
            "fa": "بوتان",
            "de": "Bhutan",
            "es": "Bután",
            "fr": "Bhoutan",
            "ja": "ブータン",
            "it": "Bhutan"
        },
        "latitude": "27.50000000",
        "longitude": "90.50000000",
        "emoji": "🇧🇹",
        "emojiU": "U+1F1E7 U+1F1F9",
        "states": [
            {
                "id": 240,
                "name": "Bumthang District",
                "state_code": "33"
            },
            {
                "id": 239,
                "name": "Chukha District",
                "state_code": "12"
            },
            {
                "id": 238,
                "name": "Dagana District",
                "state_code": "22"
            },
            {
                "id": 229,
                "name": "Gasa District",
                "state_code": "GA"
            },
            {
                "id": 232,
                "name": "Haa District",
                "state_code": "13"
            },
            {
                "id": 234,
                "name": "Lhuntse District",
                "state_code": "44"
            },
            {
                "id": 242,
                "name": "Mongar District",
                "state_code": "42"
            },
            {
                "id": 237,
                "name": "Paro District",
                "state_code": "11"
            },
            {
                "id": 244,
                "name": "Pemagatshel District",
                "state_code": "43"
            },
            {
                "id": 235,
                "name": "Punakha District",
                "state_code": "23"
            },
            {
                "id": 243,
                "name": "Samdrup Jongkhar District",
                "state_code": "45"
            },
            {
                "id": 246,
                "name": "Samtse District",
                "state_code": "14"
            },
            {
                "id": 247,
                "name": "Sarpang District",
                "state_code": "31"
            },
            {
                "id": 241,
                "name": "Thimphu District",
                "state_code": "15"
            },
            {
                "id": 236,
                "name": "Trashigang District",
                "state_code": "41"
            },
            {
                "id": 245,
                "name": "Trongsa District",
                "state_code": "32"
            },
            {
                "id": 230,
                "name": "Tsirang District",
                "state_code": "21"
            },
            {
                "id": 231,
                "name": "Wangdue Phodrang District",
                "state_code": "24"
            },
            {
                "id": 233,
                "name": "Zhemgang District",
                "state_code": "34"
            }
        ]
    },
    {
        "name": "Bolivia",
        "iso3": "BOL",
        "iso2": "BO",
        "phone_code": "591",
        "capital": "Sucre",
        "currency": "BOB",
        "currency_symbol": "Bs.",
        "tld": ".bo",
        "native": "Bolivia",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America/La_Paz",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "BOT",
                "tzName": "Bolivia Time"
            }
        ],
        "translations": {
            "br": "Bolívia",
            "pt": "Bolívia",
            "nl": "Bolivia",
            "hr": "Bolivija",
            "fa": "بولیوی",
            "de": "Bolivien",
            "es": "Bolivia",
            "fr": "Bolivie",
            "ja": "ボリビア多民族国",
            "it": "Bolivia"
        },
        "latitude": "-17.00000000",
        "longitude": "-65.00000000",
        "emoji": "🇧🇴",
        "emojiU": "U+1F1E7 U+1F1F4",
        "states": [
            {
                "id": 3375,
                "name": "Beni Department",
                "state_code": "B"
            },
            {
                "id": 3382,
                "name": "Chuquisaca Department",
                "state_code": "H"
            },
            {
                "id": 3381,
                "name": "Cochabamba Department",
                "state_code": "C"
            },
            {
                "id": 3380,
                "name": "La Paz Department",
                "state_code": "L"
            },
            {
                "id": 3376,
                "name": "Oruro Department",
                "state_code": "O"
            },
            {
                "id": 3379,
                "name": "Pando Department",
                "state_code": "N"
            },
            {
                "id": 3383,
                "name": "Potosí Department",
                "state_code": "P"
            },
            {
                "id": 3377,
                "name": "Santa Cruz Department",
                "state_code": "S"
            },
            {
                "id": 3378,
                "name": "Tarija Department",
                "state_code": "T"
            }
        ]
    },
    {
        "name": "Bonaire, Sint Eustatius and Saba",
        "iso3": "BES",
        "iso2": "BQ",
        "phone_code": "599",
        "capital": "Kralendijk",
        "currency": "USD",
        "currency_symbol": "$",
        "tld": ".an",
        "native": "Caribisch Nederland",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/Anguilla",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "translations": {
            "br": "Bonaire",
            "pt": "Bonaire",
            "fa": "بونیر",
            "de": "Bonaire, Sint Eustatius und Saba",
            "fr": "Bonaire, Saint-Eustache et Saba",
            "it": "Bonaire, Saint-Eustache e Saba"
        },
        "latitude": "12.15000000",
        "longitude": "-68.26666700",
        "emoji": "🇧🇶",
        "emojiU": "U+1F1E7 U+1F1F6",
        "states": []
    },
    {
        "name": "Bosnia and Herzegovina",
        "iso3": "BIH",
        "iso2": "BA",
        "phone_code": "387",
        "capital": "Sarajevo",
        "currency": "BAM",
        "currency_symbol": "KM",
        "tld": ".ba",
        "native": "Bosna i Hercegovina",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Sarajevo",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Bósnia e Herzegovina",
            "pt": "Bósnia e Herzegovina",
            "nl": "Bosnië en Herzegovina",
            "hr": "Bosna i Hercegovina",
            "fa": "بوسنی و هرزگوین",
            "de": "Bosnien und Herzegowina",
            "es": "Bosnia y Herzegovina",
            "fr": "Bosnie-Herzégovine",
            "ja": "ボスニア・ヘルツェゴビナ",
            "it": "Bosnia ed Erzegovina"
        },
        "latitude": "44.00000000",
        "longitude": "18.00000000",
        "emoji": "🇧🇦",
        "emojiU": "U+1F1E7 U+1F1E6",
        "states": [
            {
                "id": 472,
                "name": "Bosnian Podrinje Canton",
                "state_code": "05"
            },
            {
                "id": 460,
                "name": "Brčko District",
                "state_code": "BRC"
            },
            {
                "id": 471,
                "name": "Canton 10",
                "state_code": "10"
            },
            {
                "id": 462,
                "name": "Central Bosnia Canton",
                "state_code": "06"
            },
            {
                "id": 467,
                "name": "Federation of Bosnia and Herzegovina",
                "state_code": "BIH"
            },
            {
                "id": 463,
                "name": "Herzegovina-Neretva Canton",
                "state_code": "07"
            },
            {
                "id": 464,
                "name": "Posavina Canton",
                "state_code": "02"
            },
            {
                "id": 470,
                "name": "Republika Srpska",
                "state_code": "SRP"
            },
            {
                "id": 466,
                "name": "Sarajevo Canton",
                "state_code": "09"
            },
            {
                "id": 461,
                "name": "Tuzla Canton",
                "state_code": "03"
            },
            {
                "id": 465,
                "name": "Una-Sana Canton",
                "state_code": "01"
            },
            {
                "id": 469,
                "name": "West Herzegovina Canton",
                "state_code": "08"
            },
            {
                "id": 468,
                "name": "Zenica-Doboj Canton",
                "state_code": "04"
            }
        ]
    },
    {
        "name": "Botswana",
        "iso3": "BWA",
        "iso2": "BW",
        "phone_code": "267",
        "capital": "Gaborone",
        "currency": "BWP",
        "currency_symbol": "P",
        "tld": ".bw",
        "native": "Botswana",
        "region": "Africa",
        "subregion": "Southern Africa",
        "timezones": [
            {
                "zoneName": "Africa/Gaborone",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "CAT",
                "tzName": "Central Africa Time"
            }
        ],
        "translations": {
            "br": "Botsuana",
            "pt": "Botsuana",
            "nl": "Botswana",
            "hr": "Bocvana",
            "fa": "بوتسوانا",
            "de": "Botswana",
            "es": "Botswana",
            "fr": "Botswana",
            "ja": "ボツワナ",
            "it": "Botswana"
        },
        "latitude": "-22.00000000",
        "longitude": "24.00000000",
        "emoji": "🇧🇼",
        "emojiU": "U+1F1E7 U+1F1FC",
        "states": [
            {
                "id": 3067,
                "name": "Central District",
                "state_code": "CE"
            },
            {
                "id": 3061,
                "name": "Ghanzi District",
                "state_code": "GH"
            },
            {
                "id": 3066,
                "name": "Kgalagadi District",
                "state_code": "KG"
            },
            {
                "id": 3062,
                "name": "Kgatleng District",
                "state_code": "KL"
            },
            {
                "id": 3069,
                "name": "Kweneng District",
                "state_code": "KW"
            },
            {
                "id": 3060,
                "name": "Ngamiland",
                "state_code": "NG"
            },
            {
                "id": 3068,
                "name": "North-East District",
                "state_code": "NE"
            },
            {
                "id": 3065,
                "name": "North-West District",
                "state_code": "NW"
            },
            {
                "id": 3064,
                "name": "South-East District",
                "state_code": "SE"
            },
            {
                "id": 3063,
                "name": "Southern District",
                "state_code": "SO"
            }
        ]
    },
    {
        "name": "Bouvet Island",
        "iso3": "BVT",
        "iso2": "BV",
        "phone_code": "0055",
        "capital": "",
        "currency": "NOK",
        "currency_symbol": "kr",
        "tld": ".bv",
        "native": "Bouvetøya",
        "region": "",
        "subregion": "",
        "timezones": [
            {
                "zoneName": "Europe/Oslo",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Ilha Bouvet",
            "pt": "Ilha Bouvet",
            "nl": "Bouveteiland",
            "hr": "Otok Bouvet",
            "fa": "جزیره بووه",
            "de": "Bouvetinsel",
            "es": "Isla Bouvet",
            "fr": "Île Bouvet",
            "ja": "ブーベ島",
            "it": "Isola Bouvet"
        },
        "latitude": "-54.43333333",
        "longitude": "3.40000000",
        "emoji": "🇧🇻",
        "emojiU": "U+1F1E7 U+1F1FB",
        "states": []
    },
    {
        "name": "Brazil",
        "iso3": "BRA",
        "iso2": "BR",
        "phone_code": "55",
        "capital": "Brasilia",
        "currency": "BRL",
        "currency_symbol": "R$",
        "tld": ".br",
        "native": "Brasil",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America/Araguaina",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "BRT",
                "tzName": "Brasília Time"
            },
            {
                "zoneName": "America/Bahia",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "BRT",
                "tzName": "Brasília Time"
            },
            {
                "zoneName": "America/Belem",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "BRT",
                "tzName": "Brasília Time"
            },
            {
                "zoneName": "America/Boa_Vista",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AMT",
                "tzName": "Amazon Time (Brazil)[3"
            },
            {
                "zoneName": "America/Campo_Grande",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AMT",
                "tzName": "Amazon Time (Brazil)[3"
            },
            {
                "zoneName": "America/Cuiaba",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "BRT",
                "tzName": "Brasilia Time"
            },
            {
                "zoneName": "America/Eirunepe",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "ACT",
                "tzName": "Acre Time"
            },
            {
                "zoneName": "America/Fortaleza",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "BRT",
                "tzName": "Brasília Time"
            },
            {
                "zoneName": "America/Maceio",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "BRT",
                "tzName": "Brasília Time"
            },
            {
                "zoneName": "America/Manaus",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AMT",
                "tzName": "Amazon Time (Brazil)"
            },
            {
                "zoneName": "America/Noronha",
                "gmtOffset": -7200,
                "gmtOffsetName": "UTC-02:00",
                "abbreviation": "FNT",
                "tzName": "Fernando de Noronha Time"
            },
            {
                "zoneName": "America/Porto_Velho",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AMT",
                "tzName": "Amazon Time (Brazil)[3"
            },
            {
                "zoneName": "America/Recife",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "BRT",
                "tzName": "Brasília Time"
            },
            {
                "zoneName": "America/Rio_Branco",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "ACT",
                "tzName": "Acre Time"
            },
            {
                "zoneName": "America/Santarem",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "BRT",
                "tzName": "Brasília Time"
            },
            {
                "zoneName": "America/Sao_Paulo",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "BRT",
                "tzName": "Brasília Time"
            }
        ],
        "translations": {
            "br": "Brasil",
            "pt": "Brasil",
            "nl": "Brazilië",
            "hr": "Brazil",
            "fa": "برزیل",
            "de": "Brasilien",
            "es": "Brasil",
            "fr": "Brésil",
            "ja": "ブラジル",
            "it": "Brasile"
        },
        "latitude": "-10.00000000",
        "longitude": "-55.00000000",
        "emoji": "🇧🇷",
        "emojiU": "U+1F1E7 U+1F1F7",
        "states": [
            {
                "id": 2012,
                "name": "Acre",
                "state_code": "AC"
            },
            {
                "id": 2007,
                "name": "Alagoas",
                "state_code": "AL"
            },
            {
                "id": 1999,
                "name": "Amapá",
                "state_code": "AP"
            },
            {
                "id": 2004,
                "name": "Amazonas",
                "state_code": "AM"
            },
            {
                "id": 2002,
                "name": "Bahia",
                "state_code": "BA"
            },
            {
                "id": 2016,
                "name": "Ceará",
                "state_code": "CE"
            },
            {
                "id": 2018,
                "name": "Espírito Santo",
                "state_code": "ES"
            },
            {
                "id": 2017,
                "name": "Federal District",
                "state_code": "DF"
            },
            {
                "id": 2000,
                "name": "Goiás",
                "state_code": "GO"
            },
            {
                "id": 2015,
                "name": "Maranhão",
                "state_code": "MA"
            },
            {
                "id": 2011,
                "name": "Mato Grosso",
                "state_code": "MT"
            },
            {
                "id": 2010,
                "name": "Mato Grosso do Sul",
                "state_code": "MS"
            },
            {
                "id": 1998,
                "name": "Minas Gerais",
                "state_code": "MG"
            },
            {
                "id": 2009,
                "name": "Pará",
                "state_code": "PA"
            },
            {
                "id": 2005,
                "name": "Paraíba",
                "state_code": "PB"
            },
            {
                "id": 2022,
                "name": "Paraná",
                "state_code": "PR"
            },
            {
                "id": 2006,
                "name": "Pernambuco",
                "state_code": "PE"
            },
            {
                "id": 2008,
                "name": "Piauí",
                "state_code": "PI"
            },
            {
                "id": 1997,
                "name": "Rio de Janeiro",
                "state_code": "RJ"
            },
            {
                "id": 2019,
                "name": "Rio Grande do Norte",
                "state_code": "RN"
            },
            {
                "id": 2001,
                "name": "Rio Grande do Sul",
                "state_code": "RS"
            },
            {
                "id": 2013,
                "name": "Rondônia",
                "state_code": "RO"
            },
            {
                "id": 4858,
                "name": "Roraima",
                "state_code": "RR"
            },
            {
                "id": 2014,
                "name": "Santa Catarina",
                "state_code": "SC"
            },
            {
                "id": 2021,
                "name": "São Paulo",
                "state_code": "SP"
            },
            {
                "id": 2003,
                "name": "Sergipe",
                "state_code": "SE"
            },
            {
                "id": 2020,
                "name": "Tocantins",
                "state_code": "TO"
            }
        ]
    },
    {
        "name": "British Indian Ocean Territory",
        "iso3": "IOT",
        "iso2": "IO",
        "phone_code": "246",
        "capital": "Diego Garcia",
        "currency": "USD",
        "currency_symbol": "$",
        "tld": ".io",
        "native": "British Indian Ocean Territory",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Indian/Chagos",
                "gmtOffset": 21600,
                "gmtOffsetName": "UTC+06:00",
                "abbreviation": "IOT",
                "tzName": "Indian Ocean Time"
            }
        ],
        "translations": {
            "br": "Território Britânico do Oceano íÍdico",
            "pt": "Território Britânico do Oceano Índico",
            "nl": "Britse Gebieden in de Indische Oceaan",
            "hr": "Britanski Indijskooceanski teritorij",
            "fa": "قلمرو بریتانیا در اقیانوس هند",
            "de": "Britisches Territorium im Indischen Ozean",
            "es": "Territorio Británico del Océano Índico",
            "fr": "Territoire britannique de l'océan Indien",
            "ja": "イギリス領インド洋地域",
            "it": "Territorio britannico dell'oceano indiano"
        },
        "latitude": "-6.00000000",
        "longitude": "71.50000000",
        "emoji": "🇮🇴",
        "emojiU": "U+1F1EE U+1F1F4",
        "states": []
    },
    {
        "name": "Brunei",
        "iso3": "BRN",
        "iso2": "BN",
        "phone_code": "673",
        "capital": "Bandar Seri Begawan",
        "currency": "BND",
        "currency_symbol": "B$",
        "tld": ".bn",
        "native": "Negara Brunei Darussalam",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Brunei",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "BNT",
                "tzName": "Brunei Darussalam Time"
            }
        ],
        "translations": {
            "br": "Brunei",
            "pt": "Brunei",
            "nl": "Brunei",
            "hr": "Brunej",
            "fa": "برونئی",
            "de": "Brunei",
            "es": "Brunei",
            "fr": "Brunei",
            "ja": "ブルネイ・ダルサラーム",
            "it": "Brunei"
        },
        "latitude": "4.50000000",
        "longitude": "114.66666666",
        "emoji": "🇧🇳",
        "emojiU": "U+1F1E7 U+1F1F3",
        "states": [
            {
                "id": 1217,
                "name": "Belait District",
                "state_code": "BE"
            },
            {
                "id": 1216,
                "name": "Brunei-Muara District",
                "state_code": "BM"
            },
            {
                "id": 1218,
                "name": "Temburong District",
                "state_code": "TE"
            },
            {
                "id": 1219,
                "name": "Tutong District",
                "state_code": "TU"
            }
        ]
    },
    {
        "name": "Bulgaria",
        "iso3": "BGR",
        "iso2": "BG",
        "phone_code": "359",
        "capital": "Sofia",
        "currency": "BGN",
        "currency_symbol": "Лв.",
        "tld": ".bg",
        "native": "България",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Sofia",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "translations": {
            "br": "Bulgária",
            "pt": "Bulgária",
            "nl": "Bulgarije",
            "hr": "Bugarska",
            "fa": "بلغارستان",
            "de": "Bulgarien",
            "es": "Bulgaria",
            "fr": "Bulgarie",
            "ja": "ブルガリア",
            "it": "Bulgaria"
        },
        "latitude": "43.00000000",
        "longitude": "25.00000000",
        "emoji": "🇧🇬",
        "emojiU": "U+1F1E7 U+1F1EC",
        "states": [
            {
                "id": 4699,
                "name": "Blagoevgrad Province",
                "state_code": "01"
            },
            {
                "id": 4715,
                "name": "Burgas Province",
                "state_code": "02"
            },
            {
                "id": 4718,
                "name": "Dobrich Province",
                "state_code": "08"
            },
            {
                "id": 4693,
                "name": "Gabrovo Province",
                "state_code": "07"
            },
            {
                "id": 4704,
                "name": "Haskovo Province",
                "state_code": "26"
            },
            {
                "id": 4702,
                "name": "Kardzhali Province",
                "state_code": "09"
            },
            {
                "id": 4703,
                "name": "Kyustendil Province",
                "state_code": "10"
            },
            {
                "id": 4710,
                "name": "Lovech Province",
                "state_code": "11"
            },
            {
                "id": 4696,
                "name": "Montana Province",
                "state_code": "12"
            },
            {
                "id": 4712,
                "name": "Pazardzhik Province",
                "state_code": "13"
            },
            {
                "id": 4695,
                "name": "Pernik Province",
                "state_code": "14"
            },
            {
                "id": 4706,
                "name": "Pleven Province",
                "state_code": "15"
            },
            {
                "id": 4701,
                "name": "Plovdiv Province",
                "state_code": "16"
            },
            {
                "id": 4698,
                "name": "Razgrad Province",
                "state_code": "17"
            },
            {
                "id": 4713,
                "name": "Ruse Province",
                "state_code": "18"
            },
            {
                "id": 4882,
                "name": "Shumen",
                "state_code": "27"
            },
            {
                "id": 4708,
                "name": "Silistra Province",
                "state_code": "19"
            },
            {
                "id": 4700,
                "name": "Sliven Province",
                "state_code": "20"
            },
            {
                "id": 4694,
                "name": "Smolyan Province",
                "state_code": "21"
            },
            {
                "id": 4705,
                "name": "Sofia City Province",
                "state_code": "22"
            },
            {
                "id": 4719,
                "name": "Sofia Province",
                "state_code": "23"
            },
            {
                "id": 4707,
                "name": "Stara Zagora Province",
                "state_code": "24"
            },
            {
                "id": 4714,
                "name": "Targovishte Province",
                "state_code": "25"
            },
            {
                "id": 4717,
                "name": "Varna Province",
                "state_code": "03"
            },
            {
                "id": 4709,
                "name": "Veliko Tarnovo Province",
                "state_code": "04"
            },
            {
                "id": 4697,
                "name": "Vidin Province",
                "state_code": "05"
            },
            {
                "id": 4711,
                "name": "Vratsa Province",
                "state_code": "06"
            },
            {
                "id": 4716,
                "name": "Yambol Province",
                "state_code": "28"
            }
        ]
    },
    {
        "name": "Burkina Faso",
        "iso3": "BFA",
        "iso2": "BF",
        "phone_code": "226",
        "capital": "Ouagadougou",
        "currency": "XOF",
        "currency_symbol": "CFA",
        "tld": ".bf",
        "native": "Burkina Faso",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa/Ouagadougou",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "translations": {
            "br": "Burkina Faso",
            "pt": "Burquina Faso",
            "nl": "Burkina Faso",
            "hr": "Burkina Faso",
            "fa": "بورکینافاسو",
            "de": "Burkina Faso",
            "es": "Burkina Faso",
            "fr": "Burkina Faso",
            "ja": "ブルキナファソ",
            "it": "Burkina Faso"
        },
        "latitude": "13.00000000",
        "longitude": "-2.00000000",
        "emoji": "🇧🇫",
        "emojiU": "U+1F1E7 U+1F1EB",
        "states": [
            {
                "id": 3160,
                "name": "Balé Province",
                "state_code": "BAL"
            },
            {
                "id": 3155,
                "name": "Bam Province",
                "state_code": "BAM"
            },
            {
                "id": 3120,
                "name": "Banwa Province",
                "state_code": "BAN"
            },
            {
                "id": 3152,
                "name": "Bazèga Province",
                "state_code": "BAZ"
            },
            {
                "id": 3138,
                "name": "Boucle du Mouhoun Region",
                "state_code": "01"
            },
            {
                "id": 3121,
                "name": "Bougouriba Province",
                "state_code": "BGR"
            },
            {
                "id": 3131,
                "name": "Boulgou",
                "state_code": "BLG"
            },
            {
                "id": 3153,
                "name": "Cascades Region",
                "state_code": "02"
            },
            {
                "id": 3136,
                "name": "Centre",
                "state_code": "03"
            },
            {
                "id": 3162,
                "name": "Centre-Est Region",
                "state_code": "04"
            },
            {
                "id": 3127,
                "name": "Centre-Nord Region",
                "state_code": "05"
            },
            {
                "id": 3115,
                "name": "Centre-Ouest Region",
                "state_code": "06"
            },
            {
                "id": 3149,
                "name": "Centre-Sud Region",
                "state_code": "07"
            },
            {
                "id": 3167,
                "name": "Comoé Province",
                "state_code": "COM"
            },
            {
                "id": 3158,
                "name": "Est Region",
                "state_code": "08"
            },
            {
                "id": 3148,
                "name": "Ganzourgou Province",
                "state_code": "GAN"
            },
            {
                "id": 3122,
                "name": "Gnagna Province",
                "state_code": "GNA"
            },
            {
                "id": 3143,
                "name": "Gourma Province",
                "state_code": "GOU"
            },
            {
                "id": 3165,
                "name": "Hauts-Bassins Region",
                "state_code": "09"
            },
            {
                "id": 3129,
                "name": "Houet Province",
                "state_code": "HOU"
            },
            {
                "id": 3135,
                "name": "Ioba Province",
                "state_code": "IOB"
            },
            {
                "id": 3168,
                "name": "Kadiogo Province",
                "state_code": "KAD"
            },
            {
                "id": 3112,
                "name": "Kénédougou Province",
                "state_code": "KEN"
            },
            {
                "id": 3132,
                "name": "Komondjari Province",
                "state_code": "KMD"
            },
            {
                "id": 3157,
                "name": "Kompienga Province",
                "state_code": "KMP"
            },
            {
                "id": 3146,
                "name": "Kossi Province",
                "state_code": "KOS"
            },
            {
                "id": 3133,
                "name": "Koulpélogo Province",
                "state_code": "KOP"
            },
            {
                "id": 3161,
                "name": "Kouritenga Province",
                "state_code": "KOT"
            },
            {
                "id": 3147,
                "name": "Kourwéogo Province",
                "state_code": "KOW"
            },
            {
                "id": 3159,
                "name": "Léraba Province",
                "state_code": "LER"
            },
            {
                "id": 3151,
                "name": "Loroum Province",
                "state_code": "LOR"
            },
            {
                "id": 3123,
                "name": "Mouhoun",
                "state_code": "MOU"
            },
            {
                "id": 3116,
                "name": "Nahouri Province",
                "state_code": "NAO"
            },
            {
                "id": 3113,
                "name": "Namentenga Province",
                "state_code": "NAM"
            },
            {
                "id": 3142,
                "name": "Nayala Province",
                "state_code": "NAY"
            },
            {
                "id": 3164,
                "name": "Nord Region, Burkina Faso",
                "state_code": "10"
            },
            {
                "id": 3156,
                "name": "Noumbiel Province",
                "state_code": "NOU"
            },
            {
                "id": 3141,
                "name": "Oubritenga Province",
                "state_code": "OUB"
            },
            {
                "id": 3144,
                "name": "Oudalan Province",
                "state_code": "OUD"
            },
            {
                "id": 3117,
                "name": "Passoré Province",
                "state_code": "PAS"
            },
            {
                "id": 3125,
                "name": "Plateau-Central Region",
                "state_code": "11"
            },
            {
                "id": 3163,
                "name": "Poni Province",
                "state_code": "PON"
            },
            {
                "id": 3114,
                "name": "Sahel Region",
                "state_code": "12"
            },
            {
                "id": 3154,
                "name": "Sanguié Province",
                "state_code": "SNG"
            },
            {
                "id": 3126,
                "name": "Sanmatenga Province",
                "state_code": "SMT"
            },
            {
                "id": 3139,
                "name": "Séno Province",
                "state_code": "SEN"
            },
            {
                "id": 3119,
                "name": "Sissili Province",
                "state_code": "SIS"
            },
            {
                "id": 3166,
                "name": "Soum Province",
                "state_code": "SOM"
            },
            {
                "id": 3137,
                "name": "Sourou Province",
                "state_code": "SOR"
            },
            {
                "id": 3140,
                "name": "Sud-Ouest Region",
                "state_code": "13"
            },
            {
                "id": 3128,
                "name": "Tapoa Province",
                "state_code": "TAP"
            },
            {
                "id": 3134,
                "name": "Tuy Province",
                "state_code": "TUI"
            },
            {
                "id": 3124,
                "name": "Yagha Province",
                "state_code": "YAG"
            },
            {
                "id": 3150,
                "name": "Yatenga Province",
                "state_code": "YAT"
            },
            {
                "id": 3145,
                "name": "Ziro Province",
                "state_code": "ZIR"
            },
            {
                "id": 3130,
                "name": "Zondoma Province",
                "state_code": "ZON"
            },
            {
                "id": 3118,
                "name": "Zoundwéogo Province",
                "state_code": "ZOU"
            }
        ]
    },
    {
        "name": "Burundi",
        "iso3": "BDI",
        "iso2": "BI",
        "phone_code": "257",
        "capital": "Bujumbura",
        "currency": "BIF",
        "currency_symbol": "FBu",
        "tld": ".bi",
        "native": "Burundi",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa/Bujumbura",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "CAT",
                "tzName": "Central Africa Time"
            }
        ],
        "translations": {
            "br": "Burundi",
            "pt": "Burúndi",
            "nl": "Burundi",
            "hr": "Burundi",
            "fa": "بوروندی",
            "de": "Burundi",
            "es": "Burundi",
            "fr": "Burundi",
            "ja": "ブルンジ",
            "it": "Burundi"
        },
        "latitude": "-3.50000000",
        "longitude": "30.00000000",
        "emoji": "🇧🇮",
        "emojiU": "U+1F1E7 U+1F1EE",
        "states": [
            {
                "id": 3196,
                "name": "Bubanza Province",
                "state_code": "BB"
            },
            {
                "id": 3198,
                "name": "Bujumbura Mairie Province",
                "state_code": "BM"
            },
            {
                "id": 3200,
                "name": "Bujumbura Rural Province",
                "state_code": "BL"
            },
            {
                "id": 3202,
                "name": "Bururi Province",
                "state_code": "BR"
            },
            {
                "id": 3201,
                "name": "Cankuzo Province",
                "state_code": "CA"
            },
            {
                "id": 3190,
                "name": "Cibitoke Province",
                "state_code": "CI"
            },
            {
                "id": 3197,
                "name": "Gitega Province",
                "state_code": "GI"
            },
            {
                "id": 3194,
                "name": "Karuzi Province",
                "state_code": "KR"
            },
            {
                "id": 3192,
                "name": "Kayanza Province",
                "state_code": "KY"
            },
            {
                "id": 3195,
                "name": "Kirundo Province",
                "state_code": "KI"
            },
            {
                "id": 3188,
                "name": "Makamba Province",
                "state_code": "MA"
            },
            {
                "id": 3193,
                "name": "Muramvya Province",
                "state_code": "MU"
            },
            {
                "id": 3186,
                "name": "Muyinga Province",
                "state_code": "MY"
            },
            {
                "id": 3187,
                "name": "Mwaro Province",
                "state_code": "MW"
            },
            {
                "id": 3199,
                "name": "Ngozi Province",
                "state_code": "NG"
            },
            {
                "id": 3185,
                "name": "Rumonge Province",
                "state_code": "RM"
            },
            {
                "id": 3189,
                "name": "Rutana Province",
                "state_code": "RT"
            },
            {
                "id": 3191,
                "name": "Ruyigi Province",
                "state_code": "RY"
            }
        ]
    },
    {
        "name": "Cambodia",
        "iso3": "KHM",
        "iso2": "KH",
        "phone_code": "855",
        "capital": "Phnom Penh",
        "currency": "KHR",
        "currency_symbol": "KHR",
        "tld": ".kh",
        "native": "Kâmpŭchéa",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Phnom_Penh",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "ICT",
                "tzName": "Indochina Time"
            }
        ],
        "translations": {
            "br": "Camboja",
            "pt": "Camboja",
            "nl": "Cambodja",
            "hr": "Kambodža",
            "fa": "کامبوج",
            "de": "Kambodscha",
            "es": "Camboya",
            "fr": "Cambodge",
            "ja": "カンボジア",
            "it": "Cambogia"
        },
        "latitude": "13.00000000",
        "longitude": "105.00000000",
        "emoji": "🇰🇭",
        "emojiU": "U+1F1F0 U+1F1ED",
        "states": [
            {
                "id": 3984,
                "name": "Banteay Meanchey Province",
                "state_code": "1"
            },
            {
                "id": 3976,
                "name": "Battambang Province",
                "state_code": "2"
            },
            {
                "id": 3991,
                "name": "Kampong Cham Province",
                "state_code": "3"
            },
            {
                "id": 3979,
                "name": "Kampong Chhnang Province",
                "state_code": "4"
            },
            {
                "id": 3988,
                "name": "Kampong Speu Province",
                "state_code": "5"
            },
            {
                "id": 3981,
                "name": "Kampot Province",
                "state_code": "7"
            },
            {
                "id": 3983,
                "name": "Kandal Province",
                "state_code": "8"
            },
            {
                "id": 3978,
                "name": "Kep Province",
                "state_code": "23"
            },
            {
                "id": 3982,
                "name": "Koh Kong Province",
                "state_code": "9"
            },
            {
                "id": 3986,
                "name": "Kratié Province",
                "state_code": "10"
            },
            {
                "id": 3985,
                "name": "Mondulkiri Province",
                "state_code": "11"
            },
            {
                "id": 3987,
                "name": "Oddar Meanchey Province",
                "state_code": "22"
            },
            {
                "id": 3980,
                "name": "Pailin Province",
                "state_code": "24"
            },
            {
                "id": 3994,
                "name": "Phnom Penh",
                "state_code": "12"
            },
            {
                "id": 3973,
                "name": "Preah Vihear Province",
                "state_code": "13"
            },
            {
                "id": 3974,
                "name": "Prey Veng Province",
                "state_code": "14"
            },
            {
                "id": 3977,
                "name": "Pursat Province",
                "state_code": "15"
            },
            {
                "id": 3990,
                "name": "Ratanakiri Province",
                "state_code": "16"
            },
            {
                "id": 3992,
                "name": "Siem Reap Province",
                "state_code": "17"
            },
            {
                "id": 3989,
                "name": "Sihanoukville Province",
                "state_code": "18"
            },
            {
                "id": 3993,
                "name": "Stung Treng Province",
                "state_code": "19"
            },
            {
                "id": 3972,
                "name": "Svay Rieng Province",
                "state_code": "20"
            },
            {
                "id": 3975,
                "name": "Takéo Province",
                "state_code": "21"
            }
        ]
    },
    {
        "name": "Cameroon",
        "iso3": "CMR",
        "iso2": "CM",
        "phone_code": "237",
        "capital": "Yaounde",
        "currency": "XAF",
        "currency_symbol": "FCFA",
        "tld": ".cm",
        "native": "Cameroon",
        "region": "Africa",
        "subregion": "Middle Africa",
        "timezones": [
            {
                "zoneName": "Africa/Douala",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WAT",
                "tzName": "West Africa Time"
            }
        ],
        "translations": {
            "br": "Camarões",
            "pt": "Camarões",
            "nl": "Kameroen",
            "hr": "Kamerun",
            "fa": "کامرون",
            "de": "Kamerun",
            "es": "Camerún",
            "fr": "Cameroun",
            "ja": "カメルーン",
            "it": "Camerun"
        },
        "latitude": "6.00000000",
        "longitude": "12.00000000",
        "emoji": "🇨🇲",
        "emojiU": "U+1F1E8 U+1F1F2",
        "states": [
            {
                "id": 2663,
                "name": "Adamawa",
                "state_code": "AD"
            },
            {
                "id": 2660,
                "name": "Centre",
                "state_code": "CE"
            },
            {
                "id": 2661,
                "name": "East",
                "state_code": "ES"
            },
            {
                "id": 2656,
                "name": "Far North",
                "state_code": "EN"
            },
            {
                "id": 2662,
                "name": "Littoral",
                "state_code": "LT"
            },
            {
                "id": 2665,
                "name": "North",
                "state_code": "NO"
            },
            {
                "id": 2657,
                "name": "Northwest",
                "state_code": "NW"
            },
            {
                "id": 2659,
                "name": "South",
                "state_code": "SU"
            },
            {
                "id": 2658,
                "name": "Southwest",
                "state_code": "SW"
            },
            {
                "id": 2664,
                "name": "West",
                "state_code": "OU"
            }
        ]
    },
    {
        "name": "Canada",
        "iso3": "CAN",
        "iso2": "CA",
        "phone_code": "1",
        "capital": "Ottawa",
        "currency": "CAD",
        "currency_symbol": "$",
        "tld": ".ca",
        "native": "Canada",
        "region": "Americas",
        "subregion": "Northern America",
        "timezones": [
            {
                "zoneName": "America/Atikokan",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America)"
            },
            {
                "zoneName": "America/Blanc-Sablon",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            },
            {
                "zoneName": "America/Cambridge_Bay",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America)"
            },
            {
                "zoneName": "America/Creston",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America)"
            },
            {
                "zoneName": "America/Dawson",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America)"
            },
            {
                "zoneName": "America/Dawson_Creek",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America)"
            },
            {
                "zoneName": "America/Edmonton",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America)"
            },
            {
                "zoneName": "America/Fort_Nelson",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America)"
            },
            {
                "zoneName": "America/Glace_Bay",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            },
            {
                "zoneName": "America/Goose_Bay",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            },
            {
                "zoneName": "America/Halifax",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            },
            {
                "zoneName": "America/Inuvik",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America"
            },
            {
                "zoneName": "America/Iqaluit",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America/Moncton",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            },
            {
                "zoneName": "America/Nipigon",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America/Pangnirtung",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America/Rainy_River",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America/Rankin_Inlet",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America/Regina",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America/Resolute",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America/St_Johns",
                "gmtOffset": -12600,
                "gmtOffsetName": "UTC-03:30",
                "abbreviation": "NST",
                "tzName": "Newfoundland Standard Time"
            },
            {
                "zoneName": "America/Swift_Current",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America/Thunder_Bay",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America/Toronto",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America/Vancouver",
                "gmtOffset": -28800,
                "gmtOffsetName": "UTC-08:00",
                "abbreviation": "PST",
                "tzName": "Pacific Standard Time (North America"
            },
            {
                "zoneName": "America/Whitehorse",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America"
            },
            {
                "zoneName": "America/Winnipeg",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America/Yellowknife",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America"
            }
        ],
        "translations": {
            "br": "Canadá",
            "pt": "Canadá",
            "nl": "Canada",
            "hr": "Kanada",
            "fa": "کانادا",
            "de": "Kanada",
            "es": "Canadá",
            "fr": "Canada",
            "ja": "カナダ",
            "it": "Canada"
        },
        "latitude": "60.00000000",
        "longitude": "-95.00000000",
        "emoji": "🇨🇦",
        "emojiU": "U+1F1E8 U+1F1E6",
        "states": [
            {
                "id": 872,
                "name": "Alberta",
                "state_code": "AB"
            },
            {
                "id": 875,
                "name": "British Columbia",
                "state_code": "BC"
            },
            {
                "id": 867,
                "name": "Manitoba",
                "state_code": "MB"
            },
            {
                "id": 868,
                "name": "New Brunswick",
                "state_code": "NB"
            },
            {
                "id": 877,
                "name": "Newfoundland and Labrador",
                "state_code": "NL"
            },
            {
                "id": 878,
                "name": "Northwest Territories",
                "state_code": "NT"
            },
            {
                "id": 874,
                "name": "Nova Scotia",
                "state_code": "NS"
            },
            {
                "id": 876,
                "name": "Nunavut",
                "state_code": "NU"
            },
            {
                "id": 866,
                "name": "Ontario",
                "state_code": "ON"
            },
            {
                "id": 871,
                "name": "Prince Edward Island",
                "state_code": "PE"
            },
            {
                "id": 873,
                "name": "Quebec",
                "state_code": "QC"
            },
            {
                "id": 870,
                "name": "Saskatchewan",
                "state_code": "SK"
            },
            {
                "id": 869,
                "name": "Yukon",
                "state_code": "YT"
            }
        ]
    },
    {
        "name": "Cape Verde",
        "iso3": "CPV",
        "iso2": "CV",
        "phone_code": "238",
        "capital": "Praia",
        "currency": "CVE",
        "currency_symbol": "$",
        "tld": ".cv",
        "native": "Cabo Verde",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Atlantic/Cape_Verde",
                "gmtOffset": -3600,
                "gmtOffsetName": "UTC-01:00",
                "abbreviation": "CVT",
                "tzName": "Cape Verde Time"
            }
        ],
        "translations": {
            "br": "Cabo Verde",
            "pt": "Cabo Verde",
            "nl": "Kaapverdië",
            "hr": "Zelenortska Republika",
            "fa": "کیپ ورد",
            "de": "Kap Verde",
            "es": "Cabo Verde",
            "fr": "Cap Vert",
            "ja": "カーボベルデ",
            "it": "Capo Verde"
        },
        "latitude": "16.00000000",
        "longitude": "-24.00000000",
        "emoji": "🇨🇻",
        "emojiU": "U+1F1E8 U+1F1FB",
        "states": [
            {
                "id": 2994,
                "name": "Barlavento Islands",
                "state_code": "B"
            },
            {
                "id": 2999,
                "name": "Boa Vista",
                "state_code": "BV"
            },
            {
                "id": 2996,
                "name": "Brava",
                "state_code": "BR"
            },
            {
                "id": 2991,
                "name": "Maio Municipality",
                "state_code": "MA"
            },
            {
                "id": 2987,
                "name": "Mosteiros",
                "state_code": "MO"
            },
            {
                "id": 2997,
                "name": "Paul",
                "state_code": "PA"
            },
            {
                "id": 2989,
                "name": "Porto Novo",
                "state_code": "PN"
            },
            {
                "id": 2988,
                "name": "Praia",
                "state_code": "PR"
            },
            {
                "id": 2982,
                "name": "Ribeira Brava Municipality",
                "state_code": "RB"
            },
            {
                "id": 3002,
                "name": "Ribeira Grande",
                "state_code": "RG"
            },
            {
                "id": 2984,
                "name": "Ribeira Grande de Santiago",
                "state_code": "RS"
            },
            {
                "id": 2998,
                "name": "Sal",
                "state_code": "SL"
            },
            {
                "id": 2985,
                "name": "Santa Catarina",
                "state_code": "CA"
            },
            {
                "id": 2995,
                "name": "Santa Catarina do Fogo",
                "state_code": "CF"
            },
            {
                "id": 3004,
                "name": "Santa Cruz",
                "state_code": "CR"
            },
            {
                "id": 2986,
                "name": "São Domingos",
                "state_code": "SD"
            },
            {
                "id": 3000,
                "name": "São Filipe",
                "state_code": "SF"
            },
            {
                "id": 2993,
                "name": "São Lourenço dos Órgãos",
                "state_code": "SO"
            },
            {
                "id": 2990,
                "name": "São Miguel",
                "state_code": "SM"
            },
            {
                "id": 3001,
                "name": "São Vicente",
                "state_code": "SV"
            },
            {
                "id": 2992,
                "name": "Sotavento Islands",
                "state_code": "S"
            },
            {
                "id": 2983,
                "name": "Tarrafal",
                "state_code": "TA"
            },
            {
                "id": 3003,
                "name": "Tarrafal de São Nicolau",
                "state_code": "TS"
            }
        ]
    },
    {
        "name": "Cayman Islands",
        "iso3": "CYM",
        "iso2": "KY",
        "phone_code": "+1-345",
        "capital": "George Town",
        "currency": "KYD",
        "currency_symbol": "$",
        "tld": ".ky",
        "native": "Cayman Islands",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/Cayman",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            }
        ],
        "translations": {
            "br": "Ilhas Cayman",
            "pt": "Ilhas Caimão",
            "nl": "Caymaneilanden",
            "hr": "Kajmanski otoci",
            "fa": "جزایر کیمن",
            "de": "Kaimaninseln",
            "es": "Islas Caimán",
            "fr": "Îles Caïmans",
            "ja": "ケイマン諸島",
            "it": "Isole Cayman"
        },
        "latitude": "19.50000000",
        "longitude": "-80.50000000",
        "emoji": "🇰🇾",
        "emojiU": "U+1F1F0 U+1F1FE",
        "states": []
    },
    {
        "name": "Central African Republic",
        "iso3": "CAF",
        "iso2": "CF",
        "phone_code": "236",
        "capital": "Bangui",
        "currency": "XAF",
        "currency_symbol": "FCFA",
        "tld": ".cf",
        "native": "Ködörösêse tî Bêafrîka",
        "region": "Africa",
        "subregion": "Middle Africa",
        "timezones": [
            {
                "zoneName": "Africa/Bangui",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WAT",
                "tzName": "West Africa Time"
            }
        ],
        "translations": {
            "br": "República Centro-Africana",
            "pt": "República Centro-Africana",
            "nl": "Centraal-Afrikaanse Republiek",
            "hr": "Srednjoafrička Republika",
            "fa": "جمهوری آفریقای مرکزی",
            "de": "Zentralafrikanische Republik",
            "es": "República Centroafricana",
            "fr": "République centrafricaine",
            "ja": "中央アフリカ共和国",
            "it": "Repubblica Centrafricana"
        },
        "latitude": "7.00000000",
        "longitude": "21.00000000",
        "emoji": "🇨🇫",
        "emojiU": "U+1F1E8 U+1F1EB",
        "states": [
            {
                "id": 1259,
                "name": "Bamingui-Bangoran Prefecture",
                "state_code": "BB"
            },
            {
                "id": 1262,
                "name": "Bangui",
                "state_code": "BGF"
            },
            {
                "id": 1264,
                "name": "Basse-Kotto Prefecture",
                "state_code": "BK"
            },
            {
                "id": 1258,
                "name": "Haut-Mbomou Prefecture",
                "state_code": "HM"
            },
            {
                "id": 1268,
                "name": "Haute-Kotto Prefecture",
                "state_code": "HK"
            },
            {
                "id": 1263,
                "name": "Kémo Prefecture",
                "state_code": "KG"
            },
            {
                "id": 1256,
                "name": "Lobaye Prefecture",
                "state_code": "LB"
            },
            {
                "id": 1257,
                "name": "Mambéré-Kadéï",
                "state_code": "HS"
            },
            {
                "id": 1266,
                "name": "Mbomou Prefecture",
                "state_code": "MB"
            },
            {
                "id": 1253,
                "name": "Nana-Grébizi Economic Prefecture",
                "state_code": "KB"
            },
            {
                "id": 1260,
                "name": "Nana-Mambéré Prefecture",
                "state_code": "NM"
            },
            {
                "id": 1255,
                "name": "Ombella-M'Poko Prefecture",
                "state_code": "MP"
            },
            {
                "id": 1265,
                "name": "Ouaka Prefecture",
                "state_code": "UK"
            },
            {
                "id": 1254,
                "name": "Ouham Prefecture",
                "state_code": "AC"
            },
            {
                "id": 1267,
                "name": "Ouham-Pendé Prefecture",
                "state_code": "OP"
            },
            {
                "id": 1252,
                "name": "Sangha-Mbaéré",
                "state_code": "SE"
            },
            {
                "id": 1261,
                "name": "Vakaga Prefecture",
                "state_code": "VK"
            }
        ]
    },
    {
        "name": "Chad",
        "iso3": "TCD",
        "iso2": "TD",
        "phone_code": "235",
        "capital": "N'Djamena",
        "currency": "XAF",
        "currency_symbol": "FCFA",
        "tld": ".td",
        "native": "Tchad",
        "region": "Africa",
        "subregion": "Middle Africa",
        "timezones": [
            {
                "zoneName": "Africa/Ndjamena",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WAT",
                "tzName": "West Africa Time"
            }
        ],
        "translations": {
            "br": "Chade",
            "pt": "Chade",
            "nl": "Tsjaad",
            "hr": "Čad",
            "fa": "چاد",
            "de": "Tschad",
            "es": "Chad",
            "fr": "Tchad",
            "ja": "チャド",
            "it": "Ciad"
        },
        "latitude": "15.00000000",
        "longitude": "19.00000000",
        "emoji": "🇹🇩",
        "emojiU": "U+1F1F9 U+1F1E9",
        "states": [
            {
                "id": 3583,
                "name": "Bahr el Gazel",
                "state_code": "BG"
            },
            {
                "id": 3590,
                "name": "Batha Region",
                "state_code": "BA"
            },
            {
                "id": 3574,
                "name": "Borkou",
                "state_code": "BO"
            },
            {
                "id": 3578,
                "name": "Ennedi Region",
                "state_code": "EN"
            },
            {
                "id": 3575,
                "name": "Ennedi-Est",
                "state_code": "EE"
            },
            {
                "id": 3584,
                "name": "Ennedi-Ouest",
                "state_code": "EO"
            },
            {
                "id": 3576,
                "name": "Guéra Region",
                "state_code": "GR"
            },
            {
                "id": 3573,
                "name": "Hadjer-Lamis",
                "state_code": "HL"
            },
            {
                "id": 3588,
                "name": "Kanem Region",
                "state_code": "KA"
            },
            {
                "id": 3577,
                "name": "Lac Region",
                "state_code": "LC"
            },
            {
                "id": 3585,
                "name": "Logone Occidental Region",
                "state_code": "LO"
            },
            {
                "id": 3591,
                "name": "Logone Oriental Region",
                "state_code": "LR"
            },
            {
                "id": 3589,
                "name": "Mandoul Region",
                "state_code": "MA"
            },
            {
                "id": 3580,
                "name": "Mayo-Kebbi Est Region",
                "state_code": "ME"
            },
            {
                "id": 3571,
                "name": "Mayo-Kebbi Ouest Region",
                "state_code": "MO"
            },
            {
                "id": 3570,
                "name": "Moyen-Chari Region",
                "state_code": "MC"
            },
            {
                "id": 3586,
                "name": "N'Djamena",
                "state_code": "ND"
            },
            {
                "id": 3582,
                "name": "Ouaddaï Region",
                "state_code": "OD"
            },
            {
                "id": 3592,
                "name": "Salamat Region",
                "state_code": "SA"
            },
            {
                "id": 3572,
                "name": "Sila Region",
                "state_code": "SI"
            },
            {
                "id": 3579,
                "name": "Tandjilé Region",
                "state_code": "TA"
            },
            {
                "id": 3587,
                "name": "Tibesti Region",
                "state_code": "TI"
            },
            {
                "id": 3581,
                "name": "Wadi Fira Region",
                "state_code": "WF"
            }
        ]
    },
    {
        "name": "Chile",
        "iso3": "CHL",
        "iso2": "CL",
        "phone_code": "56",
        "capital": "Santiago",
        "currency": "CLP",
        "currency_symbol": "$",
        "tld": ".cl",
        "native": "Chile",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America/Punta_Arenas",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "CLST",
                "tzName": "Chile Summer Time"
            },
            {
                "zoneName": "America/Santiago",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "CLST",
                "tzName": "Chile Summer Time"
            },
            {
                "zoneName": "Pacific/Easter",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EASST",
                "tzName": "Easter Island Summer Time"
            }
        ],
        "translations": {
            "br": "Chile",
            "pt": "Chile",
            "nl": "Chili",
            "hr": "Čile",
            "fa": "شیلی",
            "de": "Chile",
            "es": "Chile",
            "fr": "Chili",
            "ja": "チリ",
            "it": "Cile"
        },
        "latitude": "-30.00000000",
        "longitude": "-71.00000000",
        "emoji": "🇨🇱",
        "emojiU": "U+1F1E8 U+1F1F1",
        "states": [
            {
                "id": 2832,
                "name": "Antofagasta Region",
                "state_code": "AN"
            },
            {
                "id": 2826,
                "name": "Araucanía Region",
                "state_code": "AR"
            },
            {
                "id": 2829,
                "name": "Arica y Parinacota Region",
                "state_code": "AP"
            },
            {
                "id": 2823,
                "name": "Atacama Region",
                "state_code": "AT"
            },
            {
                "id": 2828,
                "name": "Aysén Region",
                "state_code": "AI"
            },
            {
                "id": 2827,
                "name": "Bío Bío Region",
                "state_code": "BI"
            },
            {
                "id": 2825,
                "name": "Coquimbo Region",
                "state_code": "CO"
            },
            {
                "id": 2835,
                "name": "Los Lagos Region",
                "state_code": "LL"
            },
            {
                "id": 2834,
                "name": "Los Ríos Region",
                "state_code": "LR"
            },
            {
                "id": 2836,
                "name": "Magellan and the Chilean Antarctic Region",
                "state_code": "MA"
            },
            {
                "id": 2833,
                "name": "Maule Region",
                "state_code": "ML"
            },
            {
                "id": 2831,
                "name": "Ñuble Region",
                "state_code": "NB"
            },
            {
                "id": 2838,
                "name": "O'Higgins",
                "state_code": "LI"
            },
            {
                "id": 2824,
                "name": "Santiago Metropolitan Region",
                "state_code": "RM"
            },
            {
                "id": 2837,
                "name": "Tarapacá Region",
                "state_code": "TA"
            },
            {
                "id": 2830,
                "name": "Valparaíso",
                "state_code": "VS"
            }
        ]
    },
    {
        "name": "China",
        "iso3": "CHN",
        "iso2": "CN",
        "phone_code": "86",
        "capital": "Beijing",
        "currency": "CNY",
        "currency_symbol": "¥",
        "tld": ".cn",
        "native": "中国",
        "region": "Asia",
        "subregion": "Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Shanghai",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "CST",
                "tzName": "China Standard Time"
            },
            {
                "zoneName": "Asia/Urumqi",
                "gmtOffset": 21600,
                "gmtOffsetName": "UTC+06:00",
                "abbreviation": "XJT",
                "tzName": "China Standard Time"
            }
        ],
        "translations": {
            "br": "China",
            "pt": "China",
            "nl": "China",
            "hr": "Kina",
            "fa": "چین",
            "de": "China",
            "es": "China",
            "fr": "Chine",
            "ja": "中国",
            "it": "Cina"
        },
        "latitude": "35.00000000",
        "longitude": "105.00000000",
        "emoji": "🇨🇳",
        "emojiU": "U+1F1E8 U+1F1F3",
        "states": [
            {
                "id": 2251,
                "name": "Anhui",
                "state_code": "AH"
            },
            {
                "id": 2257,
                "name": "Beijing",
                "state_code": "BJ"
            },
            {
                "id": 2271,
                "name": "Chongqing",
                "state_code": "CQ"
            },
            {
                "id": 2248,
                "name": "Fujian",
                "state_code": "FJ"
            },
            {
                "id": 2275,
                "name": "Gansu",
                "state_code": "GS"
            },
            {
                "id": 2279,
                "name": "Guangdong",
                "state_code": "GD"
            },
            {
                "id": 2278,
                "name": "Guangxi Zhuang Autonomous Region",
                "state_code": "GX"
            },
            {
                "id": 2261,
                "name": "Guizhou",
                "state_code": "GZ"
            },
            {
                "id": 2273,
                "name": "Hainan",
                "state_code": "HI"
            },
            {
                "id": 2280,
                "name": "Hebei",
                "state_code": "HE"
            },
            {
                "id": 2265,
                "name": "Heilongjiang",
                "state_code": "HL"
            },
            {
                "id": 2259,
                "name": "Henan",
                "state_code": "HA"
            },
            {
                "id": 2267,
                "name": "Hong Kong",
                "state_code": "HK"
            },
            {
                "id": 2274,
                "name": "Hubei",
                "state_code": "HB"
            },
            {
                "id": 2258,
                "name": "Hunan",
                "state_code": "HN"
            },
            {
                "id": 2269,
                "name": "Inner Mongolia",
                "state_code": "NM"
            },
            {
                "id": 2250,
                "name": "Jiangsu",
                "state_code": "JS"
            },
            {
                "id": 2256,
                "name": "Jiangxi",
                "state_code": "JX"
            },
            {
                "id": 2253,
                "name": "Jilin",
                "state_code": "JL"
            },
            {
                "id": 2276,
                "name": "Keelung",
                "state_code": "TW-KEE"
            },
            {
                "id": 2268,
                "name": "Liaoning",
                "state_code": "LN"
            },
            {
                "id": 2266,
                "name": "Macau",
                "state_code": "MO"
            },
            {
                "id": 2262,
                "name": "Ningxia Hui Autonomous Region",
                "state_code": "NX"
            },
            {
                "id": 2270,
                "name": "Qinghai",
                "state_code": "QH"
            },
            {
                "id": 2272,
                "name": "Shaanxi",
                "state_code": "SN"
            },
            {
                "id": 2252,
                "name": "Shandong",
                "state_code": "SD"
            },
            {
                "id": 2249,
                "name": "Shanghai",
                "state_code": "SH"
            },
            {
                "id": 2254,
                "name": "Shanxi",
                "state_code": "SX"
            },
            {
                "id": 2277,
                "name": "Sichuan",
                "state_code": "SC"
            },
            {
                "id": 2255,
                "name": "Taiwan Province, People's Republic of China",
                "state_code": "TW"
            },
            {
                "id": 2264,
                "name": "Tibet Autonomous Region",
                "state_code": "XZ"
            },
            {
                "id": 2263,
                "name": "Xinjiang",
                "state_code": "XJ"
            },
            {
                "id": 2260,
                "name": "Yunnan",
                "state_code": "YN"
            },
            {
                "id": 2247,
                "name": "Zhejiang",
                "state_code": "ZJ"
            }
        ]
    },
    {
        "name": "Christmas Island",
        "iso3": "CXR",
        "iso2": "CX",
        "phone_code": "61",
        "capital": "Flying Fish Cove",
        "currency": "AUD",
        "currency_symbol": "$",
        "tld": ".cx",
        "native": "Christmas Island",
        "region": "Oceania",
        "subregion": "Australia and New Zealand",
        "timezones": [
            {
                "zoneName": "Indian/Christmas",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "CXT",
                "tzName": "Christmas Island Time"
            }
        ],
        "translations": {
            "br": "Ilha Christmas",
            "pt": "Ilha do Natal",
            "nl": "Christmaseiland",
            "hr": "Božićni otok",
            "fa": "جزیره کریسمس",
            "de": "Weihnachtsinsel",
            "es": "Isla de Navidad",
            "fr": "Île Christmas",
            "ja": "クリスマス島",
            "it": "Isola di Natale"
        },
        "latitude": "-10.50000000",
        "longitude": "105.66666666",
        "emoji": "🇨🇽",
        "emojiU": "U+1F1E8 U+1F1FD",
        "states": []
    },
    {
        "name": "Cocos (Keeling) Islands",
        "iso3": "CCK",
        "iso2": "CC",
        "phone_code": "61",
        "capital": "West Island",
        "currency": "AUD",
        "currency_symbol": "$",
        "tld": ".cc",
        "native": "Cocos (Keeling) Islands",
        "region": "Oceania",
        "subregion": "Australia and New Zealand",
        "timezones": [
            {
                "zoneName": "Indian/Cocos",
                "gmtOffset": 23400,
                "gmtOffsetName": "UTC+06:30",
                "abbreviation": "CCT",
                "tzName": "Cocos Islands Time"
            }
        ],
        "translations": {
            "br": "Ilhas Cocos",
            "pt": "Ilhas dos Cocos",
            "nl": "Cocoseilanden",
            "hr": "Kokosovi Otoci",
            "fa": "جزایر کوکوس",
            "de": "Kokosinseln",
            "es": "Islas Cocos o Islas Keeling",
            "fr": "Îles Cocos",
            "ja": "ココス（キーリング）諸島",
            "it": "Isole Cocos e Keeling"
        },
        "latitude": "-12.50000000",
        "longitude": "96.83333333",
        "emoji": "🇨🇨",
        "emojiU": "U+1F1E8 U+1F1E8",
        "states": []
    },
    {
        "name": "Colombia",
        "iso3": "COL",
        "iso2": "CO",
        "phone_code": "57",
        "capital": "Bogota",
        "currency": "COP",
        "currency_symbol": "$",
        "tld": ".co",
        "native": "Colombia",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America/Bogota",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "COT",
                "tzName": "Colombia Time"
            }
        ],
        "translations": {
            "br": "Colômbia",
            "pt": "Colômbia",
            "nl": "Colombia",
            "hr": "Kolumbija",
            "fa": "کلمبیا",
            "de": "Kolumbien",
            "es": "Colombia",
            "fr": "Colombie",
            "ja": "コロンビア",
            "it": "Colombia"
        },
        "latitude": "4.00000000",
        "longitude": "-72.00000000",
        "emoji": "🇨🇴",
        "emojiU": "U+1F1E8 U+1F1F4",
        "states": [
            {
                "id": 2895,
                "name": "Amazonas Department",
                "state_code": "AMA"
            },
            {
                "id": 2890,
                "name": "Antioquia Department",
                "state_code": "ANT"
            },
            {
                "id": 2881,
                "name": "Arauca Department",
                "state_code": "ARA"
            },
            {
                "id": 2900,
                "name": "Archipelago of Saint Andréws, Providence and Saint Catalina",
                "state_code": "SAP"
            },
            {
                "id": 2880,
                "name": "Atlántico Department",
                "state_code": "ATL"
            },
            {
                "id": 2893,
                "name": "Bolívar Department",
                "state_code": "BOL"
            },
            {
                "id": 2903,
                "name": "Boyacá Department",
                "state_code": "BOY"
            },
            {
                "id": 2887,
                "name": "Caldas Department",
                "state_code": "CAL"
            },
            {
                "id": 2891,
                "name": "Caquetá Department",
                "state_code": "CAQ"
            },
            {
                "id": 2892,
                "name": "Casanare Department",
                "state_code": "CAS"
            },
            {
                "id": 2884,
                "name": "Cauca Department",
                "state_code": "CAU"
            },
            {
                "id": 2899,
                "name": "Cesar Department",
                "state_code": "CES"
            },
            {
                "id": 2876,
                "name": "Chocó Department",
                "state_code": "CHO"
            },
            {
                "id": 2898,
                "name": "Córdoba Department",
                "state_code": "COR"
            },
            {
                "id": 2875,
                "name": "Cundinamarca Department",
                "state_code": "CUN"
            },
            {
                "id": 2882,
                "name": "Guainía Department",
                "state_code": "GUA"
            },
            {
                "id": 2888,
                "name": "Guaviare Department",
                "state_code": "GUV"
            },
            {
                "id": 4871,
                "name": "Huila Department",
                "state_code": "HUI"
            },
            {
                "id": 2889,
                "name": "La Guajira Department",
                "state_code": "LAG"
            },
            {
                "id": 2886,
                "name": "Magdalena Department",
                "state_code": "MAG"
            },
            {
                "id": 2878,
                "name": "Meta",
                "state_code": "MET"
            },
            {
                "id": 2897,
                "name": "Nariño Department",
                "state_code": "NAR"
            },
            {
                "id": 2877,
                "name": "Norte de Santander Department",
                "state_code": "NSA"
            },
            {
                "id": 2896,
                "name": "Putumayo Department",
                "state_code": "PUT"
            },
            {
                "id": 2874,
                "name": "Quindío Department",
                "state_code": "QUI"
            },
            {
                "id": 2879,
                "name": "Risaralda Department",
                "state_code": "RIS"
            },
            {
                "id": 2901,
                "name": "Santander Department",
                "state_code": "SAN"
            },
            {
                "id": 2902,
                "name": "Sucre Department",
                "state_code": "SUC"
            },
            {
                "id": 2883,
                "name": "Tolima Department",
                "state_code": "TOL"
            },
            {
                "id": 2904,
                "name": "Valle del Cauca Department",
                "state_code": "VAC"
            },
            {
                "id": 2885,
                "name": "Vaupés Department",
                "state_code": "VAU"
            },
            {
                "id": 2894,
                "name": "Vichada Department",
                "state_code": "VID"
            }
        ]
    },
    {
        "name": "Comoros",
        "iso3": "COM",
        "iso2": "KM",
        "phone_code": "269",
        "capital": "Moroni",
        "currency": "KMF",
        "currency_symbol": "CF",
        "tld": ".km",
        "native": "Komori",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Indian/Comoro",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "EAT",
                "tzName": "East Africa Time"
            }
        ],
        "translations": {
            "br": "Comores",
            "pt": "Comores",
            "nl": "Comoren",
            "hr": "Komori",
            "fa": "کومور",
            "de": "Union der Komoren",
            "es": "Comoras",
            "fr": "Comores",
            "ja": "コモロ",
            "it": "Comore"
        },
        "latitude": "-12.16666666",
        "longitude": "44.25000000",
        "emoji": "🇰🇲",
        "emojiU": "U+1F1F0 U+1F1F2",
        "states": [
            {
                "id": 2821,
                "name": "Anjouan",
                "state_code": "A"
            },
            {
                "id": 2822,
                "name": "Grande Comore",
                "state_code": "G"
            },
            {
                "id": 2820,
                "name": "Mohéli",
                "state_code": "M"
            }
        ]
    },
    {
        "name": "Congo",
        "iso3": "COG",
        "iso2": "CG",
        "phone_code": "242",
        "capital": "Brazzaville",
        "currency": "XAF",
        "currency_symbol": "FC",
        "tld": ".cg",
        "native": "République du Congo",
        "region": "Africa",
        "subregion": "Middle Africa",
        "timezones": [
            {
                "zoneName": "Africa/Brazzaville",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WAT",
                "tzName": "West Africa Time"
            }
        ],
        "translations": {
            "br": "Congo",
            "pt": "Congo",
            "nl": "Congo [Republiek]",
            "hr": "Kongo",
            "fa": "کنگو",
            "de": "Kongo",
            "es": "Congo",
            "fr": "Congo",
            "ja": "コンゴ共和国",
            "it": "Congo"
        },
        "latitude": "-1.00000000",
        "longitude": "15.00000000",
        "emoji": "🇨🇬",
        "emojiU": "U+1F1E8 U+1F1EC",
        "states": [
            {
                "id": 2866,
                "name": "Bouenza Department",
                "state_code": "11"
            },
            {
                "id": 2870,
                "name": "Brazzaville",
                "state_code": "BZV"
            },
            {
                "id": 2864,
                "name": "Cuvette Department",
                "state_code": "8"
            },
            {
                "id": 2869,
                "name": "Cuvette-Ouest Department",
                "state_code": "15"
            },
            {
                "id": 2867,
                "name": "Kouilou Department",
                "state_code": "5"
            },
            {
                "id": 2868,
                "name": "Lékoumou Department",
                "state_code": "2"
            },
            {
                "id": 2865,
                "name": "Likouala Department",
                "state_code": "7"
            },
            {
                "id": 2872,
                "name": "Niari Department",
                "state_code": "9"
            },
            {
                "id": 2862,
                "name": "Plateaux Department",
                "state_code": "14"
            },
            {
                "id": 2863,
                "name": "Pointe-Noire",
                "state_code": "16"
            },
            {
                "id": 2873,
                "name": "Pool Department",
                "state_code": "12"
            },
            {
                "id": 2871,
                "name": "Sangha Department",
                "state_code": "13"
            }
        ]
    },
    {
        "name": "Congo The Democratic Republic Of The",
        "iso3": "COD",
        "iso2": "CD",
        "phone_code": "243",
        "capital": "Kinshasa",
        "currency": "CDF",
        "currency_symbol": "FC",
        "tld": ".cd",
        "native": "République démocratique du Congo",
        "region": "Africa",
        "subregion": "Middle Africa",
        "timezones": [
            {
                "zoneName": "Africa/Kinshasa",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WAT",
                "tzName": "West Africa Time"
            },
            {
                "zoneName": "Africa/Lubumbashi",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "CAT",
                "tzName": "Central Africa Time"
            }
        ],
        "translations": {
            "br": "RD Congo",
            "pt": "RD Congo",
            "nl": "Congo [DRC]",
            "hr": "Kongo, Demokratska Republika",
            "fa": "جمهوری کنگو",
            "de": "Kongo (Dem. Rep.)",
            "es": "Congo (Rep. Dem.)",
            "fr": "Congo (Rép. dém.)",
            "ja": "コンゴ民主共和国",
            "it": "Congo (Rep. Dem.)"
        },
        "latitude": "0.00000000",
        "longitude": "25.00000000",
        "emoji": "🇨🇩",
        "emojiU": "U+1F1E8 U+1F1E9",
        "states": [
            {
                "id": 2754,
                "name": "Bandundu Province",
                "state_code": "BN"
            },
            {
                "id": 2746,
                "name": "Bas-Congo province",
                "state_code": "BC"
            },
            {
                "id": 2753,
                "name": "Bas-Uele",
                "state_code": "BU"
            },
            {
                "id": 2744,
                "name": "Équateur",
                "state_code": "EQ"
            },
            {
                "id": 2750,
                "name": "Haut-Katanga Province",
                "state_code": "HK"
            },
            {
                "id": 2758,
                "name": "Haut-Lomami District",
                "state_code": "HL"
            },
            {
                "id": 2734,
                "name": "Haut-Uele",
                "state_code": "HU"
            },
            {
                "id": 2751,
                "name": "Ituri Interim Administration",
                "state_code": "IT"
            },
            {
                "id": 2757,
                "name": "Kasaï District",
                "state_code": "KS"
            },
            {
                "id": 2737,
                "name": "Kasaï-Occidental",
                "state_code": "KW"
            },
            {
                "id": 2735,
                "name": "Kasaï-Oriental",
                "state_code": "KE"
            },
            {
                "id": 2742,
                "name": "Katanga Province",
                "state_code": "KA"
            },
            {
                "id": 2741,
                "name": "Kinshasa",
                "state_code": "KN"
            },
            {
                "id": 2740,
                "name": "Kwango District",
                "state_code": "KG"
            },
            {
                "id": 2759,
                "name": "Kwilu District",
                "state_code": "KL"
            },
            {
                "id": 2747,
                "name": "Lomami Province",
                "state_code": "LO"
            },
            {
                "id": 2755,
                "name": "Mai-Ndombe Province",
                "state_code": "MN"
            },
            {
                "id": 2745,
                "name": "Maniema",
                "state_code": "MA"
            },
            {
                "id": 2752,
                "name": "Mongala District",
                "state_code": "MO"
            },
            {
                "id": 2739,
                "name": "Nord-Ubangi District",
                "state_code": "NU"
            },
            {
                "id": 2749,
                "name": "North Kivu",
                "state_code": "NK"
            },
            {
                "id": 2736,
                "name": "Orientale Province",
                "state_code": "OR"
            },
            {
                "id": 2743,
                "name": "Sankuru District",
                "state_code": "SA"
            },
            {
                "id": 2738,
                "name": "South Kivu",
                "state_code": "SK"
            },
            {
                "id": 2748,
                "name": "Sud-Ubangi",
                "state_code": "SU"
            },
            {
                "id": 2733,
                "name": "Tanganyika Province",
                "state_code": "TA"
            },
            {
                "id": 2756,
                "name": "Tshopo District",
                "state_code": "TO"
            },
            {
                "id": 2732,
                "name": "Tshuapa District",
                "state_code": "TU"
            }
        ]
    },
    {
        "name": "Cook Islands",
        "iso3": "COK",
        "iso2": "CK",
        "phone_code": "682",
        "capital": "Avarua",
        "currency": "NZD",
        "currency_symbol": "$",
        "tld": ".ck",
        "native": "Cook Islands",
        "region": "Oceania",
        "subregion": "Polynesia",
        "timezones": [
            {
                "zoneName": "Pacific/Rarotonga",
                "gmtOffset": -36000,
                "gmtOffsetName": "UTC-10:00",
                "abbreviation": "CKT",
                "tzName": "Cook Island Time"
            }
        ],
        "translations": {
            "br": "Ilhas Cook",
            "pt": "Ilhas Cook",
            "nl": "Cookeilanden",
            "hr": "Cookovo Otočje",
            "fa": "جزایر کوک",
            "de": "Cookinseln",
            "es": "Islas Cook",
            "fr": "Îles Cook",
            "ja": "クック諸島",
            "it": "Isole Cook"
        },
        "latitude": "-21.23333333",
        "longitude": "-159.76666666",
        "emoji": "🇨🇰",
        "emojiU": "U+1F1E8 U+1F1F0",
        "states": []
    },
    {
        "name": "Costa Rica",
        "iso3": "CRI",
        "iso2": "CR",
        "phone_code": "506",
        "capital": "San Jose",
        "currency": "CRC",
        "currency_symbol": "₡",
        "tld": ".cr",
        "native": "Costa Rica",
        "region": "Americas",
        "subregion": "Central America",
        "timezones": [
            {
                "zoneName": "America/Costa_Rica",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            }
        ],
        "translations": {
            "br": "Costa Rica",
            "pt": "Costa Rica",
            "nl": "Costa Rica",
            "hr": "Kostarika",
            "fa": "کاستاریکا",
            "de": "Costa Rica",
            "es": "Costa Rica",
            "fr": "Costa Rica",
            "ja": "コスタリカ",
            "it": "Costa Rica"
        },
        "latitude": "10.00000000",
        "longitude": "-84.00000000",
        "emoji": "🇨🇷",
        "emojiU": "U+1F1E8 U+1F1F7",
        "states": [
            {
                "id": 1215,
                "name": "Alajuela Province",
                "state_code": "A"
            },
            {
                "id": 1209,
                "name": "Guanacaste Province",
                "state_code": "G"
            },
            {
                "id": 1212,
                "name": "Heredia Province",
                "state_code": "H"
            },
            {
                "id": 1213,
                "name": "Limón Province",
                "state_code": "L"
            },
            {
                "id": 1211,
                "name": "Provincia de Cartago",
                "state_code": "C"
            },
            {
                "id": 1210,
                "name": "Puntarenas Province",
                "state_code": "P"
            },
            {
                "id": 1214,
                "name": "San José Province",
                "state_code": "SJ"
            }
        ]
    },
    {
        "name": "Cote D'Ivoire (Ivory Coast)",
        "iso3": "CIV",
        "iso2": "CI",
        "phone_code": "225",
        "capital": "Yamoussoukro",
        "currency": "XOF",
        "currency_symbol": "CFA",
        "tld": ".ci",
        "native": null,
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa/Abidjan",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "translations": {
            "br": "Costa do Marfim",
            "pt": "Costa do Marfim",
            "nl": "Ivoorkust",
            "hr": "Obala Bjelokosti",
            "fa": "ساحل عاج",
            "de": "Elfenbeinküste",
            "es": "Costa de Marfil",
            "fr": "Côte d'Ivoire",
            "ja": "コートジボワール",
            "it": "Costa D'Avorio"
        },
        "latitude": "8.00000000",
        "longitude": "-5.00000000",
        "emoji": "🇨🇮",
        "emojiU": "U+1F1E8 U+1F1EE",
        "states": [
            {
                "id": 2634,
                "name": "Abidjan",
                "state_code": "AB"
            },
            {
                "id": 2626,
                "name": "Agnéby",
                "state_code": "16"
            },
            {
                "id": 2636,
                "name": "Bafing Region",
                "state_code": "17"
            },
            {
                "id": 2643,
                "name": "Bas-Sassandra District",
                "state_code": "BS"
            },
            {
                "id": 2635,
                "name": "Bas-Sassandra Region",
                "state_code": "09"
            },
            {
                "id": 2654,
                "name": "Comoé District",
                "state_code": "CM"
            },
            {
                "id": 2644,
                "name": "Denguélé District",
                "state_code": "DN"
            },
            {
                "id": 2642,
                "name": "Denguélé Region",
                "state_code": "10"
            },
            {
                "id": 2645,
                "name": "Dix-Huit Montagnes",
                "state_code": "06"
            },
            {
                "id": 2633,
                "name": "Fromager",
                "state_code": "18"
            },
            {
                "id": 2651,
                "name": "Gôh-Djiboua District",
                "state_code": "GD"
            },
            {
                "id": 2638,
                "name": "Haut-Sassandra",
                "state_code": "02"
            },
            {
                "id": 2632,
                "name": "Lacs District",
                "state_code": "LC"
            },
            {
                "id": 2640,
                "name": "Lacs Region",
                "state_code": "07"
            },
            {
                "id": 2627,
                "name": "Lagunes District",
                "state_code": "LG"
            },
            {
                "id": 2639,
                "name": "Lagunes region",
                "state_code": "01"
            },
            {
                "id": 2631,
                "name": "Marahoué Region",
                "state_code": "12"
            },
            {
                "id": 2629,
                "name": "Montagnes District",
                "state_code": "MG"
            },
            {
                "id": 2646,
                "name": "Moyen-Cavally",
                "state_code": "19"
            },
            {
                "id": 2630,
                "name": "Moyen-Comoé",
                "state_code": "05"
            },
            {
                "id": 2655,
                "name": "N'zi-Comoé",
                "state_code": "11"
            },
            {
                "id": 2648,
                "name": "Sassandra-Marahoué District",
                "state_code": "SM"
            },
            {
                "id": 2625,
                "name": "Savanes Region",
                "state_code": "03"
            },
            {
                "id": 2628,
                "name": "Sud-Bandama",
                "state_code": "15"
            },
            {
                "id": 2652,
                "name": "Sud-Comoé",
                "state_code": "13"
            },
            {
                "id": 2637,
                "name": "Vallée du Bandama District",
                "state_code": "VB"
            },
            {
                "id": 2647,
                "name": "Vallée du Bandama Region",
                "state_code": "04"
            },
            {
                "id": 2650,
                "name": "Woroba District",
                "state_code": "WR"
            },
            {
                "id": 2649,
                "name": "Worodougou",
                "state_code": "14"
            },
            {
                "id": 2653,
                "name": "Yamoussoukro",
                "state_code": "YM"
            },
            {
                "id": 2641,
                "name": "Zanzan Region",
                "state_code": "ZZ"
            }
        ]
    },
    {
        "name": "Croatia (Hrvatska)",
        "iso3": "HRV",
        "iso2": "HR",
        "phone_code": "385",
        "capital": "Zagreb",
        "currency": "HRK",
        "currency_symbol": "kn",
        "tld": ".hr",
        "native": "Hrvatska",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Zagreb",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Croácia",
            "pt": "Croácia",
            "nl": "Kroatië",
            "hr": "Hrvatska",
            "fa": "کرواسی",
            "de": "Kroatien",
            "es": "Croacia",
            "fr": "Croatie",
            "ja": "クロアチア",
            "it": "Croazia"
        },
        "latitude": "45.16666666",
        "longitude": "15.50000000",
        "emoji": "🇭🇷",
        "emojiU": "U+1F1ED U+1F1F7",
        "states": [
            {
                "id": 734,
                "name": "Bjelovar-Bilogora County",
                "state_code": "07"
            },
            {
                "id": 737,
                "name": "Brod-Posavina County",
                "state_code": "12"
            },
            {
                "id": 728,
                "name": "Dubrovnik-Neretva County",
                "state_code": "19"
            },
            {
                "id": 743,
                "name": "Istria County",
                "state_code": "18"
            },
            {
                "id": 742,
                "name": "Koprivnica-Križevci County",
                "state_code": "06"
            },
            {
                "id": 729,
                "name": "Krapina-Zagorje County",
                "state_code": "02"
            },
            {
                "id": 731,
                "name": "Lika-Senj County",
                "state_code": "09"
            },
            {
                "id": 726,
                "name": "Međimurje County",
                "state_code": "20"
            },
            {
                "id": 740,
                "name": "Osijek-Baranja County",
                "state_code": "14"
            },
            {
                "id": 724,
                "name": "Požega-Slavonia County",
                "state_code": "11"
            },
            {
                "id": 735,
                "name": "Primorje-Gorski Kotar County",
                "state_code": "08"
            },
            {
                "id": 730,
                "name": "Šibenik-Knin County",
                "state_code": "15"
            },
            {
                "id": 733,
                "name": "Sisak-Moslavina County",
                "state_code": "03"
            },
            {
                "id": 725,
                "name": "Split-Dalmatia County",
                "state_code": "17"
            },
            {
                "id": 739,
                "name": "Varaždin County",
                "state_code": "05"
            },
            {
                "id": 732,
                "name": "Virovitica-Podravina County",
                "state_code": "10"
            },
            {
                "id": 741,
                "name": "Vukovar-Syrmia County",
                "state_code": "16"
            },
            {
                "id": 727,
                "name": "Zadar County",
                "state_code": "13"
            },
            {
                "id": 738,
                "name": "Zagreb",
                "state_code": "21"
            },
            {
                "id": 736,
                "name": "Zagreb County",
                "state_code": "01"
            }
        ]
    },
    {
        "name": "Cuba",
        "iso3": "CUB",
        "iso2": "CU",
        "phone_code": "53",
        "capital": "Havana",
        "currency": "CUP",
        "currency_symbol": "$",
        "tld": ".cu",
        "native": "Cuba",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/Havana",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "CST",
                "tzName": "Cuba Standard Time"
            }
        ],
        "translations": {
            "br": "Cuba",
            "pt": "Cuba",
            "nl": "Cuba",
            "hr": "Kuba",
            "fa": "کوبا",
            "de": "Kuba",
            "es": "Cuba",
            "fr": "Cuba",
            "ja": "キューバ",
            "it": "Cuba"
        },
        "latitude": "21.50000000",
        "longitude": "-80.00000000",
        "emoji": "🇨🇺",
        "emojiU": "U+1F1E8 U+1F1FA",
        "states": [
            {
                "id": 283,
                "name": "Artemisa Province",
                "state_code": "15"
            },
            {
                "id": 286,
                "name": "Camagüey Province",
                "state_code": "09"
            },
            {
                "id": 282,
                "name": "Ciego de Ávila Province",
                "state_code": "08"
            },
            {
                "id": 287,
                "name": "Cienfuegos Province",
                "state_code": "06"
            },
            {
                "id": 275,
                "name": "Granma Province",
                "state_code": "12"
            },
            {
                "id": 285,
                "name": "Guantánamo Province",
                "state_code": "14"
            },
            {
                "id": 272,
                "name": "Havana Province",
                "state_code": "03"
            },
            {
                "id": 279,
                "name": "Holguín Province",
                "state_code": "11"
            },
            {
                "id": 278,
                "name": "Isla de la Juventud",
                "state_code": "99"
            },
            {
                "id": 281,
                "name": "Las Tunas Province",
                "state_code": "10"
            },
            {
                "id": 284,
                "name": "Matanzas Province",
                "state_code": "04"
            },
            {
                "id": 276,
                "name": "Mayabeque Province",
                "state_code": "16"
            },
            {
                "id": 277,
                "name": "Pinar del Río Province",
                "state_code": "01"
            },
            {
                "id": 274,
                "name": "Sancti Spíritus Province",
                "state_code": "07"
            },
            {
                "id": 273,
                "name": "Santiago de Cuba Province",
                "state_code": "13"
            },
            {
                "id": 280,
                "name": "Villa Clara Province",
                "state_code": "05"
            }
        ]
    },
    {
        "name": "Curaçao",
        "iso3": "CUW",
        "iso2": "CW",
        "phone_code": "599",
        "capital": "Willemstad",
        "currency": "ANG",
        "currency_symbol": "ƒ",
        "tld": ".cw",
        "native": "Curaçao",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/Curacao",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "translations": {
            "br": "Curaçao",
            "pt": "Curaçao",
            "nl": "Curaçao",
            "fa": "کوراسائو",
            "de": "Curaçao",
            "fr": "Curaçao",
            "it": "Curaçao"
        },
        "latitude": "12.11666700",
        "longitude": "-68.93333300",
        "emoji": "🇨🇼",
        "emojiU": "U+1F1E8 U+1F1FC",
        "states": []
    },
    {
        "name": "Cyprus",
        "iso3": "CYP",
        "iso2": "CY",
        "phone_code": "357",
        "capital": "Nicosia",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".cy",
        "native": "Κύπρος",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Asia/Famagusta",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            },
            {
                "zoneName": "Asia/Nicosia",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "translations": {
            "br": "Chipre",
            "pt": "Chipre",
            "nl": "Cyprus",
            "hr": "Cipar",
            "fa": "قبرس",
            "de": "Zypern",
            "es": "Chipre",
            "fr": "Chypre",
            "ja": "キプロス",
            "it": "Cipro"
        },
        "latitude": "35.00000000",
        "longitude": "33.00000000",
        "emoji": "🇨🇾",
        "emojiU": "U+1F1E8 U+1F1FE",
        "states": [
            {
                "id": 749,
                "name": "Famagusta District",
                "state_code": "04"
            },
            {
                "id": 744,
                "name": "Kyrenia District",
                "state_code": "06"
            },
            {
                "id": 747,
                "name": "Larnaca District",
                "state_code": "03"
            },
            {
                "id": 748,
                "name": "Limassol District",
                "state_code": "02"
            },
            {
                "id": 745,
                "name": "Nicosia District",
                "state_code": "01"
            },
            {
                "id": 746,
                "name": "Paphos District",
                "state_code": "05"
            }
        ]
    },
    {
        "name": "Czech Republic",
        "iso3": "CZE",
        "iso2": "CZ",
        "phone_code": "420",
        "capital": "Prague",
        "currency": "CZK",
        "currency_symbol": "Kč",
        "tld": ".cz",
        "native": "Česká republika",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Prague",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "República Tcheca",
            "pt": "República Checa",
            "nl": "Tsjechië",
            "hr": "Češka",
            "fa": "جمهوری چک",
            "de": "Tschechische Republik",
            "es": "República Checa",
            "fr": "République tchèque",
            "ja": "チェコ",
            "it": "Repubblica Ceca"
        },
        "latitude": "49.75000000",
        "longitude": "15.50000000",
        "emoji": "🇨🇿",
        "emojiU": "U+1F1E8 U+1F1FF",
        "states": [
            {
                "id": 4627,
                "name": "Benešov District",
                "state_code": "201"
            },
            {
                "id": 4620,
                "name": "Beroun District",
                "state_code": "202"
            },
            {
                "id": 4615,
                "name": "Blansko District",
                "state_code": "641"
            },
            {
                "id": 4542,
                "name": "Břeclav District",
                "state_code": "644"
            },
            {
                "id": 4568,
                "name": "Brno-City District",
                "state_code": "642"
            },
            {
                "id": 4545,
                "name": "Brno-Country District",
                "state_code": "643"
            },
            {
                "id": 4644,
                "name": "Bruntál District",
                "state_code": "801"
            },
            {
                "id": 4554,
                "name": "Central Bohemian Region",
                "state_code": "20"
            },
            {
                "id": 4633,
                "name": "Česká Lípa District",
                "state_code": "511"
            },
            {
                "id": 4556,
                "name": "České Budějovice District",
                "state_code": "311"
            },
            {
                "id": 4543,
                "name": "Český Krumlov District",
                "state_code": "312"
            },
            {
                "id": 4573,
                "name": "Cheb District",
                "state_code": "411"
            },
            {
                "id": 4553,
                "name": "Chomutov District",
                "state_code": "422"
            },
            {
                "id": 4634,
                "name": "Chrudim District",
                "state_code": "531"
            },
            {
                "id": 4609,
                "name": "Děčín District",
                "state_code": "421"
            },
            {
                "id": 4641,
                "name": "Domažlice District",
                "state_code": "321"
            },
            {
                "id": 4559,
                "name": "Frýdek-Místek District",
                "state_code": "802"
            },
            {
                "id": 4611,
                "name": "Havlíčkův Brod District",
                "state_code": "631"
            },
            {
                "id": 4561,
                "name": "Hodonín District",
                "state_code": "645"
            },
            {
                "id": 4577,
                "name": "Horní Počernice",
                "state_code": "120"
            },
            {
                "id": 4580,
                "name": "Hradec Králové District",
                "state_code": "521"
            },
            {
                "id": 4614,
                "name": "Hradec Králové Region",
                "state_code": "52"
            },
            {
                "id": 4612,
                "name": "Jablonec nad Nisou District",
                "state_code": "512"
            },
            {
                "id": 4625,
                "name": "Jeseník District",
                "state_code": "711"
            },
            {
                "id": 4640,
                "name": "Jičín District",
                "state_code": "522"
            },
            {
                "id": 4613,
                "name": "Jihlava District",
                "state_code": "632"
            },
            {
                "id": 4624,
                "name": "Jindřichův Hradec District",
                "state_code": "313"
            },
            {
                "id": 4604,
                "name": "Karlovy Vary District",
                "state_code": "412"
            },
            {
                "id": 4581,
                "name": "Karlovy Vary Region",
                "state_code": "41"
            },
            {
                "id": 4586,
                "name": "Karviná District",
                "state_code": "803"
            },
            {
                "id": 4631,
                "name": "Kladno District",
                "state_code": "203"
            },
            {
                "id": 4591,
                "name": "Klatovy District",
                "state_code": "322"
            },
            {
                "id": 4618,
                "name": "Kolín District",
                "state_code": "204"
            },
            {
                "id": 4593,
                "name": "Kroměříž District",
                "state_code": "721"
            },
            {
                "id": 4590,
                "name": "Liberec District",
                "state_code": "513"
            },
            {
                "id": 4601,
                "name": "Liberec Region",
                "state_code": "51"
            },
            {
                "id": 4605,
                "name": "Litoměřice District",
                "state_code": "423"
            },
            {
                "id": 4617,
                "name": "Louny District",
                "state_code": "424"
            },
            {
                "id": 4638,
                "name": "Mělník District",
                "state_code": "206"
            },
            {
                "id": 4643,
                "name": "Mladá Boleslav District",
                "state_code": "207"
            },
            {
                "id": 4600,
                "name": "Moravian-Silesian Region",
                "state_code": "80"
            },
            {
                "id": 4629,
                "name": "Most District",
                "state_code": "425"
            },
            {
                "id": 4550,
                "name": "Náchod District",
                "state_code": "523"
            },
            {
                "id": 4548,
                "name": "Nový Jičín District",
                "state_code": "804"
            },
            {
                "id": 4582,
                "name": "Nymburk District",
                "state_code": "208"
            },
            {
                "id": 4574,
                "name": "Olomouc District",
                "state_code": "712"
            },
            {
                "id": 4589,
                "name": "Olomouc Region",
                "state_code": "71"
            },
            {
                "id": 4623,
                "name": "Opava District",
                "state_code": "805"
            },
            {
                "id": 4584,
                "name": "Ostrava-City District",
                "state_code": "806"
            },
            {
                "id": 4547,
                "name": "Pardubice District",
                "state_code": "532"
            },
            {
                "id": 4588,
                "name": "Pardubice Region",
                "state_code": "53"
            },
            {
                "id": 4645,
                "name": "Pelhřimov District",
                "state_code": "633"
            },
            {
                "id": 4560,
                "name": "Písek District",
                "state_code": "314"
            },
            {
                "id": 4607,
                "name": "Plzeň Region",
                "state_code": "32"
            },
            {
                "id": 4544,
                "name": "Plzeň-City District",
                "state_code": "323"
            },
            {
                "id": 4564,
                "name": "Plzeň-North District",
                "state_code": "325"
            },
            {
                "id": 4608,
                "name": "Plzeň-South District",
                "state_code": "324"
            },
            {
                "id": 4578,
                "name": "Prachatice District",
                "state_code": "315"
            },
            {
                "id": 4598,
                "name": "Prague",
                "state_code": "10"
            },
            {
                "id": 4562,
                "name": "Prague 1",
                "state_code": "101"
            },
            {
                "id": 4603,
                "name": "Prague 10",
                "state_code": "110"
            },
            {
                "id": 4570,
                "name": "Prague 11",
                "state_code": "111"
            },
            {
                "id": 4549,
                "name": "Prague 12",
                "state_code": "112"
            },
            {
                "id": 4555,
                "name": "Prague 13",
                "state_code": "113"
            },
            {
                "id": 4585,
                "name": "Prague 14",
                "state_code": "114"
            },
            {
                "id": 4637,
                "name": "Prague 15",
                "state_code": "115"
            },
            {
                "id": 4567,
                "name": "Prague 16",
                "state_code": "116"
            },
            {
                "id": 4616,
                "name": "Prague 2",
                "state_code": "102"
            },
            {
                "id": 4632,
                "name": "Prague 21",
                "state_code": "121"
            },
            {
                "id": 4635,
                "name": "Prague 3",
                "state_code": "103"
            },
            {
                "id": 4587,
                "name": "Prague 4",
                "state_code": "104"
            },
            {
                "id": 4557,
                "name": "Prague 5",
                "state_code": "105"
            },
            {
                "id": 4569,
                "name": "Prague 6",
                "state_code": "106"
            },
            {
                "id": 4610,
                "name": "Prague 7",
                "state_code": "107"
            },
            {
                "id": 4594,
                "name": "Prague 8",
                "state_code": "108"
            },
            {
                "id": 4566,
                "name": "Prague 9",
                "state_code": "109"
            },
            {
                "id": 4606,
                "name": "Prague-East District",
                "state_code": "209"
            },
            {
                "id": 4619,
                "name": "Prague-West District",
                "state_code": "20A"
            },
            {
                "id": 4626,
                "name": "Přerov District",
                "state_code": "714"
            },
            {
                "id": 4546,
                "name": "Příbram District",
                "state_code": "20B"
            },
            {
                "id": 4551,
                "name": "Prostějov District",
                "state_code": "713"
            },
            {
                "id": 4558,
                "name": "Rakovník District",
                "state_code": "20C"
            },
            {
                "id": 4583,
                "name": "Rokycany District",
                "state_code": "326"
            },
            {
                "id": 4636,
                "name": "Rychnov nad Kněžnou District",
                "state_code": "524"
            },
            {
                "id": 4596,
                "name": "Semily District",
                "state_code": "514"
            },
            {
                "id": 4595,
                "name": "Sokolov District",
                "state_code": "413"
            },
            {
                "id": 4639,
                "name": "South Bohemian Region",
                "state_code": "31"
            },
            {
                "id": 4602,
                "name": "South Moravian Region",
                "state_code": "64"
            },
            {
                "id": 4628,
                "name": "Strakonice District",
                "state_code": "316"
            },
            {
                "id": 4642,
                "name": "Šumperk District",
                "state_code": "715"
            },
            {
                "id": 4571,
                "name": "Svitavy District",
                "state_code": "533"
            },
            {
                "id": 4565,
                "name": "Tábor District",
                "state_code": "317"
            },
            {
                "id": 4646,
                "name": "Tachov District",
                "state_code": "327"
            },
            {
                "id": 4621,
                "name": "Teplice District",
                "state_code": "426"
            },
            {
                "id": 4597,
                "name": "Třebíč District",
                "state_code": "634"
            },
            {
                "id": 4579,
                "name": "Trutnov District",
                "state_code": "525"
            },
            {
                "id": 4592,
                "name": "Uherské Hradiště District",
                "state_code": "722"
            },
            {
                "id": 4599,
                "name": "Ústí nad Labem District",
                "state_code": "427"
            },
            {
                "id": 4576,
                "name": "Ústí nad Labem Region",
                "state_code": "42"
            },
            {
                "id": 4647,
                "name": "Ústí nad Orlicí District",
                "state_code": "534"
            },
            {
                "id": 4572,
                "name": "Vsetín District",
                "state_code": "723"
            },
            {
                "id": 4622,
                "name": "Vyškov District",
                "state_code": "646"
            },
            {
                "id": 4575,
                "name": "Vysočina Region",
                "state_code": "63"
            },
            {
                "id": 4648,
                "name": "Žďár nad Sázavou District",
                "state_code": "635"
            },
            {
                "id": 4563,
                "name": "Zlín District",
                "state_code": "724"
            },
            {
                "id": 4552,
                "name": "Zlín Region",
                "state_code": "72"
            },
            {
                "id": 4630,
                "name": "Znojmo District",
                "state_code": "647"
            }
        ]
    },
    {
        "name": "Denmark",
        "iso3": "DNK",
        "iso2": "DK",
        "phone_code": "45",
        "capital": "Copenhagen",
        "currency": "DKK",
        "currency_symbol": "Kr.",
        "tld": ".dk",
        "native": "Danmark",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Copenhagen",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Dinamarca",
            "pt": "Dinamarca",
            "nl": "Denemarken",
            "hr": "Danska",
            "fa": "دانمارک",
            "de": "Dänemark",
            "es": "Dinamarca",
            "fr": "Danemark",
            "ja": "デンマーク",
            "it": "Danimarca"
        },
        "latitude": "56.00000000",
        "longitude": "10.00000000",
        "emoji": "🇩🇰",
        "emojiU": "U+1F1E9 U+1F1F0",
        "states": [
            {
                "id": 1530,
                "name": "Capital Region of Denmark",
                "state_code": "84"
            },
            {
                "id": 1531,
                "name": "Central Denmark Region",
                "state_code": "82"
            },
            {
                "id": 1532,
                "name": "North Denmark Region",
                "state_code": "81"
            },
            {
                "id": 1529,
                "name": "Region of Southern Denmark",
                "state_code": "83"
            },
            {
                "id": 1528,
                "name": "Region Zealand",
                "state_code": "85"
            }
        ]
    },
    {
        "name": "Djibouti",
        "iso3": "DJI",
        "iso2": "DJ",
        "phone_code": "253",
        "capital": "Djibouti",
        "currency": "DJF",
        "currency_symbol": "Fdj",
        "tld": ".dj",
        "native": "Djibouti",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa/Djibouti",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "EAT",
                "tzName": "East Africa Time"
            }
        ],
        "translations": {
            "br": "Djibuti",
            "pt": "Djibuti",
            "nl": "Djibouti",
            "hr": "Džibuti",
            "fa": "جیبوتی",
            "de": "Dschibuti",
            "es": "Yibuti",
            "fr": "Djibouti",
            "ja": "ジブチ",
            "it": "Gibuti"
        },
        "latitude": "11.50000000",
        "longitude": "43.00000000",
        "emoji": "🇩🇯",
        "emojiU": "U+1F1E9 U+1F1EF",
        "states": [
            {
                "id": 2933,
                "name": "Ali Sabieh Region",
                "state_code": "AS"
            },
            {
                "id": 2932,
                "name": "Arta Region",
                "state_code": "AR"
            },
            {
                "id": 2930,
                "name": "Dikhil Region",
                "state_code": "DI"
            },
            {
                "id": 2929,
                "name": "Djibouti",
                "state_code": "DJ"
            },
            {
                "id": 2928,
                "name": "Obock Region",
                "state_code": "OB"
            },
            {
                "id": 2931,
                "name": "Tadjourah Region",
                "state_code": "TA"
            }
        ]
    },
    {
        "name": "Dominica",
        "iso3": "DMA",
        "iso2": "DM",
        "phone_code": "+1-767",
        "capital": "Roseau",
        "currency": "XCD",
        "currency_symbol": "$",
        "tld": ".dm",
        "native": "Dominica",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/Dominica",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "translations": {
            "br": "Dominica",
            "pt": "Dominica",
            "nl": "Dominica",
            "hr": "Dominika",
            "fa": "دومینیکا",
            "de": "Dominica",
            "es": "Dominica",
            "fr": "Dominique",
            "ja": "ドミニカ国",
            "it": "Dominica"
        },
        "latitude": "15.41666666",
        "longitude": "-61.33333333",
        "emoji": "🇩🇲",
        "emojiU": "U+1F1E9 U+1F1F2",
        "states": [
            {
                "id": 4082,
                "name": "Saint Andrew Parish",
                "state_code": "02"
            },
            {
                "id": 4078,
                "name": "Saint David Parish",
                "state_code": "03"
            },
            {
                "id": 4079,
                "name": "Saint George Parish",
                "state_code": "04"
            },
            {
                "id": 4076,
                "name": "Saint John Parish",
                "state_code": "05"
            },
            {
                "id": 4085,
                "name": "Saint Joseph Parish",
                "state_code": "06"
            },
            {
                "id": 4083,
                "name": "Saint Luke Parish",
                "state_code": "07"
            },
            {
                "id": 4077,
                "name": "Saint Mark Parish",
                "state_code": "08"
            },
            {
                "id": 4080,
                "name": "Saint Patrick Parish",
                "state_code": "09"
            },
            {
                "id": 4084,
                "name": "Saint Paul Parish",
                "state_code": "10"
            },
            {
                "id": 4081,
                "name": "Saint Peter Parish",
                "state_code": "11"
            }
        ]
    },
    {
        "name": "Dominican Republic",
        "iso3": "DOM",
        "iso2": "DO",
        "phone_code": "+1-809 and 1-829",
        "capital": "Santo Domingo",
        "currency": "DOP",
        "currency_symbol": "$",
        "tld": ".do",
        "native": "República Dominicana",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/Santo_Domingo",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "translations": {
            "br": "República Dominicana",
            "pt": "República Dominicana",
            "nl": "Dominicaanse Republiek",
            "hr": "Dominikanska Republika",
            "fa": "جمهوری دومینیکن",
            "de": "Dominikanische Republik",
            "es": "República Dominicana",
            "fr": "République dominicaine",
            "ja": "ドミニカ共和国",
            "it": "Repubblica Dominicana"
        },
        "latitude": "19.00000000",
        "longitude": "-70.66666666",
        "emoji": "🇩🇴",
        "emojiU": "U+1F1E9 U+1F1F4",
        "states": [
            {
                "id": 4114,
                "name": "Azua Province",
                "state_code": "02"
            },
            {
                "id": 4105,
                "name": "Baoruco Province",
                "state_code": "03"
            },
            {
                "id": 4090,
                "name": "Barahona Province",
                "state_code": "04"
            },
            {
                "id": 4107,
                "name": "Dajabón Province",
                "state_code": "05"
            },
            {
                "id": 4095,
                "name": "Distrito Nacional",
                "state_code": "01"
            },
            {
                "id": 4113,
                "name": "Duarte Province",
                "state_code": "06"
            },
            {
                "id": 4086,
                "name": "El Seibo Province",
                "state_code": "08"
            },
            {
                "id": 4102,
                "name": "Espaillat Province",
                "state_code": "09"
            },
            {
                "id": 4106,
                "name": "Hato Mayor Province",
                "state_code": "30"
            },
            {
                "id": 4089,
                "name": "Hermanas Mirabal Province",
                "state_code": "19"
            },
            {
                "id": 4097,
                "name": "Independencia",
                "state_code": "10"
            },
            {
                "id": 4109,
                "name": "La Altagracia Province",
                "state_code": "11"
            },
            {
                "id": 4087,
                "name": "La Romana Province",
                "state_code": "12"
            },
            {
                "id": 4116,
                "name": "La Vega Province",
                "state_code": "13"
            },
            {
                "id": 4094,
                "name": "María Trinidad Sánchez Province",
                "state_code": "14"
            },
            {
                "id": 4099,
                "name": "Monseñor Nouel Province",
                "state_code": "28"
            },
            {
                "id": 4115,
                "name": "Monte Cristi Province",
                "state_code": "15"
            },
            {
                "id": 4111,
                "name": "Monte Plata Province",
                "state_code": "29"
            },
            {
                "id": 4101,
                "name": "Pedernales Province",
                "state_code": "16"
            },
            {
                "id": 4096,
                "name": "Peravia Province",
                "state_code": "17"
            },
            {
                "id": 4092,
                "name": "Puerto Plata Province",
                "state_code": "18"
            },
            {
                "id": 4103,
                "name": "Samaná Province",
                "state_code": "20"
            },
            {
                "id": 4091,
                "name": "San Cristóbal Province",
                "state_code": "21"
            },
            {
                "id": 4112,
                "name": "San José de Ocoa Province",
                "state_code": "31"
            },
            {
                "id": 4098,
                "name": "San Juan Province",
                "state_code": "22"
            },
            {
                "id": 4110,
                "name": "San Pedro de Macorís",
                "state_code": "23"
            },
            {
                "id": 4088,
                "name": "Sánchez Ramírez Province",
                "state_code": "24"
            },
            {
                "id": 4108,
                "name": "Santiago Province",
                "state_code": "25"
            },
            {
                "id": 4100,
                "name": "Santiago Rodríguez Province",
                "state_code": "26"
            },
            {
                "id": 4093,
                "name": "Santo Domingo Province",
                "state_code": "32"
            },
            {
                "id": 4104,
                "name": "Valverde Province",
                "state_code": "27"
            }
        ]
    },
    {
        "name": "East Timor",
        "iso3": "TLS",
        "iso2": "TL",
        "phone_code": "670",
        "capital": "Dili",
        "currency": "USD",
        "currency_symbol": "$",
        "tld": ".tl",
        "native": "Timor-Leste",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Dili",
                "gmtOffset": 32400,
                "gmtOffsetName": "UTC+09:00",
                "abbreviation": "TLT",
                "tzName": "Timor Leste Time"
            }
        ],
        "translations": {
            "br": "Timor Leste",
            "pt": "Timor Leste",
            "nl": "Oost-Timor",
            "hr": "Istočni Timor",
            "fa": "تیمور شرقی",
            "de": "Timor-Leste",
            "es": "Timor Oriental",
            "fr": "Timor oriental",
            "ja": "東ティモール",
            "it": "Timor Est"
        },
        "latitude": "-8.83333333",
        "longitude": "125.91666666",
        "emoji": "🇹🇱",
        "emojiU": "U+1F1F9 U+1F1F1",
        "states": [
            {
                "id": 4520,
                "name": "Aileu municipality",
                "state_code": "AL"
            },
            {
                "id": 4518,
                "name": "Ainaro Municipality",
                "state_code": "AN"
            },
            {
                "id": 4521,
                "name": "Baucau Municipality",
                "state_code": "BA"
            },
            {
                "id": 4525,
                "name": "Bobonaro Municipality",
                "state_code": "BO"
            },
            {
                "id": 4522,
                "name": "Cova Lima Municipality",
                "state_code": "CO"
            },
            {
                "id": 4524,
                "name": "Dili municipality",
                "state_code": "DI"
            },
            {
                "id": 4516,
                "name": "Ermera District",
                "state_code": "ER"
            },
            {
                "id": 4523,
                "name": "Lautém Municipality",
                "state_code": "LA"
            },
            {
                "id": 4515,
                "name": "Liquiçá Municipality",
                "state_code": "LI"
            },
            {
                "id": 4517,
                "name": "Manatuto District",
                "state_code": "MT"
            },
            {
                "id": 4519,
                "name": "Manufahi Municipality",
                "state_code": "MF"
            },
            {
                "id": 4514,
                "name": "Viqueque Municipality",
                "state_code": "VI"
            }
        ]
    },
    {
        "name": "Ecuador",
        "iso3": "ECU",
        "iso2": "EC",
        "phone_code": "593",
        "capital": "Quito",
        "currency": "USD",
        "currency_symbol": "$",
        "tld": ".ec",
        "native": "Ecuador",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America/Guayaquil",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "ECT",
                "tzName": "Ecuador Time"
            },
            {
                "zoneName": "Pacific/Galapagos",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "GALT",
                "tzName": "Galápagos Time"
            }
        ],
        "translations": {
            "br": "Equador",
            "pt": "Equador",
            "nl": "Ecuador",
            "hr": "Ekvador",
            "fa": "اکوادور",
            "de": "Ecuador",
            "es": "Ecuador",
            "fr": "Équateur",
            "ja": "エクアドル",
            "it": "Ecuador"
        },
        "latitude": "-2.00000000",
        "longitude": "-77.50000000",
        "emoji": "🇪🇨",
        "emojiU": "U+1F1EA U+1F1E8",
        "states": [
            {
                "id": 2923,
                "name": "Azuay Province",
                "state_code": "A"
            },
            {
                "id": 2920,
                "name": "Bolívar Province",
                "state_code": "B"
            },
            {
                "id": 2917,
                "name": "Cañar Province",
                "state_code": "F"
            },
            {
                "id": 2915,
                "name": "Carchi Province",
                "state_code": "C"
            },
            {
                "id": 2925,
                "name": "Chimborazo Province",
                "state_code": "H"
            },
            {
                "id": 2921,
                "name": "Cotopaxi Province",
                "state_code": "X"
            },
            {
                "id": 2924,
                "name": "El Oro Province",
                "state_code": "O"
            },
            {
                "id": 2922,
                "name": "Esmeraldas",
                "state_code": "E"
            },
            {
                "id": 2905,
                "name": "Galápagos Province",
                "state_code": "W"
            },
            {
                "id": 2914,
                "name": "Guayas Province",
                "state_code": "G"
            },
            {
                "id": 2911,
                "name": "Imbabura Province",
                "state_code": "I"
            },
            {
                "id": 2910,
                "name": "Los Ríos Province",
                "state_code": "R"
            },
            {
                "id": 2913,
                "name": "Manabí Province",
                "state_code": "M"
            },
            {
                "id": 2918,
                "name": "Morona-Santiago Province",
                "state_code": "S"
            },
            {
                "id": 2916,
                "name": "Napo Province",
                "state_code": "N"
            },
            {
                "id": 2926,
                "name": "Orellana Province",
                "state_code": "D"
            },
            {
                "id": 2907,
                "name": "Pastaza Province",
                "state_code": "Y"
            },
            {
                "id": 2927,
                "name": "Pichincha Province",
                "state_code": "P"
            },
            {
                "id": 2912,
                "name": "Santa Elena Province",
                "state_code": "SE"
            },
            {
                "id": 2919,
                "name": "Santo Domingo de los Tsáchilas Province",
                "state_code": "SD"
            },
            {
                "id": 2906,
                "name": "Sucumbíos Province",
                "state_code": "U"
            },
            {
                "id": 2908,
                "name": "Tungurahua Province",
                "state_code": "T"
            },
            {
                "id": 2909,
                "name": "Zamora-Chinchipe Province",
                "state_code": "Z"
            }
        ]
    },
    {
        "name": "Egypt",
        "iso3": "EGY",
        "iso2": "EG",
        "phone_code": "20",
        "capital": "Cairo",
        "currency": "EGP",
        "currency_symbol": "ج.م",
        "tld": ".eg",
        "native": "مصر‎",
        "region": "Africa",
        "subregion": "Northern Africa",
        "timezones": [
            {
                "zoneName": "Africa/Cairo",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "translations": {
            "br": "Egito",
            "pt": "Egipto",
            "nl": "Egypte",
            "hr": "Egipat",
            "fa": "مصر",
            "de": "Ägypten",
            "es": "Egipto",
            "fr": "Égypte",
            "ja": "エジプト",
            "it": "Egitto"
        },
        "latitude": "27.00000000",
        "longitude": "30.00000000",
        "emoji": "🇪🇬",
        "emojiU": "U+1F1EA U+1F1EC",
        "states": [
            {
                "id": 3235,
                "name": "Alexandria Governorate",
                "state_code": "ALX"
            },
            {
                "id": 3225,
                "name": "Aswan Governorate",
                "state_code": "ASN"
            },
            {
                "id": 3236,
                "name": "Asyut Governorate",
                "state_code": "AST"
            },
            {
                "id": 3241,
                "name": "Beheira Governorate",
                "state_code": "BH"
            },
            {
                "id": 3230,
                "name": "Beni Suef Governorate",
                "state_code": "BNS"
            },
            {
                "id": 3223,
                "name": "Cairo Governorate",
                "state_code": "C"
            },
            {
                "id": 3245,
                "name": "Dakahlia Governorate",
                "state_code": "DK"
            },
            {
                "id": 3224,
                "name": "Damietta Governorate",
                "state_code": "DT"
            },
            {
                "id": 3238,
                "name": "Faiyum Governorate",
                "state_code": "FYM"
            },
            {
                "id": 3234,
                "name": "Gharbia Governorate",
                "state_code": "GH"
            },
            {
                "id": 3239,
                "name": "Giza Governorate",
                "state_code": "GZ"
            },
            {
                "id": 3244,
                "name": "Ismailia Governorate",
                "state_code": "IS"
            },
            {
                "id": 3222,
                "name": "Kafr el-Sheikh Governorate",
                "state_code": "KFS"
            },
            {
                "id": 3242,
                "name": "Luxor Governorate",
                "state_code": "LX"
            },
            {
                "id": 3231,
                "name": "Matrouh Governorate",
                "state_code": "MT"
            },
            {
                "id": 3243,
                "name": "Minya Governorate",
                "state_code": "MN"
            },
            {
                "id": 3228,
                "name": "Monufia Governorate",
                "state_code": "MNF"
            },
            {
                "id": 3246,
                "name": "New Valley Governorate",
                "state_code": "WAD"
            },
            {
                "id": 3227,
                "name": "North Sinai Governorate",
                "state_code": "SIN"
            },
            {
                "id": 3229,
                "name": "Port Said Governorate",
                "state_code": "PTS"
            },
            {
                "id": 3232,
                "name": "Qalyubia Governorate",
                "state_code": "KB"
            },
            {
                "id": 3247,
                "name": "Qena Governorate",
                "state_code": "KN"
            },
            {
                "id": 3240,
                "name": "Red Sea Governorate",
                "state_code": "BA"
            },
            {
                "id": 3226,
                "name": "Sohag Governorate",
                "state_code": "SHG"
            },
            {
                "id": 3237,
                "name": "South Sinai Governorate",
                "state_code": "JS"
            },
            {
                "id": 3233,
                "name": "Suez Governorate",
                "state_code": "SUZ"
            }
        ]
    },
    {
        "name": "El Salvador",
        "iso3": "SLV",
        "iso2": "SV",
        "phone_code": "503",
        "capital": "San Salvador",
        "currency": "USD",
        "currency_symbol": "$",
        "tld": ".sv",
        "native": "El Salvador",
        "region": "Americas",
        "subregion": "Central America",
        "timezones": [
            {
                "zoneName": "America/El_Salvador",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            }
        ],
        "translations": {
            "br": "El Salvador",
            "pt": "El Salvador",
            "nl": "El Salvador",
            "hr": "Salvador",
            "fa": "السالوادور",
            "de": "El Salvador",
            "es": "El Salvador",
            "fr": "Salvador",
            "ja": "エルサルバドル",
            "it": "El Salvador"
        },
        "latitude": "13.83333333",
        "longitude": "-88.91666666",
        "emoji": "🇸🇻",
        "emojiU": "U+1F1F8 U+1F1FB",
        "states": [
            {
                "id": 4139,
                "name": "Ahuachapán Department",
                "state_code": "AH"
            },
            {
                "id": 4132,
                "name": "Cabañas Department",
                "state_code": "CA"
            },
            {
                "id": 4131,
                "name": "Chalatenango Department",
                "state_code": "CH"
            },
            {
                "id": 4137,
                "name": "Cuscatlán Department",
                "state_code": "CU"
            },
            {
                "id": 4134,
                "name": "La Libertad Department",
                "state_code": "LI"
            },
            {
                "id": 4136,
                "name": "La Paz Department",
                "state_code": "PA"
            },
            {
                "id": 4138,
                "name": "La Unión Department",
                "state_code": "UN"
            },
            {
                "id": 4130,
                "name": "Morazán Department",
                "state_code": "MO"
            },
            {
                "id": 4135,
                "name": "San Miguel Department",
                "state_code": "SM"
            },
            {
                "id": 4133,
                "name": "San Salvador Department",
                "state_code": "SS"
            },
            {
                "id": 4127,
                "name": "San Vicente Department",
                "state_code": "SV"
            },
            {
                "id": 4128,
                "name": "Santa Ana Department",
                "state_code": "SA"
            },
            {
                "id": 4140,
                "name": "Sonsonate Department",
                "state_code": "SO"
            },
            {
                "id": 4129,
                "name": "Usulután Department",
                "state_code": "US"
            }
        ]
    },
    {
        "name": "Equatorial Guinea",
        "iso3": "GNQ",
        "iso2": "GQ",
        "phone_code": "240",
        "capital": "Malabo",
        "currency": "XAF",
        "currency_symbol": "FCFA",
        "tld": ".gq",
        "native": "Guinea Ecuatorial",
        "region": "Africa",
        "subregion": "Middle Africa",
        "timezones": [
            {
                "zoneName": "Africa/Malabo",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WAT",
                "tzName": "West Africa Time"
            }
        ],
        "translations": {
            "br": "Guiné Equatorial",
            "pt": "Guiné Equatorial",
            "nl": "Equatoriaal-Guinea",
            "hr": "Ekvatorijalna Gvineja",
            "fa": "گینه استوایی",
            "de": "Äquatorial-Guinea",
            "es": "Guinea Ecuatorial",
            "fr": "Guinée-Équatoriale",
            "ja": "赤道ギニア",
            "it": "Guinea Equatoriale"
        },
        "latitude": "2.00000000",
        "longitude": "10.00000000",
        "emoji": "🇬🇶",
        "emojiU": "U+1F1EC U+1F1F6",
        "states": [
            {
                "id": 3444,
                "name": "Annobón Province",
                "state_code": "AN"
            },
            {
                "id": 3446,
                "name": "Bioko Norte Province",
                "state_code": "BN"
            },
            {
                "id": 3443,
                "name": "Bioko Sur Province",
                "state_code": "BS"
            },
            {
                "id": 3445,
                "name": "Centro Sur Province",
                "state_code": "CS"
            },
            {
                "id": 3442,
                "name": "Insular Region",
                "state_code": "I"
            },
            {
                "id": 3439,
                "name": "Kié-Ntem Province",
                "state_code": "KN"
            },
            {
                "id": 3441,
                "name": "Litoral Province",
                "state_code": "LI"
            },
            {
                "id": 3438,
                "name": "Río Muni",
                "state_code": "C"
            },
            {
                "id": 3440,
                "name": "Wele-Nzas Province",
                "state_code": "WN"
            }
        ]
    },
    {
        "name": "Eritrea",
        "iso3": "ERI",
        "iso2": "ER",
        "phone_code": "291",
        "capital": "Asmara",
        "currency": "ERN",
        "currency_symbol": "Nfk",
        "tld": ".er",
        "native": "ኤርትራ",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa/Asmara",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "EAT",
                "tzName": "East Africa Time"
            }
        ],
        "translations": {
            "br": "Eritreia",
            "pt": "Eritreia",
            "nl": "Eritrea",
            "hr": "Eritreja",
            "fa": "اریتره",
            "de": "Eritrea",
            "es": "Eritrea",
            "fr": "Érythrée",
            "ja": "エリトリア",
            "it": "Eritrea"
        },
        "latitude": "15.00000000",
        "longitude": "39.00000000",
        "emoji": "🇪🇷",
        "emojiU": "U+1F1EA U+1F1F7",
        "states": [
            {
                "id": 3425,
                "name": "Anseba Region",
                "state_code": "AN"
            },
            {
                "id": 3427,
                "name": "Debub Region",
                "state_code": "DU"
            },
            {
                "id": 3428,
                "name": "Gash-Barka Region",
                "state_code": "GB"
            },
            {
                "id": 3426,
                "name": "Maekel Region",
                "state_code": "MA"
            },
            {
                "id": 3424,
                "name": "Northern Red Sea Region",
                "state_code": "SK"
            },
            {
                "id": 3429,
                "name": "Southern Red Sea Region",
                "state_code": "DK"
            }
        ]
    },
    {
        "name": "Estonia",
        "iso3": "EST",
        "iso2": "EE",
        "phone_code": "372",
        "capital": "Tallinn",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".ee",
        "native": "Eesti",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Tallinn",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "translations": {
            "br": "Estônia",
            "pt": "Estónia",
            "nl": "Estland",
            "hr": "Estonija",
            "fa": "استونی",
            "de": "Estland",
            "es": "Estonia",
            "fr": "Estonie",
            "ja": "エストニア",
            "it": "Estonia"
        },
        "latitude": "59.00000000",
        "longitude": "26.00000000",
        "emoji": "🇪🇪",
        "emojiU": "U+1F1EA U+1F1EA",
        "states": [
            {
                "id": 3567,
                "name": "Harju County",
                "state_code": "37"
            },
            {
                "id": 3555,
                "name": "Hiiu County",
                "state_code": "39"
            },
            {
                "id": 3569,
                "name": "Ida-Viru County",
                "state_code": "44"
            },
            {
                "id": 3566,
                "name": "Järva County",
                "state_code": "51"
            },
            {
                "id": 3565,
                "name": "Jõgeva County",
                "state_code": "49"
            },
            {
                "id": 3568,
                "name": "Lääne County",
                "state_code": "57"
            },
            {
                "id": 3564,
                "name": "Lääne-Viru County",
                "state_code": "59"
            },
            {
                "id": 3562,
                "name": "Pärnu County",
                "state_code": "67"
            },
            {
                "id": 3563,
                "name": "Põlva County",
                "state_code": "65"
            },
            {
                "id": 3559,
                "name": "Rapla County",
                "state_code": "70"
            },
            {
                "id": 3561,
                "name": "Saare County",
                "state_code": "74"
            },
            {
                "id": 3557,
                "name": "Tartu County",
                "state_code": "78"
            },
            {
                "id": 3558,
                "name": "Valga County",
                "state_code": "82"
            },
            {
                "id": 3556,
                "name": "Viljandi County",
                "state_code": "84"
            },
            {
                "id": 3560,
                "name": "Võru County",
                "state_code": "86"
            }
        ]
    },
    {
        "name": "Ethiopia",
        "iso3": "ETH",
        "iso2": "ET",
        "phone_code": "251",
        "capital": "Addis Ababa",
        "currency": "ETB",
        "currency_symbol": "Nkf",
        "tld": ".et",
        "native": "ኢትዮጵያ",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa/Addis_Ababa",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "EAT",
                "tzName": "East Africa Time"
            }
        ],
        "translations": {
            "br": "Etiópia",
            "pt": "Etiópia",
            "nl": "Ethiopië",
            "hr": "Etiopija",
            "fa": "اتیوپی",
            "de": "Äthiopien",
            "es": "Etiopía",
            "fr": "Éthiopie",
            "ja": "エチオピア",
            "it": "Etiopia"
        },
        "latitude": "8.00000000",
        "longitude": "38.00000000",
        "emoji": "🇪🇹",
        "emojiU": "U+1F1EA U+1F1F9",
        "states": [
            {
                "id": 11,
                "name": "Addis Ababa",
                "state_code": "AA"
            },
            {
                "id": 6,
                "name": "Afar Region",
                "state_code": "AF"
            },
            {
                "id": 3,
                "name": "Amhara Region",
                "state_code": "AM"
            },
            {
                "id": 9,
                "name": "Benishangul-Gumuz Region",
                "state_code": "BE"
            },
            {
                "id": 8,
                "name": "Dire Dawa",
                "state_code": "DD"
            },
            {
                "id": 10,
                "name": "Gambela Region",
                "state_code": "GA"
            },
            {
                "id": 7,
                "name": "Harari Region",
                "state_code": "HA"
            },
            {
                "id": 5,
                "name": "Oromia Region",
                "state_code": "OR"
            },
            {
                "id": 2,
                "name": "Somali Region",
                "state_code": "SO"
            },
            {
                "id": 1,
                "name": "Southern Nations, Nationalities, and Peoples' Region",
                "state_code": "SN"
            },
            {
                "id": 4,
                "name": "Tigray Region",
                "state_code": "TI"
            }
        ]
    },
    {
        "name": "Falkland Islands",
        "iso3": "FLK",
        "iso2": "FK",
        "phone_code": "500",
        "capital": "Stanley",
        "currency": "FKP",
        "currency_symbol": "£",
        "tld": ".fk",
        "native": "Falkland Islands",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "Atlantic/Stanley",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "FKST",
                "tzName": "Falkland Islands Summer Time"
            }
        ],
        "translations": {
            "br": "Ilhas Malvinas",
            "pt": "Ilhas Falkland",
            "nl": "Falklandeilanden [Islas Malvinas]",
            "hr": "Falklandski Otoci",
            "fa": "جزایر فالکلند",
            "de": "Falklandinseln",
            "es": "Islas Malvinas",
            "fr": "Îles Malouines",
            "ja": "フォークランド（マルビナス）諸島",
            "it": "Isole Falkland o Isole Malvine"
        },
        "latitude": "-51.75000000",
        "longitude": "-59.00000000",
        "emoji": "🇫🇰",
        "emojiU": "U+1F1EB U+1F1F0",
        "states": []
    },
    {
        "name": "Faroe Islands",
        "iso3": "FRO",
        "iso2": "FO",
        "phone_code": "298",
        "capital": "Torshavn",
        "currency": "DKK",
        "currency_symbol": "Kr.",
        "tld": ".fo",
        "native": "Føroyar",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Atlantic/Faroe",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "WET",
                "tzName": "Western European Time"
            }
        ],
        "translations": {
            "br": "Ilhas Faroé",
            "pt": "Ilhas Faroé",
            "nl": "Faeröer",
            "hr": "Farski Otoci",
            "fa": "جزایر فارو",
            "de": "Färöer-Inseln",
            "es": "Islas Faroe",
            "fr": "Îles Féroé",
            "ja": "フェロー諸島",
            "it": "Isole Far Oer"
        },
        "latitude": "62.00000000",
        "longitude": "-7.00000000",
        "emoji": "🇫🇴",
        "emojiU": "U+1F1EB U+1F1F4",
        "states": []
    },
    {
        "name": "Fiji Islands",
        "iso3": "FJI",
        "iso2": "FJ",
        "phone_code": "679",
        "capital": "Suva",
        "currency": "FJD",
        "currency_symbol": "FJ$",
        "tld": ".fj",
        "native": "Fiji",
        "region": "Oceania",
        "subregion": "Melanesia",
        "timezones": [
            {
                "zoneName": "Pacific/Fiji",
                "gmtOffset": 43200,
                "gmtOffsetName": "UTC+12:00",
                "abbreviation": "FJT",
                "tzName": "Fiji Time"
            }
        ],
        "translations": {
            "br": "Fiji",
            "pt": "Fiji",
            "nl": "Fiji",
            "hr": "Fiđi",
            "fa": "فیجی",
            "de": "Fidschi",
            "es": "Fiyi",
            "fr": "Fidji",
            "ja": "フィジー",
            "it": "Figi"
        },
        "latitude": "-18.00000000",
        "longitude": "175.00000000",
        "emoji": "🇫🇯",
        "emojiU": "U+1F1EB U+1F1EF",
        "states": [
            {
                "id": 1917,
                "name": "Ba",
                "state_code": "01"
            },
            {
                "id": 1930,
                "name": "Bua",
                "state_code": "02"
            },
            {
                "id": 1924,
                "name": "Cakaudrove",
                "state_code": "03"
            },
            {
                "id": 1929,
                "name": "Central Division",
                "state_code": "C"
            },
            {
                "id": 1932,
                "name": "Eastern Division",
                "state_code": "E"
            },
            {
                "id": 1934,
                "name": "Kadavu",
                "state_code": "04"
            },
            {
                "id": 1933,
                "name": "Lau",
                "state_code": "05"
            },
            {
                "id": 1916,
                "name": "Lomaiviti",
                "state_code": "06"
            },
            {
                "id": 1922,
                "name": "Macuata",
                "state_code": "07"
            },
            {
                "id": 1919,
                "name": "Nadroga-Navosa",
                "state_code": "08"
            },
            {
                "id": 1927,
                "name": "Naitasiri",
                "state_code": "09"
            },
            {
                "id": 1928,
                "name": "Namosi",
                "state_code": "10"
            },
            {
                "id": 1921,
                "name": "Northern Division",
                "state_code": "N"
            },
            {
                "id": 1926,
                "name": "Ra",
                "state_code": "11"
            },
            {
                "id": 1920,
                "name": "Rewa",
                "state_code": "12"
            },
            {
                "id": 1931,
                "name": "Rotuma",
                "state_code": "R"
            },
            {
                "id": 1925,
                "name": "Serua",
                "state_code": "13"
            },
            {
                "id": 1918,
                "name": "Tailevu",
                "state_code": "14"
            },
            {
                "id": 1923,
                "name": "Western Division",
                "state_code": "W"
            }
        ]
    },
    {
        "name": "Finland",
        "iso3": "FIN",
        "iso2": "FI",
        "phone_code": "358",
        "capital": "Helsinki",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".fi",
        "native": "Suomi",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Helsinki",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "translations": {
            "br": "Finlândia",
            "pt": "Finlândia",
            "nl": "Finland",
            "hr": "Finska",
            "fa": "فنلاند",
            "de": "Finnland",
            "es": "Finlandia",
            "fr": "Finlande",
            "ja": "フィンランド",
            "it": "Finlandia"
        },
        "latitude": "64.00000000",
        "longitude": "26.00000000",
        "emoji": "🇫🇮",
        "emojiU": "U+1F1EB U+1F1EE",
        "states": [
            {
                "id": 1509,
                "name": "Åland Islands",
                "state_code": "01"
            },
            {
                "id": 1511,
                "name": "Central Finland",
                "state_code": "08"
            },
            {
                "id": 1494,
                "name": "Central Ostrobothnia",
                "state_code": "07"
            },
            {
                "id": 1492,
                "name": "Eastern Finland Province",
                "state_code": "IS"
            },
            {
                "id": 1507,
                "name": "Finland Proper",
                "state_code": "19"
            },
            {
                "id": 1496,
                "name": "Kainuu",
                "state_code": "05"
            },
            {
                "id": 1512,
                "name": "Kymenlaakso",
                "state_code": "09"
            },
            {
                "id": 1500,
                "name": "Lapland",
                "state_code": "LL"
            },
            {
                "id": 1504,
                "name": "North Karelia",
                "state_code": "13"
            },
            {
                "id": 1505,
                "name": "Northern Ostrobothnia",
                "state_code": "14"
            },
            {
                "id": 1503,
                "name": "Northern Savonia",
                "state_code": "15"
            },
            {
                "id": 1508,
                "name": "Ostrobothnia",
                "state_code": "12"
            },
            {
                "id": 1499,
                "name": "Oulu Province",
                "state_code": "OL"
            },
            {
                "id": 1502,
                "name": "Päijänne Tavastia",
                "state_code": "16"
            },
            {
                "id": 1506,
                "name": "Pirkanmaa",
                "state_code": "11"
            },
            {
                "id": 1501,
                "name": "Satakunta",
                "state_code": "17"
            },
            {
                "id": 1497,
                "name": "South Karelia",
                "state_code": "02"
            },
            {
                "id": 1498,
                "name": "Southern Ostrobothnia",
                "state_code": "03"
            },
            {
                "id": 1495,
                "name": "Southern Savonia",
                "state_code": "04"
            },
            {
                "id": 1493,
                "name": "Tavastia Proper",
                "state_code": "06"
            },
            {
                "id": 1510,
                "name": "Uusimaa",
                "state_code": "18"
            }
        ]
    },
    {
        "name": "France",
        "iso3": "FRA",
        "iso2": "FR",
        "phone_code": "33",
        "capital": "Paris",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".fr",
        "native": "France",
        "region": "Europe",
        "subregion": "Western Europe",
        "timezones": [
            {
                "zoneName": "Europe/Paris",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "França",
            "pt": "França",
            "nl": "Frankrijk",
            "hr": "Francuska",
            "fa": "فرانسه",
            "de": "Frankreich",
            "es": "Francia",
            "fr": "France",
            "ja": "フランス",
            "it": "Francia"
        },
        "latitude": "46.00000000",
        "longitude": "2.00000000",
        "emoji": "🇫🇷",
        "emojiU": "U+1F1EB U+1F1F7",
        "states": [
            {
                "id": 4800,
                "name": "Alo",
                "state_code": "WF-AL"
            },
            {
                "id": 4811,
                "name": "Alsace",
                "state_code": "A"
            },
            {
                "id": 4808,
                "name": "Aquitaine",
                "state_code": "B"
            },
            {
                "id": 4789,
                "name": "Auvergne",
                "state_code": "C"
            },
            {
                "id": 4798,
                "name": "Auvergne-Rhône-Alpes",
                "state_code": "ARA"
            },
            {
                "id": 4825,
                "name": "Bourgogne-Franche-Comté",
                "state_code": "BFC"
            },
            {
                "id": 4807,
                "name": "Brittany",
                "state_code": "BRE"
            },
            {
                "id": 4788,
                "name": "Burgundy",
                "state_code": "D"
            },
            {
                "id": 4818,
                "name": "Centre-Val de Loire",
                "state_code": "CVL"
            },
            {
                "id": 4791,
                "name": "Champagne-Ardenne",
                "state_code": "G"
            },
            {
                "id": 4806,
                "name": "Corsica",
                "state_code": "COR"
            },
            {
                "id": 4805,
                "name": "Franche-Comté",
                "state_code": "I"
            },
            {
                "id": 4822,
                "name": "French Guiana",
                "state_code": "GF"
            },
            {
                "id": 4824,
                "name": "French Polynesia",
                "state_code": "PF"
            },
            {
                "id": 4820,
                "name": "Grand Est",
                "state_code": "GES"
            },
            {
                "id": 4829,
                "name": "Guadeloupe",
                "state_code": "GP"
            },
            {
                "id": 4828,
                "name": "Hauts-de-France",
                "state_code": "HDF"
            },
            {
                "id": 4796,
                "name": "Île-de-France",
                "state_code": "IDF"
            },
            {
                "id": 4803,
                "name": "Languedoc-Roussillon",
                "state_code": "K"
            },
            {
                "id": 4792,
                "name": "Limousin",
                "state_code": "L"
            },
            {
                "id": 4801,
                "name": "Lorraine",
                "state_code": "M"
            },
            {
                "id": 4814,
                "name": "Lower Normandy",
                "state_code": "P"
            },
            {
                "id": 4827,
                "name": "Martinique",
                "state_code": "MQ"
            },
            {
                "id": 4797,
                "name": "Mayotte",
                "state_code": "YT"
            },
            {
                "id": 4793,
                "name": "Nord-Pas-de-Calais",
                "state_code": "O"
            },
            {
                "id": 4804,
                "name": "Normandy",
                "state_code": "NOR"
            },
            {
                "id": 4795,
                "name": "Nouvelle-Aquitaine",
                "state_code": "NAQ"
            },
            {
                "id": 4799,
                "name": "Occitania",
                "state_code": "OCC"
            },
            {
                "id": 4816,
                "name": "Paris",
                "state_code": "75"
            },
            {
                "id": 4802,
                "name": "Pays de la Loire",
                "state_code": "PDL"
            },
            {
                "id": 4790,
                "name": "Picardy",
                "state_code": "S"
            },
            {
                "id": 4815,
                "name": "Poitou-Charentes",
                "state_code": "T"
            },
            {
                "id": 4812,
                "name": "Provence-Alpes-Côte d'Azur",
                "state_code": "PAC"
            },
            {
                "id": 4823,
                "name": "Réunion",
                "state_code": "RE"
            },
            {
                "id": 4813,
                "name": "Rhône-Alpes",
                "state_code": "V"
            },
            {
                "id": 4794,
                "name": "Saint Barthélemy",
                "state_code": "BL"
            },
            {
                "id": 4809,
                "name": "Saint Martin",
                "state_code": "MF"
            },
            {
                "id": 4821,
                "name": "Saint Pierre and Miquelon",
                "state_code": "PM"
            },
            {
                "id": 4819,
                "name": "Sigave",
                "state_code": "WF-SG"
            },
            {
                "id": 4826,
                "name": "Upper Normandy",
                "state_code": "Q"
            },
            {
                "id": 4817,
                "name": "Uvea",
                "state_code": "WF-UV"
            },
            {
                "id": 4810,
                "name": "Wallis and Futuna",
                "state_code": "WF"
            }
        ]
    },
    {
        "name": "French Guiana",
        "iso3": "GUF",
        "iso2": "GF",
        "phone_code": "594",
        "capital": "Cayenne",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".gf",
        "native": "Guyane française",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America/Cayenne",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "GFT",
                "tzName": "French Guiana Time"
            }
        ],
        "translations": {
            "br": "Guiana Francesa",
            "pt": "Guiana Francesa",
            "nl": "Frans-Guyana",
            "hr": "Francuska Gvajana",
            "fa": "گویان فرانسه",
            "de": "Französisch Guyana",
            "es": "Guayana Francesa",
            "fr": "Guayane",
            "ja": "フランス領ギアナ",
            "it": "Guyana francese"
        },
        "latitude": "4.00000000",
        "longitude": "-53.00000000",
        "emoji": "🇬🇫",
        "emojiU": "U+1F1EC U+1F1EB",
        "states": []
    },
    {
        "name": "French Polynesia",
        "iso3": "PYF",
        "iso2": "PF",
        "phone_code": "689",
        "capital": "Papeete",
        "currency": "XPF",
        "currency_symbol": "₣",
        "tld": ".pf",
        "native": "Polynésie française",
        "region": "Oceania",
        "subregion": "Polynesia",
        "timezones": [
            {
                "zoneName": "Pacific/Gambier",
                "gmtOffset": -32400,
                "gmtOffsetName": "UTC-09:00",
                "abbreviation": "GAMT",
                "tzName": "Gambier Islands Time"
            },
            {
                "zoneName": "Pacific/Marquesas",
                "gmtOffset": -34200,
                "gmtOffsetName": "UTC-09:30",
                "abbreviation": "MART",
                "tzName": "Marquesas Islands Time"
            },
            {
                "zoneName": "Pacific/Tahiti",
                "gmtOffset": -36000,
                "gmtOffsetName": "UTC-10:00",
                "abbreviation": "TAHT",
                "tzName": "Tahiti Time"
            }
        ],
        "translations": {
            "br": "Polinésia Francesa",
            "pt": "Polinésia Francesa",
            "nl": "Frans-Polynesië",
            "hr": "Francuska Polinezija",
            "fa": "پلی‌نزی فرانسه",
            "de": "Französisch-Polynesien",
            "es": "Polinesia Francesa",
            "fr": "Polynésie française",
            "ja": "フランス領ポリネシア",
            "it": "Polinesia Francese"
        },
        "latitude": "-15.00000000",
        "longitude": "-140.00000000",
        "emoji": "🇵🇫",
        "emojiU": "U+1F1F5 U+1F1EB",
        "states": []
    },
    {
        "name": "French Southern Territories",
        "iso3": "ATF",
        "iso2": "TF",
        "phone_code": "",
        "capital": "Port-aux-Francais",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".tf",
        "native": "Territoire des Terres australes et antarctiques fr",
        "region": "Africa",
        "subregion": "Southern Africa",
        "timezones": [
            {
                "zoneName": "Indian/Kerguelen",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "TFT",
                "tzName": "French Southern and Antarctic Time"
            }
        ],
        "translations": {
            "br": "Terras Austrais e Antárticas Francesas",
            "pt": "Terras Austrais e Antárticas Francesas",
            "nl": "Franse Gebieden in de zuidelijke Indische Oceaan",
            "hr": "Francuski južni i antarktički teritoriji",
            "fa": "سرزمین‌های جنوبی و جنوبگانی فرانسه",
            "de": "Französische Süd- und Antarktisgebiete",
            "es": "Tierras Australes y Antárticas Francesas",
            "fr": "Terres australes et antarctiques françaises",
            "ja": "フランス領南方・南極地域",
            "it": "Territori Francesi del Sud"
        },
        "latitude": "-49.25000000",
        "longitude": "69.16700000",
        "emoji": "🇹🇫",
        "emojiU": "U+1F1F9 U+1F1EB",
        "states": []
    },
    {
        "name": "Gabon",
        "iso3": "GAB",
        "iso2": "GA",
        "phone_code": "241",
        "capital": "Libreville",
        "currency": "XAF",
        "currency_symbol": "FCFA",
        "tld": ".ga",
        "native": "Gabon",
        "region": "Africa",
        "subregion": "Middle Africa",
        "timezones": [
            {
                "zoneName": "Africa/Libreville",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WAT",
                "tzName": "West Africa Time"
            }
        ],
        "translations": {
            "br": "Gabão",
            "pt": "Gabão",
            "nl": "Gabon",
            "hr": "Gabon",
            "fa": "گابن",
            "de": "Gabun",
            "es": "Gabón",
            "fr": "Gabon",
            "ja": "ガボン",
            "it": "Gabon"
        },
        "latitude": "-1.00000000",
        "longitude": "11.75000000",
        "emoji": "🇬🇦",
        "emojiU": "U+1F1EC U+1F1E6",
        "states": [
            {
                "id": 2727,
                "name": "Estuaire Province",
                "state_code": "1"
            },
            {
                "id": 2726,
                "name": "Haut-Ogooué Province",
                "state_code": "2"
            },
            {
                "id": 2730,
                "name": "Moyen-Ogooué Province",
                "state_code": "3"
            },
            {
                "id": 2731,
                "name": "Ngounié Province",
                "state_code": "4"
            },
            {
                "id": 2725,
                "name": "Nyanga Province",
                "state_code": "5"
            },
            {
                "id": 2724,
                "name": "Ogooué-Ivindo Province",
                "state_code": "6"
            },
            {
                "id": 2729,
                "name": "Ogooué-Lolo Province",
                "state_code": "7"
            },
            {
                "id": 2728,
                "name": "Ogooué-Maritime Province",
                "state_code": "8"
            },
            {
                "id": 2723,
                "name": "Woleu-Ntem Province",
                "state_code": "9"
            }
        ]
    },
    {
        "name": "Gambia The",
        "iso3": "GMB",
        "iso2": "GM",
        "phone_code": "220",
        "capital": "Banjul",
        "currency": "GMD",
        "currency_symbol": "D",
        "tld": ".gm",
        "native": "Gambia",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa/Banjul",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "translations": {
            "br": "Gâmbia",
            "pt": "Gâmbia",
            "nl": "Gambia",
            "hr": "Gambija",
            "fa": "گامبیا",
            "de": "Gambia",
            "es": "Gambia",
            "fr": "Gambie",
            "ja": "ガンビア",
            "it": "Gambia"
        },
        "latitude": "13.46666666",
        "longitude": "-16.56666666",
        "emoji": "🇬🇲",
        "emojiU": "U+1F1EC U+1F1F2",
        "states": [
            {
                "id": 2666,
                "name": "Banjul",
                "state_code": "B"
            },
            {
                "id": 2669,
                "name": "Central River Division",
                "state_code": "M"
            },
            {
                "id": 2670,
                "name": "Lower River Division",
                "state_code": "L"
            },
            {
                "id": 2671,
                "name": "North Bank Division",
                "state_code": "N"
            },
            {
                "id": 2668,
                "name": "Upper River Division",
                "state_code": "U"
            },
            {
                "id": 2667,
                "name": "West Coast Division",
                "state_code": "W"
            }
        ]
    },
    {
        "name": "Georgia",
        "iso3": "GEO",
        "iso2": "GE",
        "phone_code": "995",
        "capital": "Tbilisi",
        "currency": "GEL",
        "currency_symbol": "ლ",
        "tld": ".ge",
        "native": "საქართველო",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia/Tbilisi",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "GET",
                "tzName": "Georgia Standard Time"
            }
        ],
        "translations": {
            "br": "Geórgia",
            "pt": "Geórgia",
            "nl": "Georgië",
            "hr": "Gruzija",
            "fa": "گرجستان",
            "de": "Georgien",
            "es": "Georgia",
            "fr": "Géorgie",
            "ja": "グルジア",
            "it": "Georgia"
        },
        "latitude": "42.00000000",
        "longitude": "43.50000000",
        "emoji": "🇬🇪",
        "emojiU": "U+1F1EC U+1F1EA",
        "states": [
            {
                "id": 900,
                "name": "Adjara",
                "state_code": "AJ"
            },
            {
                "id": 901,
                "name": "Autonomous Republic of Abkhazia",
                "state_code": "AB"
            },
            {
                "id": 907,
                "name": "Guria",
                "state_code": "GU"
            },
            {
                "id": 905,
                "name": "Imereti",
                "state_code": "IM"
            },
            {
                "id": 910,
                "name": "Kakheti",
                "state_code": "KA"
            },
            {
                "id": 897,
                "name": "Khelvachauri Municipality",
                "state_code": "29"
            },
            {
                "id": 904,
                "name": "Kvemo Kartli",
                "state_code": "KK"
            },
            {
                "id": 902,
                "name": "Mtskheta-Mtianeti",
                "state_code": "MM"
            },
            {
                "id": 909,
                "name": "Racha-Lechkhumi and Kvemo Svaneti",
                "state_code": "RL"
            },
            {
                "id": 908,
                "name": "Samegrelo-Zemo Svaneti",
                "state_code": "SZ"
            },
            {
                "id": 906,
                "name": "Samtskhe-Javakheti",
                "state_code": "SJ"
            },
            {
                "id": 898,
                "name": "Senaki Municipality",
                "state_code": "50"
            },
            {
                "id": 903,
                "name": "Shida Kartli",
                "state_code": "SK"
            },
            {
                "id": 899,
                "name": "Tbilisi",
                "state_code": "TB"
            }
        ]
    },
    {
        "name": "Germany",
        "iso3": "DEU",
        "iso2": "DE",
        "phone_code": "49",
        "capital": "Berlin",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".de",
        "native": "Deutschland",
        "region": "Europe",
        "subregion": "Western Europe",
        "timezones": [
            {
                "zoneName": "Europe/Berlin",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            },
            {
                "zoneName": "Europe/Busingen",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Alemanha",
            "pt": "Alemanha",
            "nl": "Duitsland",
            "hr": "Njemačka",
            "fa": "آلمان",
            "de": "Deutschland",
            "es": "Alemania",
            "fr": "Allemagne",
            "ja": "ドイツ",
            "it": "Germania"
        },
        "latitude": "51.00000000",
        "longitude": "9.00000000",
        "emoji": "🇩🇪",
        "emojiU": "U+1F1E9 U+1F1EA",
        "states": [
            {
                "id": 3006,
                "name": "Baden-Württemberg",
                "state_code": "BW"
            },
            {
                "id": 3009,
                "name": "Bavaria",
                "state_code": "BY"
            },
            {
                "id": 3010,
                "name": "Berlin",
                "state_code": "BE"
            },
            {
                "id": 3013,
                "name": "Brandenburg",
                "state_code": "BB"
            },
            {
                "id": 3014,
                "name": "Bremen",
                "state_code": "HB"
            },
            {
                "id": 3016,
                "name": "Hamburg",
                "state_code": "HH"
            },
            {
                "id": 3018,
                "name": "Hesse",
                "state_code": "HE"
            },
            {
                "id": 3008,
                "name": "Lower Saxony",
                "state_code": "NI"
            },
            {
                "id": 3007,
                "name": "Mecklenburg-Vorpommern",
                "state_code": "MV"
            },
            {
                "id": 3017,
                "name": "North Rhine-Westphalia",
                "state_code": "NW"
            },
            {
                "id": 3019,
                "name": "Rhineland-Palatinate",
                "state_code": "RP"
            },
            {
                "id": 3020,
                "name": "Saarland",
                "state_code": "SL"
            },
            {
                "id": 3021,
                "name": "Saxony",
                "state_code": "SN"
            },
            {
                "id": 3011,
                "name": "Saxony-Anhalt",
                "state_code": "ST"
            },
            {
                "id": 3005,
                "name": "Schleswig-Holstein",
                "state_code": "SH"
            },
            {
                "id": 3015,
                "name": "Thuringia",
                "state_code": "TH"
            }
        ]
    },
    {
        "name": "Ghana",
        "iso3": "GHA",
        "iso2": "GH",
        "phone_code": "233",
        "capital": "Accra",
        "currency": "GHS",
        "currency_symbol": "GH₵",
        "tld": ".gh",
        "native": "Ghana",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa/Accra",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "translations": {
            "br": "Gana",
            "pt": "Gana",
            "nl": "Ghana",
            "hr": "Gana",
            "fa": "غنا",
            "de": "Ghana",
            "es": "Ghana",
            "fr": "Ghana",
            "ja": "ガーナ",
            "it": "Ghana"
        },
        "latitude": "8.00000000",
        "longitude": "-2.00000000",
        "emoji": "🇬🇭",
        "emojiU": "U+1F1EC U+1F1ED",
        "states": [
            {
                "id": 48,
                "name": "Ashanti Region",
                "state_code": "AH"
            },
            {
                "id": 53,
                "name": "Brong-Ahafo Region",
                "state_code": "BA"
            },
            {
                "id": 52,
                "name": "Central Region",
                "state_code": "CP"
            },
            {
                "id": 50,
                "name": "Eastern Region",
                "state_code": "EP"
            },
            {
                "id": 54,
                "name": "Greater Accra Region",
                "state_code": "AA"
            },
            {
                "id": 51,
                "name": "Northern Region",
                "state_code": "NP"
            },
            {
                "id": 55,
                "name": "Upper East Region",
                "state_code": "UE"
            },
            {
                "id": 57,
                "name": "Upper West Region",
                "state_code": "UW"
            },
            {
                "id": 56,
                "name": "Volta Region",
                "state_code": "TV"
            },
            {
                "id": 49,
                "name": "Western Region",
                "state_code": "WP"
            }
        ]
    },
    {
        "name": "Gibraltar",
        "iso3": "GIB",
        "iso2": "GI",
        "phone_code": "350",
        "capital": "Gibraltar",
        "currency": "GIP",
        "currency_symbol": "£",
        "tld": ".gi",
        "native": "Gibraltar",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Gibraltar",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Gibraltar",
            "pt": "Gibraltar",
            "nl": "Gibraltar",
            "hr": "Gibraltar",
            "fa": "جبل‌طارق",
            "de": "Gibraltar",
            "es": "Gibraltar",
            "fr": "Gibraltar",
            "ja": "ジブラルタル",
            "it": "Gibilterra"
        },
        "latitude": "36.13333333",
        "longitude": "-5.35000000",
        "emoji": "🇬🇮",
        "emojiU": "U+1F1EC U+1F1EE",
        "states": []
    },
    {
        "name": "Greece",
        "iso3": "GRC",
        "iso2": "GR",
        "phone_code": "30",
        "capital": "Athens",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".gr",
        "native": "Ελλάδα",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Athens",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "translations": {
            "br": "Grécia",
            "pt": "Grécia",
            "nl": "Griekenland",
            "hr": "Grčka",
            "fa": "یونان",
            "de": "Griechenland",
            "es": "Grecia",
            "fr": "Grèce",
            "ja": "ギリシャ",
            "it": "Grecia"
        },
        "latitude": "39.00000000",
        "longitude": "22.00000000",
        "emoji": "🇬🇷",
        "emojiU": "U+1F1EC U+1F1F7",
        "states": [
            {
                "id": 2116,
                "name": "Achaea Regional Unit",
                "state_code": "13"
            },
            {
                "id": 2123,
                "name": "Aetolia-Acarnania Regional Unit",
                "state_code": "01"
            },
            {
                "id": 2098,
                "name": "Arcadia Prefecture",
                "state_code": "12"
            },
            {
                "id": 2105,
                "name": "Argolis Regional Unit",
                "state_code": "11"
            },
            {
                "id": 2122,
                "name": "Attica Region",
                "state_code": "I"
            },
            {
                "id": 2126,
                "name": "Boeotia Regional Unit",
                "state_code": "03"
            },
            {
                "id": 2128,
                "name": "Central Greece Region",
                "state_code": "H"
            },
            {
                "id": 2125,
                "name": "Central Macedonia",
                "state_code": "B"
            },
            {
                "id": 2115,
                "name": "Chania Regional Unit",
                "state_code": "94"
            },
            {
                "id": 2124,
                "name": "Corfu Prefecture",
                "state_code": "22"
            },
            {
                "id": 2129,
                "name": "Corinthia Regional Unit",
                "state_code": "15"
            },
            {
                "id": 2109,
                "name": "Crete Region",
                "state_code": "M"
            },
            {
                "id": 2130,
                "name": "Drama Regional Unit",
                "state_code": "52"
            },
            {
                "id": 2120,
                "name": "East Attica Regional Unit",
                "state_code": "A2"
            },
            {
                "id": 2117,
                "name": "East Macedonia and Thrace",
                "state_code": "A"
            },
            {
                "id": 2110,
                "name": "Epirus Region",
                "state_code": "D"
            },
            {
                "id": 2101,
                "name": "Euboea",
                "state_code": "04"
            },
            {
                "id": 2102,
                "name": "Grevena Prefecture",
                "state_code": "51"
            },
            {
                "id": 2099,
                "name": "Imathia Regional Unit",
                "state_code": "53"
            },
            {
                "id": 2113,
                "name": "Ioannina Regional Unit",
                "state_code": "33"
            },
            {
                "id": 2131,
                "name": "Ionian Islands Region",
                "state_code": "F"
            },
            {
                "id": 2095,
                "name": "Karditsa Regional Unit",
                "state_code": "41"
            },
            {
                "id": 2100,
                "name": "Kastoria Regional Unit",
                "state_code": "56"
            },
            {
                "id": 2127,
                "name": "Kefalonia Prefecture",
                "state_code": "23"
            },
            {
                "id": 2111,
                "name": "Kilkis Regional Unit",
                "state_code": "57"
            },
            {
                "id": 2112,
                "name": "Kozani Prefecture",
                "state_code": "58"
            },
            {
                "id": 2106,
                "name": "Laconia",
                "state_code": "16"
            },
            {
                "id": 2132,
                "name": "Larissa Prefecture",
                "state_code": "42"
            },
            {
                "id": 2104,
                "name": "Lefkada Regional Unit",
                "state_code": "24"
            },
            {
                "id": 2107,
                "name": "Pella Regional Unit",
                "state_code": "59"
            },
            {
                "id": 2119,
                "name": "Peloponnese Region",
                "state_code": "J"
            },
            {
                "id": 2114,
                "name": "Phthiotis Prefecture",
                "state_code": "06"
            },
            {
                "id": 2103,
                "name": "Preveza Prefecture",
                "state_code": "34"
            },
            {
                "id": 2121,
                "name": "Serres Prefecture",
                "state_code": "62"
            },
            {
                "id": 2118,
                "name": "South Aegean",
                "state_code": "L"
            },
            {
                "id": 2097,
                "name": "Thessaloniki Regional Unit",
                "state_code": "54"
            },
            {
                "id": 2096,
                "name": "West Greece Region",
                "state_code": "G"
            },
            {
                "id": 2108,
                "name": "West Macedonia Region",
                "state_code": "C"
            }
        ]
    },
    {
        "name": "Greenland",
        "iso3": "GRL",
        "iso2": "GL",
        "phone_code": "299",
        "capital": "Nuuk",
        "currency": "DKK",
        "currency_symbol": "Kr.",
        "tld": ".gl",
        "native": "Kalaallit Nunaat",
        "region": "Americas",
        "subregion": "Northern America",
        "timezones": [
            {
                "zoneName": "America/Danmarkshavn",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            },
            {
                "zoneName": "America/Nuuk",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "WGT",
                "tzName": "West Greenland Time"
            },
            {
                "zoneName": "America/Scoresbysund",
                "gmtOffset": -3600,
                "gmtOffsetName": "UTC-01:00",
                "abbreviation": "EGT",
                "tzName": "Eastern Greenland Time"
            },
            {
                "zoneName": "America/Thule",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "translations": {
            "br": "Groelândia",
            "pt": "Gronelândia",
            "nl": "Groenland",
            "hr": "Grenland",
            "fa": "گرینلند",
            "de": "Grönland",
            "es": "Groenlandia",
            "fr": "Groenland",
            "ja": "グリーンランド",
            "it": "Groenlandia"
        },
        "latitude": "72.00000000",
        "longitude": "-40.00000000",
        "emoji": "🇬🇱",
        "emojiU": "U+1F1EC U+1F1F1",
        "states": []
    },
    {
        "name": "Grenada",
        "iso3": "GRD",
        "iso2": "GD",
        "phone_code": "+1-473",
        "capital": "St. George's",
        "currency": "XCD",
        "currency_symbol": "$",
        "tld": ".gd",
        "native": "Grenada",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/Grenada",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "translations": {
            "br": "Granada",
            "pt": "Granada",
            "nl": "Grenada",
            "hr": "Grenada",
            "fa": "گرنادا",
            "de": "Grenada",
            "es": "Grenada",
            "fr": "Grenade",
            "ja": "グレナダ",
            "it": "Grenada"
        },
        "latitude": "12.11666666",
        "longitude": "-61.66666666",
        "emoji": "🇬🇩",
        "emojiU": "U+1F1EC U+1F1E9",
        "states": [
            {
                "id": 3867,
                "name": "Carriacou and Petite Martinique",
                "state_code": "10"
            },
            {
                "id": 3865,
                "name": "Saint Andrew Parish",
                "state_code": "01"
            },
            {
                "id": 3869,
                "name": "Saint David Parish",
                "state_code": "02"
            },
            {
                "id": 3864,
                "name": "Saint George Parish",
                "state_code": "03"
            },
            {
                "id": 3868,
                "name": "Saint John Parish",
                "state_code": "04"
            },
            {
                "id": 3866,
                "name": "Saint Mark Parish",
                "state_code": "05"
            },
            {
                "id": 3863,
                "name": "Saint Patrick Parish",
                "state_code": "06"
            }
        ]
    },
    {
        "name": "Guadeloupe",
        "iso3": "GLP",
        "iso2": "GP",
        "phone_code": "590",
        "capital": "Basse-Terre",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".gp",
        "native": "Guadeloupe",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/Guadeloupe",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "translations": {
            "br": "Guadalupe",
            "pt": "Guadalupe",
            "nl": "Guadeloupe",
            "hr": "Gvadalupa",
            "fa": "جزیره گوادلوپ",
            "de": "Guadeloupe",
            "es": "Guadalupe",
            "fr": "Guadeloupe",
            "ja": "グアドループ",
            "it": "Guadeloupa"
        },
        "latitude": "16.25000000",
        "longitude": "-61.58333300",
        "emoji": "🇬🇵",
        "emojiU": "U+1F1EC U+1F1F5",
        "states": []
    },
    {
        "name": "Guam",
        "iso3": "GUM",
        "iso2": "GU",
        "phone_code": "+1-671",
        "capital": "Hagatna",
        "currency": "USD",
        "currency_symbol": "$",
        "tld": ".gu",
        "native": "Guam",
        "region": "Oceania",
        "subregion": "Micronesia",
        "timezones": [
            {
                "zoneName": "Pacific/Guam",
                "gmtOffset": 36000,
                "gmtOffsetName": "UTC+10:00",
                "abbreviation": "CHST",
                "tzName": "Chamorro Standard Time"
            }
        ],
        "translations": {
            "br": "Guam",
            "pt": "Guame",
            "nl": "Guam",
            "hr": "Guam",
            "fa": "گوام",
            "de": "Guam",
            "es": "Guam",
            "fr": "Guam",
            "ja": "グアム",
            "it": "Guam"
        },
        "latitude": "13.46666666",
        "longitude": "144.78333333",
        "emoji": "🇬🇺",
        "emojiU": "U+1F1EC U+1F1FA",
        "states": []
    },
    {
        "name": "Guatemala",
        "iso3": "GTM",
        "iso2": "GT",
        "phone_code": "502",
        "capital": "Guatemala City",
        "currency": "GTQ",
        "currency_symbol": "Q",
        "tld": ".gt",
        "native": "Guatemala",
        "region": "Americas",
        "subregion": "Central America",
        "timezones": [
            {
                "zoneName": "America/Guatemala",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            }
        ],
        "translations": {
            "br": "Guatemala",
            "pt": "Guatemala",
            "nl": "Guatemala",
            "hr": "Gvatemala",
            "fa": "گواتمالا",
            "de": "Guatemala",
            "es": "Guatemala",
            "fr": "Guatemala",
            "ja": "グアテマラ",
            "it": "Guatemala"
        },
        "latitude": "15.50000000",
        "longitude": "-90.25000000",
        "emoji": "🇬🇹",
        "emojiU": "U+1F1EC U+1F1F9",
        "states": [
            {
                "id": 3671,
                "name": "Alta Verapaz Department",
                "state_code": "AV"
            },
            {
                "id": 3674,
                "name": "Baja Verapaz Department",
                "state_code": "BV"
            },
            {
                "id": 3675,
                "name": "Chimaltenango Department",
                "state_code": "CM"
            },
            {
                "id": 3666,
                "name": "Chiquimula Department",
                "state_code": "CQ"
            },
            {
                "id": 3662,
                "name": "El Progreso Department",
                "state_code": "PR"
            },
            {
                "id": 3677,
                "name": "Escuintla Department",
                "state_code": "ES"
            },
            {
                "id": 3672,
                "name": "Guatemala Department",
                "state_code": "GU"
            },
            {
                "id": 3670,
                "name": "Huehuetenango Department",
                "state_code": "HU"
            },
            {
                "id": 3659,
                "name": "Izabal Department",
                "state_code": "IZ"
            },
            {
                "id": 3658,
                "name": "Jalapa Department",
                "state_code": "JA"
            },
            {
                "id": 3673,
                "name": "Jutiapa Department",
                "state_code": "JU"
            },
            {
                "id": 3669,
                "name": "Petén Department",
                "state_code": "PE"
            },
            {
                "id": 3668,
                "name": "Quetzaltenango Department",
                "state_code": "QZ"
            },
            {
                "id": 3657,
                "name": "Quiché Department",
                "state_code": "QC"
            },
            {
                "id": 3664,
                "name": "Retalhuleu Department",
                "state_code": "RE"
            },
            {
                "id": 3676,
                "name": "Sacatepéquez Department",
                "state_code": "SA"
            },
            {
                "id": 3667,
                "name": "San Marcos Department",
                "state_code": "SM"
            },
            {
                "id": 3665,
                "name": "Santa Rosa Department",
                "state_code": "SR"
            },
            {
                "id": 3661,
                "name": "Sololá Department",
                "state_code": "SO"
            },
            {
                "id": 3660,
                "name": "Suchitepéquez Department",
                "state_code": "SU"
            },
            {
                "id": 3663,
                "name": "Totonicapán Department",
                "state_code": "TO"
            }
        ]
    },
    {
        "name": "Guernsey and Alderney",
        "iso3": "GGY",
        "iso2": "GG",
        "phone_code": "+44-1481",
        "capital": "St Peter Port",
        "currency": "GBP",
        "currency_symbol": "£",
        "tld": ".gg",
        "native": "Guernsey",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Guernsey",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "translations": {
            "br": "Guernsey",
            "pt": "Guernsey",
            "nl": "Guernsey",
            "hr": "Guernsey",
            "fa": "گرنزی",
            "de": "Guernsey",
            "es": "Guernsey",
            "fr": "Guernesey",
            "ja": "ガーンジー",
            "it": "Guernsey"
        },
        "latitude": "49.46666666",
        "longitude": "-2.58333333",
        "emoji": "🇬🇬",
        "emojiU": "U+1F1EC U+1F1EC",
        "states": []
    },
    {
        "name": "Guinea",
        "iso3": "GIN",
        "iso2": "GN",
        "phone_code": "224",
        "capital": "Conakry",
        "currency": "GNF",
        "currency_symbol": "FG",
        "tld": ".gn",
        "native": "Guinée",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa/Conakry",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "translations": {
            "br": "Guiné",
            "pt": "Guiné",
            "nl": "Guinee",
            "hr": "Gvineja",
            "fa": "گینه",
            "de": "Guinea",
            "es": "Guinea",
            "fr": "Guinée",
            "ja": "ギニア",
            "it": "Guinea"
        },
        "latitude": "11.00000000",
        "longitude": "-10.00000000",
        "emoji": "🇬🇳",
        "emojiU": "U+1F1EC U+1F1F3",
        "states": [
            {
                "id": 2672,
                "name": "Beyla Prefecture",
                "state_code": "BE"
            },
            {
                "id": 2699,
                "name": "Boffa Prefecture",
                "state_code": "BF"
            },
            {
                "id": 2709,
                "name": "Boké Prefecture",
                "state_code": "BK"
            },
            {
                "id": 2676,
                "name": "Boké Region",
                "state_code": "B"
            },
            {
                "id": 2686,
                "name": "Conakry",
                "state_code": "C"
            },
            {
                "id": 2705,
                "name": "Coyah Prefecture",
                "state_code": "CO"
            },
            {
                "id": 2679,
                "name": "Dabola Prefecture",
                "state_code": "DB"
            },
            {
                "id": 2706,
                "name": "Dalaba Prefecture",
                "state_code": "DL"
            },
            {
                "id": 2688,
                "name": "Dinguiraye Prefecture",
                "state_code": "DI"
            },
            {
                "id": 2681,
                "name": "Dubréka Prefecture",
                "state_code": "DU"
            },
            {
                "id": 2682,
                "name": "Faranah Prefecture",
                "state_code": "FA"
            },
            {
                "id": 2683,
                "name": "Forécariah Prefecture",
                "state_code": "FO"
            },
            {
                "id": 2675,
                "name": "Fria Prefecture",
                "state_code": "FR"
            },
            {
                "id": 2685,
                "name": "Gaoual Prefecture",
                "state_code": "GA"
            },
            {
                "id": 2711,
                "name": "Guéckédou Prefecture",
                "state_code": "GU"
            },
            {
                "id": 2704,
                "name": "Kankan Prefecture",
                "state_code": "KA"
            },
            {
                "id": 2697,
                "name": "Kankan Region",
                "state_code": "K"
            },
            {
                "id": 2710,
                "name": "Kérouané Prefecture",
                "state_code": "KE"
            },
            {
                "id": 2693,
                "name": "Kindia Prefecture",
                "state_code": "KD"
            },
            {
                "id": 2701,
                "name": "Kindia Region",
                "state_code": "D"
            },
            {
                "id": 2691,
                "name": "Kissidougou Prefecture",
                "state_code": "KS"
            },
            {
                "id": 2692,
                "name": "Koubia Prefecture",
                "state_code": "KB"
            },
            {
                "id": 2703,
                "name": "Koundara Prefecture",
                "state_code": "KN"
            },
            {
                "id": 2695,
                "name": "Kouroussa Prefecture",
                "state_code": "KO"
            },
            {
                "id": 2680,
                "name": "Labé Prefecture",
                "state_code": "LA"
            },
            {
                "id": 2677,
                "name": "Labé Region",
                "state_code": "L"
            },
            {
                "id": 2690,
                "name": "Lélouma Prefecture",
                "state_code": "LE"
            },
            {
                "id": 2708,
                "name": "Lola Prefecture",
                "state_code": "LO"
            },
            {
                "id": 2702,
                "name": "Macenta Prefecture",
                "state_code": "MC"
            },
            {
                "id": 2700,
                "name": "Mali Prefecture",
                "state_code": "ML"
            },
            {
                "id": 2689,
                "name": "Mamou Prefecture",
                "state_code": "MM"
            },
            {
                "id": 2698,
                "name": "Mamou Region",
                "state_code": "M"
            },
            {
                "id": 2673,
                "name": "Mandiana Prefecture",
                "state_code": "MD"
            },
            {
                "id": 2678,
                "name": "Nzérékoré Prefecture",
                "state_code": "NZ"
            },
            {
                "id": 2684,
                "name": "Nzérékoré Region",
                "state_code": "N"
            },
            {
                "id": 2694,
                "name": "Pita Prefecture",
                "state_code": "PI"
            },
            {
                "id": 2707,
                "name": "Siguiri Prefecture",
                "state_code": "SI"
            },
            {
                "id": 2687,
                "name": "Télimélé Prefecture",
                "state_code": "TE"
            },
            {
                "id": 2696,
                "name": "Tougué Prefecture",
                "state_code": "TO"
            },
            {
                "id": 2674,
                "name": "Yomou Prefecture",
                "state_code": "YO"
            }
        ]
    },
    {
        "name": "Guinea-Bissau",
        "iso3": "GNB",
        "iso2": "GW",
        "phone_code": "245",
        "capital": "Bissau",
        "currency": "XOF",
        "currency_symbol": "CFA",
        "tld": ".gw",
        "native": "Guiné-Bissau",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa/Bissau",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "translations": {
            "br": "Guiné-Bissau",
            "pt": "Guiné-Bissau",
            "nl": "Guinee-Bissau",
            "hr": "Gvineja Bisau",
            "fa": "گینه بیسائو",
            "de": "Guinea-Bissau",
            "es": "Guinea-Bisáu",
            "fr": "Guinée-Bissau",
            "ja": "ギニアビサウ",
            "it": "Guinea-Bissau"
        },
        "latitude": "12.00000000",
        "longitude": "-15.00000000",
        "emoji": "🇬🇼",
        "emojiU": "U+1F1EC U+1F1FC",
        "states": [
            {
                "id": 2720,
                "name": "Bafatá",
                "state_code": "BA"
            },
            {
                "id": 2714,
                "name": "Biombo Region",
                "state_code": "BM"
            },
            {
                "id": 2722,
                "name": "Bolama Region",
                "state_code": "BL"
            },
            {
                "id": 2713,
                "name": "Cacheu Region",
                "state_code": "CA"
            },
            {
                "id": 2719,
                "name": "Gabú Region",
                "state_code": "GA"
            },
            {
                "id": 2721,
                "name": "Leste Province",
                "state_code": "L"
            },
            {
                "id": 2717,
                "name": "Norte Province",
                "state_code": "N"
            },
            {
                "id": 2718,
                "name": "Oio Region",
                "state_code": "OI"
            },
            {
                "id": 2715,
                "name": "Quinara Region",
                "state_code": "QU"
            },
            {
                "id": 2716,
                "name": "Sul Province",
                "state_code": "S"
            },
            {
                "id": 2712,
                "name": "Tombali Region",
                "state_code": "TO"
            }
        ]
    },
    {
        "name": "Guyana",
        "iso3": "GUY",
        "iso2": "GY",
        "phone_code": "592",
        "capital": "Georgetown",
        "currency": "GYD",
        "currency_symbol": "$",
        "tld": ".gy",
        "native": "Guyana",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America/Guyana",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "GYT",
                "tzName": "Guyana Time"
            }
        ],
        "translations": {
            "br": "Guiana",
            "pt": "Guiana",
            "nl": "Guyana",
            "hr": "Gvajana",
            "fa": "گویان",
            "de": "Guyana",
            "es": "Guyana",
            "fr": "Guyane",
            "ja": "ガイアナ",
            "it": "Guyana"
        },
        "latitude": "5.00000000",
        "longitude": "-59.00000000",
        "emoji": "🇬🇾",
        "emojiU": "U+1F1EC U+1F1FE",
        "states": [
            {
                "id": 2764,
                "name": "Barima-Waini",
                "state_code": "BA"
            },
            {
                "id": 2760,
                "name": "Cuyuni-Mazaruni",
                "state_code": "CU"
            },
            {
                "id": 2767,
                "name": "Demerara-Mahaica",
                "state_code": "DE"
            },
            {
                "id": 2766,
                "name": "East Berbice-Corentyne",
                "state_code": "EB"
            },
            {
                "id": 2768,
                "name": "Essequibo Islands-West Demerara",
                "state_code": "ES"
            },
            {
                "id": 2762,
                "name": "Mahaica-Berbice",
                "state_code": "MA"
            },
            {
                "id": 2765,
                "name": "Pomeroon-Supenaam",
                "state_code": "PM"
            },
            {
                "id": 2761,
                "name": "Potaro-Siparuni",
                "state_code": "PT"
            },
            {
                "id": 2763,
                "name": "Upper Demerara-Berbice",
                "state_code": "UD"
            },
            {
                "id": 2769,
                "name": "Upper Takutu-Upper Essequibo",
                "state_code": "UT"
            }
        ]
    },
    {
        "name": "Haiti",
        "iso3": "HTI",
        "iso2": "HT",
        "phone_code": "509",
        "capital": "Port-au-Prince",
        "currency": "HTG",
        "currency_symbol": "G",
        "tld": ".ht",
        "native": "Haïti",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/Port-au-Prince",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            }
        ],
        "translations": {
            "br": "Haiti",
            "pt": "Haiti",
            "nl": "Haïti",
            "hr": "Haiti",
            "fa": "هائیتی",
            "de": "Haiti",
            "es": "Haiti",
            "fr": "Haïti",
            "ja": "ハイチ",
            "it": "Haiti"
        },
        "latitude": "19.00000000",
        "longitude": "-72.41666666",
        "emoji": "🇭🇹",
        "emojiU": "U+1F1ED U+1F1F9",
        "states": [
            {
                "id": 4123,
                "name": "Artibonite",
                "state_code": "AR"
            },
            {
                "id": 4125,
                "name": "Centre",
                "state_code": "CE"
            },
            {
                "id": 4119,
                "name": "Grand'Anse",
                "state_code": "GA"
            },
            {
                "id": 4118,
                "name": "Nippes",
                "state_code": "NI"
            },
            {
                "id": 4117,
                "name": "Nord",
                "state_code": "ND"
            },
            {
                "id": 4121,
                "name": "Nord-Est",
                "state_code": "NE"
            },
            {
                "id": 4126,
                "name": "Nord-Ouest",
                "state_code": "NO"
            },
            {
                "id": 4120,
                "name": "Ouest",
                "state_code": "OU"
            },
            {
                "id": 4122,
                "name": "Sud",
                "state_code": "SD"
            },
            {
                "id": 4124,
                "name": "Sud-Est",
                "state_code": "SE"
            }
        ]
    },
    {
        "name": "Heard Island and McDonald Islands",
        "iso3": "HMD",
        "iso2": "HM",
        "phone_code": "",
        "capital": "",
        "currency": "AUD",
        "currency_symbol": "$",
        "tld": ".hm",
        "native": "Heard Island and McDonald Islands",
        "region": "",
        "subregion": "",
        "timezones": [
            {
                "zoneName": "Indian/Kerguelen",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "TFT",
                "tzName": "French Southern and Antarctic Time"
            }
        ],
        "translations": {
            "br": "Ilha Heard e Ilhas McDonald",
            "pt": "Ilha Heard e Ilhas McDonald",
            "nl": "Heard- en McDonaldeilanden",
            "hr": "Otok Heard i otočje McDonald",
            "fa": "جزیره هرد و جزایر مک‌دونالد",
            "de": "Heard und die McDonaldinseln",
            "es": "Islas Heard y McDonald",
            "fr": "Îles Heard-et-MacDonald",
            "ja": "ハード島とマクドナルド諸島",
            "it": "Isole Heard e McDonald"
        },
        "latitude": "-53.10000000",
        "longitude": "72.51666666",
        "emoji": "🇭🇲",
        "emojiU": "U+1F1ED U+1F1F2",
        "states": []
    },
    {
        "name": "Honduras",
        "iso3": "HND",
        "iso2": "HN",
        "phone_code": "504",
        "capital": "Tegucigalpa",
        "currency": "HNL",
        "currency_symbol": "L",
        "tld": ".hn",
        "native": "Honduras",
        "region": "Americas",
        "subregion": "Central America",
        "timezones": [
            {
                "zoneName": "America/Tegucigalpa",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            }
        ],
        "translations": {
            "br": "Honduras",
            "pt": "Honduras",
            "nl": "Honduras",
            "hr": "Honduras",
            "fa": "هندوراس",
            "de": "Honduras",
            "es": "Honduras",
            "fr": "Honduras",
            "ja": "ホンジュラス",
            "it": "Honduras"
        },
        "latitude": "15.00000000",
        "longitude": "-86.50000000",
        "emoji": "🇭🇳",
        "emojiU": "U+1F1ED U+1F1F3",
        "states": [
            {
                "id": 4047,
                "name": "Atlántida Department",
                "state_code": "AT"
            },
            {
                "id": 4045,
                "name": "Bay Islands Department",
                "state_code": "IB"
            },
            {
                "id": 4041,
                "name": "Choluteca Department",
                "state_code": "CH"
            },
            {
                "id": 4051,
                "name": "Colón Department",
                "state_code": "CL"
            },
            {
                "id": 4042,
                "name": "Comayagua Department",
                "state_code": "CM"
            },
            {
                "id": 4049,
                "name": "Copán Department",
                "state_code": "CP"
            },
            {
                "id": 4046,
                "name": "Cortés Department",
                "state_code": "CR"
            },
            {
                "id": 4043,
                "name": "El Paraíso Department",
                "state_code": "EP"
            },
            {
                "id": 4052,
                "name": "Francisco Morazán Department",
                "state_code": "FM"
            },
            {
                "id": 4048,
                "name": "Gracias a Dios Department",
                "state_code": "GD"
            },
            {
                "id": 4044,
                "name": "Intibucá Department",
                "state_code": "IN"
            },
            {
                "id": 4058,
                "name": "La Paz Department",
                "state_code": "LP"
            },
            {
                "id": 4054,
                "name": "Lempira Department",
                "state_code": "LE"
            },
            {
                "id": 4056,
                "name": "Ocotepeque Department",
                "state_code": "OC"
            },
            {
                "id": 4050,
                "name": "Olancho Department",
                "state_code": "OL"
            },
            {
                "id": 4053,
                "name": "Santa Bárbara Department",
                "state_code": "SB"
            },
            {
                "id": 4055,
                "name": "Valle Department",
                "state_code": "VA"
            },
            {
                "id": 4057,
                "name": "Yoro Department",
                "state_code": "YO"
            }
        ]
    },
    {
        "name": "Hong Kong S.A.R.",
        "iso3": "HKG",
        "iso2": "HK",
        "phone_code": "852",
        "capital": "Hong Kong",
        "currency": "HKD",
        "currency_symbol": "$",
        "tld": ".hk",
        "native": "香港",
        "region": "Asia",
        "subregion": "Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Hong_Kong",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "HKT",
                "tzName": "Hong Kong Time"
            }
        ],
        "translations": {
            "br": "Hong Kong",
            "pt": "Hong Kong",
            "nl": "Hongkong",
            "hr": "Hong Kong",
            "fa": "هنگ‌کنگ",
            "de": "Hong Kong",
            "es": "Hong Kong",
            "fr": "Hong Kong",
            "ja": "香港",
            "it": "Hong Kong"
        },
        "latitude": "22.25000000",
        "longitude": "114.16666666",
        "emoji": "🇭🇰",
        "emojiU": "U+1F1ED U+1F1F0",
        "states": [
            {
                "id": 4889,
                "name": "Central and Western District",
                "state_code": "HCW"
            },
            {
                "id": 4891,
                "name": "Eastern",
                "state_code": "HEA"
            },
            {
                "id": 4888,
                "name": "Islands District",
                "state_code": "NIS"
            },
            {
                "id": 4895,
                "name": "Kowloon City",
                "state_code": "KKC"
            },
            {
                "id": 4898,
                "name": "Kwai Tsing",
                "state_code": "NKT"
            },
            {
                "id": 4897,
                "name": "Kwun Tong",
                "state_code": "KKT"
            },
            {
                "id": 4900,
                "name": "North",
                "state_code": "NNO"
            },
            {
                "id": 4887,
                "name": "Sai Kung District",
                "state_code": "NSK"
            },
            {
                "id": 4901,
                "name": "Sha Tin",
                "state_code": "NST"
            },
            {
                "id": 4894,
                "name": "Sham Shui Po",
                "state_code": "KSS"
            },
            {
                "id": 4892,
                "name": "Southern",
                "state_code": "HSO"
            },
            {
                "id": 4885,
                "name": "Tai Po District",
                "state_code": "NTP"
            },
            {
                "id": 4884,
                "name": "Tsuen Wan District",
                "state_code": "NTW"
            },
            {
                "id": 4899,
                "name": "Tuen Mun",
                "state_code": "NTM"
            },
            {
                "id": 4890,
                "name": "Wan Chai",
                "state_code": "HWC"
            },
            {
                "id": 4896,
                "name": "Wong Tai Sin",
                "state_code": "KWT"
            },
            {
                "id": 4893,
                "name": "Yau Tsim Mong",
                "state_code": "KYT"
            },
            {
                "id": 4883,
                "name": "Yuen Long District",
                "state_code": "NYL"
            }
        ]
    },
    {
        "name": "Hungary",
        "iso3": "HUN",
        "iso2": "HU",
        "phone_code": "36",
        "capital": "Budapest",
        "currency": "HUF",
        "currency_symbol": "Ft",
        "tld": ".hu",
        "native": "Magyarország",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Budapest",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Hungria",
            "pt": "Hungria",
            "nl": "Hongarije",
            "hr": "Mađarska",
            "fa": "مجارستان",
            "de": "Ungarn",
            "es": "Hungría",
            "fr": "Hongrie",
            "ja": "ハンガリー",
            "it": "Ungheria"
        },
        "latitude": "47.00000000",
        "longitude": "20.00000000",
        "emoji": "🇭🇺",
        "emojiU": "U+1F1ED U+1F1FA",
        "states": [
            {
                "id": 1048,
                "name": "Bács-Kiskun County",
                "state_code": "BK"
            },
            {
                "id": 1055,
                "name": "Baranya County",
                "state_code": "BA"
            },
            {
                "id": 1060,
                "name": "Békés County",
                "state_code": "BE"
            },
            {
                "id": 1036,
                "name": "Békéscsaba",
                "state_code": "BC"
            },
            {
                "id": 1058,
                "name": "Borsod-Abaúj-Zemplén County",
                "state_code": "BZ"
            },
            {
                "id": 1064,
                "name": "Budapest",
                "state_code": "BU"
            },
            {
                "id": 1031,
                "name": "Csongrád County",
                "state_code": "CS"
            },
            {
                "id": 1032,
                "name": "Debrecen",
                "state_code": "DE"
            },
            {
                "id": 1049,
                "name": "Dunaújváros",
                "state_code": "DU"
            },
            {
                "id": 1037,
                "name": "Eger",
                "state_code": "EG"
            },
            {
                "id": 1028,
                "name": "Érd",
                "state_code": "ER"
            },
            {
                "id": 1044,
                "name": "Fejér County",
                "state_code": "FE"
            },
            {
                "id": 1041,
                "name": "Győr",
                "state_code": "GY"
            },
            {
                "id": 1042,
                "name": "Győr-Moson-Sopron County",
                "state_code": "GS"
            },
            {
                "id": 1063,
                "name": "Hajdú-Bihar County",
                "state_code": "HB"
            },
            {
                "id": 1040,
                "name": "Heves County",
                "state_code": "HE"
            },
            {
                "id": 1027,
                "name": "Hódmezővásárhely",
                "state_code": "HV"
            },
            {
                "id": 1043,
                "name": "Jász-Nagykun-Szolnok County",
                "state_code": "JN"
            },
            {
                "id": 1067,
                "name": "Kaposvár",
                "state_code": "KV"
            },
            {
                "id": 1056,
                "name": "Kecskemét",
                "state_code": "KM"
            },
            {
                "id": 1065,
                "name": "Miskolc",
                "state_code": "MI"
            },
            {
                "id": 1030,
                "name": "Nagykanizsa",
                "state_code": "NK"
            },
            {
                "id": 1051,
                "name": "Nógrád County",
                "state_code": "NO"
            },
            {
                "id": 1034,
                "name": "Nyíregyháza",
                "state_code": "NY"
            },
            {
                "id": 1053,
                "name": "Pécs",
                "state_code": "PS"
            },
            {
                "id": 1059,
                "name": "Pest County",
                "state_code": "PE"
            },
            {
                "id": 1068,
                "name": "Salgótarján",
                "state_code": "ST"
            },
            {
                "id": 1035,
                "name": "Somogy County",
                "state_code": "SO"
            },
            {
                "id": 1057,
                "name": "Sopron",
                "state_code": "SN"
            },
            {
                "id": 1045,
                "name": "Szabolcs-Szatmár-Bereg County",
                "state_code": "SZ"
            },
            {
                "id": 1029,
                "name": "Szeged",
                "state_code": "SD"
            },
            {
                "id": 1033,
                "name": "Székesfehérvár",
                "state_code": "SF"
            },
            {
                "id": 1061,
                "name": "Szekszárd",
                "state_code": "SS"
            },
            {
                "id": 1047,
                "name": "Szolnok",
                "state_code": "SK"
            },
            {
                "id": 1052,
                "name": "Szombathely",
                "state_code": "SH"
            },
            {
                "id": 1066,
                "name": "Tatabánya",
                "state_code": "TB"
            },
            {
                "id": 1038,
                "name": "Tolna County",
                "state_code": "TO"
            },
            {
                "id": 1039,
                "name": "Vas County",
                "state_code": "VA"
            },
            {
                "id": 1062,
                "name": "Veszprém",
                "state_code": "VM"
            },
            {
                "id": 1054,
                "name": "Veszprém County",
                "state_code": "VE"
            },
            {
                "id": 1046,
                "name": "Zala County",
                "state_code": "ZA"
            },
            {
                "id": 1050,
                "name": "Zalaegerszeg",
                "state_code": "ZE"
            }
        ]
    },
    {
        "name": "Iceland",
        "iso3": "ISL",
        "iso2": "IS",
        "phone_code": "354",
        "capital": "Reykjavik",
        "currency": "ISK",
        "currency_symbol": "kr",
        "tld": ".is",
        "native": "Ísland",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Atlantic/Reykjavik",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "translations": {
            "br": "Islândia",
            "pt": "Islândia",
            "nl": "IJsland",
            "hr": "Island",
            "fa": "ایسلند",
            "de": "Island",
            "es": "Islandia",
            "fr": "Islande",
            "ja": "アイスランド",
            "it": "Islanda"
        },
        "latitude": "65.00000000",
        "longitude": "-18.00000000",
        "emoji": "🇮🇸",
        "emojiU": "U+1F1EE U+1F1F8",
        "states": [
            {
                "id": 3431,
                "name": "Capital Region",
                "state_code": "1"
            },
            {
                "id": 3433,
                "name": "Eastern Region",
                "state_code": "7"
            },
            {
                "id": 3437,
                "name": "Northeastern Region",
                "state_code": "6"
            },
            {
                "id": 3435,
                "name": "Northwestern Region",
                "state_code": "5"
            },
            {
                "id": 3430,
                "name": "Southern Peninsula Region",
                "state_code": "2"
            },
            {
                "id": 3434,
                "name": "Southern Region",
                "state_code": "8"
            },
            {
                "id": 3436,
                "name": "Western Region",
                "state_code": "3"
            },
            {
                "id": 3432,
                "name": "Westfjords",
                "state_code": "4"
            }
        ]
    },
    {
        "name": "India",
        "iso3": "IND",
        "iso2": "IN",
        "phone_code": "91",
        "capital": "New Delhi",
        "currency": "INR",
        "currency_symbol": "₹",
        "tld": ".in",
        "native": "भारत",
        "region": "Asia",
        "subregion": "Southern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Kolkata",
                "gmtOffset": 19800,
                "gmtOffsetName": "UTC+05:30",
                "abbreviation": "IST",
                "tzName": "Indian Standard Time"
            }
        ],
        "translations": {
            "br": "Índia",
            "pt": "Índia",
            "nl": "India",
            "hr": "Indija",
            "fa": "هند",
            "de": "Indien",
            "es": "India",
            "fr": "Inde",
            "ja": "インド",
            "it": "India"
        },
        "latitude": "20.00000000",
        "longitude": "77.00000000",
        "emoji": "🇮🇳",
        "emojiU": "U+1F1EE U+1F1F3",
        "states": [
            {
                "id": 4023,
                "name": "Andaman and Nicobar Islands",
                "state_code": "AN"
            },
            {
                "id": 4017,
                "name": "Andhra Pradesh",
                "state_code": "AP"
            },
            {
                "id": 4024,
                "name": "Arunachal Pradesh",
                "state_code": "AR"
            },
            {
                "id": 4027,
                "name": "Assam",
                "state_code": "AS"
            },
            {
                "id": 4037,
                "name": "Bihar",
                "state_code": "BR"
            },
            {
                "id": 4031,
                "name": "Chandigarh",
                "state_code": "CH"
            },
            {
                "id": 4040,
                "name": "Chhattisgarh",
                "state_code": "CT"
            },
            {
                "id": 4033,
                "name": "Dadra and Nagar Haveli and Daman and Diu",
                "state_code": "DH"
            },
            {
                "id": 4021,
                "name": "Delhi",
                "state_code": "DL"
            },
            {
                "id": 4009,
                "name": "Goa",
                "state_code": "GA"
            },
            {
                "id": 4030,
                "name": "Gujarat",
                "state_code": "GJ"
            },
            {
                "id": 4007,
                "name": "Haryana",
                "state_code": "HR"
            },
            {
                "id": 4020,
                "name": "Himachal Pradesh",
                "state_code": "HP"
            },
            {
                "id": 4029,
                "name": "Jammu and Kashmir",
                "state_code": "JK"
            },
            {
                "id": 4025,
                "name": "Jharkhand",
                "state_code": "JH"
            },
            {
                "id": 4026,
                "name": "Karnataka",
                "state_code": "KA"
            },
            {
                "id": 4028,
                "name": "Kerala",
                "state_code": "KL"
            },
            {
                "id": 4852,
                "name": "Ladakh",
                "state_code": "LA"
            },
            {
                "id": 4019,
                "name": "Lakshadweep",
                "state_code": "LD"
            },
            {
                "id": 4039,
                "name": "Madhya Pradesh",
                "state_code": "MP"
            },
            {
                "id": 4008,
                "name": "Maharashtra",
                "state_code": "MH"
            },
            {
                "id": 4010,
                "name": "Manipur",
                "state_code": "MN"
            },
            {
                "id": 4006,
                "name": "Meghalaya",
                "state_code": "ML"
            },
            {
                "id": 4036,
                "name": "Mizoram",
                "state_code": "MZ"
            },
            {
                "id": 4018,
                "name": "Nagaland",
                "state_code": "NL"
            },
            {
                "id": 4013,
                "name": "Odisha",
                "state_code": "OR"
            },
            {
                "id": 4011,
                "name": "Puducherry",
                "state_code": "PY"
            },
            {
                "id": 4015,
                "name": "Punjab",
                "state_code": "PB"
            },
            {
                "id": 4014,
                "name": "Rajasthan",
                "state_code": "RJ"
            },
            {
                "id": 4034,
                "name": "Sikkim",
                "state_code": "SK"
            },
            {
                "id": 4035,
                "name": "Tamil Nadu",
                "state_code": "TN"
            },
            {
                "id": 4012,
                "name": "Telangana",
                "state_code": "TG"
            },
            {
                "id": 4038,
                "name": "Tripura",
                "state_code": "TR"
            },
            {
                "id": 4022,
                "name": "Uttar Pradesh",
                "state_code": "UP"
            },
            {
                "id": 4016,
                "name": "Uttarakhand",
                "state_code": "UT"
            },
            {
                "id": 4853,
                "name": "West Bengal",
                "state_code": "WB"
            }
        ]
    },
    {
        "name": "Indonesia",
        "iso3": "IDN",
        "iso2": "ID",
        "phone_code": "62",
        "capital": "Jakarta",
        "currency": "IDR",
        "currency_symbol": "Rp",
        "tld": ".id",
        "native": "Indonesia",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Jakarta",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "WIB",
                "tzName": "Western Indonesian Time"
            },
            {
                "zoneName": "Asia/Jayapura",
                "gmtOffset": 32400,
                "gmtOffsetName": "UTC+09:00",
                "abbreviation": "WIT",
                "tzName": "Eastern Indonesian Time"
            },
            {
                "zoneName": "Asia/Makassar",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "WITA",
                "tzName": "Central Indonesia Time"
            },
            {
                "zoneName": "Asia/Pontianak",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "WIB",
                "tzName": "Western Indonesian Time"
            }
        ],
        "translations": {
            "br": "Indonésia",
            "pt": "Indonésia",
            "nl": "Indonesië",
            "hr": "Indonezija",
            "fa": "اندونزی",
            "de": "Indonesien",
            "es": "Indonesia",
            "fr": "Indonésie",
            "ja": "インドネシア",
            "it": "Indonesia"
        },
        "latitude": "-5.00000000",
        "longitude": "120.00000000",
        "emoji": "🇮🇩",
        "emojiU": "U+1F1EE U+1F1E9",
        "states": [
            {
                "id": 1822,
                "name": "Aceh",
                "state_code": "AC"
            },
            {
                "id": 1826,
                "name": "Bali",
                "state_code": "BA"
            },
            {
                "id": 1820,
                "name": "Bangka Belitung Islands",
                "state_code": "BB"
            },
            {
                "id": 1810,
                "name": "Banten",
                "state_code": "BT"
            },
            {
                "id": 1793,
                "name": "Bengkulu",
                "state_code": "BE"
            },
            {
                "id": 1802,
                "name": "Central Java",
                "state_code": "JT"
            },
            {
                "id": 1794,
                "name": "Central Kalimantan",
                "state_code": "KT"
            },
            {
                "id": 1813,
                "name": "Central Sulawesi",
                "state_code": "ST"
            },
            {
                "id": 1827,
                "name": "East Java",
                "state_code": "JI"
            },
            {
                "id": 1804,
                "name": "East Kalimantan",
                "state_code": "KI"
            },
            {
                "id": 1818,
                "name": "East Nusa Tenggara",
                "state_code": "NT"
            },
            {
                "id": 1812,
                "name": "Gorontalo",
                "state_code": "GO"
            },
            {
                "id": 1805,
                "name": "Jakarta",
                "state_code": "JK"
            },
            {
                "id": 1815,
                "name": "Jambi",
                "state_code": "JA"
            },
            {
                "id": 1806,
                "name": "Kalimantan",
                "state_code": "KA"
            },
            {
                "id": 1811,
                "name": "Lampung",
                "state_code": "LA"
            },
            {
                "id": 1821,
                "name": "Lesser Sunda Islands",
                "state_code": "NU"
            },
            {
                "id": 1800,
                "name": "Maluku",
                "state_code": "MA"
            },
            {
                "id": 1823,
                "name": "Maluku Islands",
                "state_code": "ML"
            },
            {
                "id": 1824,
                "name": "North Kalimantan",
                "state_code": "KU"
            },
            {
                "id": 1801,
                "name": "North Maluku",
                "state_code": "MU"
            },
            {
                "id": 1808,
                "name": "North Sulawesi",
                "state_code": "SA"
            },
            {
                "id": 1792,
                "name": "North Sumatra",
                "state_code": "SU"
            },
            {
                "id": 1798,
                "name": "Papua",
                "state_code": "PA"
            },
            {
                "id": 1809,
                "name": "Riau",
                "state_code": "RI"
            },
            {
                "id": 1807,
                "name": "Riau Islands",
                "state_code": "KR"
            },
            {
                "id": 1819,
                "name": "South Kalimantan",
                "state_code": "KS"
            },
            {
                "id": 1795,
                "name": "South Sulawesi",
                "state_code": "SN"
            },
            {
                "id": 1816,
                "name": "South Sumatra",
                "state_code": "SS"
            },
            {
                "id": 1796,
                "name": "Southeast Sulawesi",
                "state_code": "SG"
            },
            {
                "id": 1829,
                "name": "Special Region of Yogyakarta",
                "state_code": "YO"
            },
            {
                "id": 1803,
                "name": "Sulawesi",
                "state_code": "SL"
            },
            {
                "id": 1797,
                "name": "Sumatra",
                "state_code": "SM"
            },
            {
                "id": 1825,
                "name": "West Java",
                "state_code": "JB"
            },
            {
                "id": 1814,
                "name": "West Nusa Tenggara",
                "state_code": "NB"
            },
            {
                "id": 1799,
                "name": "West Papua",
                "state_code": "PB"
            },
            {
                "id": 1817,
                "name": "West Sulawesi",
                "state_code": "SR"
            },
            {
                "id": 1828,
                "name": "West Sumatra",
                "state_code": "SB"
            }
        ]
    },
    {
        "name": "Iran",
        "iso3": "IRN",
        "iso2": "IR",
        "phone_code": "98",
        "capital": "Tehran",
        "currency": "IRR",
        "currency_symbol": "﷼",
        "tld": ".ir",
        "native": "ایران",
        "region": "Asia",
        "subregion": "Southern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Tehran",
                "gmtOffset": 12600,
                "gmtOffsetName": "UTC+03:30",
                "abbreviation": "IRDT",
                "tzName": "Iran Daylight Time"
            }
        ],
        "translations": {
            "br": "Irã",
            "pt": "Irão",
            "nl": "Iran",
            "hr": "Iran",
            "fa": "ایران",
            "de": "Iran",
            "es": "Iran",
            "fr": "Iran",
            "ja": "イラン・イスラム共和国"
        },
        "latitude": "32.00000000",
        "longitude": "53.00000000",
        "emoji": "🇮🇷",
        "emojiU": "U+1F1EE U+1F1F7",
        "states": [
            {
                "id": 3929,
                "name": "Alborz Province",
                "state_code": "30"
            },
            {
                "id": 3934,
                "name": "Ardabil Province",
                "state_code": "24"
            },
            {
                "id": 3932,
                "name": "Bushehr Province",
                "state_code": "18"
            },
            {
                "id": 3921,
                "name": "Chaharmahal and Bakhtiari Province",
                "state_code": "14"
            },
            {
                "id": 3944,
                "name": "East Azerbaijan Province",
                "state_code": "03"
            },
            {
                "id": 3939,
                "name": "Fars Province",
                "state_code": "07"
            },
            {
                "id": 3920,
                "name": "Gilan Province",
                "state_code": "01"
            },
            {
                "id": 3933,
                "name": "Golestan Province",
                "state_code": "27"
            },
            {
                "id": 4920,
                "name": "Hamadan Province",
                "state_code": "13"
            },
            {
                "id": 3937,
                "name": "Hormozgan Province",
                "state_code": "22"
            },
            {
                "id": 3918,
                "name": "Ilam Province",
                "state_code": "16"
            },
            {
                "id": 3923,
                "name": "Isfahan Province",
                "state_code": "10"
            },
            {
                "id": 3943,
                "name": "Kerman Province",
                "state_code": "08"
            },
            {
                "id": 3919,
                "name": "Kermanshah Province",
                "state_code": "05"
            },
            {
                "id": 3917,
                "name": "Khuzestan Province",
                "state_code": "06"
            },
            {
                "id": 3926,
                "name": "Kohgiluyeh and Boyer-Ahmad Province",
                "state_code": "17"
            },
            {
                "id": 3935,
                "name": "Kurdistan Province",
                "state_code": "12"
            },
            {
                "id": 3928,
                "name": "Lorestan Province",
                "state_code": "15"
            },
            {
                "id": 3916,
                "name": "Markazi Province",
                "state_code": "00"
            },
            {
                "id": 3938,
                "name": "Mazandaran Province",
                "state_code": "02"
            },
            {
                "id": 3942,
                "name": "North Khorasan Province",
                "state_code": "28"
            },
            {
                "id": 3941,
                "name": "Qazvin Province",
                "state_code": "26"
            },
            {
                "id": 3922,
                "name": "Qom Province",
                "state_code": "25"
            },
            {
                "id": 3927,
                "name": "Razavi Khorasan Province",
                "state_code": "09"
            },
            {
                "id": 3940,
                "name": "Semnan Province",
                "state_code": "20"
            },
            {
                "id": 3931,
                "name": "Sistan and Baluchestan",
                "state_code": "11"
            },
            {
                "id": 3930,
                "name": "South Khorasan Province",
                "state_code": "29"
            },
            {
                "id": 3945,
                "name": "Tehran Province",
                "state_code": "23"
            },
            {
                "id": 3924,
                "name": "West Azarbaijan Province",
                "state_code": "04"
            },
            {
                "id": 3936,
                "name": "Yazd Province",
                "state_code": "21"
            },
            {
                "id": 3925,
                "name": "Zanjan Province",
                "state_code": "19"
            }
        ]
    },
    {
        "name": "Iraq",
        "iso3": "IRQ",
        "iso2": "IQ",
        "phone_code": "964",
        "capital": "Baghdad",
        "currency": "IQD",
        "currency_symbol": "د.ع",
        "tld": ".iq",
        "native": "العراق",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia/Baghdad",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "AST",
                "tzName": "Arabia Standard Time"
            }
        ],
        "translations": {
            "br": "Iraque",
            "pt": "Iraque",
            "nl": "Irak",
            "hr": "Irak",
            "fa": "عراق",
            "de": "Irak",
            "es": "Irak",
            "fr": "Irak",
            "ja": "イラク",
            "it": "Iraq"
        },
        "latitude": "33.00000000",
        "longitude": "44.00000000",
        "emoji": "🇮🇶",
        "emojiU": "U+1F1EE U+1F1F6",
        "states": [
            {
                "id": 3964,
                "name": "Al Anbar Governorate",
                "state_code": "AN"
            },
            {
                "id": 3958,
                "name": "Al Muthanna Governorate",
                "state_code": "MU"
            },
            {
                "id": 3956,
                "name": "Al-Qādisiyyah Governorate",
                "state_code": "QA"
            },
            {
                "id": 3955,
                "name": "Babylon Governorate",
                "state_code": "BB"
            },
            {
                "id": 3959,
                "name": "Baghdad Governorate",
                "state_code": "BG"
            },
            {
                "id": 3960,
                "name": "Basra Governorate",
                "state_code": "BA"
            },
            {
                "id": 3954,
                "name": "Dhi Qar Governorate",
                "state_code": "DQ"
            },
            {
                "id": 3965,
                "name": "Diyala Governorate",
                "state_code": "DI"
            },
            {
                "id": 3967,
                "name": "Dohuk Governorate",
                "state_code": "DA"
            },
            {
                "id": 3968,
                "name": "Erbil Governorate",
                "state_code": "AR"
            },
            {
                "id": 3957,
                "name": "Karbala Governorate",
                "state_code": "KA"
            },
            {
                "id": 3971,
                "name": "Kirkuk Governorate",
                "state_code": "KI"
            },
            {
                "id": 3966,
                "name": "Maysan Governorate",
                "state_code": "MA"
            },
            {
                "id": 3962,
                "name": "Najaf Governorate",
                "state_code": "NA"
            },
            {
                "id": 3963,
                "name": "Nineveh Governorate",
                "state_code": "NI"
            },
            {
                "id": 3961,
                "name": "Saladin Governorate",
                "state_code": "SD"
            },
            {
                "id": 3969,
                "name": "Sulaymaniyah Governorate",
                "state_code": "SU"
            },
            {
                "id": 3970,
                "name": "Wasit Governorate",
                "state_code": "WA"
            }
        ]
    },
    {
        "name": "Ireland",
        "iso3": "IRL",
        "iso2": "IE",
        "phone_code": "353",
        "capital": "Dublin",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".ie",
        "native": "Éire",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Dublin",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "translations": {
            "br": "Irlanda",
            "pt": "Irlanda",
            "nl": "Ierland",
            "hr": "Irska",
            "fa": "ایرلند",
            "de": "Irland",
            "es": "Irlanda",
            "fr": "Irlande",
            "ja": "アイルランド",
            "it": "Irlanda"
        },
        "latitude": "53.00000000",
        "longitude": "-8.00000000",
        "emoji": "🇮🇪",
        "emojiU": "U+1F1EE U+1F1EA",
        "states": [
            {
                "id": 1087,
                "name": "Connacht",
                "state_code": "C"
            },
            {
                "id": 1095,
                "name": "County Carlow",
                "state_code": "CW"
            },
            {
                "id": 1088,
                "name": "County Cavan",
                "state_code": "CN"
            },
            {
                "id": 1091,
                "name": "County Clare",
                "state_code": "CE"
            },
            {
                "id": 1074,
                "name": "County Cork",
                "state_code": "CO"
            },
            {
                "id": 1071,
                "name": "County Donegal",
                "state_code": "DL"
            },
            {
                "id": 1072,
                "name": "County Dublin",
                "state_code": "D"
            },
            {
                "id": 1079,
                "name": "County Galway",
                "state_code": "G"
            },
            {
                "id": 1077,
                "name": "County Kerry",
                "state_code": "KY"
            },
            {
                "id": 1082,
                "name": "County Kildare",
                "state_code": "KE"
            },
            {
                "id": 1090,
                "name": "County Kilkenny",
                "state_code": "KK"
            },
            {
                "id": 1096,
                "name": "County Laois",
                "state_code": "LS"
            },
            {
                "id": 1094,
                "name": "County Limerick",
                "state_code": "LK"
            },
            {
                "id": 1076,
                "name": "County Longford",
                "state_code": "LD"
            },
            {
                "id": 1083,
                "name": "County Louth",
                "state_code": "LH"
            },
            {
                "id": 1084,
                "name": "County Mayo",
                "state_code": "MO"
            },
            {
                "id": 1092,
                "name": "County Meath",
                "state_code": "MH"
            },
            {
                "id": 1075,
                "name": "County Monaghan",
                "state_code": "MN"
            },
            {
                "id": 1078,
                "name": "County Offaly",
                "state_code": "OY"
            },
            {
                "id": 1081,
                "name": "County Roscommon",
                "state_code": "RN"
            },
            {
                "id": 1070,
                "name": "County Sligo",
                "state_code": "SO"
            },
            {
                "id": 1069,
                "name": "County Tipperary",
                "state_code": "TA"
            },
            {
                "id": 1089,
                "name": "County Waterford",
                "state_code": "WD"
            },
            {
                "id": 1097,
                "name": "County Westmeath",
                "state_code": "WH"
            },
            {
                "id": 1093,
                "name": "County Wexford",
                "state_code": "WX"
            },
            {
                "id": 1085,
                "name": "County Wicklow",
                "state_code": "WW"
            },
            {
                "id": 1073,
                "name": "Leinster",
                "state_code": "L"
            },
            {
                "id": 1080,
                "name": "Munster",
                "state_code": "M"
            },
            {
                "id": 1086,
                "name": "Ulster",
                "state_code": "U"
            }
        ]
    },
    {
        "name": "Israel",
        "iso3": "ISR",
        "iso2": "IL",
        "phone_code": "972",
        "capital": "Jerusalem",
        "currency": "ILS",
        "currency_symbol": "₪",
        "tld": ".il",
        "native": "יִשְׂרָאֵל",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia/Jerusalem",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "IST",
                "tzName": "Israel Standard Time"
            }
        ],
        "translations": {
            "br": "Israel",
            "pt": "Israel",
            "nl": "Israël",
            "hr": "Izrael",
            "fa": "اسرائیل",
            "de": "Israel",
            "es": "Israel",
            "fr": "Israël",
            "ja": "イスラエル",
            "it": "Israele"
        },
        "latitude": "31.50000000",
        "longitude": "34.75000000",
        "emoji": "🇮🇱",
        "emojiU": "U+1F1EE U+1F1F1",
        "states": [
            {
                "id": 1367,
                "name": "Central District",
                "state_code": "M"
            },
            {
                "id": 1369,
                "name": "Haifa District",
                "state_code": "HA"
            },
            {
                "id": 1370,
                "name": "Jerusalem District",
                "state_code": "JM"
            },
            {
                "id": 1366,
                "name": "Northern District",
                "state_code": "Z"
            },
            {
                "id": 1368,
                "name": "Southern District",
                "state_code": "D"
            },
            {
                "id": 1371,
                "name": "Tel Aviv District",
                "state_code": "TA"
            }
        ]
    },
    {
        "name": "Italy",
        "iso3": "ITA",
        "iso2": "IT",
        "phone_code": "39",
        "capital": "Rome",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".it",
        "native": "Italia",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Rome",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Itália",
            "pt": "Itália",
            "nl": "Italië",
            "hr": "Italija",
            "fa": "ایتالیا",
            "de": "Italien",
            "es": "Italia",
            "fr": "Italie",
            "ja": "イタリア",
            "it": "Italia"
        },
        "latitude": "42.83333333",
        "longitude": "12.83333333",
        "emoji": "🇮🇹",
        "emojiU": "U+1F1EE U+1F1F9",
        "states": [
            {
                "id": 1679,
                "name": "Abruzzo",
                "state_code": "65"
            },
            {
                "id": 1716,
                "name": "Aosta Valley",
                "state_code": "23"
            },
            {
                "id": 1688,
                "name": "Apulia",
                "state_code": "75"
            },
            {
                "id": 1706,
                "name": "Basilicata",
                "state_code": "77"
            },
            {
                "id": 1701,
                "name": "Benevento Province",
                "state_code": "BN"
            },
            {
                "id": 1703,
                "name": "Calabria",
                "state_code": "78"
            },
            {
                "id": 1669,
                "name": "Campania",
                "state_code": "72"
            },
            {
                "id": 1773,
                "name": "Emilia-Romagna",
                "state_code": "45"
            },
            {
                "id": 1756,
                "name": "Friuli–Venezia Giulia",
                "state_code": "36"
            },
            {
                "id": 1678,
                "name": "Lazio",
                "state_code": "62"
            },
            {
                "id": 1727,
                "name": "Libero consorzio comunale di Agrigento",
                "state_code": "AG"
            },
            {
                "id": 1718,
                "name": "Libero consorzio comunale di Caltanissetta",
                "state_code": "CL"
            },
            {
                "id": 1723,
                "name": "Libero consorzio comunale di Enna",
                "state_code": "EN"
            },
            {
                "id": 1729,
                "name": "Libero consorzio comunale di Ragusa",
                "state_code": "RG"
            },
            {
                "id": 1667,
                "name": "Libero consorzio comunale di Siracusa",
                "state_code": "SR"
            },
            {
                "id": 1733,
                "name": "Libero consorzio comunale di Trapani",
                "state_code": "TP"
            },
            {
                "id": 1768,
                "name": "Liguria",
                "state_code": "42"
            },
            {
                "id": 1705,
                "name": "Lombardy",
                "state_code": "25"
            },
            {
                "id": 1670,
                "name": "Marche",
                "state_code": "57"
            },
            {
                "id": 1772,
                "name": "Metropolitan City of Bari",
                "state_code": "BA"
            },
            {
                "id": 1684,
                "name": "Metropolitan City of Bologna",
                "state_code": "BO"
            },
            {
                "id": 1682,
                "name": "Metropolitan City of Cagliari",
                "state_code": "CA"
            },
            {
                "id": 1766,
                "name": "Metropolitan City of Catania",
                "state_code": "CT"
            },
            {
                "id": 1680,
                "name": "Metropolitan City of Florence",
                "state_code": "FI"
            },
            {
                "id": 1699,
                "name": "Metropolitan City of Genoa",
                "state_code": "GE"
            },
            {
                "id": 1770,
                "name": "Metropolitan City of Messina",
                "state_code": "ME"
            },
            {
                "id": 1698,
                "name": "Metropolitan City of Milan",
                "state_code": "MI"
            },
            {
                "id": 1724,
                "name": "Metropolitan City of Naples",
                "state_code": "NA"
            },
            {
                "id": 1668,
                "name": "Metropolitan City of Palermo",
                "state_code": "PA"
            },
            {
                "id": 1671,
                "name": "Metropolitan City of Reggio Calabria",
                "state_code": "RC"
            },
            {
                "id": 1711,
                "name": "Metropolitan City of Rome",
                "state_code": "RM"
            },
            {
                "id": 1710,
                "name": "Metropolitan City of Turin",
                "state_code": "TO"
            },
            {
                "id": 1673,
                "name": "Metropolitan City of Venice",
                "state_code": "VE"
            },
            {
                "id": 1695,
                "name": "Molise",
                "state_code": "67"
            },
            {
                "id": 1693,
                "name": "Pesaro and Urbino Province",
                "state_code": "PU"
            },
            {
                "id": 1702,
                "name": "Piedmont",
                "state_code": "21"
            },
            {
                "id": 1783,
                "name": "Province of Alessandria",
                "state_code": "AL"
            },
            {
                "id": 1672,
                "name": "Province of Ancona",
                "state_code": "AN"
            },
            {
                "id": 1681,
                "name": "Province of Ascoli Piceno",
                "state_code": "AP"
            },
            {
                "id": 1780,
                "name": "Province of Asti",
                "state_code": "AT"
            },
            {
                "id": 1692,
                "name": "Province of Avellino",
                "state_code": "AV"
            },
            {
                "id": 1686,
                "name": "Province of Barletta-Andria-Trani",
                "state_code": "BT"
            },
            {
                "id": 1689,
                "name": "Province of Belluno",
                "state_code": "BL"
            },
            {
                "id": 1704,
                "name": "Province of Bergamo",
                "state_code": "BG"
            },
            {
                "id": 1778,
                "name": "Province of Biella",
                "state_code": "BI"
            },
            {
                "id": 1717,
                "name": "Province of Brescia",
                "state_code": "BS"
            },
            {
                "id": 1714,
                "name": "Province of Brindisi",
                "state_code": "BR"
            },
            {
                "id": 1721,
                "name": "Province of Campobasso",
                "state_code": "CB"
            },
            {
                "id": 1730,
                "name": "Province of Carbonia-Iglesias",
                "state_code": "CI"
            },
            {
                "id": 1731,
                "name": "Province of Caserta",
                "state_code": "CE"
            },
            {
                "id": 1728,
                "name": "Province of Catanzaro",
                "state_code": "CZ"
            },
            {
                "id": 1739,
                "name": "Province of Chieti",
                "state_code": "CH"
            },
            {
                "id": 1740,
                "name": "Province of Como",
                "state_code": "CO"
            },
            {
                "id": 1742,
                "name": "Province of Cosenza",
                "state_code": "CS"
            },
            {
                "id": 1751,
                "name": "Province of Cremona",
                "state_code": "CR"
            },
            {
                "id": 1754,
                "name": "Province of Crotone",
                "state_code": "KR"
            },
            {
                "id": 1775,
                "name": "Province of Cuneo",
                "state_code": "CN"
            },
            {
                "id": 1744,
                "name": "Province of Fermo",
                "state_code": "FM"
            },
            {
                "id": 1746,
                "name": "Province of Ferrara",
                "state_code": "FE"
            },
            {
                "id": 1771,
                "name": "Province of Foggia",
                "state_code": "FG"
            },
            {
                "id": 1779,
                "name": "Province of Forlì-Cesena",
                "state_code": "FC"
            },
            {
                "id": 1776,
                "name": "Province of Frosinone",
                "state_code": "FR"
            },
            {
                "id": 1777,
                "name": "Province of Gorizia",
                "state_code": "GO"
            },
            {
                "id": 1787,
                "name": "Province of Grosseto",
                "state_code": "GR"
            },
            {
                "id": 1788,
                "name": "Province of Imperia",
                "state_code": "IM"
            },
            {
                "id": 1789,
                "name": "Province of Isernia",
                "state_code": "IS"
            },
            {
                "id": 1781,
                "name": "Province of L'Aquila",
                "state_code": "AQ"
            },
            {
                "id": 1791,
                "name": "Province of La Spezia",
                "state_code": "SP"
            },
            {
                "id": 1674,
                "name": "Province of Latina",
                "state_code": "LT"
            },
            {
                "id": 1675,
                "name": "Province of Lecce",
                "state_code": "LE"
            },
            {
                "id": 1677,
                "name": "Province of Lecco",
                "state_code": "LC"
            },
            {
                "id": 1745,
                "name": "Province of Livorno",
                "state_code": "LI"
            },
            {
                "id": 1747,
                "name": "Province of Lodi",
                "state_code": "LO"
            },
            {
                "id": 1749,
                "name": "Province of Lucca",
                "state_code": "LU"
            },
            {
                "id": 1750,
                "name": "Province of Macerata",
                "state_code": "MC"
            },
            {
                "id": 1758,
                "name": "Province of Mantua",
                "state_code": "MN"
            },
            {
                "id": 1759,
                "name": "Province of Massa and Carrara",
                "state_code": "MS"
            },
            {
                "id": 1760,
                "name": "Province of Matera",
                "state_code": "MT"
            },
            {
                "id": 1761,
                "name": "Province of Medio Campidano",
                "state_code": "VS"
            },
            {
                "id": 1757,
                "name": "Province of Modena",
                "state_code": "MO"
            },
            {
                "id": 1769,
                "name": "Province of Monza and Brianza",
                "state_code": "MB"
            },
            {
                "id": 1774,
                "name": "Province of Novara",
                "state_code": "NO"
            },
            {
                "id": 1790,
                "name": "Province of Nuoro",
                "state_code": "NU"
            },
            {
                "id": 1782,
                "name": "Province of Ogliastra",
                "state_code": "OG"
            },
            {
                "id": 1784,
                "name": "Province of Olbia-Tempio",
                "state_code": "OT"
            },
            {
                "id": 1786,
                "name": "Province of Oristano",
                "state_code": "OR"
            },
            {
                "id": 1665,
                "name": "Province of Padua",
                "state_code": "PD"
            },
            {
                "id": 1666,
                "name": "Province of Parma",
                "state_code": "PR"
            },
            {
                "id": 1676,
                "name": "Province of Pavia",
                "state_code": "PV"
            },
            {
                "id": 1691,
                "name": "Province of Perugia",
                "state_code": "PG"
            },
            {
                "id": 1694,
                "name": "Province of Pescara",
                "state_code": "PE"
            },
            {
                "id": 1696,
                "name": "Province of Piacenza",
                "state_code": "PC"
            },
            {
                "id": 1685,
                "name": "Province of Pisa",
                "state_code": "PI"
            },
            {
                "id": 1687,
                "name": "Province of Pistoia",
                "state_code": "PT"
            },
            {
                "id": 1690,
                "name": "Province of Pordenone",
                "state_code": "PN"
            },
            {
                "id": 1697,
                "name": "Province of Potenza",
                "state_code": "PZ"
            },
            {
                "id": 1700,
                "name": "Province of Prato",
                "state_code": "PO"
            },
            {
                "id": 1707,
                "name": "Province of Ravenna",
                "state_code": "RA"
            },
            {
                "id": 1708,
                "name": "Province of Reggio Emilia",
                "state_code": "RE"
            },
            {
                "id": 1712,
                "name": "Province of Rieti",
                "state_code": "RI"
            },
            {
                "id": 1713,
                "name": "Province of Rimini",
                "state_code": "RN"
            },
            {
                "id": 1719,
                "name": "Province of Rovigo",
                "state_code": "RO"
            },
            {
                "id": 1720,
                "name": "Province of Salerno",
                "state_code": "SA"
            },
            {
                "id": 1722,
                "name": "Province of Sassari",
                "state_code": "SS"
            },
            {
                "id": 1732,
                "name": "Province of Savona",
                "state_code": "SV"
            },
            {
                "id": 1734,
                "name": "Province of Siena",
                "state_code": "SI"
            },
            {
                "id": 1741,
                "name": "Province of Sondrio",
                "state_code": "SO"
            },
            {
                "id": 1743,
                "name": "Province of Taranto",
                "state_code": "TA"
            },
            {
                "id": 1752,
                "name": "Province of Teramo",
                "state_code": "TE"
            },
            {
                "id": 1755,
                "name": "Province of Terni",
                "state_code": "TR"
            },
            {
                "id": 1762,
                "name": "Province of Treviso",
                "state_code": "TV"
            },
            {
                "id": 1763,
                "name": "Province of Trieste",
                "state_code": "TS"
            },
            {
                "id": 1764,
                "name": "Province of Udine",
                "state_code": "UD"
            },
            {
                "id": 1765,
                "name": "Province of Varese",
                "state_code": "VA"
            },
            {
                "id": 1726,
                "name": "Province of Verbano-Cusio-Ossola",
                "state_code": "VB"
            },
            {
                "id": 1785,
                "name": "Province of Vercelli",
                "state_code": "VC"
            },
            {
                "id": 1736,
                "name": "Province of Verona",
                "state_code": "VR"
            },
            {
                "id": 1737,
                "name": "Province of Vibo Valentia",
                "state_code": "VV"
            },
            {
                "id": 1738,
                "name": "Province of Vicenza",
                "state_code": "VI"
            },
            {
                "id": 1735,
                "name": "Province of Viterbo",
                "state_code": "VT"
            },
            {
                "id": 1715,
                "name": "Sardinia",
                "state_code": "88"
            },
            {
                "id": 1709,
                "name": "Sicily",
                "state_code": "82"
            },
            {
                "id": 1767,
                "name": "South Tyrol",
                "state_code": "BZ"
            },
            {
                "id": 1748,
                "name": "Trentino",
                "state_code": "TN"
            },
            {
                "id": 1725,
                "name": "Trentino-South Tyrol",
                "state_code": "32"
            },
            {
                "id": 1664,
                "name": "Tuscany",
                "state_code": "52"
            },
            {
                "id": 1683,
                "name": "Umbria",
                "state_code": "55"
            },
            {
                "id": 1753,
                "name": "Veneto",
                "state_code": "34"
            }
        ]
    },
    {
        "name": "Jamaica",
        "iso3": "JAM",
        "iso2": "JM",
        "phone_code": "+1-876",
        "capital": "Kingston",
        "currency": "JMD",
        "currency_symbol": "J$",
        "tld": ".jm",
        "native": "Jamaica",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/Jamaica",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            }
        ],
        "translations": {
            "br": "Jamaica",
            "pt": "Jamaica",
            "nl": "Jamaica",
            "hr": "Jamajka",
            "fa": "جامائیکا",
            "de": "Jamaika",
            "es": "Jamaica",
            "fr": "Jamaïque",
            "ja": "ジャマイカ",
            "it": "Giamaica"
        },
        "latitude": "18.25000000",
        "longitude": "-77.50000000",
        "emoji": "🇯🇲",
        "emojiU": "U+1F1EF U+1F1F2",
        "states": [
            {
                "id": 3753,
                "name": "Clarendon Parish",
                "state_code": "13"
            },
            {
                "id": 3749,
                "name": "Hanover Parish",
                "state_code": "09"
            },
            {
                "id": 3748,
                "name": "Kingston Parish",
                "state_code": "01"
            },
            {
                "id": 3754,
                "name": "Manchester Parish",
                "state_code": "12"
            },
            {
                "id": 3752,
                "name": "Portland Parish",
                "state_code": "04"
            },
            {
                "id": 3751,
                "name": "Saint Andrew",
                "state_code": "02"
            },
            {
                "id": 3744,
                "name": "Saint Ann Parish",
                "state_code": "06"
            },
            {
                "id": 3746,
                "name": "Saint Catherine Parish",
                "state_code": "14"
            },
            {
                "id": 3743,
                "name": "Saint Elizabeth Parish",
                "state_code": "11"
            },
            {
                "id": 3745,
                "name": "Saint James Parish",
                "state_code": "08"
            },
            {
                "id": 3747,
                "name": "Saint Mary Parish",
                "state_code": "05"
            },
            {
                "id": 3750,
                "name": "Saint Thomas Parish",
                "state_code": "03"
            },
            {
                "id": 3755,
                "name": "Trelawny Parish",
                "state_code": "07"
            },
            {
                "id": 3742,
                "name": "Westmoreland Parish",
                "state_code": "10"
            }
        ]
    },
    {
        "name": "Japan",
        "iso3": "JPN",
        "iso2": "JP",
        "phone_code": "81",
        "capital": "Tokyo",
        "currency": "JPY",
        "currency_symbol": "¥",
        "tld": ".jp",
        "native": "日本",
        "region": "Asia",
        "subregion": "Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Tokyo",
                "gmtOffset": 32400,
                "gmtOffsetName": "UTC+09:00",
                "abbreviation": "JST",
                "tzName": "Japan Standard Time"
            }
        ],
        "translations": {
            "br": "Japão",
            "pt": "Japão",
            "nl": "Japan",
            "hr": "Japan",
            "fa": "ژاپن",
            "de": "Japan",
            "es": "Japón",
            "fr": "Japon",
            "ja": "日本",
            "it": "Giappone"
        },
        "latitude": "36.00000000",
        "longitude": "138.00000000",
        "emoji": "🇯🇵",
        "emojiU": "U+1F1EF U+1F1F5",
        "states": [
            {
                "id": 827,
                "name": "Aichi Prefecture",
                "state_code": "23"
            },
            {
                "id": 829,
                "name": "Akita Prefecture",
                "state_code": "05"
            },
            {
                "id": 839,
                "name": "Aomori Prefecture",
                "state_code": "02"
            },
            {
                "id": 821,
                "name": "Chiba Prefecture",
                "state_code": "12"
            },
            {
                "id": 865,
                "name": "Ehime Prefecture",
                "state_code": "38"
            },
            {
                "id": 848,
                "name": "Fukui Prefecture",
                "state_code": "18"
            },
            {
                "id": 861,
                "name": "Fukuoka Prefecture",
                "state_code": "40"
            },
            {
                "id": 847,
                "name": "Fukushima Prefecture",
                "state_code": "07"
            },
            {
                "id": 858,
                "name": "Gifu Prefecture",
                "state_code": "21"
            },
            {
                "id": 862,
                "name": "Gunma Prefecture",
                "state_code": "10"
            },
            {
                "id": 828,
                "name": "Hiroshima Prefecture",
                "state_code": "34"
            },
            {
                "id": 832,
                "name": "Hokkaidō Prefecture",
                "state_code": "01"
            },
            {
                "id": 831,
                "name": "Hyōgo Prefecture",
                "state_code": "28"
            },
            {
                "id": 851,
                "name": "Ibaraki Prefecture",
                "state_code": "08"
            },
            {
                "id": 830,
                "name": "Ishikawa Prefecture",
                "state_code": "17"
            },
            {
                "id": 856,
                "name": "Iwate Prefecture",
                "state_code": "03"
            },
            {
                "id": 864,
                "name": "Kagawa Prefecture",
                "state_code": "37"
            },
            {
                "id": 840,
                "name": "Kagoshima Prefecture",
                "state_code": "46"
            },
            {
                "id": 842,
                "name": "Kanagawa Prefecture",
                "state_code": "14"
            },
            {
                "id": 846,
                "name": "Kumamoto Prefecture",
                "state_code": "43"
            },
            {
                "id": 834,
                "name": "Kyōto Prefecture",
                "state_code": "26"
            },
            {
                "id": 833,
                "name": "Mie Prefecture",
                "state_code": "24"
            },
            {
                "id": 857,
                "name": "Miyagi Prefecture",
                "state_code": "04"
            },
            {
                "id": 855,
                "name": "Miyazaki Prefecture",
                "state_code": "45"
            },
            {
                "id": 843,
                "name": "Nagano Prefecture",
                "state_code": "20"
            },
            {
                "id": 849,
                "name": "Nagasaki Prefecture",
                "state_code": "42"
            },
            {
                "id": 824,
                "name": "Nara Prefecture",
                "state_code": "29"
            },
            {
                "id": 841,
                "name": "Niigata Prefecture",
                "state_code": "15"
            },
            {
                "id": 822,
                "name": "Ōita Prefecture",
                "state_code": "44"
            },
            {
                "id": 820,
                "name": "Okayama Prefecture",
                "state_code": "33"
            },
            {
                "id": 853,
                "name": "Okinawa Prefecture",
                "state_code": "47"
            },
            {
                "id": 859,
                "name": "Ōsaka Prefecture",
                "state_code": "27"
            },
            {
                "id": 863,
                "name": "Saga Prefecture",
                "state_code": "41"
            },
            {
                "id": 860,
                "name": "Saitama Prefecture",
                "state_code": "11"
            },
            {
                "id": 845,
                "name": "Shiga Prefecture",
                "state_code": "25"
            },
            {
                "id": 826,
                "name": "Shimane Prefecture",
                "state_code": "32"
            },
            {
                "id": 825,
                "name": "Shizuoka Prefecture",
                "state_code": "22"
            },
            {
                "id": 854,
                "name": "Tochigi Prefecture",
                "state_code": "09"
            },
            {
                "id": 836,
                "name": "Tokushima Prefecture",
                "state_code": "36"
            },
            {
                "id": 823,
                "name": "Tokyo",
                "state_code": "13"
            },
            {
                "id": 850,
                "name": "Tottori Prefecture",
                "state_code": "31"
            },
            {
                "id": 838,
                "name": "Toyama Prefecture",
                "state_code": "16"
            },
            {
                "id": 844,
                "name": "Wakayama Prefecture",
                "state_code": "30"
            },
            {
                "id": 837,
                "name": "Yamagata Prefecture",
                "state_code": "06"
            },
            {
                "id": 835,
                "name": "Yamaguchi Prefecture",
                "state_code": "35"
            },
            {
                "id": 852,
                "name": "Yamanashi Prefecture",
                "state_code": "19"
            }
        ]
    },
    {
        "name": "Jersey",
        "iso3": "JEY",
        "iso2": "JE",
        "phone_code": "+44-1534",
        "capital": "Saint Helier",
        "currency": "GBP",
        "currency_symbol": "£",
        "tld": ".je",
        "native": "Jersey",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Jersey",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "translations": {
            "br": "Jersey",
            "pt": "Jersey",
            "nl": "Jersey",
            "hr": "Jersey",
            "fa": "جرزی",
            "de": "Jersey",
            "es": "Jersey",
            "fr": "Jersey",
            "ja": "ジャージー",
            "it": "Isola di Jersey"
        },
        "latitude": "49.25000000",
        "longitude": "-2.16666666",
        "emoji": "🇯🇪",
        "emojiU": "U+1F1EF U+1F1EA",
        "states": []
    },
    {
        "name": "Jordan",
        "iso3": "JOR",
        "iso2": "JO",
        "phone_code": "962",
        "capital": "Amman",
        "currency": "JOD",
        "currency_symbol": "ا.د",
        "tld": ".jo",
        "native": "الأردن",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia/Amman",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "translations": {
            "br": "Jordânia",
            "pt": "Jordânia",
            "nl": "Jordanië",
            "hr": "Jordan",
            "fa": "اردن",
            "de": "Jordanien",
            "es": "Jordania",
            "fr": "Jordanie",
            "ja": "ヨルダン",
            "it": "Giordania"
        },
        "latitude": "31.00000000",
        "longitude": "36.00000000",
        "emoji": "🇯🇴",
        "emojiU": "U+1F1EF U+1F1F4",
        "states": [
            {
                "id": 963,
                "name": "Ajloun Governorate",
                "state_code": "AJ"
            },
            {
                "id": 965,
                "name": "Amman Governorate",
                "state_code": "AM"
            },
            {
                "id": 959,
                "name": "Aqaba Governorate",
                "state_code": "AQ"
            },
            {
                "id": 961,
                "name": "Balqa Governorate",
                "state_code": "BA"
            },
            {
                "id": 960,
                "name": "Irbid Governorate",
                "state_code": "IR"
            },
            {
                "id": 966,
                "name": "Jerash Governorate",
                "state_code": "JA"
            },
            {
                "id": 956,
                "name": "Karak Governorate",
                "state_code": "KA"
            },
            {
                "id": 964,
                "name": "Ma'an Governorate",
                "state_code": "MN"
            },
            {
                "id": 958,
                "name": "Madaba Governorate",
                "state_code": "MD"
            },
            {
                "id": 962,
                "name": "Mafraq Governorate",
                "state_code": "MA"
            },
            {
                "id": 957,
                "name": "Tafilah Governorate",
                "state_code": "AT"
            },
            {
                "id": 967,
                "name": "Zarqa Governorate",
                "state_code": "AZ"
            }
        ]
    },
    {
        "name": "Kazakhstan",
        "iso3": "KAZ",
        "iso2": "KZ",
        "phone_code": "7",
        "capital": "Astana",
        "currency": "KZT",
        "currency_symbol": "лв",
        "tld": ".kz",
        "native": "Қазақстан",
        "region": "Asia",
        "subregion": "Central Asia",
        "timezones": [
            {
                "zoneName": "Asia/Almaty",
                "gmtOffset": 21600,
                "gmtOffsetName": "UTC+06:00",
                "abbreviation": "ALMT",
                "tzName": "Alma-Ata Time[1"
            },
            {
                "zoneName": "Asia/Aqtau",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "AQTT",
                "tzName": "Aqtobe Time"
            },
            {
                "zoneName": "Asia/Aqtobe",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "AQTT",
                "tzName": "Aqtobe Time"
            },
            {
                "zoneName": "Asia/Atyrau",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "MSD+1",
                "tzName": "Moscow Daylight Time+1"
            },
            {
                "zoneName": "Asia/Oral",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "ORAT",
                "tzName": "Oral Time"
            },
            {
                "zoneName": "Asia/Qostanay",
                "gmtOffset": 21600,
                "gmtOffsetName": "UTC+06:00",
                "abbreviation": "QYZST",
                "tzName": "Qyzylorda Summer Time"
            },
            {
                "zoneName": "Asia/Qyzylorda",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "QYZT",
                "tzName": "Qyzylorda Summer Time"
            }
        ],
        "translations": {
            "br": "Cazaquistão",
            "pt": "Cazaquistão",
            "nl": "Kazachstan",
            "hr": "Kazahstan",
            "fa": "قزاقستان",
            "de": "Kasachstan",
            "es": "Kazajistán",
            "fr": "Kazakhstan",
            "ja": "カザフスタン",
            "it": "Kazakistan"
        },
        "latitude": "48.00000000",
        "longitude": "68.00000000",
        "emoji": "🇰🇿",
        "emojiU": "U+1F1F0 U+1F1FF",
        "states": [
            {
                "id": 145,
                "name": "Akmola Region",
                "state_code": "AKM"
            },
            {
                "id": 151,
                "name": "Aktobe Region",
                "state_code": "AKT"
            },
            {
                "id": 152,
                "name": "Almaty",
                "state_code": "ALA"
            },
            {
                "id": 143,
                "name": "Almaty Region",
                "state_code": "ALM"
            },
            {
                "id": 153,
                "name": "Atyrau Region",
                "state_code": "ATY"
            },
            {
                "id": 155,
                "name": "Baikonur",
                "state_code": "BAY"
            },
            {
                "id": 154,
                "name": "East Kazakhstan Region",
                "state_code": "VOS"
            },
            {
                "id": 147,
                "name": "Jambyl Region",
                "state_code": "ZHA"
            },
            {
                "id": 150,
                "name": "Karaganda Region",
                "state_code": "KAR"
            },
            {
                "id": 157,
                "name": "Kostanay Region",
                "state_code": "KUS"
            },
            {
                "id": 142,
                "name": "Kyzylorda Region",
                "state_code": "KZY"
            },
            {
                "id": 141,
                "name": "Mangystau Region",
                "state_code": "MAN"
            },
            {
                "id": 144,
                "name": "North Kazakhstan Region",
                "state_code": "SEV"
            },
            {
                "id": 156,
                "name": "Nur-Sultan",
                "state_code": "AST"
            },
            {
                "id": 146,
                "name": "Pavlodar Region",
                "state_code": "PAV"
            },
            {
                "id": 149,
                "name": "Turkestan Region",
                "state_code": "YUZ"
            },
            {
                "id": 148,
                "name": "West Kazakhstan Province",
                "state_code": "ZAP"
            }
        ]
    },
    {
        "name": "Kenya",
        "iso3": "KEN",
        "iso2": "KE",
        "phone_code": "254",
        "capital": "Nairobi",
        "currency": "KES",
        "currency_symbol": "KSh",
        "tld": ".ke",
        "native": "Kenya",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa/Nairobi",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "EAT",
                "tzName": "East Africa Time"
            }
        ],
        "translations": {
            "br": "Quênia",
            "pt": "Quénia",
            "nl": "Kenia",
            "hr": "Kenija",
            "fa": "کنیا",
            "de": "Kenia",
            "es": "Kenia",
            "fr": "Kenya",
            "ja": "ケニア",
            "it": "Kenya"
        },
        "latitude": "1.00000000",
        "longitude": "38.00000000",
        "emoji": "🇰🇪",
        "emojiU": "U+1F1F0 U+1F1EA",
        "states": [
            {
                "id": 181,
                "name": "Baringo County",
                "state_code": "01"
            },
            {
                "id": 210,
                "name": "Bomet County",
                "state_code": "02"
            },
            {
                "id": 168,
                "name": "Bungoma County",
                "state_code": "03"
            },
            {
                "id": 161,
                "name": "Busia County",
                "state_code": "04"
            },
            {
                "id": 160,
                "name": "Central Province",
                "state_code": "200"
            },
            {
                "id": 172,
                "name": "Coast Province",
                "state_code": "300"
            },
            {
                "id": 189,
                "name": "Eastern Province",
                "state_code": "400"
            },
            {
                "id": 201,
                "name": "Elgeyo-Marakwet County",
                "state_code": "05"
            },
            {
                "id": 163,
                "name": "Embu County",
                "state_code": "06"
            },
            {
                "id": 196,
                "name": "Garissa County",
                "state_code": "07"
            },
            {
                "id": 195,
                "name": "Homa Bay County",
                "state_code": "08"
            },
            {
                "id": 170,
                "name": "Isiolo County",
                "state_code": "09"
            },
            {
                "id": 197,
                "name": "Kajiado County",
                "state_code": "10"
            },
            {
                "id": 158,
                "name": "Kakamega County",
                "state_code": "11"
            },
            {
                "id": 193,
                "name": "Kericho County",
                "state_code": "12"
            },
            {
                "id": 199,
                "name": "Kiambu County",
                "state_code": "13"
            },
            {
                "id": 174,
                "name": "Kilifi County",
                "state_code": "14"
            },
            {
                "id": 167,
                "name": "Kirinyaga County",
                "state_code": "15"
            },
            {
                "id": 159,
                "name": "Kisii County",
                "state_code": "16"
            },
            {
                "id": 171,
                "name": "Kisumu County",
                "state_code": "17"
            },
            {
                "id": 211,
                "name": "Kitui County",
                "state_code": "18"
            },
            {
                "id": 173,
                "name": "Kwale County",
                "state_code": "19"
            },
            {
                "id": 164,
                "name": "Laikipia County",
                "state_code": "20"
            },
            {
                "id": 166,
                "name": "Lamu County",
                "state_code": "21"
            },
            {
                "id": 184,
                "name": "Machakos County",
                "state_code": "22"
            },
            {
                "id": 188,
                "name": "Makueni County",
                "state_code": "23"
            },
            {
                "id": 187,
                "name": "Mandera County",
                "state_code": "24"
            },
            {
                "id": 194,
                "name": "Marsabit County",
                "state_code": "25"
            },
            {
                "id": 198,
                "name": "Meru County",
                "state_code": "26"
            },
            {
                "id": 190,
                "name": "Migori County",
                "state_code": "27"
            },
            {
                "id": 200,
                "name": "Mombasa County",
                "state_code": "28"
            },
            {
                "id": 178,
                "name": "Muranga County",
                "state_code": "29"
            },
            {
                "id": 191,
                "name": "Nairobi",
                "state_code": "110"
            },
            {
                "id": 203,
                "name": "Nakuru District",
                "state_code": "31"
            },
            {
                "id": 165,
                "name": "Nandi District",
                "state_code": "32"
            },
            {
                "id": 175,
                "name": "Narok County",
                "state_code": "33"
            },
            {
                "id": 162,
                "name": "North Eastern Province",
                "state_code": "500"
            },
            {
                "id": 209,
                "name": "Nyamira District",
                "state_code": "34"
            },
            {
                "id": 192,
                "name": "Nyandarua County",
                "state_code": "35"
            },
            {
                "id": 204,
                "name": "Nyanza Province",
                "state_code": "600"
            },
            {
                "id": 180,
                "name": "Nyeri County",
                "state_code": "36"
            },
            {
                "id": 179,
                "name": "Rift Valley Province",
                "state_code": "700"
            },
            {
                "id": 207,
                "name": "Samburu County",
                "state_code": "37"
            },
            {
                "id": 186,
                "name": "Siaya County",
                "state_code": "38"
            },
            {
                "id": 176,
                "name": "Taita–Taveta County",
                "state_code": "39"
            },
            {
                "id": 205,
                "name": "Tana River County",
                "state_code": "40"
            },
            {
                "id": 185,
                "name": "Tharaka Nithi County",
                "state_code": "41"
            },
            {
                "id": 183,
                "name": "Trans-Nzoia District",
                "state_code": "42"
            },
            {
                "id": 206,
                "name": "Turkana County",
                "state_code": "43"
            },
            {
                "id": 169,
                "name": "Uasin Gishu District",
                "state_code": "44"
            },
            {
                "id": 202,
                "name": "Vihiga District",
                "state_code": "45"
            },
            {
                "id": 182,
                "name": "Wajir County",
                "state_code": "46"
            },
            {
                "id": 208,
                "name": "West Pokot County",
                "state_code": "47"
            },
            {
                "id": 177,
                "name": "Western Province",
                "state_code": "800"
            }
        ]
    },
    {
        "name": "Kiribati",
        "iso3": "KIR",
        "iso2": "KI",
        "phone_code": "686",
        "capital": "Tarawa",
        "currency": "AUD",
        "currency_symbol": "$",
        "tld": ".ki",
        "native": "Kiribati",
        "region": "Oceania",
        "subregion": "Micronesia",
        "timezones": [
            {
                "zoneName": "Pacific/Enderbury",
                "gmtOffset": 46800,
                "gmtOffsetName": "UTC+13:00",
                "abbreviation": "PHOT",
                "tzName": "Phoenix Island Time"
            },
            {
                "zoneName": "Pacific/Kiritimati",
                "gmtOffset": 50400,
                "gmtOffsetName": "UTC+14:00",
                "abbreviation": "LINT",
                "tzName": "Line Islands Time"
            },
            {
                "zoneName": "Pacific/Tarawa",
                "gmtOffset": 43200,
                "gmtOffsetName": "UTC+12:00",
                "abbreviation": "GILT",
                "tzName": "Gilbert Island Time"
            }
        ],
        "translations": {
            "br": "Kiribati",
            "pt": "Quiribáti",
            "nl": "Kiribati",
            "hr": "Kiribati",
            "fa": "کیریباتی",
            "de": "Kiribati",
            "es": "Kiribati",
            "fr": "Kiribati",
            "ja": "キリバス",
            "it": "Kiribati"
        },
        "latitude": "1.41666666",
        "longitude": "173.00000000",
        "emoji": "🇰🇮",
        "emojiU": "U+1F1F0 U+1F1EE",
        "states": [
            {
                "id": 1831,
                "name": "Gilbert Islands",
                "state_code": "G"
            },
            {
                "id": 1832,
                "name": "Line Islands",
                "state_code": "L"
            },
            {
                "id": 1830,
                "name": "Phoenix Islands",
                "state_code": "P"
            }
        ]
    },
    {
        "name": "Korea North",
        "iso3": "PRK",
        "iso2": "KP",
        "phone_code": "850",
        "capital": "Pyongyang",
        "currency": "KPW",
        "currency_symbol": "₩",
        "tld": ".kp",
        "native": "북한",
        "region": "Asia",
        "subregion": "Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Pyongyang",
                "gmtOffset": 32400,
                "gmtOffsetName": "UTC+09:00",
                "abbreviation": "KST",
                "tzName": "Korea Standard Time"
            }
        ],
        "translations": {
            "br": "Coreia do Norte",
            "pt": "Coreia do Norte",
            "nl": "Noord-Korea",
            "hr": "Sjeverna Koreja",
            "fa": "کره جنوبی",
            "de": "Nordkorea",
            "es": "Corea del Norte",
            "fr": "Corée du Nord",
            "ja": "朝鮮民主主義人民共和国",
            "it": "Corea del Nord"
        },
        "latitude": "40.00000000",
        "longitude": "127.00000000",
        "emoji": "🇰🇵",
        "emojiU": "U+1F1F0 U+1F1F5",
        "states": [
            {
                "id": 3998,
                "name": "Chagang Province",
                "state_code": "04"
            },
            {
                "id": 3999,
                "name": "Kangwon Province",
                "state_code": "07"
            },
            {
                "id": 3995,
                "name": "North Hamgyong Province",
                "state_code": "09"
            },
            {
                "id": 4004,
                "name": "North Hwanghae Province",
                "state_code": "06"
            },
            {
                "id": 4002,
                "name": "North Pyongan Province",
                "state_code": "03"
            },
            {
                "id": 4005,
                "name": "Pyongyang",
                "state_code": "01"
            },
            {
                "id": 4001,
                "name": "Rason",
                "state_code": "13"
            },
            {
                "id": 3996,
                "name": "Ryanggang Province",
                "state_code": "10"
            },
            {
                "id": 4000,
                "name": "South Hamgyong Province",
                "state_code": "08"
            },
            {
                "id": 4003,
                "name": "South Hwanghae Province",
                "state_code": "05"
            },
            {
                "id": 3997,
                "name": "South Pyongan Province",
                "state_code": "02"
            }
        ]
    },
    {
        "name": "Korea South",
        "iso3": "KOR",
        "iso2": "KR",
        "phone_code": "82",
        "capital": "Seoul",
        "currency": "KRW",
        "currency_symbol": "₩",
        "tld": ".kr",
        "native": "대한민국",
        "region": "Asia",
        "subregion": "Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Seoul",
                "gmtOffset": 32400,
                "gmtOffsetName": "UTC+09:00",
                "abbreviation": "KST",
                "tzName": "Korea Standard Time"
            }
        ],
        "translations": {
            "br": "Coreia do Sul",
            "pt": "Coreia do Sul",
            "nl": "Zuid-Korea",
            "hr": "Južna Koreja",
            "fa": "کره شمالی",
            "de": "Südkorea",
            "es": "Corea del Sur",
            "fr": "Corée du Sud",
            "ja": "大韓民国",
            "it": "Corea del Sud"
        },
        "latitude": "37.00000000",
        "longitude": "127.50000000",
        "emoji": "🇰🇷",
        "emojiU": "U+1F1F0 U+1F1F7",
        "states": [
            {
                "id": 3860,
                "name": "Busan",
                "state_code": "26"
            },
            {
                "id": 3846,
                "name": "Daegu",
                "state_code": "27"
            },
            {
                "id": 3850,
                "name": "Daejeon",
                "state_code": "30"
            },
            {
                "id": 3862,
                "name": "Gangwon Province",
                "state_code": "42"
            },
            {
                "id": 3858,
                "name": "Gwangju",
                "state_code": "29"
            },
            {
                "id": 3847,
                "name": "Gyeonggi Province",
                "state_code": "41"
            },
            {
                "id": 3848,
                "name": "Incheon",
                "state_code": "28"
            },
            {
                "id": 3853,
                "name": "Jeju",
                "state_code": "49"
            },
            {
                "id": 3854,
                "name": "North Chungcheong Province",
                "state_code": "43"
            },
            {
                "id": 3855,
                "name": "North Gyeongsang Province",
                "state_code": "47"
            },
            {
                "id": 3851,
                "name": "North Jeolla Province",
                "state_code": "45"
            },
            {
                "id": 3861,
                "name": "Sejong City",
                "state_code": "50"
            },
            {
                "id": 3849,
                "name": "Seoul",
                "state_code": "11"
            },
            {
                "id": 3859,
                "name": "South Chungcheong Province",
                "state_code": "44"
            },
            {
                "id": 3857,
                "name": "South Gyeongsang Province",
                "state_code": "48"
            },
            {
                "id": 3856,
                "name": "South Jeolla Province",
                "state_code": "46"
            },
            {
                "id": 3852,
                "name": "Ulsan",
                "state_code": "31"
            }
        ]
    },
    {
        "name": "Kosovo",
        "iso3": "XKX",
        "iso2": "XK",
        "phone_code": "383",
        "capital": "Pristina",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".xk",
        "native": "Republika e Kosovës",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Belgrade",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": null,
        "latitude": "42.56129090",
        "longitude": "20.34030350",
        "emoji": "🇽🇰",
        "emojiU": "U+1F1FD U+1F1F0",
        "states": [
            {
                "id": 4876,
                "name": "Đakovica District (Gjakove)",
                "state_code": "XDG"
            },
            {
                "id": 4877,
                "name": "Gjilan District",
                "state_code": "XGJ"
            },
            {
                "id": 4878,
                "name": "Kosovska Mitrovica District",
                "state_code": "XKM"
            },
            {
                "id": 3738,
                "name": "Peć District",
                "state_code": "XPE"
            },
            {
                "id": 4879,
                "name": "Pristina (Priştine)",
                "state_code": "XPI"
            },
            {
                "id": 3723,
                "name": "Prizren District",
                "state_code": "XPR"
            },
            {
                "id": 4874,
                "name": "Uroševac District (Ferizaj)",
                "state_code": "XUF"
            }
        ]
    },
    {
        "name": "Kuwait",
        "iso3": "KWT",
        "iso2": "KW",
        "phone_code": "965",
        "capital": "Kuwait City",
        "currency": "KWD",
        "currency_symbol": "ك.د",
        "tld": ".kw",
        "native": "الكويت",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia/Kuwait",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "AST",
                "tzName": "Arabia Standard Time"
            }
        ],
        "translations": {
            "br": "Kuwait",
            "pt": "Kuwait",
            "nl": "Koeweit",
            "hr": "Kuvajt",
            "fa": "کویت",
            "de": "Kuwait",
            "es": "Kuwait",
            "fr": "Koweït",
            "ja": "クウェート",
            "it": "Kuwait"
        },
        "latitude": "29.50000000",
        "longitude": "45.75000000",
        "emoji": "🇰🇼",
        "emojiU": "U+1F1F0 U+1F1FC",
        "states": [
            {
                "id": 977,
                "name": "Al Ahmadi Governorate",
                "state_code": "AH"
            },
            {
                "id": 975,
                "name": "Al Farwaniyah Governorate",
                "state_code": "FA"
            },
            {
                "id": 972,
                "name": "Al Jahra Governorate",
                "state_code": "JA"
            },
            {
                "id": 976,
                "name": "Capital Governorate",
                "state_code": "KU"
            },
            {
                "id": 973,
                "name": "Hawalli Governorate",
                "state_code": "HA"
            },
            {
                "id": 974,
                "name": "Mubarak Al-Kabeer Governorate",
                "state_code": "MU"
            }
        ]
    },
    {
        "name": "Kyrgyzstan",
        "iso3": "KGZ",
        "iso2": "KG",
        "phone_code": "996",
        "capital": "Bishkek",
        "currency": "KGS",
        "currency_symbol": "лв",
        "tld": ".kg",
        "native": "Кыргызстан",
        "region": "Asia",
        "subregion": "Central Asia",
        "timezones": [
            {
                "zoneName": "Asia/Bishkek",
                "gmtOffset": 21600,
                "gmtOffsetName": "UTC+06:00",
                "abbreviation": "KGT",
                "tzName": "Kyrgyzstan Time"
            }
        ],
        "translations": {
            "br": "Quirguistão",
            "pt": "Quirguizistão",
            "nl": "Kirgizië",
            "hr": "Kirgistan",
            "fa": "قرقیزستان",
            "de": "Kirgisistan",
            "es": "Kirguizistán",
            "fr": "Kirghizistan",
            "ja": "キルギス",
            "it": "Kirghizistan"
        },
        "latitude": "41.00000000",
        "longitude": "75.00000000",
        "emoji": "🇰🇬",
        "emojiU": "U+1F1F0 U+1F1EC",
        "states": [
            {
                "id": 998,
                "name": "Batken Region",
                "state_code": "B"
            },
            {
                "id": 1001,
                "name": "Bishkek",
                "state_code": "GB"
            },
            {
                "id": 1004,
                "name": "Chuy Region",
                "state_code": "C"
            },
            {
                "id": 1002,
                "name": "Issyk-Kul Region",
                "state_code": "Y"
            },
            {
                "id": 1000,
                "name": "Jalal-Abad Region",
                "state_code": "J"
            },
            {
                "id": 999,
                "name": "Naryn Region",
                "state_code": "N"
            },
            {
                "id": 1003,
                "name": "Osh",
                "state_code": "GO"
            },
            {
                "id": 1005,
                "name": "Osh Region",
                "state_code": "O"
            },
            {
                "id": 997,
                "name": "Talas Region",
                "state_code": "T"
            }
        ]
    },
    {
        "name": "Laos",
        "iso3": "LAO",
        "iso2": "LA",
        "phone_code": "856",
        "capital": "Vientiane",
        "currency": "LAK",
        "currency_symbol": "₭",
        "tld": ".la",
        "native": "ສປປລາວ",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Vientiane",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "ICT",
                "tzName": "Indochina Time"
            }
        ],
        "translations": {
            "br": "Laos",
            "pt": "Laos",
            "nl": "Laos",
            "hr": "Laos",
            "fa": "لائوس",
            "de": "Laos",
            "es": "Laos",
            "fr": "Laos",
            "ja": "ラオス人民民主共和国",
            "it": "Laos"
        },
        "latitude": "18.00000000",
        "longitude": "105.00000000",
        "emoji": "🇱🇦",
        "emojiU": "U+1F1F1 U+1F1E6",
        "states": [
            {
                "id": 982,
                "name": "Attapeu Province",
                "state_code": "AT"
            },
            {
                "id": 991,
                "name": "Bokeo Province",
                "state_code": "BK"
            },
            {
                "id": 985,
                "name": "Bolikhamsai Province",
                "state_code": "BL"
            },
            {
                "id": 996,
                "name": "Champasak Province",
                "state_code": "CH"
            },
            {
                "id": 989,
                "name": "Houaphanh Province",
                "state_code": "HO"
            },
            {
                "id": 986,
                "name": "Khammouane Province",
                "state_code": "KH"
            },
            {
                "id": 992,
                "name": "Luang Namtha Province",
                "state_code": "LM"
            },
            {
                "id": 978,
                "name": "Luang Prabang Province",
                "state_code": "LP"
            },
            {
                "id": 988,
                "name": "Oudomxay Province",
                "state_code": "OU"
            },
            {
                "id": 987,
                "name": "Phongsaly Province",
                "state_code": "PH"
            },
            {
                "id": 993,
                "name": "Sainyabuli Province",
                "state_code": "XA"
            },
            {
                "id": 981,
                "name": "Salavan Province",
                "state_code": "SL"
            },
            {
                "id": 990,
                "name": "Savannakhet Province",
                "state_code": "SV"
            },
            {
                "id": 984,
                "name": "Sekong Province",
                "state_code": "XE"
            },
            {
                "id": 979,
                "name": "Vientiane Prefecture",
                "state_code": "VT"
            },
            {
                "id": 980,
                "name": "Vientiane Province",
                "state_code": "VI"
            },
            {
                "id": 994,
                "name": "Xaisomboun",
                "state_code": "XN"
            },
            {
                "id": 983,
                "name": "Xaisomboun Province",
                "state_code": "XS"
            },
            {
                "id": 995,
                "name": "Xiangkhouang Province",
                "state_code": "XI"
            }
        ]
    },
    {
        "name": "Latvia",
        "iso3": "LVA",
        "iso2": "LV",
        "phone_code": "371",
        "capital": "Riga",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".lv",
        "native": "Latvija",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Riga",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "translations": {
            "br": "Letônia",
            "pt": "Letónia",
            "nl": "Letland",
            "hr": "Latvija",
            "fa": "لتونی",
            "de": "Lettland",
            "es": "Letonia",
            "fr": "Lettonie",
            "ja": "ラトビア",
            "it": "Lettonia"
        },
        "latitude": "57.00000000",
        "longitude": "25.00000000",
        "emoji": "🇱🇻",
        "emojiU": "U+1F1F1 U+1F1FB",
        "states": [
            {
                "id": 4445,
                "name": "Aglona Municipality",
                "state_code": "001"
            },
            {
                "id": 4472,
                "name": "Aizkraukle Municipality",
                "state_code": "002"
            },
            {
                "id": 4496,
                "name": "Aizpute Municipality",
                "state_code": "003"
            },
            {
                "id": 4499,
                "name": "Aknīste Municipality",
                "state_code": "004"
            },
            {
                "id": 4484,
                "name": "Aloja Municipality",
                "state_code": "005"
            },
            {
                "id": 4485,
                "name": "Alsunga Municipality",
                "state_code": "006"
            },
            {
                "id": 4487,
                "name": "Alūksne Municipality",
                "state_code": "007"
            },
            {
                "id": 4497,
                "name": "Amata Municipality",
                "state_code": "008"
            },
            {
                "id": 4457,
                "name": "Ape Municipality",
                "state_code": "009"
            },
            {
                "id": 4481,
                "name": "Auce Municipality",
                "state_code": "010"
            },
            {
                "id": 4427,
                "name": "Babīte Municipality",
                "state_code": "012"
            },
            {
                "id": 4482,
                "name": "Baldone Municipality",
                "state_code": "013"
            },
            {
                "id": 4498,
                "name": "Baltinava Municipality",
                "state_code": "014"
            },
            {
                "id": 4505,
                "name": "Balvi Municipality",
                "state_code": "015"
            },
            {
                "id": 4465,
                "name": "Bauska Municipality",
                "state_code": "016"
            },
            {
                "id": 4471,
                "name": "Beverīna Municipality",
                "state_code": "017"
            },
            {
                "id": 4468,
                "name": "Brocēni Municipality",
                "state_code": "018"
            },
            {
                "id": 4411,
                "name": "Burtnieki Municipality",
                "state_code": "019"
            },
            {
                "id": 4454,
                "name": "Carnikava Municipality",
                "state_code": "020"
            },
            {
                "id": 4469,
                "name": "Cēsis Municipality",
                "state_code": "022"
            },
            {
                "id": 4414,
                "name": "Cesvaine Municipality",
                "state_code": "021"
            },
            {
                "id": 4410,
                "name": "Cibla Municipality",
                "state_code": "023"
            },
            {
                "id": 4504,
                "name": "Dagda Municipality",
                "state_code": "024"
            },
            {
                "id": 4463,
                "name": "Daugavpils",
                "state_code": "DGV"
            },
            {
                "id": 4492,
                "name": "Daugavpils Municipality",
                "state_code": "025"
            },
            {
                "id": 4437,
                "name": "Dobele Municipality",
                "state_code": "026"
            },
            {
                "id": 4428,
                "name": "Dundaga Municipality",
                "state_code": "027"
            },
            {
                "id": 4458,
                "name": "Durbe Municipality",
                "state_code": "028"
            },
            {
                "id": 4448,
                "name": "Engure Municipality",
                "state_code": "029"
            },
            {
                "id": 4444,
                "name": "Ērgļi Municipality",
                "state_code": "030"
            },
            {
                "id": 4510,
                "name": "Garkalne Municipality",
                "state_code": "031"
            },
            {
                "id": 4470,
                "name": "Grobiņa Municipality",
                "state_code": "032"
            },
            {
                "id": 4400,
                "name": "Gulbene Municipality",
                "state_code": "033"
            },
            {
                "id": 4441,
                "name": "Iecava Municipality",
                "state_code": "034"
            },
            {
                "id": 4511,
                "name": "Ikšķile Municipality",
                "state_code": "035"
            },
            {
                "id": 4399,
                "name": "Ilūkste Municipality",
                "state_code": "036"
            },
            {
                "id": 4449,
                "name": "Inčukalns Municipality",
                "state_code": "037"
            },
            {
                "id": 4475,
                "name": "Jaunjelgava Municipality",
                "state_code": "038"
            },
            {
                "id": 4407,
                "name": "Jaunpiebalga Municipality",
                "state_code": "039"
            },
            {
                "id": 4489,
                "name": "Jaunpils Municipality",
                "state_code": "040"
            },
            {
                "id": 4464,
                "name": "Jēkabpils",
                "state_code": "JKB"
            },
            {
                "id": 4438,
                "name": "Jēkabpils Municipality",
                "state_code": "042"
            },
            {
                "id": 4500,
                "name": "Jelgava",
                "state_code": "JEL"
            },
            {
                "id": 4424,
                "name": "Jelgava Municipality",
                "state_code": "041"
            },
            {
                "id": 4446,
                "name": "Jūrmala",
                "state_code": "JUR"
            },
            {
                "id": 4420,
                "name": "Kandava Municipality",
                "state_code": "043"
            },
            {
                "id": 4453,
                "name": "Kārsava Municipality",
                "state_code": "044"
            },
            {
                "id": 4412,
                "name": "Ķegums Municipality",
                "state_code": "051"
            },
            {
                "id": 4435,
                "name": "Ķekava Municipality",
                "state_code": "052"
            },
            {
                "id": 4495,
                "name": "Kocēni Municipality",
                "state_code": "045"
            },
            {
                "id": 4452,
                "name": "Koknese Municipality",
                "state_code": "046"
            },
            {
                "id": 4474,
                "name": "Krāslava Municipality",
                "state_code": "047"
            },
            {
                "id": 4422,
                "name": "Krimulda Municipality",
                "state_code": "048"
            },
            {
                "id": 4413,
                "name": "Krustpils Municipality",
                "state_code": "049"
            },
            {
                "id": 4490,
                "name": "Kuldīga Municipality",
                "state_code": "050"
            },
            {
                "id": 4512,
                "name": "Lielvārde Municipality",
                "state_code": "053"
            },
            {
                "id": 4460,
                "name": "Liepāja",
                "state_code": "LPX"
            },
            {
                "id": 4488,
                "name": "Līgatne Municipality",
                "state_code": "055"
            },
            {
                "id": 4418,
                "name": "Limbaži Municipality",
                "state_code": "054"
            },
            {
                "id": 4401,
                "name": "Līvāni Municipality",
                "state_code": "056"
            },
            {
                "id": 4419,
                "name": "Lubāna Municipality",
                "state_code": "057"
            },
            {
                "id": 4501,
                "name": "Ludza Municipality",
                "state_code": "058"
            },
            {
                "id": 4433,
                "name": "Madona Municipality",
                "state_code": "059"
            },
            {
                "id": 4461,
                "name": "Mālpils Municipality",
                "state_code": "061"
            },
            {
                "id": 4450,
                "name": "Mārupe Municipality",
                "state_code": "062"
            },
            {
                "id": 4513,
                "name": "Mazsalaca Municipality",
                "state_code": "060"
            },
            {
                "id": 4451,
                "name": "Mērsrags Municipality",
                "state_code": "063"
            },
            {
                "id": 4398,
                "name": "Naukšēni Municipality",
                "state_code": "064"
            },
            {
                "id": 4432,
                "name": "Nereta Municipality",
                "state_code": "065"
            },
            {
                "id": 4436,
                "name": "Nīca Municipality",
                "state_code": "066"
            },
            {
                "id": 4416,
                "name": "Ogre Municipality",
                "state_code": "067"
            },
            {
                "id": 4417,
                "name": "Olaine Municipality",
                "state_code": "068"
            },
            {
                "id": 4442,
                "name": "Ozolnieki Municipality",
                "state_code": "069"
            },
            {
                "id": 4507,
                "name": "Pārgauja Municipality",
                "state_code": "070"
            },
            {
                "id": 4467,
                "name": "Pāvilosta Municipality",
                "state_code": "071"
            },
            {
                "id": 4405,
                "name": "Pļaviņas Municipality",
                "state_code": "072"
            },
            {
                "id": 4483,
                "name": "Preiļi Municipality",
                "state_code": "073"
            },
            {
                "id": 4429,
                "name": "Priekule Municipality",
                "state_code": "074"
            },
            {
                "id": 4506,
                "name": "Priekuļi Municipality",
                "state_code": "075"
            },
            {
                "id": 4479,
                "name": "Rauna Municipality",
                "state_code": "076"
            },
            {
                "id": 4509,
                "name": "Rēzekne",
                "state_code": "REZ"
            },
            {
                "id": 4455,
                "name": "Rēzekne Municipality",
                "state_code": "077"
            },
            {
                "id": 4502,
                "name": "Riebiņi Municipality",
                "state_code": "078"
            },
            {
                "id": 4491,
                "name": "Riga",
                "state_code": "RIX"
            },
            {
                "id": 4440,
                "name": "Roja Municipality",
                "state_code": "079"
            },
            {
                "id": 4493,
                "name": "Ropaži Municipality",
                "state_code": "080"
            },
            {
                "id": 4503,
                "name": "Rucava Municipality",
                "state_code": "081"
            },
            {
                "id": 4423,
                "name": "Rugāji Municipality",
                "state_code": "082"
            },
            {
                "id": 4426,
                "name": "Rūjiena Municipality",
                "state_code": "084"
            },
            {
                "id": 4404,
                "name": "Rundāle Municipality",
                "state_code": "083"
            },
            {
                "id": 4434,
                "name": "Sala Municipality",
                "state_code": "085"
            },
            {
                "id": 4396,
                "name": "Salacgrīva Municipality",
                "state_code": "086"
            },
            {
                "id": 4402,
                "name": "Salaspils Municipality",
                "state_code": "087"
            },
            {
                "id": 4439,
                "name": "Saldus Municipality",
                "state_code": "088"
            },
            {
                "id": 4443,
                "name": "Saulkrasti Municipality",
                "state_code": "089"
            },
            {
                "id": 4408,
                "name": "Sēja Municipality",
                "state_code": "090"
            },
            {
                "id": 4476,
                "name": "Sigulda Municipality",
                "state_code": "091"
            },
            {
                "id": 4415,
                "name": "Skrīveri Municipality",
                "state_code": "092"
            },
            {
                "id": 4447,
                "name": "Skrunda Municipality",
                "state_code": "093"
            },
            {
                "id": 4462,
                "name": "Smiltene Municipality",
                "state_code": "094"
            },
            {
                "id": 4478,
                "name": "Stopiņi Municipality",
                "state_code": "095"
            },
            {
                "id": 4494,
                "name": "Strenči Municipality",
                "state_code": "096"
            },
            {
                "id": 4459,
                "name": "Talsi Municipality",
                "state_code": "097"
            },
            {
                "id": 4480,
                "name": "Tērvete Municipality",
                "state_code": "098"
            },
            {
                "id": 4409,
                "name": "Tukums Municipality",
                "state_code": "099"
            },
            {
                "id": 4508,
                "name": "Vaiņode Municipality",
                "state_code": "100"
            },
            {
                "id": 4425,
                "name": "Valka Municipality",
                "state_code": "101"
            },
            {
                "id": 4473,
                "name": "Valmiera",
                "state_code": "VMR"
            },
            {
                "id": 4431,
                "name": "Varakļāni Municipality",
                "state_code": "102"
            },
            {
                "id": 4406,
                "name": "Vārkava Municipality",
                "state_code": "103"
            },
            {
                "id": 4466,
                "name": "Vecpiebalga Municipality",
                "state_code": "104"
            },
            {
                "id": 4397,
                "name": "Vecumnieki Municipality",
                "state_code": "105"
            },
            {
                "id": 4421,
                "name": "Ventspils",
                "state_code": "VEN"
            },
            {
                "id": 4403,
                "name": "Ventspils Municipality",
                "state_code": "106"
            },
            {
                "id": 4456,
                "name": "Viesīte Municipality",
                "state_code": "107"
            },
            {
                "id": 4477,
                "name": "Viļaka Municipality",
                "state_code": "108"
            },
            {
                "id": 4486,
                "name": "Viļāni Municipality",
                "state_code": "109"
            },
            {
                "id": 4430,
                "name": "Zilupe Municipality",
                "state_code": "110"
            }
        ]
    },
    {
        "name": "Lebanon",
        "iso3": "LBN",
        "iso2": "LB",
        "phone_code": "961",
        "capital": "Beirut",
        "currency": "LBP",
        "currency_symbol": "£",
        "tld": ".lb",
        "native": "لبنان",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia/Beirut",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "translations": {
            "br": "Líbano",
            "pt": "Líbano",
            "nl": "Libanon",
            "hr": "Libanon",
            "fa": "لبنان",
            "de": "Libanon",
            "es": "Líbano",
            "fr": "Liban",
            "ja": "レバノン",
            "it": "Libano"
        },
        "latitude": "33.83333333",
        "longitude": "35.83333333",
        "emoji": "🇱🇧",
        "emojiU": "U+1F1F1 U+1F1E7",
        "states": [
            {
                "id": 2285,
                "name": "Akkar Governorate",
                "state_code": "AK"
            },
            {
                "id": 2283,
                "name": "Baalbek-Hermel Governorate",
                "state_code": "BH"
            },
            {
                "id": 2286,
                "name": "Beirut Governorate",
                "state_code": "BA"
            },
            {
                "id": 2287,
                "name": "Beqaa Governorate",
                "state_code": "BI"
            },
            {
                "id": 2282,
                "name": "Mount Lebanon Governorate",
                "state_code": "JL"
            },
            {
                "id": 2288,
                "name": "Nabatieh Governorate",
                "state_code": "NA"
            },
            {
                "id": 2284,
                "name": "North Governorate",
                "state_code": "AS"
            },
            {
                "id": 2281,
                "name": "South Governorate",
                "state_code": "JA"
            }
        ]
    },
    {
        "name": "Lesotho",
        "iso3": "LSO",
        "iso2": "LS",
        "phone_code": "266",
        "capital": "Maseru",
        "currency": "LSL",
        "currency_symbol": "L",
        "tld": ".ls",
        "native": "Lesotho",
        "region": "Africa",
        "subregion": "Southern Africa",
        "timezones": [
            {
                "zoneName": "Africa/Maseru",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "SAST",
                "tzName": "South African Standard Time"
            }
        ],
        "translations": {
            "br": "Lesoto",
            "pt": "Lesoto",
            "nl": "Lesotho",
            "hr": "Lesoto",
            "fa": "لسوتو",
            "de": "Lesotho",
            "es": "Lesotho",
            "fr": "Lesotho",
            "ja": "レソト",
            "it": "Lesotho"
        },
        "latitude": "-29.50000000",
        "longitude": "28.50000000",
        "emoji": "🇱🇸",
        "emojiU": "U+1F1F1 U+1F1F8",
        "states": [
            {
                "id": 3030,
                "name": "Berea District",
                "state_code": "D"
            },
            {
                "id": 3029,
                "name": "Butha-Buthe District",
                "state_code": "B"
            },
            {
                "id": 3026,
                "name": "Leribe District",
                "state_code": "C"
            },
            {
                "id": 3022,
                "name": "Mafeteng District",
                "state_code": "E"
            },
            {
                "id": 3028,
                "name": "Maseru District",
                "state_code": "A"
            },
            {
                "id": 3023,
                "name": "Mohale's Hoek District",
                "state_code": "F"
            },
            {
                "id": 3024,
                "name": "Mokhotlong District",
                "state_code": "J"
            },
            {
                "id": 3025,
                "name": "Qacha's Nek District",
                "state_code": "H"
            },
            {
                "id": 3027,
                "name": "Quthing District",
                "state_code": "G"
            },
            {
                "id": 3031,
                "name": "Thaba-Tseka District",
                "state_code": "K"
            }
        ]
    },
    {
        "name": "Liberia",
        "iso3": "LBR",
        "iso2": "LR",
        "phone_code": "231",
        "capital": "Monrovia",
        "currency": "LRD",
        "currency_symbol": "$",
        "tld": ".lr",
        "native": "Liberia",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa/Monrovia",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "translations": {
            "br": "Libéria",
            "pt": "Libéria",
            "nl": "Liberia",
            "hr": "Liberija",
            "fa": "لیبریا",
            "de": "Liberia",
            "es": "Liberia",
            "fr": "Liberia",
            "ja": "リベリア",
            "it": "Liberia"
        },
        "latitude": "6.50000000",
        "longitude": "-9.50000000",
        "emoji": "🇱🇷",
        "emojiU": "U+1F1F1 U+1F1F7",
        "states": [
            {
                "id": 3041,
                "name": "Bomi County",
                "state_code": "BM"
            },
            {
                "id": 3034,
                "name": "Bong County",
                "state_code": "BG"
            },
            {
                "id": 3044,
                "name": "Gbarpolu County",
                "state_code": "GP"
            },
            {
                "id": 3040,
                "name": "Grand Bassa County",
                "state_code": "GB"
            },
            {
                "id": 3036,
                "name": "Grand Cape Mount County",
                "state_code": "CM"
            },
            {
                "id": 3039,
                "name": "Grand Gedeh County",
                "state_code": "GG"
            },
            {
                "id": 3045,
                "name": "Grand Kru County",
                "state_code": "GK"
            },
            {
                "id": 3037,
                "name": "Lofa County",
                "state_code": "LO"
            },
            {
                "id": 3043,
                "name": "Margibi County",
                "state_code": "MG"
            },
            {
                "id": 3042,
                "name": "Maryland County",
                "state_code": "MY"
            },
            {
                "id": 3032,
                "name": "Montserrado County",
                "state_code": "MO"
            },
            {
                "id": 3046,
                "name": "Nimba",
                "state_code": "NI"
            },
            {
                "id": 3033,
                "name": "River Cess County",
                "state_code": "RI"
            },
            {
                "id": 3038,
                "name": "River Gee County",
                "state_code": "RG"
            },
            {
                "id": 3035,
                "name": "Sinoe County",
                "state_code": "SI"
            }
        ]
    },
    {
        "name": "Libya",
        "iso3": "LBY",
        "iso2": "LY",
        "phone_code": "218",
        "capital": "Tripolis",
        "currency": "LYD",
        "currency_symbol": "د.ل",
        "tld": ".ly",
        "native": "‏ليبيا",
        "region": "Africa",
        "subregion": "Northern Africa",
        "timezones": [
            {
                "zoneName": "Africa/Tripoli",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "translations": {
            "br": "Líbia",
            "pt": "Líbia",
            "nl": "Libië",
            "hr": "Libija",
            "fa": "لیبی",
            "de": "Libyen",
            "es": "Libia",
            "fr": "Libye",
            "ja": "リビア",
            "it": "Libia"
        },
        "latitude": "25.00000000",
        "longitude": "17.00000000",
        "emoji": "🇱🇾",
        "emojiU": "U+1F1F1 U+1F1FE",
        "states": [
            {
                "id": 2964,
                "name": "Al Wahat District",
                "state_code": "WA"
            },
            {
                "id": 2981,
                "name": "Benghazi",
                "state_code": "BA"
            },
            {
                "id": 2966,
                "name": "Derna District",
                "state_code": "DR"
            },
            {
                "id": 2969,
                "name": "Ghat District",
                "state_code": "GT"
            },
            {
                "id": 2980,
                "name": "Jabal al Akhdar",
                "state_code": "JA"
            },
            {
                "id": 2974,
                "name": "Jabal al Gharbi District",
                "state_code": "JG"
            },
            {
                "id": 2979,
                "name": "Jafara",
                "state_code": "JI"
            },
            {
                "id": 2970,
                "name": "Jufra",
                "state_code": "JU"
            },
            {
                "id": 2972,
                "name": "Kufra District",
                "state_code": "KF"
            },
            {
                "id": 2968,
                "name": "Marj District",
                "state_code": "MJ"
            },
            {
                "id": 2978,
                "name": "Misrata District",
                "state_code": "MI"
            },
            {
                "id": 2961,
                "name": "Murqub",
                "state_code": "MB"
            },
            {
                "id": 2967,
                "name": "Murzuq District",
                "state_code": "MQ"
            },
            {
                "id": 2976,
                "name": "Nalut District",
                "state_code": "NL"
            },
            {
                "id": 2962,
                "name": "Nuqat al Khams",
                "state_code": "NQ"
            },
            {
                "id": 2965,
                "name": "Sabha District",
                "state_code": "SB"
            },
            {
                "id": 2977,
                "name": "Sirte District",
                "state_code": "SR"
            },
            {
                "id": 2971,
                "name": "Tripoli District",
                "state_code": "TB"
            },
            {
                "id": 2973,
                "name": "Wadi al Hayaa District",
                "state_code": "WD"
            },
            {
                "id": 2975,
                "name": "Wadi al Shatii District",
                "state_code": "WS"
            },
            {
                "id": 2963,
                "name": "Zawiya District",
                "state_code": "ZA"
            }
        ]
    },
    {
        "name": "Liechtenstein",
        "iso3": "LIE",
        "iso2": "LI",
        "phone_code": "423",
        "capital": "Vaduz",
        "currency": "CHF",
        "currency_symbol": "CHf",
        "tld": ".li",
        "native": "Liechtenstein",
        "region": "Europe",
        "subregion": "Western Europe",
        "timezones": [
            {
                "zoneName": "Europe/Vaduz",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Liechtenstein",
            "pt": "Listenstaine",
            "nl": "Liechtenstein",
            "hr": "Lihtenštajn",
            "fa": "لیختن‌اشتاین",
            "de": "Liechtenstein",
            "es": "Liechtenstein",
            "fr": "Liechtenstein",
            "ja": "リヒテンシュタイン",
            "it": "Liechtenstein"
        },
        "latitude": "47.26666666",
        "longitude": "9.53333333",
        "emoji": "🇱🇮",
        "emojiU": "U+1F1F1 U+1F1EE",
        "states": [
            {
                "id": 458,
                "name": "Balzers",
                "state_code": "01"
            },
            {
                "id": 451,
                "name": "Eschen",
                "state_code": "02"
            },
            {
                "id": 457,
                "name": "Gamprin",
                "state_code": "03"
            },
            {
                "id": 455,
                "name": "Mauren",
                "state_code": "04"
            },
            {
                "id": 454,
                "name": "Planken",
                "state_code": "05"
            },
            {
                "id": 453,
                "name": "Ruggell",
                "state_code": "06"
            },
            {
                "id": 450,
                "name": "Schaan",
                "state_code": "07"
            },
            {
                "id": 449,
                "name": "Schellenberg",
                "state_code": "08"
            },
            {
                "id": 459,
                "name": "Triesen",
                "state_code": "09"
            },
            {
                "id": 456,
                "name": "Triesenberg",
                "state_code": "10"
            },
            {
                "id": 452,
                "name": "Vaduz",
                "state_code": "11"
            }
        ]
    },
    {
        "name": "Lithuania",
        "iso3": "LTU",
        "iso2": "LT",
        "phone_code": "370",
        "capital": "Vilnius",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".lt",
        "native": "Lietuva",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Vilnius",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "translations": {
            "br": "Lituânia",
            "pt": "Lituânia",
            "nl": "Litouwen",
            "hr": "Litva",
            "fa": "لیتوانی",
            "de": "Litauen",
            "es": "Lituania",
            "fr": "Lituanie",
            "ja": "リトアニア",
            "it": "Lituania"
        },
        "latitude": "56.00000000",
        "longitude": "24.00000000",
        "emoji": "🇱🇹",
        "emojiU": "U+1F1F1 U+1F1F9",
        "states": [
            {
                "id": 1561,
                "name": "Akmenė District Municipality",
                "state_code": "01"
            },
            {
                "id": 1605,
                "name": "Alytus City Municipality",
                "state_code": "02"
            },
            {
                "id": 1574,
                "name": "Alytus County",
                "state_code": "AL"
            },
            {
                "id": 1599,
                "name": "Alytus District Municipality",
                "state_code": "03"
            },
            {
                "id": 1603,
                "name": "Birštonas Municipality",
                "state_code": "05"
            },
            {
                "id": 1566,
                "name": "Biržai District Municipality",
                "state_code": "06"
            },
            {
                "id": 1579,
                "name": "Druskininkai municipality",
                "state_code": "07"
            },
            {
                "id": 1559,
                "name": "Elektrėnai municipality",
                "state_code": "08"
            },
            {
                "id": 1562,
                "name": "Ignalina District Municipality",
                "state_code": "09"
            },
            {
                "id": 1567,
                "name": "Jonava District Municipality",
                "state_code": "10"
            },
            {
                "id": 1581,
                "name": "Joniškis District Municipality",
                "state_code": "11"
            },
            {
                "id": 1555,
                "name": "Jurbarkas District Municipality",
                "state_code": "12"
            },
            {
                "id": 1583,
                "name": "Kaišiadorys District Municipality",
                "state_code": "13"
            },
            {
                "id": 1591,
                "name": "Kalvarija municipality",
                "state_code": "14"
            },
            {
                "id": 1580,
                "name": "Kaunas City Municipality",
                "state_code": "15"
            },
            {
                "id": 1556,
                "name": "Kaunas County",
                "state_code": "KU"
            },
            {
                "id": 1565,
                "name": "Kaunas District Municipality",
                "state_code": "16"
            },
            {
                "id": 1575,
                "name": "Kazlų Rūda municipality",
                "state_code": "17"
            },
            {
                "id": 1584,
                "name": "Kėdainiai District Municipality",
                "state_code": "18"
            },
            {
                "id": 1618,
                "name": "Kelmė District Municipality",
                "state_code": "19"
            },
            {
                "id": 1597,
                "name": "Klaipeda City Municipality",
                "state_code": "20"
            },
            {
                "id": 1600,
                "name": "Klaipėda County",
                "state_code": "KL"
            },
            {
                "id": 1604,
                "name": "Klaipėda District Municipality",
                "state_code": "21"
            },
            {
                "id": 1571,
                "name": "Kretinga District Municipality",
                "state_code": "22"
            },
            {
                "id": 1585,
                "name": "Kupiškis District Municipality",
                "state_code": "23"
            },
            {
                "id": 1611,
                "name": "Lazdijai District Municipality",
                "state_code": "24"
            },
            {
                "id": 1570,
                "name": "Marijampolė County",
                "state_code": "MR"
            },
            {
                "id": 1610,
                "name": "Marijampolė Municipality",
                "state_code": "25"
            },
            {
                "id": 1557,
                "name": "Mažeikiai District Municipality",
                "state_code": "26"
            },
            {
                "id": 1582,
                "name": "Molėtai District Municipality",
                "state_code": "27"
            },
            {
                "id": 1563,
                "name": "Neringa Municipality",
                "state_code": "28"
            },
            {
                "id": 1612,
                "name": "Pagėgiai municipality",
                "state_code": "29"
            },
            {
                "id": 1595,
                "name": "Pakruojis District Municipality",
                "state_code": "30"
            },
            {
                "id": 1588,
                "name": "Palanga City Municipality",
                "state_code": "31"
            },
            {
                "id": 1589,
                "name": "Panevėžys City Municipality",
                "state_code": "32"
            },
            {
                "id": 1558,
                "name": "Panevėžys County",
                "state_code": "PN"
            },
            {
                "id": 1614,
                "name": "Panevėžys District Municipality",
                "state_code": "33"
            },
            {
                "id": 1616,
                "name": "Pasvalys District Municipality",
                "state_code": "34"
            },
            {
                "id": 1553,
                "name": "Plungė District Municipality",
                "state_code": "35"
            },
            {
                "id": 1578,
                "name": "Prienai District Municipality",
                "state_code": "36"
            },
            {
                "id": 1568,
                "name": "Radviliškis District Municipality",
                "state_code": "37"
            },
            {
                "id": 1587,
                "name": "Raseiniai District Municipality",
                "state_code": "38"
            },
            {
                "id": 1590,
                "name": "Rietavas municipality",
                "state_code": "39"
            },
            {
                "id": 1615,
                "name": "Rokiškis District Municipality",
                "state_code": "40"
            },
            {
                "id": 1576,
                "name": "Šakiai District Municipality",
                "state_code": "41"
            },
            {
                "id": 1577,
                "name": "Šalčininkai District Municipality",
                "state_code": "42"
            },
            {
                "id": 1609,
                "name": "Šiauliai City Municipality",
                "state_code": "43"
            },
            {
                "id": 1586,
                "name": "Šiauliai County",
                "state_code": "SA"
            },
            {
                "id": 1554,
                "name": "Šiauliai District Municipality",
                "state_code": "44"
            },
            {
                "id": 1613,
                "name": "Šilalė District Municipality",
                "state_code": "45"
            },
            {
                "id": 1607,
                "name": "Šilutė District Municipality",
                "state_code": "46"
            },
            {
                "id": 1594,
                "name": "Širvintos District Municipality",
                "state_code": "47"
            },
            {
                "id": 1617,
                "name": "Skuodas District Municipality",
                "state_code": "48"
            },
            {
                "id": 1560,
                "name": "Švenčionys District Municipality",
                "state_code": "49"
            },
            {
                "id": 1573,
                "name": "Tauragė County",
                "state_code": "TA"
            },
            {
                "id": 1572,
                "name": "Tauragė District Municipality",
                "state_code": "50"
            },
            {
                "id": 1569,
                "name": "Telšiai County",
                "state_code": "TE"
            },
            {
                "id": 1608,
                "name": "Telšiai District Municipality",
                "state_code": "51"
            },
            {
                "id": 1593,
                "name": "Trakai District Municipality",
                "state_code": "52"
            },
            {
                "id": 1596,
                "name": "Ukmergė District Municipality",
                "state_code": "53"
            },
            {
                "id": 1621,
                "name": "Utena County",
                "state_code": "UT"
            },
            {
                "id": 1598,
                "name": "Utena District Municipality",
                "state_code": "54"
            },
            {
                "id": 1602,
                "name": "Varėna District Municipality",
                "state_code": "55"
            },
            {
                "id": 1620,
                "name": "Vilkaviškis District Municipality",
                "state_code": "56"
            },
            {
                "id": 1606,
                "name": "Vilnius City Municipality",
                "state_code": "57"
            },
            {
                "id": 1601,
                "name": "Vilnius County",
                "state_code": "VL"
            },
            {
                "id": 1592,
                "name": "Vilnius District Municipality",
                "state_code": "58"
            },
            {
                "id": 1564,
                "name": "Visaginas Municipality",
                "state_code": "59"
            },
            {
                "id": 1619,
                "name": "Zarasai District Municipality",
                "state_code": "60"
            }
        ]
    },
    {
        "name": "Luxembourg",
        "iso3": "LUX",
        "iso2": "LU",
        "phone_code": "352",
        "capital": "Luxembourg",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".lu",
        "native": "Luxembourg",
        "region": "Europe",
        "subregion": "Western Europe",
        "timezones": [
            {
                "zoneName": "Europe/Luxembourg",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Luxemburgo",
            "pt": "Luxemburgo",
            "nl": "Luxemburg",
            "hr": "Luksemburg",
            "fa": "لوکزامبورگ",
            "de": "Luxemburg",
            "es": "Luxemburgo",
            "fr": "Luxembourg",
            "ja": "ルクセンブルク",
            "it": "Lussemburgo"
        },
        "latitude": "49.75000000",
        "longitude": "6.16666666",
        "emoji": "🇱🇺",
        "emojiU": "U+1F1F1 U+1F1FA",
        "states": [
            {
                "id": 1518,
                "name": "Canton of Capellen",
                "state_code": "CA"
            },
            {
                "id": 1521,
                "name": "Canton of Clervaux",
                "state_code": "CL"
            },
            {
                "id": 1513,
                "name": "Canton of Diekirch",
                "state_code": "DI"
            },
            {
                "id": 1515,
                "name": "Canton of Echternach",
                "state_code": "EC"
            },
            {
                "id": 1517,
                "name": "Canton of Esch-sur-Alzette",
                "state_code": "ES"
            },
            {
                "id": 1525,
                "name": "Canton of Grevenmacher",
                "state_code": "GR"
            },
            {
                "id": 1527,
                "name": "Canton of Luxembourg",
                "state_code": "LU"
            },
            {
                "id": 1522,
                "name": "Canton of Mersch",
                "state_code": "ME"
            },
            {
                "id": 1516,
                "name": "Canton of Redange",
                "state_code": "RD"
            },
            {
                "id": 1519,
                "name": "Canton of Remich",
                "state_code": "RM"
            },
            {
                "id": 1523,
                "name": "Canton of Vianden",
                "state_code": "VD"
            },
            {
                "id": 1526,
                "name": "Canton of Wiltz",
                "state_code": "WI"
            },
            {
                "id": 1524,
                "name": "Diekirch District",
                "state_code": "D"
            },
            {
                "id": 1520,
                "name": "Grevenmacher District",
                "state_code": "G"
            },
            {
                "id": 1514,
                "name": "Luxembourg District",
                "state_code": "L"
            }
        ]
    },
    {
        "name": "Macau S.A.R.",
        "iso3": "MAC",
        "iso2": "MO",
        "phone_code": "853",
        "capital": "Macao",
        "currency": "MOP",
        "currency_symbol": "$",
        "tld": ".mo",
        "native": "澳門",
        "region": "Asia",
        "subregion": "Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Macau",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "CST",
                "tzName": "China Standard Time"
            }
        ],
        "translations": {
            "br": "Macau",
            "pt": "Macau",
            "nl": "Macao",
            "hr": "Makao",
            "fa": "مکائو",
            "de": "Macao",
            "es": "Macao",
            "fr": "Macao",
            "ja": "マカオ",
            "it": "Macao"
        },
        "latitude": "22.16666666",
        "longitude": "113.55000000",
        "emoji": "🇲🇴",
        "emojiU": "U+1F1F2 U+1F1F4",
        "states": []
    },
    {
        "name": "Macedonia",
        "iso3": "MKD",
        "iso2": "MK",
        "phone_code": "389",
        "capital": "Skopje",
        "currency": "MKD",
        "currency_symbol": "ден",
        "tld": ".mk",
        "native": "Северна Македонија",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Skopje",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Macedônia",
            "pt": "Macedónia",
            "nl": "Macedonië",
            "hr": "Makedonija",
            "fa": "",
            "de": "Mazedonien",
            "es": "Macedonia",
            "fr": "Macédoine",
            "ja": "マケドニア旧ユーゴスラビア共和国",
            "it": "Macedonia"
        },
        "latitude": "41.83333333",
        "longitude": "22.00000000",
        "emoji": "🇲🇰",
        "emojiU": "U+1F1F2 U+1F1F0",
        "states": [
            {
                "id": 703,
                "name": "Aerodrom Municipality",
                "state_code": "01"
            },
            {
                "id": 656,
                "name": "Aračinovo Municipality",
                "state_code": "02"
            },
            {
                "id": 716,
                "name": "Berovo Municipality",
                "state_code": "03"
            },
            {
                "id": 679,
                "name": "Bitola Municipality",
                "state_code": "04"
            },
            {
                "id": 649,
                "name": "Bogdanci Municipality",
                "state_code": "05"
            },
            {
                "id": 721,
                "name": "Bogovinje Municipality",
                "state_code": "06"
            },
            {
                "id": 652,
                "name": "Bosilovo Municipality",
                "state_code": "07"
            },
            {
                "id": 660,
                "name": "Brvenica Municipality",
                "state_code": "08"
            },
            {
                "id": 694,
                "name": "Butel Municipality",
                "state_code": "09"
            },
            {
                "id": 704,
                "name": "Čair Municipality",
                "state_code": "79"
            },
            {
                "id": 676,
                "name": "Čaška Municipality",
                "state_code": "80"
            },
            {
                "id": 702,
                "name": "Centar Municipality",
                "state_code": "77"
            },
            {
                "id": 720,
                "name": "Centar Župa Municipality",
                "state_code": "78"
            },
            {
                "id": 644,
                "name": "Češinovo-Obleševo Municipality",
                "state_code": "81"
            },
            {
                "id": 715,
                "name": "Čučer-Sandevo Municipality",
                "state_code": "82"
            },
            {
                "id": 645,
                "name": "Debarca Municipality",
                "state_code": "22"
            },
            {
                "id": 695,
                "name": "Delčevo Municipality",
                "state_code": "23"
            },
            {
                "id": 687,
                "name": "Demir Hisar Municipality",
                "state_code": "25"
            },
            {
                "id": 655,
                "name": "Demir Kapija Municipality",
                "state_code": "24"
            },
            {
                "id": 697,
                "name": "Dojran Municipality",
                "state_code": "26"
            },
            {
                "id": 675,
                "name": "Dolneni Municipality",
                "state_code": "27"
            },
            {
                "id": 657,
                "name": "Drugovo Municipality",
                "state_code": "28"
            },
            {
                "id": 707,
                "name": "Gazi Baba Municipality",
                "state_code": "17"
            },
            {
                "id": 648,
                "name": "Gevgelija Municipality",
                "state_code": "18"
            },
            {
                "id": 722,
                "name": "Gjorče Petrov Municipality",
                "state_code": "29"
            },
            {
                "id": 693,
                "name": "Gostivar Municipality",
                "state_code": "19"
            },
            {
                "id": 708,
                "name": "Gradsko Municipality",
                "state_code": "20"
            },
            {
                "id": 684,
                "name": "Greater Skopje",
                "state_code": "85"
            },
            {
                "id": 690,
                "name": "Ilinden Municipality",
                "state_code": "34"
            },
            {
                "id": 678,
                "name": "Jegunovce Municipality",
                "state_code": "35"
            },
            {
                "id": 674,
                "name": "Karbinci",
                "state_code": "37"
            },
            {
                "id": 681,
                "name": "Karpoš Municipality",
                "state_code": "38"
            },
            {
                "id": 713,
                "name": "Kavadarci Municipality",
                "state_code": "36"
            },
            {
                "id": 688,
                "name": "Kičevo Municipality",
                "state_code": "40"
            },
            {
                "id": 686,
                "name": "Kisela Voda Municipality",
                "state_code": "39"
            },
            {
                "id": 723,
                "name": "Kočani Municipality",
                "state_code": "42"
            },
            {
                "id": 665,
                "name": "Konče Municipality",
                "state_code": "41"
            },
            {
                "id": 641,
                "name": "Kratovo Municipality",
                "state_code": "43"
            },
            {
                "id": 677,
                "name": "Kriva Palanka Municipality",
                "state_code": "44"
            },
            {
                "id": 647,
                "name": "Krivogaštani Municipality",
                "state_code": "45"
            },
            {
                "id": 714,
                "name": "Kruševo Municipality",
                "state_code": "46"
            },
            {
                "id": 683,
                "name": "Kumanovo Municipality",
                "state_code": "47"
            },
            {
                "id": 659,
                "name": "Lipkovo Municipality",
                "state_code": "48"
            },
            {
                "id": 705,
                "name": "Lozovo Municipality",
                "state_code": "49"
            },
            {
                "id": 701,
                "name": "Makedonska Kamenica Municipality",
                "state_code": "51"
            },
            {
                "id": 692,
                "name": "Makedonski Brod Municipality",
                "state_code": "52"
            },
            {
                "id": 669,
                "name": "Mavrovo and Rostuša Municipality",
                "state_code": "50"
            },
            {
                "id": 653,
                "name": "Mogila Municipality",
                "state_code": "53"
            },
            {
                "id": 664,
                "name": "Negotino Municipality",
                "state_code": "54"
            },
            {
                "id": 696,
                "name": "Novaci Municipality",
                "state_code": "55"
            },
            {
                "id": 718,
                "name": "Novo Selo Municipality",
                "state_code": "56"
            },
            {
                "id": 699,
                "name": "Ohrid Municipality",
                "state_code": "58"
            },
            {
                "id": 682,
                "name": "Oslomej Municipality",
                "state_code": "57"
            },
            {
                "id": 685,
                "name": "Pehčevo Municipality",
                "state_code": "60"
            },
            {
                "id": 698,
                "name": "Petrovec Municipality",
                "state_code": "59"
            },
            {
                "id": 670,
                "name": "Plasnica Municipality",
                "state_code": "61"
            },
            {
                "id": 666,
                "name": "Prilep Municipality",
                "state_code": "62"
            },
            {
                "id": 646,
                "name": "Probištip Municipality",
                "state_code": "63"
            },
            {
                "id": 709,
                "name": "Radoviš Municipality",
                "state_code": "64"
            },
            {
                "id": 717,
                "name": "Rankovce Municipality",
                "state_code": "65"
            },
            {
                "id": 712,
                "name": "Resen Municipality",
                "state_code": "66"
            },
            {
                "id": 691,
                "name": "Rosoman Municipality",
                "state_code": "67"
            },
            {
                "id": 667,
                "name": "Saraj Municipality",
                "state_code": "68"
            },
            {
                "id": 719,
                "name": "Sopište Municipality",
                "state_code": "70"
            },
            {
                "id": 643,
                "name": "Staro Nagoričane Municipality",
                "state_code": "71"
            },
            {
                "id": 661,
                "name": "Štip Municipality",
                "state_code": "83"
            },
            {
                "id": 700,
                "name": "Struga Municipality",
                "state_code": "72"
            },
            {
                "id": 710,
                "name": "Strumica Municipality",
                "state_code": "73"
            },
            {
                "id": 711,
                "name": "Studeničani Municipality",
                "state_code": "74"
            },
            {
                "id": 680,
                "name": "Šuto Orizari Municipality",
                "state_code": "84"
            },
            {
                "id": 640,
                "name": "Sveti Nikole Municipality",
                "state_code": "69"
            },
            {
                "id": 654,
                "name": "Tearce Municipality",
                "state_code": "75"
            },
            {
                "id": 663,
                "name": "Tetovo Municipality",
                "state_code": "76"
            },
            {
                "id": 671,
                "name": "Valandovo Municipality",
                "state_code": "10"
            },
            {
                "id": 658,
                "name": "Vasilevo Municipality",
                "state_code": "11"
            },
            {
                "id": 651,
                "name": "Veles Municipality",
                "state_code": "13"
            },
            {
                "id": 662,
                "name": "Vevčani Municipality",
                "state_code": "12"
            },
            {
                "id": 672,
                "name": "Vinica Municipality",
                "state_code": "14"
            },
            {
                "id": 650,
                "name": "Vraneštica Municipality",
                "state_code": "15"
            },
            {
                "id": 689,
                "name": "Vrapčište Municipality",
                "state_code": "16"
            },
            {
                "id": 642,
                "name": "Zajas Municipality",
                "state_code": "31"
            },
            {
                "id": 706,
                "name": "Zelenikovo Municipality",
                "state_code": "32"
            },
            {
                "id": 668,
                "name": "Želino Municipality",
                "state_code": "30"
            },
            {
                "id": 673,
                "name": "Zrnovci Municipality",
                "state_code": "33"
            }
        ]
    },
    {
        "name": "Madagascar",
        "iso3": "MDG",
        "iso2": "MG",
        "phone_code": "261",
        "capital": "Antananarivo",
        "currency": "MGA",
        "currency_symbol": "Ar",
        "tld": ".mg",
        "native": "Madagasikara",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Indian/Antananarivo",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "EAT",
                "tzName": "East Africa Time"
            }
        ],
        "translations": {
            "br": "Madagascar",
            "pt": "Madagáscar",
            "nl": "Madagaskar",
            "hr": "Madagaskar",
            "fa": "ماداگاسکار",
            "de": "Madagaskar",
            "es": "Madagascar",
            "fr": "Madagascar",
            "ja": "マダガスカル",
            "it": "Madagascar"
        },
        "latitude": "-20.00000000",
        "longitude": "47.00000000",
        "emoji": "🇲🇬",
        "emojiU": "U+1F1F2 U+1F1EC",
        "states": [
            {
                "id": 2951,
                "name": "Antananarivo Province",
                "state_code": "T"
            },
            {
                "id": 2950,
                "name": "Antsiranana Province",
                "state_code": "D"
            },
            {
                "id": 2948,
                "name": "Fianarantsoa Province",
                "state_code": "F"
            },
            {
                "id": 2953,
                "name": "Mahajanga Province",
                "state_code": "M"
            },
            {
                "id": 2952,
                "name": "Toamasina Province",
                "state_code": "A"
            },
            {
                "id": 2949,
                "name": "Toliara Province",
                "state_code": "U"
            }
        ]
    },
    {
        "name": "Malawi",
        "iso3": "MWI",
        "iso2": "MW",
        "phone_code": "265",
        "capital": "Lilongwe",
        "currency": "MWK",
        "currency_symbol": "MK",
        "tld": ".mw",
        "native": "Malawi",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa/Blantyre",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "CAT",
                "tzName": "Central Africa Time"
            }
        ],
        "translations": {
            "br": "Malawi",
            "pt": "Malávi",
            "nl": "Malawi",
            "hr": "Malavi",
            "fa": "مالاوی",
            "de": "Malawi",
            "es": "Malawi",
            "fr": "Malawi",
            "ja": "マラウイ",
            "it": "Malawi"
        },
        "latitude": "-13.50000000",
        "longitude": "34.00000000",
        "emoji": "🇲🇼",
        "emojiU": "U+1F1F2 U+1F1FC",
        "states": [
            {
                "id": 3096,
                "name": "Balaka District",
                "state_code": "BA"
            },
            {
                "id": 3102,
                "name": "Blantyre District",
                "state_code": "BL"
            },
            {
                "id": 3092,
                "name": "Central Region",
                "state_code": "C"
            },
            {
                "id": 3107,
                "name": "Chikwawa District",
                "state_code": "CK"
            },
            {
                "id": 3109,
                "name": "Chiradzulu District",
                "state_code": "CR"
            },
            {
                "id": 3087,
                "name": "Chitipa district",
                "state_code": "CT"
            },
            {
                "id": 3097,
                "name": "Dedza District",
                "state_code": "DE"
            },
            {
                "id": 3090,
                "name": "Dowa District",
                "state_code": "DO"
            },
            {
                "id": 3091,
                "name": "Karonga District",
                "state_code": "KR"
            },
            {
                "id": 3094,
                "name": "Kasungu District",
                "state_code": "KS"
            },
            {
                "id": 3093,
                "name": "Likoma District",
                "state_code": "LK"
            },
            {
                "id": 3101,
                "name": "Lilongwe District",
                "state_code": "LI"
            },
            {
                "id": 3082,
                "name": "Machinga District",
                "state_code": "MH"
            },
            {
                "id": 3110,
                "name": "Mangochi District",
                "state_code": "MG"
            },
            {
                "id": 3099,
                "name": "Mchinji District",
                "state_code": "MC"
            },
            {
                "id": 3103,
                "name": "Mulanje District",
                "state_code": "MU"
            },
            {
                "id": 3084,
                "name": "Mwanza District",
                "state_code": "MW"
            },
            {
                "id": 3104,
                "name": "Mzimba District",
                "state_code": "MZ"
            },
            {
                "id": 3095,
                "name": "Nkhata Bay District",
                "state_code": "NB"
            },
            {
                "id": 3100,
                "name": "Nkhotakota District",
                "state_code": "NK"
            },
            {
                "id": 3105,
                "name": "Northern Region",
                "state_code": "N"
            },
            {
                "id": 3085,
                "name": "Nsanje District",
                "state_code": "NS"
            },
            {
                "id": 3088,
                "name": "Ntcheu District",
                "state_code": "NU"
            },
            {
                "id": 3111,
                "name": "Ntchisi District",
                "state_code": "NI"
            },
            {
                "id": 3108,
                "name": "Phalombe District",
                "state_code": "PH"
            },
            {
                "id": 3089,
                "name": "Rumphi District",
                "state_code": "RU"
            },
            {
                "id": 3086,
                "name": "Salima District",
                "state_code": "SA"
            },
            {
                "id": 3106,
                "name": "Southern Region",
                "state_code": "S"
            },
            {
                "id": 3098,
                "name": "Thyolo District",
                "state_code": "TH"
            },
            {
                "id": 3083,
                "name": "Zomba District",
                "state_code": "ZO"
            }
        ]
    },
    {
        "name": "Malaysia",
        "iso3": "MYS",
        "iso2": "MY",
        "phone_code": "60",
        "capital": "Kuala Lumpur",
        "currency": "MYR",
        "currency_symbol": "RM",
        "tld": ".my",
        "native": "Malaysia",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Kuala_Lumpur",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "MYT",
                "tzName": "Malaysia Time"
            },
            {
                "zoneName": "Asia/Kuching",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "MYT",
                "tzName": "Malaysia Time"
            }
        ],
        "translations": {
            "br": "Malásia",
            "pt": "Malásia",
            "nl": "Maleisië",
            "hr": "Malezija",
            "fa": "مالزی",
            "de": "Malaysia",
            "es": "Malasia",
            "fr": "Malaisie",
            "ja": "マレーシア",
            "it": "Malesia"
        },
        "latitude": "2.50000000",
        "longitude": "112.50000000",
        "emoji": "🇲🇾",
        "emojiU": "U+1F1F2 U+1F1FE",
        "states": [
            {
                "id": 1950,
                "name": "Johor",
                "state_code": "01"
            },
            {
                "id": 1947,
                "name": "Kedah",
                "state_code": "02"
            },
            {
                "id": 1946,
                "name": "Kelantan",
                "state_code": "03"
            },
            {
                "id": 1949,
                "name": "Kuala Lumpur",
                "state_code": "14"
            },
            {
                "id": 1935,
                "name": "Labuan",
                "state_code": "15"
            },
            {
                "id": 1941,
                "name": "Malacca",
                "state_code": "04"
            },
            {
                "id": 1948,
                "name": "Negeri Sembilan",
                "state_code": "05"
            },
            {
                "id": 1940,
                "name": "Pahang",
                "state_code": "06"
            },
            {
                "id": 1939,
                "name": "Penang",
                "state_code": "07"
            },
            {
                "id": 1943,
                "name": "Perak",
                "state_code": "08"
            },
            {
                "id": 1938,
                "name": "Perlis",
                "state_code": "09"
            },
            {
                "id": 1945,
                "name": "Putrajaya",
                "state_code": "16"
            },
            {
                "id": 1936,
                "name": "Sabah",
                "state_code": "12"
            },
            {
                "id": 1937,
                "name": "Sarawak",
                "state_code": "13"
            },
            {
                "id": 1944,
                "name": "Selangor",
                "state_code": "10"
            },
            {
                "id": 1942,
                "name": "Terengganu",
                "state_code": "11"
            }
        ]
    },
    {
        "name": "Maldives",
        "iso3": "MDV",
        "iso2": "MV",
        "phone_code": "960",
        "capital": "Male",
        "currency": "MVR",
        "currency_symbol": "Rf",
        "tld": ".mv",
        "native": "Maldives",
        "region": "Asia",
        "subregion": "Southern Asia",
        "timezones": [
            {
                "zoneName": "Indian/Maldives",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "MVT",
                "tzName": "Maldives Time"
            }
        ],
        "translations": {
            "br": "Maldivas",
            "pt": "Maldivas",
            "nl": "Maldiven",
            "hr": "Maldivi",
            "fa": "مالدیو",
            "de": "Malediven",
            "es": "Maldivas",
            "fr": "Maldives",
            "ja": "モルディブ",
            "it": "Maldive"
        },
        "latitude": "3.25000000",
        "longitude": "73.00000000",
        "emoji": "🇲🇻",
        "emojiU": "U+1F1F2 U+1F1FB",
        "states": [
            {
                "id": 2594,
                "name": "Addu Atoll",
                "state_code": "01"
            },
            {
                "id": 2587,
                "name": "Alif Alif Atoll",
                "state_code": "02"
            },
            {
                "id": 2600,
                "name": "Alif Dhaal Atoll",
                "state_code": "00"
            },
            {
                "id": 2604,
                "name": "Central Province",
                "state_code": "CE"
            },
            {
                "id": 2590,
                "name": "Dhaalu Atoll",
                "state_code": "17"
            },
            {
                "id": 2599,
                "name": "Faafu Atoll",
                "state_code": "14"
            },
            {
                "id": 2598,
                "name": "Gaafu Alif Atoll",
                "state_code": "27"
            },
            {
                "id": 2603,
                "name": "Gaafu Dhaalu Atoll",
                "state_code": "28"
            },
            {
                "id": 2595,
                "name": "Gnaviyani Atoll",
                "state_code": "29"
            },
            {
                "id": 2586,
                "name": "Haa Alif Atoll",
                "state_code": "07"
            },
            {
                "id": 2597,
                "name": "Haa Dhaalu Atoll",
                "state_code": "23"
            },
            {
                "id": 2596,
                "name": "Kaafu Atoll",
                "state_code": "26"
            },
            {
                "id": 2601,
                "name": "Laamu Atoll",
                "state_code": "05"
            },
            {
                "id": 2607,
                "name": "Lhaviyani Atoll",
                "state_code": "03"
            },
            {
                "id": 2609,
                "name": "Malé",
                "state_code": "MLE"
            },
            {
                "id": 2608,
                "name": "Meemu Atoll",
                "state_code": "12"
            },
            {
                "id": 2592,
                "name": "Noonu Atoll",
                "state_code": "25"
            },
            {
                "id": 2589,
                "name": "North Central Province",
                "state_code": "NC"
            },
            {
                "id": 2588,
                "name": "North Province",
                "state_code": "NO"
            },
            {
                "id": 2602,
                "name": "Raa Atoll",
                "state_code": "13"
            },
            {
                "id": 2585,
                "name": "Shaviyani Atoll",
                "state_code": "24"
            },
            {
                "id": 2606,
                "name": "South Central Province",
                "state_code": "SC"
            },
            {
                "id": 2605,
                "name": "South Province",
                "state_code": "SU"
            },
            {
                "id": 2591,
                "name": "Thaa Atoll",
                "state_code": "08"
            },
            {
                "id": 2593,
                "name": "Upper South Province",
                "state_code": "US"
            },
            {
                "id": 2584,
                "name": "Vaavu Atoll",
                "state_code": "04"
            }
        ]
    },
    {
        "name": "Mali",
        "iso3": "MLI",
        "iso2": "ML",
        "phone_code": "223",
        "capital": "Bamako",
        "currency": "XOF",
        "currency_symbol": "CFA",
        "tld": ".ml",
        "native": "Mali",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa/Bamako",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "translations": {
            "br": "Mali",
            "pt": "Mali",
            "nl": "Mali",
            "hr": "Mali",
            "fa": "مالی",
            "de": "Mali",
            "es": "Mali",
            "fr": "Mali",
            "ja": "マリ",
            "it": "Mali"
        },
        "latitude": "17.00000000",
        "longitude": "-4.00000000",
        "emoji": "🇲🇱",
        "emojiU": "U+1F1F2 U+1F1F1",
        "states": [
            {
                "id": 253,
                "name": "Bamako",
                "state_code": "BKO"
            },
            {
                "id": 258,
                "name": "Gao Region",
                "state_code": "7"
            },
            {
                "id": 252,
                "name": "Kayes Region",
                "state_code": "1"
            },
            {
                "id": 257,
                "name": "Kidal Region",
                "state_code": "8"
            },
            {
                "id": 250,
                "name": "Koulikoro Region",
                "state_code": "2"
            },
            {
                "id": 251,
                "name": "Ménaka Region",
                "state_code": "9"
            },
            {
                "id": 255,
                "name": "Mopti Region",
                "state_code": "5"
            },
            {
                "id": 249,
                "name": "Ségou Region",
                "state_code": "4"
            },
            {
                "id": 254,
                "name": "Sikasso Region",
                "state_code": "3"
            },
            {
                "id": 256,
                "name": "Taoudénit Region",
                "state_code": "10"
            },
            {
                "id": 248,
                "name": "Tombouctou Region",
                "state_code": "6"
            }
        ]
    },
    {
        "name": "Malta",
        "iso3": "MLT",
        "iso2": "MT",
        "phone_code": "356",
        "capital": "Valletta",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".mt",
        "native": "Malta",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Malta",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Malta",
            "pt": "Malta",
            "nl": "Malta",
            "hr": "Malta",
            "fa": "مالت",
            "de": "Malta",
            "es": "Malta",
            "fr": "Malte",
            "ja": "マルタ",
            "it": "Malta"
        },
        "latitude": "35.83333333",
        "longitude": "14.58333333",
        "emoji": "🇲🇹",
        "emojiU": "U+1F1F2 U+1F1F9",
        "states": [
            {
                "id": 110,
                "name": "Attard",
                "state_code": "01"
            },
            {
                "id": 108,
                "name": "Balzan",
                "state_code": "02"
            },
            {
                "id": 107,
                "name": "Birgu",
                "state_code": "03"
            },
            {
                "id": 97,
                "name": "Birkirkara",
                "state_code": "04"
            },
            {
                "id": 88,
                "name": "Birżebbuġa",
                "state_code": "05"
            },
            {
                "id": 138,
                "name": "Cospicua",
                "state_code": "06"
            },
            {
                "id": 117,
                "name": "Dingli",
                "state_code": "07"
            },
            {
                "id": 129,
                "name": "Fgura",
                "state_code": "08"
            },
            {
                "id": 84,
                "name": "Floriana",
                "state_code": "09"
            },
            {
                "id": 134,
                "name": "Fontana",
                "state_code": "10"
            },
            {
                "id": 130,
                "name": "Għajnsielem",
                "state_code": "13"
            },
            {
                "id": 92,
                "name": "Għarb",
                "state_code": "14"
            },
            {
                "id": 120,
                "name": "Għargħur",
                "state_code": "15"
            },
            {
                "id": 106,
                "name": "Għasri",
                "state_code": "16"
            },
            {
                "id": 124,
                "name": "Għaxaq",
                "state_code": "17"
            },
            {
                "id": 118,
                "name": "Gudja",
                "state_code": "11"
            },
            {
                "id": 113,
                "name": "Gżira",
                "state_code": "12"
            },
            {
                "id": 105,
                "name": "Ħamrun",
                "state_code": "18"
            },
            {
                "id": 93,
                "name": "Iklin",
                "state_code": "19"
            },
            {
                "id": 99,
                "name": "Kalkara",
                "state_code": "21"
            },
            {
                "id": 91,
                "name": "Kerċem",
                "state_code": "22"
            },
            {
                "id": 82,
                "name": "Kirkop",
                "state_code": "23"
            },
            {
                "id": 126,
                "name": "Lija",
                "state_code": "24"
            },
            {
                "id": 77,
                "name": "Luqa",
                "state_code": "25"
            },
            {
                "id": 128,
                "name": "Marsa",
                "state_code": "26"
            },
            {
                "id": 137,
                "name": "Marsaskala",
                "state_code": "27"
            },
            {
                "id": 78,
                "name": "Marsaxlokk",
                "state_code": "28"
            },
            {
                "id": 89,
                "name": "Mdina",
                "state_code": "29"
            },
            {
                "id": 102,
                "name": "Mellieħa",
                "state_code": "30"
            },
            {
                "id": 109,
                "name": "Mġarr",
                "state_code": "31"
            },
            {
                "id": 140,
                "name": "Mosta",
                "state_code": "32"
            },
            {
                "id": 74,
                "name": "Mqabba",
                "state_code": "33"
            },
            {
                "id": 96,
                "name": "Msida",
                "state_code": "34"
            },
            {
                "id": 131,
                "name": "Mtarfa",
                "state_code": "35"
            },
            {
                "id": 132,
                "name": "Munxar",
                "state_code": "36"
            },
            {
                "id": 133,
                "name": "Nadur",
                "state_code": "37"
            },
            {
                "id": 112,
                "name": "Naxxar",
                "state_code": "38"
            },
            {
                "id": 115,
                "name": "Paola",
                "state_code": "39"
            },
            {
                "id": 125,
                "name": "Pembroke",
                "state_code": "40"
            },
            {
                "id": 127,
                "name": "Pietà",
                "state_code": "41"
            },
            {
                "id": 79,
                "name": "Qala",
                "state_code": "42"
            },
            {
                "id": 119,
                "name": "Qormi",
                "state_code": "43"
            },
            {
                "id": 111,
                "name": "Qrendi",
                "state_code": "44"
            },
            {
                "id": 83,
                "name": "Rabat",
                "state_code": "46"
            },
            {
                "id": 87,
                "name": "Saint Lawrence",
                "state_code": "50"
            },
            {
                "id": 75,
                "name": "San Ġwann",
                "state_code": "49"
            },
            {
                "id": 116,
                "name": "Sannat",
                "state_code": "52"
            },
            {
                "id": 94,
                "name": "Santa Luċija",
                "state_code": "53"
            },
            {
                "id": 90,
                "name": "Santa Venera",
                "state_code": "54"
            },
            {
                "id": 136,
                "name": "Senglea",
                "state_code": "20"
            },
            {
                "id": 98,
                "name": "Siġġiewi",
                "state_code": "55"
            },
            {
                "id": 104,
                "name": "Sliema",
                "state_code": "56"
            },
            {
                "id": 100,
                "name": "St. Julian's",
                "state_code": "48"
            },
            {
                "id": 139,
                "name": "St. Paul's Bay",
                "state_code": "51"
            },
            {
                "id": 86,
                "name": "Swieqi",
                "state_code": "57"
            },
            {
                "id": 122,
                "name": "Ta' Xbiex",
                "state_code": "58"
            },
            {
                "id": 103,
                "name": "Tarxien",
                "state_code": "59"
            },
            {
                "id": 95,
                "name": "Valletta",
                "state_code": "60"
            },
            {
                "id": 101,
                "name": "Victoria",
                "state_code": "45"
            },
            {
                "id": 114,
                "name": "Xagħra",
                "state_code": "61"
            },
            {
                "id": 121,
                "name": "Xewkija",
                "state_code": "62"
            },
            {
                "id": 81,
                "name": "Xgħajra",
                "state_code": "63"
            },
            {
                "id": 123,
                "name": "Żabbar",
                "state_code": "64"
            },
            {
                "id": 85,
                "name": "Żebbuġ Gozo",
                "state_code": "65"
            },
            {
                "id": 80,
                "name": "Żebbuġ Malta",
                "state_code": "66"
            },
            {
                "id": 135,
                "name": "Żejtun",
                "state_code": "67"
            },
            {
                "id": 76,
                "name": "Żurrieq",
                "state_code": "68"
            }
        ]
    },
    {
        "name": "Man (Isle of)",
        "iso3": "IMN",
        "iso2": "IM",
        "phone_code": "+44-1624",
        "capital": "Douglas, Isle of Man",
        "currency": "GBP",
        "currency_symbol": "£",
        "tld": ".im",
        "native": "Isle of Man",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Isle_of_Man",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "translations": {
            "br": "Ilha de Man",
            "pt": "Ilha de Man",
            "nl": "Isle of Man",
            "hr": "Otok Man",
            "fa": "جزیره من",
            "de": "Insel Man",
            "es": "Isla de Man",
            "fr": "Île de Man",
            "ja": "マン島",
            "it": "Isola di Man"
        },
        "latitude": "54.25000000",
        "longitude": "-4.50000000",
        "emoji": "🇮🇲",
        "emojiU": "U+1F1EE U+1F1F2",
        "states": []
    },
    {
        "name": "Marshall Islands",
        "iso3": "MHL",
        "iso2": "MH",
        "phone_code": "692",
        "capital": "Majuro",
        "currency": "USD",
        "currency_symbol": "$",
        "tld": ".mh",
        "native": "M̧ajeļ",
        "region": "Oceania",
        "subregion": "Micronesia",
        "timezones": [
            {
                "zoneName": "Pacific/Kwajalein",
                "gmtOffset": 43200,
                "gmtOffsetName": "UTC+12:00",
                "abbreviation": "MHT",
                "tzName": "Marshall Islands Time"
            },
            {
                "zoneName": "Pacific/Majuro",
                "gmtOffset": 43200,
                "gmtOffsetName": "UTC+12:00",
                "abbreviation": "MHT",
                "tzName": "Marshall Islands Time"
            }
        ],
        "translations": {
            "br": "Ilhas Marshall",
            "pt": "Ilhas Marshall",
            "nl": "Marshalleilanden",
            "hr": "Maršalovi Otoci",
            "fa": "جزایر مارشال",
            "de": "Marshallinseln",
            "es": "Islas Marshall",
            "fr": "Îles Marshall",
            "ja": "マーシャル諸島",
            "it": "Isole Marshall"
        },
        "latitude": "9.00000000",
        "longitude": "168.00000000",
        "emoji": "🇲🇭",
        "emojiU": "U+1F1F2 U+1F1ED",
        "states": [
            {
                "id": 2574,
                "name": "Ralik Chain",
                "state_code": "L"
            },
            {
                "id": 2573,
                "name": "Ratak Chain",
                "state_code": "T"
            }
        ]
    },
    {
        "name": "Martinique",
        "iso3": "MTQ",
        "iso2": "MQ",
        "phone_code": "596",
        "capital": "Fort-de-France",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".mq",
        "native": "Martinique",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/Martinique",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "translations": {
            "br": "Martinica",
            "pt": "Martinica",
            "nl": "Martinique",
            "hr": "Martinique",
            "fa": "مونتسرات",
            "de": "Martinique",
            "es": "Martinica",
            "fr": "Martinique",
            "ja": "マルティニーク",
            "it": "Martinica"
        },
        "latitude": "14.66666700",
        "longitude": "-61.00000000",
        "emoji": "🇲🇶",
        "emojiU": "U+1F1F2 U+1F1F6",
        "states": []
    },
    {
        "name": "Mauritania",
        "iso3": "MRT",
        "iso2": "MR",
        "phone_code": "222",
        "capital": "Nouakchott",
        "currency": "MRO",
        "currency_symbol": "MRU",
        "tld": ".mr",
        "native": "موريتانيا",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa/Nouakchott",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "translations": {
            "br": "Mauritânia",
            "pt": "Mauritânia",
            "nl": "Mauritanië",
            "hr": "Mauritanija",
            "fa": "موریتانی",
            "de": "Mauretanien",
            "es": "Mauritania",
            "fr": "Mauritanie",
            "ja": "モーリタニア",
            "it": "Mauritania"
        },
        "latitude": "20.00000000",
        "longitude": "-12.00000000",
        "emoji": "🇲🇷",
        "emojiU": "U+1F1F2 U+1F1F7",
        "states": [
            {
                "id": 3344,
                "name": "Adrar Region",
                "state_code": "07"
            },
            {
                "id": 3349,
                "name": "Assaba Region",
                "state_code": "03"
            },
            {
                "id": 3339,
                "name": "Brakna Region",
                "state_code": "05"
            },
            {
                "id": 3346,
                "name": "Dakhlet Nouadhibou",
                "state_code": "08"
            },
            {
                "id": 3341,
                "name": "Gorgol Region",
                "state_code": "04"
            },
            {
                "id": 3350,
                "name": "Guidimaka Region",
                "state_code": "10"
            },
            {
                "id": 3338,
                "name": "Hodh Ech Chargui Region",
                "state_code": "01"
            },
            {
                "id": 3351,
                "name": "Hodh El Gharbi Region",
                "state_code": "02"
            },
            {
                "id": 3342,
                "name": "Inchiri Region",
                "state_code": "12"
            },
            {
                "id": 3343,
                "name": "Nouakchott-Nord Region",
                "state_code": "14"
            },
            {
                "id": 3352,
                "name": "Nouakchott-Ouest Region",
                "state_code": "13"
            },
            {
                "id": 3347,
                "name": "Nouakchott-Sud Region",
                "state_code": "15"
            },
            {
                "id": 3345,
                "name": "Tagant Region",
                "state_code": "09"
            },
            {
                "id": 3340,
                "name": "Tiris Zemmour Region",
                "state_code": "11"
            },
            {
                "id": 3348,
                "name": "Trarza Region",
                "state_code": "06"
            }
        ]
    },
    {
        "name": "Mauritius",
        "iso3": "MUS",
        "iso2": "MU",
        "phone_code": "230",
        "capital": "Port Louis",
        "currency": "MUR",
        "currency_symbol": "₨",
        "tld": ".mu",
        "native": "Maurice",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Indian/Mauritius",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "MUT",
                "tzName": "Mauritius Time"
            }
        ],
        "translations": {
            "br": "Maurício",
            "pt": "Maurícia",
            "nl": "Mauritius",
            "hr": "Mauricijus",
            "fa": "موریس",
            "de": "Mauritius",
            "es": "Mauricio",
            "fr": "Île Maurice",
            "ja": "モーリシャス",
            "it": "Mauritius"
        },
        "latitude": "-20.28333333",
        "longitude": "57.55000000",
        "emoji": "🇲🇺",
        "emojiU": "U+1F1F2 U+1F1FA",
        "states": [
            {
                "id": 3248,
                "name": "Agaléga",
                "state_code": "AG"
            },
            {
                "id": 3262,
                "name": "Beau Bassin-Rose Hill",
                "state_code": "BR"
            },
            {
                "id": 3251,
                "name": "Cargados Carajos",
                "state_code": "CC"
            },
            {
                "id": 3255,
                "name": "Curepipe",
                "state_code": "CU"
            },
            {
                "id": 3254,
                "name": "Flacq District",
                "state_code": "FL"
            },
            {
                "id": 3264,
                "name": "Grand Port District",
                "state_code": "GP"
            },
            {
                "id": 3253,
                "name": "Moka District",
                "state_code": "MO"
            },
            {
                "id": 3250,
                "name": "Pamplemousses District",
                "state_code": "PA"
            },
            {
                "id": 3263,
                "name": "Plaines Wilhems District",
                "state_code": "PW"
            },
            {
                "id": 3256,
                "name": "Port Louis",
                "state_code": "PU"
            },
            {
                "id": 3260,
                "name": "Port Louis District",
                "state_code": "PL"
            },
            {
                "id": 3258,
                "name": "Quatre Bornes",
                "state_code": "QB"
            },
            {
                "id": 3261,
                "name": "Rivière du Rempart District",
                "state_code": "RR"
            },
            {
                "id": 3259,
                "name": "Rivière Noire District",
                "state_code": "BL"
            },
            {
                "id": 3249,
                "name": "Rodrigues",
                "state_code": "RO"
            },
            {
                "id": 3257,
                "name": "Savanne District",
                "state_code": "SA"
            },
            {
                "id": 3252,
                "name": "Vacoas-Phoenix",
                "state_code": "VP"
            }
        ]
    },
    {
        "name": "Mayotte",
        "iso3": "MYT",
        "iso2": "YT",
        "phone_code": "262",
        "capital": "Mamoudzou",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".yt",
        "native": "Mayotte",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Indian/Mayotte",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "EAT",
                "tzName": "East Africa Time"
            }
        ],
        "translations": {
            "br": "Mayotte",
            "pt": "Mayotte",
            "nl": "Mayotte",
            "hr": "Mayotte",
            "fa": "مایوت",
            "de": "Mayotte",
            "es": "Mayotte",
            "fr": "Mayotte",
            "ja": "マヨット",
            "it": "Mayotte"
        },
        "latitude": "-12.83333333",
        "longitude": "45.16666666",
        "emoji": "🇾🇹",
        "emojiU": "U+1F1FE U+1F1F9",
        "states": []
    },
    {
        "name": "Mexico",
        "iso3": "MEX",
        "iso2": "MX",
        "phone_code": "52",
        "capital": "Mexico City",
        "currency": "MXN",
        "currency_symbol": "$",
        "tld": ".mx",
        "native": "México",
        "region": "Americas",
        "subregion": "Central America",
        "timezones": [
            {
                "zoneName": "America/Bahia_Banderas",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America/Cancun",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America/Chihuahua",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America"
            },
            {
                "zoneName": "America/Hermosillo",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America"
            },
            {
                "zoneName": "America/Matamoros",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America/Mazatlan",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America"
            },
            {
                "zoneName": "America/Merida",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America/Mexico_City",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America/Monterrey",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America/Ojinaga",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America"
            },
            {
                "zoneName": "America/Tijuana",
                "gmtOffset": -28800,
                "gmtOffsetName": "UTC-08:00",
                "abbreviation": "PST",
                "tzName": "Pacific Standard Time (North America"
            }
        ],
        "translations": {
            "br": "México",
            "pt": "México",
            "nl": "Mexico",
            "hr": "Meksiko",
            "fa": "مکزیک",
            "de": "Mexiko",
            "es": "México",
            "fr": "Mexique",
            "ja": "メキシコ",
            "it": "Messico"
        },
        "latitude": "23.00000000",
        "longitude": "-102.00000000",
        "emoji": "🇲🇽",
        "emojiU": "U+1F1F2 U+1F1FD",
        "states": [
            {
                "id": 3456,
                "name": "Aguascalientes",
                "state_code": "AGU"
            },
            {
                "id": 3457,
                "name": "Baja California",
                "state_code": "BCN"
            },
            {
                "id": 3460,
                "name": "Baja California Sur",
                "state_code": "BCS"
            },
            {
                "id": 3475,
                "name": "Campeche",
                "state_code": "CAM"
            },
            {
                "id": 3451,
                "name": "Chiapas",
                "state_code": "CHP"
            },
            {
                "id": 3447,
                "name": "Chihuahua",
                "state_code": "CHH"
            },
            {
                "id": 3471,
                "name": "Coahuila",
                "state_code": "COA"
            },
            {
                "id": 3472,
                "name": "Colima",
                "state_code": "COL"
            },
            {
                "id": 3453,
                "name": "Durango",
                "state_code": "DUR"
            },
            {
                "id": 3469,
                "name": "Guanajuato",
                "state_code": "GUA"
            },
            {
                "id": 3459,
                "name": "Guerrero",
                "state_code": "GRO"
            },
            {
                "id": 3470,
                "name": "Hidalgo",
                "state_code": "HID"
            },
            {
                "id": 4857,
                "name": "Jalisco",
                "state_code": "JAL"
            },
            {
                "id": 3450,
                "name": "México",
                "state_code": "MEX"
            },
            {
                "id": 3473,
                "name": "Mexico City",
                "state_code": "CMX"
            },
            {
                "id": 3474,
                "name": "Michoacán",
                "state_code": "MIC"
            },
            {
                "id": 3465,
                "name": "Morelos",
                "state_code": "MOR"
            },
            {
                "id": 3477,
                "name": "Nayarit",
                "state_code": "NAY"
            },
            {
                "id": 3452,
                "name": "Nuevo León",
                "state_code": "NLE"
            },
            {
                "id": 3448,
                "name": "Oaxaca",
                "state_code": "OAX"
            },
            {
                "id": 3476,
                "name": "Puebla",
                "state_code": "PUE"
            },
            {
                "id": 3455,
                "name": "Querétaro",
                "state_code": "QUE"
            },
            {
                "id": 3467,
                "name": "Quintana Roo",
                "state_code": "ROO"
            },
            {
                "id": 3461,
                "name": "San Luis Potosí",
                "state_code": "SLP"
            },
            {
                "id": 3449,
                "name": "Sinaloa",
                "state_code": "SIN"
            },
            {
                "id": 3468,
                "name": "Sonora",
                "state_code": "SON"
            },
            {
                "id": 3454,
                "name": "Tabasco",
                "state_code": "TAB"
            },
            {
                "id": 3463,
                "name": "Tamaulipas",
                "state_code": "TAM"
            },
            {
                "id": 3458,
                "name": "Tlaxcala",
                "state_code": "TLA"
            },
            {
                "id": 3464,
                "name": "Veracruz",
                "state_code": "VER"
            },
            {
                "id": 3466,
                "name": "Yucatán",
                "state_code": "YUC"
            },
            {
                "id": 3462,
                "name": "Zacatecas",
                "state_code": "ZAC"
            }
        ]
    },
    {
        "name": "Micronesia",
        "iso3": "FSM",
        "iso2": "FM",
        "phone_code": "691",
        "capital": "Palikir",
        "currency": "USD",
        "currency_symbol": "$",
        "tld": ".fm",
        "native": "Micronesia",
        "region": "Oceania",
        "subregion": "Micronesia",
        "timezones": [
            {
                "zoneName": "Pacific/Chuuk",
                "gmtOffset": 36000,
                "gmtOffsetName": "UTC+10:00",
                "abbreviation": "CHUT",
                "tzName": "Chuuk Time"
            },
            {
                "zoneName": "Pacific/Kosrae",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "KOST",
                "tzName": "Kosrae Time"
            },
            {
                "zoneName": "Pacific/Pohnpei",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "PONT",
                "tzName": "Pohnpei Standard Time"
            }
        ],
        "translations": {
            "br": "Micronésia",
            "pt": "Micronésia",
            "nl": "Micronesië",
            "hr": "Mikronezija",
            "fa": "ایالات فدرال میکرونزی",
            "de": "Mikronesien",
            "es": "Micronesia",
            "fr": "Micronésie",
            "ja": "ミクロネシア連邦",
            "it": "Micronesia"
        },
        "latitude": "6.91666666",
        "longitude": "158.25000000",
        "emoji": "🇫🇲",
        "emojiU": "U+1F1EB U+1F1F2",
        "states": [
            {
                "id": 2580,
                "name": "Chuuk State",
                "state_code": "TRK"
            },
            {
                "id": 2583,
                "name": "Kosrae State",
                "state_code": "KSA"
            },
            {
                "id": 2581,
                "name": "Pohnpei State",
                "state_code": "PNI"
            },
            {
                "id": 2582,
                "name": "Yap State",
                "state_code": "YAP"
            }
        ]
    },
    {
        "name": "Moldova",
        "iso3": "MDA",
        "iso2": "MD",
        "phone_code": "373",
        "capital": "Chisinau",
        "currency": "MDL",
        "currency_symbol": "L",
        "tld": ".md",
        "native": "Moldova",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Chisinau",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "translations": {
            "br": "Moldávia",
            "pt": "Moldávia",
            "nl": "Moldavië",
            "hr": "Moldova",
            "fa": "مولداوی",
            "de": "Moldawie",
            "es": "Moldavia",
            "fr": "Moldavie",
            "ja": "モルドバ共和国",
            "it": "Moldavia"
        },
        "latitude": "47.00000000",
        "longitude": "29.00000000",
        "emoji": "🇲🇩",
        "emojiU": "U+1F1F2 U+1F1E9",
        "states": [
            {
                "id": 4368,
                "name": "Anenii Noi District",
                "state_code": "AN"
            },
            {
                "id": 4393,
                "name": "Bălți Municipality",
                "state_code": "BA"
            },
            {
                "id": 4379,
                "name": "Basarabeasca District",
                "state_code": "BS"
            },
            {
                "id": 4362,
                "name": "Bender Municipality",
                "state_code": "BD"
            },
            {
                "id": 4375,
                "name": "Briceni District",
                "state_code": "BR"
            },
            {
                "id": 4391,
                "name": "Cahul District",
                "state_code": "CA"
            },
            {
                "id": 4366,
                "name": "Călărași District",
                "state_code": "CL"
            },
            {
                "id": 4380,
                "name": "Cantemir District",
                "state_code": "CT"
            },
            {
                "id": 4365,
                "name": "Căușeni District",
                "state_code": "CS"
            },
            {
                "id": 4373,
                "name": "Chișinău Municipality",
                "state_code": "CU"
            },
            {
                "id": 4360,
                "name": "Cimișlia District",
                "state_code": "CM"
            },
            {
                "id": 4390,
                "name": "Criuleni District",
                "state_code": "CR"
            },
            {
                "id": 4384,
                "name": "Dondușeni District",
                "state_code": "DO"
            },
            {
                "id": 4392,
                "name": "Drochia District",
                "state_code": "DR"
            },
            {
                "id": 4383,
                "name": "Dubăsari District",
                "state_code": "DU"
            },
            {
                "id": 4387,
                "name": "Edineț District",
                "state_code": "ED"
            },
            {
                "id": 4381,
                "name": "Fălești District",
                "state_code": "FA"
            },
            {
                "id": 4370,
                "name": "Florești District",
                "state_code": "FL"
            },
            {
                "id": 4385,
                "name": "Gagauzia",
                "state_code": "GA"
            },
            {
                "id": 4367,
                "name": "Glodeni District",
                "state_code": "GL"
            },
            {
                "id": 4382,
                "name": "Hîncești District",
                "state_code": "HI"
            },
            {
                "id": 4369,
                "name": "Ialoveni District",
                "state_code": "IA"
            },
            {
                "id": 4363,
                "name": "Nisporeni District",
                "state_code": "NI"
            },
            {
                "id": 4389,
                "name": "Ocnița District",
                "state_code": "OC"
            },
            {
                "id": 4361,
                "name": "Orhei District",
                "state_code": "OR"
            },
            {
                "id": 4394,
                "name": "Rezina District",
                "state_code": "RE"
            },
            {
                "id": 4376,
                "name": "Rîșcani District",
                "state_code": "RI"
            },
            {
                "id": 4364,
                "name": "Sîngerei District",
                "state_code": "SI"
            },
            {
                "id": 4388,
                "name": "Șoldănești District",
                "state_code": "SD"
            },
            {
                "id": 4374,
                "name": "Soroca District",
                "state_code": "SO"
            },
            {
                "id": 4378,
                "name": "Ștefan Vodă District",
                "state_code": "SV"
            },
            {
                "id": 4377,
                "name": "Strășeni District",
                "state_code": "ST"
            },
            {
                "id": 4372,
                "name": "Taraclia District",
                "state_code": "TA"
            },
            {
                "id": 4371,
                "name": "Telenești District",
                "state_code": "TE"
            },
            {
                "id": 4395,
                "name": "Transnistria autonomous territorial unit",
                "state_code": "SN"
            },
            {
                "id": 4386,
                "name": "Ungheni District",
                "state_code": "UN"
            }
        ]
    },
    {
        "name": "Monaco",
        "iso3": "MCO",
        "iso2": "MC",
        "phone_code": "377",
        "capital": "Monaco",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".mc",
        "native": "Monaco",
        "region": "Europe",
        "subregion": "Western Europe",
        "timezones": [
            {
                "zoneName": "Europe/Monaco",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Mônaco",
            "pt": "Mónaco",
            "nl": "Monaco",
            "hr": "Monako",
            "fa": "موناکو",
            "de": "Monaco",
            "es": "Mónaco",
            "fr": "Monaco",
            "ja": "モナコ",
            "it": "Principato di Monaco"
        },
        "latitude": "43.73333333",
        "longitude": "7.40000000",
        "emoji": "🇲🇨",
        "emojiU": "U+1F1F2 U+1F1E8",
        "states": [
            {
                "id": 4917,
                "name": "La Colle",
                "state_code": "CL"
            },
            {
                "id": 4918,
                "name": "La Condamine",
                "state_code": "CO"
            },
            {
                "id": 4919,
                "name": "Moneghetti",
                "state_code": "MG"
            }
        ]
    },
    {
        "name": "Mongolia",
        "iso3": "MNG",
        "iso2": "MN",
        "phone_code": "976",
        "capital": "Ulan Bator",
        "currency": "MNT",
        "currency_symbol": "₮",
        "tld": ".mn",
        "native": "Монгол улс",
        "region": "Asia",
        "subregion": "Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Choibalsan",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "CHOT",
                "tzName": "Choibalsan Standard Time"
            },
            {
                "zoneName": "Asia/Hovd",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "HOVT",
                "tzName": "Hovd Time"
            },
            {
                "zoneName": "Asia/Ulaanbaatar",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "ULAT",
                "tzName": "Ulaanbaatar Standard Time"
            }
        ],
        "translations": {
            "br": "Mongólia",
            "pt": "Mongólia",
            "nl": "Mongolië",
            "hr": "Mongolija",
            "fa": "مغولستان",
            "de": "Mongolei",
            "es": "Mongolia",
            "fr": "Mongolie",
            "ja": "モンゴル",
            "it": "Mongolia"
        },
        "latitude": "46.00000000",
        "longitude": "105.00000000",
        "emoji": "🇲🇳",
        "emojiU": "U+1F1F2 U+1F1F3",
        "states": [
            {
                "id": 1973,
                "name": "Arkhangai Province",
                "state_code": "073"
            },
            {
                "id": 1969,
                "name": "Bayan-Ölgii Province",
                "state_code": "071"
            },
            {
                "id": 1976,
                "name": "Bayankhongor Province",
                "state_code": "069"
            },
            {
                "id": 1961,
                "name": "Bulgan Province",
                "state_code": "067"
            },
            {
                "id": 1962,
                "name": "Darkhan-Uul Province",
                "state_code": "037"
            },
            {
                "id": 1963,
                "name": "Dornod Province",
                "state_code": "061"
            },
            {
                "id": 1981,
                "name": "Dornogovi Province",
                "state_code": "063"
            },
            {
                "id": 1970,
                "name": "Dundgovi Province",
                "state_code": "059"
            },
            {
                "id": 1972,
                "name": "Govi-Altai Province",
                "state_code": "065"
            },
            {
                "id": 1978,
                "name": "Govisümber Province",
                "state_code": "064"
            },
            {
                "id": 1974,
                "name": "Khentii Province",
                "state_code": "039"
            },
            {
                "id": 1964,
                "name": "Khovd Province",
                "state_code": "043"
            },
            {
                "id": 1975,
                "name": "Khövsgöl Province",
                "state_code": "041"
            },
            {
                "id": 1967,
                "name": "Ömnögovi Province",
                "state_code": "053"
            },
            {
                "id": 1966,
                "name": "Orkhon Province",
                "state_code": "035"
            },
            {
                "id": 1965,
                "name": "Övörkhangai Province",
                "state_code": "055"
            },
            {
                "id": 1980,
                "name": "Selenge Province",
                "state_code": "049"
            },
            {
                "id": 1977,
                "name": "Sükhbaatar Province",
                "state_code": "051"
            },
            {
                "id": 1968,
                "name": "Töv Province",
                "state_code": "047"
            },
            {
                "id": 1971,
                "name": "Uvs Province",
                "state_code": "046"
            },
            {
                "id": 1979,
                "name": "Zavkhan Province",
                "state_code": "057"
            }
        ]
    },
    {
        "name": "Montenegro",
        "iso3": "MNE",
        "iso2": "ME",
        "phone_code": "382",
        "capital": "Podgorica",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".me",
        "native": "Црна Гора",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Podgorica",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Montenegro",
            "pt": "Montenegro",
            "nl": "Montenegro",
            "hr": "Crna Gora",
            "fa": "مونته‌نگرو",
            "de": "Montenegro",
            "es": "Montenegro",
            "fr": "Monténégro",
            "ja": "モンテネグロ",
            "it": "Montenegro"
        },
        "latitude": "42.50000000",
        "longitude": "19.30000000",
        "emoji": "🇲🇪",
        "emojiU": "U+1F1F2 U+1F1EA",
        "states": [
            {
                "id": 23,
                "name": "Andrijevica Municipality",
                "state_code": "01"
            },
            {
                "id": 13,
                "name": "Bar Municipality",
                "state_code": "02"
            },
            {
                "id": 21,
                "name": "Berane Municipality",
                "state_code": "03"
            },
            {
                "id": 25,
                "name": "Bijelo Polje Municipality",
                "state_code": "04"
            },
            {
                "id": 30,
                "name": "Budva Municipality",
                "state_code": "05"
            },
            {
                "id": 14,
                "name": "Danilovgrad Municipality",
                "state_code": "07"
            },
            {
                "id": 24,
                "name": "Gusinje Municipality",
                "state_code": "22"
            },
            {
                "id": 31,
                "name": "Kolašin Municipality",
                "state_code": "09"
            },
            {
                "id": 26,
                "name": "Kotor Municipality",
                "state_code": "10"
            },
            {
                "id": 22,
                "name": "Mojkovac Municipality",
                "state_code": "11"
            },
            {
                "id": 17,
                "name": "Nikšić Municipality",
                "state_code": "12"
            },
            {
                "id": 28,
                "name": "Old Royal Capital Cetinje",
                "state_code": "06"
            },
            {
                "id": 12,
                "name": "Petnjica Municipality",
                "state_code": "23"
            },
            {
                "id": 19,
                "name": "Plav Municipality",
                "state_code": "13"
            },
            {
                "id": 20,
                "name": "Pljevlja Municipality",
                "state_code": "14"
            },
            {
                "id": 16,
                "name": "Plužine Municipality",
                "state_code": "15"
            },
            {
                "id": 27,
                "name": "Podgorica Municipality",
                "state_code": "16"
            },
            {
                "id": 15,
                "name": "Rožaje Municipality",
                "state_code": "17"
            },
            {
                "id": 18,
                "name": "Šavnik Municipality",
                "state_code": "18"
            },
            {
                "id": 29,
                "name": "Tivat Municipality",
                "state_code": "19"
            },
            {
                "id": 33,
                "name": "Ulcinj Municipality",
                "state_code": "20"
            },
            {
                "id": 32,
                "name": "Žabljak Municipality",
                "state_code": "21"
            }
        ]
    },
    {
        "name": "Montserrat",
        "iso3": "MSR",
        "iso2": "MS",
        "phone_code": "+1-664",
        "capital": "Plymouth",
        "currency": "XCD",
        "currency_symbol": "$",
        "tld": ".ms",
        "native": "Montserrat",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/Montserrat",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "translations": {
            "br": "Montserrat",
            "pt": "Monserrate",
            "nl": "Montserrat",
            "hr": "Montserrat",
            "fa": "مایوت",
            "de": "Montserrat",
            "es": "Montserrat",
            "fr": "Montserrat",
            "ja": "モントセラト",
            "it": "Montserrat"
        },
        "latitude": "16.75000000",
        "longitude": "-62.20000000",
        "emoji": "🇲🇸",
        "emojiU": "U+1F1F2 U+1F1F8",
        "states": []
    },
    {
        "name": "Morocco",
        "iso3": "MAR",
        "iso2": "MA",
        "phone_code": "212",
        "capital": "Rabat",
        "currency": "MAD",
        "currency_symbol": "DH",
        "tld": ".ma",
        "native": "المغرب",
        "region": "Africa",
        "subregion": "Northern Africa",
        "timezones": [
            {
                "zoneName": "Africa/Casablanca",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WEST",
                "tzName": "Western European Summer Time"
            }
        ],
        "translations": {
            "br": "Marrocos",
            "pt": "Marrocos",
            "nl": "Marokko",
            "hr": "Maroko",
            "fa": "مراکش",
            "de": "Marokko",
            "es": "Marruecos",
            "fr": "Maroc",
            "ja": "モロッコ",
            "it": "Marocco"
        },
        "latitude": "32.00000000",
        "longitude": "-5.00000000",
        "emoji": "🇲🇦",
        "emojiU": "U+1F1F2 U+1F1E6",
        "states": [
            {
                "id": 3320,
                "name": "Al Haouz Province",
                "state_code": "HAO"
            },
            {
                "id": 3267,
                "name": "Al Hoceïma Province",
                "state_code": "HOC"
            },
            {
                "id": 3266,
                "name": "Aousserd Province",
                "state_code": "AOU"
            },
            {
                "id": 3297,
                "name": "Assa-Zag Province",
                "state_code": "ASZ"
            },
            {
                "id": 3321,
                "name": "Azilal Province",
                "state_code": "AZI"
            },
            {
                "id": 3304,
                "name": "Ben Slimane Province",
                "state_code": "BES"
            },
            {
                "id": 3278,
                "name": "Béni Mellal-Khénifra",
                "state_code": "05"
            },
            {
                "id": 3272,
                "name": "Béni-Mellal Province",
                "state_code": "BEM"
            },
            {
                "id": 3285,
                "name": "Berkane Province",
                "state_code": "BER"
            },
            {
                "id": 3275,
                "name": "Boujdour Province",
                "state_code": "BOD"
            },
            {
                "id": 3270,
                "name": "Boulemane Province",
                "state_code": "BOM"
            },
            {
                "id": 3303,
                "name": "Casablanca-Settat",
                "state_code": "06"
            },
            {
                "id": 3310,
                "name": "Chefchaouen Province",
                "state_code": "CHE"
            },
            {
                "id": 3274,
                "name": "Chichaoua Province",
                "state_code": "CHI"
            },
            {
                "id": 3306,
                "name": "Dakhla-Oued Ed-Dahab",
                "state_code": "12"
            },
            {
                "id": 3290,
                "name": "Drâa-Tafilalet",
                "state_code": "08"
            },
            {
                "id": 3291,
                "name": "El Hajeb Province",
                "state_code": "HAJ"
            },
            {
                "id": 3280,
                "name": "El Jadida Province",
                "state_code": "JDI"
            },
            {
                "id": 3299,
                "name": "Errachidia Province",
                "state_code": "ERR"
            },
            {
                "id": 3292,
                "name": "Es Semara Province",
                "state_code": "ESM"
            },
            {
                "id": 3316,
                "name": "Essaouira Province",
                "state_code": "ESI"
            },
            {
                "id": 3300,
                "name": "Fahs Anjra Province",
                "state_code": "FAH"
            },
            {
                "id": 3313,
                "name": "Fès-Meknès",
                "state_code": "03"
            },
            {
                "id": 3301,
                "name": "Figuig Province",
                "state_code": "FIG"
            },
            {
                "id": 3265,
                "name": "Guelmim Province",
                "state_code": "GUE"
            },
            {
                "id": 3305,
                "name": "Guelmim-Oued Noun",
                "state_code": "10"
            },
            {
                "id": 3325,
                "name": "Ifrane Province",
                "state_code": "IFR"
            },
            {
                "id": 3294,
                "name": "Inezgane-Aït Melloul Prefecture",
                "state_code": "INE"
            },
            {
                "id": 3307,
                "name": "Jerada Province",
                "state_code": "JRA"
            },
            {
                "id": 3309,
                "name": "Kelaat Sraghna Province",
                "state_code": "KES"
            },
            {
                "id": 3308,
                "name": "Kénitra Province",
                "state_code": "KEN"
            },
            {
                "id": 3276,
                "name": "Khémisset Province",
                "state_code": "KHE"
            },
            {
                "id": 3317,
                "name": "Khénifra Province",
                "state_code": "KHN"
            },
            {
                "id": 3326,
                "name": "Khouribga Province",
                "state_code": "KHO"
            },
            {
                "id": 3293,
                "name": "Laâyoune Province",
                "state_code": "LAA"
            },
            {
                "id": 3298,
                "name": "Laâyoune-Sakia El Hamra",
                "state_code": "11"
            },
            {
                "id": 3268,
                "name": "Larache Province",
                "state_code": "LAR"
            },
            {
                "id": 3288,
                "name": "Marrakesh-Safi",
                "state_code": "07"
            },
            {
                "id": 3284,
                "name": "Mediouna Province",
                "state_code": "MED"
            },
            {
                "id": 3315,
                "name": "Moulay Yacoub Province",
                "state_code": "MOU"
            },
            {
                "id": 3281,
                "name": "Nador Province",
                "state_code": "NAD"
            },
            {
                "id": 3287,
                "name": "Nouaceur Province",
                "state_code": "NOU"
            },
            {
                "id": 3271,
                "name": "Oriental",
                "state_code": "02"
            },
            {
                "id": 3269,
                "name": "Ouarzazate Province",
                "state_code": "OUA"
            },
            {
                "id": 3319,
                "name": "Oued Ed-Dahab Province",
                "state_code": "OUD"
            },
            {
                "id": 3311,
                "name": "Safi Province",
                "state_code": "SAF"
            },
            {
                "id": 3289,
                "name": "Sefrou Province",
                "state_code": "SEF"
            },
            {
                "id": 3282,
                "name": "Settat Province",
                "state_code": "SET"
            },
            {
                "id": 3302,
                "name": "Shtouka Ait Baha Province",
                "state_code": "CHT"
            },
            {
                "id": 3279,
                "name": "Sidi Kacem Province",
                "state_code": "SIK"
            },
            {
                "id": 3273,
                "name": "Sidi Youssef Ben Ali",
                "state_code": "SYB"
            },
            {
                "id": 3295,
                "name": "Souss-Massa",
                "state_code": "09"
            },
            {
                "id": 3286,
                "name": "Tan-Tan Province",
                "state_code": "TNT"
            },
            {
                "id": 3324,
                "name": "Tanger-Tétouan-Al Hoceïma",
                "state_code": "01"
            },
            {
                "id": 3323,
                "name": "Taounate Province",
                "state_code": "TAO"
            },
            {
                "id": 3322,
                "name": "Taourirt Province",
                "state_code": "TAI"
            },
            {
                "id": 3314,
                "name": "Taroudant Province",
                "state_code": "TAR"
            },
            {
                "id": 3312,
                "name": "Tata Province",
                "state_code": "TAT"
            },
            {
                "id": 3296,
                "name": "Taza Province",
                "state_code": "TAZ"
            },
            {
                "id": 3318,
                "name": "Tétouan Province",
                "state_code": "TET"
            },
            {
                "id": 3277,
                "name": "Tiznit Province",
                "state_code": "TIZ"
            },
            {
                "id": 3283,
                "name": "Zagora Province",
                "state_code": "ZAG"
            }
        ]
    },
    {
        "name": "Mozambique",
        "iso3": "MOZ",
        "iso2": "MZ",
        "phone_code": "258",
        "capital": "Maputo",
        "currency": "MZN",
        "currency_symbol": "MT",
        "tld": ".mz",
        "native": "Moçambique",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa/Maputo",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "CAT",
                "tzName": "Central Africa Time"
            }
        ],
        "translations": {
            "br": "Moçambique",
            "pt": "Moçambique",
            "nl": "Mozambique",
            "hr": "Mozambik",
            "fa": "موزامبیک",
            "de": "Mosambik",
            "es": "Mozambique",
            "fr": "Mozambique",
            "ja": "モザンビーク",
            "it": "Mozambico"
        },
        "latitude": "-18.25000000",
        "longitude": "35.00000000",
        "emoji": "🇲🇿",
        "emojiU": "U+1F1F2 U+1F1FF",
        "states": [
            {
                "id": 3327,
                "name": "Cabo Delgado Province",
                "state_code": "P"
            },
            {
                "id": 3329,
                "name": "Gaza Province",
                "state_code": "G"
            },
            {
                "id": 3330,
                "name": "Inhambane Province",
                "state_code": "I"
            },
            {
                "id": 3337,
                "name": "Manica Province",
                "state_code": "B"
            },
            {
                "id": 3335,
                "name": "Maputo",
                "state_code": "MPM"
            },
            {
                "id": 3332,
                "name": "Maputo Province",
                "state_code": "L"
            },
            {
                "id": 3336,
                "name": "Nampula Province",
                "state_code": "N"
            },
            {
                "id": 3333,
                "name": "Niassa Province",
                "state_code": "A"
            },
            {
                "id": 3331,
                "name": "Sofala Province",
                "state_code": "S"
            },
            {
                "id": 3334,
                "name": "Tete Province",
                "state_code": "T"
            },
            {
                "id": 3328,
                "name": "Zambezia Province",
                "state_code": "Q"
            }
        ]
    },
    {
        "name": "Myanmar",
        "iso3": "MMR",
        "iso2": "MM",
        "phone_code": "95",
        "capital": "Nay Pyi Taw",
        "currency": "MMK",
        "currency_symbol": "K",
        "tld": ".mm",
        "native": "မြန်မာ",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Yangon",
                "gmtOffset": 23400,
                "gmtOffsetName": "UTC+06:30",
                "abbreviation": "MMT",
                "tzName": "Myanmar Standard Time"
            }
        ],
        "translations": {
            "br": "Myanmar",
            "pt": "Myanmar",
            "nl": "Myanmar",
            "hr": "Mijanmar",
            "fa": "میانمار",
            "de": "Myanmar",
            "es": "Myanmar",
            "fr": "Myanmar",
            "ja": "ミャンマー",
            "it": "Birmania"
        },
        "latitude": "22.00000000",
        "longitude": "98.00000000",
        "emoji": "🇲🇲",
        "emojiU": "U+1F1F2 U+1F1F2",
        "states": [
            {
                "id": 2142,
                "name": "Ayeyarwady Region",
                "state_code": "07"
            },
            {
                "id": 2141,
                "name": "Bago",
                "state_code": "02"
            },
            {
                "id": 2137,
                "name": "Chin State",
                "state_code": "14"
            },
            {
                "id": 2143,
                "name": "Kachin State",
                "state_code": "11"
            },
            {
                "id": 2144,
                "name": "Kayah State",
                "state_code": "12"
            },
            {
                "id": 2133,
                "name": "Kayin State",
                "state_code": "13"
            },
            {
                "id": 2136,
                "name": "Magway Region",
                "state_code": "03"
            },
            {
                "id": 2134,
                "name": "Mandalay Region",
                "state_code": "04"
            },
            {
                "id": 2147,
                "name": "Mon State",
                "state_code": "15"
            },
            {
                "id": 2146,
                "name": "Naypyidaw Union Territory",
                "state_code": "18"
            },
            {
                "id": 2138,
                "name": "Rakhine State",
                "state_code": "16"
            },
            {
                "id": 2145,
                "name": "Sagaing Region",
                "state_code": "01"
            },
            {
                "id": 2139,
                "name": "Shan State",
                "state_code": "17"
            },
            {
                "id": 2140,
                "name": "Tanintharyi Region",
                "state_code": "05"
            },
            {
                "id": 2135,
                "name": "Yangon Region",
                "state_code": "06"
            }
        ]
    },
    {
        "name": "Namibia",
        "iso3": "NAM",
        "iso2": "NA",
        "phone_code": "264",
        "capital": "Windhoek",
        "currency": "NAD",
        "currency_symbol": "$",
        "tld": ".na",
        "native": "Namibia",
        "region": "Africa",
        "subregion": "Southern Africa",
        "timezones": [
            {
                "zoneName": "Africa/Windhoek",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "WAST",
                "tzName": "West Africa Summer Time"
            }
        ],
        "translations": {
            "br": "Namíbia",
            "pt": "Namíbia",
            "nl": "Namibië",
            "hr": "Namibija",
            "fa": "نامیبیا",
            "de": "Namibia",
            "es": "Namibia",
            "fr": "Namibie",
            "ja": "ナミビア",
            "it": "Namibia"
        },
        "latitude": "-22.00000000",
        "longitude": "17.00000000",
        "emoji": "🇳🇦",
        "emojiU": "U+1F1F3 U+1F1E6",
        "states": [
            {
                "id": 43,
                "name": "Erongo Region",
                "state_code": "ER"
            },
            {
                "id": 38,
                "name": "Hardap Region",
                "state_code": "HA"
            },
            {
                "id": 45,
                "name": "Karas Region",
                "state_code": "KA"
            },
            {
                "id": 36,
                "name": "Kavango East Region",
                "state_code": "KE"
            },
            {
                "id": 35,
                "name": "Kavango West Region",
                "state_code": "KW"
            },
            {
                "id": 44,
                "name": "Khomas Region",
                "state_code": "KH"
            },
            {
                "id": 34,
                "name": "Kunene Region",
                "state_code": "KU"
            },
            {
                "id": 40,
                "name": "Ohangwena Region",
                "state_code": "OW"
            },
            {
                "id": 41,
                "name": "Omaheke Region",
                "state_code": "OH"
            },
            {
                "id": 39,
                "name": "Omusati Region",
                "state_code": "OS"
            },
            {
                "id": 37,
                "name": "Oshana Region",
                "state_code": "ON"
            },
            {
                "id": 42,
                "name": "Oshikoto Region",
                "state_code": "OT"
            },
            {
                "id": 46,
                "name": "Otjozondjupa Region",
                "state_code": "OD"
            },
            {
                "id": 47,
                "name": "Zambezi Region",
                "state_code": "CA"
            }
        ]
    },
    {
        "name": "Nauru",
        "iso3": "NRU",
        "iso2": "NR",
        "phone_code": "674",
        "capital": "Yaren",
        "currency": "AUD",
        "currency_symbol": "$",
        "tld": ".nr",
        "native": "Nauru",
        "region": "Oceania",
        "subregion": "Micronesia",
        "timezones": [
            {
                "zoneName": "Pacific/Nauru",
                "gmtOffset": 43200,
                "gmtOffsetName": "UTC+12:00",
                "abbreviation": "NRT",
                "tzName": "Nauru Time"
            }
        ],
        "translations": {
            "br": "Nauru",
            "pt": "Nauru",
            "nl": "Nauru",
            "hr": "Nauru",
            "fa": "نائورو",
            "de": "Nauru",
            "es": "Nauru",
            "fr": "Nauru",
            "ja": "ナウル",
            "it": "Nauru"
        },
        "latitude": "-0.53333333",
        "longitude": "166.91666666",
        "emoji": "🇳🇷",
        "emojiU": "U+1F1F3 U+1F1F7",
        "states": [
            {
                "id": 4656,
                "name": "Aiwo District",
                "state_code": "01"
            },
            {
                "id": 4658,
                "name": "Anabar District",
                "state_code": "02"
            },
            {
                "id": 4667,
                "name": "Anetan District",
                "state_code": "03"
            },
            {
                "id": 4663,
                "name": "Anibare District",
                "state_code": "04"
            },
            {
                "id": 4660,
                "name": "Baiti District",
                "state_code": "05"
            },
            {
                "id": 4665,
                "name": "Boe District",
                "state_code": "06"
            },
            {
                "id": 4662,
                "name": "Buada District",
                "state_code": "07"
            },
            {
                "id": 4666,
                "name": "Denigomodu District",
                "state_code": "08"
            },
            {
                "id": 4654,
                "name": "Ewa District",
                "state_code": "09"
            },
            {
                "id": 4661,
                "name": "Ijuw District",
                "state_code": "10"
            },
            {
                "id": 4657,
                "name": "Meneng District",
                "state_code": "11"
            },
            {
                "id": 4659,
                "name": "Nibok District",
                "state_code": "12"
            },
            {
                "id": 4655,
                "name": "Uaboe District",
                "state_code": "13"
            },
            {
                "id": 4664,
                "name": "Yaren District",
                "state_code": "14"
            }
        ]
    },
    {
        "name": "Nepal",
        "iso3": "NPL",
        "iso2": "NP",
        "phone_code": "977",
        "capital": "Kathmandu",
        "currency": "NPR",
        "currency_symbol": "₨",
        "tld": ".np",
        "native": "नपल",
        "region": "Asia",
        "subregion": "Southern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Kathmandu",
                "gmtOffset": 20700,
                "gmtOffsetName": "UTC+05:45",
                "abbreviation": "NPT",
                "tzName": "Nepal Time"
            }
        ],
        "translations": {
            "br": "Nepal",
            "pt": "Nepal",
            "nl": "Nepal",
            "hr": "Nepal",
            "fa": "نپال",
            "de": "Népal",
            "es": "Nepal",
            "fr": "Népal",
            "ja": "ネパール",
            "it": "Nepal"
        },
        "latitude": "28.00000000",
        "longitude": "84.00000000",
        "emoji": "🇳🇵",
        "emojiU": "U+1F1F3 U+1F1F5",
        "states": [
            {
                "id": 2082,
                "name": "Bagmati Zone",
                "state_code": "BA"
            },
            {
                "id": 2071,
                "name": "Bheri Zone",
                "state_code": "BH"
            },
            {
                "id": 2073,
                "name": "Central Region",
                "state_code": "1"
            },
            {
                "id": 2080,
                "name": "Dhaulagiri Zone",
                "state_code": "DH"
            },
            {
                "id": 2069,
                "name": "Eastern Development Region",
                "state_code": "4"
            },
            {
                "id": 2068,
                "name": "Far-Western Development Region",
                "state_code": "5"
            },
            {
                "id": 2081,
                "name": "Gandaki Zone",
                "state_code": "GA"
            },
            {
                "id": 2076,
                "name": "Janakpur Zone",
                "state_code": "JA"
            },
            {
                "id": 2079,
                "name": "Karnali Zone",
                "state_code": "KA"
            },
            {
                "id": 2072,
                "name": "Kosi Zone",
                "state_code": "KO"
            },
            {
                "id": 2074,
                "name": "Lumbini Zone",
                "state_code": "LU"
            },
            {
                "id": 2083,
                "name": "Mahakali Zone",
                "state_code": "MA"
            },
            {
                "id": 2070,
                "name": "Mechi Zone",
                "state_code": "ME"
            },
            {
                "id": 2066,
                "name": "Mid-Western Region",
                "state_code": "2"
            },
            {
                "id": 2075,
                "name": "Narayani Zone",
                "state_code": "NA"
            },
            {
                "id": 2077,
                "name": "Rapti Zone",
                "state_code": "RA"
            },
            {
                "id": 2084,
                "name": "Sagarmatha Zone",
                "state_code": "SA"
            },
            {
                "id": 2078,
                "name": "Seti Zone",
                "state_code": "SE"
            },
            {
                "id": 2067,
                "name": "Western Region",
                "state_code": "3"
            }
        ]
    },
    {
        "name": "Netherlands The",
        "iso3": "NLD",
        "iso2": "NL",
        "phone_code": "31",
        "capital": "Amsterdam",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".nl",
        "native": "Nederland",
        "region": "Europe",
        "subregion": "Western Europe",
        "timezones": [
            {
                "zoneName": "Europe/Amsterdam",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Holanda",
            "pt": "Países Baixos",
            "nl": "Nederland",
            "hr": "Nizozemska",
            "fa": "پادشاهی هلند",
            "de": "Niederlande",
            "es": "Países Bajos",
            "fr": "Pays-Bas",
            "ja": "オランダ",
            "it": "Paesi Bassi"
        },
        "latitude": "52.50000000",
        "longitude": "5.75000000",
        "emoji": "🇳🇱",
        "emojiU": "U+1F1F3 U+1F1F1",
        "states": [
            {
                "id": 2624,
                "name": "Bonaire",
                "state_code": "BQ1"
            },
            {
                "id": 2613,
                "name": "Drenthe",
                "state_code": "DR"
            },
            {
                "id": 2619,
                "name": "Flevoland",
                "state_code": "FL"
            },
            {
                "id": 2622,
                "name": "Friesland",
                "state_code": "FR"
            },
            {
                "id": 2611,
                "name": "Gelderland",
                "state_code": "GE"
            },
            {
                "id": 2617,
                "name": "Groningen",
                "state_code": "GR"
            },
            {
                "id": 2615,
                "name": "Limburg",
                "state_code": "LI"
            },
            {
                "id": 2623,
                "name": "North Brabant",
                "state_code": "NB"
            },
            {
                "id": 2612,
                "name": "North Holland",
                "state_code": "NH"
            },
            {
                "id": 2618,
                "name": "Overijssel",
                "state_code": "OV"
            },
            {
                "id": 2621,
                "name": "Saba",
                "state_code": "BQ2"
            },
            {
                "id": 2616,
                "name": "Sint Eustatius",
                "state_code": "BQ3"
            },
            {
                "id": 2614,
                "name": "South Holland",
                "state_code": "ZH"
            },
            {
                "id": 2610,
                "name": "Utrecht",
                "state_code": "UT"
            },
            {
                "id": 2620,
                "name": "Zeeland",
                "state_code": "ZE"
            }
        ]
    },
    {
        "name": "New Caledonia",
        "iso3": "NCL",
        "iso2": "NC",
        "phone_code": "687",
        "capital": "Noumea",
        "currency": "XPF",
        "currency_symbol": "₣",
        "tld": ".nc",
        "native": "Nouvelle-Calédonie",
        "region": "Oceania",
        "subregion": "Melanesia",
        "timezones": [
            {
                "zoneName": "Pacific/Noumea",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "NCT",
                "tzName": "New Caledonia Time"
            }
        ],
        "translations": {
            "br": "Nova Caledônia",
            "pt": "Nova Caledónia",
            "nl": "Nieuw-Caledonië",
            "hr": "Nova Kaledonija",
            "fa": "کالدونیای جدید",
            "de": "Neukaledonien",
            "es": "Nueva Caledonia",
            "fr": "Nouvelle-Calédonie",
            "ja": "ニューカレドニア",
            "it": "Nuova Caledonia"
        },
        "latitude": "-21.50000000",
        "longitude": "165.50000000",
        "emoji": "🇳🇨",
        "emojiU": "U+1F1F3 U+1F1E8",
        "states": []
    },
    {
        "name": "New Zealand",
        "iso3": "NZL",
        "iso2": "NZ",
        "phone_code": "64",
        "capital": "Wellington",
        "currency": "NZD",
        "currency_symbol": "$",
        "tld": ".nz",
        "native": "New Zealand",
        "region": "Oceania",
        "subregion": "Australia and New Zealand",
        "timezones": [
            {
                "zoneName": "Pacific/Auckland",
                "gmtOffset": 46800,
                "gmtOffsetName": "UTC+13:00",
                "abbreviation": "NZDT",
                "tzName": "New Zealand Daylight Time"
            },
            {
                "zoneName": "Pacific/Chatham",
                "gmtOffset": 49500,
                "gmtOffsetName": "UTC+13:45",
                "abbreviation": "CHAST",
                "tzName": "Chatham Standard Time"
            }
        ],
        "translations": {
            "br": "Nova Zelândia",
            "pt": "Nova Zelândia",
            "nl": "Nieuw-Zeeland",
            "hr": "Novi Zeland",
            "fa": "نیوزیلند",
            "de": "Neuseeland",
            "es": "Nueva Zelanda",
            "fr": "Nouvelle-Zélande",
            "ja": "ニュージーランド",
            "it": "Nuova Zelanda"
        },
        "latitude": "-41.00000000",
        "longitude": "174.00000000",
        "emoji": "🇳🇿",
        "emojiU": "U+1F1F3 U+1F1FF",
        "states": [
            {
                "id": 4072,
                "name": "Auckland Region",
                "state_code": "AUK"
            },
            {
                "id": 4074,
                "name": "Bay of Plenty Region",
                "state_code": "BOP"
            },
            {
                "id": 4066,
                "name": "Canterbury Region",
                "state_code": "CAN"
            },
            {
                "id": 4067,
                "name": "Chatham Islands",
                "state_code": "CIT"
            },
            {
                "id": 4068,
                "name": "Gisborne District",
                "state_code": "GIS"
            },
            {
                "id": 4075,
                "name": "Hawke's Bay Region",
                "state_code": "HKB"
            },
            {
                "id": 4060,
                "name": "Manawatu-Wanganui Region",
                "state_code": "MWT"
            },
            {
                "id": 4063,
                "name": "Marlborough Region",
                "state_code": "MBH"
            },
            {
                "id": 4070,
                "name": "Nelson Region",
                "state_code": "NSN"
            },
            {
                "id": 4059,
                "name": "Northland Region",
                "state_code": "NTL"
            },
            {
                "id": 4062,
                "name": "Otago Region",
                "state_code": "OTA"
            },
            {
                "id": 4071,
                "name": "Southland Region",
                "state_code": "STL"
            },
            {
                "id": 4069,
                "name": "Taranaki Region",
                "state_code": "TKI"
            },
            {
                "id": 4073,
                "name": "Tasman District",
                "state_code": "TAS"
            },
            {
                "id": 4061,
                "name": "Waikato Region",
                "state_code": "WKO"
            },
            {
                "id": 4065,
                "name": "Wellington Region",
                "state_code": "WGN"
            },
            {
                "id": 4064,
                "name": "West Coast Region",
                "state_code": "WTC"
            }
        ]
    },
    {
        "name": "Nicaragua",
        "iso3": "NIC",
        "iso2": "NI",
        "phone_code": "505",
        "capital": "Managua",
        "currency": "NIO",
        "currency_symbol": "C$",
        "tld": ".ni",
        "native": "Nicaragua",
        "region": "Americas",
        "subregion": "Central America",
        "timezones": [
            {
                "zoneName": "America/Managua",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            }
        ],
        "translations": {
            "br": "Nicarágua",
            "pt": "Nicarágua",
            "nl": "Nicaragua",
            "hr": "Nikaragva",
            "fa": "نیکاراگوئه",
            "de": "Nicaragua",
            "es": "Nicaragua",
            "fr": "Nicaragua",
            "ja": "ニカラグア",
            "it": "Nicaragua"
        },
        "latitude": "13.00000000",
        "longitude": "-85.00000000",
        "emoji": "🇳🇮",
        "emojiU": "U+1F1F3 U+1F1EE",
        "states": [
            {
                "id": 946,
                "name": "Boaco Department",
                "state_code": "BO"
            },
            {
                "id": 950,
                "name": "Carazo Department",
                "state_code": "CA"
            },
            {
                "id": 954,
                "name": "Chinandega Department",
                "state_code": "CI"
            },
            {
                "id": 940,
                "name": "Chontales Department",
                "state_code": "CO"
            },
            {
                "id": 945,
                "name": "Estelí Department",
                "state_code": "ES"
            },
            {
                "id": 943,
                "name": "Granada Department",
                "state_code": "GR"
            },
            {
                "id": 955,
                "name": "Jinotega Department",
                "state_code": "JI"
            },
            {
                "id": 944,
                "name": "León Department",
                "state_code": "LE"
            },
            {
                "id": 948,
                "name": "Madriz Department",
                "state_code": "MD"
            },
            {
                "id": 941,
                "name": "Managua Department",
                "state_code": "MN"
            },
            {
                "id": 953,
                "name": "Masaya Department",
                "state_code": "MS"
            },
            {
                "id": 947,
                "name": "Matagalpa Department",
                "state_code": "MT"
            },
            {
                "id": 951,
                "name": "North Caribbean Coast Autonomous Region",
                "state_code": "AN"
            },
            {
                "id": 949,
                "name": "Río San Juan Department",
                "state_code": "SJ"
            },
            {
                "id": 942,
                "name": "Rivas Department",
                "state_code": "RI"
            },
            {
                "id": 952,
                "name": "South Caribbean Coast Autonomous Region",
                "state_code": "AS"
            }
        ]
    },
    {
        "name": "Niger",
        "iso3": "NER",
        "iso2": "NE",
        "phone_code": "227",
        "capital": "Niamey",
        "currency": "XOF",
        "currency_symbol": "CFA",
        "tld": ".ne",
        "native": "Niger",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa/Niamey",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WAT",
                "tzName": "West Africa Time"
            }
        ],
        "translations": {
            "br": "Níger",
            "pt": "Níger",
            "nl": "Niger",
            "hr": "Niger",
            "fa": "نیجر",
            "de": "Niger",
            "es": "Níger",
            "fr": "Niger",
            "ja": "ニジェール",
            "it": "Niger"
        },
        "latitude": "16.00000000",
        "longitude": "8.00000000",
        "emoji": "🇳🇪",
        "emojiU": "U+1F1F3 U+1F1EA",
        "states": [
            {
                "id": 71,
                "name": "Agadez Region",
                "state_code": "1"
            },
            {
                "id": 72,
                "name": "Diffa Region",
                "state_code": "2"
            },
            {
                "id": 68,
                "name": "Dosso Region",
                "state_code": "3"
            },
            {
                "id": 70,
                "name": "Maradi Region",
                "state_code": "4"
            },
            {
                "id": 73,
                "name": "Tahoua Region",
                "state_code": "5"
            },
            {
                "id": 67,
                "name": "Tillabéri Region",
                "state_code": "6"
            },
            {
                "id": 69,
                "name": "Zinder Region",
                "state_code": "7"
            }
        ]
    },
    {
        "name": "Nigeria",
        "iso3": "NGA",
        "iso2": "NG",
        "phone_code": "234",
        "capital": "Abuja",
        "currency": "NGN",
        "currency_symbol": "₦",
        "tld": ".ng",
        "native": "Nigeria",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa/Lagos",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WAT",
                "tzName": "West Africa Time"
            }
        ],
        "translations": {
            "br": "Nigéria",
            "pt": "Nigéria",
            "nl": "Nigeria",
            "hr": "Nigerija",
            "fa": "نیجریه",
            "de": "Nigeria",
            "es": "Nigeria",
            "fr": "Nigéria",
            "ja": "ナイジェリア",
            "it": "Nigeria"
        },
        "latitude": "10.00000000",
        "longitude": "8.00000000",
        "emoji": "🇳🇬",
        "emojiU": "U+1F1F3 U+1F1EC",
        "states": [
            {
                "id": 303,
                "name": "Abia State",
                "state_code": "AB"
            },
            {
                "id": 320,
                "name": "Adamawa State",
                "state_code": "AD"
            },
            {
                "id": 304,
                "name": "Akwa Ibom State",
                "state_code": "AK"
            },
            {
                "id": 315,
                "name": "Anambra State",
                "state_code": "AN"
            },
            {
                "id": 312,
                "name": "Bauchi State",
                "state_code": "BA"
            },
            {
                "id": 305,
                "name": "Bayelsa State",
                "state_code": "BY"
            },
            {
                "id": 291,
                "name": "Benue State",
                "state_code": "BE"
            },
            {
                "id": 307,
                "name": "Borno State",
                "state_code": "BO"
            },
            {
                "id": 314,
                "name": "Cross River State",
                "state_code": "CR"
            },
            {
                "id": 316,
                "name": "Delta State",
                "state_code": "DE"
            },
            {
                "id": 311,
                "name": "Ebonyi State",
                "state_code": "EB"
            },
            {
                "id": 318,
                "name": "Edo State",
                "state_code": "ED"
            },
            {
                "id": 309,
                "name": "Ekiti State",
                "state_code": "EK"
            },
            {
                "id": 289,
                "name": "Enugu State",
                "state_code": "EN"
            },
            {
                "id": 293,
                "name": "Federal Capital Territory",
                "state_code": "FC"
            },
            {
                "id": 310,
                "name": "Gombe State",
                "state_code": "GO"
            },
            {
                "id": 308,
                "name": "Imo State",
                "state_code": "IM"
            },
            {
                "id": 288,
                "name": "Jigawa State",
                "state_code": "JI"
            },
            {
                "id": 294,
                "name": "Kaduna State",
                "state_code": "KD"
            },
            {
                "id": 300,
                "name": "Kano State",
                "state_code": "KN"
            },
            {
                "id": 313,
                "name": "Katsina State",
                "state_code": "KT"
            },
            {
                "id": 290,
                "name": "Kebbi State",
                "state_code": "KE"
            },
            {
                "id": 298,
                "name": "Kogi State",
                "state_code": "KO"
            },
            {
                "id": 295,
                "name": "Kwara State",
                "state_code": "KW"
            },
            {
                "id": 306,
                "name": "Lagos",
                "state_code": "LA"
            },
            {
                "id": 301,
                "name": "Nasarawa State",
                "state_code": "NA"
            },
            {
                "id": 317,
                "name": "Niger State",
                "state_code": "NI"
            },
            {
                "id": 323,
                "name": "Ogun State",
                "state_code": "OG"
            },
            {
                "id": 321,
                "name": "Ondo State",
                "state_code": "ON"
            },
            {
                "id": 322,
                "name": "Osun State",
                "state_code": "OS"
            },
            {
                "id": 296,
                "name": "Oyo State",
                "state_code": "OY"
            },
            {
                "id": 302,
                "name": "Plateau State",
                "state_code": "PL"
            },
            {
                "id": 292,
                "name": "Sokoto State",
                "state_code": "SO"
            },
            {
                "id": 319,
                "name": "Taraba State",
                "state_code": "TA"
            },
            {
                "id": 297,
                "name": "Yobe State",
                "state_code": "YO"
            },
            {
                "id": 299,
                "name": "Zamfara State",
                "state_code": "ZA"
            }
        ]
    },
    {
        "name": "Niue",
        "iso3": "NIU",
        "iso2": "NU",
        "phone_code": "683",
        "capital": "Alofi",
        "currency": "NZD",
        "currency_symbol": "$",
        "tld": ".nu",
        "native": "Niuē",
        "region": "Oceania",
        "subregion": "Polynesia",
        "timezones": [
            {
                "zoneName": "Pacific/Niue",
                "gmtOffset": -39600,
                "gmtOffsetName": "UTC-11:00",
                "abbreviation": "NUT",
                "tzName": "Niue Time"
            }
        ],
        "translations": {
            "br": "Niue",
            "pt": "Niue",
            "nl": "Niue",
            "hr": "Niue",
            "fa": "نیووی",
            "de": "Niue",
            "es": "Niue",
            "fr": "Niue",
            "ja": "ニウエ",
            "it": "Niue"
        },
        "latitude": "-19.03333333",
        "longitude": "-169.86666666",
        "emoji": "🇳🇺",
        "emojiU": "U+1F1F3 U+1F1FA",
        "states": []
    },
    {
        "name": "Norfolk Island",
        "iso3": "NFK",
        "iso2": "NF",
        "phone_code": "672",
        "capital": "Kingston",
        "currency": "AUD",
        "currency_symbol": "$",
        "tld": ".nf",
        "native": "Norfolk Island",
        "region": "Oceania",
        "subregion": "Australia and New Zealand",
        "timezones": [
            {
                "zoneName": "Pacific/Norfolk",
                "gmtOffset": 43200,
                "gmtOffsetName": "UTC+12:00",
                "abbreviation": "NFT",
                "tzName": "Norfolk Time"
            }
        ],
        "translations": {
            "br": "Ilha Norfolk",
            "pt": "Ilha Norfolk",
            "nl": "Norfolkeiland",
            "hr": "Otok Norfolk",
            "fa": "جزیره نورفک",
            "de": "Norfolkinsel",
            "es": "Isla de Norfolk",
            "fr": "Île de Norfolk",
            "ja": "ノーフォーク島",
            "it": "Isola Norfolk"
        },
        "latitude": "-29.03333333",
        "longitude": "167.95000000",
        "emoji": "🇳🇫",
        "emojiU": "U+1F1F3 U+1F1EB",
        "states": []
    },
    {
        "name": "Northern Mariana Islands",
        "iso3": "MNP",
        "iso2": "MP",
        "phone_code": "+1-670",
        "capital": "Saipan",
        "currency": "USD",
        "currency_symbol": "$",
        "tld": ".mp",
        "native": "Northern Mariana Islands",
        "region": "Oceania",
        "subregion": "Micronesia",
        "timezones": [
            {
                "zoneName": "Pacific/Saipan",
                "gmtOffset": 36000,
                "gmtOffsetName": "UTC+10:00",
                "abbreviation": "ChST",
                "tzName": "Chamorro Standard Time"
            }
        ],
        "translations": {
            "br": "Ilhas Marianas",
            "pt": "Ilhas Marianas",
            "nl": "Noordelijke Marianeneilanden",
            "hr": "Sjevernomarijanski otoci",
            "fa": "جزایر ماریانای شمالی",
            "de": "Nördliche Marianen",
            "es": "Islas Marianas del Norte",
            "fr": "Îles Mariannes du Nord",
            "ja": "北マリアナ諸島",
            "it": "Isole Marianne Settentrionali"
        },
        "latitude": "15.20000000",
        "longitude": "145.75000000",
        "emoji": "🇲🇵",
        "emojiU": "U+1F1F2 U+1F1F5",
        "states": []
    },
    {
        "name": "Norway",
        "iso3": "NOR",
        "iso2": "NO",
        "phone_code": "47",
        "capital": "Oslo",
        "currency": "NOK",
        "currency_symbol": "kr",
        "tld": ".no",
        "native": "Norge",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Oslo",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Noruega",
            "pt": "Noruega",
            "nl": "Noorwegen",
            "hr": "Norveška",
            "fa": "نروژ",
            "de": "Norwegen",
            "es": "Noruega",
            "fr": "Norvège",
            "ja": "ノルウェー",
            "it": "Norvegia"
        },
        "latitude": "62.00000000",
        "longitude": "10.00000000",
        "emoji": "🇳🇴",
        "emojiU": "U+1F1F3 U+1F1F4",
        "states": [
            {
                "id": 1017,
                "name": "Akershus",
                "state_code": "02"
            },
            {
                "id": 1011,
                "name": "Buskerud",
                "state_code": "06"
            },
            {
                "id": 1016,
                "name": "Finnmark",
                "state_code": "20"
            },
            {
                "id": 1019,
                "name": "Hedmark",
                "state_code": "04"
            },
            {
                "id": 1023,
                "name": "Hordaland",
                "state_code": "12"
            },
            {
                "id": 1026,
                "name": "Jan Mayen",
                "state_code": "22"
            },
            {
                "id": 1020,
                "name": "Møre og Romsdal",
                "state_code": "15"
            },
            {
                "id": 1012,
                "name": "Nord-Trøndelag",
                "state_code": "17"
            },
            {
                "id": 1025,
                "name": "Nordland",
                "state_code": "18"
            },
            {
                "id": 1009,
                "name": "Oppland",
                "state_code": "05"
            },
            {
                "id": 1007,
                "name": "Oslo",
                "state_code": "03"
            },
            {
                "id": 1022,
                "name": "Østfold",
                "state_code": "01"
            },
            {
                "id": 1021,
                "name": "Rogaland",
                "state_code": "11"
            },
            {
                "id": 1018,
                "name": "Sogn og Fjordane",
                "state_code": "14"
            },
            {
                "id": 1010,
                "name": "Sør-Trøndelag",
                "state_code": "16"
            },
            {
                "id": 1013,
                "name": "Svalbard",
                "state_code": "21"
            },
            {
                "id": 1024,
                "name": "Telemark",
                "state_code": "08"
            },
            {
                "id": 1015,
                "name": "Troms",
                "state_code": "19"
            },
            {
                "id": 1006,
                "name": "Trøndelag",
                "state_code": "50"
            },
            {
                "id": 1014,
                "name": "Vest-Agder",
                "state_code": "10"
            },
            {
                "id": 1008,
                "name": "Vestfold",
                "state_code": "07"
            }
        ]
    },
    {
        "name": "Oman",
        "iso3": "OMN",
        "iso2": "OM",
        "phone_code": "968",
        "capital": "Muscat",
        "currency": "OMR",
        "currency_symbol": ".ع.ر",
        "tld": ".om",
        "native": "عمان",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia/Muscat",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "GST",
                "tzName": "Gulf Standard Time"
            }
        ],
        "translations": {
            "br": "Omã",
            "pt": "Omã",
            "nl": "Oman",
            "hr": "Oman",
            "fa": "عمان",
            "de": "Oman",
            "es": "Omán",
            "fr": "Oman",
            "ja": "オマーン",
            "it": "oman"
        },
        "latitude": "21.00000000",
        "longitude": "57.00000000",
        "emoji": "🇴🇲",
        "emojiU": "U+1F1F4 U+1F1F2",
        "states": [
            {
                "id": 3058,
                "name": "Ad Dakhiliyah Governorate",
                "state_code": "DA"
            },
            {
                "id": 3047,
                "name": "Ad Dhahirah Governorate",
                "state_code": "ZA"
            },
            {
                "id": 3048,
                "name": "Al Batinah North Governorate",
                "state_code": "BS"
            },
            {
                "id": 3050,
                "name": "Al Batinah Region",
                "state_code": "BA"
            },
            {
                "id": 3049,
                "name": "Al Batinah South Governorate",
                "state_code": "BJ"
            },
            {
                "id": 3059,
                "name": "Al Buraimi Governorate",
                "state_code": "BU"
            },
            {
                "id": 3056,
                "name": "Al Wusta Governorate",
                "state_code": "WU"
            },
            {
                "id": 3053,
                "name": "Ash Sharqiyah North Governorate",
                "state_code": "SS"
            },
            {
                "id": 3051,
                "name": "Ash Sharqiyah Region",
                "state_code": "SH"
            },
            {
                "id": 3054,
                "name": "Ash Sharqiyah South Governorate",
                "state_code": "SJ"
            },
            {
                "id": 3057,
                "name": "Dhofar Governorate",
                "state_code": "ZU"
            },
            {
                "id": 3052,
                "name": "Musandam Governorate",
                "state_code": "MU"
            },
            {
                "id": 3055,
                "name": "Muscat Governorate",
                "state_code": "MA"
            }
        ]
    },
    {
        "name": "Pakistan",
        "iso3": "PAK",
        "iso2": "PK",
        "phone_code": "92",
        "capital": "Islamabad",
        "currency": "PKR",
        "currency_symbol": "₨",
        "tld": ".pk",
        "native": "Pakistan",
        "region": "Asia",
        "subregion": "Southern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Karachi",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "PKT",
                "tzName": "Pakistan Standard Time"
            }
        ],
        "translations": {
            "br": "Paquistão",
            "pt": "Paquistão",
            "nl": "Pakistan",
            "hr": "Pakistan",
            "fa": "پاکستان",
            "de": "Pakistan",
            "es": "Pakistán",
            "fr": "Pakistan",
            "ja": "パキスタン",
            "it": "Pakistan"
        },
        "latitude": "30.00000000",
        "longitude": "70.00000000",
        "emoji": "🇵🇰",
        "emojiU": "U+1F1F5 U+1F1F0",
        "states": [
            {
                "id": 3172,
                "name": "Azad Kashmir",
                "state_code": "JK"
            },
            {
                "id": 3174,
                "name": "Balochistan",
                "state_code": "BA"
            },
            {
                "id": 3173,
                "name": "Federally Administered Tribal Areas",
                "state_code": "TA"
            },
            {
                "id": 3170,
                "name": "Gilgit-Baltistan",
                "state_code": "GB"
            },
            {
                "id": 3169,
                "name": "Islamabad Capital Territory",
                "state_code": "IS"
            },
            {
                "id": 3171,
                "name": "Khyber Pakhtunkhwa",
                "state_code": "KP"
            },
            {
                "id": 3176,
                "name": "Punjab",
                "state_code": "PB"
            },
            {
                "id": 3175,
                "name": "Sindh",
                "state_code": "SD"
            }
        ]
    },
    {
        "name": "Palau",
        "iso3": "PLW",
        "iso2": "PW",
        "phone_code": "680",
        "capital": "Melekeok",
        "currency": "USD",
        "currency_symbol": "$",
        "tld": ".pw",
        "native": "Palau",
        "region": "Oceania",
        "subregion": "Micronesia",
        "timezones": [
            {
                "zoneName": "Pacific/Palau",
                "gmtOffset": 32400,
                "gmtOffsetName": "UTC+09:00",
                "abbreviation": "PWT",
                "tzName": "Palau Time"
            }
        ],
        "translations": {
            "br": "Palau",
            "pt": "Palau",
            "nl": "Palau",
            "hr": "Palau",
            "fa": "پالائو",
            "de": "Palau",
            "es": "Palau",
            "fr": "Palaos",
            "ja": "パラオ",
            "it": "Palau"
        },
        "latitude": "7.50000000",
        "longitude": "134.50000000",
        "emoji": "🇵🇼",
        "emojiU": "U+1F1F5 U+1F1FC",
        "states": [
            {
                "id": 4540,
                "name": "Aimeliik",
                "state_code": "002"
            },
            {
                "id": 4528,
                "name": "Airai",
                "state_code": "004"
            },
            {
                "id": 4538,
                "name": "Angaur",
                "state_code": "010"
            },
            {
                "id": 4529,
                "name": "Hatohobei",
                "state_code": "050"
            },
            {
                "id": 4539,
                "name": "Kayangel",
                "state_code": "100"
            },
            {
                "id": 4532,
                "name": "Koror",
                "state_code": "150"
            },
            {
                "id": 4530,
                "name": "Melekeok",
                "state_code": "212"
            },
            {
                "id": 4537,
                "name": "Ngaraard",
                "state_code": "214"
            },
            {
                "id": 4533,
                "name": "Ngarchelong",
                "state_code": "218"
            },
            {
                "id": 4527,
                "name": "Ngardmau",
                "state_code": "222"
            },
            {
                "id": 4531,
                "name": "Ngatpang",
                "state_code": "224"
            },
            {
                "id": 4536,
                "name": "Ngchesar",
                "state_code": "226"
            },
            {
                "id": 4541,
                "name": "Ngeremlengui",
                "state_code": "227"
            },
            {
                "id": 4534,
                "name": "Ngiwal",
                "state_code": "228"
            },
            {
                "id": 4526,
                "name": "Peleliu",
                "state_code": "350"
            },
            {
                "id": 4535,
                "name": "Sonsorol",
                "state_code": "370"
            }
        ]
    },
    {
        "name": "Palestinian Territory Occupied",
        "iso3": "PSE",
        "iso2": "PS",
        "phone_code": "970",
        "capital": "East Jerusalem",
        "currency": "ILS",
        "currency_symbol": "₪",
        "tld": ".ps",
        "native": "فلسطين",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia/Gaza",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            },
            {
                "zoneName": "Asia/Hebron",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "translations": {
            "br": "Palestina",
            "pt": "Palestina",
            "nl": "Palestijnse gebieden",
            "hr": "Palestina",
            "fa": "فلسطین",
            "de": "Palästina",
            "es": "Palestina",
            "fr": "Palestine",
            "ja": "パレスチナ",
            "it": "Palestina"
        },
        "latitude": "31.90000000",
        "longitude": "35.20000000",
        "emoji": "🇵🇸",
        "emojiU": "U+1F1F5 U+1F1F8",
        "states": []
    },
    {
        "name": "Panama",
        "iso3": "PAN",
        "iso2": "PA",
        "phone_code": "507",
        "capital": "Panama City",
        "currency": "PAB",
        "currency_symbol": "B/.",
        "tld": ".pa",
        "native": "Panamá",
        "region": "Americas",
        "subregion": "Central America",
        "timezones": [
            {
                "zoneName": "America/Panama",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            }
        ],
        "translations": {
            "br": "Panamá",
            "pt": "Panamá",
            "nl": "Panama",
            "hr": "Panama",
            "fa": "پاناما",
            "de": "Panama",
            "es": "Panamá",
            "fr": "Panama",
            "ja": "パナマ",
            "it": "Panama"
        },
        "latitude": "9.00000000",
        "longitude": "-80.00000000",
        "emoji": "🇵🇦",
        "emojiU": "U+1F1F5 U+1F1E6",
        "states": [
            {
                "id": 1393,
                "name": "Bocas del Toro Province",
                "state_code": "1"
            },
            {
                "id": 1397,
                "name": "Chiriquí Province",
                "state_code": "4"
            },
            {
                "id": 1387,
                "name": "Coclé Province",
                "state_code": "2"
            },
            {
                "id": 1386,
                "name": "Colón Province",
                "state_code": "3"
            },
            {
                "id": 1385,
                "name": "Darién Province",
                "state_code": "5"
            },
            {
                "id": 1396,
                "name": "Emberá-Wounaan Comarca",
                "state_code": "EM"
            },
            {
                "id": 1388,
                "name": "Guna Yala",
                "state_code": "KY"
            },
            {
                "id": 1389,
                "name": "Herrera Province",
                "state_code": "6"
            },
            {
                "id": 1390,
                "name": "Los Santos Province",
                "state_code": "7"
            },
            {
                "id": 1391,
                "name": "Ngöbe-Buglé Comarca",
                "state_code": "NB"
            },
            {
                "id": 1394,
                "name": "Panamá Oeste Province",
                "state_code": "10"
            },
            {
                "id": 1395,
                "name": "Panamá Province",
                "state_code": "8"
            },
            {
                "id": 1392,
                "name": "Veraguas Province",
                "state_code": "9"
            }
        ]
    },
    {
        "name": "Papua new Guinea",
        "iso3": "PNG",
        "iso2": "PG",
        "phone_code": "675",
        "capital": "Port Moresby",
        "currency": "PGK",
        "currency_symbol": "K",
        "tld": ".pg",
        "native": "Papua Niugini",
        "region": "Oceania",
        "subregion": "Melanesia",
        "timezones": [
            {
                "zoneName": "Pacific/Bougainville",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "BST",
                "tzName": "Bougainville Standard Time[6"
            },
            {
                "zoneName": "Pacific/Port_Moresby",
                "gmtOffset": 36000,
                "gmtOffsetName": "UTC+10:00",
                "abbreviation": "PGT",
                "tzName": "Papua New Guinea Time"
            }
        ],
        "translations": {
            "br": "Papua Nova Guiné",
            "pt": "Papua Nova Guiné",
            "nl": "Papoea-Nieuw-Guinea",
            "hr": "Papua Nova Gvineja",
            "fa": "پاپوآ گینه نو",
            "de": "Papua-Neuguinea",
            "es": "Papúa Nueva Guinea",
            "fr": "Papouasie-Nouvelle-Guinée",
            "ja": "パプアニューギニア",
            "it": "Papua Nuova Guinea"
        },
        "latitude": "-6.00000000",
        "longitude": "147.00000000",
        "emoji": "🇵🇬",
        "emojiU": "U+1F1F5 U+1F1EC",
        "states": [
            {
                "id": 4831,
                "name": "Bougainville",
                "state_code": "NSB"
            },
            {
                "id": 4847,
                "name": "Central Province",
                "state_code": "CPM"
            },
            {
                "id": 4846,
                "name": "Chimbu Province",
                "state_code": "CPK"
            },
            {
                "id": 4834,
                "name": "East New Britain",
                "state_code": "EBR"
            },
            {
                "id": 4845,
                "name": "Eastern Highlands Province",
                "state_code": "EHG"
            },
            {
                "id": 4848,
                "name": "Enga Province",
                "state_code": "EPW"
            },
            {
                "id": 4839,
                "name": "Gulf",
                "state_code": "GPK"
            },
            {
                "id": 4833,
                "name": "Hela",
                "state_code": "HLA"
            },
            {
                "id": 4832,
                "name": "Jiwaka Province",
                "state_code": "JWK"
            },
            {
                "id": 4843,
                "name": "Madang Province",
                "state_code": "MPM"
            },
            {
                "id": 4842,
                "name": "Manus Province",
                "state_code": "MRL"
            },
            {
                "id": 4849,
                "name": "Milne Bay Province",
                "state_code": "MBA"
            },
            {
                "id": 4835,
                "name": "Morobe Province",
                "state_code": "MPL"
            },
            {
                "id": 4841,
                "name": "New Ireland Province",
                "state_code": "NIK"
            },
            {
                "id": 4838,
                "name": "Oro Province",
                "state_code": "NPP"
            },
            {
                "id": 4837,
                "name": "Port Moresby",
                "state_code": "NCD"
            },
            {
                "id": 4836,
                "name": "Sandaun Province",
                "state_code": "SAN"
            },
            {
                "id": 4844,
                "name": "Southern Highlands Province",
                "state_code": "SHM"
            },
            {
                "id": 4830,
                "name": "West New Britain Province",
                "state_code": "WBK"
            },
            {
                "id": 4840,
                "name": "Western Highlands Province",
                "state_code": "WHM"
            },
            {
                "id": 4850,
                "name": "Western Province",
                "state_code": "WPD"
            }
        ]
    },
    {
        "name": "Paraguay",
        "iso3": "PRY",
        "iso2": "PY",
        "phone_code": "595",
        "capital": "Asuncion",
        "currency": "PYG",
        "currency_symbol": "₲",
        "tld": ".py",
        "native": "Paraguay",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America/Asuncion",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "PYST",
                "tzName": "Paraguay Summer Time"
            }
        ],
        "translations": {
            "br": "Paraguai",
            "pt": "Paraguai",
            "nl": "Paraguay",
            "hr": "Paragvaj",
            "fa": "پاراگوئه",
            "de": "Paraguay",
            "es": "Paraguay",
            "fr": "Paraguay",
            "ja": "パラグアイ",
            "it": "Paraguay"
        },
        "latitude": "-23.00000000",
        "longitude": "-58.00000000",
        "emoji": "🇵🇾",
        "emojiU": "U+1F1F5 U+1F1FE",
        "states": [
            {
                "id": 2785,
                "name": "Alto Paraguay Department",
                "state_code": "16"
            },
            {
                "id": 2784,
                "name": "Alto Paraná Department",
                "state_code": "10"
            },
            {
                "id": 2782,
                "name": "Amambay Department",
                "state_code": "13"
            },
            {
                "id": 2780,
                "name": "Boquerón Department",
                "state_code": "19"
            },
            {
                "id": 2773,
                "name": "Caaguazú",
                "state_code": "5"
            },
            {
                "id": 2775,
                "name": "Caazapá",
                "state_code": "6"
            },
            {
                "id": 2771,
                "name": "Canindeyú",
                "state_code": "14"
            },
            {
                "id": 2777,
                "name": "Central Department",
                "state_code": "11"
            },
            {
                "id": 2779,
                "name": "Concepción Department",
                "state_code": "1"
            },
            {
                "id": 2783,
                "name": "Cordillera Department",
                "state_code": "3"
            },
            {
                "id": 2772,
                "name": "Guairá Department",
                "state_code": "4"
            },
            {
                "id": 2778,
                "name": "Itapúa",
                "state_code": "7"
            },
            {
                "id": 2786,
                "name": "Misiones Department",
                "state_code": "8"
            },
            {
                "id": 2781,
                "name": "Ñeembucú Department",
                "state_code": "12"
            },
            {
                "id": 2774,
                "name": "Paraguarí Department",
                "state_code": "9"
            },
            {
                "id": 2770,
                "name": "Presidente Hayes Department",
                "state_code": "15"
            },
            {
                "id": 2776,
                "name": "San Pedro Department",
                "state_code": "2"
            }
        ]
    },
    {
        "name": "Peru",
        "iso3": "PER",
        "iso2": "PE",
        "phone_code": "51",
        "capital": "Lima",
        "currency": "PEN",
        "currency_symbol": "S/.",
        "tld": ".pe",
        "native": "Perú",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America/Lima",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "PET",
                "tzName": "Peru Time"
            }
        ],
        "translations": {
            "br": "Peru",
            "pt": "Peru",
            "nl": "Peru",
            "hr": "Peru",
            "fa": "پرو",
            "de": "Peru",
            "es": "Perú",
            "fr": "Pérou",
            "ja": "ペルー",
            "it": "Perù"
        },
        "latitude": "-10.00000000",
        "longitude": "-76.00000000",
        "emoji": "🇵🇪",
        "emojiU": "U+1F1F5 U+1F1EA",
        "states": [
            {
                "id": 3685,
                "name": "Amazonas",
                "state_code": "AMA"
            },
            {
                "id": 3680,
                "name": "Áncash",
                "state_code": "ANC"
            },
            {
                "id": 3699,
                "name": "Apurímac",
                "state_code": "APU"
            },
            {
                "id": 3681,
                "name": "Arequipa",
                "state_code": "ARE"
            },
            {
                "id": 3692,
                "name": "Ayacucho",
                "state_code": "AYA"
            },
            {
                "id": 3688,
                "name": "Cajamarca",
                "state_code": "CAJ"
            },
            {
                "id": 3701,
                "name": "Callao",
                "state_code": "CAL"
            },
            {
                "id": 3691,
                "name": "Cusco",
                "state_code": "CUS"
            },
            {
                "id": 3679,
                "name": "Huancavelica",
                "state_code": "HUV"
            },
            {
                "id": 3687,
                "name": "Huanuco",
                "state_code": "HUC"
            },
            {
                "id": 3700,
                "name": "Ica",
                "state_code": "ICA"
            },
            {
                "id": 3693,
                "name": "Junín",
                "state_code": "JUN"
            },
            {
                "id": 3683,
                "name": "La Libertad",
                "state_code": "LAL"
            },
            {
                "id": 3702,
                "name": "Lambayeque",
                "state_code": "LAM"
            },
            {
                "id": 3695,
                "name": "Lima",
                "state_code": "LIM"
            },
            {
                "id": 3678,
                "name": "Madre de Dios",
                "state_code": "MDD"
            },
            {
                "id": 3698,
                "name": "Moquegua",
                "state_code": "MOQ"
            },
            {
                "id": 3686,
                "name": "Pasco",
                "state_code": "PAS"
            },
            {
                "id": 3697,
                "name": "Piura",
                "state_code": "PIU"
            },
            {
                "id": 3682,
                "name": "Puno",
                "state_code": "PUN"
            },
            {
                "id": 3694,
                "name": "San Martín",
                "state_code": "SAM"
            },
            {
                "id": 3696,
                "name": "Tacna",
                "state_code": "TAC"
            },
            {
                "id": 3689,
                "name": "Tumbes",
                "state_code": "TUM"
            },
            {
                "id": 3684,
                "name": "Ucayali",
                "state_code": "UCA"
            }
        ]
    },
    {
        "name": "Philippines",
        "iso3": "PHL",
        "iso2": "PH",
        "phone_code": "63",
        "capital": "Manila",
        "currency": "PHP",
        "currency_symbol": "₱",
        "tld": ".ph",
        "native": "Pilipinas",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Manila",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "PHT",
                "tzName": "Philippine Time"
            }
        ],
        "translations": {
            "br": "Filipinas",
            "pt": "Filipinas",
            "nl": "Filipijnen",
            "hr": "Filipini",
            "fa": "جزایر الندفیلیپین",
            "de": "Philippinen",
            "es": "Filipinas",
            "fr": "Philippines",
            "ja": "フィリピン",
            "it": "Filippine"
        },
        "latitude": "13.00000000",
        "longitude": "122.00000000",
        "emoji": "🇵🇭",
        "emojiU": "U+1F1F5 U+1F1ED",
        "states": [
            {
                "id": 1324,
                "name": "Abra",
                "state_code": "ABR"
            },
            {
                "id": 1323,
                "name": "Agusan del Norte",
                "state_code": "AGN"
            },
            {
                "id": 1326,
                "name": "Agusan del Sur",
                "state_code": "AGS"
            },
            {
                "id": 1331,
                "name": "Aklan",
                "state_code": "AKL"
            },
            {
                "id": 1337,
                "name": "Albay",
                "state_code": "ALB"
            },
            {
                "id": 1336,
                "name": "Antique",
                "state_code": "ANT"
            },
            {
                "id": 1334,
                "name": "Apayao",
                "state_code": "APA"
            },
            {
                "id": 1341,
                "name": "Aurora",
                "state_code": "AUR"
            },
            {
                "id": 1316,
                "name": "Autonomous Region in Muslim Mindanao",
                "state_code": "14"
            },
            {
                "id": 1346,
                "name": "Basilan",
                "state_code": "BAS"
            },
            {
                "id": 1344,
                "name": "Bataan",
                "state_code": "BAN"
            },
            {
                "id": 1352,
                "name": "Batanes",
                "state_code": "BTN"
            },
            {
                "id": 1359,
                "name": "Batangas",
                "state_code": "BTG"
            },
            {
                "id": 1363,
                "name": "Benguet",
                "state_code": "BEN"
            },
            {
                "id": 1304,
                "name": "Bicol Region",
                "state_code": "05"
            },
            {
                "id": 1274,
                "name": "Biliran",
                "state_code": "BIL"
            },
            {
                "id": 1272,
                "name": "Bohol",
                "state_code": "BOH"
            },
            {
                "id": 1270,
                "name": "Bukidnon",
                "state_code": "BUK"
            },
            {
                "id": 1278,
                "name": "Bulacan",
                "state_code": "BUL"
            },
            {
                "id": 1279,
                "name": "Cagayan",
                "state_code": "CAG"
            },
            {
                "id": 1342,
                "name": "Cagayan Valley",
                "state_code": "02"
            },
            {
                "id": 1294,
                "name": "Calabarzon",
                "state_code": "40"
            },
            {
                "id": 1283,
                "name": "Camarines Norte",
                "state_code": "CAN"
            },
            {
                "id": 1287,
                "name": "Camarines Sur",
                "state_code": "CAS"
            },
            {
                "id": 1285,
                "name": "Camiguin",
                "state_code": "CAM"
            },
            {
                "id": 1292,
                "name": "Capiz",
                "state_code": "CAP"
            },
            {
                "id": 1314,
                "name": "Caraga",
                "state_code": "13"
            },
            {
                "id": 1301,
                "name": "Catanduanes",
                "state_code": "CAT"
            },
            {
                "id": 1307,
                "name": "Cavite",
                "state_code": "CAV"
            },
            {
                "id": 1306,
                "name": "Cebu",
                "state_code": "CEB"
            },
            {
                "id": 1345,
                "name": "Central Luzon",
                "state_code": "03"
            },
            {
                "id": 1308,
                "name": "Central Visayas",
                "state_code": "07"
            },
            {
                "id": 1311,
                "name": "Compostela Valley",
                "state_code": "COM"
            },
            {
                "id": 1335,
                "name": "Cordillera Administrative Region",
                "state_code": "15"
            },
            {
                "id": 1320,
                "name": "Cotabato",
                "state_code": "NCO"
            },
            {
                "id": 1319,
                "name": "Davao del Norte",
                "state_code": "DAV"
            },
            {
                "id": 1318,
                "name": "Davao del Sur",
                "state_code": "DAS"
            },
            {
                "id": 1309,
                "name": "Davao Occidental",
                "state_code": "DVO"
            },
            {
                "id": 1289,
                "name": "Davao Oriental",
                "state_code": "DAO"
            },
            {
                "id": 1340,
                "name": "Davao Region",
                "state_code": "11"
            },
            {
                "id": 1291,
                "name": "Dinagat Islands",
                "state_code": "DIN"
            },
            {
                "id": 1290,
                "name": "Eastern Samar",
                "state_code": "EAS"
            },
            {
                "id": 1322,
                "name": "Eastern Visayas",
                "state_code": "08"
            },
            {
                "id": 1303,
                "name": "Guimaras",
                "state_code": "GUI"
            },
            {
                "id": 1300,
                "name": "Ifugao",
                "state_code": "IFU"
            },
            {
                "id": 1298,
                "name": "Ilocos Norte",
                "state_code": "ILN"
            },
            {
                "id": 1355,
                "name": "Ilocos Region",
                "state_code": "01"
            },
            {
                "id": 1321,
                "name": "Ilocos Sur",
                "state_code": "ILS"
            },
            {
                "id": 1315,
                "name": "Iloilo",
                "state_code": "ILI"
            },
            {
                "id": 1313,
                "name": "Isabela",
                "state_code": "ISA"
            },
            {
                "id": 1312,
                "name": "Kalinga",
                "state_code": "KAL"
            },
            {
                "id": 1317,
                "name": "La Union",
                "state_code": "LUN"
            },
            {
                "id": 1328,
                "name": "Laguna",
                "state_code": "LAG"
            },
            {
                "id": 1327,
                "name": "Lanao del Norte",
                "state_code": "LAN"
            },
            {
                "id": 1333,
                "name": "Lanao del Sur",
                "state_code": "LAS"
            },
            {
                "id": 1332,
                "name": "Leyte",
                "state_code": "LEY"
            },
            {
                "id": 1330,
                "name": "Maguindanao",
                "state_code": "MAG"
            },
            {
                "id": 1329,
                "name": "Marinduque",
                "state_code": "MAD"
            },
            {
                "id": 1338,
                "name": "Masbate",
                "state_code": "MAS"
            },
            {
                "id": 1347,
                "name": "Metro Manila",
                "state_code": "NCR"
            },
            {
                "id": 1299,
                "name": "Mimaropa",
                "state_code": "41"
            },
            {
                "id": 1343,
                "name": "Misamis Occidental",
                "state_code": "MSC"
            },
            {
                "id": 1348,
                "name": "Misamis Oriental",
                "state_code": "MSR"
            },
            {
                "id": 1353,
                "name": "Mountain Province",
                "state_code": "MOU"
            },
            {
                "id": 1351,
                "name": "Negros Occidental",
                "state_code": "NEC"
            },
            {
                "id": 1350,
                "name": "Negros Oriental",
                "state_code": "NER"
            },
            {
                "id": 1339,
                "name": "Northern Mindanao",
                "state_code": "10"
            },
            {
                "id": 1349,
                "name": "Northern Samar",
                "state_code": "NSA"
            },
            {
                "id": 1360,
                "name": "Nueva Ecija",
                "state_code": "NUE"
            },
            {
                "id": 1358,
                "name": "Nueva Vizcaya",
                "state_code": "NUV"
            },
            {
                "id": 1356,
                "name": "Occidental Mindoro",
                "state_code": "MDC"
            },
            {
                "id": 1354,
                "name": "Oriental Mindoro",
                "state_code": "MDR"
            },
            {
                "id": 1361,
                "name": "Palawan",
                "state_code": "PLW"
            },
            {
                "id": 1365,
                "name": "Pampanga",
                "state_code": "PAM"
            },
            {
                "id": 1364,
                "name": "Pangasinan",
                "state_code": "PAN"
            },
            {
                "id": 1275,
                "name": "Quezon",
                "state_code": "QUE"
            },
            {
                "id": 1273,
                "name": "Quirino",
                "state_code": "QUI"
            },
            {
                "id": 1271,
                "name": "Rizal",
                "state_code": "RIZ"
            },
            {
                "id": 1269,
                "name": "Romblon",
                "state_code": "ROM"
            },
            {
                "id": 1277,
                "name": "Sarangani",
                "state_code": "SAR"
            },
            {
                "id": 1276,
                "name": "Siquijor",
                "state_code": "SIG"
            },
            {
                "id": 1310,
                "name": "Soccsksargen",
                "state_code": "12"
            },
            {
                "id": 1281,
                "name": "Sorsogon",
                "state_code": "SOR"
            },
            {
                "id": 1280,
                "name": "South Cotabato",
                "state_code": "SCO"
            },
            {
                "id": 1284,
                "name": "Southern Leyte",
                "state_code": "SLE"
            },
            {
                "id": 1282,
                "name": "Sultan Kudarat",
                "state_code": "SUK"
            },
            {
                "id": 1288,
                "name": "Sulu",
                "state_code": "SLU"
            },
            {
                "id": 1286,
                "name": "Surigao del Norte",
                "state_code": "SUN"
            },
            {
                "id": 1296,
                "name": "Surigao del Sur",
                "state_code": "SUR"
            },
            {
                "id": 1295,
                "name": "Tarlac",
                "state_code": "TAR"
            },
            {
                "id": 1293,
                "name": "Tawi-Tawi",
                "state_code": "TAW"
            },
            {
                "id": 1305,
                "name": "Western Visayas",
                "state_code": "06"
            },
            {
                "id": 1297,
                "name": "Zambales",
                "state_code": "ZMB"
            },
            {
                "id": 1302,
                "name": "Zamboanga del Norte",
                "state_code": "ZAN"
            },
            {
                "id": 1357,
                "name": "Zamboanga del Sur",
                "state_code": "ZAS"
            },
            {
                "id": 1325,
                "name": "Zamboanga Peninsula",
                "state_code": "09"
            },
            {
                "id": 1362,
                "name": "Zamboanga Sibugay",
                "state_code": "ZSI"
            }
        ]
    },
    {
        "name": "Pitcairn Island",
        "iso3": "PCN",
        "iso2": "PN",
        "phone_code": "870",
        "capital": "Adamstown",
        "currency": "NZD",
        "currency_symbol": "$",
        "tld": ".pn",
        "native": "Pitcairn Islands",
        "region": "Oceania",
        "subregion": "Polynesia",
        "timezones": [
            {
                "zoneName": "Pacific/Pitcairn",
                "gmtOffset": -28800,
                "gmtOffsetName": "UTC-08:00",
                "abbreviation": "PST",
                "tzName": "Pacific Standard Time (North America"
            }
        ],
        "translations": {
            "br": "Ilhas Pitcairn",
            "pt": "Ilhas Picárnia",
            "nl": "Pitcairneilanden",
            "hr": "Pitcairnovo otočje",
            "fa": "پیتکرن",
            "de": "Pitcairn",
            "es": "Islas Pitcairn",
            "fr": "Îles Pitcairn",
            "ja": "ピトケアン",
            "it": "Isole Pitcairn"
        },
        "latitude": "-25.06666666",
        "longitude": "-130.10000000",
        "emoji": "🇵🇳",
        "emojiU": "U+1F1F5 U+1F1F3",
        "states": []
    },
    {
        "name": "Poland",
        "iso3": "POL",
        "iso2": "PL",
        "phone_code": "48",
        "capital": "Warsaw",
        "currency": "PLN",
        "currency_symbol": "zł",
        "tld": ".pl",
        "native": "Polska",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Warsaw",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Polônia",
            "pt": "Polónia",
            "nl": "Polen",
            "hr": "Poljska",
            "fa": "لهستان",
            "de": "Polen",
            "es": "Polonia",
            "fr": "Pologne",
            "ja": "ポーランド",
            "it": "Polonia"
        },
        "latitude": "52.00000000",
        "longitude": "20.00000000",
        "emoji": "🇵🇱",
        "emojiU": "U+1F1F5 U+1F1F1",
        "states": [
            {
                "id": 1634,
                "name": "Greater Poland Voivodeship",
                "state_code": "WP"
            },
            {
                "id": 1625,
                "name": "Kuyavian-Pomeranian Voivodeship",
                "state_code": "KP"
            },
            {
                "id": 1635,
                "name": "Lesser Poland Voivodeship",
                "state_code": "MA"
            },
            {
                "id": 1629,
                "name": "Lower Silesian Voivodeship",
                "state_code": "DS"
            },
            {
                "id": 1638,
                "name": "Lublin Voivodeship",
                "state_code": "LU"
            },
            {
                "id": 1631,
                "name": "Lubusz Voivodeship",
                "state_code": "LB"
            },
            {
                "id": 1636,
                "name": "Łódź Voivodeship",
                "state_code": "LD"
            },
            {
                "id": 1637,
                "name": "Masovian Voivodeship",
                "state_code": "MZ"
            },
            {
                "id": 1622,
                "name": "Opole Voivodeship",
                "state_code": "OP"
            },
            {
                "id": 1626,
                "name": "Podkarpackie Voivodeship",
                "state_code": "PK"
            },
            {
                "id": 1632,
                "name": "Podlaskie Voivodeship",
                "state_code": "PD"
            },
            {
                "id": 1624,
                "name": "Pomeranian Voivodeship",
                "state_code": "PM"
            },
            {
                "id": 1623,
                "name": "Silesian Voivodeship",
                "state_code": "SL"
            },
            {
                "id": 1630,
                "name": "Świętokrzyskie Voivodeship",
                "state_code": "SK"
            },
            {
                "id": 1628,
                "name": "Warmian-Masurian Voivodeship",
                "state_code": "WN"
            },
            {
                "id": 1633,
                "name": "West Pomeranian Voivodeship",
                "state_code": "ZP"
            }
        ]
    },
    {
        "name": "Portugal",
        "iso3": "PRT",
        "iso2": "PT",
        "phone_code": "351",
        "capital": "Lisbon",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".pt",
        "native": "Portugal",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Atlantic/Azores",
                "gmtOffset": -3600,
                "gmtOffsetName": "UTC-01:00",
                "abbreviation": "AZOT",
                "tzName": "Azores Standard Time"
            },
            {
                "zoneName": "Atlantic/Madeira",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "WET",
                "tzName": "Western European Time"
            },
            {
                "zoneName": "Europe/Lisbon",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "WET",
                "tzName": "Western European Time"
            }
        ],
        "translations": {
            "br": "Portugal",
            "pt": "Portugal",
            "nl": "Portugal",
            "hr": "Portugal",
            "fa": "پرتغال",
            "de": "Portugal",
            "es": "Portugal",
            "fr": "Portugal",
            "ja": "ポルトガル",
            "it": "Portogallo"
        },
        "latitude": "39.50000000",
        "longitude": "-8.00000000",
        "emoji": "🇵🇹",
        "emojiU": "U+1F1F5 U+1F1F9",
        "states": [
            {
                "id": 2233,
                "name": "Açores",
                "state_code": "20"
            },
            {
                "id": 2235,
                "name": "Aveiro",
                "state_code": "01"
            },
            {
                "id": 2230,
                "name": "Beja",
                "state_code": "02"
            },
            {
                "id": 2244,
                "name": "Braga",
                "state_code": "03"
            },
            {
                "id": 2229,
                "name": "Bragança",
                "state_code": "04"
            },
            {
                "id": 2241,
                "name": "Castelo Branco",
                "state_code": "05"
            },
            {
                "id": 2246,
                "name": "Coimbra",
                "state_code": "06"
            },
            {
                "id": 2236,
                "name": "Évora",
                "state_code": "07"
            },
            {
                "id": 2239,
                "name": "Faro",
                "state_code": "08"
            },
            {
                "id": 4859,
                "name": "Guarda",
                "state_code": "09"
            },
            {
                "id": 2240,
                "name": "Leiria",
                "state_code": "10"
            },
            {
                "id": 2228,
                "name": "Lisbon",
                "state_code": "11"
            },
            {
                "id": 2231,
                "name": "Madeira",
                "state_code": "30"
            },
            {
                "id": 2232,
                "name": "Portalegre",
                "state_code": "12"
            },
            {
                "id": 2243,
                "name": "Porto",
                "state_code": "13"
            },
            {
                "id": 2238,
                "name": "Santarém",
                "state_code": "14"
            },
            {
                "id": 2242,
                "name": "Setúbal",
                "state_code": "15"
            },
            {
                "id": 2245,
                "name": "Viana do Castelo",
                "state_code": "16"
            },
            {
                "id": 2234,
                "name": "Vila Real",
                "state_code": "17"
            },
            {
                "id": 2237,
                "name": "Viseu",
                "state_code": "18"
            }
        ]
    },
    {
        "name": "Puerto Rico",
        "iso3": "PRI",
        "iso2": "PR",
        "phone_code": "+1-787 and 1-939",
        "capital": "San Juan",
        "currency": "USD",
        "currency_symbol": "$",
        "tld": ".pr",
        "native": "Puerto Rico",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/Puerto_Rico",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "translations": {
            "br": "Porto Rico",
            "pt": "Porto Rico",
            "nl": "Puerto Rico",
            "hr": "Portoriko",
            "fa": "پورتو ریکو",
            "de": "Puerto Rico",
            "es": "Puerto Rico",
            "fr": "Porto Rico",
            "ja": "プエルトリコ",
            "it": "Porto Rico"
        },
        "latitude": "18.25000000",
        "longitude": "-66.50000000",
        "emoji": "🇵🇷",
        "emojiU": "U+1F1F5 U+1F1F7",
        "states": []
    },
    {
        "name": "Qatar",
        "iso3": "QAT",
        "iso2": "QA",
        "phone_code": "974",
        "capital": "Doha",
        "currency": "QAR",
        "currency_symbol": "ق.ر",
        "tld": ".qa",
        "native": "قطر",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia/Qatar",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "AST",
                "tzName": "Arabia Standard Time"
            }
        ],
        "translations": {
            "br": "Catar",
            "pt": "Catar",
            "nl": "Qatar",
            "hr": "Katar",
            "fa": "قطر",
            "de": "Katar",
            "es": "Catar",
            "fr": "Qatar",
            "ja": "カタール",
            "it": "Qatar"
        },
        "latitude": "25.50000000",
        "longitude": "51.25000000",
        "emoji": "🇶🇦",
        "emojiU": "U+1F1F6 U+1F1E6",
        "states": [
            {
                "id": 3182,
                "name": "Al Daayen",
                "state_code": "ZA"
            },
            {
                "id": 3183,
                "name": "Al Khor",
                "state_code": "KH"
            },
            {
                "id": 3177,
                "name": "Al Rayyan Municipality",
                "state_code": "RA"
            },
            {
                "id": 3179,
                "name": "Al Wakrah",
                "state_code": "WA"
            },
            {
                "id": 3178,
                "name": "Al-Shahaniya",
                "state_code": "SH"
            },
            {
                "id": 3181,
                "name": "Doha",
                "state_code": "DA"
            },
            {
                "id": 3180,
                "name": "Madinat ash Shamal",
                "state_code": "MS"
            },
            {
                "id": 3184,
                "name": "Umm Salal Municipality",
                "state_code": "US"
            }
        ]
    },
    {
        "name": "Reunion",
        "iso3": "REU",
        "iso2": "RE",
        "phone_code": "262",
        "capital": "Saint-Denis",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".re",
        "native": "La Réunion",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Indian/Reunion",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "RET",
                "tzName": "Réunion Time"
            }
        ],
        "translations": {
            "br": "Reunião",
            "pt": "Reunião",
            "nl": "Réunion",
            "hr": "Réunion",
            "fa": "رئونیون",
            "de": "Réunion",
            "es": "Reunión",
            "fr": "Réunion",
            "ja": "レユニオン",
            "it": "Riunione"
        },
        "latitude": "-21.15000000",
        "longitude": "55.50000000",
        "emoji": "🇷🇪",
        "emojiU": "U+1F1F7 U+1F1EA",
        "states": []
    },
    {
        "name": "Romania",
        "iso3": "ROU",
        "iso2": "RO",
        "phone_code": "40",
        "capital": "Bucharest",
        "currency": "RON",
        "currency_symbol": "lei",
        "tld": ".ro",
        "native": "România",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Bucharest",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "translations": {
            "br": "Romênia",
            "pt": "Roménia",
            "nl": "Roemenië",
            "hr": "Rumunjska",
            "fa": "رومانی",
            "de": "Rumänien",
            "es": "Rumania",
            "fr": "Roumanie",
            "ja": "ルーマニア",
            "it": "Romania"
        },
        "latitude": "46.00000000",
        "longitude": "25.00000000",
        "emoji": "🇷🇴",
        "emojiU": "U+1F1F7 U+1F1F4",
        "states": [
            {
                "id": 4724,
                "name": "Alba",
                "state_code": "AB"
            },
            {
                "id": 4739,
                "name": "Arad County",
                "state_code": "AR"
            },
            {
                "id": 4722,
                "name": "Arges",
                "state_code": "AG"
            },
            {
                "id": 4744,
                "name": "Bacău County",
                "state_code": "BC"
            },
            {
                "id": 4723,
                "name": "Bihor County",
                "state_code": "BH"
            },
            {
                "id": 4733,
                "name": "Bistrița-Năsăud County",
                "state_code": "BN"
            },
            {
                "id": 4740,
                "name": "Botoșani County",
                "state_code": "BT"
            },
            {
                "id": 4736,
                "name": "Braila",
                "state_code": "BR"
            },
            {
                "id": 4759,
                "name": "Brașov County",
                "state_code": "BV"
            },
            {
                "id": 4730,
                "name": "Bucharest",
                "state_code": "B"
            },
            {
                "id": 4756,
                "name": "Buzău County",
                "state_code": "BZ"
            },
            {
                "id": 4732,
                "name": "Călărași County",
                "state_code": "CL"
            },
            {
                "id": 4753,
                "name": "Caraș-Severin County",
                "state_code": "CS"
            },
            {
                "id": 4734,
                "name": "Cluj County",
                "state_code": "CJ"
            },
            {
                "id": 4737,
                "name": "Constanța County",
                "state_code": "CT"
            },
            {
                "id": 4754,
                "name": "Covasna County",
                "state_code": "CV"
            },
            {
                "id": 4745,
                "name": "Dâmbovița County",
                "state_code": "DB"
            },
            {
                "id": 4742,
                "name": "Dolj County",
                "state_code": "DJ"
            },
            {
                "id": 4747,
                "name": "Galați County",
                "state_code": "GL"
            },
            {
                "id": 4726,
                "name": "Giurgiu County",
                "state_code": "GR"
            },
            {
                "id": 4750,
                "name": "Gorj County",
                "state_code": "GJ"
            },
            {
                "id": 4749,
                "name": "Harghita County",
                "state_code": "HR"
            },
            {
                "id": 4721,
                "name": "Hunedoara County",
                "state_code": "HD"
            },
            {
                "id": 4743,
                "name": "Ialomița County",
                "state_code": "IL"
            },
            {
                "id": 4735,
                "name": "Iași County",
                "state_code": "IS"
            },
            {
                "id": 4725,
                "name": "Ilfov County",
                "state_code": "IF"
            },
            {
                "id": 4760,
                "name": "Maramureș County",
                "state_code": "MM"
            },
            {
                "id": 4751,
                "name": "Mehedinți County",
                "state_code": "MH"
            },
            {
                "id": 4915,
                "name": "Mureș County",
                "state_code": "MS"
            },
            {
                "id": 4731,
                "name": "Neamț County",
                "state_code": "NT"
            },
            {
                "id": 4738,
                "name": "Olt County",
                "state_code": "OT"
            },
            {
                "id": 4729,
                "name": "Prahova County",
                "state_code": "PH"
            },
            {
                "id": 4741,
                "name": "Sălaj County",
                "state_code": "SJ"
            },
            {
                "id": 4746,
                "name": "Satu Mare County",
                "state_code": "SM"
            },
            {
                "id": 4755,
                "name": "Sibiu County",
                "state_code": "SB"
            },
            {
                "id": 4720,
                "name": "Suceava County",
                "state_code": "SV"
            },
            {
                "id": 4728,
                "name": "Teleorman County",
                "state_code": "TR"
            },
            {
                "id": 4748,
                "name": "Timiș County",
                "state_code": "TM"
            },
            {
                "id": 4727,
                "name": "Tulcea County",
                "state_code": "TL"
            },
            {
                "id": 4757,
                "name": "Vâlcea County",
                "state_code": "VL"
            },
            {
                "id": 4752,
                "name": "Vaslui County",
                "state_code": "VS"
            },
            {
                "id": 4758,
                "name": "Vrancea County",
                "state_code": "VN"
            }
        ]
    },
    {
        "name": "Russia",
        "iso3": "RUS",
        "iso2": "RU",
        "phone_code": "7",
        "capital": "Moscow",
        "currency": "RUB",
        "currency_symbol": "₽",
        "tld": ".ru",
        "native": "Россия",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "timezones": [
            {
                "zoneName": "Asia/Anadyr",
                "gmtOffset": 43200,
                "gmtOffsetName": "UTC+12:00",
                "abbreviation": "ANAT",
                "tzName": "Anadyr Time[4"
            },
            {
                "zoneName": "Asia/Barnaul",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "KRAT",
                "tzName": "Krasnoyarsk Time"
            },
            {
                "zoneName": "Asia/Chita",
                "gmtOffset": 32400,
                "gmtOffsetName": "UTC+09:00",
                "abbreviation": "YAKT",
                "tzName": "Yakutsk Time"
            },
            {
                "zoneName": "Asia/Irkutsk",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "IRKT",
                "tzName": "Irkutsk Time"
            },
            {
                "zoneName": "Asia/Kamchatka",
                "gmtOffset": 43200,
                "gmtOffsetName": "UTC+12:00",
                "abbreviation": "PETT",
                "tzName": "Kamchatka Time"
            },
            {
                "zoneName": "Asia/Khandyga",
                "gmtOffset": 32400,
                "gmtOffsetName": "UTC+09:00",
                "abbreviation": "YAKT",
                "tzName": "Yakutsk Time"
            },
            {
                "zoneName": "Asia/Krasnoyarsk",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "KRAT",
                "tzName": "Krasnoyarsk Time"
            },
            {
                "zoneName": "Asia/Magadan",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "MAGT",
                "tzName": "Magadan Time"
            },
            {
                "zoneName": "Asia/Novokuznetsk",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "KRAT",
                "tzName": "Krasnoyarsk Time"
            },
            {
                "zoneName": "Asia/Novosibirsk",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "NOVT",
                "tzName": "Novosibirsk Time"
            },
            {
                "zoneName": "Asia/Omsk",
                "gmtOffset": 21600,
                "gmtOffsetName": "UTC+06:00",
                "abbreviation": "OMST",
                "tzName": "Omsk Time"
            },
            {
                "zoneName": "Asia/Sakhalin",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "SAKT",
                "tzName": "Sakhalin Island Time"
            },
            {
                "zoneName": "Asia/Srednekolymsk",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "SRET",
                "tzName": "Srednekolymsk Time"
            },
            {
                "zoneName": "Asia/Tomsk",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "MSD+3",
                "tzName": "Moscow Daylight Time+3"
            },
            {
                "zoneName": "Asia/Ust-Nera",
                "gmtOffset": 36000,
                "gmtOffsetName": "UTC+10:00",
                "abbreviation": "VLAT",
                "tzName": "Vladivostok Time"
            },
            {
                "zoneName": "Asia/Vladivostok",
                "gmtOffset": 36000,
                "gmtOffsetName": "UTC+10:00",
                "abbreviation": "VLAT",
                "tzName": "Vladivostok Time"
            },
            {
                "zoneName": "Asia/Yakutsk",
                "gmtOffset": 32400,
                "gmtOffsetName": "UTC+09:00",
                "abbreviation": "YAKT",
                "tzName": "Yakutsk Time"
            },
            {
                "zoneName": "Asia/Yekaterinburg",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "YEKT",
                "tzName": "Yekaterinburg Time"
            },
            {
                "zoneName": "Europe/Astrakhan",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "SAMT",
                "tzName": "Samara Time"
            },
            {
                "zoneName": "Europe/Kaliningrad",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            },
            {
                "zoneName": "Europe/Kirov",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "MSK",
                "tzName": "Moscow Time"
            },
            {
                "zoneName": "Europe/Moscow",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "MSK",
                "tzName": "Moscow Time"
            },
            {
                "zoneName": "Europe/Samara",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "SAMT",
                "tzName": "Samara Time"
            },
            {
                "zoneName": "Europe/Saratov",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "MSD",
                "tzName": "Moscow Daylight Time+4"
            },
            {
                "zoneName": "Europe/Ulyanovsk",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "SAMT",
                "tzName": "Samara Time"
            },
            {
                "zoneName": "Europe/Volgograd",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "MSK",
                "tzName": "Moscow Standard Time"
            }
        ],
        "translations": {
            "br": "Rússia",
            "pt": "Rússia",
            "nl": "Rusland",
            "hr": "Rusija",
            "fa": "روسیه",
            "de": "Russland",
            "es": "Rusia",
            "fr": "Russie",
            "ja": "ロシア連邦",
            "it": "Russia"
        },
        "latitude": "60.00000000",
        "longitude": "100.00000000",
        "emoji": "🇷🇺",
        "emojiU": "U+1F1F7 U+1F1FA",
        "states": [
            {
                "id": 1911,
                "name": "Altai Krai",
                "state_code": "ALT"
            },
            {
                "id": 1876,
                "name": "Altai Republic",
                "state_code": "AL"
            },
            {
                "id": 1858,
                "name": "Amur Oblast",
                "state_code": "AMU"
            },
            {
                "id": 1849,
                "name": "Arkhangelsk",
                "state_code": "ARK"
            },
            {
                "id": 1866,
                "name": "Astrakhan Oblast",
                "state_code": "AST"
            },
            {
                "id": 1903,
                "name": "Belgorod Oblast",
                "state_code": "BEL"
            },
            {
                "id": 1867,
                "name": "Bryansk Oblast",
                "state_code": "BRY"
            },
            {
                "id": 1893,
                "name": "Chechen Republic",
                "state_code": "CE"
            },
            {
                "id": 1845,
                "name": "Chelyabinsk Oblast",
                "state_code": "CHE"
            },
            {
                "id": 1859,
                "name": "Chukotka Autonomous Okrug",
                "state_code": "CHU"
            },
            {
                "id": 1914,
                "name": "Chuvash Republic",
                "state_code": "CU"
            },
            {
                "id": 1880,
                "name": "Irkutsk",
                "state_code": "IRK"
            },
            {
                "id": 1864,
                "name": "Ivanovo Oblast",
                "state_code": "IVA"
            },
            {
                "id": 1835,
                "name": "Jewish Autonomous Oblast",
                "state_code": "YEV"
            },
            {
                "id": 1892,
                "name": "Kabardino-Balkar Republic",
                "state_code": "KB"
            },
            {
                "id": 1902,
                "name": "Kaliningrad",
                "state_code": "KGD"
            },
            {
                "id": 1844,
                "name": "Kaluga Oblast",
                "state_code": "KLU"
            },
            {
                "id": 1865,
                "name": "Kamchatka Krai",
                "state_code": "KAM"
            },
            {
                "id": 1869,
                "name": "Karachay-Cherkess Republic",
                "state_code": "KC"
            },
            {
                "id": 1897,
                "name": "Kemerovo Oblast",
                "state_code": "KEM"
            },
            {
                "id": 1873,
                "name": "Khabarovsk Krai",
                "state_code": "KHA"
            },
            {
                "id": 1838,
                "name": "Khanty-Mansi Autonomous Okrug",
                "state_code": "KHM"
            },
            {
                "id": 1890,
                "name": "Kirov Oblast",
                "state_code": "KIR"
            },
            {
                "id": 1899,
                "name": "Komi Republic",
                "state_code": "KO"
            },
            {
                "id": 1910,
                "name": "Kostroma Oblast",
                "state_code": "KOS"
            },
            {
                "id": 1891,
                "name": "Krasnodar Krai",
                "state_code": "KDA"
            },
            {
                "id": 1840,
                "name": "Krasnoyarsk Krai",
                "state_code": "KYA"
            },
            {
                "id": 1915,
                "name": "Kurgan Oblast",
                "state_code": "KGN"
            },
            {
                "id": 1855,
                "name": "Kursk Oblast",
                "state_code": "KRS"
            },
            {
                "id": 1896,
                "name": "Leningrad Oblast",
                "state_code": "LEN"
            },
            {
                "id": 1889,
                "name": "Lipetsk Oblast",
                "state_code": "LIP"
            },
            {
                "id": 1839,
                "name": "Magadan Oblast",
                "state_code": "MAG"
            },
            {
                "id": 1870,
                "name": "Mari El Republic",
                "state_code": "ME"
            },
            {
                "id": 1901,
                "name": "Moscow",
                "state_code": "MOW"
            },
            {
                "id": 1882,
                "name": "Moscow Oblast",
                "state_code": "MOS"
            },
            {
                "id": 1843,
                "name": "Murmansk Oblast",
                "state_code": "MUR"
            },
            {
                "id": 1836,
                "name": "Nenets Autonomous Okrug",
                "state_code": "NEN"
            },
            {
                "id": 1857,
                "name": "Nizhny Novgorod Oblast",
                "state_code": "NIZ"
            },
            {
                "id": 1834,
                "name": "Novgorod Oblast",
                "state_code": "NGR"
            },
            {
                "id": 1888,
                "name": "Novosibirsk",
                "state_code": "NVS"
            },
            {
                "id": 1846,
                "name": "Omsk Oblast",
                "state_code": "OMS"
            },
            {
                "id": 1886,
                "name": "Orenburg Oblast",
                "state_code": "ORE"
            },
            {
                "id": 1908,
                "name": "Oryol Oblast",
                "state_code": "ORL"
            },
            {
                "id": 1909,
                "name": "Penza Oblast",
                "state_code": "PNZ"
            },
            {
                "id": 1871,
                "name": "Perm Krai",
                "state_code": "PER"
            },
            {
                "id": 1833,
                "name": "Primorsky Krai",
                "state_code": "PRI"
            },
            {
                "id": 1863,
                "name": "Pskov Oblast",
                "state_code": "PSK"
            },
            {
                "id": 1852,
                "name": "Republic of Adygea",
                "state_code": "AD"
            },
            {
                "id": 1854,
                "name": "Republic of Bashkortostan",
                "state_code": "BA"
            },
            {
                "id": 1842,
                "name": "Republic of Buryatia",
                "state_code": "BU"
            },
            {
                "id": 1850,
                "name": "Republic of Dagestan",
                "state_code": "DA"
            },
            {
                "id": 1884,
                "name": "Republic of Ingushetia",
                "state_code": "IN"
            },
            {
                "id": 1883,
                "name": "Republic of Kalmykia",
                "state_code": "KL"
            },
            {
                "id": 1841,
                "name": "Republic of Karelia",
                "state_code": "KR"
            },
            {
                "id": 1877,
                "name": "Republic of Khakassia",
                "state_code": "KK"
            },
            {
                "id": 1898,
                "name": "Republic of Mordovia",
                "state_code": "MO"
            },
            {
                "id": 1853,
                "name": "Republic of North Ossetia-Alania",
                "state_code": "SE"
            },
            {
                "id": 1861,
                "name": "Republic of Tatarstan",
                "state_code": "TA"
            },
            {
                "id": 1837,
                "name": "Rostov Oblast",
                "state_code": "ROS"
            },
            {
                "id": 1905,
                "name": "Ryazan Oblast",
                "state_code": "RYA"
            },
            {
                "id": 1879,
                "name": "Saint Petersburg",
                "state_code": "SPE"
            },
            {
                "id": 1848,
                "name": "Sakha Republic",
                "state_code": "SA"
            },
            {
                "id": 1875,
                "name": "Sakhalin",
                "state_code": "SAK"
            },
            {
                "id": 1862,
                "name": "Samara Oblast",
                "state_code": "SAM"
            },
            {
                "id": 1887,
                "name": "Saratov Oblast",
                "state_code": "SAR"
            },
            {
                "id": 1912,
                "name": "Sevastopol",
                "state_code": "UA-40"
            },
            {
                "id": 1885,
                "name": "Smolensk Oblast",
                "state_code": "SMO"
            },
            {
                "id": 1868,
                "name": "Stavropol Krai",
                "state_code": "STA"
            },
            {
                "id": 1894,
                "name": "Sverdlovsk",
                "state_code": "SVE"
            },
            {
                "id": 1878,
                "name": "Tambov Oblast",
                "state_code": "TAM"
            },
            {
                "id": 1872,
                "name": "Tomsk Oblast",
                "state_code": "TOM"
            },
            {
                "id": 1895,
                "name": "Tula Oblast",
                "state_code": "TUL"
            },
            {
                "id": 1900,
                "name": "Tuva Republic",
                "state_code": "TY"
            },
            {
                "id": 1860,
                "name": "Tver Oblast",
                "state_code": "TVE"
            },
            {
                "id": 1907,
                "name": "Tyumen Oblast",
                "state_code": "TYU"
            },
            {
                "id": 1913,
                "name": "Udmurt Republic",
                "state_code": "UD"
            },
            {
                "id": 1856,
                "name": "Ulyanovsk Oblast",
                "state_code": "ULY"
            },
            {
                "id": 1881,
                "name": "Vladimir Oblast",
                "state_code": "VLA"
            },
            {
                "id": 4916,
                "name": "Volgograd Oblast",
                "state_code": "VGG"
            },
            {
                "id": 1874,
                "name": "Vologda Oblast",
                "state_code": "VLG"
            },
            {
                "id": 1906,
                "name": "Voronezh Oblast",
                "state_code": "VOR"
            },
            {
                "id": 1847,
                "name": "Yamalo-Nenets Autonomous Okrug",
                "state_code": "YAN"
            },
            {
                "id": 1851,
                "name": "Yaroslavl Oblast",
                "state_code": "YAR"
            },
            {
                "id": 1904,
                "name": "Zabaykalsky Krai",
                "state_code": "ZAB"
            }
        ]
    },
    {
        "name": "Rwanda",
        "iso3": "RWA",
        "iso2": "RW",
        "phone_code": "250",
        "capital": "Kigali",
        "currency": "RWF",
        "currency_symbol": "FRw",
        "tld": ".rw",
        "native": "Rwanda",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa/Kigali",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "CAT",
                "tzName": "Central Africa Time"
            }
        ],
        "translations": {
            "br": "Ruanda",
            "pt": "Ruanda",
            "nl": "Rwanda",
            "hr": "Ruanda",
            "fa": "رواندا",
            "de": "Ruanda",
            "es": "Ruanda",
            "fr": "Rwanda",
            "ja": "ルワンダ",
            "it": "Ruanda"
        },
        "latitude": "-2.00000000",
        "longitude": "30.00000000",
        "emoji": "🇷🇼",
        "emojiU": "U+1F1F7 U+1F1FC",
        "states": [
            {
                "id": 261,
                "name": "Eastern Province",
                "state_code": "02"
            },
            {
                "id": 262,
                "name": "Kigali district",
                "state_code": "01"
            },
            {
                "id": 263,
                "name": "Northern Province",
                "state_code": "03"
            },
            {
                "id": 259,
                "name": "Southern Province",
                "state_code": "05"
            },
            {
                "id": 260,
                "name": "Western Province",
                "state_code": "04"
            }
        ]
    },
    {
        "name": "Saint Helena",
        "iso3": "SHN",
        "iso2": "SH",
        "phone_code": "290",
        "capital": "Jamestown",
        "currency": "SHP",
        "currency_symbol": "£",
        "tld": ".sh",
        "native": "Saint Helena",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Atlantic/St_Helena",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "translations": {
            "br": "Santa Helena",
            "pt": "Santa Helena",
            "nl": "Sint-Helena",
            "hr": "Sveta Helena",
            "fa": "سنت هلنا، اسنشن و تریستان دا کونا",
            "de": "Sankt Helena",
            "es": "Santa Helena",
            "fr": "Sainte-Hélène",
            "ja": "セントヘレナ・アセンションおよびトリスタンダクーニャ",
            "it": "Sant'Elena"
        },
        "latitude": "-15.95000000",
        "longitude": "-5.70000000",
        "emoji": "🇸🇭",
        "emojiU": "U+1F1F8 U+1F1ED",
        "states": []
    },
    {
        "name": "Saint Kitts And Nevis",
        "iso3": "KNA",
        "iso2": "KN",
        "phone_code": "+1-869",
        "capital": "Basseterre",
        "currency": "XCD",
        "currency_symbol": "$",
        "tld": ".kn",
        "native": "Saint Kitts and Nevis",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/St_Kitts",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "translations": {
            "br": "São Cristóvão e Neves",
            "pt": "São Cristóvão e Neves",
            "nl": "Saint Kitts en Nevis",
            "hr": "Sveti Kristof i Nevis",
            "fa": "سنت کیتس و نویس",
            "de": "St. Kitts und Nevis",
            "es": "San Cristóbal y Nieves",
            "fr": "Saint-Christophe-et-Niévès",
            "ja": "セントクリストファー・ネイビス",
            "it": "Saint Kitts e Nevis"
        },
        "latitude": "17.33333333",
        "longitude": "-62.75000000",
        "emoji": "🇰🇳",
        "emojiU": "U+1F1F0 U+1F1F3",
        "states": [
            {
                "id": 3833,
                "name": "Christ Church Nichola Town Parish",
                "state_code": "01"
            },
            {
                "id": 3832,
                "name": "Nevis",
                "state_code": "N"
            },
            {
                "id": 3836,
                "name": "Saint Anne Sandy Point Parish",
                "state_code": "02"
            },
            {
                "id": 3837,
                "name": "Saint George Gingerland Parish",
                "state_code": "04"
            },
            {
                "id": 3835,
                "name": "Saint James Windward Parish",
                "state_code": "05"
            },
            {
                "id": 3845,
                "name": "Saint John Capisterre Parish",
                "state_code": "06"
            },
            {
                "id": 3840,
                "name": "Saint John Figtree Parish",
                "state_code": "07"
            },
            {
                "id": 3841,
                "name": "Saint Kitts",
                "state_code": "K"
            },
            {
                "id": 3844,
                "name": "Saint Mary Cayon Parish",
                "state_code": "08"
            },
            {
                "id": 3834,
                "name": "Saint Paul Capisterre Parish",
                "state_code": "09"
            },
            {
                "id": 3838,
                "name": "Saint Paul Charlestown Parish",
                "state_code": "10"
            },
            {
                "id": 3831,
                "name": "Saint Peter Basseterre Parish",
                "state_code": "11"
            },
            {
                "id": 3839,
                "name": "Saint Thomas Lowland Parish",
                "state_code": "12"
            },
            {
                "id": 3842,
                "name": "Saint Thomas Middle Island Parish",
                "state_code": "13"
            },
            {
                "id": 3843,
                "name": "Trinity Palmetto Point Parish",
                "state_code": "15"
            }
        ]
    },
    {
        "name": "Saint Lucia",
        "iso3": "LCA",
        "iso2": "LC",
        "phone_code": "+1-758",
        "capital": "Castries",
        "currency": "XCD",
        "currency_symbol": "$",
        "tld": ".lc",
        "native": "Saint Lucia",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/St_Lucia",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "translations": {
            "br": "Santa Lúcia",
            "pt": "Santa Lúcia",
            "nl": "Saint Lucia",
            "hr": "Sveta Lucija",
            "fa": "سنت لوسیا",
            "de": "Saint Lucia",
            "es": "Santa Lucía",
            "fr": "Saint-Lucie",
            "ja": "セントルシア",
            "it": "Santa Lucia"
        },
        "latitude": "13.88333333",
        "longitude": "-60.96666666",
        "emoji": "🇱🇨",
        "emojiU": "U+1F1F1 U+1F1E8",
        "states": [
            {
                "id": 3757,
                "name": "Anse la Raye Quarter",
                "state_code": "01"
            },
            {
                "id": 3761,
                "name": "Canaries",
                "state_code": "12"
            },
            {
                "id": 3758,
                "name": "Castries Quarter",
                "state_code": "02"
            },
            {
                "id": 3760,
                "name": "Choiseul Quarter",
                "state_code": "03"
            },
            {
                "id": 3767,
                "name": "Dauphin Quarter",
                "state_code": "04"
            },
            {
                "id": 3756,
                "name": "Dennery Quarter",
                "state_code": "05"
            },
            {
                "id": 3766,
                "name": "Gros Islet Quarter",
                "state_code": "06"
            },
            {
                "id": 3759,
                "name": "Laborie Quarter",
                "state_code": "07"
            },
            {
                "id": 3762,
                "name": "Micoud Quarter",
                "state_code": "08"
            },
            {
                "id": 3765,
                "name": "Praslin Quarter",
                "state_code": "09"
            },
            {
                "id": 3764,
                "name": "Soufrière Quarter",
                "state_code": "10"
            },
            {
                "id": 3763,
                "name": "Vieux Fort Quarter",
                "state_code": "11"
            }
        ]
    },
    {
        "name": "Saint Pierre and Miquelon",
        "iso3": "SPM",
        "iso2": "PM",
        "phone_code": "508",
        "capital": "Saint-Pierre",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".pm",
        "native": "Saint-Pierre-et-Miquelon",
        "region": "Americas",
        "subregion": "Northern America",
        "timezones": [
            {
                "zoneName": "America/Miquelon",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "PMDT",
                "tzName": "Pierre & Miquelon Daylight Time"
            }
        ],
        "translations": {
            "br": "Saint-Pierre e Miquelon",
            "pt": "São Pedro e Miquelon",
            "nl": "Saint Pierre en Miquelon",
            "hr": "Sveti Petar i Mikelon",
            "fa": "سن پیر و میکلن",
            "de": "Saint-Pierre und Miquelon",
            "es": "San Pedro y Miquelón",
            "fr": "Saint-Pierre-et-Miquelon",
            "ja": "サンピエール島・ミクロン島",
            "it": "Saint-Pierre e Miquelon"
        },
        "latitude": "46.83333333",
        "longitude": "-56.33333333",
        "emoji": "🇵🇲",
        "emojiU": "U+1F1F5 U+1F1F2",
        "states": []
    },
    {
        "name": "Saint Vincent And The Grenadines",
        "iso3": "VCT",
        "iso2": "VC",
        "phone_code": "+1-784",
        "capital": "Kingstown",
        "currency": "XCD",
        "currency_symbol": "$",
        "tld": ".vc",
        "native": "Saint Vincent and the Grenadines",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/St_Vincent",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "translations": {
            "br": "São Vicente e Granadinas",
            "pt": "São Vicente e Granadinas",
            "nl": "Saint Vincent en de Grenadines",
            "hr": "Sveti Vincent i Grenadini",
            "fa": "سنت وینسنت و گرنادین‌ها",
            "de": "Saint Vincent und die Grenadinen",
            "es": "San Vicente y Granadinas",
            "fr": "Saint-Vincent-et-les-Grenadines",
            "ja": "セントビンセントおよびグレナディーン諸島",
            "it": "Saint Vincent e Grenadine"
        },
        "latitude": "13.25000000",
        "longitude": "-61.20000000",
        "emoji": "🇻🇨",
        "emojiU": "U+1F1FB U+1F1E8",
        "states": [
            {
                "id": 3389,
                "name": "Charlotte Parish",
                "state_code": "01"
            },
            {
                "id": 3388,
                "name": "Grenadines Parish",
                "state_code": "06"
            },
            {
                "id": 3386,
                "name": "Saint Andrew Parish",
                "state_code": "02"
            },
            {
                "id": 3387,
                "name": "Saint David Parish",
                "state_code": "03"
            },
            {
                "id": 3384,
                "name": "Saint George Parish",
                "state_code": "04"
            },
            {
                "id": 3385,
                "name": "Saint Patrick Parish",
                "state_code": "05"
            }
        ]
    },
    {
        "name": "Saint-Barthelemy",
        "iso3": "BLM",
        "iso2": "BL",
        "phone_code": "590",
        "capital": "Gustavia",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".bl",
        "native": "Saint-Barthélemy",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/St_Barthelemy",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "translations": {
            "br": "São Bartolomeu",
            "pt": "São Bartolomeu",
            "nl": "Saint Barthélemy",
            "hr": "Saint Barthélemy",
            "fa": "سن-بارتلمی",
            "de": "Saint-Barthélemy",
            "es": "San Bartolomé",
            "fr": "Saint-Barthélemy",
            "ja": "サン・バルテルミー",
            "it": "Antille Francesi"
        },
        "latitude": "18.50000000",
        "longitude": "-63.41666666",
        "emoji": "🇧🇱",
        "emojiU": "U+1F1E7 U+1F1F1",
        "states": []
    },
    {
        "name": "Saint-Martin (French part)",
        "iso3": "MAF",
        "iso2": "MF",
        "phone_code": "590",
        "capital": "Marigot",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".mf",
        "native": "Saint-Martin",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/Marigot",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "translations": {
            "br": "Saint Martin",
            "pt": "Ilha São Martinho",
            "nl": "Saint-Martin",
            "hr": "Sveti Martin",
            "fa": "سینت مارتن",
            "de": "Saint Martin",
            "es": "Saint Martin",
            "fr": "Saint-Martin",
            "ja": "サン・マルタン（フランス領）",
            "it": "Saint Martin"
        },
        "latitude": "18.08333333",
        "longitude": "-63.95000000",
        "emoji": "🇲🇫",
        "emojiU": "U+1F1F2 U+1F1EB",
        "states": []
    },
    {
        "name": "Samoa",
        "iso3": "WSM",
        "iso2": "WS",
        "phone_code": "685",
        "capital": "Apia",
        "currency": "WST",
        "currency_symbol": "SAT",
        "tld": ".ws",
        "native": "Samoa",
        "region": "Oceania",
        "subregion": "Polynesia",
        "timezones": [
            {
                "zoneName": "Pacific/Apia",
                "gmtOffset": 50400,
                "gmtOffsetName": "UTC+14:00",
                "abbreviation": "WST",
                "tzName": "West Samoa Time"
            }
        ],
        "translations": {
            "br": "Samoa",
            "pt": "Samoa",
            "nl": "Samoa",
            "hr": "Samoa",
            "fa": "ساموآ",
            "de": "Samoa",
            "es": "Samoa",
            "fr": "Samoa",
            "ja": "サモア",
            "it": "Samoa"
        },
        "latitude": "-13.58333333",
        "longitude": "-172.33333333",
        "emoji": "🇼🇸",
        "emojiU": "U+1F1FC U+1F1F8",
        "states": [
            {
                "id": 4763,
                "name": "A'ana",
                "state_code": "AA"
            },
            {
                "id": 4761,
                "name": "Aiga-i-le-Tai",
                "state_code": "AL"
            },
            {
                "id": 4765,
                "name": "Atua",
                "state_code": "AT"
            },
            {
                "id": 4764,
                "name": "Fa'asaleleaga",
                "state_code": "FA"
            },
            {
                "id": 4769,
                "name": "Gaga'emauga",
                "state_code": "GE"
            },
            {
                "id": 4771,
                "name": "Gaga'ifomauga",
                "state_code": "GI"
            },
            {
                "id": 4767,
                "name": "Palauli",
                "state_code": "PA"
            },
            {
                "id": 4762,
                "name": "Satupa'itea",
                "state_code": "SA"
            },
            {
                "id": 4770,
                "name": "Tuamasaga",
                "state_code": "TU"
            },
            {
                "id": 4768,
                "name": "Va'a-o-Fonoti",
                "state_code": "VF"
            },
            {
                "id": 4766,
                "name": "Vaisigano",
                "state_code": "VS"
            }
        ]
    },
    {
        "name": "San Marino",
        "iso3": "SMR",
        "iso2": "SM",
        "phone_code": "378",
        "capital": "San Marino",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".sm",
        "native": "San Marino",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe/San_Marino",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "San Marino",
            "pt": "São Marinho",
            "nl": "San Marino",
            "hr": "San Marino",
            "fa": "سان مارینو",
            "de": "San Marino",
            "es": "San Marino",
            "fr": "Saint-Marin",
            "ja": "サンマリノ",
            "it": "San Marino"
        },
        "latitude": "43.76666666",
        "longitude": "12.41666666",
        "emoji": "🇸🇲",
        "emojiU": "U+1F1F8 U+1F1F2",
        "states": [
            {
                "id": 59,
                "name": "Acquaviva",
                "state_code": "01"
            },
            {
                "id": 61,
                "name": "Borgo Maggiore",
                "state_code": "06"
            },
            {
                "id": 60,
                "name": "Chiesanuova",
                "state_code": "02"
            },
            {
                "id": 64,
                "name": "Domagnano",
                "state_code": "03"
            },
            {
                "id": 62,
                "name": "Faetano",
                "state_code": "04"
            },
            {
                "id": 66,
                "name": "Fiorentino",
                "state_code": "05"
            },
            {
                "id": 63,
                "name": "Montegiardino",
                "state_code": "08"
            },
            {
                "id": 58,
                "name": "San Marino",
                "state_code": "07"
            },
            {
                "id": 65,
                "name": "Serravalle",
                "state_code": "09"
            }
        ]
    },
    {
        "name": "Sao Tome and Principe",
        "iso3": "STP",
        "iso2": "ST",
        "phone_code": "239",
        "capital": "Sao Tome",
        "currency": "STD",
        "currency_symbol": "Db",
        "tld": ".st",
        "native": "São Tomé e Príncipe",
        "region": "Africa",
        "subregion": "Middle Africa",
        "timezones": [
            {
                "zoneName": "Africa/Sao_Tome",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "translations": {
            "br": "São Tomé e Príncipe",
            "pt": "São Tomé e Príncipe",
            "nl": "Sao Tomé en Principe",
            "hr": "Sveti Toma i Princip",
            "fa": "کواترو دو فرویرو",
            "de": "São Tomé und Príncipe",
            "es": "Santo Tomé y Príncipe",
            "fr": "Sao Tomé-et-Principe",
            "ja": "サントメ・プリンシペ",
            "it": "São Tomé e Príncipe"
        },
        "latitude": "1.00000000",
        "longitude": "7.00000000",
        "emoji": "🇸🇹",
        "emojiU": "U+1F1F8 U+1F1F9",
        "states": [
            {
                "id": 270,
                "name": "Príncipe Province",
                "state_code": "P"
            },
            {
                "id": 271,
                "name": "São Tomé Province",
                "state_code": "S"
            }
        ]
    },
    {
        "name": "Saudi Arabia",
        "iso3": "SAU",
        "iso2": "SA",
        "phone_code": "966",
        "capital": "Riyadh",
        "currency": "SAR",
        "currency_symbol": "﷼",
        "tld": ".sa",
        "native": "العربية السعودية",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia/Riyadh",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "AST",
                "tzName": "Arabia Standard Time"
            }
        ],
        "translations": {
            "br": "Arábia Saudita",
            "pt": "Arábia Saudita",
            "nl": "Saoedi-Arabië",
            "hr": "Saudijska Arabija",
            "fa": "عربستان سعودی",
            "de": "Saudi-Arabien",
            "es": "Arabia Saudí",
            "fr": "Arabie Saoudite",
            "ja": "サウジアラビア",
            "it": "Arabia Saudita"
        },
        "latitude": "25.00000000",
        "longitude": "45.00000000",
        "emoji": "🇸🇦",
        "emojiU": "U+1F1F8 U+1F1E6",
        "states": [
            {
                "id": 2853,
                "name": "'Asir Region",
                "state_code": "14"
            },
            {
                "id": 2859,
                "name": "Al Bahah Region",
                "state_code": "11"
            },
            {
                "id": 2857,
                "name": "Al Jawf Region",
                "state_code": "12"
            },
            {
                "id": 2851,
                "name": "Al Madinah Region",
                "state_code": "03"
            },
            {
                "id": 2861,
                "name": "Al-Qassim Region",
                "state_code": "05"
            },
            {
                "id": 2856,
                "name": "Eastern Province",
                "state_code": "04"
            },
            {
                "id": 2855,
                "name": "Ha'il Region",
                "state_code": "06"
            },
            {
                "id": 2858,
                "name": "Jizan Region",
                "state_code": "09"
            },
            {
                "id": 2850,
                "name": "Makkah Region",
                "state_code": "02"
            },
            {
                "id": 2860,
                "name": "Najran Region",
                "state_code": "10"
            },
            {
                "id": 2854,
                "name": "Northern Borders Region",
                "state_code": "08"
            },
            {
                "id": 2849,
                "name": "Riyadh Region",
                "state_code": "01"
            },
            {
                "id": 2852,
                "name": "Tabuk Region",
                "state_code": "07"
            }
        ]
    },
    {
        "name": "Senegal",
        "iso3": "SEN",
        "iso2": "SN",
        "phone_code": "221",
        "capital": "Dakar",
        "currency": "XOF",
        "currency_symbol": "CFA",
        "tld": ".sn",
        "native": "Sénégal",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa/Dakar",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "translations": {
            "br": "Senegal",
            "pt": "Senegal",
            "nl": "Senegal",
            "hr": "Senegal",
            "fa": "سنگال",
            "de": "Senegal",
            "es": "Senegal",
            "fr": "Sénégal",
            "ja": "セネガル",
            "it": "Senegal"
        },
        "latitude": "14.00000000",
        "longitude": "-14.00000000",
        "emoji": "🇸🇳",
        "emojiU": "U+1F1F8 U+1F1F3",
        "states": [
            {
                "id": 473,
                "name": "Dakar",
                "state_code": "DK"
            },
            {
                "id": 480,
                "name": "Diourbel Region",
                "state_code": "DB"
            },
            {
                "id": 479,
                "name": "Fatick",
                "state_code": "FK"
            },
            {
                "id": 475,
                "name": "Kaffrine",
                "state_code": "KA"
            },
            {
                "id": 483,
                "name": "Kaolack",
                "state_code": "KL"
            },
            {
                "id": 481,
                "name": "Kédougou",
                "state_code": "KE"
            },
            {
                "id": 474,
                "name": "Kolda",
                "state_code": "KD"
            },
            {
                "id": 485,
                "name": "Louga",
                "state_code": "LG"
            },
            {
                "id": 476,
                "name": "Matam",
                "state_code": "MT"
            },
            {
                "id": 477,
                "name": "Saint-Louis",
                "state_code": "SL"
            },
            {
                "id": 482,
                "name": "Sédhiou",
                "state_code": "SE"
            },
            {
                "id": 486,
                "name": "Tambacounda Region",
                "state_code": "TC"
            },
            {
                "id": 484,
                "name": "Thiès Region",
                "state_code": "TH"
            },
            {
                "id": 478,
                "name": "Ziguinchor",
                "state_code": "ZG"
            }
        ]
    },
    {
        "name": "Serbia",
        "iso3": "SRB",
        "iso2": "RS",
        "phone_code": "381",
        "capital": "Belgrade",
        "currency": "RSD",
        "currency_symbol": "din",
        "tld": ".rs",
        "native": "Србија",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Belgrade",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Sérvia",
            "pt": "Sérvia",
            "nl": "Servië",
            "hr": "Srbija",
            "fa": "صربستان",
            "de": "Serbien",
            "es": "Serbia",
            "fr": "Serbie",
            "ja": "セルビア",
            "it": "Serbia"
        },
        "latitude": "44.00000000",
        "longitude": "21.00000000",
        "emoji": "🇷🇸",
        "emojiU": "U+1F1F7 U+1F1F8",
        "states": [
            {
                "id": 3728,
                "name": "Belgrade",
                "state_code": "00"
            },
            {
                "id": 3717,
                "name": "Bor District",
                "state_code": "14"
            },
            {
                "id": 3732,
                "name": "Braničevo District",
                "state_code": "11"
            },
            {
                "id": 3716,
                "name": "Central Banat District",
                "state_code": "02"
            },
            {
                "id": 3715,
                "name": "Jablanica District",
                "state_code": "23"
            },
            {
                "id": 3724,
                "name": "Kolubara District",
                "state_code": "09"
            },
            {
                "id": 3719,
                "name": "Mačva District",
                "state_code": "08"
            },
            {
                "id": 3727,
                "name": "Moravica District",
                "state_code": "17"
            },
            {
                "id": 3722,
                "name": "Nišava District",
                "state_code": "20"
            },
            {
                "id": 3714,
                "name": "North Bačka District",
                "state_code": "01"
            },
            {
                "id": 3736,
                "name": "North Banat District",
                "state_code": "03"
            },
            {
                "id": 3721,
                "name": "Pčinja District",
                "state_code": "24"
            },
            {
                "id": 3712,
                "name": "Pirot District",
                "state_code": "22"
            },
            {
                "id": 3741,
                "name": "Podunavlje District",
                "state_code": "10"
            },
            {
                "id": 3737,
                "name": "Pomoravlje District",
                "state_code": "13"
            },
            {
                "id": 3720,
                "name": "Rasina District",
                "state_code": "19"
            },
            {
                "id": 3725,
                "name": "Raška District",
                "state_code": "18"
            },
            {
                "id": 3711,
                "name": "South Bačka District",
                "state_code": "06"
            },
            {
                "id": 3713,
                "name": "South Banat District",
                "state_code": "04"
            },
            {
                "id": 3740,
                "name": "Srem District",
                "state_code": "07"
            },
            {
                "id": 3734,
                "name": "Šumadija District",
                "state_code": "12"
            },
            {
                "id": 3718,
                "name": "Toplica District",
                "state_code": "21"
            },
            {
                "id": 3733,
                "name": "Vojvodina",
                "state_code": "VO"
            },
            {
                "id": 3726,
                "name": "West Bačka District",
                "state_code": "05"
            },
            {
                "id": 3731,
                "name": "Zaječar District",
                "state_code": "15"
            },
            {
                "id": 3729,
                "name": "Zlatibor District",
                "state_code": "16"
            }
        ]
    },
    {
        "name": "Seychelles",
        "iso3": "SYC",
        "iso2": "SC",
        "phone_code": "248",
        "capital": "Victoria",
        "currency": "SCR",
        "currency_symbol": "SRe",
        "tld": ".sc",
        "native": "Seychelles",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Indian/Mahe",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "SCT",
                "tzName": "Seychelles Time"
            }
        ],
        "translations": {
            "br": "Seicheles",
            "pt": "Seicheles",
            "nl": "Seychellen",
            "hr": "Sejšeli",
            "fa": "سیشل",
            "de": "Seychellen",
            "es": "Seychelles",
            "fr": "Seychelles",
            "ja": "セーシェル",
            "it": "Seychelles"
        },
        "latitude": "-4.58333333",
        "longitude": "55.66666666",
        "emoji": "🇸🇨",
        "emojiU": "U+1F1F8 U+1F1E8",
        "states": [
            {
                "id": 513,
                "name": "Anse Boileau",
                "state_code": "02"
            },
            {
                "id": 502,
                "name": "Anse Royale",
                "state_code": "05"
            },
            {
                "id": 506,
                "name": "Anse-aux-Pins",
                "state_code": "01"
            },
            {
                "id": 508,
                "name": "Au Cap",
                "state_code": "04"
            },
            {
                "id": 497,
                "name": "Baie Lazare",
                "state_code": "06"
            },
            {
                "id": 514,
                "name": "Baie Sainte Anne",
                "state_code": "07"
            },
            {
                "id": 512,
                "name": "Beau Vallon",
                "state_code": "08"
            },
            {
                "id": 515,
                "name": "Bel Air",
                "state_code": "09"
            },
            {
                "id": 505,
                "name": "Bel Ombre",
                "state_code": "10"
            },
            {
                "id": 517,
                "name": "Cascade",
                "state_code": "11"
            },
            {
                "id": 503,
                "name": "Glacis",
                "state_code": "12"
            },
            {
                "id": 500,
                "name": "Grand'Anse Mahé",
                "state_code": "13"
            },
            {
                "id": 504,
                "name": "Grand'Anse Praslin",
                "state_code": "14"
            },
            {
                "id": 495,
                "name": "La Digue",
                "state_code": "15"
            },
            {
                "id": 516,
                "name": "La Rivière Anglaise",
                "state_code": "16"
            },
            {
                "id": 499,
                "name": "Les Mamelles",
                "state_code": "24"
            },
            {
                "id": 494,
                "name": "Mont Buxton",
                "state_code": "17"
            },
            {
                "id": 498,
                "name": "Mont Fleuri",
                "state_code": "18"
            },
            {
                "id": 511,
                "name": "Plaisance",
                "state_code": "19"
            },
            {
                "id": 510,
                "name": "Pointe La Rue",
                "state_code": "20"
            },
            {
                "id": 507,
                "name": "Port Glaud",
                "state_code": "21"
            },
            {
                "id": 501,
                "name": "Roche Caiman",
                "state_code": "25"
            },
            {
                "id": 496,
                "name": "Saint Louis",
                "state_code": "22"
            },
            {
                "id": 509,
                "name": "Takamaka",
                "state_code": "23"
            }
        ]
    },
    {
        "name": "Sierra Leone",
        "iso3": "SLE",
        "iso2": "SL",
        "phone_code": "232",
        "capital": "Freetown",
        "currency": "SLL",
        "currency_symbol": "Le",
        "tld": ".sl",
        "native": "Sierra Leone",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa/Freetown",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "translations": {
            "br": "Serra Leoa",
            "pt": "Serra Leoa",
            "nl": "Sierra Leone",
            "hr": "Sijera Leone",
            "fa": "سیرالئون",
            "de": "Sierra Leone",
            "es": "Sierra Leone",
            "fr": "Sierra Leone",
            "ja": "シエラレオネ",
            "it": "Sierra Leone"
        },
        "latitude": "8.50000000",
        "longitude": "-11.50000000",
        "emoji": "🇸🇱",
        "emojiU": "U+1F1F8 U+1F1F1",
        "states": [
            {
                "id": 914,
                "name": "Eastern Province",
                "state_code": "E"
            },
            {
                "id": 911,
                "name": "Northern Province",
                "state_code": "N"
            },
            {
                "id": 912,
                "name": "Southern Province",
                "state_code": "S"
            },
            {
                "id": 913,
                "name": "Western Area",
                "state_code": "W"
            }
        ]
    },
    {
        "name": "Singapore",
        "iso3": "SGP",
        "iso2": "SG",
        "phone_code": "65",
        "capital": "Singapur",
        "currency": "SGD",
        "currency_symbol": "$",
        "tld": ".sg",
        "native": "Singapore",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Singapore",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "SGT",
                "tzName": "Singapore Time"
            }
        ],
        "translations": {
            "br": "Singapura",
            "pt": "Singapura",
            "nl": "Singapore",
            "hr": "Singapur",
            "fa": "سنگاپور",
            "de": "Singapur",
            "es": "Singapur",
            "fr": "Singapour",
            "ja": "シンガポール",
            "it": "Singapore"
        },
        "latitude": "1.36666666",
        "longitude": "103.80000000",
        "emoji": "🇸🇬",
        "emojiU": "U+1F1F8 U+1F1EC",
        "states": [
            {
                "id": 4651,
                "name": "Central Singapore Community Development Council",
                "state_code": "01"
            },
            {
                "id": 4649,
                "name": "North East Community Development Council",
                "state_code": "02"
            },
            {
                "id": 4653,
                "name": "North West Community Development Council",
                "state_code": "03"
            },
            {
                "id": 4650,
                "name": "South East Community Development Council",
                "state_code": "04"
            },
            {
                "id": 4652,
                "name": "South West Community Development Council",
                "state_code": "05"
            }
        ]
    },
    {
        "name": "Sint Maarten (Dutch part)",
        "iso3": "SXM",
        "iso2": "SX",
        "phone_code": "1721",
        "capital": "Philipsburg",
        "currency": "ANG",
        "currency_symbol": "ƒ",
        "tld": ".sx",
        "native": "Sint Maarten",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/Anguilla",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "translations": {
            "br": "Sint Maarten",
            "pt": "São Martinho",
            "nl": "Sint Maarten",
            "fa": "سینت مارتن",
            "de": "Sint Maarten (niederl. Teil)",
            "fr": "Saint Martin (partie néerlandaise)",
            "it": "Saint Martin (parte olandese)"
        },
        "latitude": "18.03333300",
        "longitude": "-63.05000000",
        "emoji": "🇸🇽",
        "emojiU": "U+1F1F8 U+1F1FD",
        "states": []
    },
    {
        "name": "Slovakia",
        "iso3": "SVK",
        "iso2": "SK",
        "phone_code": "421",
        "capital": "Bratislava",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".sk",
        "native": "Slovensko",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Bratislava",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Eslováquia",
            "pt": "Eslováquia",
            "nl": "Slowakije",
            "hr": "Slovačka",
            "fa": "اسلواکی",
            "de": "Slowakei",
            "es": "República Eslovaca",
            "fr": "Slovaquie",
            "ja": "スロバキア",
            "it": "Slovacchia"
        },
        "latitude": "48.66666666",
        "longitude": "19.50000000",
        "emoji": "🇸🇰",
        "emojiU": "U+1F1F8 U+1F1F0",
        "states": [
            {
                "id": 4352,
                "name": "Banská Bystrica Region",
                "state_code": "BC"
            },
            {
                "id": 4356,
                "name": "Bratislava Region",
                "state_code": "BL"
            },
            {
                "id": 4353,
                "name": "Košice Region",
                "state_code": "KI"
            },
            {
                "id": 4357,
                "name": "Nitra Region",
                "state_code": "NI"
            },
            {
                "id": 4354,
                "name": "Prešov Region",
                "state_code": "PV"
            },
            {
                "id": 4358,
                "name": "Trenčín Region",
                "state_code": "TC"
            },
            {
                "id": 4355,
                "name": "Trnava Region",
                "state_code": "TA"
            },
            {
                "id": 4359,
                "name": "Žilina Region",
                "state_code": "ZI"
            }
        ]
    },
    {
        "name": "Slovenia",
        "iso3": "SVN",
        "iso2": "SI",
        "phone_code": "386",
        "capital": "Ljubljana",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".si",
        "native": "Slovenija",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Ljubljana",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Eslovênia",
            "pt": "Eslovénia",
            "nl": "Slovenië",
            "hr": "Slovenija",
            "fa": "اسلوونی",
            "de": "Slowenien",
            "es": "Eslovenia",
            "fr": "Slovénie",
            "ja": "スロベニア",
            "it": "Slovenia"
        },
        "latitude": "46.11666666",
        "longitude": "14.81666666",
        "emoji": "🇸🇮",
        "emojiU": "U+1F1F8 U+1F1EE",
        "states": [
            {
                "id": 4183,
                "name": "Ajdovščina Municipality",
                "state_code": "001"
            },
            {
                "id": 4326,
                "name": "Ankaran Municipality",
                "state_code": "213"
            },
            {
                "id": 4301,
                "name": "Beltinci Municipality",
                "state_code": "002"
            },
            {
                "id": 4166,
                "name": "Benedikt Municipality",
                "state_code": "148"
            },
            {
                "id": 4179,
                "name": "Bistrica ob Sotli Municipality",
                "state_code": "149"
            },
            {
                "id": 4202,
                "name": "Bled Municipality",
                "state_code": "003"
            },
            {
                "id": 4278,
                "name": "Bloke Municipality",
                "state_code": "150"
            },
            {
                "id": 4282,
                "name": "Bohinj Municipality",
                "state_code": "004"
            },
            {
                "id": 4200,
                "name": "Borovnica Municipality",
                "state_code": "005"
            },
            {
                "id": 4181,
                "name": "Bovec Municipality",
                "state_code": "006"
            },
            {
                "id": 4141,
                "name": "Braslovče Municipality",
                "state_code": "151"
            },
            {
                "id": 4240,
                "name": "Brda Municipality",
                "state_code": "007"
            },
            {
                "id": 4215,
                "name": "Brežice Municipality",
                "state_code": "009"
            },
            {
                "id": 4165,
                "name": "Brezovica Municipality",
                "state_code": "008"
            },
            {
                "id": 4147,
                "name": "Cankova Municipality",
                "state_code": "152"
            },
            {
                "id": 4310,
                "name": "Cerklje na Gorenjskem Municipality",
                "state_code": "012"
            },
            {
                "id": 4162,
                "name": "Cerknica Municipality",
                "state_code": "013"
            },
            {
                "id": 4178,
                "name": "Cerkno Municipality",
                "state_code": "014"
            },
            {
                "id": 4176,
                "name": "Cerkvenjak Municipality",
                "state_code": "153"
            },
            {
                "id": 4191,
                "name": "City Municipality of Celje",
                "state_code": "011"
            },
            {
                "id": 4236,
                "name": "City Municipality of Novo Mesto",
                "state_code": "085"
            },
            {
                "id": 4151,
                "name": "Črenšovci Municipality",
                "state_code": "015"
            },
            {
                "id": 4232,
                "name": "Črna na Koroškem Municipality",
                "state_code": "016"
            },
            {
                "id": 4291,
                "name": "Črnomelj Municipality",
                "state_code": "017"
            },
            {
                "id": 4304,
                "name": "Destrnik Municipality",
                "state_code": "018"
            },
            {
                "id": 4167,
                "name": "Divača Municipality",
                "state_code": "019"
            },
            {
                "id": 4295,
                "name": "Dobje Municipality",
                "state_code": "154"
            },
            {
                "id": 4216,
                "name": "Dobrepolje Municipality",
                "state_code": "020"
            },
            {
                "id": 4252,
                "name": "Dobrna Municipality",
                "state_code": "155"
            },
            {
                "id": 4308,
                "name": "Dobrova–Polhov Gradec Municipality",
                "state_code": "021"
            },
            {
                "id": 4189,
                "name": "Dobrovnik Municipality",
                "state_code": "156"
            },
            {
                "id": 4173,
                "name": "Dol pri Ljubljani Municipality",
                "state_code": "022"
            },
            {
                "id": 4281,
                "name": "Dolenjske Toplice Municipality",
                "state_code": "157"
            },
            {
                "id": 4159,
                "name": "Domžale Municipality",
                "state_code": "023"
            },
            {
                "id": 4290,
                "name": "Dornava Municipality",
                "state_code": "024"
            },
            {
                "id": 4345,
                "name": "Dravograd Municipality",
                "state_code": "025"
            },
            {
                "id": 4213,
                "name": "Duplek Municipality",
                "state_code": "026"
            },
            {
                "id": 4293,
                "name": "Gorenja Vas–Poljane Municipality",
                "state_code": "027"
            },
            {
                "id": 4210,
                "name": "Gorišnica Municipality",
                "state_code": "028"
            },
            {
                "id": 4284,
                "name": "Gorje Municipality",
                "state_code": "207"
            },
            {
                "id": 4343,
                "name": "Gornja Radgona Municipality",
                "state_code": "029"
            },
            {
                "id": 4339,
                "name": "Gornji Grad Municipality",
                "state_code": "030"
            },
            {
                "id": 4271,
                "name": "Gornji Petrovci Municipality",
                "state_code": "031"
            },
            {
                "id": 4217,
                "name": "Grad Municipality",
                "state_code": "158"
            },
            {
                "id": 4336,
                "name": "Grosuplje Municipality",
                "state_code": "032"
            },
            {
                "id": 4145,
                "name": "Hajdina Municipality",
                "state_code": "159"
            },
            {
                "id": 4175,
                "name": "Hoče–Slivnica Municipality",
                "state_code": "160"
            },
            {
                "id": 4327,
                "name": "Hodoš Municipality",
                "state_code": "161"
            },
            {
                "id": 4193,
                "name": "Horjul Municipality",
                "state_code": "162"
            },
            {
                "id": 4341,
                "name": "Hrastnik Municipality",
                "state_code": "034"
            },
            {
                "id": 4321,
                "name": "Hrpelje–Kozina Municipality",
                "state_code": "035"
            },
            {
                "id": 4152,
                "name": "Idrija Municipality",
                "state_code": "036"
            },
            {
                "id": 4286,
                "name": "Ig Municipality",
                "state_code": "037"
            },
            {
                "id": 4305,
                "name": "Ivančna Gorica Municipality",
                "state_code": "039"
            },
            {
                "id": 4322,
                "name": "Izola Municipality",
                "state_code": "040"
            },
            {
                "id": 4337,
                "name": "Jesenice Municipality",
                "state_code": "041"
            },
            {
                "id": 4203,
                "name": "Jezersko Municipality",
                "state_code": "163"
            },
            {
                "id": 4266,
                "name": "Juršinci Municipality",
                "state_code": "042"
            },
            {
                "id": 4180,
                "name": "Kamnik Municipality",
                "state_code": "043"
            },
            {
                "id": 4227,
                "name": "Kanal ob Soči Municipality",
                "state_code": "044"
            },
            {
                "id": 4150,
                "name": "Kidričevo Municipality",
                "state_code": "045"
            },
            {
                "id": 4243,
                "name": "Kobarid Municipality",
                "state_code": "046"
            },
            {
                "id": 4325,
                "name": "Kobilje Municipality",
                "state_code": "047"
            },
            {
                "id": 4335,
                "name": "Kočevje Municipality",
                "state_code": "048"
            },
            {
                "id": 4315,
                "name": "Komen Municipality",
                "state_code": "049"
            },
            {
                "id": 4283,
                "name": "Komenda Municipality",
                "state_code": "164"
            },
            {
                "id": 4319,
                "name": "Koper City Municipality",
                "state_code": "050"
            },
            {
                "id": 4254,
                "name": "Kostanjevica na Krki Municipality",
                "state_code": "197"
            },
            {
                "id": 4331,
                "name": "Kostel Municipality",
                "state_code": "165"
            },
            {
                "id": 4186,
                "name": "Kozje Municipality",
                "state_code": "051"
            },
            {
                "id": 4287,
                "name": "Kranj City Municipality",
                "state_code": "052"
            },
            {
                "id": 4340,
                "name": "Kranjska Gora Municipality",
                "state_code": "053"
            },
            {
                "id": 4238,
                "name": "Križevci Municipality",
                "state_code": "166"
            },
            {
                "id": 4197,
                "name": "Kungota",
                "state_code": "055"
            },
            {
                "id": 4211,
                "name": "Kuzma Municipality",
                "state_code": "056"
            },
            {
                "id": 4338,
                "name": "Laško Municipality",
                "state_code": "057"
            },
            {
                "id": 4142,
                "name": "Lenart Municipality",
                "state_code": "058"
            },
            {
                "id": 4225,
                "name": "Lendava Municipality",
                "state_code": "059"
            },
            {
                "id": 4347,
                "name": "Litija Municipality",
                "state_code": "060"
            },
            {
                "id": 4270,
                "name": "Ljubljana City Municipality",
                "state_code": "061"
            },
            {
                "id": 4294,
                "name": "Ljubno Municipality",
                "state_code": "062"
            },
            {
                "id": 4351,
                "name": "Ljutomer Municipality",
                "state_code": "063"
            },
            {
                "id": 4306,
                "name": "Log–Dragomer Municipality",
                "state_code": "208"
            },
            {
                "id": 4350,
                "name": "Logatec Municipality",
                "state_code": "064"
            },
            {
                "id": 4174,
                "name": "Loška Dolina Municipality",
                "state_code": "065"
            },
            {
                "id": 4158,
                "name": "Loški Potok Municipality",
                "state_code": "066"
            },
            {
                "id": 4156,
                "name": "Lovrenc na Pohorju Municipality",
                "state_code": "167"
            },
            {
                "id": 4219,
                "name": "Luče Municipality",
                "state_code": "067"
            },
            {
                "id": 4302,
                "name": "Lukovica Municipality",
                "state_code": "068"
            },
            {
                "id": 4157,
                "name": "Majšperk Municipality",
                "state_code": "069"
            },
            {
                "id": 4224,
                "name": "Makole Municipality",
                "state_code": "198"
            },
            {
                "id": 4242,
                "name": "Maribor City Municipality",
                "state_code": "070"
            },
            {
                "id": 4244,
                "name": "Markovci Municipality",
                "state_code": "168"
            },
            {
                "id": 4349,
                "name": "Medvode Municipality",
                "state_code": "071"
            },
            {
                "id": 4348,
                "name": "Mengeš Municipality",
                "state_code": "072"
            },
            {
                "id": 4323,
                "name": "Metlika Municipality",
                "state_code": "073"
            },
            {
                "id": 4265,
                "name": "Mežica Municipality",
                "state_code": "074"
            },
            {
                "id": 4223,
                "name": "Miklavž na Dravskem Polju Municipality",
                "state_code": "169"
            },
            {
                "id": 4220,
                "name": "Miren–Kostanjevica Municipality",
                "state_code": "075"
            },
            {
                "id": 4298,
                "name": "Mirna Municipality",
                "state_code": "212"
            },
            {
                "id": 4237,
                "name": "Mirna Peč Municipality",
                "state_code": "170"
            },
            {
                "id": 4212,
                "name": "Mislinja Municipality",
                "state_code": "076"
            },
            {
                "id": 4297,
                "name": "Mokronog–Trebelno Municipality",
                "state_code": "199"
            },
            {
                "id": 4168,
                "name": "Moravče Municipality",
                "state_code": "077"
            },
            {
                "id": 4218,
                "name": "Moravske Toplice Municipality",
                "state_code": "078"
            },
            {
                "id": 4190,
                "name": "Mozirje Municipality",
                "state_code": "079"
            },
            {
                "id": 4318,
                "name": "Municipality of Apače",
                "state_code": "195"
            },
            {
                "id": 4309,
                "name": "Municipality of Cirkulane",
                "state_code": "196"
            },
            {
                "id": 4344,
                "name": "Municipality of Ilirska Bistrica",
                "state_code": "038"
            },
            {
                "id": 4314,
                "name": "Municipality of Krško",
                "state_code": "054"
            },
            {
                "id": 4187,
                "name": "Municipality of Škofljica",
                "state_code": "123"
            },
            {
                "id": 4313,
                "name": "Murska Sobota City Municipality",
                "state_code": "080"
            },
            {
                "id": 4208,
                "name": "Muta Municipality",
                "state_code": "081"
            },
            {
                "id": 4177,
                "name": "Naklo Municipality",
                "state_code": "082"
            },
            {
                "id": 4329,
                "name": "Nazarje Municipality",
                "state_code": "083"
            },
            {
                "id": 4205,
                "name": "Nova Gorica City Municipality",
                "state_code": "084"
            },
            {
                "id": 4320,
                "name": "Odranci Municipality",
                "state_code": "086"
            },
            {
                "id": 4143,
                "name": "Oplotnica",
                "state_code": "171"
            },
            {
                "id": 4221,
                "name": "Ormož Municipality",
                "state_code": "087"
            },
            {
                "id": 4199,
                "name": "Osilnica Municipality",
                "state_code": "088"
            },
            {
                "id": 4172,
                "name": "Pesnica Municipality",
                "state_code": "089"
            },
            {
                "id": 4201,
                "name": "Piran Municipality",
                "state_code": "090"
            },
            {
                "id": 4184,
                "name": "Pivka Municipality",
                "state_code": "091"
            },
            {
                "id": 4146,
                "name": "Podčetrtek Municipality",
                "state_code": "092"
            },
            {
                "id": 4161,
                "name": "Podlehnik Municipality",
                "state_code": "172"
            },
            {
                "id": 4234,
                "name": "Podvelka Municipality",
                "state_code": "093"
            },
            {
                "id": 4239,
                "name": "Poljčane Municipality",
                "state_code": "200"
            },
            {
                "id": 4272,
                "name": "Polzela Municipality",
                "state_code": "173"
            },
            {
                "id": 4330,
                "name": "Postojna Municipality",
                "state_code": "094"
            },
            {
                "id": 4188,
                "name": "Prebold Municipality",
                "state_code": "174"
            },
            {
                "id": 4303,
                "name": "Preddvor Municipality",
                "state_code": "095"
            },
            {
                "id": 4274,
                "name": "Prevalje Municipality",
                "state_code": "175"
            },
            {
                "id": 4228,
                "name": "Ptuj City Municipality",
                "state_code": "096"
            },
            {
                "id": 4288,
                "name": "Puconci Municipality",
                "state_code": "097"
            },
            {
                "id": 4204,
                "name": "Rače–Fram Municipality",
                "state_code": "098"
            },
            {
                "id": 4195,
                "name": "Radeče Municipality",
                "state_code": "099"
            },
            {
                "id": 4292,
                "name": "Radenci Municipality",
                "state_code": "100"
            },
            {
                "id": 4275,
                "name": "Radlje ob Dravi Municipality",
                "state_code": "101"
            },
            {
                "id": 4231,
                "name": "Radovljica Municipality",
                "state_code": "102"
            },
            {
                "id": 4155,
                "name": "Ravne na Koroškem Municipality",
                "state_code": "103"
            },
            {
                "id": 4206,
                "name": "Razkrižje Municipality",
                "state_code": "176"
            },
            {
                "id": 4160,
                "name": "Rečica ob Savinji Municipality",
                "state_code": "209"
            },
            {
                "id": 4253,
                "name": "Renče–Vogrsko Municipality",
                "state_code": "201"
            },
            {
                "id": 4235,
                "name": "Ribnica Municipality",
                "state_code": "104"
            },
            {
                "id": 4207,
                "name": "Ribnica na Pohorju Municipality",
                "state_code": "177"
            },
            {
                "id": 4233,
                "name": "Rogaška Slatina Municipality",
                "state_code": "106"
            },
            {
                "id": 4264,
                "name": "Rogašovci Municipality",
                "state_code": "105"
            },
            {
                "id": 4209,
                "name": "Rogatec Municipality",
                "state_code": "107"
            },
            {
                "id": 4280,
                "name": "Ruše Municipality",
                "state_code": "108"
            },
            {
                "id": 4222,
                "name": "Šalovci Municipality",
                "state_code": "033"
            },
            {
                "id": 4230,
                "name": "Selnica ob Dravi Municipality",
                "state_code": "178"
            },
            {
                "id": 4346,
                "name": "Semič Municipality",
                "state_code": "109"
            },
            {
                "id": 4317,
                "name": "Šempeter–Vrtojba Municipality",
                "state_code": "183"
            },
            {
                "id": 4299,
                "name": "Šenčur Municipality",
                "state_code": "117"
            },
            {
                "id": 4324,
                "name": "Šentilj Municipality",
                "state_code": "118"
            },
            {
                "id": 4241,
                "name": "Šentjernej Municipality",
                "state_code": "119"
            },
            {
                "id": 4171,
                "name": "Šentjur Municipality",
                "state_code": "120"
            },
            {
                "id": 4311,
                "name": "Šentrupert Municipality",
                "state_code": "211"
            },
            {
                "id": 4268,
                "name": "Sevnica Municipality",
                "state_code": "110"
            },
            {
                "id": 4149,
                "name": "Sežana Municipality",
                "state_code": "111"
            },
            {
                "id": 4170,
                "name": "Škocjan Municipality",
                "state_code": "121"
            },
            {
                "id": 4316,
                "name": "Škofja Loka Municipality",
                "state_code": "122"
            },
            {
                "id": 4169,
                "name": "Slovenj Gradec City Municipality",
                "state_code": "112"
            },
            {
                "id": 4332,
                "name": "Slovenska Bistrica Municipality",
                "state_code": "113"
            },
            {
                "id": 4198,
                "name": "Slovenske Konjice Municipality",
                "state_code": "114"
            },
            {
                "id": 4285,
                "name": "Šmarje pri Jelšah Municipality",
                "state_code": "124"
            },
            {
                "id": 4289,
                "name": "Šmarješke Toplice Municipality",
                "state_code": "206"
            },
            {
                "id": 4296,
                "name": "Šmartno ob Paki Municipality",
                "state_code": "125"
            },
            {
                "id": 4279,
                "name": "Šmartno pri Litiji Municipality",
                "state_code": "194"
            },
            {
                "id": 4277,
                "name": "Sodražica Municipality",
                "state_code": "179"
            },
            {
                "id": 4261,
                "name": "Solčava Municipality",
                "state_code": "180"
            },
            {
                "id": 4248,
                "name": "Šoštanj Municipality",
                "state_code": "126"
            },
            {
                "id": 4263,
                "name": "Središče ob Dravi",
                "state_code": "202"
            },
            {
                "id": 4259,
                "name": "Starše Municipality",
                "state_code": "115"
            },
            {
                "id": 4185,
                "name": "Štore Municipality",
                "state_code": "127"
            },
            {
                "id": 4333,
                "name": "Straža Municipality",
                "state_code": "203"
            },
            {
                "id": 4164,
                "name": "Sveta Ana Municipality",
                "state_code": "181"
            },
            {
                "id": 4260,
                "name": "Sveta Trojica v Slovenskih Goricah Municipality",
                "state_code": "204"
            },
            {
                "id": 4229,
                "name": "Sveti Andraž v Slovenskih Goricah Municipality",
                "state_code": "182"
            },
            {
                "id": 4255,
                "name": "Sveti Jurij ob Ščavnici Municipality",
                "state_code": "116"
            },
            {
                "id": 4328,
                "name": "Sveti Jurij v Slovenskih Goricah Municipality",
                "state_code": "210"
            },
            {
                "id": 4273,
                "name": "Sveti Tomaž Municipality",
                "state_code": "205"
            },
            {
                "id": 4194,
                "name": "Tabor Municipality",
                "state_code": "184"
            },
            {
                "id": 4312,
                "name": "Tišina Municipality",
                "state_code": "010"
            },
            {
                "id": 4247,
                "name": "Tolmin Municipality",
                "state_code": "128"
            },
            {
                "id": 4246,
                "name": "Trbovlje Municipality",
                "state_code": "129"
            },
            {
                "id": 4214,
                "name": "Trebnje Municipality",
                "state_code": "130"
            },
            {
                "id": 4153,
                "name": "Trnovska Vas Municipality",
                "state_code": "185"
            },
            {
                "id": 4250,
                "name": "Tržič Municipality",
                "state_code": "131"
            },
            {
                "id": 4334,
                "name": "Trzin Municipality",
                "state_code": "186"
            },
            {
                "id": 4251,
                "name": "Turnišče Municipality",
                "state_code": "132"
            },
            {
                "id": 4267,
                "name": "Velika Polana Municipality",
                "state_code": "187"
            },
            {
                "id": 4144,
                "name": "Velike Lašče Municipality",
                "state_code": "134"
            },
            {
                "id": 4257,
                "name": "Veržej Municipality",
                "state_code": "188"
            },
            {
                "id": 4300,
                "name": "Videm Municipality",
                "state_code": "135"
            },
            {
                "id": 4196,
                "name": "Vipava Municipality",
                "state_code": "136"
            },
            {
                "id": 4148,
                "name": "Vitanje Municipality",
                "state_code": "137"
            },
            {
                "id": 4154,
                "name": "Vodice Municipality",
                "state_code": "138"
            },
            {
                "id": 4245,
                "name": "Vojnik Municipality",
                "state_code": "139"
            },
            {
                "id": 4163,
                "name": "Vransko Municipality",
                "state_code": "189"
            },
            {
                "id": 4262,
                "name": "Vrhnika Municipality",
                "state_code": "140"
            },
            {
                "id": 4226,
                "name": "Vuzenica Municipality",
                "state_code": "141"
            },
            {
                "id": 4269,
                "name": "Zagorje ob Savi Municipality",
                "state_code": "142"
            },
            {
                "id": 4258,
                "name": "Žalec Municipality",
                "state_code": "190"
            },
            {
                "id": 4182,
                "name": "Zavrč Municipality",
                "state_code": "143"
            },
            {
                "id": 4256,
                "name": "Železniki Municipality",
                "state_code": "146"
            },
            {
                "id": 4249,
                "name": "Žetale Municipality",
                "state_code": "191"
            },
            {
                "id": 4192,
                "name": "Žiri Municipality",
                "state_code": "147"
            },
            {
                "id": 4276,
                "name": "Žirovnica Municipality",
                "state_code": "192"
            },
            {
                "id": 4342,
                "name": "Zreče Municipality",
                "state_code": "144"
            },
            {
                "id": 4307,
                "name": "Žužemberk Municipality",
                "state_code": "193"
            }
        ]
    },
    {
        "name": "Solomon Islands",
        "iso3": "SLB",
        "iso2": "SB",
        "phone_code": "677",
        "capital": "Honiara",
        "currency": "SBD",
        "currency_symbol": "Si$",
        "tld": ".sb",
        "native": "Solomon Islands",
        "region": "Oceania",
        "subregion": "Melanesia",
        "timezones": [
            {
                "zoneName": "Pacific/Guadalcanal",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "SBT",
                "tzName": "Solomon Islands Time"
            }
        ],
        "translations": {
            "br": "Ilhas Salomão",
            "pt": "Ilhas Salomão",
            "nl": "Salomonseilanden",
            "hr": "Solomonski Otoci",
            "fa": "جزایر سلیمان",
            "de": "Salomonen",
            "es": "Islas Salomón",
            "fr": "Îles Salomon",
            "ja": "ソロモン諸島",
            "it": "Isole Salomone"
        },
        "latitude": "-8.00000000",
        "longitude": "159.00000000",
        "emoji": "🇸🇧",
        "emojiU": "U+1F1F8 U+1F1E7",
        "states": [
            {
                "id": 4784,
                "name": "Central Province",
                "state_code": "CE"
            },
            {
                "id": 4781,
                "name": "Choiseul Province",
                "state_code": "CH"
            },
            {
                "id": 4785,
                "name": "Guadalcanal Province",
                "state_code": "GU"
            },
            {
                "id": 4778,
                "name": "Honiara",
                "state_code": "CT"
            },
            {
                "id": 4780,
                "name": "Isabel Province",
                "state_code": "IS"
            },
            {
                "id": 4782,
                "name": "Makira-Ulawa Province",
                "state_code": "MK"
            },
            {
                "id": 4783,
                "name": "Malaita Province",
                "state_code": "ML"
            },
            {
                "id": 4787,
                "name": "Rennell and Bellona Province",
                "state_code": "RB"
            },
            {
                "id": 4779,
                "name": "Temotu Province",
                "state_code": "TE"
            },
            {
                "id": 4786,
                "name": "Western Province",
                "state_code": "WE"
            }
        ]
    },
    {
        "name": "Somalia",
        "iso3": "SOM",
        "iso2": "SO",
        "phone_code": "252",
        "capital": "Mogadishu",
        "currency": "SOS",
        "currency_symbol": "Sh.so.",
        "tld": ".so",
        "native": "Soomaaliya",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa/Mogadishu",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "EAT",
                "tzName": "East Africa Time"
            }
        ],
        "translations": {
            "br": "Somália",
            "pt": "Somália",
            "nl": "Somalië",
            "hr": "Somalija",
            "fa": "سومالی",
            "de": "Somalia",
            "es": "Somalia",
            "fr": "Somalie",
            "ja": "ソマリア",
            "it": "Somalia"
        },
        "latitude": "10.00000000",
        "longitude": "49.00000000",
        "emoji": "🇸🇴",
        "emojiU": "U+1F1F8 U+1F1F4",
        "states": [
            {
                "id": 925,
                "name": "Awdal Region",
                "state_code": "AW"
            },
            {
                "id": 917,
                "name": "Bakool",
                "state_code": "BK"
            },
            {
                "id": 927,
                "name": "Banaadir",
                "state_code": "BN"
            },
            {
                "id": 930,
                "name": "Bari",
                "state_code": "BR"
            },
            {
                "id": 926,
                "name": "Bay",
                "state_code": "BY"
            },
            {
                "id": 918,
                "name": "Galguduud",
                "state_code": "GA"
            },
            {
                "id": 928,
                "name": "Gedo",
                "state_code": "GE"
            },
            {
                "id": 915,
                "name": "Hiran",
                "state_code": "HI"
            },
            {
                "id": 924,
                "name": "Lower Juba",
                "state_code": "JH"
            },
            {
                "id": 921,
                "name": "Lower Shebelle",
                "state_code": "SH"
            },
            {
                "id": 922,
                "name": "Middle Juba",
                "state_code": "JD"
            },
            {
                "id": 923,
                "name": "Middle Shebelle",
                "state_code": "SD"
            },
            {
                "id": 916,
                "name": "Mudug",
                "state_code": "MU"
            },
            {
                "id": 920,
                "name": "Nugal",
                "state_code": "NU"
            },
            {
                "id": 919,
                "name": "Sanaag Region",
                "state_code": "SA"
            },
            {
                "id": 929,
                "name": "Togdheer Region",
                "state_code": "TO"
            }
        ]
    },
    {
        "name": "South Africa",
        "iso3": "ZAF",
        "iso2": "ZA",
        "phone_code": "27",
        "capital": "Pretoria",
        "currency": "ZAR",
        "currency_symbol": "R",
        "tld": ".za",
        "native": "South Africa",
        "region": "Africa",
        "subregion": "Southern Africa",
        "timezones": [
            {
                "zoneName": "Africa/Johannesburg",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "SAST",
                "tzName": "South African Standard Time"
            }
        ],
        "translations": {
            "br": "República Sul-Africana",
            "pt": "República Sul-Africana",
            "nl": "Zuid-Afrika",
            "hr": "Južnoafrička Republika",
            "fa": "آفریقای جنوبی",
            "de": "Republik Südafrika",
            "es": "República de Sudáfrica",
            "fr": "Afrique du Sud",
            "ja": "南アフリカ",
            "it": "Sud Africa"
        },
        "latitude": "-29.00000000",
        "longitude": "24.00000000",
        "emoji": "🇿🇦",
        "emojiU": "U+1F1FF U+1F1E6",
        "states": [
            {
                "id": 938,
                "name": "Eastern Cape",
                "state_code": "EC"
            },
            {
                "id": 932,
                "name": "Free State",
                "state_code": "FS"
            },
            {
                "id": 936,
                "name": "Gauteng",
                "state_code": "GP"
            },
            {
                "id": 935,
                "name": "KwaZulu-Natal",
                "state_code": "KZN"
            },
            {
                "id": 933,
                "name": "Limpopo",
                "state_code": "LP"
            },
            {
                "id": 937,
                "name": "Mpumalanga",
                "state_code": "MP"
            },
            {
                "id": 934,
                "name": "North West",
                "state_code": "NW"
            },
            {
                "id": 931,
                "name": "Northern Cape",
                "state_code": "NC"
            },
            {
                "id": 939,
                "name": "Western Cape",
                "state_code": "WC"
            }
        ]
    },
    {
        "name": "South Georgia",
        "iso3": "SGS",
        "iso2": "GS",
        "phone_code": "",
        "capital": "Grytviken",
        "currency": "GBP",
        "currency_symbol": "£",
        "tld": ".gs",
        "native": "South Georgia",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "Atlantic/South_Georgia",
                "gmtOffset": -7200,
                "gmtOffsetName": "UTC-02:00",
                "abbreviation": "GST",
                "tzName": "South Georgia and the South Sandwich Islands Time"
            }
        ],
        "translations": {
            "br": "Ilhas Geórgias do Sul e Sandwich do Sul",
            "pt": "Ilhas Geórgia do Sul e Sanduíche do Sul",
            "nl": "Zuid-Georgia en Zuidelijke Sandwicheilanden",
            "hr": "Južna Georgija i otočje Južni Sandwich",
            "fa": "جزایر جورجیای جنوبی و ساندویچ جنوبی",
            "de": "Südgeorgien und die Südlichen Sandwichinseln",
            "es": "Islas Georgias del Sur y Sandwich del Sur",
            "fr": "Géorgie du Sud-et-les Îles Sandwich du Sud",
            "ja": "サウスジョージア・サウスサンドウィッチ諸島",
            "it": "Georgia del Sud e Isole Sandwich Meridionali"
        },
        "latitude": "-54.50000000",
        "longitude": "-37.00000000",
        "emoji": "🇬🇸",
        "emojiU": "U+1F1EC U+1F1F8",
        "states": []
    },
    {
        "name": "South Sudan",
        "iso3": "SSD",
        "iso2": "SS",
        "phone_code": "211",
        "capital": "Juba",
        "currency": "SSP",
        "currency_symbol": "£",
        "tld": ".ss",
        "native": "South Sudan",
        "region": "Africa",
        "subregion": "Middle Africa",
        "timezones": [
            {
                "zoneName": "Africa/Juba",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "EAT",
                "tzName": "East Africa Time"
            }
        ],
        "translations": {
            "br": "Sudão do Sul",
            "pt": "Sudão do Sul",
            "nl": "Zuid-Soedan",
            "hr": "Južni Sudan",
            "fa": "سودان جنوبی",
            "de": "Südsudan",
            "es": "Sudán del Sur",
            "fr": "Soudan du Sud",
            "ja": "南スーダン",
            "it": "Sudan del sud"
        },
        "latitude": "7.00000000",
        "longitude": "30.00000000",
        "emoji": "🇸🇸",
        "emojiU": "U+1F1F8 U+1F1F8",
        "states": [
            {
                "id": 2092,
                "name": "Central Equatoria",
                "state_code": "EC"
            },
            {
                "id": 2093,
                "name": "Eastern Equatoria",
                "state_code": "EE"
            },
            {
                "id": 2094,
                "name": "Jonglei State",
                "state_code": "JG"
            },
            {
                "id": 2090,
                "name": "Lakes",
                "state_code": "LK"
            },
            {
                "id": 2088,
                "name": "Northern Bahr el Ghazal",
                "state_code": "BN"
            },
            {
                "id": 2085,
                "name": "Unity",
                "state_code": "UY"
            },
            {
                "id": 2086,
                "name": "Upper Nile",
                "state_code": "NU"
            },
            {
                "id": 2087,
                "name": "Warrap",
                "state_code": "WR"
            },
            {
                "id": 2091,
                "name": "Western Bahr el Ghazal",
                "state_code": "BW"
            },
            {
                "id": 2089,
                "name": "Western Equatoria",
                "state_code": "EW"
            }
        ]
    },
    {
        "name": "Spain",
        "iso3": "ESP",
        "iso2": "ES",
        "phone_code": "34",
        "capital": "Madrid",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".es",
        "native": "España",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Africa/Ceuta",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            },
            {
                "zoneName": "Atlantic/Canary",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "WET",
                "tzName": "Western European Time"
            },
            {
                "zoneName": "Europe/Madrid",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Espanha",
            "pt": "Espanha",
            "nl": "Spanje",
            "hr": "Španjolska",
            "fa": "اسپانیا",
            "de": "Spanien",
            "es": "España",
            "fr": "Espagne",
            "ja": "スペイン",
            "it": "Spagna"
        },
        "latitude": "40.00000000",
        "longitude": "-4.00000000",
        "emoji": "🇪🇸",
        "emojiU": "U+1F1EA U+1F1F8",
        "states": [
            {
                "id": 1193,
                "name": "Andalusia",
                "state_code": "AN"
            },
            {
                "id": 1177,
                "name": "Aragon",
                "state_code": "AR"
            },
            {
                "id": 1160,
                "name": "Asturias",
                "state_code": "AS"
            },
            {
                "id": 1189,
                "name": "Ávila",
                "state_code": "AV"
            },
            {
                "id": 1174,
                "name": "Balearic Islands",
                "state_code": "PM"
            },
            {
                "id": 1191,
                "name": "Basque Country",
                "state_code": "PV"
            },
            {
                "id": 1146,
                "name": "Burgos Province",
                "state_code": "BU"
            },
            {
                "id": 1185,
                "name": "Canary Islands",
                "state_code": "CN"
            },
            {
                "id": 1170,
                "name": "Cantabria",
                "state_code": "CB"
            },
            {
                "id": 1184,
                "name": "Castile and León",
                "state_code": "CL"
            },
            {
                "id": 1205,
                "name": "Castilla La Mancha",
                "state_code": "CM"
            },
            {
                "id": 1203,
                "name": "Catalonia",
                "state_code": "CT"
            },
            {
                "id": 1206,
                "name": "Ceuta",
                "state_code": "CE"
            },
            {
                "id": 1190,
                "name": "Extremadura",
                "state_code": "EX"
            },
            {
                "id": 1167,
                "name": "Galicia",
                "state_code": "GA"
            },
            {
                "id": 1171,
                "name": "La Rioja",
                "state_code": "RI"
            },
            {
                "id": 1200,
                "name": "Léon",
                "state_code": "LE"
            },
            {
                "id": 1158,
                "name": "Madrid",
                "state_code": "MD"
            },
            {
                "id": 1159,
                "name": "Melilla",
                "state_code": "ML"
            },
            {
                "id": 1176,
                "name": "Murcia",
                "state_code": "MC"
            },
            {
                "id": 1204,
                "name": "Navarra",
                "state_code": "NC"
            },
            {
                "id": 1157,
                "name": "Palencia Province",
                "state_code": "P"
            },
            {
                "id": 1147,
                "name": "Salamanca Province",
                "state_code": "SA"
            },
            {
                "id": 1192,
                "name": "Segovia Province",
                "state_code": "SG"
            },
            {
                "id": 1208,
                "name": "Soria Province",
                "state_code": "SO"
            },
            {
                "id": 1175,
                "name": "Valencia",
                "state_code": "VC"
            },
            {
                "id": 1183,
                "name": "Valladolid Province",
                "state_code": "VA"
            },
            {
                "id": 1161,
                "name": "Zamora Province",
                "state_code": "ZA"
            }
        ]
    },
    {
        "name": "Sri Lanka",
        "iso3": "LKA",
        "iso2": "LK",
        "phone_code": "94",
        "capital": "Colombo",
        "currency": "LKR",
        "currency_symbol": "Rs",
        "tld": ".lk",
        "native": "śrī laṃkāva",
        "region": "Asia",
        "subregion": "Southern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Colombo",
                "gmtOffset": 19800,
                "gmtOffsetName": "UTC+05:30",
                "abbreviation": "IST",
                "tzName": "Indian Standard Time"
            }
        ],
        "translations": {
            "br": "Sri Lanka",
            "pt": "Sri Lanka",
            "nl": "Sri Lanka",
            "hr": "Šri Lanka",
            "fa": "سری‌لانکا",
            "de": "Sri Lanka",
            "es": "Sri Lanka",
            "fr": "Sri Lanka",
            "ja": "スリランカ",
            "it": "Sri Lanka"
        },
        "latitude": "7.00000000",
        "longitude": "81.00000000",
        "emoji": "🇱🇰",
        "emojiU": "U+1F1F1 U+1F1F0",
        "states": [
            {
                "id": 2799,
                "name": "Ampara District",
                "state_code": "52"
            },
            {
                "id": 2816,
                "name": "Anuradhapura District",
                "state_code": "71"
            },
            {
                "id": 2790,
                "name": "Badulla District",
                "state_code": "81"
            },
            {
                "id": 2818,
                "name": "Batticaloa District",
                "state_code": "51"
            },
            {
                "id": 2798,
                "name": "Central Province",
                "state_code": "2"
            },
            {
                "id": 2815,
                "name": "Colombo District",
                "state_code": "11"
            },
            {
                "id": 2808,
                "name": "Eastern Province",
                "state_code": "5"
            },
            {
                "id": 2792,
                "name": "Galle District",
                "state_code": "31"
            },
            {
                "id": 2804,
                "name": "Gampaha District",
                "state_code": "12"
            },
            {
                "id": 2791,
                "name": "Hambantota District",
                "state_code": "33"
            },
            {
                "id": 2787,
                "name": "Jaffna District",
                "state_code": "41"
            },
            {
                "id": 2789,
                "name": "Kalutara District",
                "state_code": "13"
            },
            {
                "id": 2788,
                "name": "Kandy District",
                "state_code": "21"
            },
            {
                "id": 2797,
                "name": "Kegalle District",
                "state_code": "92"
            },
            {
                "id": 2793,
                "name": "Kilinochchi District",
                "state_code": "42"
            },
            {
                "id": 2805,
                "name": "Mannar District",
                "state_code": "43"
            },
            {
                "id": 2810,
                "name": "Matale District",
                "state_code": "22"
            },
            {
                "id": 2806,
                "name": "Matara District",
                "state_code": "32"
            },
            {
                "id": 2819,
                "name": "Monaragala District",
                "state_code": "82"
            },
            {
                "id": 2814,
                "name": "Mullaitivu District",
                "state_code": "45"
            },
            {
                "id": 2800,
                "name": "North Central Province",
                "state_code": "7"
            },
            {
                "id": 2817,
                "name": "North Western Province",
                "state_code": "6"
            },
            {
                "id": 2813,
                "name": "Northern Province",
                "state_code": "4"
            },
            {
                "id": 2794,
                "name": "Nuwara Eliya District",
                "state_code": "23"
            },
            {
                "id": 2812,
                "name": "Polonnaruwa District",
                "state_code": "72"
            },
            {
                "id": 2796,
                "name": "Puttalam District",
                "state_code": "62"
            },
            {
                "id": 2807,
                "name": "Ratnapura district",
                "state_code": "91"
            },
            {
                "id": 2803,
                "name": "Sabaragamuwa Province",
                "state_code": "9"
            },
            {
                "id": 2801,
                "name": "Southern Province",
                "state_code": "3"
            },
            {
                "id": 2795,
                "name": "Trincomalee District",
                "state_code": "53"
            },
            {
                "id": 2811,
                "name": "Uva Province",
                "state_code": "8"
            },
            {
                "id": 2809,
                "name": "Vavuniya District",
                "state_code": "44"
            },
            {
                "id": 2802,
                "name": "Western Province",
                "state_code": "1"
            }
        ]
    },
    {
        "name": "Sudan",
        "iso3": "SDN",
        "iso2": "SD",
        "phone_code": "249",
        "capital": "Khartoum",
        "currency": "SDG",
        "currency_symbol": ".س.ج",
        "tld": ".sd",
        "native": "السودان",
        "region": "Africa",
        "subregion": "Northern Africa",
        "timezones": [
            {
                "zoneName": "Africa/Khartoum",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EAT",
                "tzName": "Eastern African Time"
            }
        ],
        "translations": {
            "br": "Sudão",
            "pt": "Sudão",
            "nl": "Soedan",
            "hr": "Sudan",
            "fa": "سودان",
            "de": "Sudan",
            "es": "Sudán",
            "fr": "Soudan",
            "ja": "スーダン",
            "it": "Sudan"
        },
        "latitude": "15.00000000",
        "longitude": "30.00000000",
        "emoji": "🇸🇩",
        "emojiU": "U+1F1F8 U+1F1E9",
        "states": [
            {
                "id": 885,
                "name": "Al Jazirah",
                "state_code": "GZ"
            },
            {
                "id": 886,
                "name": "Al Qadarif",
                "state_code": "GD"
            },
            {
                "id": 887,
                "name": "Blue Nile",
                "state_code": "NB"
            },
            {
                "id": 896,
                "name": "Central Darfur",
                "state_code": "DC"
            },
            {
                "id": 892,
                "name": "East Darfur",
                "state_code": "DE"
            },
            {
                "id": 884,
                "name": "Kassala",
                "state_code": "KA"
            },
            {
                "id": 881,
                "name": "Khartoum",
                "state_code": "KH"
            },
            {
                "id": 890,
                "name": "North Darfur",
                "state_code": "DN"
            },
            {
                "id": 893,
                "name": "North Kordofan",
                "state_code": "KN"
            },
            {
                "id": 895,
                "name": "Northern",
                "state_code": "NO"
            },
            {
                "id": 880,
                "name": "Red Sea",
                "state_code": "RS"
            },
            {
                "id": 891,
                "name": "River Nile",
                "state_code": "NR"
            },
            {
                "id": 882,
                "name": "Sennar",
                "state_code": "SI"
            },
            {
                "id": 894,
                "name": "South Darfur",
                "state_code": "DS"
            },
            {
                "id": 883,
                "name": "South Kordofan",
                "state_code": "KS"
            },
            {
                "id": 888,
                "name": "West Darfur",
                "state_code": "DW"
            },
            {
                "id": 889,
                "name": "West Kordofan",
                "state_code": "GK"
            },
            {
                "id": 879,
                "name": "White Nile",
                "state_code": "NW"
            }
        ]
    },
    {
        "name": "Suriname",
        "iso3": "SUR",
        "iso2": "SR",
        "phone_code": "597",
        "capital": "Paramaribo",
        "currency": "SRD",
        "currency_symbol": "$",
        "tld": ".sr",
        "native": "Suriname",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America/Paramaribo",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "SRT",
                "tzName": "Suriname Time"
            }
        ],
        "translations": {
            "br": "Suriname",
            "pt": "Suriname",
            "nl": "Suriname",
            "hr": "Surinam",
            "fa": "سورینام",
            "de": "Suriname",
            "es": "Surinam",
            "fr": "Surinam",
            "ja": "スリナム",
            "it": "Suriname"
        },
        "latitude": "4.00000000",
        "longitude": "-56.00000000",
        "emoji": "🇸🇷",
        "emojiU": "U+1F1F8 U+1F1F7",
        "states": [
            {
                "id": 2846,
                "name": "Brokopondo District",
                "state_code": "BR"
            },
            {
                "id": 2839,
                "name": "Commewijne District",
                "state_code": "CM"
            },
            {
                "id": 2842,
                "name": "Coronie District",
                "state_code": "CR"
            },
            {
                "id": 2845,
                "name": "Marowijne District",
                "state_code": "MA"
            },
            {
                "id": 2840,
                "name": "Nickerie District",
                "state_code": "NI"
            },
            {
                "id": 2841,
                "name": "Para District",
                "state_code": "PR"
            },
            {
                "id": 2843,
                "name": "Paramaribo District",
                "state_code": "PM"
            },
            {
                "id": 2848,
                "name": "Saramacca District",
                "state_code": "SA"
            },
            {
                "id": 2847,
                "name": "Sipaliwini District",
                "state_code": "SI"
            },
            {
                "id": 2844,
                "name": "Wanica District",
                "state_code": "WA"
            }
        ]
    },
    {
        "name": "Svalbard And Jan Mayen Islands",
        "iso3": "SJM",
        "iso2": "SJ",
        "phone_code": "47",
        "capital": "Longyearbyen",
        "currency": "NOK",
        "currency_symbol": "kr",
        "tld": ".sj",
        "native": "Svalbard og Jan Mayen",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Arctic/Longyearbyen",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Svalbard",
            "pt": "Svalbard",
            "nl": "Svalbard en Jan Mayen",
            "hr": "Svalbard i Jan Mayen",
            "fa": "سوالبارد و یان ماین",
            "de": "Svalbard und Jan Mayen",
            "es": "Islas Svalbard y Jan Mayen",
            "fr": "Svalbard et Jan Mayen",
            "ja": "スヴァールバル諸島およびヤンマイエン島",
            "it": "Svalbard e Jan Mayen"
        },
        "latitude": "78.00000000",
        "longitude": "20.00000000",
        "emoji": "🇸🇯",
        "emojiU": "U+1F1F8 U+1F1EF",
        "states": []
    },
    {
        "name": "Swaziland",
        "iso3": "SWZ",
        "iso2": "SZ",
        "phone_code": "268",
        "capital": "Mbabane",
        "currency": "SZL",
        "currency_symbol": "E",
        "tld": ".sz",
        "native": "Swaziland",
        "region": "Africa",
        "subregion": "Southern Africa",
        "timezones": [
            {
                "zoneName": "Africa/Mbabane",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "SAST",
                "tzName": "South African Standard Time"
            }
        ],
        "translations": {
            "br": "Suazilândia",
            "pt": "Suazilândia",
            "nl": "Swaziland",
            "hr": "Svazi",
            "fa": "سوازیلند",
            "de": "Swasiland",
            "es": "Suazilandia",
            "fr": "Swaziland",
            "ja": "スワジランド",
            "it": "Swaziland"
        },
        "latitude": "-26.50000000",
        "longitude": "31.50000000",
        "emoji": "🇸🇿",
        "emojiU": "U+1F1F8 U+1F1FF",
        "states": [
            {
                "id": 969,
                "name": "Hhohho District",
                "state_code": "HH"
            },
            {
                "id": 970,
                "name": "Lubombo District",
                "state_code": "LU"
            },
            {
                "id": 968,
                "name": "Manzini District",
                "state_code": "MA"
            },
            {
                "id": 971,
                "name": "Shiselweni District",
                "state_code": "SH"
            }
        ]
    },
    {
        "name": "Sweden",
        "iso3": "SWE",
        "iso2": "SE",
        "phone_code": "46",
        "capital": "Stockholm",
        "currency": "SEK",
        "currency_symbol": "kr",
        "tld": ".se",
        "native": "Sverige",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Stockholm",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Suécia",
            "pt": "Suécia",
            "nl": "Zweden",
            "hr": "Švedska",
            "fa": "سوئد",
            "de": "Schweden",
            "es": "Suecia",
            "fr": "Suède",
            "ja": "スウェーデン",
            "it": "Svezia"
        },
        "latitude": "62.00000000",
        "longitude": "15.00000000",
        "emoji": "🇸🇪",
        "emojiU": "U+1F1F8 U+1F1EA",
        "states": [
            {
                "id": 1537,
                "name": "Blekinge",
                "state_code": "K"
            },
            {
                "id": 1534,
                "name": "Dalarna County",
                "state_code": "W"
            },
            {
                "id": 1533,
                "name": "Gävleborg County",
                "state_code": "X"
            },
            {
                "id": 1546,
                "name": "Gotland County",
                "state_code": "I"
            },
            {
                "id": 1548,
                "name": "Halland County",
                "state_code": "N"
            },
            {
                "id": 1550,
                "name": "Jönköping County",
                "state_code": "F"
            },
            {
                "id": 1544,
                "name": "Kalmar County",
                "state_code": "H"
            },
            {
                "id": 1542,
                "name": "Kronoberg County",
                "state_code": "G"
            },
            {
                "id": 1538,
                "name": "Norrbotten County",
                "state_code": "BD"
            },
            {
                "id": 1539,
                "name": "Örebro County",
                "state_code": "T"
            },
            {
                "id": 1536,
                "name": "Östergötland County",
                "state_code": "E"
            },
            {
                "id": 1541,
                "name": "Skåne County",
                "state_code": "M"
            },
            {
                "id": 1540,
                "name": "Södermanland County",
                "state_code": "D"
            },
            {
                "id": 1551,
                "name": "Stockholm County",
                "state_code": "AB"
            },
            {
                "id": 1545,
                "name": "Uppsala County",
                "state_code": "C"
            },
            {
                "id": 1535,
                "name": "Värmland County",
                "state_code": "S"
            },
            {
                "id": 1543,
                "name": "Västerbotten County",
                "state_code": "AC"
            },
            {
                "id": 1552,
                "name": "Västernorrland County",
                "state_code": "Y"
            },
            {
                "id": 1549,
                "name": "Västmanland County",
                "state_code": "U"
            },
            {
                "id": 1547,
                "name": "Västra Götaland County",
                "state_code": "O"
            }
        ]
    },
    {
        "name": "Switzerland",
        "iso3": "CHE",
        "iso2": "CH",
        "phone_code": "41",
        "capital": "Bern",
        "currency": "CHF",
        "currency_symbol": "CHf",
        "tld": ".ch",
        "native": "Schweiz",
        "region": "Europe",
        "subregion": "Western Europe",
        "timezones": [
            {
                "zoneName": "Europe/Zurich",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Suíça",
            "pt": "Suíça",
            "nl": "Zwitserland",
            "hr": "Švicarska",
            "fa": "سوئیس",
            "de": "Schweiz",
            "es": "Suiza",
            "fr": "Suisse",
            "ja": "スイス",
            "it": "Svizzera"
        },
        "latitude": "47.00000000",
        "longitude": "8.00000000",
        "emoji": "🇨🇭",
        "emojiU": "U+1F1E8 U+1F1ED",
        "states": [
            {
                "id": 1639,
                "name": "Aargau",
                "state_code": "AG"
            },
            {
                "id": 1655,
                "name": "Appenzell Ausserrhoden",
                "state_code": "AR"
            },
            {
                "id": 1649,
                "name": "Appenzell Innerrhoden",
                "state_code": "AI"
            },
            {
                "id": 1641,
                "name": "Basel-Landschaft",
                "state_code": "BL"
            },
            {
                "id": 1645,
                "name": "canton of Bern",
                "state_code": "BE"
            },
            {
                "id": 1640,
                "name": "Canton of Fribourg",
                "state_code": "FR"
            },
            {
                "id": 1647,
                "name": "Canton of Geneva",
                "state_code": "GE"
            },
            {
                "id": 1658,
                "name": "Canton of Jura",
                "state_code": "JU"
            },
            {
                "id": 1663,
                "name": "Canton of Lucerne",
                "state_code": "LU"
            },
            {
                "id": 1659,
                "name": "Canton of Neuchâtel",
                "state_code": "NE"
            },
            {
                "id": 1654,
                "name": "Canton of Schaffhausen",
                "state_code": "SH"
            },
            {
                "id": 1662,
                "name": "Canton of Solothurn",
                "state_code": "SO"
            },
            {
                "id": 1644,
                "name": "Canton of St. Gallen",
                "state_code": "SG"
            },
            {
                "id": 1648,
                "name": "Canton of Valais",
                "state_code": "VS"
            },
            {
                "id": 1651,
                "name": "Canton of Vaud",
                "state_code": "VD"
            },
            {
                "id": 1646,
                "name": "Canton of Zug",
                "state_code": "ZG"
            },
            {
                "id": 1656,
                "name": "canton of Zürich",
                "state_code": "ZH"
            },
            {
                "id": 1661,
                "name": "Glarus",
                "state_code": "GL"
            },
            {
                "id": 1660,
                "name": "Graubünden",
                "state_code": "GR"
            },
            {
                "id": 1652,
                "name": "Nidwalden",
                "state_code": "NW"
            },
            {
                "id": 1650,
                "name": "Obwalden",
                "state_code": "OW"
            },
            {
                "id": 1653,
                "name": "Schwyz",
                "state_code": "SZ"
            },
            {
                "id": 1657,
                "name": "Thurgau",
                "state_code": "TG"
            },
            {
                "id": 1643,
                "name": "Ticino",
                "state_code": "TI"
            },
            {
                "id": 1642,
                "name": "Uri",
                "state_code": "UR"
            }
        ]
    },
    {
        "name": "Syria",
        "iso3": "SYR",
        "iso2": "SY",
        "phone_code": "963",
        "capital": "Damascus",
        "currency": "SYP",
        "currency_symbol": "LS",
        "tld": ".sy",
        "native": "سوريا",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia/Damascus",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "translations": {
            "br": "Síria",
            "pt": "Síria",
            "nl": "Syrië",
            "hr": "Sirija",
            "fa": "سوریه",
            "de": "Syrien",
            "es": "Siria",
            "fr": "Syrie",
            "ja": "シリア・アラブ共和国",
            "it": "Siria"
        },
        "latitude": "35.00000000",
        "longitude": "38.00000000",
        "emoji": "🇸🇾",
        "emojiU": "U+1F1F8 U+1F1FE",
        "states": [
            {
                "id": 2941,
                "name": "Al-Hasakah Governorate",
                "state_code": "HA"
            },
            {
                "id": 2944,
                "name": "Al-Raqqah Governorate",
                "state_code": "RA"
            },
            {
                "id": 2946,
                "name": "Aleppo Governorate",
                "state_code": "HL"
            },
            {
                "id": 2936,
                "name": "As-Suwayda Governorate",
                "state_code": "SU"
            },
            {
                "id": 2939,
                "name": "Damascus Governorate",
                "state_code": "DI"
            },
            {
                "id": 2945,
                "name": "Daraa Governorate",
                "state_code": "DR"
            },
            {
                "id": 2937,
                "name": "Deir ez-Zor Governorate",
                "state_code": "DY"
            },
            {
                "id": 2934,
                "name": "Hama Governorate",
                "state_code": "HM"
            },
            {
                "id": 2942,
                "name": "Homs Governorate",
                "state_code": "HI"
            },
            {
                "id": 2940,
                "name": "Idlib Governorate",
                "state_code": "ID"
            },
            {
                "id": 2938,
                "name": "Latakia Governorate",
                "state_code": "LA"
            },
            {
                "id": 2943,
                "name": "Quneitra Governorate",
                "state_code": "QU"
            },
            {
                "id": 2935,
                "name": "Rif Dimashq Governorate",
                "state_code": "RD"
            },
            {
                "id": 2947,
                "name": "Tartus Governorate",
                "state_code": "TA"
            }
        ]
    },
    {
        "name": "Taiwan",
        "iso3": "TWN",
        "iso2": "TW",
        "phone_code": "886",
        "capital": "Taipei",
        "currency": "TWD",
        "currency_symbol": "$",
        "tld": ".tw",
        "native": "臺灣",
        "region": "Asia",
        "subregion": "Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Taipei",
                "gmtOffset": 28800,
                "gmtOffsetName": "UTC+08:00",
                "abbreviation": "CST",
                "tzName": "China Standard Time"
            }
        ],
        "translations": {
            "br": "Taiwan",
            "pt": "Taiwan",
            "nl": "Taiwan",
            "hr": "Tajvan",
            "fa": "تایوان",
            "de": "Taiwan",
            "es": "Taiwán",
            "fr": "Taïwan",
            "ja": "台湾（中華民国）",
            "it": "Taiwan"
        },
        "latitude": "23.50000000",
        "longitude": "121.00000000",
        "emoji": "🇹🇼",
        "emojiU": "U+1F1F9 U+1F1FC",
        "states": [
            {
                "id": 3404,
                "name": "Changhua County",
                "state_code": "CHA"
            },
            {
                "id": 3418,
                "name": "Chiayi City",
                "state_code": "CYQ"
            },
            {
                "id": 3408,
                "name": "Chiayi County",
                "state_code": "CYI"
            },
            {
                "id": 3417,
                "name": "Hsinchu",
                "state_code": "HSZ"
            },
            {
                "id": 3423,
                "name": "Hsinchu County",
                "state_code": "HSQ"
            },
            {
                "id": 3411,
                "name": "Hualien County",
                "state_code": "HUA"
            },
            {
                "id": 3412,
                "name": "Kaohsiung",
                "state_code": "KHH"
            },
            {
                "id": 3409,
                "name": "Kaohsiung County",
                "state_code": "KHQ"
            },
            {
                "id": 3415,
                "name": "Kinmen",
                "state_code": "KIN"
            },
            {
                "id": 3420,
                "name": "Lienchiang County",
                "state_code": "LIE"
            },
            {
                "id": 3413,
                "name": "Miaoli County",
                "state_code": "MIA"
            },
            {
                "id": 3407,
                "name": "Nantou County",
                "state_code": "NAN"
            },
            {
                "id": 3403,
                "name": "Penghu County",
                "state_code": "PEN"
            },
            {
                "id": 3405,
                "name": "Pingtung County",
                "state_code": "PIF"
            },
            {
                "id": 3406,
                "name": "Taichung",
                "state_code": "TXG"
            },
            {
                "id": 3414,
                "name": "Taichung County",
                "state_code": "TXQ"
            },
            {
                "id": 3421,
                "name": "Tainan",
                "state_code": "TNN"
            },
            {
                "id": 3401,
                "name": "Tainan County",
                "state_code": "TNQ"
            },
            {
                "id": 3422,
                "name": "Taipei",
                "state_code": "TPE"
            },
            {
                "id": 3410,
                "name": "Taitung County",
                "state_code": "TTT"
            },
            {
                "id": 3419,
                "name": "Taoyuan City",
                "state_code": "TAO"
            },
            {
                "id": 3402,
                "name": "Yilan County",
                "state_code": "ILA"
            },
            {
                "id": 3416,
                "name": "Yunlin County",
                "state_code": "YUN"
            }
        ]
    },
    {
        "name": "Tajikistan",
        "iso3": "TJK",
        "iso2": "TJ",
        "phone_code": "992",
        "capital": "Dushanbe",
        "currency": "TJS",
        "currency_symbol": "SM",
        "tld": ".tj",
        "native": "Тоҷикистон",
        "region": "Asia",
        "subregion": "Central Asia",
        "timezones": [
            {
                "zoneName": "Asia/Dushanbe",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "TJT",
                "tzName": "Tajikistan Time"
            }
        ],
        "translations": {
            "br": "Tajiquistão",
            "pt": "Tajiquistão",
            "nl": "Tadzjikistan",
            "hr": "Tađikistan",
            "fa": "تاجیکستان",
            "de": "Tadschikistan",
            "es": "Tayikistán",
            "fr": "Tadjikistan",
            "ja": "タジキスタン",
            "it": "Tagikistan"
        },
        "latitude": "39.00000000",
        "longitude": "71.00000000",
        "emoji": "🇹🇯",
        "emojiU": "U+1F1F9 U+1F1EF",
        "states": [
            {
                "id": 3397,
                "name": "districts of Republican Subordination",
                "state_code": "RA"
            },
            {
                "id": 3399,
                "name": "Gorno-Badakhshan Autonomous Province",
                "state_code": "GB"
            },
            {
                "id": 3398,
                "name": "Khatlon Province",
                "state_code": "KT"
            },
            {
                "id": 3400,
                "name": "Sughd Province",
                "state_code": "SU"
            }
        ]
    },
    {
        "name": "Tanzania",
        "iso3": "TZA",
        "iso2": "TZ",
        "phone_code": "255",
        "capital": "Dodoma",
        "currency": "TZS",
        "currency_symbol": "TSh",
        "tld": ".tz",
        "native": "Tanzania",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa/Dar_es_Salaam",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "EAT",
                "tzName": "East Africa Time"
            }
        ],
        "translations": {
            "br": "Tanzânia",
            "pt": "Tanzânia",
            "nl": "Tanzania",
            "hr": "Tanzanija",
            "fa": "تانزانیا",
            "de": "Tansania",
            "es": "Tanzania",
            "fr": "Tanzanie",
            "ja": "タンザニア",
            "it": "Tanzania"
        },
        "latitude": "-6.00000000",
        "longitude": "35.00000000",
        "emoji": "🇹🇿",
        "emojiU": "U+1F1F9 U+1F1FF",
        "states": [
            {
                "id": 1491,
                "name": "Arusha Region",
                "state_code": "01"
            },
            {
                "id": 1490,
                "name": "Dar es Salaam Region",
                "state_code": "02"
            },
            {
                "id": 1466,
                "name": "Dodoma Region",
                "state_code": "03"
            },
            {
                "id": 1481,
                "name": "Geita Region",
                "state_code": "27"
            },
            {
                "id": 1489,
                "name": "Iringa Region",
                "state_code": "04"
            },
            {
                "id": 1465,
                "name": "Kagera Region",
                "state_code": "05"
            },
            {
                "id": 1482,
                "name": "Katavi Region",
                "state_code": "28"
            },
            {
                "id": 1478,
                "name": "Kigoma Region",
                "state_code": "08"
            },
            {
                "id": 1467,
                "name": "Kilimanjaro Region",
                "state_code": "09"
            },
            {
                "id": 1483,
                "name": "Lindi Region",
                "state_code": "12"
            },
            {
                "id": 1484,
                "name": "Manyara Region",
                "state_code": "26"
            },
            {
                "id": 1468,
                "name": "Mara Region",
                "state_code": "13"
            },
            {
                "id": 1470,
                "name": "Morogoro Region",
                "state_code": "16"
            },
            {
                "id": 1476,
                "name": "Mtwara Region",
                "state_code": "17"
            },
            {
                "id": 1479,
                "name": "Mwanza Region",
                "state_code": "18"
            },
            {
                "id": 1480,
                "name": "Njombe Region",
                "state_code": "29"
            },
            {
                "id": 1488,
                "name": "North Pemba Region",
                "state_code": "06"
            },
            {
                "id": 1485,
                "name": "Pwani Region",
                "state_code": "19"
            },
            {
                "id": 1477,
                "name": "Rukwa Region",
                "state_code": "20"
            },
            {
                "id": 1486,
                "name": "Ruvuma Region",
                "state_code": "21"
            },
            {
                "id": 1463,
                "name": "Shinyanga Region",
                "state_code": "22"
            },
            {
                "id": 1464,
                "name": "Simiyu Region",
                "state_code": "30"
            },
            {
                "id": 1474,
                "name": "Singida Region",
                "state_code": "23"
            },
            {
                "id": 1472,
                "name": "South Pemba Region",
                "state_code": "10"
            },
            {
                "id": 1469,
                "name": "Tabora Region",
                "state_code": "24"
            },
            {
                "id": 1487,
                "name": "Tanga Region",
                "state_code": "25"
            },
            {
                "id": 1471,
                "name": "Zanzibar Central/South Region",
                "state_code": "11"
            },
            {
                "id": 1473,
                "name": "Zanzibar North Region",
                "state_code": "07"
            },
            {
                "id": 1475,
                "name": "Zanzibar Urban/West Region",
                "state_code": "15"
            }
        ]
    },
    {
        "name": "Thailand",
        "iso3": "THA",
        "iso2": "TH",
        "phone_code": "66",
        "capital": "Bangkok",
        "currency": "THB",
        "currency_symbol": "฿",
        "tld": ".th",
        "native": "ประเทศไทย",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Bangkok",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "ICT",
                "tzName": "Indochina Time"
            }
        ],
        "translations": {
            "br": "Tailândia",
            "pt": "Tailândia",
            "nl": "Thailand",
            "hr": "Tajland",
            "fa": "تایلند",
            "de": "Thailand",
            "es": "Tailandia",
            "fr": "Thaïlande",
            "ja": "タイ",
            "it": "Tailandia"
        },
        "latitude": "15.00000000",
        "longitude": "100.00000000",
        "emoji": "🇹🇭",
        "emojiU": "U+1F1F9 U+1F1ED",
        "states": [
            {
                "id": 3523,
                "name": "Amnat Charoen",
                "state_code": "37"
            },
            {
                "id": 3519,
                "name": "Ang Thong",
                "state_code": "15"
            },
            {
                "id": 3554,
                "name": "Bangkok",
                "state_code": "10"
            },
            {
                "id": 3533,
                "name": "Bueng Kan",
                "state_code": "38"
            },
            {
                "id": 3534,
                "name": "Buri Ram",
                "state_code": "31"
            },
            {
                "id": 3552,
                "name": "Chachoengsao",
                "state_code": "24"
            },
            {
                "id": 3522,
                "name": "Chai Nat",
                "state_code": "18"
            },
            {
                "id": 3486,
                "name": "Chanthaburi",
                "state_code": "22"
            },
            {
                "id": 3491,
                "name": "Chiang Mai",
                "state_code": "50"
            },
            {
                "id": 3498,
                "name": "Chiang Rai",
                "state_code": "57"
            },
            {
                "id": 3513,
                "name": "Chon Buri",
                "state_code": "20"
            },
            {
                "id": 3526,
                "name": "Chumphon",
                "state_code": "86"
            },
            {
                "id": 3550,
                "name": "Kalasin",
                "state_code": "46"
            },
            {
                "id": 3516,
                "name": "Kamphaeng Phet",
                "state_code": "62"
            },
            {
                "id": 3511,
                "name": "Kanchanaburi",
                "state_code": "71"
            },
            {
                "id": 3485,
                "name": "Khon Kaen",
                "state_code": "40"
            },
            {
                "id": 3478,
                "name": "Krabi",
                "state_code": "81"
            },
            {
                "id": 3544,
                "name": "Lampang",
                "state_code": "52"
            },
            {
                "id": 3483,
                "name": "Lamphun",
                "state_code": "51"
            },
            {
                "id": 3509,
                "name": "Loei",
                "state_code": "42"
            },
            {
                "id": 3543,
                "name": "Lopburi",
                "state_code": "16"
            },
            {
                "id": 3505,
                "name": "Mae Hong Son",
                "state_code": "58"
            },
            {
                "id": 3517,
                "name": "Maha Sarakham",
                "state_code": "44"
            },
            {
                "id": 3546,
                "name": "Mukdahan",
                "state_code": "49"
            },
            {
                "id": 3535,
                "name": "Nakhon Nayok",
                "state_code": "26"
            },
            {
                "id": 3503,
                "name": "Nakhon Pathom",
                "state_code": "73"
            },
            {
                "id": 3548,
                "name": "Nakhon Phanom",
                "state_code": "48"
            },
            {
                "id": 3497,
                "name": "Nakhon Ratchasima",
                "state_code": "30"
            },
            {
                "id": 3492,
                "name": "Nakhon Sawan",
                "state_code": "60"
            },
            {
                "id": 3520,
                "name": "Nakhon Si Thammarat",
                "state_code": "80"
            },
            {
                "id": 3530,
                "name": "Nan",
                "state_code": "55"
            },
            {
                "id": 3553,
                "name": "Narathiwat",
                "state_code": "96"
            },
            {
                "id": 3480,
                "name": "Nong Bua Lam Phu",
                "state_code": "39"
            },
            {
                "id": 3484,
                "name": "Nong Khai",
                "state_code": "43"
            },
            {
                "id": 3495,
                "name": "Nonthaburi",
                "state_code": "12"
            },
            {
                "id": 3500,
                "name": "Pathum Thani",
                "state_code": "13"
            },
            {
                "id": 3540,
                "name": "Pattani",
                "state_code": "94"
            },
            {
                "id": 3507,
                "name": "Pattaya",
                "state_code": "S"
            },
            {
                "id": 3549,
                "name": "Phang Nga",
                "state_code": "82"
            },
            {
                "id": 3488,
                "name": "Phatthalung",
                "state_code": "93"
            },
            {
                "id": 3538,
                "name": "Phayao",
                "state_code": "56"
            },
            {
                "id": 3515,
                "name": "Phetchabun",
                "state_code": "67"
            },
            {
                "id": 3532,
                "name": "Phetchaburi",
                "state_code": "76"
            },
            {
                "id": 3514,
                "name": "Phichit",
                "state_code": "66"
            },
            {
                "id": 3506,
                "name": "Phitsanulok",
                "state_code": "65"
            },
            {
                "id": 3494,
                "name": "Phra Nakhon Si Ayutthaya",
                "state_code": "14"
            },
            {
                "id": 3528,
                "name": "Phrae",
                "state_code": "54"
            },
            {
                "id": 3536,
                "name": "Phuket",
                "state_code": "83"
            },
            {
                "id": 3542,
                "name": "Prachin Buri",
                "state_code": "25"
            },
            {
                "id": 3508,
                "name": "Prachuap Khiri Khan",
                "state_code": "77"
            },
            {
                "id": 3479,
                "name": "Ranong",
                "state_code": "85"
            },
            {
                "id": 3499,
                "name": "Ratchaburi",
                "state_code": "70"
            },
            {
                "id": 3518,
                "name": "Rayong",
                "state_code": "21"
            },
            {
                "id": 3510,
                "name": "Roi Et",
                "state_code": "45"
            },
            {
                "id": 3529,
                "name": "Sa Kaeo",
                "state_code": "27"
            },
            {
                "id": 3501,
                "name": "Sakon Nakhon",
                "state_code": "47"
            },
            {
                "id": 3481,
                "name": "Samut Prakan",
                "state_code": "11"
            },
            {
                "id": 3504,
                "name": "Samut Sakhon",
                "state_code": "74"
            },
            {
                "id": 3502,
                "name": "Samut Songkhram",
                "state_code": "75"
            },
            {
                "id": 3487,
                "name": "Saraburi",
                "state_code": "19"
            },
            {
                "id": 3537,
                "name": "Satun",
                "state_code": "91"
            },
            {
                "id": 3547,
                "name": "Si Sa Ket",
                "state_code": "33"
            },
            {
                "id": 3490,
                "name": "Sing Buri",
                "state_code": "17"
            },
            {
                "id": 3539,
                "name": "Songkhla",
                "state_code": "90"
            },
            {
                "id": 3545,
                "name": "Sukhothai",
                "state_code": "64"
            },
            {
                "id": 3524,
                "name": "Suphanburi",
                "state_code": "72"
            },
            {
                "id": 3482,
                "name": "Surat Thani",
                "state_code": "84"
            },
            {
                "id": 3531,
                "name": "Surin",
                "state_code": "32"
            },
            {
                "id": 3525,
                "name": "Tak",
                "state_code": "63"
            },
            {
                "id": 3541,
                "name": "Trang",
                "state_code": "92"
            },
            {
                "id": 3496,
                "name": "Trat",
                "state_code": "23"
            },
            {
                "id": 3512,
                "name": "Ubon Ratchathani",
                "state_code": "34"
            },
            {
                "id": 3527,
                "name": "Udon Thani",
                "state_code": "41"
            },
            {
                "id": 3551,
                "name": "Uthai Thani",
                "state_code": "61"
            },
            {
                "id": 3489,
                "name": "Uttaradit",
                "state_code": "53"
            },
            {
                "id": 3493,
                "name": "Yala",
                "state_code": "95"
            },
            {
                "id": 3521,
                "name": "Yasothon",
                "state_code": "35"
            }
        ]
    },
    {
        "name": "Togo",
        "iso3": "TGO",
        "iso2": "TG",
        "phone_code": "228",
        "capital": "Lome",
        "currency": "XOF",
        "currency_symbol": "CFA",
        "tld": ".tg",
        "native": "Togo",
        "region": "Africa",
        "subregion": "Western Africa",
        "timezones": [
            {
                "zoneName": "Africa/Lome",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "translations": {
            "br": "Togo",
            "pt": "Togo",
            "nl": "Togo",
            "hr": "Togo",
            "fa": "توگو",
            "de": "Togo",
            "es": "Togo",
            "fr": "Togo",
            "ja": "トーゴ",
            "it": "Togo"
        },
        "latitude": "8.00000000",
        "longitude": "1.16666666",
        "emoji": "🇹🇬",
        "emojiU": "U+1F1F9 U+1F1EC",
        "states": [
            {
                "id": 2575,
                "name": "Centrale Region",
                "state_code": "C"
            },
            {
                "id": 2579,
                "name": "Kara Region",
                "state_code": "K"
            },
            {
                "id": 2576,
                "name": "Maritime",
                "state_code": "M"
            },
            {
                "id": 2577,
                "name": "Plateaux Region",
                "state_code": "P"
            },
            {
                "id": 2578,
                "name": "Savanes Region",
                "state_code": "S"
            }
        ]
    },
    {
        "name": "Tokelau",
        "iso3": "TKL",
        "iso2": "TK",
        "phone_code": "690",
        "capital": "",
        "currency": "NZD",
        "currency_symbol": "$",
        "tld": ".tk",
        "native": "Tokelau",
        "region": "Oceania",
        "subregion": "Polynesia",
        "timezones": [
            {
                "zoneName": "Pacific/Fakaofo",
                "gmtOffset": 46800,
                "gmtOffsetName": "UTC+13:00",
                "abbreviation": "TKT",
                "tzName": "Tokelau Time"
            }
        ],
        "translations": {
            "br": "Tokelau",
            "pt": "Toquelau",
            "nl": "Tokelau",
            "hr": "Tokelau",
            "fa": "توکلائو",
            "de": "Tokelau",
            "es": "Islas Tokelau",
            "fr": "Tokelau",
            "ja": "トケラウ",
            "it": "Isole Tokelau"
        },
        "latitude": "-9.00000000",
        "longitude": "-172.00000000",
        "emoji": "🇹🇰",
        "emojiU": "U+1F1F9 U+1F1F0",
        "states": []
    },
    {
        "name": "Tonga",
        "iso3": "TON",
        "iso2": "TO",
        "phone_code": "676",
        "capital": "Nuku'alofa",
        "currency": "TOP",
        "currency_symbol": "$",
        "tld": ".to",
        "native": "Tonga",
        "region": "Oceania",
        "subregion": "Polynesia",
        "timezones": [
            {
                "zoneName": "Pacific/Tongatapu",
                "gmtOffset": 46800,
                "gmtOffsetName": "UTC+13:00",
                "abbreviation": "TOT",
                "tzName": "Tonga Time"
            }
        ],
        "translations": {
            "br": "Tonga",
            "pt": "Tonga",
            "nl": "Tonga",
            "hr": "Tonga",
            "fa": "تونگا",
            "de": "Tonga",
            "es": "Tonga",
            "fr": "Tonga",
            "ja": "トンガ",
            "it": "Tonga"
        },
        "latitude": "-20.00000000",
        "longitude": "-175.00000000",
        "emoji": "🇹🇴",
        "emojiU": "U+1F1F9 U+1F1F4",
        "states": [
            {
                "id": 3913,
                "name": "Haʻapai",
                "state_code": "02"
            },
            {
                "id": 3915,
                "name": "ʻEua",
                "state_code": "01"
            },
            {
                "id": 3914,
                "name": "Niuas",
                "state_code": "03"
            },
            {
                "id": 3912,
                "name": "Tongatapu",
                "state_code": "04"
            },
            {
                "id": 3911,
                "name": "Vavaʻu",
                "state_code": "05"
            }
        ]
    },
    {
        "name": "Trinidad And Tobago",
        "iso3": "TTO",
        "iso2": "TT",
        "phone_code": "+1-868",
        "capital": "Port of Spain",
        "currency": "TTD",
        "currency_symbol": "$",
        "tld": ".tt",
        "native": "Trinidad and Tobago",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/Port_of_Spain",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "translations": {
            "br": "Trinidad e Tobago",
            "pt": "Trindade e Tobago",
            "nl": "Trinidad en Tobago",
            "hr": "Trinidad i Tobago",
            "fa": "ترینیداد و توباگو",
            "de": "Trinidad und Tobago",
            "es": "Trinidad y Tobago",
            "fr": "Trinité et Tobago",
            "ja": "トリニダード・トバゴ",
            "it": "Trinidad e Tobago"
        },
        "latitude": "11.00000000",
        "longitude": "-61.00000000",
        "emoji": "🇹🇹",
        "emojiU": "U+1F1F9 U+1F1F9",
        "states": [
            {
                "id": 3362,
                "name": "Arima",
                "state_code": "ARI"
            },
            {
                "id": 3366,
                "name": "Chaguanas",
                "state_code": "CHA"
            },
            {
                "id": 3354,
                "name": "Couva-Tabaquite-Talparo Regional Corporation",
                "state_code": "CTT"
            },
            {
                "id": 3367,
                "name": "Diego Martin Regional Corporation",
                "state_code": "DMN"
            },
            {
                "id": 3355,
                "name": "Eastern Tobago",
                "state_code": "ETO"
            },
            {
                "id": 3365,
                "name": "Penal-Debe Regional Corporation",
                "state_code": "PED"
            },
            {
                "id": 3360,
                "name": "Point Fortin",
                "state_code": "PTF"
            },
            {
                "id": 3363,
                "name": "Port of Spain",
                "state_code": "POS"
            },
            {
                "id": 3368,
                "name": "Princes Town Regional Corporation",
                "state_code": "PRT"
            },
            {
                "id": 3356,
                "name": "Rio Claro-Mayaro Regional Corporation",
                "state_code": "MRC"
            },
            {
                "id": 3359,
                "name": "San Fernando",
                "state_code": "SFO"
            },
            {
                "id": 3357,
                "name": "San Juan-Laventille Regional Corporation",
                "state_code": "SJL"
            },
            {
                "id": 3361,
                "name": "Sangre Grande Regional Corporation",
                "state_code": "SGE"
            },
            {
                "id": 3364,
                "name": "Siparia Regional Corporation",
                "state_code": "SIP"
            },
            {
                "id": 3358,
                "name": "Tunapuna-Piarco Regional Corporation",
                "state_code": "TUP"
            },
            {
                "id": 3353,
                "name": "Western Tobago",
                "state_code": "WTO"
            }
        ]
    },
    {
        "name": "Tunisia",
        "iso3": "TUN",
        "iso2": "TN",
        "phone_code": "216",
        "capital": "Tunis",
        "currency": "TND",
        "currency_symbol": "ت.د",
        "tld": ".tn",
        "native": "تونس",
        "region": "Africa",
        "subregion": "Northern Africa",
        "timezones": [
            {
                "zoneName": "Africa/Tunis",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Tunísia",
            "pt": "Tunísia",
            "nl": "Tunesië",
            "hr": "Tunis",
            "fa": "تونس",
            "de": "Tunesien",
            "es": "Túnez",
            "fr": "Tunisie",
            "ja": "チュニジア",
            "it": "Tunisia"
        },
        "latitude": "34.00000000",
        "longitude": "9.00000000",
        "emoji": "🇹🇳",
        "emojiU": "U+1F1F9 U+1F1F3",
        "states": [
            {
                "id": 2550,
                "name": "Ariana Governorate",
                "state_code": "12"
            },
            {
                "id": 2566,
                "name": "Ben Arous Governorate",
                "state_code": "13"
            },
            {
                "id": 2551,
                "name": "Bizerte Governorate",
                "state_code": "23"
            },
            {
                "id": 2558,
                "name": "Gabès Governorate",
                "state_code": "81"
            },
            {
                "id": 2556,
                "name": "Gafsa Governorate",
                "state_code": "71"
            },
            {
                "id": 2552,
                "name": "Jendouba Governorate",
                "state_code": "32"
            },
            {
                "id": 2564,
                "name": "Kairouan Governorate",
                "state_code": "41"
            },
            {
                "id": 2570,
                "name": "Kasserine Governorate",
                "state_code": "42"
            },
            {
                "id": 2572,
                "name": "Kassrine",
                "state_code": "31"
            },
            {
                "id": 2562,
                "name": "Kebili Governorate",
                "state_code": "73"
            },
            {
                "id": 2561,
                "name": "Kef Governorate",
                "state_code": "33"
            },
            {
                "id": 2568,
                "name": "Mahdia Governorate",
                "state_code": "53"
            },
            {
                "id": 2555,
                "name": "Manouba Governorate",
                "state_code": "14"
            },
            {
                "id": 2560,
                "name": "Medenine Governorate",
                "state_code": "82"
            },
            {
                "id": 2553,
                "name": "Monastir Governorate",
                "state_code": "52"
            },
            {
                "id": 2557,
                "name": "Sfax Governorate",
                "state_code": "61"
            },
            {
                "id": 2567,
                "name": "Sidi Bouzid Governorate",
                "state_code": "43"
            },
            {
                "id": 2563,
                "name": "Siliana Governorate",
                "state_code": "34"
            },
            {
                "id": 2571,
                "name": "Sousse Governorate",
                "state_code": "51"
            },
            {
                "id": 2559,
                "name": "Tataouine Governorate",
                "state_code": "83"
            },
            {
                "id": 2569,
                "name": "Tozeur Governorate",
                "state_code": "72"
            },
            {
                "id": 2554,
                "name": "Tunis Governorate",
                "state_code": "11"
            },
            {
                "id": 2565,
                "name": "Zaghouan Governorate",
                "state_code": "22"
            }
        ]
    },
    {
        "name": "Turkey",
        "iso3": "TUR",
        "iso2": "TR",
        "phone_code": "90",
        "capital": "Ankara",
        "currency": "TRY",
        "currency_symbol": "₺",
        "tld": ".tr",
        "native": "Türkiye",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Europe/Istanbul",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "translations": {
            "br": "Turquia",
            "pt": "Turquia",
            "nl": "Turkije",
            "hr": "Turska",
            "fa": "ترکیه",
            "de": "Türkei",
            "es": "Turquía",
            "fr": "Turquie",
            "ja": "トルコ",
            "it": "Turchia"
        },
        "latitude": "39.00000000",
        "longitude": "35.00000000",
        "emoji": "🇹🇷",
        "emojiU": "U+1F1F9 U+1F1F7",
        "states": [
            {
                "id": 2212,
                "name": "Adana Province",
                "state_code": "01"
            },
            {
                "id": 2155,
                "name": "Adıyaman Province",
                "state_code": "02"
            },
            {
                "id": 2179,
                "name": "Afyonkarahisar Province",
                "state_code": "03"
            },
            {
                "id": 2193,
                "name": "Ağrı Province",
                "state_code": "04"
            },
            {
                "id": 2210,
                "name": "Aksaray Province",
                "state_code": "68"
            },
            {
                "id": 2161,
                "name": "Amasya Province",
                "state_code": "05"
            },
            {
                "id": 2217,
                "name": "Ankara Province",
                "state_code": "06"
            },
            {
                "id": 2169,
                "name": "Antalya Province",
                "state_code": "07"
            },
            {
                "id": 2185,
                "name": "Ardahan Province",
                "state_code": "75"
            },
            {
                "id": 2191,
                "name": "Artvin Province",
                "state_code": "08"
            },
            {
                "id": 2187,
                "name": "Aydın Province",
                "state_code": "09"
            },
            {
                "id": 2175,
                "name": "Balıkesir Province",
                "state_code": "10"
            },
            {
                "id": 2148,
                "name": "Bartın Province",
                "state_code": "74"
            },
            {
                "id": 2194,
                "name": "Batman Province",
                "state_code": "72"
            },
            {
                "id": 2177,
                "name": "Bayburt Province",
                "state_code": "69"
            },
            {
                "id": 2221,
                "name": "Bilecik Province",
                "state_code": "11"
            },
            {
                "id": 2153,
                "name": "Bingöl Province",
                "state_code": "12"
            },
            {
                "id": 2215,
                "name": "Bitlis Province",
                "state_code": "13"
            },
            {
                "id": 2172,
                "name": "Bolu Province",
                "state_code": "14"
            },
            {
                "id": 2209,
                "name": "Burdur Province",
                "state_code": "15"
            },
            {
                "id": 2163,
                "name": "Bursa Province",
                "state_code": "16"
            },
            {
                "id": 2216,
                "name": "Çanakkale Province",
                "state_code": "17"
            },
            {
                "id": 2168,
                "name": "Çankırı Province",
                "state_code": "18"
            },
            {
                "id": 2173,
                "name": "Çorum Province",
                "state_code": "19"
            },
            {
                "id": 2157,
                "name": "Denizli Province",
                "state_code": "20"
            },
            {
                "id": 2226,
                "name": "Diyarbakır Province",
                "state_code": "21"
            },
            {
                "id": 2202,
                "name": "Düzce Province",
                "state_code": "81"
            },
            {
                "id": 2151,
                "name": "Edirne Province",
                "state_code": "22"
            },
            {
                "id": 2159,
                "name": "Elazığ Province",
                "state_code": "23"
            },
            {
                "id": 2160,
                "name": "Erzincan Province",
                "state_code": "24"
            },
            {
                "id": 2165,
                "name": "Erzurum Province",
                "state_code": "25"
            },
            {
                "id": 2164,
                "name": "Eskişehir Province",
                "state_code": "26"
            },
            {
                "id": 2203,
                "name": "Gaziantep Province",
                "state_code": "27"
            },
            {
                "id": 2186,
                "name": "Giresun Province",
                "state_code": "28"
            },
            {
                "id": 2204,
                "name": "Gümüşhane Province",
                "state_code": "29"
            },
            {
                "id": 2190,
                "name": "Hakkâri Province",
                "state_code": "30"
            },
            {
                "id": 2211,
                "name": "Hatay Province",
                "state_code": "31"
            },
            {
                "id": 2166,
                "name": "Iğdır Province",
                "state_code": "76"
            },
            {
                "id": 2222,
                "name": "Isparta Province",
                "state_code": "32"
            },
            {
                "id": 2170,
                "name": "Istanbul Province",
                "state_code": "34"
            },
            {
                "id": 2205,
                "name": "İzmir Province",
                "state_code": "35"
            },
            {
                "id": 2227,
                "name": "Kahramanmaraş Province",
                "state_code": "46"
            },
            {
                "id": 2223,
                "name": "Karabük Province",
                "state_code": "78"
            },
            {
                "id": 2184,
                "name": "Karaman Province",
                "state_code": "70"
            },
            {
                "id": 2208,
                "name": "Kars Province",
                "state_code": "36"
            },
            {
                "id": 2197,
                "name": "Kastamonu Province",
                "state_code": "37"
            },
            {
                "id": 2200,
                "name": "Kayseri Province",
                "state_code": "38"
            },
            {
                "id": 2154,
                "name": "Kilis Province",
                "state_code": "79"
            },
            {
                "id": 2178,
                "name": "Kırıkkale Province",
                "state_code": "71"
            },
            {
                "id": 2176,
                "name": "Kırklareli Province",
                "state_code": "39"
            },
            {
                "id": 2180,
                "name": "Kırşehir Province",
                "state_code": "40"
            },
            {
                "id": 2195,
                "name": "Kocaeli Province",
                "state_code": "41"
            },
            {
                "id": 2171,
                "name": "Konya Province",
                "state_code": "42"
            },
            {
                "id": 2149,
                "name": "Kütahya Province",
                "state_code": "43"
            },
            {
                "id": 2158,
                "name": "Malatya Province",
                "state_code": "44"
            },
            {
                "id": 2198,
                "name": "Manisa Province",
                "state_code": "45"
            },
            {
                "id": 2224,
                "name": "Mardin Province",
                "state_code": "47"
            },
            {
                "id": 2156,
                "name": "Mersin Province",
                "state_code": "33"
            },
            {
                "id": 2182,
                "name": "Muğla Province",
                "state_code": "48"
            },
            {
                "id": 2162,
                "name": "Muş Province",
                "state_code": "49"
            },
            {
                "id": 2196,
                "name": "Nevşehir Province",
                "state_code": "50"
            },
            {
                "id": 2189,
                "name": "Niğde Province",
                "state_code": "51"
            },
            {
                "id": 2174,
                "name": "Ordu Province",
                "state_code": "52"
            },
            {
                "id": 2214,
                "name": "Osmaniye Province",
                "state_code": "80"
            },
            {
                "id": 2219,
                "name": "Rize Province",
                "state_code": "53"
            },
            {
                "id": 2150,
                "name": "Sakarya Province",
                "state_code": "54"
            },
            {
                "id": 2220,
                "name": "Samsun Province",
                "state_code": "55"
            },
            {
                "id": 2183,
                "name": "Şanlıurfa Province",
                "state_code": "63"
            },
            {
                "id": 2207,
                "name": "Siirt Province",
                "state_code": "56"
            },
            {
                "id": 4854,
                "name": "Sinop Province",
                "state_code": "57"
            },
            {
                "id": 2181,
                "name": "Sivas Province",
                "state_code": "58"
            },
            {
                "id": 2225,
                "name": "Şırnak Province",
                "state_code": "73"
            },
            {
                "id": 2167,
                "name": "Tekirdağ Province",
                "state_code": "59"
            },
            {
                "id": 2199,
                "name": "Tokat Province",
                "state_code": "60"
            },
            {
                "id": 2206,
                "name": "Trabzon Province",
                "state_code": "61"
            },
            {
                "id": 2192,
                "name": "Tunceli Province",
                "state_code": "62"
            },
            {
                "id": 2201,
                "name": "Uşak Province",
                "state_code": "64"
            },
            {
                "id": 2152,
                "name": "Van Province",
                "state_code": "65"
            },
            {
                "id": 2218,
                "name": "Yalova Province",
                "state_code": "77"
            },
            {
                "id": 2188,
                "name": "Yozgat Province",
                "state_code": "66"
            },
            {
                "id": 2213,
                "name": "Zonguldak Province",
                "state_code": "67"
            }
        ]
    },
    {
        "name": "Turkmenistan",
        "iso3": "TKM",
        "iso2": "TM",
        "phone_code": "993",
        "capital": "Ashgabat",
        "currency": "TMT",
        "currency_symbol": "T",
        "tld": ".tm",
        "native": "Türkmenistan",
        "region": "Asia",
        "subregion": "Central Asia",
        "timezones": [
            {
                "zoneName": "Asia/Ashgabat",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "TMT",
                "tzName": "Turkmenistan Time"
            }
        ],
        "translations": {
            "br": "Turcomenistão",
            "pt": "Turquemenistão",
            "nl": "Turkmenistan",
            "hr": "Turkmenistan",
            "fa": "ترکمنستان",
            "de": "Turkmenistan",
            "es": "Turkmenistán",
            "fr": "Turkménistan",
            "ja": "トルクメニスタン",
            "it": "Turkmenistan"
        },
        "latitude": "40.00000000",
        "longitude": "60.00000000",
        "emoji": "🇹🇲",
        "emojiU": "U+1F1F9 U+1F1F2",
        "states": [
            {
                "id": 3374,
                "name": "Ahal Region",
                "state_code": "A"
            },
            {
                "id": 3371,
                "name": "Ashgabat",
                "state_code": "S"
            },
            {
                "id": 3372,
                "name": "Balkan Region",
                "state_code": "B"
            },
            {
                "id": 3373,
                "name": "Daşoguz Region",
                "state_code": "D"
            },
            {
                "id": 3370,
                "name": "Lebap Region",
                "state_code": "L"
            },
            {
                "id": 3369,
                "name": "Mary Region",
                "state_code": "M"
            }
        ]
    },
    {
        "name": "Turks And Caicos Islands",
        "iso3": "TCA",
        "iso2": "TC",
        "phone_code": "+1-649",
        "capital": "Cockburn Town",
        "currency": "USD",
        "currency_symbol": "$",
        "tld": ".tc",
        "native": "Turks and Caicos Islands",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/Grand_Turk",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            }
        ],
        "translations": {
            "br": "Ilhas Turcas e Caicos",
            "pt": "Ilhas Turcas e Caicos",
            "nl": "Turks- en Caicoseilanden",
            "hr": "Otoci Turks i Caicos",
            "fa": "جزایر تورکس و کایکوس",
            "de": "Turks- und Caicosinseln",
            "es": "Islas Turks y Caicos",
            "fr": "Îles Turques-et-Caïques",
            "ja": "タークス・カイコス諸島",
            "it": "Isole Turks e Caicos"
        },
        "latitude": "21.75000000",
        "longitude": "-71.58333333",
        "emoji": "🇹🇨",
        "emojiU": "U+1F1F9 U+1F1E8",
        "states": []
    },
    {
        "name": "Tuvalu",
        "iso3": "TUV",
        "iso2": "TV",
        "phone_code": "688",
        "capital": "Funafuti",
        "currency": "AUD",
        "currency_symbol": "$",
        "tld": ".tv",
        "native": "Tuvalu",
        "region": "Oceania",
        "subregion": "Polynesia",
        "timezones": [
            {
                "zoneName": "Pacific/Funafuti",
                "gmtOffset": 43200,
                "gmtOffsetName": "UTC+12:00",
                "abbreviation": "TVT",
                "tzName": "Tuvalu Time"
            }
        ],
        "translations": {
            "br": "Tuvalu",
            "pt": "Tuvalu",
            "nl": "Tuvalu",
            "hr": "Tuvalu",
            "fa": "تووالو",
            "de": "Tuvalu",
            "es": "Tuvalu",
            "fr": "Tuvalu",
            "ja": "ツバル",
            "it": "Tuvalu"
        },
        "latitude": "-8.00000000",
        "longitude": "178.00000000",
        "emoji": "🇹🇻",
        "emojiU": "U+1F1F9 U+1F1FB",
        "states": [
            {
                "id": 3951,
                "name": "Funafuti",
                "state_code": "FUN"
            },
            {
                "id": 3947,
                "name": "Nanumanga",
                "state_code": "NMG"
            },
            {
                "id": 3949,
                "name": "Nanumea",
                "state_code": "NMA"
            },
            {
                "id": 3946,
                "name": "Niutao Island Council",
                "state_code": "NIT"
            },
            {
                "id": 3948,
                "name": "Nui",
                "state_code": "NUI"
            },
            {
                "id": 3952,
                "name": "Nukufetau",
                "state_code": "NKF"
            },
            {
                "id": 3953,
                "name": "Nukulaelae",
                "state_code": "NKL"
            },
            {
                "id": 3950,
                "name": "Vaitupu",
                "state_code": "VAI"
            }
        ]
    },
    {
        "name": "Uganda",
        "iso3": "UGA",
        "iso2": "UG",
        "phone_code": "256",
        "capital": "Kampala",
        "currency": "UGX",
        "currency_symbol": "USh",
        "tld": ".ug",
        "native": "Uganda",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa/Kampala",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "EAT",
                "tzName": "East Africa Time"
            }
        ],
        "translations": {
            "br": "Uganda",
            "pt": "Uganda",
            "nl": "Oeganda",
            "hr": "Uganda",
            "fa": "اوگاندا",
            "de": "Uganda",
            "es": "Uganda",
            "fr": "Uganda",
            "ja": "ウガンダ",
            "it": "Uganda"
        },
        "latitude": "1.00000000",
        "longitude": "32.00000000",
        "emoji": "🇺🇬",
        "emojiU": "U+1F1FA U+1F1EC",
        "states": [
            {
                "id": 329,
                "name": "Abim District",
                "state_code": "314"
            },
            {
                "id": 361,
                "name": "Adjumani District",
                "state_code": "301"
            },
            {
                "id": 392,
                "name": "Agago District",
                "state_code": "322"
            },
            {
                "id": 344,
                "name": "Alebtong District",
                "state_code": "323"
            },
            {
                "id": 416,
                "name": "Amolatar District",
                "state_code": "315"
            },
            {
                "id": 353,
                "name": "Amudat District",
                "state_code": "324"
            },
            {
                "id": 352,
                "name": "Amuria District",
                "state_code": "216"
            },
            {
                "id": 335,
                "name": "Amuru District",
                "state_code": "316"
            },
            {
                "id": 328,
                "name": "Apac District",
                "state_code": "302"
            },
            {
                "id": 447,
                "name": "Arua District",
                "state_code": "303"
            },
            {
                "id": 441,
                "name": "Budaka District",
                "state_code": "217"
            },
            {
                "id": 349,
                "name": "Bududa District",
                "state_code": "218"
            },
            {
                "id": 387,
                "name": "Bugiri District",
                "state_code": "201"
            },
            {
                "id": 391,
                "name": "Buhweju District",
                "state_code": "420"
            },
            {
                "id": 377,
                "name": "Buikwe District",
                "state_code": "117"
            },
            {
                "id": 343,
                "name": "Bukedea District",
                "state_code": "219"
            },
            {
                "id": 375,
                "name": "Bukomansimbi District",
                "state_code": "118"
            },
            {
                "id": 385,
                "name": "Bukwo District",
                "state_code": "220"
            },
            {
                "id": 428,
                "name": "Bulambuli District",
                "state_code": "225"
            },
            {
                "id": 389,
                "name": "Buliisa District",
                "state_code": "416"
            },
            {
                "id": 419,
                "name": "Bundibugyo District",
                "state_code": "401"
            },
            {
                "id": 381,
                "name": "Bunyangabu District",
                "state_code": "430"
            },
            {
                "id": 386,
                "name": "Bushenyi District",
                "state_code": "402"
            },
            {
                "id": 431,
                "name": "Busia District",
                "state_code": "202"
            },
            {
                "id": 365,
                "name": "Butaleja District",
                "state_code": "221"
            },
            {
                "id": 384,
                "name": "Butambala District",
                "state_code": "119"
            },
            {
                "id": 388,
                "name": "Butebo District",
                "state_code": "233"
            },
            {
                "id": 414,
                "name": "Buvuma District",
                "state_code": "120"
            },
            {
                "id": 380,
                "name": "Buyende District",
                "state_code": "226"
            },
            {
                "id": 396,
                "name": "Central Region",
                "state_code": "C"
            },
            {
                "id": 341,
                "name": "Dokolo District",
                "state_code": "317"
            },
            {
                "id": 372,
                "name": "Eastern Region",
                "state_code": "E"
            },
            {
                "id": 366,
                "name": "Gomba District",
                "state_code": "121"
            },
            {
                "id": 413,
                "name": "Gulu District",
                "state_code": "304"
            },
            {
                "id": 339,
                "name": "Ibanda District",
                "state_code": "417"
            },
            {
                "id": 340,
                "name": "Iganga District",
                "state_code": "203"
            },
            {
                "id": 383,
                "name": "Isingiro District",
                "state_code": "418"
            },
            {
                "id": 367,
                "name": "Jinja District",
                "state_code": "204"
            },
            {
                "id": 434,
                "name": "Kaabong District",
                "state_code": "318"
            },
            {
                "id": 426,
                "name": "Kabale District",
                "state_code": "404"
            },
            {
                "id": 326,
                "name": "Kabarole District",
                "state_code": "405"
            },
            {
                "id": 336,
                "name": "Kaberamaido District",
                "state_code": "213"
            },
            {
                "id": 403,
                "name": "Kagadi District",
                "state_code": "427"
            },
            {
                "id": 399,
                "name": "Kakumiro District",
                "state_code": "428"
            },
            {
                "id": 405,
                "name": "Kalangala District",
                "state_code": "101"
            },
            {
                "id": 398,
                "name": "Kaliro District",
                "state_code": "222"
            },
            {
                "id": 394,
                "name": "Kalungu District",
                "state_code": "122"
            },
            {
                "id": 382,
                "name": "Kampala District",
                "state_code": "102"
            },
            {
                "id": 334,
                "name": "Kamuli District",
                "state_code": "205"
            },
            {
                "id": 360,
                "name": "Kamwenge District",
                "state_code": "413"
            },
            {
                "id": 373,
                "name": "Kanungu District",
                "state_code": "414"
            },
            {
                "id": 432,
                "name": "Kapchorwa District",
                "state_code": "206"
            },
            {
                "id": 440,
                "name": "Kasese District",
                "state_code": "406"
            },
            {
                "id": 420,
                "name": "Katakwi District",
                "state_code": "207"
            },
            {
                "id": 368,
                "name": "Kayunga District",
                "state_code": "112"
            },
            {
                "id": 436,
                "name": "Kibaale District",
                "state_code": "407"
            },
            {
                "id": 347,
                "name": "Kiboga District",
                "state_code": "103"
            },
            {
                "id": 338,
                "name": "Kibuku District",
                "state_code": "227"
            },
            {
                "id": 355,
                "name": "Kiruhura District",
                "state_code": "419"
            },
            {
                "id": 346,
                "name": "Kiryandongo District",
                "state_code": "421"
            },
            {
                "id": 409,
                "name": "Kisoro District",
                "state_code": "408"
            },
            {
                "id": 348,
                "name": "Kitgum District",
                "state_code": "305"
            },
            {
                "id": 345,
                "name": "Koboko District",
                "state_code": "319"
            },
            {
                "id": 401,
                "name": "Kole District",
                "state_code": "325"
            },
            {
                "id": 443,
                "name": "Kotido District",
                "state_code": "306"
            },
            {
                "id": 425,
                "name": "Kumi District",
                "state_code": "208"
            },
            {
                "id": 369,
                "name": "Kween District",
                "state_code": "228"
            },
            {
                "id": 325,
                "name": "Kyankwanzi District",
                "state_code": "123"
            },
            {
                "id": 437,
                "name": "Kyegegwa District",
                "state_code": "422"
            },
            {
                "id": 402,
                "name": "Kyenjojo District",
                "state_code": "415"
            },
            {
                "id": 448,
                "name": "Kyotera District",
                "state_code": "125"
            },
            {
                "id": 411,
                "name": "Lamwo District",
                "state_code": "326"
            },
            {
                "id": 342,
                "name": "Lira District",
                "state_code": "307"
            },
            {
                "id": 445,
                "name": "Luuka District",
                "state_code": "229"
            },
            {
                "id": 433,
                "name": "Luwero District",
                "state_code": "104"
            },
            {
                "id": 417,
                "name": "Lwengo District",
                "state_code": "124"
            },
            {
                "id": 376,
                "name": "Lyantonde District",
                "state_code": "114"
            },
            {
                "id": 438,
                "name": "Manafwa District",
                "state_code": "223"
            },
            {
                "id": 421,
                "name": "Maracha District",
                "state_code": "320"
            },
            {
                "id": 356,
                "name": "Masaka District",
                "state_code": "105"
            },
            {
                "id": 354,
                "name": "Masindi District",
                "state_code": "409"
            },
            {
                "id": 418,
                "name": "Mayuge District",
                "state_code": "214"
            },
            {
                "id": 350,
                "name": "Mbale District",
                "state_code": "209"
            },
            {
                "id": 415,
                "name": "Mbarara District",
                "state_code": "410"
            },
            {
                "id": 435,
                "name": "Mitooma District",
                "state_code": "423"
            },
            {
                "id": 364,
                "name": "Mityana District",
                "state_code": "115"
            },
            {
                "id": 395,
                "name": "Moroto District",
                "state_code": "308"
            },
            {
                "id": 363,
                "name": "Moyo District",
                "state_code": "309"
            },
            {
                "id": 327,
                "name": "Mpigi District",
                "state_code": "106"
            },
            {
                "id": 371,
                "name": "Mubende District",
                "state_code": "107"
            },
            {
                "id": 410,
                "name": "Mukono District",
                "state_code": "108"
            },
            {
                "id": 393,
                "name": "Nakapiripirit District",
                "state_code": "311"
            },
            {
                "id": 423,
                "name": "Nakaseke District",
                "state_code": "116"
            },
            {
                "id": 406,
                "name": "Nakasongola District",
                "state_code": "109"
            },
            {
                "id": 351,
                "name": "Namayingo District",
                "state_code": "230"
            },
            {
                "id": 400,
                "name": "Namisindwa District",
                "state_code": "234"
            },
            {
                "id": 337,
                "name": "Namutumba District",
                "state_code": "224"
            },
            {
                "id": 430,
                "name": "Napak District",
                "state_code": "327"
            },
            {
                "id": 446,
                "name": "Nebbi District",
                "state_code": "310"
            },
            {
                "id": 424,
                "name": "Ngora District",
                "state_code": "231"
            },
            {
                "id": 332,
                "name": "Northern Region",
                "state_code": "N"
            },
            {
                "id": 422,
                "name": "Ntoroko District",
                "state_code": "424"
            },
            {
                "id": 404,
                "name": "Ntungamo District",
                "state_code": "411"
            },
            {
                "id": 378,
                "name": "Nwoya District",
                "state_code": "328"
            },
            {
                "id": 374,
                "name": "Omoro District",
                "state_code": "331"
            },
            {
                "id": 390,
                "name": "Otuke District",
                "state_code": "329"
            },
            {
                "id": 397,
                "name": "Oyam District",
                "state_code": "321"
            },
            {
                "id": 408,
                "name": "Pader District",
                "state_code": "312"
            },
            {
                "id": 357,
                "name": "Pakwach District",
                "state_code": "332"
            },
            {
                "id": 412,
                "name": "Pallisa District",
                "state_code": "210"
            },
            {
                "id": 439,
                "name": "Rakai District",
                "state_code": "110"
            },
            {
                "id": 358,
                "name": "Rubanda District",
                "state_code": "429"
            },
            {
                "id": 442,
                "name": "Rubirizi District",
                "state_code": "425"
            },
            {
                "id": 331,
                "name": "Rukiga District",
                "state_code": "431"
            },
            {
                "id": 324,
                "name": "Rukungiri District",
                "state_code": "412"
            },
            {
                "id": 427,
                "name": "Sembabule District",
                "state_code": "111"
            },
            {
                "id": 333,
                "name": "Serere District",
                "state_code": "232"
            },
            {
                "id": 407,
                "name": "Sheema District",
                "state_code": "426"
            },
            {
                "id": 429,
                "name": "Sironko District",
                "state_code": "215"
            },
            {
                "id": 444,
                "name": "Soroti District",
                "state_code": "211"
            },
            {
                "id": 359,
                "name": "Tororo District",
                "state_code": "212"
            },
            {
                "id": 362,
                "name": "Wakiso District",
                "state_code": "113"
            },
            {
                "id": 370,
                "name": "Western Region",
                "state_code": "W"
            },
            {
                "id": 330,
                "name": "Yumbe District",
                "state_code": "313"
            },
            {
                "id": 379,
                "name": "Zombo District",
                "state_code": "330"
            }
        ]
    },
    {
        "name": "Ukraine",
        "iso3": "UKR",
        "iso2": "UA",
        "phone_code": "380",
        "capital": "Kiev",
        "currency": "UAH",
        "currency_symbol": "₴",
        "tld": ".ua",
        "native": "Україна",
        "region": "Europe",
        "subregion": "Eastern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Kiev",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            },
            {
                "zoneName": "Europe/Simferopol",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "MSK",
                "tzName": "Moscow Time"
            },
            {
                "zoneName": "Europe/Uzhgorod",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            },
            {
                "zoneName": "Europe/Zaporozhye",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "EET",
                "tzName": "Eastern European Time"
            }
        ],
        "translations": {
            "br": "Ucrânia",
            "pt": "Ucrânia",
            "nl": "Oekraïne",
            "hr": "Ukrajina",
            "fa": "وکراین",
            "de": "Ukraine",
            "es": "Ucrania",
            "fr": "Ukraine",
            "ja": "ウクライナ",
            "it": "Ucraina"
        },
        "latitude": "49.00000000",
        "longitude": "32.00000000",
        "emoji": "🇺🇦",
        "emojiU": "U+1F1FA U+1F1E6",
        "states": [
            {
                "id": 4689,
                "name": "Autonomous Republic of Crimea",
                "state_code": "43"
            },
            {
                "id": 4680,
                "name": "Cherkasy Oblast",
                "state_code": "71"
            },
            {
                "id": 4692,
                "name": "Chernihiv Oblast",
                "state_code": "74"
            },
            {
                "id": 4678,
                "name": "Chernivtsi Oblast",
                "state_code": "77"
            },
            {
                "id": 4675,
                "name": "Dnipropetrovsk Oblast",
                "state_code": "12"
            },
            {
                "id": 4691,
                "name": "Donetsk Oblast",
                "state_code": "14"
            },
            {
                "id": 4682,
                "name": "Ivano-Frankivsk Oblast",
                "state_code": "26"
            },
            {
                "id": 4686,
                "name": "Kharkiv Oblast",
                "state_code": "63"
            },
            {
                "id": 4684,
                "name": "Kherson Oblast",
                "state_code": "65"
            },
            {
                "id": 4681,
                "name": "Khmelnytsky Oblast",
                "state_code": "68"
            },
            {
                "id": 4676,
                "name": "Kiev",
                "state_code": "30"
            },
            {
                "id": 4677,
                "name": "Kirovohrad Oblast",
                "state_code": "35"
            },
            {
                "id": 4671,
                "name": "Kyiv Oblast",
                "state_code": "32"
            },
            {
                "id": 4673,
                "name": "Luhansk Oblast",
                "state_code": "09"
            },
            {
                "id": 4672,
                "name": "Lviv Oblast",
                "state_code": "46"
            },
            {
                "id": 4679,
                "name": "Mykolaiv Oblast",
                "state_code": "48"
            },
            {
                "id": 4688,
                "name": "Odessa Oblast",
                "state_code": "51"
            },
            {
                "id": 4683,
                "name": "Rivne Oblast",
                "state_code": "56"
            },
            {
                "id": 4685,
                "name": "Sumy Oblast",
                "state_code": "59"
            },
            {
                "id": 4674,
                "name": "Ternopil Oblast",
                "state_code": "61"
            },
            {
                "id": 4669,
                "name": "Vinnytsia Oblast",
                "state_code": "05"
            },
            {
                "id": 4690,
                "name": "Volyn Oblast",
                "state_code": "07"
            },
            {
                "id": 4670,
                "name": "Zakarpattia Oblast",
                "state_code": "21"
            },
            {
                "id": 4687,
                "name": "Zaporizhzhya Oblast",
                "state_code": "23"
            },
            {
                "id": 4668,
                "name": "Zhytomyr Oblast",
                "state_code": "18"
            }
        ]
    },
    {
        "name": "United Arab Emirates",
        "iso3": "ARE",
        "iso2": "AE",
        "phone_code": "971",
        "capital": "Abu Dhabi",
        "currency": "AED",
        "currency_symbol": "إ.د",
        "tld": ".ae",
        "native": "دولة الإمارات العربية المتحدة",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia/Dubai",
                "gmtOffset": 14400,
                "gmtOffsetName": "UTC+04:00",
                "abbreviation": "GST",
                "tzName": "Gulf Standard Time"
            }
        ],
        "translations": {
            "br": "Emirados árabes Unidos",
            "pt": "Emirados árabes Unidos",
            "nl": "Verenigde Arabische Emiraten",
            "hr": "Ujedinjeni Arapski Emirati",
            "fa": "امارات متحده عربی",
            "de": "Vereinigte Arabische Emirate",
            "es": "Emiratos Árabes Unidos",
            "fr": "Émirats arabes unis",
            "ja": "アラブ首長国連邦",
            "it": "Emirati Arabi Uniti"
        },
        "latitude": "24.00000000",
        "longitude": "54.00000000",
        "emoji": "🇦🇪",
        "emojiU": "U+1F1E6 U+1F1EA",
        "states": [
            {
                "id": 3396,
                "name": "Abu Dhabi Emirate",
                "state_code": "AZ"
            },
            {
                "id": 3395,
                "name": "Ajman Emirate",
                "state_code": "AJ"
            },
            {
                "id": 3391,
                "name": "Dubai",
                "state_code": "DU"
            },
            {
                "id": 3393,
                "name": "Fujairah",
                "state_code": "FU"
            },
            {
                "id": 3394,
                "name": "Ras al-Khaimah",
                "state_code": "RK"
            },
            {
                "id": 3390,
                "name": "Sharjah Emirate",
                "state_code": "SH"
            },
            {
                "id": 3392,
                "name": "Umm al-Quwain",
                "state_code": "UQ"
            }
        ]
    },
    {
        "name": "United Kingdom",
        "iso3": "GBR",
        "iso2": "GB",
        "phone_code": "44",
        "capital": "London",
        "currency": "GBP",
        "currency_symbol": "£",
        "tld": ".uk",
        "native": "United Kingdom",
        "region": "Europe",
        "subregion": "Northern Europe",
        "timezones": [
            {
                "zoneName": "Europe/London",
                "gmtOffset": 0,
                "gmtOffsetName": "UTC±00",
                "abbreviation": "GMT",
                "tzName": "Greenwich Mean Time"
            }
        ],
        "translations": {
            "br": "Reino Unido",
            "pt": "Reino Unido",
            "nl": "Verenigd Koninkrijk",
            "hr": "Ujedinjeno Kraljevstvo",
            "fa": "بریتانیای کبیر و ایرلند شمالی",
            "de": "Vereinigtes Königreich",
            "es": "Reino Unido",
            "fr": "Royaume-Uni",
            "ja": "イギリス",
            "it": "Regno Unito"
        },
        "latitude": "54.00000000",
        "longitude": "-2.00000000",
        "emoji": "🇬🇧",
        "emojiU": "U+1F1EC U+1F1E7",
        "states": [
            {
                "id": 2463,
                "name": "Aberdeen",
                "state_code": "ABE"
            },
            {
                "id": 2401,
                "name": "Aberdeenshire",
                "state_code": "ABD"
            },
            {
                "id": 2387,
                "name": "Angus",
                "state_code": "ANS"
            },
            {
                "id": 2533,
                "name": "Antrim",
                "state_code": "ANT"
            },
            {
                "id": 2412,
                "name": "Antrim and Newtownabbey",
                "state_code": "ANN"
            },
            {
                "id": 2498,
                "name": "Ards",
                "state_code": "ARD"
            },
            {
                "id": 2523,
                "name": "Ards and North Down",
                "state_code": "AND"
            },
            {
                "id": 2392,
                "name": "Argyll and Bute",
                "state_code": "AGB"
            },
            {
                "id": 2331,
                "name": "Armagh City and District Council",
                "state_code": "ARM"
            },
            {
                "id": 2324,
                "name": "Armagh, Banbridge and Craigavon",
                "state_code": "ABC"
            },
            {
                "id": 2378,
                "name": "Ascension Island",
                "state_code": "SH-AC"
            },
            {
                "id": 2363,
                "name": "Ballymena Borough",
                "state_code": "BLA"
            },
            {
                "id": 2361,
                "name": "Ballymoney",
                "state_code": "BLY"
            },
            {
                "id": 2315,
                "name": "Banbridge",
                "state_code": "BNB"
            },
            {
                "id": 2499,
                "name": "Barnsley",
                "state_code": "BNS"
            },
            {
                "id": 2339,
                "name": "Bath and North East Somerset",
                "state_code": "BAS"
            },
            {
                "id": 2507,
                "name": "Bedford",
                "state_code": "BDF"
            },
            {
                "id": 2311,
                "name": "Belfast district",
                "state_code": "BFS"
            },
            {
                "id": 2425,
                "name": "Birmingham",
                "state_code": "BIR"
            },
            {
                "id": 2329,
                "name": "Blackburn with Darwen",
                "state_code": "BBD"
            },
            {
                "id": 2451,
                "name": "Blackpool",
                "state_code": "BPL"
            },
            {
                "id": 2530,
                "name": "Blaenau Gwent County Borough",
                "state_code": "BGW"
            },
            {
                "id": 2504,
                "name": "Bolton",
                "state_code": "BOL"
            },
            {
                "id": 2342,
                "name": "Bournemouth",
                "state_code": "BMH"
            },
            {
                "id": 2470,
                "name": "Bracknell Forest",
                "state_code": "BRC"
            },
            {
                "id": 2529,
                "name": "Bradford",
                "state_code": "BRD"
            },
            {
                "id": 2452,
                "name": "Bridgend County Borough",
                "state_code": "BGE"
            },
            {
                "id": 2395,
                "name": "Brighton and Hove",
                "state_code": "BNH"
            },
            {
                "id": 2405,
                "name": "Buckinghamshire",
                "state_code": "BKM"
            },
            {
                "id": 2459,
                "name": "Bury",
                "state_code": "BUR"
            },
            {
                "id": 2298,
                "name": "Caerphilly County Borough",
                "state_code": "CAY"
            },
            {
                "id": 2517,
                "name": "Calderdale",
                "state_code": "CLD"
            },
            {
                "id": 2423,
                "name": "Cambridgeshire",
                "state_code": "CAM"
            },
            {
                "id": 2484,
                "name": "Carmarthenshire",
                "state_code": "CMN"
            },
            {
                "id": 2439,
                "name": "Carrickfergus Borough Council",
                "state_code": "CKF"
            },
            {
                "id": 2525,
                "name": "Castlereagh",
                "state_code": "CSR"
            },
            {
                "id": 2316,
                "name": "Causeway Coast and Glens",
                "state_code": "CCG"
            },
            {
                "id": 2303,
                "name": "Central Bedfordshire",
                "state_code": "CBF"
            },
            {
                "id": 2509,
                "name": "Ceredigion",
                "state_code": "CGN"
            },
            {
                "id": 2444,
                "name": "Cheshire East",
                "state_code": "CHE"
            },
            {
                "id": 2442,
                "name": "Cheshire West and Chester",
                "state_code": "CHW"
            },
            {
                "id": 2528,
                "name": "City and County of Cardiff",
                "state_code": "CRF"
            },
            {
                "id": 2433,
                "name": "City and County of Swansea",
                "state_code": "SWA"
            },
            {
                "id": 2413,
                "name": "City of Bristol",
                "state_code": "BST"
            },
            {
                "id": 2485,
                "name": "City of Derby",
                "state_code": "DER"
            },
            {
                "id": 2475,
                "name": "City of Kingston upon Hull",
                "state_code": "KHL"
            },
            {
                "id": 2318,
                "name": "City of Leicester",
                "state_code": "LCE"
            },
            {
                "id": 2424,
                "name": "City of London",
                "state_code": "LND"
            },
            {
                "id": 2359,
                "name": "City of Nottingham",
                "state_code": "NGM"
            },
            {
                "id": 2297,
                "name": "City of Peterborough",
                "state_code": "PTE"
            },
            {
                "id": 2514,
                "name": "City of Plymouth",
                "state_code": "PLY"
            },
            {
                "id": 2305,
                "name": "City of Portsmouth",
                "state_code": "POR"
            },
            {
                "id": 2294,
                "name": "City of Southampton",
                "state_code": "STH"
            },
            {
                "id": 2506,
                "name": "City of Stoke-on-Trent",
                "state_code": "STE"
            },
            {
                "id": 2372,
                "name": "City of Sunderland",
                "state_code": "SND"
            },
            {
                "id": 2357,
                "name": "City of Westminster",
                "state_code": "WSM"
            },
            {
                "id": 2489,
                "name": "City of Wolverhampton",
                "state_code": "WLV"
            },
            {
                "id": 2426,
                "name": "City of York",
                "state_code": "YOR"
            },
            {
                "id": 2450,
                "name": "Clackmannanshire",
                "state_code": "CLK"
            },
            {
                "id": 2461,
                "name": "Coleraine Borough Council",
                "state_code": "CLR"
            },
            {
                "id": 2352,
                "name": "Conwy County Borough",
                "state_code": "CWY"
            },
            {
                "id": 2445,
                "name": "Cookstown District Council",
                "state_code": "CKT"
            },
            {
                "id": 2312,
                "name": "Cornwall",
                "state_code": "CON"
            },
            {
                "id": 2406,
                "name": "County Durham",
                "state_code": "DUR"
            },
            {
                "id": 2438,
                "name": "Coventry",
                "state_code": "COV"
            },
            {
                "id": 2449,
                "name": "Craigavon Borough Council",
                "state_code": "CGV"
            },
            {
                "id": 2334,
                "name": "Cumbria",
                "state_code": "CMA"
            },
            {
                "id": 2389,
                "name": "Darlington",
                "state_code": "DAL"
            },
            {
                "id": 2497,
                "name": "Denbighshire",
                "state_code": "DEN"
            },
            {
                "id": 2403,
                "name": "Derbyshire",
                "state_code": "DBY"
            },
            {
                "id": 2446,
                "name": "Derry City and Strabane",
                "state_code": "DRS"
            },
            {
                "id": 2417,
                "name": "Derry City Council",
                "state_code": "DRY"
            },
            {
                "id": 2491,
                "name": "Devon",
                "state_code": "DEV"
            },
            {
                "id": 2364,
                "name": "Doncaster",
                "state_code": "DNC"
            },
            {
                "id": 2345,
                "name": "Dorset",
                "state_code": "DOR"
            },
            {
                "id": 2304,
                "name": "Down District Council",
                "state_code": "DOW"
            },
            {
                "id": 2457,
                "name": "Dudley",
                "state_code": "DUD"
            },
            {
                "id": 2415,
                "name": "Dumfries and Galloway",
                "state_code": "DGY"
            },
            {
                "id": 2511,
                "name": "Dundee",
                "state_code": "DND"
            },
            {
                "id": 2508,
                "name": "Dungannon and South Tyrone Borough Council",
                "state_code": "DGN"
            },
            {
                "id": 2374,
                "name": "East Ayrshire",
                "state_code": "EAY"
            },
            {
                "id": 2454,
                "name": "East Dunbartonshire",
                "state_code": "EDU"
            },
            {
                "id": 2462,
                "name": "East Lothian",
                "state_code": "ELN"
            },
            {
                "id": 2333,
                "name": "East Renfrewshire",
                "state_code": "ERW"
            },
            {
                "id": 2370,
                "name": "East Riding of Yorkshire",
                "state_code": "ERY"
            },
            {
                "id": 2414,
                "name": "East Sussex",
                "state_code": "ESX"
            },
            {
                "id": 2428,
                "name": "Edinburgh",
                "state_code": "EDH"
            },
            {
                "id": 2336,
                "name": "England",
                "state_code": "ENG"
            },
            {
                "id": 2410,
                "name": "Essex",
                "state_code": "ESS"
            },
            {
                "id": 2344,
                "name": "Falkirk",
                "state_code": "FAL"
            },
            {
                "id": 2366,
                "name": "Fermanagh and Omagh",
                "state_code": "FMO"
            },
            {
                "id": 2531,
                "name": "Fermanagh District Council",
                "state_code": "FER"
            },
            {
                "id": 2479,
                "name": "Fife",
                "state_code": "FIF"
            },
            {
                "id": 2437,
                "name": "Flintshire",
                "state_code": "FLN"
            },
            {
                "id": 2431,
                "name": "Gateshead",
                "state_code": "GAT"
            },
            {
                "id": 2404,
                "name": "Glasgow",
                "state_code": "GLG"
            },
            {
                "id": 2373,
                "name": "Gloucestershire",
                "state_code": "GLS"
            },
            {
                "id": 2379,
                "name": "Gwynedd",
                "state_code": "GWN"
            },
            {
                "id": 2466,
                "name": "Halton",
                "state_code": "HAL"
            },
            {
                "id": 2435,
                "name": "Hampshire",
                "state_code": "HAM"
            },
            {
                "id": 2309,
                "name": "Hartlepool",
                "state_code": "HPL"
            },
            {
                "id": 2500,
                "name": "Herefordshire",
                "state_code": "HEF"
            },
            {
                "id": 2369,
                "name": "Hertfordshire",
                "state_code": "HRT"
            },
            {
                "id": 2383,
                "name": "Highland",
                "state_code": "HLD"
            },
            {
                "id": 2388,
                "name": "Inverclyde",
                "state_code": "IVC"
            },
            {
                "id": 2289,
                "name": "Isle of Wight",
                "state_code": "IOW"
            },
            {
                "id": 2343,
                "name": "Isles of Scilly",
                "state_code": "IOS"
            },
            {
                "id": 2464,
                "name": "Kent",
                "state_code": "KEN"
            },
            {
                "id": 2371,
                "name": "Kirklees",
                "state_code": "KIR"
            },
            {
                "id": 2330,
                "name": "Knowsley",
                "state_code": "KWL"
            },
            {
                "id": 2495,
                "name": "Lancashire",
                "state_code": "LAN"
            },
            {
                "id": 2515,
                "name": "Larne Borough Council",
                "state_code": "LRN"
            },
            {
                "id": 2503,
                "name": "Leeds",
                "state_code": "LDS"
            },
            {
                "id": 2516,
                "name": "Leicestershire",
                "state_code": "LEC"
            },
            {
                "id": 2382,
                "name": "Limavady Borough Council",
                "state_code": "LMV"
            },
            {
                "id": 2355,
                "name": "Lincolnshire",
                "state_code": "LIN"
            },
            {
                "id": 2460,
                "name": "Lisburn and Castlereagh",
                "state_code": "LBC"
            },
            {
                "id": 2494,
                "name": "Lisburn City Council",
                "state_code": "LSB"
            },
            {
                "id": 2340,
                "name": "Liverpool",
                "state_code": "LIV"
            },
            {
                "id": 2356,
                "name": "London Borough of Barking and Dagenham",
                "state_code": "BDG"
            },
            {
                "id": 2520,
                "name": "London Borough of Barnet",
                "state_code": "BNE"
            },
            {
                "id": 2307,
                "name": "London Borough of Bexley",
                "state_code": "BEX"
            },
            {
                "id": 2291,
                "name": "London Borough of Brent",
                "state_code": "BEN"
            },
            {
                "id": 2490,
                "name": "London Borough of Bromley",
                "state_code": "BRY"
            },
            {
                "id": 2349,
                "name": "London Borough of Camden",
                "state_code": "CMD"
            },
            {
                "id": 2512,
                "name": "London Borough of Croydon",
                "state_code": "CRY"
            },
            {
                "id": 2532,
                "name": "London Borough of Ealing",
                "state_code": "EAL"
            },
            {
                "id": 2476,
                "name": "London Borough of Enfield",
                "state_code": "ENF"
            },
            {
                "id": 2411,
                "name": "London Borough of Hackney",
                "state_code": "HCK"
            },
            {
                "id": 2448,
                "name": "London Borough of Hammersmith and Fulham",
                "state_code": "HMF"
            },
            {
                "id": 2306,
                "name": "London Borough of Haringey",
                "state_code": "HRY"
            },
            {
                "id": 2385,
                "name": "London Borough of Harrow",
                "state_code": "HRW"
            },
            {
                "id": 2347,
                "name": "London Borough of Havering",
                "state_code": "HAV"
            },
            {
                "id": 2376,
                "name": "London Borough of Hillingdon",
                "state_code": "HIL"
            },
            {
                "id": 2380,
                "name": "London Borough of Hounslow",
                "state_code": "HNS"
            },
            {
                "id": 2319,
                "name": "London Borough of Islington",
                "state_code": "ISL"
            },
            {
                "id": 2396,
                "name": "London Borough of Lambeth",
                "state_code": "LBH"
            },
            {
                "id": 2358,
                "name": "London Borough of Lewisham",
                "state_code": "LEW"
            },
            {
                "id": 2483,
                "name": "London Borough of Merton",
                "state_code": "MRT"
            },
            {
                "id": 2418,
                "name": "London Borough of Newham",
                "state_code": "NWM"
            },
            {
                "id": 2397,
                "name": "London Borough of Redbridge",
                "state_code": "RDB"
            },
            {
                "id": 2501,
                "name": "London Borough of Richmond upon Thames",
                "state_code": "RIC"
            },
            {
                "id": 2432,
                "name": "London Borough of Southwark",
                "state_code": "SWK"
            },
            {
                "id": 2313,
                "name": "London Borough of Sutton",
                "state_code": "STN"
            },
            {
                "id": 2390,
                "name": "London Borough of Tower Hamlets",
                "state_code": "TWH"
            },
            {
                "id": 2326,
                "name": "London Borough of Waltham Forest",
                "state_code": "WFT"
            },
            {
                "id": 2434,
                "name": "London Borough of Wandsworth",
                "state_code": "WND"
            },
            {
                "id": 2322,
                "name": "Magherafelt District Council",
                "state_code": "MFT"
            },
            {
                "id": 2398,
                "name": "Manchester",
                "state_code": "MAN"
            },
            {
                "id": 2381,
                "name": "Medway",
                "state_code": "MDW"
            },
            {
                "id": 2328,
                "name": "Merthyr Tydfil County Borough",
                "state_code": "MTY"
            },
            {
                "id": 2320,
                "name": "Metropolitan Borough of Wigan",
                "state_code": "WGN"
            },
            {
                "id": 2429,
                "name": "Mid and East Antrim",
                "state_code": "MEA"
            },
            {
                "id": 2399,
                "name": "Mid Ulster",
                "state_code": "MUL"
            },
            {
                "id": 2332,
                "name": "Middlesbrough",
                "state_code": "MDB"
            },
            {
                "id": 2519,
                "name": "Midlothian",
                "state_code": "MLN"
            },
            {
                "id": 2416,
                "name": "Milton Keynes",
                "state_code": "MIK"
            },
            {
                "id": 2402,
                "name": "Monmouthshire",
                "state_code": "MON"
            },
            {
                "id": 2360,
                "name": "Moray",
                "state_code": "MRY"
            },
            {
                "id": 2348,
                "name": "Moyle District Council",
                "state_code": "MYL"
            },
            {
                "id": 2351,
                "name": "Neath Port Talbot County Borough",
                "state_code": "NTL"
            },
            {
                "id": 2458,
                "name": "Newcastle upon Tyne",
                "state_code": "NET"
            },
            {
                "id": 2524,
                "name": "Newport",
                "state_code": "NWP"
            },
            {
                "id": 2350,
                "name": "Newry and Mourne District Council",
                "state_code": "NYM"
            },
            {
                "id": 2534,
                "name": "Newry, Mourne and Down",
                "state_code": "NMD"
            },
            {
                "id": 2317,
                "name": "Newtownabbey Borough Council",
                "state_code": "NTA"
            },
            {
                "id": 2473,
                "name": "Norfolk",
                "state_code": "NFK"
            },
            {
                "id": 2535,
                "name": "North Ayrshire",
                "state_code": "NAY"
            },
            {
                "id": 2513,
                "name": "North Down Borough Council",
                "state_code": "NDN"
            },
            {
                "id": 2384,
                "name": "North East Lincolnshire",
                "state_code": "NEL"
            },
            {
                "id": 2487,
                "name": "North Lanarkshire",
                "state_code": "NLK"
            },
            {
                "id": 2453,
                "name": "North Lincolnshire",
                "state_code": "NLN"
            },
            {
                "id": 2430,
                "name": "North Somerset",
                "state_code": "NSM"
            },
            {
                "id": 2521,
                "name": "North Tyneside",
                "state_code": "NTY"
            },
            {
                "id": 2522,
                "name": "North Yorkshire",
                "state_code": "NYK"
            },
            {
                "id": 2480,
                "name": "Northamptonshire",
                "state_code": "NTH"
            },
            {
                "id": 2337,
                "name": "Northern Ireland",
                "state_code": "NIR"
            },
            {
                "id": 2365,
                "name": "Northumberland",
                "state_code": "NBL"
            },
            {
                "id": 2456,
                "name": "Nottinghamshire",
                "state_code": "NTT"
            },
            {
                "id": 2477,
                "name": "Oldham",
                "state_code": "OLD"
            },
            {
                "id": 2314,
                "name": "Omagh District Council",
                "state_code": "OMH"
            },
            {
                "id": 2474,
                "name": "Orkney Islands",
                "state_code": "ORK"
            },
            {
                "id": 2353,
                "name": "Outer Hebrides",
                "state_code": "ELS"
            },
            {
                "id": 2321,
                "name": "Oxfordshire",
                "state_code": "OXF"
            },
            {
                "id": 2486,
                "name": "Pembrokeshire",
                "state_code": "PEM"
            },
            {
                "id": 2325,
                "name": "Perth and Kinross",
                "state_code": "PKN"
            },
            {
                "id": 2302,
                "name": "Poole",
                "state_code": "POL"
            },
            {
                "id": 2441,
                "name": "Powys",
                "state_code": "POW"
            },
            {
                "id": 2455,
                "name": "Reading",
                "state_code": "RDG"
            },
            {
                "id": 2527,
                "name": "Redcar and Cleveland",
                "state_code": "RCC"
            },
            {
                "id": 2443,
                "name": "Renfrewshire",
                "state_code": "RFW"
            },
            {
                "id": 2301,
                "name": "Rhondda Cynon Taf",
                "state_code": "RCT"
            },
            {
                "id": 2327,
                "name": "Rochdale",
                "state_code": "RCH"
            },
            {
                "id": 2308,
                "name": "Rotherham",
                "state_code": "ROT"
            },
            {
                "id": 2492,
                "name": "Royal Borough of Greenwich",
                "state_code": "GRE"
            },
            {
                "id": 2368,
                "name": "Royal Borough of Kensington and Chelsea",
                "state_code": "KEC"
            },
            {
                "id": 2481,
                "name": "Royal Borough of Kingston upon Thames",
                "state_code": "KTT"
            },
            {
                "id": 2472,
                "name": "Rutland",
                "state_code": "RUT"
            },
            {
                "id": 2502,
                "name": "Saint Helena",
                "state_code": "SH-HL"
            },
            {
                "id": 2493,
                "name": "Salford",
                "state_code": "SLF"
            },
            {
                "id": 2341,
                "name": "Sandwell",
                "state_code": "SAW"
            },
            {
                "id": 2335,
                "name": "Scotland",
                "state_code": "SCT"
            },
            {
                "id": 2346,
                "name": "Scottish Borders",
                "state_code": "SCB"
            },
            {
                "id": 2518,
                "name": "Sefton",
                "state_code": "SFT"
            },
            {
                "id": 2295,
                "name": "Sheffield",
                "state_code": "SHF"
            },
            {
                "id": 2300,
                "name": "Shetland Islands",
                "state_code": "ZET"
            },
            {
                "id": 2407,
                "name": "Shropshire",
                "state_code": "SHR"
            },
            {
                "id": 2427,
                "name": "Slough",
                "state_code": "SLG"
            },
            {
                "id": 2469,
                "name": "Solihull",
                "state_code": "SOL"
            },
            {
                "id": 2386,
                "name": "Somerset",
                "state_code": "SOM"
            },
            {
                "id": 2377,
                "name": "South Ayrshire",
                "state_code": "SAY"
            },
            {
                "id": 2400,
                "name": "South Gloucestershire",
                "state_code": "SGC"
            },
            {
                "id": 2362,
                "name": "South Lanarkshire",
                "state_code": "SLK"
            },
            {
                "id": 2409,
                "name": "South Tyneside",
                "state_code": "STY"
            },
            {
                "id": 2323,
                "name": "Southend-on-Sea",
                "state_code": "SOS"
            },
            {
                "id": 2290,
                "name": "St Helens",
                "state_code": "SHN"
            },
            {
                "id": 2447,
                "name": "Staffordshire",
                "state_code": "STS"
            },
            {
                "id": 2488,
                "name": "Stirling",
                "state_code": "STG"
            },
            {
                "id": 2394,
                "name": "Stockport",
                "state_code": "SKP"
            },
            {
                "id": 2421,
                "name": "Stockton-on-Tees",
                "state_code": "STT"
            },
            {
                "id": 2393,
                "name": "Strabane District Council",
                "state_code": "STB"
            },
            {
                "id": 2467,
                "name": "Suffolk",
                "state_code": "SFK"
            },
            {
                "id": 2526,
                "name": "Surrey",
                "state_code": "SRY"
            },
            {
                "id": 2422,
                "name": "Swindon",
                "state_code": "SWD"
            },
            {
                "id": 2367,
                "name": "Tameside",
                "state_code": "TAM"
            },
            {
                "id": 2310,
                "name": "Telford and Wrekin",
                "state_code": "TFW"
            },
            {
                "id": 2468,
                "name": "Thurrock",
                "state_code": "THR"
            },
            {
                "id": 2478,
                "name": "Torbay",
                "state_code": "TOB"
            },
            {
                "id": 2496,
                "name": "Torfaen",
                "state_code": "TOF"
            },
            {
                "id": 2293,
                "name": "Trafford",
                "state_code": "TRF"
            },
            {
                "id": 2375,
                "name": "United Kingdom",
                "state_code": "UKM"
            },
            {
                "id": 2299,
                "name": "Vale of Glamorgan",
                "state_code": "VGL"
            },
            {
                "id": 2465,
                "name": "Wakefield",
                "state_code": "WKF"
            },
            {
                "id": 2338,
                "name": "Wales",
                "state_code": "WLS"
            },
            {
                "id": 2292,
                "name": "Walsall",
                "state_code": "WLL"
            },
            {
                "id": 2420,
                "name": "Warrington",
                "state_code": "WRT"
            },
            {
                "id": 2505,
                "name": "Warwickshire",
                "state_code": "WAR"
            },
            {
                "id": 2471,
                "name": "West Berkshire",
                "state_code": "WBK"
            },
            {
                "id": 2440,
                "name": "West Dunbartonshire",
                "state_code": "WDU"
            },
            {
                "id": 2354,
                "name": "West Lothian",
                "state_code": "WLN"
            },
            {
                "id": 2296,
                "name": "West Sussex",
                "state_code": "WSX"
            },
            {
                "id": 2391,
                "name": "Wiltshire",
                "state_code": "WIL"
            },
            {
                "id": 2482,
                "name": "Windsor and Maidenhead",
                "state_code": "WNM"
            },
            {
                "id": 2408,
                "name": "Wirral",
                "state_code": "WRL"
            },
            {
                "id": 2419,
                "name": "Wokingham",
                "state_code": "WOK"
            },
            {
                "id": 2510,
                "name": "Worcestershire",
                "state_code": "WOR"
            },
            {
                "id": 2436,
                "name": "Wrexham County Borough",
                "state_code": "WRX"
            }
        ]
    },
    {
        "name": "United States",
        "iso3": "USA",
        "iso2": "US",
        "phone_code": "1",
        "capital": "Washington",
        "currency": "USD",
        "currency_symbol": "$",
        "tld": ".us",
        "native": "United States",
        "region": "Americas",
        "subregion": "Northern America",
        "timezones": [
            {
                "zoneName": "America/Adak",
                "gmtOffset": -36000,
                "gmtOffsetName": "UTC-10:00",
                "abbreviation": "HST",
                "tzName": "Hawaii–Aleutian Standard Time"
            },
            {
                "zoneName": "America/Anchorage",
                "gmtOffset": -32400,
                "gmtOffsetName": "UTC-09:00",
                "abbreviation": "AKST",
                "tzName": "Alaska Standard Time"
            },
            {
                "zoneName": "America/Boise",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America"
            },
            {
                "zoneName": "America/Chicago",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America/Denver",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America"
            },
            {
                "zoneName": "America/Detroit",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America/Indiana/Indianapolis",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America/Indiana/Knox",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America/Indiana/Marengo",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America/Indiana/Petersburg",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America/Indiana/Tell_City",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America/Indiana/Vevay",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America/Indiana/Vincennes",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America/Indiana/Winamac",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America/Juneau",
                "gmtOffset": -32400,
                "gmtOffsetName": "UTC-09:00",
                "abbreviation": "AKST",
                "tzName": "Alaska Standard Time"
            },
            {
                "zoneName": "America/Kentucky/Louisville",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America/Kentucky/Monticello",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America/Los_Angeles",
                "gmtOffset": -28800,
                "gmtOffsetName": "UTC-08:00",
                "abbreviation": "PST",
                "tzName": "Pacific Standard Time (North America"
            },
            {
                "zoneName": "America/Menominee",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America/Metlakatla",
                "gmtOffset": -32400,
                "gmtOffsetName": "UTC-09:00",
                "abbreviation": "AKST",
                "tzName": "Alaska Standard Time"
            },
            {
                "zoneName": "America/New_York",
                "gmtOffset": -18000,
                "gmtOffsetName": "UTC-05:00",
                "abbreviation": "EST",
                "tzName": "Eastern Standard Time (North America"
            },
            {
                "zoneName": "America/Nome",
                "gmtOffset": -32400,
                "gmtOffsetName": "UTC-09:00",
                "abbreviation": "AKST",
                "tzName": "Alaska Standard Time"
            },
            {
                "zoneName": "America/North_Dakota/Beulah",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America/North_Dakota/Center",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America/North_Dakota/New_Salem",
                "gmtOffset": -21600,
                "gmtOffsetName": "UTC-06:00",
                "abbreviation": "CST",
                "tzName": "Central Standard Time (North America"
            },
            {
                "zoneName": "America/Phoenix",
                "gmtOffset": -25200,
                "gmtOffsetName": "UTC-07:00",
                "abbreviation": "MST",
                "tzName": "Mountain Standard Time (North America"
            },
            {
                "zoneName": "America/Sitka",
                "gmtOffset": -32400,
                "gmtOffsetName": "UTC-09:00",
                "abbreviation": "AKST",
                "tzName": "Alaska Standard Time"
            },
            {
                "zoneName": "America/Yakutat",
                "gmtOffset": -32400,
                "gmtOffsetName": "UTC-09:00",
                "abbreviation": "AKST",
                "tzName": "Alaska Standard Time"
            },
            {
                "zoneName": "Pacific/Honolulu",
                "gmtOffset": -36000,
                "gmtOffsetName": "UTC-10:00",
                "abbreviation": "HST",
                "tzName": "Hawaii–Aleutian Standard Time"
            }
        ],
        "translations": {
            "br": "Estados Unidos",
            "pt": "Estados Unidos",
            "nl": "Verenigde Staten",
            "hr": "Sjedinjene Američke Države",
            "fa": "ایالات متحده آمریکا",
            "de": "Vereinigte Staaten von Amerika",
            "es": "Estados Unidos",
            "fr": "États-Unis",
            "ja": "アメリカ合衆国",
            "it": "Stati Uniti D'America"
        },
        "latitude": "38.00000000",
        "longitude": "-97.00000000",
        "emoji": "🇺🇸",
        "emojiU": "U+1F1FA U+1F1F8",
        "states": [
            {
                "id": 1456,
                "name": "Alabama",
                "state_code": "AL"
            },
            {
                "id": 1400,
                "name": "Alaska",
                "state_code": "AK"
            },
            {
                "id": 1424,
                "name": "American Samoa",
                "state_code": "AS"
            },
            {
                "id": 1434,
                "name": "Arizona",
                "state_code": "AZ"
            },
            {
                "id": 1444,
                "name": "Arkansas",
                "state_code": "AR"
            },
            {
                "id": 1402,
                "name": "Baker Island",
                "state_code": "UM-81"
            },
            {
                "id": 1416,
                "name": "California",
                "state_code": "CA"
            },
            {
                "id": 1450,
                "name": "Colorado",
                "state_code": "CO"
            },
            {
                "id": 1435,
                "name": "Connecticut",
                "state_code": "CT"
            },
            {
                "id": 1399,
                "name": "Delaware",
                "state_code": "DE"
            },
            {
                "id": 1437,
                "name": "District of Columbia",
                "state_code": "DC"
            },
            {
                "id": 1436,
                "name": "Florida",
                "state_code": "FL"
            },
            {
                "id": 1455,
                "name": "Georgia",
                "state_code": "GA"
            },
            {
                "id": 1412,
                "name": "Guam",
                "state_code": "GU"
            },
            {
                "id": 1411,
                "name": "Hawaii",
                "state_code": "HI"
            },
            {
                "id": 1398,
                "name": "Howland Island",
                "state_code": "UM-84"
            },
            {
                "id": 1460,
                "name": "Idaho",
                "state_code": "ID"
            },
            {
                "id": 1425,
                "name": "Illinois",
                "state_code": "IL"
            },
            {
                "id": 1440,
                "name": "Indiana",
                "state_code": "IN"
            },
            {
                "id": 1459,
                "name": "Iowa",
                "state_code": "IA"
            },
            {
                "id": 1410,
                "name": "Jarvis Island",
                "state_code": "UM-86"
            },
            {
                "id": 1428,
                "name": "Johnston Atoll",
                "state_code": "UM-67"
            },
            {
                "id": 1406,
                "name": "Kansas",
                "state_code": "KS"
            },
            {
                "id": 1419,
                "name": "Kentucky",
                "state_code": "KY"
            },
            {
                "id": 1403,
                "name": "Kingman Reef",
                "state_code": "UM-89"
            },
            {
                "id": 1457,
                "name": "Louisiana",
                "state_code": "LA"
            },
            {
                "id": 1453,
                "name": "Maine",
                "state_code": "ME"
            },
            {
                "id": 1401,
                "name": "Maryland",
                "state_code": "MD"
            },
            {
                "id": 1433,
                "name": "Massachusetts",
                "state_code": "MA"
            },
            {
                "id": 1426,
                "name": "Michigan",
                "state_code": "MI"
            },
            {
                "id": 1438,
                "name": "Midway Atoll",
                "state_code": "UM-71"
            },
            {
                "id": 1420,
                "name": "Minnesota",
                "state_code": "MN"
            },
            {
                "id": 1430,
                "name": "Mississippi",
                "state_code": "MS"
            },
            {
                "id": 1451,
                "name": "Missouri",
                "state_code": "MO"
            },
            {
                "id": 1446,
                "name": "Montana",
                "state_code": "MT"
            },
            {
                "id": 1439,
                "name": "Navassa Island",
                "state_code": "UM-76"
            },
            {
                "id": 1408,
                "name": "Nebraska",
                "state_code": "NE"
            },
            {
                "id": 1458,
                "name": "Nevada",
                "state_code": "NV"
            },
            {
                "id": 1404,
                "name": "New Hampshire",
                "state_code": "NH"
            },
            {
                "id": 1417,
                "name": "New Jersey",
                "state_code": "NJ"
            },
            {
                "id": 1423,
                "name": "New Mexico",
                "state_code": "NM"
            },
            {
                "id": 1452,
                "name": "New York",
                "state_code": "NY"
            },
            {
                "id": 1447,
                "name": "North Carolina",
                "state_code": "NC"
            },
            {
                "id": 1418,
                "name": "North Dakota",
                "state_code": "ND"
            },
            {
                "id": 1431,
                "name": "Northern Mariana Islands",
                "state_code": "MP"
            },
            {
                "id": 4851,
                "name": "Ohio",
                "state_code": "OH"
            },
            {
                "id": 1421,
                "name": "Oklahoma",
                "state_code": "OK"
            },
            {
                "id": 1415,
                "name": "Oregon",
                "state_code": "OR"
            },
            {
                "id": 1448,
                "name": "Palmyra Atoll",
                "state_code": "UM-95"
            },
            {
                "id": 1422,
                "name": "Pennsylvania",
                "state_code": "PA"
            },
            {
                "id": 1449,
                "name": "Puerto Rico",
                "state_code": "PR"
            },
            {
                "id": 1461,
                "name": "Rhode Island",
                "state_code": "RI"
            },
            {
                "id": 1443,
                "name": "South Carolina",
                "state_code": "SC"
            },
            {
                "id": 1445,
                "name": "South Dakota",
                "state_code": "SD"
            },
            {
                "id": 1454,
                "name": "Tennessee",
                "state_code": "TN"
            },
            {
                "id": 1407,
                "name": "Texas",
                "state_code": "TX"
            },
            {
                "id": 1432,
                "name": "United States Minor Outlying Islands",
                "state_code": "UM"
            },
            {
                "id": 1413,
                "name": "United States Virgin Islands",
                "state_code": "VI"
            },
            {
                "id": 1414,
                "name": "Utah",
                "state_code": "UT"
            },
            {
                "id": 1409,
                "name": "Vermont",
                "state_code": "VT"
            },
            {
                "id": 1427,
                "name": "Virginia",
                "state_code": "VA"
            },
            {
                "id": 1405,
                "name": "Wake Island",
                "state_code": "UM-79"
            },
            {
                "id": 1462,
                "name": "Washington",
                "state_code": "WA"
            },
            {
                "id": 1429,
                "name": "West Virginia",
                "state_code": "WV"
            },
            {
                "id": 1441,
                "name": "Wisconsin",
                "state_code": "WI"
            },
            {
                "id": 1442,
                "name": "Wyoming",
                "state_code": "WY"
            }
        ]
    },
    {
        "name": "United States Minor Outlying Islands",
        "iso3": "UMI",
        "iso2": "UM",
        "phone_code": "1",
        "capital": "",
        "currency": "USD",
        "currency_symbol": "$",
        "tld": ".us",
        "native": "United States Minor Outlying Islands",
        "region": "Americas",
        "subregion": "Northern America",
        "timezones": [
            {
                "zoneName": "Pacific/Midway",
                "gmtOffset": -39600,
                "gmtOffsetName": "UTC-11:00",
                "abbreviation": "SST",
                "tzName": "Samoa Standard Time"
            },
            {
                "zoneName": "Pacific/Wake",
                "gmtOffset": 43200,
                "gmtOffsetName": "UTC+12:00",
                "abbreviation": "WAKT",
                "tzName": "Wake Island Time"
            }
        ],
        "translations": {
            "br": "Ilhas Menores Distantes dos Estados Unidos",
            "pt": "Ilhas Menores Distantes dos Estados Unidos",
            "nl": "Kleine afgelegen eilanden van de Verenigde Staten",
            "hr": "Mali udaljeni otoci SAD-a",
            "fa": "جزایر کوچک حاشیه‌ای ایالات متحده آمریکا",
            "de": "Kleinere Inselbesitzungen der Vereinigten Staaten",
            "es": "Islas Ultramarinas Menores de Estados Unidos",
            "fr": "Îles mineures éloignées des États-Unis",
            "ja": "合衆国領有小離島",
            "it": "Isole minori esterne degli Stati Uniti d'America"
        },
        "latitude": "0.00000000",
        "longitude": "0.00000000",
        "emoji": "🇺🇲",
        "emojiU": "U+1F1FA U+1F1F2",
        "states": []
    },
    {
        "name": "Uruguay",
        "iso3": "URY",
        "iso2": "UY",
        "phone_code": "598",
        "capital": "Montevideo",
        "currency": "UYU",
        "currency_symbol": "$",
        "tld": ".uy",
        "native": "Uruguay",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America/Montevideo",
                "gmtOffset": -10800,
                "gmtOffsetName": "UTC-03:00",
                "abbreviation": "UYT",
                "tzName": "Uruguay Standard Time"
            }
        ],
        "translations": {
            "br": "Uruguai",
            "pt": "Uruguai",
            "nl": "Uruguay",
            "hr": "Urugvaj",
            "fa": "اروگوئه",
            "de": "Uruguay",
            "es": "Uruguay",
            "fr": "Uruguay",
            "ja": "ウルグアイ",
            "it": "Uruguay"
        },
        "latitude": "-33.00000000",
        "longitude": "-56.00000000",
        "emoji": "🇺🇾",
        "emojiU": "U+1F1FA U+1F1FE",
        "states": [
            {
                "id": 3205,
                "name": "Artigas Department",
                "state_code": "AR"
            },
            {
                "id": 3213,
                "name": "Canelones Department",
                "state_code": "CA"
            },
            {
                "id": 3211,
                "name": "Cerro Largo Department",
                "state_code": "CL"
            },
            {
                "id": 3208,
                "name": "Colonia Department",
                "state_code": "CO"
            },
            {
                "id": 3209,
                "name": "Durazno Department",
                "state_code": "DU"
            },
            {
                "id": 3203,
                "name": "Flores Department",
                "state_code": "FS"
            },
            {
                "id": 3217,
                "name": "Florida Department",
                "state_code": "FD"
            },
            {
                "id": 3215,
                "name": "Lavalleja Department",
                "state_code": "LA"
            },
            {
                "id": 3206,
                "name": "Maldonado Department",
                "state_code": "MA"
            },
            {
                "id": 3218,
                "name": "Montevideo Department",
                "state_code": "MO"
            },
            {
                "id": 3212,
                "name": "Paysandú Department",
                "state_code": "PA"
            },
            {
                "id": 3210,
                "name": "Río Negro Department",
                "state_code": "RN"
            },
            {
                "id": 3207,
                "name": "Rivera Department",
                "state_code": "RV"
            },
            {
                "id": 3216,
                "name": "Rocha Department",
                "state_code": "RO"
            },
            {
                "id": 3220,
                "name": "Salto Department",
                "state_code": "SA"
            },
            {
                "id": 3204,
                "name": "San José Department",
                "state_code": "SJ"
            },
            {
                "id": 3219,
                "name": "Soriano Department",
                "state_code": "SO"
            },
            {
                "id": 3221,
                "name": "Tacuarembó Department",
                "state_code": "TA"
            },
            {
                "id": 3214,
                "name": "Treinta y Tres Department",
                "state_code": "TT"
            }
        ]
    },
    {
        "name": "Uzbekistan",
        "iso3": "UZB",
        "iso2": "UZ",
        "phone_code": "998",
        "capital": "Tashkent",
        "currency": "UZS",
        "currency_symbol": "лв",
        "tld": ".uz",
        "native": "O‘zbekiston",
        "region": "Asia",
        "subregion": "Central Asia",
        "timezones": [
            {
                "zoneName": "Asia/Samarkand",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "UZT",
                "tzName": "Uzbekistan Time"
            },
            {
                "zoneName": "Asia/Tashkent",
                "gmtOffset": 18000,
                "gmtOffsetName": "UTC+05:00",
                "abbreviation": "UZT",
                "tzName": "Uzbekistan Time"
            }
        ],
        "translations": {
            "br": "Uzbequistão",
            "pt": "Usbequistão",
            "nl": "Oezbekistan",
            "hr": "Uzbekistan",
            "fa": "ازبکستان",
            "de": "Usbekistan",
            "es": "Uzbekistán",
            "fr": "Ouzbékistan",
            "ja": "ウズベキスタン",
            "it": "Uzbekistan"
        },
        "latitude": "41.00000000",
        "longitude": "64.00000000",
        "emoji": "🇺🇿",
        "emojiU": "U+1F1FA U+1F1FF",
        "states": [
            {
                "id": 2540,
                "name": "Andijan Region",
                "state_code": "AN"
            },
            {
                "id": 2541,
                "name": "Bukhara Region",
                "state_code": "BU"
            },
            {
                "id": 2538,
                "name": "Fergana Region",
                "state_code": "FA"
            },
            {
                "id": 2545,
                "name": "Jizzakh Region",
                "state_code": "JI"
            },
            {
                "id": 2548,
                "name": "Karakalpakstan",
                "state_code": "QR"
            },
            {
                "id": 2537,
                "name": "Namangan Region",
                "state_code": "NG"
            },
            {
                "id": 2542,
                "name": "Navoiy Region",
                "state_code": "NW"
            },
            {
                "id": 2543,
                "name": "Qashqadaryo Region",
                "state_code": "QA"
            },
            {
                "id": 2544,
                "name": "Samarqand Region",
                "state_code": "SA"
            },
            {
                "id": 2547,
                "name": "Sirdaryo Region",
                "state_code": "SI"
            },
            {
                "id": 2546,
                "name": "Surxondaryo Region",
                "state_code": "SU"
            },
            {
                "id": 2536,
                "name": "Tashkent",
                "state_code": "TK"
            },
            {
                "id": 2549,
                "name": "Tashkent Region",
                "state_code": "TO"
            },
            {
                "id": 2539,
                "name": "Xorazm Region",
                "state_code": "XO"
            }
        ]
    },
    {
        "name": "Vanuatu",
        "iso3": "VUT",
        "iso2": "VU",
        "phone_code": "678",
        "capital": "Port Vila",
        "currency": "VUV",
        "currency_symbol": "VT",
        "tld": ".vu",
        "native": "Vanuatu",
        "region": "Oceania",
        "subregion": "Melanesia",
        "timezones": [
            {
                "zoneName": "Pacific/Efate",
                "gmtOffset": 39600,
                "gmtOffsetName": "UTC+11:00",
                "abbreviation": "VUT",
                "tzName": "Vanuatu Time"
            }
        ],
        "translations": {
            "br": "Vanuatu",
            "pt": "Vanuatu",
            "nl": "Vanuatu",
            "hr": "Vanuatu",
            "fa": "وانواتو",
            "de": "Vanuatu",
            "es": "Vanuatu",
            "fr": "Vanuatu",
            "ja": "バヌアツ",
            "it": "Vanuatu"
        },
        "latitude": "-16.00000000",
        "longitude": "167.00000000",
        "emoji": "🇻🇺",
        "emojiU": "U+1F1FB U+1F1FA",
        "states": [
            {
                "id": 4775,
                "name": "Malampa",
                "state_code": "MAP"
            },
            {
                "id": 4773,
                "name": "Penama",
                "state_code": "PAM"
            },
            {
                "id": 4776,
                "name": "Sanma",
                "state_code": "SAM"
            },
            {
                "id": 4774,
                "name": "Shefa",
                "state_code": "SEE"
            },
            {
                "id": 4777,
                "name": "Tafea",
                "state_code": "TAE"
            },
            {
                "id": 4772,
                "name": "Torba",
                "state_code": "TOB"
            }
        ]
    },
    {
        "name": "Vatican City State (Holy See)",
        "iso3": "VAT",
        "iso2": "VA",
        "phone_code": "379",
        "capital": "Vatican City",
        "currency": "EUR",
        "currency_symbol": "€",
        "tld": ".va",
        "native": "Vaticano",
        "region": "Europe",
        "subregion": "Southern Europe",
        "timezones": [
            {
                "zoneName": "Europe/Vatican",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "CET",
                "tzName": "Central European Time"
            }
        ],
        "translations": {
            "br": "Vaticano",
            "pt": "Vaticano",
            "nl": "Heilige Stoel",
            "hr": "Sveta Stolica",
            "fa": "سریر مقدس",
            "de": "Heiliger Stuhl",
            "es": "Santa Sede",
            "fr": "voir Saint",
            "ja": "聖座",
            "it": "Santa Sede"
        },
        "latitude": "41.90000000",
        "longitude": "12.45000000",
        "emoji": "🇻🇦",
        "emojiU": "U+1F1FB U+1F1E6",
        "states": []
    },
    {
        "name": "Venezuela",
        "iso3": "VEN",
        "iso2": "VE",
        "phone_code": "58",
        "capital": "Caracas",
        "currency": "VEF",
        "currency_symbol": "Bs",
        "tld": ".ve",
        "native": "Venezuela",
        "region": "Americas",
        "subregion": "South America",
        "timezones": [
            {
                "zoneName": "America/Caracas",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "VET",
                "tzName": "Venezuelan Standard Time"
            }
        ],
        "translations": {
            "br": "Venezuela",
            "pt": "Venezuela",
            "nl": "Venezuela",
            "hr": "Venezuela",
            "fa": "ونزوئلا",
            "de": "Venezuela",
            "es": "Venezuela",
            "fr": "Venezuela",
            "ja": "ベネズエラ・ボリバル共和国",
            "it": "Venezuela"
        },
        "latitude": "8.00000000",
        "longitude": "-66.00000000",
        "emoji": "🇻🇪",
        "emojiU": "U+1F1FB U+1F1EA",
        "states": [
            {
                "id": 2044,
                "name": "Amazonas",
                "state_code": "Z"
            },
            {
                "id": 2050,
                "name": "Anzoátegui",
                "state_code": "B"
            },
            {
                "id": 4856,
                "name": "Apure",
                "state_code": "C"
            },
            {
                "id": 2047,
                "name": "Aragua",
                "state_code": "D"
            },
            {
                "id": 2049,
                "name": "Barinas",
                "state_code": "E"
            },
            {
                "id": 2039,
                "name": "Bolívar",
                "state_code": "F"
            },
            {
                "id": 4855,
                "name": "Capital District",
                "state_code": "A"
            },
            {
                "id": 2040,
                "name": "Carabobo",
                "state_code": "G"
            },
            {
                "id": 2034,
                "name": "Cojedes",
                "state_code": "H"
            },
            {
                "id": 2051,
                "name": "Delta Amacuro",
                "state_code": "Y"
            },
            {
                "id": 2035,
                "name": "Falcón",
                "state_code": "I"
            },
            {
                "id": 2046,
                "name": "Federal Dependencies of Venezuela",
                "state_code": "W"
            },
            {
                "id": 2045,
                "name": "Guárico",
                "state_code": "J"
            },
            {
                "id": 2038,
                "name": "Lara",
                "state_code": "K"
            },
            {
                "id": 2053,
                "name": "Mérida",
                "state_code": "L"
            },
            {
                "id": 2037,
                "name": "Miranda",
                "state_code": "M"
            },
            {
                "id": 2054,
                "name": "Monagas",
                "state_code": "N"
            },
            {
                "id": 2052,
                "name": "Nueva Esparta",
                "state_code": "O"
            },
            {
                "id": 2036,
                "name": "Portuguesa",
                "state_code": "P"
            },
            {
                "id": 2056,
                "name": "Sucre",
                "state_code": "R"
            },
            {
                "id": 2048,
                "name": "Táchira",
                "state_code": "S"
            },
            {
                "id": 2043,
                "name": "Trujillo",
                "state_code": "T"
            },
            {
                "id": 2055,
                "name": "Vargas",
                "state_code": "X"
            },
            {
                "id": 2041,
                "name": "Yaracuy",
                "state_code": "U"
            },
            {
                "id": 2042,
                "name": "Zulia",
                "state_code": "V"
            }
        ]
    },
    {
        "name": "Vietnam",
        "iso3": "VNM",
        "iso2": "VN",
        "phone_code": "84",
        "capital": "Hanoi",
        "currency": "VND",
        "currency_symbol": "₫",
        "tld": ".vn",
        "native": "Việt Nam",
        "region": "Asia",
        "subregion": "South-Eastern Asia",
        "timezones": [
            {
                "zoneName": "Asia/Ho_Chi_Minh",
                "gmtOffset": 25200,
                "gmtOffsetName": "UTC+07:00",
                "abbreviation": "ICT",
                "tzName": "Indochina Time"
            }
        ],
        "translations": {
            "br": "Vietnã",
            "pt": "Vietname",
            "nl": "Vietnam",
            "hr": "Vijetnam",
            "fa": "ویتنام",
            "de": "Vietnam",
            "es": "Vietnam",
            "fr": "Viêt Nam",
            "ja": "ベトナム",
            "it": "Vietnam"
        },
        "latitude": "16.16666666",
        "longitude": "107.83333333",
        "emoji": "🇻🇳",
        "emojiU": "U+1F1FB U+1F1F3",
        "states": [
            {
                "id": 3794,
                "name": "An Giang",
                "state_code": "44"
            },
            {
                "id": 3770,
                "name": "Bà Rịa-Vũng Tàu",
                "state_code": "43"
            },
            {
                "id": 3815,
                "name": "Bắc Giang",
                "state_code": "54"
            },
            {
                "id": 3822,
                "name": "Bắc Kạn",
                "state_code": "53"
            },
            {
                "id": 3804,
                "name": "Bạc Liêu",
                "state_code": "55"
            },
            {
                "id": 3791,
                "name": "Bắc Ninh",
                "state_code": "56"
            },
            {
                "id": 3796,
                "name": "Bến Tre",
                "state_code": "50"
            },
            {
                "id": 3785,
                "name": "Bình Dương",
                "state_code": "57"
            },
            {
                "id": 3830,
                "name": "Bình Định",
                "state_code": "31"
            },
            {
                "id": 3797,
                "name": "Bình Phước",
                "state_code": "58"
            },
            {
                "id": 3787,
                "name": "Bình Thuận",
                "state_code": "40"
            },
            {
                "id": 3778,
                "name": "Cà Mau",
                "state_code": "59"
            },
            {
                "id": 3782,
                "name": "Cao Bằng",
                "state_code": "04"
            },
            {
                "id": 3806,
                "name": "Da Nang",
                "state_code": "DN"
            },
            {
                "id": 3829,
                "name": "Đắk Lắk",
                "state_code": "33"
            },
            {
                "id": 3823,
                "name": "Đắk Nông",
                "state_code": "72"
            },
            {
                "id": 3773,
                "name": "Điện Biên",
                "state_code": "71"
            },
            {
                "id": 3821,
                "name": "Đồng Nai",
                "state_code": "39"
            },
            {
                "id": 3769,
                "name": "Đồng Tháp",
                "state_code": "45"
            },
            {
                "id": 3813,
                "name": "Gia Lai",
                "state_code": "30"
            },
            {
                "id": 3779,
                "name": "Hà Giang",
                "state_code": "03"
            },
            {
                "id": 3802,
                "name": "Hà Nam",
                "state_code": "63"
            },
            {
                "id": 3820,
                "name": "Hà Tây",
                "state_code": "15"
            },
            {
                "id": 3816,
                "name": "Hà Tĩnh",
                "state_code": "23"
            },
            {
                "id": 3827,
                "name": "Hải Dương",
                "state_code": "61"
            },
            {
                "id": 3783,
                "name": "Haiphong",
                "state_code": "HP"
            },
            {
                "id": 3810,
                "name": "Hanoi",
                "state_code": "HN"
            },
            {
                "id": 3777,
                "name": "Hậu Giang",
                "state_code": "73"
            },
            {
                "id": 3811,
                "name": "Ho Chi Minh City",
                "state_code": "SG"
            },
            {
                "id": 3799,
                "name": "Hòa Bình",
                "state_code": "14"
            },
            {
                "id": 3768,
                "name": "Hưng Yên",
                "state_code": "66"
            },
            {
                "id": 3793,
                "name": "Khánh Hòa",
                "state_code": "34"
            },
            {
                "id": 3800,
                "name": "Kiên Giang",
                "state_code": "47"
            },
            {
                "id": 3772,
                "name": "Kon Tum",
                "state_code": "28"
            },
            {
                "id": 3825,
                "name": "Lai Châu",
                "state_code": "01"
            },
            {
                "id": 3818,
                "name": "Lâm Đồng",
                "state_code": "35"
            },
            {
                "id": 3792,
                "name": "Lạng Sơn",
                "state_code": "09"
            },
            {
                "id": 3817,
                "name": "Lào Cai",
                "state_code": "02"
            },
            {
                "id": 3808,
                "name": "Long An",
                "state_code": "41"
            },
            {
                "id": 3789,
                "name": "Nam Định",
                "state_code": "67"
            },
            {
                "id": 3780,
                "name": "Nghệ An",
                "state_code": "22"
            },
            {
                "id": 3786,
                "name": "Ninh Bình",
                "state_code": "18"
            },
            {
                "id": 3788,
                "name": "Ninh Thuận",
                "state_code": "36"
            },
            {
                "id": 3801,
                "name": "Phú Thọ",
                "state_code": "68"
            },
            {
                "id": 3824,
                "name": "Phú Yên",
                "state_code": "32"
            },
            {
                "id": 3809,
                "name": "Quảng Bình",
                "state_code": "24"
            },
            {
                "id": 3776,
                "name": "Quảng Nam",
                "state_code": "27"
            },
            {
                "id": 3828,
                "name": "Quảng Ngãi",
                "state_code": "29"
            },
            {
                "id": 3814,
                "name": "Quảng Ninh",
                "state_code": "13"
            },
            {
                "id": 3803,
                "name": "Quảng Trị",
                "state_code": "25"
            },
            {
                "id": 3819,
                "name": "Sóc Trăng",
                "state_code": "52"
            },
            {
                "id": 3812,
                "name": "Sơn La",
                "state_code": "05"
            },
            {
                "id": 3826,
                "name": "Tây Ninh",
                "state_code": "37"
            },
            {
                "id": 3775,
                "name": "Thái Bình",
                "state_code": "20"
            },
            {
                "id": 3807,
                "name": "Thái Nguyên",
                "state_code": "69"
            },
            {
                "id": 3771,
                "name": "Thanh Hóa",
                "state_code": "21"
            },
            {
                "id": 3798,
                "name": "Thừa Thiên-Huế",
                "state_code": "26"
            },
            {
                "id": 3781,
                "name": "Tiền Giang",
                "state_code": "46"
            },
            {
                "id": 3805,
                "name": "Trà Vinh",
                "state_code": "51"
            },
            {
                "id": 3795,
                "name": "Tuyên Quang",
                "state_code": "07"
            },
            {
                "id": 3790,
                "name": "Vĩnh Long",
                "state_code": "49"
            },
            {
                "id": 3774,
                "name": "Vĩnh Phúc",
                "state_code": "70"
            },
            {
                "id": 3784,
                "name": "Yên Bái",
                "state_code": "06"
            }
        ]
    },
    {
        "name": "Virgin Islands (British)",
        "iso3": "VGB",
        "iso2": "VG",
        "phone_code": "+1-284",
        "capital": "Road Town",
        "currency": "USD",
        "currency_symbol": "$",
        "tld": ".vg",
        "native": "British Virgin Islands",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/Tortola",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "translations": {
            "br": "Ilhas Virgens Britânicas",
            "pt": "Ilhas Virgens Britânicas",
            "nl": "Britse Maagdeneilanden",
            "hr": "Britanski Djevičanski Otoci",
            "fa": "جزایر ویرجین بریتانیا",
            "de": "Britische Jungferninseln",
            "es": "Islas Vírgenes del Reino Unido",
            "fr": "Îles Vierges britanniques",
            "ja": "イギリス領ヴァージン諸島",
            "it": "Isole Vergini Britanniche"
        },
        "latitude": "18.43138300",
        "longitude": "-64.62305000",
        "emoji": "🇻🇬",
        "emojiU": "U+1F1FB U+1F1EC",
        "states": []
    },
    {
        "name": "Virgin Islands (US)",
        "iso3": "VIR",
        "iso2": "VI",
        "phone_code": "+1-340",
        "capital": "Charlotte Amalie",
        "currency": "USD",
        "currency_symbol": "$",
        "tld": ".vi",
        "native": "United States Virgin Islands",
        "region": "Americas",
        "subregion": "Caribbean",
        "timezones": [
            {
                "zoneName": "America/St_Thomas",
                "gmtOffset": -14400,
                "gmtOffsetName": "UTC-04:00",
                "abbreviation": "AST",
                "tzName": "Atlantic Standard Time"
            }
        ],
        "translations": {
            "br": "Ilhas Virgens Americanas",
            "pt": "Ilhas Virgens Americanas",
            "nl": "Verenigde Staten Maagdeneilanden",
            "fa": "جزایر ویرجین آمریکا",
            "de": "Amerikanische Jungferninseln",
            "es": "Islas Vírgenes de los Estados Unidos",
            "fr": "Îles Vierges des États-Unis",
            "ja": "アメリカ領ヴァージン諸島",
            "it": "Isole Vergini americane"
        },
        "latitude": "18.34000000",
        "longitude": "-64.93000000",
        "emoji": "🇻🇮",
        "emojiU": "U+1F1FB U+1F1EE",
        "states": []
    },
    {
        "name": "Wallis And Futuna Islands",
        "iso3": "WLF",
        "iso2": "WF",
        "phone_code": "681",
        "capital": "Mata Utu",
        "currency": "XPF",
        "currency_symbol": "₣",
        "tld": ".wf",
        "native": "Wallis et Futuna",
        "region": "Oceania",
        "subregion": "Polynesia",
        "timezones": [
            {
                "zoneName": "Pacific/Wallis",
                "gmtOffset": 43200,
                "gmtOffsetName": "UTC+12:00",
                "abbreviation": "WFT",
                "tzName": "Wallis & Futuna Time"
            }
        ],
        "translations": {
            "br": "Wallis e Futuna",
            "pt": "Wallis e Futuna",
            "nl": "Wallis en Futuna",
            "hr": "Wallis i Fortuna",
            "fa": "والیس و فوتونا",
            "de": "Wallis und Futuna",
            "es": "Wallis y Futuna",
            "fr": "Wallis-et-Futuna",
            "ja": "ウォリス・フツナ",
            "it": "Wallis e Futuna"
        },
        "latitude": "-13.30000000",
        "longitude": "-176.20000000",
        "emoji": "🇼🇫",
        "emojiU": "U+1F1FC U+1F1EB",
        "states": []
    },
    {
        "name": "Western Sahara",
        "iso3": "ESH",
        "iso2": "EH",
        "phone_code": "212",
        "capital": "El-Aaiun",
        "currency": "MAD",
        "currency_symbol": "MAD",
        "tld": ".eh",
        "native": "الصحراء الغربية",
        "region": "Africa",
        "subregion": "Northern Africa",
        "timezones": [
            {
                "zoneName": "Africa/El_Aaiun",
                "gmtOffset": 3600,
                "gmtOffsetName": "UTC+01:00",
                "abbreviation": "WEST",
                "tzName": "Western European Summer Time"
            }
        ],
        "translations": {
            "br": "Saara Ocidental",
            "pt": "Saara Ocidental",
            "nl": "Westelijke Sahara",
            "hr": "Zapadna Sahara",
            "fa": "جمهوری دموکراتیک عربی صحرا",
            "de": "Westsahara",
            "es": "Sahara Occidental",
            "fr": "Sahara Occidental",
            "ja": "西サハラ",
            "it": "Sahara Occidentale"
        },
        "latitude": "24.50000000",
        "longitude": "-13.00000000",
        "emoji": "🇪🇭",
        "emojiU": "U+1F1EA U+1F1ED",
        "states": []
    },
    {
        "name": "Yemen",
        "iso3": "YEM",
        "iso2": "YE",
        "phone_code": "967",
        "capital": "Sanaa",
        "currency": "YER",
        "currency_symbol": "﷼",
        "tld": ".ye",
        "native": "اليَمَن",
        "region": "Asia",
        "subregion": "Western Asia",
        "timezones": [
            {
                "zoneName": "Asia/Aden",
                "gmtOffset": 10800,
                "gmtOffsetName": "UTC+03:00",
                "abbreviation": "AST",
                "tzName": "Arabia Standard Time"
            }
        ],
        "translations": {
            "br": "Iêmen",
            "pt": "Iémen",
            "nl": "Jemen",
            "hr": "Jemen",
            "fa": "یمن",
            "de": "Jemen",
            "es": "Yemen",
            "fr": "Yémen",
            "ja": "イエメン",
            "it": "Yemen"
        },
        "latitude": "15.00000000",
        "longitude": "48.00000000",
        "emoji": "🇾🇪",
        "emojiU": "U+1F1FE U+1F1EA",
        "states": [
            {
                "id": 1242,
                "name": "'Adan Governorate",
                "state_code": "AD"
            },
            {
                "id": 1250,
                "name": "'Amran Governorate",
                "state_code": "AM"
            },
            {
                "id": 1237,
                "name": "Abyan Governorate",
                "state_code": "AB"
            },
            {
                "id": 1240,
                "name": "Al Bayda' Governorate",
                "state_code": "BA"
            },
            {
                "id": 1241,
                "name": "Al Hudaydah Governorate",
                "state_code": "HU"
            },
            {
                "id": 1243,
                "name": "Al Jawf Governorate",
                "state_code": "JA"
            },
            {
                "id": 1251,
                "name": "Al Mahrah Governorate",
                "state_code": "MR"
            },
            {
                "id": 1235,
                "name": "Al Mahwit Governorate",
                "state_code": "MW"
            },
            {
                "id": 1246,
                "name": "Dhamar Governorate",
                "state_code": "DH"
            },
            {
                "id": 1238,
                "name": "Hadhramaut Governorate",
                "state_code": "HD"
            },
            {
                "id": 1244,
                "name": "Hajjah Governorate",
                "state_code": "HJ"
            },
            {
                "id": 1233,
                "name": "Ibb Governorate",
                "state_code": "IB"
            },
            {
                "id": 1245,
                "name": "Lahij Governorate",
                "state_code": "LA"
            },
            {
                "id": 1234,
                "name": "Ma'rib Governorate",
                "state_code": "MA"
            },
            {
                "id": 1248,
                "name": "Raymah Governorate",
                "state_code": "RA"
            },
            {
                "id": 1249,
                "name": "Saada Governorate",
                "state_code": "SD"
            },
            {
                "id": 1232,
                "name": "Sana'a",
                "state_code": "SA"
            },
            {
                "id": 1236,
                "name": "Sana'a Governorate",
                "state_code": "SN"
            },
            {
                "id": 1247,
                "name": "Shabwah Governorate",
                "state_code": "SH"
            },
            {
                "id": 1239,
                "name": "Socotra Governorate",
                "state_code": "SU"
            },
            {
                "id": 1231,
                "name": "Ta'izz Governorate",
                "state_code": "TA"
            }
        ]
    },
    {
        "name": "Zambia",
        "iso3": "ZMB",
        "iso2": "ZM",
        "phone_code": "260",
        "capital": "Lusaka",
        "currency": "ZMW",
        "currency_symbol": "ZK",
        "tld": ".zm",
        "native": "Zambia",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa/Lusaka",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "CAT",
                "tzName": "Central Africa Time"
            }
        ],
        "translations": {
            "br": "Zâmbia",
            "pt": "Zâmbia",
            "nl": "Zambia",
            "hr": "Zambija",
            "fa": "زامبیا",
            "de": "Sambia",
            "es": "Zambia",
            "fr": "Zambie",
            "ja": "ザンビア",
            "it": "Zambia"
        },
        "latitude": "-15.00000000",
        "longitude": "30.00000000",
        "emoji": "🇿🇲",
        "emojiU": "U+1F1FF U+1F1F2",
        "states": [
            {
                "id": 1986,
                "name": "Central Province",
                "state_code": "02"
            },
            {
                "id": 1984,
                "name": "Copperbelt Province",
                "state_code": "08"
            },
            {
                "id": 1991,
                "name": "Eastern Province",
                "state_code": "03"
            },
            {
                "id": 1987,
                "name": "Luapula Province",
                "state_code": "04"
            },
            {
                "id": 1988,
                "name": "Lusaka Province",
                "state_code": "09"
            },
            {
                "id": 1989,
                "name": "Muchinga Province",
                "state_code": "10"
            },
            {
                "id": 1982,
                "name": "Northern Province",
                "state_code": "05"
            },
            {
                "id": 1985,
                "name": "Northwestern Province",
                "state_code": "06"
            },
            {
                "id": 1990,
                "name": "Southern Province",
                "state_code": "07"
            },
            {
                "id": 1983,
                "name": "Western Province",
                "state_code": "01"
            }
        ]
    },
    {
        "name": "Zimbabwe",
        "iso3": "ZWE",
        "iso2": "ZW",
        "phone_code": "263",
        "capital": "Harare",
        "currency": "ZWL",
        "currency_symbol": "$",
        "tld": ".zw",
        "native": "Zimbabwe",
        "region": "Africa",
        "subregion": "Eastern Africa",
        "timezones": [
            {
                "zoneName": "Africa/Harare",
                "gmtOffset": 7200,
                "gmtOffsetName": "UTC+02:00",
                "abbreviation": "CAT",
                "tzName": "Central Africa Time"
            }
        ],
        "translations": {
            "br": "Zimbabwe",
            "pt": "Zimbabué",
            "nl": "Zimbabwe",
            "hr": "Zimbabve",
            "fa": "زیمباوه",
            "de": "Simbabwe",
            "es": "Zimbabue",
            "fr": "Zimbabwe",
            "ja": "ジンバブエ",
            "it": "Zimbabwe"
        },
        "latitude": "-20.00000000",
        "longitude": "30.00000000",
        "emoji": "🇿🇼",
        "emojiU": "U+1F1FF U+1F1FC",
        "states": [
            {
                "id": 1956,
                "name": "Bulawayo Province",
                "state_code": "BU"
            },
            {
                "id": 1958,
                "name": "Harare Province",
                "state_code": "HA"
            },
            {
                "id": 1959,
                "name": "Manicaland",
                "state_code": "MA"
            },
            {
                "id": 1955,
                "name": "Mashonaland Central Province",
                "state_code": "MC"
            },
            {
                "id": 1951,
                "name": "Mashonaland East Province",
                "state_code": "ME"
            },
            {
                "id": 1953,
                "name": "Mashonaland West Province",
                "state_code": "MW"
            },
            {
                "id": 1960,
                "name": "Masvingo Province",
                "state_code": "MV"
            },
            {
                "id": 1954,
                "name": "Matabeleland North Province",
                "state_code": "MN"
            },
            {
                "id": 1952,
                "name": "Matabeleland South Province",
                "state_code": "MS"
            },
            {
                "id": 1957,
                "name": "Midlands Province",
                "state_code": "MI"
            }
        ]
    }
  ]
  
import React from 'react'
import { Center, FlexProps, Icon, Stack, Text } from '@chakra-ui/react'
import { SIDEBAR_CONFIG, SIDEBAR_PORTRAIT_TO_LANDSCAPE_RATIO } from '../../configs/LayoutConfigs'
import './SidebarItem.css'
import { useHistory, useLocation } from 'react-router-dom'
import { SidebarItemObjectType } from '../../dataObjects/SideBarObject'

export interface SidebarItemInputType extends FlexProps {
    menuObj: SidebarItemObjectType
}

export const SidebarItem = ( props:SidebarItemInputType ) => {
    const { menuObj, ...propsToPass } = props
    const location = useLocation()
    const history = useHistory()
    const isActive = ( location.pathname === menuObj.link )?'is-current':''
    return (
        <Center
            w={{
                base: `${SIDEBAR_CONFIG.MOBILE_VIEW.BOTTOM_NAVBAR_HEIGHT}rem`,
                md: `${SIDEBAR_CONFIG.WIDE_SCREEN_VIEW.SIDE_NAVBAR_WIDTH}rem`
            }}
            h={{
                base: `${SIDEBAR_CONFIG.MOBILE_VIEW.BOTTOM_NAVBAR_HEIGHT}rem`,
                md: `${SIDEBAR_CONFIG.WIDE_SCREEN_VIEW.SIDE_NAVBAR_WIDTH}rem`
            }}
            transform={{
                base: `scale(${SIDEBAR_PORTRAIT_TO_LANDSCAPE_RATIO * 1.4})`,
                md: 'scale(1)'
            }}
            className={ `list-hover-slide ${isActive}` }
            {...propsToPass}
            onClick={() => {
                //@ts-ignore
                (menuObj.noLink === true)? menuObj.onClickFunction() : history.push(menuObj.link)
            }}
            cursor="pointer"
        >
            <Stack alignItems="center" spacing={1} >
                <Icon as={ menuObj.icon } />
                <Text 
                    fontSize={{
                        base: "0.4rem",
                        md: "0.6rem"
                    }}
                    textAlign="center"
                    wordBreak="break-all"
                    // display={{
                    //     base: "none",
                    //     md: "inherit"
                    // }}
                >
                    { menuObj.name }
                </Text>
            </Stack>
        </Center>
    )
}

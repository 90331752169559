import React, { RefObject } from "react";
import { State } from "@hookstate/core";
import { ElementsType } from "../DrawingSheet";
import { Layer as KonvaLayer } from "konva/lib/Layer";

import TextEditor from "./TextEditor";
import EmptyPane from "./EmptyPane";
import ImageEditor from "./ImageEditor";

interface Props {
	elements: State<ElementsType>;
	selectedId: State<string | null>;
	layerRef: RefObject<KonvaLayer>;
}

const PropsPane: React.FC<Props> = ({ elements, selectedId }) => {
	if (!!!selectedId.get()) return <EmptyPane />;
	const element = elements[selectedId.get() || "null"];
	if (!!!element.get()) return <EmptyPane />;
	switch (element.type.get()) {
		case "text":
			return <TextEditor element={element} />;
		case "qr":
			return <ImageEditor isQr={true} element={element} />;
		case "image":
			return <ImageEditor element={element} />;
		default:
			return <EmptyPane />;
	}
};

export default PropsPane;

import React, { useRef, useEffect } from "react";
import { Transformer, Ellipse } from "react-konva";
import { Ellipse as KonvaEllipse } from "konva/lib/shapes/Ellipse";
import { Transformer as KonvaTransformer } from "konva/lib/shapes/Transformer";

export interface EllipseProps {
	id?: string;
	x: number;
	y: number;
	radiusX: number;
	radiusY: number;
	width?: number;
	height?: number;
	fill: string;
	stroke?: string;
	strokeWidth?: number;
}

export interface Props {
	attrs: EllipseProps;
	isSelected: boolean;
	onSelect: () => void;
	onChange: (newAttrs: EllipseProps) => void;
}

const EllipseShape: React.FC<Props> = ({ attrs, isSelected, onSelect, onChange }) => {
	const shapeRef = useRef<KonvaEllipse>(null);
	const trRef = useRef<KonvaTransformer>(null);

	const handleKeyup = () => {
		if (isSelected) {
			handleDragEnd()
		}
	}

	const handleKeydown = (event:any) => {
		if (isSelected) {
			switch (event.key) {
				case 'ArrowLeft':
					shapeRef.current?.x(shapeRef.current?.x() - 1)		
					break;

				case 'ArrowRight':
					shapeRef.current?.x(shapeRef.current?.x() + 1)	
					break;

				case 'ArrowUp':
					shapeRef.current?.y(shapeRef.current?.y() - 1)		
					break;

				case 'ArrowDown':
					shapeRef.current?.y(shapeRef.current?.y() + 1)		
					break;
			
				default:
					break;
			}
		}
	}

	useEffect(() => {
		window.addEventListener('keydown', handleKeydown)
		window.addEventListener('keyup', handleKeyup)
		return () => {
			window.removeEventListener('keydown', handleKeydown)
			window.removeEventListener('keyup', handleKeyup)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSelected])

	useEffect(() => {
		if (isSelected) {
			// attach transformer
			if (trRef.current && shapeRef.current) {
				trRef.current.nodes([shapeRef.current]);
				trRef.current.moveToTop();
				trRef.current.getLayer()?.batchDraw();
			}
		}
	}, [isSelected]);

	const handleMouseEnter = () => {
		const node = shapeRef.current;
		if (node && !isSelected) onChange({ ...attrs, stroke: "#42adf5", strokeWidth: 1.5 });
	};

	const handleMouseLeave = () => {
		const node = shapeRef.current;
		if (node) onChange({ ...attrs, strokeWidth: 0 });
	};

	const handleDragEnd = () => {
		let tr = trRef.current;
		if (tr) {
			let x = tr?.getX();
			let y = tr?.getY();
			onChange({ ...attrs, x, y });
		}
		onSelect();
	};

	return (
		<>
			<Ellipse
				draggable
				{...attrs}
				onMouseDown={onSelect}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={onSelect}
				onTap={onSelect}
				ref={shapeRef}
				onDragEnd={handleDragEnd}
				onTransformEnd={(e) => {
					onSelect();
					const node = shapeRef.current;
					if (node) {
						const scaleX = node.scaleX();
						const scaleY = node.scaleY();

						node.scaleX(1);
						node.scaleY(1);
						onChange({
							...attrs,
							width: node.width() * scaleX,
							height: node.height() * scaleY,
						});
					}
				}}
			/>
			{isSelected && (
				<Transformer
					ref={trRef}
					boundBoxFunc={(oldBox, newBox) => {
						if (newBox.width < 5 || newBox.height < 5) {
							return oldBox;
						}
						return newBox;
					}}
				/>
			)}
		</>
	);
};

export default EllipseShape;

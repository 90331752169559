import {
    Box,
    Heading,
    Input,
    Text,
    FormControl,
    Stack,
    InputGroup,
    InputLeftAddon,
    useColorModeValue,
    Button,
    Flex,
    FormLabel,
    FormHelperText,
    FormErrorMessage,
    Icon,
} from '@chakra-ui/react'
import * as React from 'react'
import { Card } from '../../components/card/Card'
import { Logo } from '../../components/logo/Logo'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FaUser } from 'react-icons/fa'
import { ColorModeSwitcher } from '../../ColorModeSwitcher'
import { useAuth } from '../../contexts/Auth'
import { displayError, displaySuccess } from '../../helpers/CommonFunctions'

const schema = yup.object().shape({
	email: yup.string().email().required()
})

export type MagicLinkFormInputs = {
	email: string
}

export default function MagicLink() {

    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<MagicLinkFormInputs>({
		mode: 'onBlur',
		resolver: yupResolver(schema),
	})

    const { sendMagicLink } = useAuth()

    const handleSendEmailButtonClick = async (values:MagicLinkFormInputs) => {
        const { error } = await sendMagicLink(values)
        if (error) {
            displayError( '', '', true, {
                code: error.status,
                error: error
            })
        } else {
            displaySuccess( '', '', true, {
                type: 'USER',
                subType: 'LINK_SEND'
            })
        }
    }

    return (
        <>
        <Flex justifyContent="flex-end" mb={-14}>
          <ColorModeSwitcher m={2} />
        </Flex>
        <Box
            bg={useColorModeValue('gray.50', 'inherit')}
            minH="100vh"
            py="12"
            px={{ base: '4', lg: '8' }}
        >
        <Box maxW="md" mx="auto">
          <Logo mx="auto" h="8" justifyContent="center" mb={{ base: '10', md: '20' }} />
          <Heading textAlign="center" size="xl" fontWeight="extrabold">
            Have too much to remember ?
          </Heading>
          <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
            <Text as="span">Here is one less password for you</Text>
          </Text>
          <Card>
            <form
				onSubmit={handleSubmit(handleSendEmailButtonClick)}
			>
				<Stack spacing="12">
				<FormControl 
					id="email"
					isInvalid={!!errors?.email}
					errortext={errors?.email?.message}
					isRequired
				>
					<Flex align="baseline" justify="space-between">
						<FormLabel mb={1}>Email</FormLabel>
					</Flex>
					<InputGroup>
						<InputLeftAddon 
							children={<Icon as={FaUser} />}
						/>
						<Input 
							type="email" 
							autoComplete="email" 
							{...register('email')} 
							placeholder="someone@email.com"
						/>
					</InputGroup>
					<FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
                    <FormHelperText>Just click on the link, We'll send on this email and Houdini will log you in magically</FormHelperText>
				</FormControl>
                <Button 
                    type="submit" 
                    colorScheme="blue" 
                    size="lg" 
                    fontSize="md" 
                    isLoading={isSubmitting} 
                    loadingText="Sending..."
                    isDisabled={Object.keys(errors).length !== 0}
                >
                    Send Magic Link
                </Button>
                </Stack>
            </form>
          </Card>
        </Box>
      </Box>
      </>
    )
}

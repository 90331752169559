import React from 'react'
import { SidebarSection } from '../../layout/sidebarSection/SidebarSection'
import { menuData, sidebarLogoutObject } from '../../dataObjects/SideBarObject'
import { SidebarItem } from './SidebarItem'
import { useAuth } from '../../contexts/Auth'

export const Sidebar = () => {
    const menuDataList = menuData.data
    const { signOut } = useAuth()

    sidebarLogoutObject.onClickFunction = signOut
    return (
        <SidebarSection>
            {
                menuDataList.map( (menuDataObject) => {
                    return (
                        <SidebarItem key={menuDataObject.id} menuObj={menuDataObject} />
                    )
                })
            }
            <SidebarItem 
                key={sidebarLogoutObject.id} 
                menuObj={sidebarLogoutObject} 
                mt="auto" 
                display={{
                    base: "none",
                    md: "inherit"
                }}
            />
        </SidebarSection>
    )
}

import moment from 'moment'
import React from 'react'
import { stringOrNumber } from '../../helpers/CommonFunctions'
import DisplayObjectInCard from '../displayObjectInCard/DisplayObjectInCard'

export interface ShowEventInputType {
    displayOrder: stringOrNumber[],
    keysAndDisplayNameMapping: {[key:string]:string},
    displayObject: {[key:string]:any}
}

const ShowEvent = (props:ShowEventInputType) => {
    const { displayOrder, keysAndDisplayNameMapping, displayObject } = props
    const internalDisplayObject = {
        ...displayObject,
        ...( displayObject.start_date && { start_date: moment( displayObject.start_date ).local().format('MMM DD, YYYY') } ),
        ...( displayObject.end_date && { end_date: moment( displayObject.end_date ).local().format('MMM DD, YYYY') } ),
        ...( displayObject.inserted_at && { inserted_at: moment( displayObject.inserted_at ).local().format('MMM DD, YYYY HH:mm:ss') } ),
        ...( displayObject.updated_at && { updated_at: moment( displayObject.updated_at ).local().format('MMM DD, YYYY HH:mm:ss') } ),
        ...( displayObject.hashtags && { hashtags: displayObject.hashtags.map( (item:string) => ( '#'+item )).join(' ') } ),
        address: [displayObject.address['address_line_one'], displayObject.address['address_line_two'] ,displayObject.address['city'], displayObject.address['state'], displayObject.address['country'], displayObject.address['pin_code']].filter(Boolean).join(", ")
    }
    const internalDisplayOrder = displayOrder.filter(item => !['controls'].includes(item as string))
    return (
        <DisplayObjectInCard 
            displayOrder={internalDisplayOrder}
            keysAndDisplayNameMapping={keysAndDisplayNameMapping}
            displayObject={internalDisplayObject}
        />
    )
}

export default ShowEvent

import React, { BaseSyntheticEvent, useRef } from "react";
import { State } from "@hookstate/core";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Button, Text, Flex, Modal, ModalOverlay, ModalBody, ModalContent, ModalHeader, ModalFooter, Input } from "@chakra-ui/react";
import { FiImage } from "react-icons/fi";

import supabase from "../../configs/Supabase";
import { useAuth } from "../../contexts/Auth";

import { Config } from "./ArtBoard";

interface Props {
	config: State<Config | null>;
	imageURL: State<string>;
}

const DropperBox: React.FC<Props> = ({ config, imageURL }) => {
	const { user } = useAuth();
	const history = useHistory();
	const { params } = useRouteMatch();
	const pickerRef = useRef<HTMLInputElement | null>(null);

	const handleDragEnd = (e: any) => {
		// TODO: add accepted file types
		e.preventDefault();
		const files = e.dataTransfer.files;
		if (files.length > 0) {
			uploadImage(files[0]);
		}
	};

	const onDragOver = (e: any) => {
		let event = e as Event;
		event.stopPropagation();
		event.preventDefault();
	};

	const uploadImage = async (file: File) => {
		// TODO: Error handling
		if ("id" in params) {
			//@ts-ignore
			let { id } = params;
			let ext = file.type.substr(file.type.lastIndexOf("/") + 1).split(".")[0];
			let { data, error } = await supabase.storage
				.from("template-images")
				.upload(`${user?.id}/${id}.${ext}`, file, { upsert: true, cacheControl: "3600" });
			// .update(`${user?.id}/${id}.${ext}`, file, { upsert: true, cacheControl: "3600" });
			if (error) return;
			config.merge({ image_url: data?.Key || "" });
			let sUrl = await supabase.storage.from("template-images").createSignedUrl(`${user?.id}/${id}.${ext}`, 3600);
			if (sUrl.error) return;
			imageURL.set(sUrl.signedURL || "");
		}
	};

	const handleClick = () => {
		pickerRef.current?.click();
	};

	const handleChange = (e: BaseSyntheticEvent) => {
		const files = e.target.files;
		if (files.length > 0) {
			uploadImage(files[0]);
		}
	};

	const handleClose = () => {
		history.replace("/dashboard/templates");
	};

	return (
		<Modal isOpen={true} onClose={handleClose} size={"4xl"}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Add Background Image</ModalHeader>
				<ModalBody onDrop={handleDragEnd} onDragOver={onDragOver}>
					<Flex justify='center' minH='50vh' mb={5}>
						<Flex justify='center' direction='column' outline='6px dashed' outlineColor='gray.600' p={5}>
							<Text fontSize='5xl' alignSelf='center' color='gray.600'>
								<FiImage />
							</Text>
							<Text fontSize='3xl' color='gray.600'>
								Drag & drop an image to upload
							</Text>
							<Text fontSize='xl' color='gray.600' textAlign='center' m={8}>
								OR
							</Text>
							<Flex justify='center'>
								<Input ref={pickerRef} type='file' hidden onChange={handleChange} />
								<Button onClick={handleClick}>Browse files</Button>
							</Flex>
						</Flex>
					</Flex>
				</ModalBody>

				<ModalFooter>
					<Button onClick={handleClose}>Cancel</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default DropperBox;

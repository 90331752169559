import { 
    DEFAULT_USER_ERROR_MESSAGE,
    DEFAULT_USER_ERROR_TITLE,
    FORGOT_PASSWORD_NOT_ALLOWED_FOR_SOME_TIME,
    GENERIC_ERROR_TITLE,
    NO_USER_WITH_EMAIL, 
    USER_ERROR_INVALID_AUTH_MESSAGE, 
    USER_ERROR_INVALID_AUTH_TITLE, 
    USER_ERROR_NOT_ALLOWED, 
    USER_NOT_EXIST, 
    USER_NOT_LOGGED_IN_CHANGE_PASSWORD_MESSAGE, 
    USER_NOT_LOGGED_IN_TITLE
} from "../configs/GlobalConstants"
import { errorObjectInputType } from "./CommonFunctions"

export const globalErrorObjectMaker = ( input:errorObjectInputType ) => {
    if (input.error?.status == null) {
        return {
            code: 500,
            title: GENERIC_ERROR_TITLE,
            message: input.error?.message,
            errorObject: input.error
        }
    }
    if (!input.type && !input.subType ) {
        return {
            code: 500,
            title: input.error.error,
            message: input.error?.message,
            errorObject: input.error
        }
    }
    switch (input.type) {
        case 'USER':
            switch (input.subType) {
                case 'NOT_FOUND':
                    if ( input.code === 429 ) {
                        return {
                            code: 429,
                            title: USER_ERROR_NOT_ALLOWED,
                            message: FORGOT_PASSWORD_NOT_ALLOWED_FOR_SOME_TIME,
                            errorObject: input.error
                        }
                    }
                    return {
                        code: 404,
                        title: USER_NOT_EXIST,
                        message: NO_USER_WITH_EMAIL,
                        errorObject: input.error
                    }

                case 'INVALID_AUTH':
                    return {
                        code: 400,
                        title: USER_ERROR_INVALID_AUTH_TITLE,
                        message: USER_ERROR_INVALID_AUTH_MESSAGE,
                        errorObject: input.error
                    }

                case 'NOT_LOGGED_IN_CHANGE_PASSWORD':
                    return {
                        code: 400,
                        title: USER_NOT_LOGGED_IN_TITLE,
                        message: USER_NOT_LOGGED_IN_CHANGE_PASSWORD_MESSAGE,
                        errorObject: input.error
                    }

                default:
                    return {
                        code: 500,
                        title: DEFAULT_USER_ERROR_TITLE,
                        message: input.error?.message || DEFAULT_USER_ERROR_MESSAGE,
                        errorObject: input.error
                    }
            }
    
        default:
            return {
                code: 500,
                title: DEFAULT_USER_ERROR_TITLE,
                message: input.error?.message || DEFAULT_USER_ERROR_MESSAGE,
                errorObject: input.error
            }
    }
}
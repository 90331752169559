import React from "react";
import { Route, useRouteMatch, Switch } from "react-router-dom";
import { MainSection } from "../../layout/mainSection/MainSection";
import { Home } from "../../sections/home/Home";
import { UserProfile } from "../../sections/userProfile/UserProfile";
import { Builder } from "../../sections/templates/Builder";
import { Templates } from "../../sections/templates/Templates";
import { Events } from "../../sections/events/Events";
import { Organisations } from "../../sections/organisations/Organisations";
import { Certificates } from "../../sections/certificates/Certificates";
import { NewCertificate } from "../../sections/newCertificate/NewCertificate";
import { JobStatus } from "../../sections/jobStatus/JobStatus";
import { ComingSoon } from "../../sections/comingSoon/ComingSoon";
import { Settings } from "../../sections/settings/Settings";
import { Progress } from "@chakra-ui/react";
import { HEADER_NAVBAR_HEIGHT, SIDEBAR_CONFIG } from "../../configs/LayoutConfigs";
import { useHookstate } from "@hookstate/core";
import { loadingStatusStore } from "../../stores/LoadingStatusStore";

export const Main = () => {
	const match = useRouteMatch();
	const loadingState = useHookstate(loadingStatusStore)
	return (
		<MainSection>
			{
				loadingState.isLoading.get() &&
				<Progress 
					size='xs' 
					position='fixed' 
					top={`${HEADER_NAVBAR_HEIGHT}rem`} 
					w='100%' 
					left={{
						base: 0,
						md: `${SIDEBAR_CONFIG.WIDE_SCREEN_VIEW.SIDE_NAVBAR_WIDTH}rem`
					}}
					isIndeterminate 
				/>
			}
			<Switch>
				<Route path={`${match.path}/profile`} component={UserProfile} />
				<Route path={`${match.path}/organisations`} component={Organisations} />
				<Route path={`${match.path}/events`} component={Events} />
				<Route exact path={`${match.path}/templates`} component={Templates} />
				<Route path={`${match.path}/templates/edit/:id`} component={Builder} />
				<Route path={`${match.path}/certificates/status/:id`} component={JobStatus} />
				<Route path={`${match.path}/certificates/new`} component={NewCertificate} />
				<Route exact path={`${match.path}/certificates`} component={Certificates} />
				<Route path={`${match.path}/apicenter`} component={ComingSoon} />
				<Route path={`${match.path}/settings`} component={Settings} />
				<Route path={`${match.path}/`} component={Home} />
			</Switch>
		</MainSection>
	)
}

import React from 'react'
import { stringOrNumber } from '../../helpers/CommonFunctions'
import { Stack, StackDivider } from '@chakra-ui/react'
import { CardProperty } from '../cardProperty/CardProperty'
import { CardWithColorAccent } from '../cardWithColorAccent/CardWithColorAccent'

export interface displayObjectInCardInputType {
    displayOrder?: stringOrNumber[],
    keysAndDisplayNameMapping?: {[key:string]:string},
    displayObject: {[key:string]:any}
}

const DisplayObjectInCard = ( props:displayObjectInCardInputType ) => {
    const { displayOrder, keysAndDisplayNameMapping, displayObject } = props

    let internalDisplayOrder:stringOrNumber[] = []
    let internalKeysAndDisplayNameMapping:{[key:string]:string} = {}

    if ( displayOrder ) {
        internalDisplayOrder = displayOrder
    } else {
        internalDisplayOrder = Object.keys( displayObject )
    }

    if ( keysAndDisplayNameMapping ) {
        internalKeysAndDisplayNameMapping = keysAndDisplayNameMapping
    } else {
        Object.keys( displayObject ).map( key => {
            internalKeysAndDisplayNameMapping[key] = key
            return true
        })
    }
    
    return (
        <CardWithColorAccent>
            <Stack divider={<StackDivider />}>
                {
                    internalDisplayOrder.map( itemKey => (
                        <CardProperty 
                            key={itemKey.toString()}
                            label={internalKeysAndDisplayNameMapping[itemKey]} 
                            value={displayObject[itemKey]} 
                        />
                    ))
                }
            </Stack>
        </CardWithColorAccent>
    )
}

export default DisplayObjectInCard

import moment from "moment"
import supabase from "../configs/Supabase"
import { displayError } from "../helpers/CommonFunctions"
import { eventDataObjectReturnType, eventsDataObjectType, updateEventDataObjectType, deleteEventDataObjectType } from "../types/dataFetcherTypes/EventsDataFetcherTypes"

export const setEventsData = async (updatedData:eventsDataObjectType): Promise<eventDataObjectReturnType> => {
    
    const updatedDataObject = {
        ...updatedData,
        ...( updatedData.start_date && { start_date: moment( updatedData.start_date ).utc() } ),
        ...( updatedData.end_date && { end_date: moment( updatedData.end_date ).utc() } ),
        ...( updatedData.address_type && { address_type: updatedData.address_type.toLowerCase() } ),
    }

    let isError = false
    const { data, error } = await supabase.from( 'events' ).insert(updatedDataObject, {
        returning: "minimal"
    })

    if (error) {
        isError = true
        displayError( 'Action not allowed', error.message || error.details || error.hint)
    }

    return {
        isQueryError: isError,
        queryData: data as eventsDataObjectType[] | null,
        queryError: error
    }
}

export const getEventsData = async ( page:number=1, size:number=10, sortOnColumn:string='updated_at', sortOrder:string='ascending', serchText?:string|null, searchOnColumn?:string|null ): Promise<eventDataObjectReturnType> => {
    try {
        let isError = false
        let queryCondition = supabase
                                .from( 'events' )
                                .select( `
                                    id,
                                    name,
                                    type,
                                    hashtags,
                                    user_id,
                                    about,
                                    start_date,
                                    end_date,
                                    address_type,
                                    address,
                                    inserted_at,
                                    updated_at
                                `, {
                                    count: "exact"
                                })
                                .eq('is_deleted', false)

        if (serchText && searchOnColumn) {
            queryCondition = queryCondition.ilike(searchOnColumn, `%${serchText}%`)
        }

        const { data, error, count } = await queryCondition
                                                .order( sortOnColumn, {
                                                    ascending: (sortOrder === 'ascending')
                                                })
                                                .range( (page-1)*size, (page*size)-1 )
        if (error) {
            isError = true
        }

        return {
            isQueryError: isError,
            queryData: data,
            queryError: error,
            querySize: count,
        }
    } catch (error) {
        throw error;  
    }
}

export const deleteEvent = async ( values:deleteEventDataObjectType ) => {

    const dataToUpdate = {
        is_deleted: true
    }

    let isError = false
    const { data, error } = await supabase.from( 'events' ).update(dataToUpdate)
    .eq('id', values.id)
    .eq('user_id', values.user_id)
    
    if (error) {
        isError = true
        displayError( 'Action not allowed', error.message || error.details || error.hint)
    }

    return {
        isQueryError: isError,
        queryData: data as eventsDataObjectType[] | null,
        queryError: error
    }
}

export const updateEvent = async ( values:updateEventDataObjectType ) => {
    
    const dataToUpdate = {
        ...values,
        id: undefined
    }

    let isError = false
    const { data, error } = await supabase.from( 'events' ).update(dataToUpdate)
    .eq('id', values.id)
    .eq('user_id', values.user_id)
    
    if (error) {
        isError = true
        displayError( 'Action not allowed', error.message || error.details || error.hint)
    }

    return {
        isQueryError: isError,
        queryData: data as eventsDataObjectType[] | null,
        queryError: error
    }
}
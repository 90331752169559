import { FaHome, FaCalendarAlt, FaPeopleCarry, FaCertificate, FaPowerOff } from 'react-icons/fa'
import { HiOutlineTemplate } from 'react-icons/hi'
import { AiOutlineApi } from 'react-icons/ai'
import { IconType } from 'react-icons/lib'

export interface SidebarItemObjectType {
    id: number,
    name: string,
    link: string,
    badge: string,
    icon: IconType,
    noLink?: boolean,
    onClickFunction?: () => void
}

export interface MenuDataType {
    data: Array<SidebarItemObjectType>
}

export const menuData:MenuDataType = {
    data: [
        {
            id: 1,
            name: "Home",
            link: "/dashboard",
            badge: "new",
            icon: FaHome
        },
        {
            id: 2,
            name: "Organisations",
            link: "/dashboard/organisations",
            badge: "new",
            icon: FaPeopleCarry
        },
        {
            id: 3,
            name: "Events",
            link: "/dashboard/events",
            badge: "new",
            icon: FaCalendarAlt
        },
        {
            id: 4,
            name: "Templates",
            link: "/dashboard/templates",
            badge: "new",
            icon: HiOutlineTemplate
        },
        {
            id: 5,
            name: "Certificates",
            link: "/dashboard/certificates",
            badge: "new",
            icon: FaCertificate
        },
        {
            id: 6,
            name: "Api Center",
            link: "/dashboard/apicenter",
            badge: "new",
            icon: AiOutlineApi
        },
    ]
}

export const sidebarLogoutObject:SidebarItemObjectType = {
    id: 7,
    name: "Logout",
    link: "/logout",
    badge: "new",
    icon: FaPowerOff,
    noLink: true,
    onClickFunction: () => {}
}
import { Box, Flex, Heading, HStack, Text, Stack, Button, useColorModeValue as mode, Divider, IconButton } from '@chakra-ui/react'
import * as React from 'react'
import { FaFacebook, FaGoogle, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa'
import { Logo } from '../../components/logo/Logo'
import { SigninForm } from '../../components/signinForm/SigninForm'
import { DividerWithText } from '../../components/divider/DividerWithText'
import { useAuth } from '../../contexts/Auth'
import { ColorModeSwitcher } from '../../ColorModeSwitcher'
import { useLocation } from 'react-router-dom'
// import queryString from 'querystring'
import { redirectURLHolderOnAuth } from '../../stores/AuthStore'
import { useState } from '@hookstate/core'
import { useEffect } from 'react'

export const Signin: React.FC = () => {
    const { signInByGmail, signInByFacebook } = useAuth()
    const { search } = useLocation()
    const searchParams = new URLSearchParams(search)
    const redirectUrl = useState(redirectURLHolderOnAuth)
    const redirectToPath = searchParams.get('redirectToPath')

    const gmailLogin = async () => {
        await signInByGmail()
    }

    const facebookLogin = async () => {
        await signInByFacebook()
    }

    useEffect(() => {
      redirectUrl.set( p => (redirectToPath)?redirectToPath:p )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  return (
    <>
    <Flex justifyContent="flex-end" mb={-14}>
        <ColorModeSwitcher m={2} />
    </Flex>
    <Flex minH="100vh" direction={{ base: 'column', md: 'row' }}>
      <Box
        display={{ base: 'none', md: 'block' }}
        flex="1"
        bg={mode('white', 'gray.700')}
        color="white"
        px="10"
        py="8"
      >
        <Box>
          <Logo w="auto" h="7" color="white" justifyContent="center" />
        </Box>
        <Flex
          direction="column"
          align="center"
          justify="center"
          h="full"
          textAlign="center"
          mt="-10"
          color={mode('gray.700', 'inherit')}
        >
          <Box>
            <Text
              maxW="md"
              mx="auto"
              fontWeight="extrabold"
              fontSize={{ base: '4xl', lg: '5xl' }}
              letterSpacing="tight"
              lineHeight="normal"
            >
              Thank You For Your Support
            </Text>
            <Text mt="5" maxW="sm" mx="auto">
              <b>1200+</b> organizations have chosen to trust us with issuing there certificates. Over <b>2.5 million</b> certificates are issued using our plateform for organizers across <b>17 countries</b>.
            </Text>
            <Divider my={5} />
            <Text>
              Follow us to stay connected
            </Text>
            <HStack
              justifyContent="center"
              pt={3}
            >
              <IconButton
                aria-label='Follow on Instagram'
                size='md'
                variant="outline"
                icon={<FaInstagram />}
                colorScheme='orange'
                onClick={() => window.open("https://www.instagram.com/givemycertificate", "_blank")}
              />
              <IconButton
                aria-label='Follow on Linked In'
                size='md'
                variant="outline"
                icon={<FaLinkedin />}
                colorScheme='linkedin'
                onClick={() => window.open("https://www.linkedin.com/company/givemycertificate/", "_blank")}
              />
              <IconButton
                aria-label='Follow on Twitter'
                size='md'
                variant="outline"
                icon={<FaTwitter />}
                colorScheme='twitter'
                onClick={() => window.open("https://twitter.com/givemycert", "_blank")}
              />
              <IconButton
                aria-label='Follow on Facebook'
                size='md'
                variant="outline"
                icon={<FaFacebook />}
                colorScheme='facebook'
                onClick={() => window.open("https://www.instagram.com/givemycertificate/", "_blank")}
              />
            </HStack>
          </Box>
          {/* <HStack
            justify="center"
            as="a"
            href="#"
            minW="2xs"
            py="3"
            fontWeight="semibold"
            px="2"
            mt="5"
            border="2px solid white"
            rounded="lg"
            _hover={{ bg: 'whiteAlpha.200' }}
          >
            <Box>Demo Video</Box>
            <HiOutlineExternalLink />
          </HStack> */}
        </Flex>
      </Box>
      <Box minW="50%" >
        <Box
            flex="1"
            px={{ base: '6', md: '10', lg: '16', xl: '28' }}
            py={{ base: '10', md: '48' }}
            bg={{ md: mode('gray.50', 'gray.800') }}
        >
            <Box maxW="xl">
            <Box>
                <Box display={{ md: 'none' }} mb="16">
                    <Logo w="auto" h="7" iconColor="blue.400" justifyContent="center" />
                </Box>
                <Heading
                    color={mode('blue.600', 'blue.400')}
                    as="h1"
                    size="2xl"
                    fontWeight="extrabold"
                    letterSpacing="tight"
                >
                    Welcome back
                </Heading>
                <Text
                    mt="3"
                    fontSize={{ base: 'xl', md: '3xl' }}
                    fontWeight="bold"
                    color={mode('gray.500', 'inherit')}
                >
                    Sign in to continue
                </Text>
            </Box>

            <Box
                minW={{ md: '420px' }}
                mt="10"
                rounded="xl"
                bg={{ md: mode('white', 'gray.700') }}
                shadow={{ md: 'lg' }}
                px={{ md: '10' }}
                pt={{ base: '8', md: '12' }}
                pb="8"
            >
                <Stack spacing="4">
                    <Button 
                        variant="outline" 
                        leftIcon={<Box as={FaGoogle} color="red.500" />} 
                        onClick={gmailLogin}
                    >
                        Sign up with Google
                    </Button>
                    <Button
                        variant="outline"
                        leftIcon={<Box as={FaFacebook} color={mode('facebook.500', 'facebook.300')} />}
                        onClick={facebookLogin}
                    >
                        Sign up with Facebook
                    </Button>
                </Stack>

                <DividerWithText>or</DividerWithText>
                <SigninForm />
            </Box>
            </Box>
        </Box>
      </Box>
    </Flex>
    </>
  )
}